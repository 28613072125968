import React, { Component } from 'react';

class InclusionExclusion extends Component {
    render() {
        return (
            <>
                <h4><u>INCLUSION: <strong className='tomato'>ASSAM MEGHALAYA ARUNACHAL PRADESH PACKAGE TOUR</strong></u></h4>
                <ul>
                <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;All transportation by a dedicated and exclusive vehicle
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Experienced & friendly driver throughout the trip
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Accommodation in Best Hotels</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Elephant safari, Jeep Safari & Entry Fee Charges in Kaziranga National Park</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Arunachal Pradesh entry permits (ILP)</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;All Parking, Toll Charges, Fuel Charges, State Permits etc</li>
                 
                </ul><br/><br/><br/>
                <h4><u>EXCLUSIONS: <strong className='tomato'>TAWANG SHILLONG KAZIRANGA PACKAGE TOUR </strong></u></h4>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Items not mentioned in the inclusion section</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Sightseeing points entry fee</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Local Car charges for visiting Bumla pass</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;A.C will not operate on hills</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Personal Expenses</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Applicable Govt. GST</li>
                </ul>
            </>
        );
    }
}

export default InclusionExclusion;