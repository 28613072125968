import React, { Component } from 'react';

class InclusionExclusion_group extends Component {
    render() {
        return (
            <>
                <h4 className='tomato1'>INCLUSIONS: <strong>SILK ROUTE PACKAGE TOUR FROM KOLKATA</strong></h4><br/>
                <ul>
                <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Accommodation in the listed Hotels / Homestays
                    </li><br/>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;All Meals (Breakfast / Lunch / Evening Snacks / Dinner)
                    </li><br/>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;All transfers in an exclusive non-ac vehicle (NJP-NJP)</li><br/>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Driver allowances, fuel, toll tax</li><br/>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;All permits and applicable taxes</li><br/>
                    
                </ul>
                <br/><br/><br/>
                <h4 className='tomato1'>EXCLUSIONS: <strong>SILK ROUTE TOUR PACKAGE FROM KOLKATA</strong></h4><br/>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;All items which are not included in the inclusion list</li><br/>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Sunrise from Lungthung, Entry Fees for Pangolakha Wildlife Sanctuary</li><br/>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Room Heater</li><br/>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Syndicate Charge at NJP / Siliguri (Rs 200/-)</li><br/>
                    
                </ul>
            </>
        );
    }
}

export default InclusionExclusion_group;