class ApiURL {
  ////==========================================================================
  ////Base URL Section
  ////==========================================================================
  ////Local Mechine
    //static BaseURL = "http://localhost:8000";

  ////Production Server
  static BaseURL = "https://api.adorablevacation.com";

  ////==========================================================================
  ////Image URL Section
  ////==========================================================================
  static BaseImageURL = this.BaseURL + "/uploadfiles";
  static HomeBannerImageURL = this.BaseImageURL + "/HomeBanner";
  static VisaServicesImageURL = this.BaseImageURL + "/VisaService";
  static DomesticExplorerImageURL = this.BaseImageURL + "/DomesticExplorer";
  static BlogExplorerImageURL = this.BaseImageURL + "/BlogExplorer";
  static InternationalExplorerImageURL = this.BaseImageURL + "/InternationalExplorer";
  static TestimonialClientImageURL = this.BaseImageURL + "/Testimonial";
  static HotPackageImageURL = this.BaseImageURL + "/HotPackages";
  static AboutUsImageURL = this.BaseImageURL + "/AboutUs";
  static ContactUsImageURL = this.BaseImageURL + "/ContactUs";
  //----------------------------------------------------------------------------

  static BhutanTourPackageImageURL = this.BaseImageURL + "/Bhutan_Tour_Package";
  static FIFO_4N_5D_ImageURL = this.BaseImageURL + "/4N_5D_FIFO";
  static FIFO_5N_6D_ImageURL = this.BaseImageURL + "/5N_6D_FIFO";
  static FIFO_6N_7D_ImageURL = this.BaseImageURL + "/6N_7D_FIFO";
  static FIFO_7N_8D_ImageURL = this.BaseImageURL + "/7N_8D_FIFO";
  static FIFO_8N_9D_ImageURL = this.BaseImageURL + "/8N_9D_FIFO";
  static FIFO_10N_11D_ImageURL = this.BaseImageURL + "/10N_11D_FIFO";
  static BhutanTourPackageFromDelhiImageURL = this.BaseImageURL + "/Bhutan_Tour_Package_From_Delhi";
  static BhutanTourPackageFromMumbaiImageURL = this.BaseImageURL + "/Bhutan_Tour_Package_From_Mumbai";
  static BhutanTourPackageFromKolkataImageURL = this.BaseImageURL + "/Bhutan_Tour_Package_From_Kolkata";
  static BhutanTourPackageFromChennaiImageURL = this.BaseImageURL + "/Bhutan_Tour_Package_From_Chennai";
  static BhutanTourPackageFromHyderabadImageURL = this.BaseImageURL + "/Bhutan_Tour_Package_From_Hyderabad";
  static BhutanTourPackageFromAhemdabadImageURL = this.BaseImageURL + "/Bhutan_Tour_Package_From_Ahemdabad";
  static BhutanTourPackageFromBangaloreImageURL = this.BaseImageURL + "/Bhutan_Tour_Package_From_Bangalore";
  static BhutanTourPackageFromPuneImageURL = this.BaseImageURL + "/Bhutan_Tour_Package_From_Pune";
  static BhutanTourPackageFromJaigaonImageURL = this.BaseImageURL + "/Bhutan_Tour_Package_From_Jaigaon";
  static BhutanTourPackageFromBagdograImageURL = this.BaseImageURL + "/Bhutan_Tour_Package_From_Bagdogra";
  static BhutanTourPackageFromSuratImageURL = this.BaseImageURL + "/Bhutan_Tour_Package_From_Surat";
  static BhutanB2BPackageImageURL = this.BaseImageURL + "/Bhutan_B2B_Package";
  static BhutanTourPackageFromGuwahatiImageURL = this.BaseImageURL + "/Bhutan_Tour_Package_From_Guwahati";
  static BhutanTourPackageSixsensesImageURL = this.BaseImageURL + "/Bhutan_Tour_Package_Sixsenses";
  static BhutanTourPackageFromHasimaraImageURL = this.BaseImageURL + "/Bhutan_Tour_Package_From_Hasimara";
  static BhutanTourPackageFromPhuentsholingImageURL = this.BaseImageURL + "/Bhutan_Tour_Package_From_Phuentsholing";
  static BhutanTourPackageViaKolkataAirportImageURL = this.BaseImageURL + "/Bhutan_Tour_Package_From_Bangalore_via_kolkata_airport";
  //--------------------Meghalaya---------------------
  static MeghalayaTourPackageImageURL = this.BaseImageURL + "/Meghalaya_Tour_Package";
  static Megalaya_5N_6D_PackageImageURL = this.BaseImageURL + "/5N-6D-Meghalaya";
  static Megalaya_6N_7D_PackageImageURL = this.BaseImageURL + "/6N-7D-Meghalaya";
  static Megalaya_7N_8D_PackageImageURL = this.BaseImageURL + "/7N-8D-Meghalaya";
  static Arunachal_6N_7D_PackageImageURL = this.BaseImageURL + "/6N-7D-Arunachal";
  static Arunachal_10N_11D_PackageImageURL = this.BaseImageURL + "/10N-11D-Arunachal";
  static Arunachal_11N_12D_PackageImageURL = this.BaseImageURL + "/11N-12D-Arunachal";
  static Shillong_Meghalaya_6N_7D_PackageImageURL = this.BaseImageURL + "/6N-7D-Shillong_Meghalaya_guwahati";
  static Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL = this.BaseImageURL + "/Kaziranga-Shillong-Cherapunjee-from_guwahati";
  static Meghalaya_Shillong_Cherrapunjee_7N_8D_PackageImageURL = this.BaseImageURL + "/Meghalaya-Shillong-Cherapunjee-from_guwahati";
  static Meghalaya_WariChora_Tura_7N_8D_PackageImageURL = this.BaseImageURL + "/Meghalaya-Wari-Chora-Tuta-from-guwahati";
  static Assam_Meghalaya_Arunachal_19N_20D_PackageImageURL = this.BaseImageURL + "/Assam-Meghalaya-Arunachal-from-guwahati";
  //-----------------Arunachal-----------------------------------
  static Arunachal_guwahati_15N_16D_PackageImageURL = this.BaseImageURL + "/15N-16D-Arunachal_guwahati";
  static ArunachalTourMumbai_14N_15D_PackageImageURL = this.BaseImageURL + "/14N-15D-Arunachal_mumbai";
  static ArunachalTourKolkata_9N_10D_PackageImageURL = this.BaseImageURL + "/9N-10D-Arunachal-kolkata";
  static NortEastTourIndia_10N_11D_PackageImageURL = this.BaseImageURL + "/North_East_India_Tour_From_Guwahati";
  static MeghalayaArunachal_12N_13D_PackageImageURL = this.BaseImageURL + "/MeghalayaArunachal_12N_13D_from_Guwahati";
  static TawangBumlaPass_7N_8D_PackageImageURL = this.BaseImageURL + "/TawangBumlaPass_7N_8D";
  //--------------------Sikkim-Darjeeling--------------------------
  static Sikkim_4N_5D_PackageImageURL = this.BaseImageURL + "/Sikkim_Darjeeling_4N_5D";
  static Sikkim_5N_6D_PackageImageURL = this.BaseImageURL + "/sikkim_tour_package_5N_6D";
  static NorthSikkim_Silkroute_8N_9DImageURL = this.BaseImageURL + "/NorthSikkim_Silkroute_8N_9D";
  static Sikkim_Bagdogra_Package_9N_10DImageURL = this.BaseImageURL + "/Sikkim_Bagdogra_Package_9N_10D";
  static SilkRoute_Tour_Package_KolkataImageURL = this.BaseImageURL + "/Silkroute_Tour_package_Kolkata";
  //---------------------Andaman----------------
  static Andaman_6N_7D_Package = this.BaseImageURL + "/Andaman_6N_7D_package";
  static Andaman_7N_8D_Package_KolkataImageURL = this.BaseImageURL + "/Andaman_7N_8D_Package_Kolkata";
  static Andaman_5N_6D_HoneymoonPackageImageURL = this.BaseImageURL + "/Andaman_5N_6D_Honeymoon_Package";
  //---------------------Kashmir------------------
  static Kashmir_Tour_Package_from_Srinagar_Airport = this.BaseImageURL + "/Kashmir-Tour-Package-from-Srinagar-Airport";
  static Kashmir_Tour_Package_with_Doodhpathri_Yusmarg = this.BaseImageURL + "/Kashmir-Tour-Packages-With-Doodhpathri-Yusmarg";
  static Kashmir_Tour_Package_with_Gurez_Valley = this.BaseImageURL + "/Kashmir-Tour-Packages-With-Gurez-valley";
  static Kashmir_Tour_Package_with_Sinthan_Top = this.BaseImageURL + "/Kashmir-Tour-Packages-With-Sinthan-Top";
  static Kashmir_Tour_Package_with_Vaishno_Devi = this.BaseImageURL + "/Kashmir-Tour-Packages-With-Vaishno-devi";
  static Kashmir_Tour_Package_with_Tulip = this.BaseImageURL + "/Kashmir-Tour-Packages-With-Tulip";
  static Kashmir_Tour_Package_from_Kolkata = this.BaseImageURL + "/Kashmir-Tour-Packages-from-kolkata";
  static Kashmir_Tour_Package_from_Surat = this.BaseImageURL + "/Kashmir-Tour-Packages-from-Surat";
  static Kashmir_Tour_Package_from_Chennai = this.BaseImageURL + "/Kashmir-Tour-Packages-from-Chennai";
  static Kashmir_Tour_Package_from_Bangalore = this.BaseImageURL + "/Kashmir-Tour-Packages-from-Bangalore";
  static Kashmir_Tour_Package_from_Pune = this.BaseImageURL + "/Kashmir-Tour-Packages-from-Pune";
  static Kashmir_Tour_Package_from_Mumbai = this.BaseImageURL + "/Kashmir-Tour-Packages-from-Mumbai";
  static Kashmir_Tour_Package_from_Delhi = this.BaseImageURL + "/Kashmir-Tour-Packages-from-Delhi";
  static Kashmir_Tour_Package_from_Ahmedabad = this.BaseImageURL + "/Kashmir-Tour-Packages-from-Ahmedabad";
  static Kashmir_Family_Package_Tour = this.BaseImageURL + "/Kashmir-Family-Package-Tour";
  static Kashmir_Vaishno_devi_Amritsar_Tour_Package = this.BaseImageURL + "/Kashmir-Vaishno-devi-Amritsar-Tour-Package";
   //---------------------Ladakh------------------
   static Ladakh_Tour_Package_6N_7D = this.BaseImageURL + "/Ladakh-Tour-Package-6N-7D";
   static leh_ladakh_kargil_8N_9D = this.BaseImageURL + "/leh-ladakh-kargil-8N-9D";
   static Leh_Ladakh_Turtuk_Package = this.BaseImageURL + "/Leh-Ladakh-Turtuk-Package";
   static Kashmir_Leh_Ladakh_Tour_PackageImageURL = this.BaseImageURL + "/Kashmir-Leh-Ladakh-Tour-Package";
   //----------------------Thailand----------------
   static Thailand_8N_9D_Package = this.BaseImageURL + "/Thailand-Tour-Package-8N-9D";
   static BangkokPattaya_8N_9D_Package = this.BaseImageURL + "/Bangkok-Pattaya-Tour-Package-8N-9D";
   //-----------------------Vietnam----------------
   static Vietnam_7N_8D_Kolkata_PackageImageURL = this.BaseImageURL + "/Vietnam_7N_8D_From_Kolkata";
    //----------------------Egypt----------------
    static Egypt_8N_9D_PackageImageURL = this.BaseImageURL + "/Egypt-8N-9D-Tour-Package";
   //----------------------Vietnam-----------------
   static Vietnam_7N_8D_Package = this.BaseImageURL + "/Vietnam_7N_8D_Package";
   //-----------------------Sundarban---------------------------
   static Sundarban_Sonarbangla_PackageImageURL = this.BaseImageURL + "/Sundarban_Sonarbangla_Tour";
   static Sundarban_Kolkata_PackageImageURL = this.BaseImageURL + "/sundarban_kolkata";
   //----------------------Sandakphu
   static Sandakphu_trekking_PackageImageURL = this.BaseImageURL + "/sandakphu_trekking_Package";
   static Sandakphu_land_rover_PackageImageURL = this.BaseImageURL + "/sandakphu_land_rover_Package";
  ////===========================================================================
  ////API URL Section
  ////===========================================================================
  static SaveCustomerEnquery = this.BaseURL + "/api/customer/saveCustomerEnquery/";
  static GetAllOriginCities = this.BaseURL + "/api/customer/getAllOriginCities/";
  // static ContactUs = this.BaseURL + "/api/customer/contactUs";
  // static title = this.BaseURL + "/pages/";

  ////=========================================================================
  /*static ProductListByCategory(Cat1Name) {
    return this.BaseURL + "/api/product/productListByCategory/" + Cat1Name;
  }
  static ProductListBySubcategory(Cat1Name, Cat2Name) {
    return this.BaseURL + "/api/product/productListBySubcategory/" + Cat1Name + "/" + Cat2Name;
  } */


}

export default ApiURL;
