import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./InclusionExclusion";
import InclusionExclusion from "./InclusionExclusion_AssamMeghalayaArunachal_19n_20d";

class Assam_Meghalaya_Arunachal_19N_20D_Package extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: 'tab1',
      daywiseActiveTab: 'day1'
    }
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  handleDayWiseTabClick = (tab) => {
    this.setState({
      daywiseActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919674738480&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
  render() {
    const { customActiveTab, daywiseActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.Assam_Meghalaya_Arunachal_19N_20D_PackageImageURL + "/Arunachal-Pradesh-Package-Tour-from-Kolkata.webp";
    const packageTopBanner2ImageURL = ApiURL.Assam_Meghalaya_Arunachal_19N_20D_PackageImageURL + "/Arunachal-Pradesh-Tour-Package-from-Kolkata.webp";
    const sightseen1ImageURL = ApiURL.Assam_Meghalaya_Arunachal_19N_20D_PackageImageURL + "/ARUNACHAL PRADESH TOUR PACKAGES FROM KOLKATA.webp";
    const sightseen2ImageURL = ApiURL.Assam_Meghalaya_Arunachal_19N_20D_PackageImageURL + "/Arunachal-Pradesh-Package-Tour-from-Guwahati.webp";
    const sightseen3ImageURL = ApiURL.Assam_Meghalaya_Arunachal_19N_20D_PackageImageURL + "/Shillong-Package-Tour.webp";

    const navDay1ImageURL = ApiURL.Assam_Meghalaya_Arunachal_19N_20D_PackageImageURL + "/BHALUKPONG-TO-DIRANG-TOUR.webp";
    const navDay2ImageURL = ApiURL.Assam_Meghalaya_Arunachal_19N_20D_PackageImageURL + "/DIRANG-TO-TAWANG.webp";
    const navDay3ImageURL = ApiURL.Assam_Meghalaya_Arunachal_19N_20D_PackageImageURL + "/TAWANG-LOCAL-SIGHTSEEING.webp";
    const navDay4ImageURL = ApiURL.Assam_Meghalaya_Arunachal_19N_20D_PackageImageURL + "/TAWANG-BUMLA PASS.webp";
    const navDay5ImageURL = ApiURL.Assam_Meghalaya_Arunachal_19N_20D_PackageImageURL + "/TEZPUR-KAZIRANGA.webp";
    const navDay6ImageURL = ApiURL.Assam_Meghalaya_Arunachal_19N_20D_PackageImageURL + "/TAWANG-BOMDILA.webp";
    const navDay7ImageURL = ApiURL.Assam_Meghalaya_Arunachal_19N_20D_PackageImageURL + "/BOMDILA-TEZPUR";
    const navDay8ImageURL = ApiURL.Assam_Meghalaya_Arunachal_19N_20D_PackageImageURL + "/JUNGLE-SAFARI-KAZIRANGA.webp";
    const navDay9ImageURL = ApiURL.Assam_Meghalaya_Arunachal_19N_20D_PackageImageURL + "/GUWAHATI-TO-BHALUKPONG.webp";
    const navDay10ImageURL = ApiURL.Assam_Meghalaya_Arunachal_19N_20D_PackageImageURL + "/GUWAHATI.webp";

    const TrendingPackagesDataList = this.props.TrendingPackagesData;
    const hotPackagesView = TrendingPackagesDataList.length > 0 ?
      (
        TrendingPackagesDataList.map((dataItem, i) => {
          const hotelImgURL = ApiURL.Assam_Meghalaya_Arunachal_19N_20D_PackageImageURL + "/" + dataItem.ImageName;
          return (<div className="col-md-12 p-0" key={i}>
            <div className="card h-100">
              <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
              <div className="card-body">
                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text"><span>{dataItem.Description}</span></p>
                <p>
                  <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                  <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                  <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                </p>
              </div>
              <div className="card-footer">
                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                <button type="button" className="btn btn-Warning btn-sm float-end">Book Now</button>
              </div>
            </div>
          </div>)
        })
      ) : (<div></div>)


    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL = ApiURL.Assam_Meghalaya_Arunachal_19N_20D_PackageImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      )
    })

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
          </div>
        </div>
        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container py-1">


                    <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>Assam Meghalaya Arunachal Pradesh Package Tour</strong> From Guwahati - North East Spl | BEST PRICE</center></h1>

                    <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Assam Meghalaya Arunachal Pradesh Package Tour</strong> From Guwahati - North East Spl | BEST PRICE</center>
                      <hr />
                    </h3>
                    <div className="container-fluid">
                      <div className="row">
                        <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                      </div>
                    </div>
                    <hr />
                  </div>
                  <h2 className="content-desktop p-4" style={{ fontSize: "2.0vw" }}> Looking for <strong>Assam Meghalaya Arunachal Pradesh Package Tour from Guwahati</strong>? <a href="tel:+91-9674738480">FOR BOOKING CALL +91-96747-38480</a><br /></h2>
                  <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> Looking for <strong>Assam Meghalaya Arunachal Pradesh Package Tour from Guwahati</strong>? <a href="tel:+91-9674738480">FOR BOOKING CALL +91-96747-38480</a><br /></h3>
                  <div className="card">
                    <div
                      className="card-header custHeaer"
                    >
                      <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Embark on an Enchanting Journey with <strong>Assam Meghalaya Arunachal Pradesh Package Tour from Guwahati</strong></h3>
                      <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Embark on an Enchanting Journey with <strong>Assam Meghalaya Arunachal Pradesh Package Tour from Guwahati</strong></h4>
                    </div>
                    <div className="card-body">
                      <p>Unleash your wanderlust and explore the less explored wonderland of India - The North East with NatureWings special <strong>Assam Meghalaya Arunachal Pradesh Package Tour</strong>. North East indeed is a remarkable destination for a memorable holiday. These states are the perfect places for travelers who wish to explore the scenic natural beauty along with the primitive tribes of India, their culture and simplicity along with attractive nature beauty. <br /><br />
                        <b>Assam Meghalaya and Arunachal Pradesh</b> is a place for those who love exploration and travelling. Here you can hear the melody of mesmerizing <b>Noh KaLikai Fall</b> creating ripples at bottom amongst the green valley of Meghalaya. Stop over at the beautiful Tawang Monastery - the second largest monastery in World. Behold the astonishing engineering of human - the <b>Double Decker Root Bridges of Umshiang at Cherrapunji</b> and get socked with mist and rain at <b>Mawsynram</b> - the place for highest rain fall in India. Endow your famished eyes with a stunning view of <b>Umiam Lake</b> - a wonderful man made reservoir expanding over 222 square kilometers. <br /><br />
                        Indeed <b>North East</b> is a treasure box of beautiful marvels and man-made wonders that you can experience only during your <strong>Assam Meghalaya Arunachal Pradesh Tour Package from Guwahati</strong>. This is a world to amazing beauty where you can get the proximity of unadulterated natural beauty and inner peace. So, start exploring this treasure land with NatureWings - we bet it will be your life time experience. <br /><br /></p>

                      <div className="content-desktop">
                        <center><img src={sightseen1ImageURL} alt="..." /></center>
                      </div>

                      <div className="content-mobile2">
                        <center><img src={sightseen1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                      </div>
                      <br />

                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>ASSAM MEGHALAYA ARUNACHAL TOUR </strong> Duration : 19 Nights | 20 Days</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>ASSAM MEGHALAYA ARUNACHAL TOUR</strong> DURATION : 19N | 20D </h4>
                </div>
                <div className="card-body">
                  <p>Destinations covered in this <a href="/north-east-india-package-tour-from-guwahati-airport" target="_blank"><strong>North East Package Tour</strong></a> : Guwahati - 1N | Cherrapunjee - 2N | Shillong - 2N | Bhalukpong - 1N | Dirang - 1N | Tawang - 3N | Bomdila - 1N | Nameri - 1N | Itanagar - 2N | Ziro - 2N | Kaziranga National Park - 2N | Majuli/Jorhat - 1N <br /><br /></p>

                  <p><b>Pickup :</b> Lokpriya Gopinath Bordoloi International Airport, Guwahati [GAU] / Guwahati Railway Station [GHY] <br /></p>
                  <p><b>Drop :</b> Jorhat Airport [JRH] <br /><br /></p>

                  <p><b>Location of Bhalukpong</b> : 213 m [Coordinates - 27.0137&#176; N, 92.6345&#176; E] <br /></p>
                  <p><b>Location of Dirang</b> : 1,560 m [Coordinates - 27.3584&#176; N, 92.2409&#176; E] <br /></p>
                  <p><b>Location of Tawang</b> : 3,048 m [Coordinates - 27.5861&#176; N, 91.8594&#176; E] <br /></p>
                  <p><b>Location of Bomdila</b> : 2,415 m [Coordinates - 27.2645&#176; N, 92.4159&#176; E] <br /></p>
                  <p><b>Location of Tezpur</b> : 48 m [Coordinates - 26.6528&#176; N, 92.7926&#176; E] <br /></p>
                  <p><b>Location of Shillong</b> : 1,965 m [Coordinates 25.576&#176; N, 91.883&#176; E]<br /></p>
                  <p><b>Location of Cherapunjee</b> : 1,430 m [Coordinates - 25.2702&#176; N, 91.7323&#176; E] <br /></p>
                  <p><b>Location of Mawsynram </b> : 1,400 m [Coordinates - 25.2975&#176; N, 91.5826&#176; E] <br /></p>
                  <p><b>Location of Kaziranga : </b> 26.5775&#176; N, 93.1711&#176; E <br /><br /><br /></p>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-danger">
                          <th>
                            Summary of Summary of <strong>Assam Meghalaya Arunachal Package Tour</strong>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                          >
                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                              <span>
                                GUWAHATI ARRIVAL + LOCAL SIGHTSEEING
                              </span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                              <span>GUWAHATI - CHERAPUNJEE TOUR</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                              <span>CHERRAPUNJEE FULL DAY EXCURSION TRIP</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                              <span>CHERRAPUNJEE -  SHILLONG</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                              <span> SHILLONG - MAWLYNNONG - DAWKI - SHILLONG</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                              <span> SHILLONG - BHALUKPONG TRIP</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                              <span> BHALUKPONG - DIRANG TRIP</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                              <span>  DIRANG - TAWANG TRIP</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day9" style={{ color: 'black' }}><b>DAY 9 → </b>
                              <span> TAWANG MONASTERY TOUR</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day10" style={{ color: 'black' }}><b>DAY 10 → </b>
                              <span> TAWANG LOCAL SIGHTSEEING TOUR</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day11" style={{ color: 'black' }}><b>DAY 11 → </b>
                              <span> TAWANG - BOMDILA TRANSFER</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day12" style={{ color: 'black' }}><b>DAY 12 → </b>
                              <span> BOMDILA - NAMERI TRANSFER</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day13" style={{ color: 'black' }}><b>DAY 13 → </b>
                              <span> NAMERI - ITANAGAR</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day14" style={{ color: 'black' }}><b>DAY 14 → </b>
                              <span> ITANAGAR - ZIRO</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day15" style={{ color: 'black' }}><b>DAY 15 → </b>
                              <span> FULL DAY ZIRO VALLEY EXCURSION</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day16" style={{ color: 'black' }}><b>DAY 16 → </b>
                              <span> ZIRO - TEZPUR</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day17" style={{ color: 'black' }}><b>DAY 17 → </b>
                              <span> TEZPUR - KAZIRANGA NATIONAL PARK</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day18" style={{ color: 'black' }}><b>DAY 18 → </b>
                              <span> KAZIRANGA SAFARI [ELEPHANT + JEEP]</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day19" style={{ color: 'black' }}><b>DAY 19 → </b>
                              <span> KAZIRANGA - MAJULI</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day19" style={{ color: 'black' }}><b>DAY 20 → </b>
                              <span> MAZULI - JORHAT [TOUR ENDS]</span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHAT IS THE BEST TIME TO TRAVEL TAWANG, SHILLONG, CHERRAPUNJI AND KAZIRANGA?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT IS THE BEST TIME TO TRAVEL TAWANG, SHILLONG, CHERRAPUNJI AND KAZIRANGA?</h4>
                </div>
                <div className="card-body">
                  <p>Before you choose this <strong>North East India Tour Package</strong>, lets first understand that this is going to be a long trip as you are travelling three most astonishing and nature pact states - <b>Assam</b>, <b>Arunachal</b> & <b>Meghalaya</b>. Some of the destinations during this tour will be above 3,000 meters like Tawang (3,048 m) in Arunachal Pradesh and some the destination is in plain grass land surrounded by large river valleys. That's why you need to plan your travel date very wisely. Here comes NatureWings - The <a href="north-east-india-package-tour-from-guwahati-airport" target="_blank"><strong>North East Tour Package from Guwahati</strong></a> specialist to suggest you season wise best time to book your <strong>Assam Meghalaya Arunachal Pradesh Package Tour</strong>.<br /><br /><br /></p>
                  <h4><b>Summer in North East</b> <br /></h4>
                  <p>When the entire plains of India burns due to unbearable heat of summer, Arunachal comes as an relief to save you up from boiling temperature. If you want to beat the heat of summer, <a href="/arunachal-pradesh-package-tour-from-kolkata" target="_blank"><strong>Arunachal Pradesh Package Tour from Kolkata</strong></a> or any <a href="/arunachal-pradesh-package-tour-from-guwahati" target="_blank"><strong>Arunachal Package Tour from Guwahati</strong></a> is best option. You will experience temperature between 8&#176;c to 14&#176;c which makes <strong>Arunachal a perfect place for Summer Holidays</strong>. Summer in Arunachal is the best time to behold the breathtaking beauties of mountains, streams, waterfalls, valleys, wild life and monasteries also. Same weather is noticed in Shillong also. With moderate temperature between 10&#176;c to 18&#176;c Shillong and Cherrapunji is cold and little bit rainy in summer which is enjoyable. <br /><br />
                    Now, comes the issue with Kaziranga National Park. Every year from 30th April to 31th October (in 2021 it was 1st Oct) Kaziranga remains close being the breathing season for wild animals in this national park. More over due to heavy rain most of the park goes under flood water of Brahmaputra River. So, during summer you will not be able to explore all the destinations that you are planning for. But if you are looking for only <strong>Arunachal Pradesh Package Tour</strong>  or <a href="/shillong-meghalaya-package-tour-from-guwahati" target="_blank"><strong>Shillong Meghalaya Package Tour</strong></a>, you can definitely plan for your trip.<br /><br /><br /></p>

                  <h4><b>Winter in North East</b> <br /></h4>
                  <p>Winter is probably the best time to run away from the mad crowds, hectic schedule of 9 to 6 office hours, suffocating city smog and monotonous life. If you want to spend your holidays in winter, want to play with snow ball, make snow man, then travelling Arunachal during winter (Oct to March) will be an amazing experience.<br /><br />
                    In winter Shillong & Cherrapunji of Meghalaya also remains very cold and mystic hills of Garo, Khasi and Jaintia makes this place with unadulterated beauty. Travelling North East during winter is really worth value.<br /><br />
                    Now comes to Kaziranga. It's wide spread grassy lands with some of the best wild inhabitant's draws lots of tourist in winter. Having mystic chill weather, <a href="/Kaziranga-Shillong-Cherrapunjee-tour-Package-from-Guwahati" target="_blank"><strong>Elephant Safari in Kaziranga National Park</strong></a> and Jeep Safari in four of its ranges such as <b>Central Range or Kohora Range</b>, <b>Western Range or Bagori Range</b>, <b>Eastern Range or Agartoli Range</b>, <b>Burhapahaar Range or Ghorakati Range</b> attracts visitors to explore and enjoy the park at its best. So, winter is the best time to book your <strong>Assam Meghalaya Arunachal Pradesh Package Tour from Guwahati</strong>.<br /><br /><br /></p>

                  <h4><b>Monsoon in North East</b> <br /></h4>
                  <p>Monsoon generally consists of July to September in North East. In monsoon, Cherapunji and Mawsynrum has a special attraction that you cannot experience anywhere in India. During monsoon Cherapunji and Mawsynrum turns into a rainy paradise with gorgeous waterfalls, lush greenery and lakes with full brink. It is best time for adventure lovers who want to experience the rain sitting idle by the window for whole day. Nothing is comparable to the feeling of sipping a cup of warm tea or coffee and listening to the songs of rains or some romantic old melodies like:<br /><br />

                    <b>"Zindagi bhar nahin bhoolegi woh<br />
                      barsaat ki raat<br />
                      Ek anjaan haseena se mulaqaat ki raat"</b><br /><br />

                    After all rain has its own beauty that brings life in to action. So if you want to experience rain washed refreshing North East, book your <strong>Shillong Meghalaya Package Tour</strong> from NatureWings between July to September.<br /><br />
                    <span>PLEASE NOTE :</span> During monsoon travel you can experience some difficulties like road block, land slide, incessant rain for several days. More over Kaziranga National Park also remain close from April to October i.e. entire monsoon season. So, booking a complete <strong>19 Nights/20 Days Assam Meghalaya & Arunachal Package Tour</strong> is not suggestible at all during this time.<br /><br />
                    In spite of that, if you are too much travel enthusiast, keen to explore the rain washed valleys, gushing waterfalls, over flooded streams and life style of people of North East, travel here in monsoon only. We bet thrilling and excitement will be waiting in every corner of your tour.<br /><br /><br /></p>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> MONTH WISE WEATHER IN <strong>ASSAM MEGHALAYA ARUNACHAL TOUR PACKAGE</strong> </h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>  MONTH WISE WEATHER IN <strong>ASSAM MEGHALAYA ARUNACHAL TOUR PACKAGE</strong> </h4>
                </div>
                <div className="card-body">
                  <h4><b>Month Wise Weather in Cherrapunjee</b> <br /><br /></h4>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th><b>Travel Month</b></th><th><b>Min / Max Temperature</b></th><th><b>Season</b></th></tr></thead>
                      <tbody><tr><td>March to June</td><td>15&#176; - 25&#176;C</td><td>Warm but Pleasant</td></tr>
                        <tr><td>July to September</td><td>18&#176; - 28&#176;C</td><td>Warm and Wet</td></tr>
                        <tr><td>October to February</td><td>8&#176; - 15&#176;C</td><td>Cold & Pleasant</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br />  <br />
                  <h4><b>Month Wise Weather in Shillong</b> <br /><br /></h4>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th><b>Travel Month</b></th><th><b>Min / Max Temperature</b></th><th><b>Season</b></th></tr></thead>
                      <tbody><tr><td>March to June</td><td>12&#176; - 24&#176;C</td><td>Pleasant</td></tr>
                        <tr><td>July to September</td><td>10&#176; - 22&#176;C</td><td>Rainy Season</td></tr>
                        <tr><td>October to February</td><td>5&#176; - 14&#176;C</td><td>Cold</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br />  <br />
                  <h4><b>Month Wise Weather in Arunachal</b> <br /><br /></h4>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th><b>Travel Month</b></th><th><b>Min / Max Temperature</b></th><th><b>Season</b></th></tr></thead>
                      <tbody><tr><td>March to June</td><td>10&#176;C to 30&#176;C</td><td>Summer</td></tr>
                        <tr><td>July to September</td><td>18&#176;C to 32&#176;C</td><td>Monsoon</td></tr>
                        <tr><td>November to February</td><td>6&#176; C to 22&#176; C</td><td>Winter</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="content-desktop">
                    <center><img src={sightseen3ImageURL} alt="..." /></center>
                  </div>

                  <div className="content-mobile2">
                    <center><img src={sightseen3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DISTANCES TO NOTE FOR <strong>ASSAM, MEGHALAYA, ARUNACHAL PACKAGE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY DISTANCES TO NOTE FOR <strong>ASSAM, MEGHALAYA, ARUNACHAL PACKAGE TOUR</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr><th>Places</th><th>Distance/Time</th></tr>
                      </thead>
                      <tbody>
                        <tr><td>Guwahati to Cherrapunji Distance</td><td>4 hr 16 min (147.8 km) via NH6</td></tr>
                        <tr><td>Cherrapunji to Shillong Distance</td><td>1 hr 45 min (53.9 km) via SH 5</td></tr>
                        <tr><td>Shillong to Mawlynnong Distance</td><td>2 hr 41 min (78.1 km) via NH206</td></tr>
                        <tr><td>Shillong to Dawki Distance</td><td>2 hr 45 min (81.8 km) via NH206</td></tr>
                        <tr><td>Shillong to Bhalukpong Distance</td><td>6 hr 40 min (298.5 km) via AH1/AH2</td></tr>
                        <tr><td>Bhalukpong to Dirang Distance</td><td>4 hr 7 min (138.6 km) via Chariduar - Tawang Rd</td></tr>
                        <tr><td>Dirang to Tawang Distance</td><td>4 hr 30 min (134.5 km) via NH13</td></tr>
                        <tr><td>Tawang to Bomdila Distance</td><td>5 hr 31 min (176.7 km) via NH13</td></tr>
                        <tr><td>Bomdila to Nameri Distance</td><td>3 hr 25 min (122.7 km) via Chariduar - Tawang Rd</td></tr>
                        <tr><td>Nameri to Itanagar Distance</td><td>3 hr 56 min (153.4 km) via NH 15</td></tr>
                        <tr><td>Itanagar to Ziro Distance</td><td>4 hr 26 min (109.5 km) via NH13</td></tr>
                        <tr><td>Ziro to Tezpur Distance</td><td>8 hr 32 min (279.0 km) via NH13 and NH 15</td></tr>
                        <tr><td>Tezpur to Kaziranga Distance</td><td>1 hr 17 min (50.6 km) via NH715</td></tr>
                        <tr><td>Kaziranga to Majuli Distance</td><td>3 hr 30 min (110.0 km) via NH715</td></tr>
                        <tr><td>Majuli to Jorhat Drop</td><td>1 hr 50 min (41.3 km) via Neamati Ghat - Jorhat Rd</td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> PRICE RANGE TO KNOW FOR BOOKING <strong>KAZIRANGA JEEP SAFARI</strong> IN DIFFERENT RANGES</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>PRICE RANGE TO KNOW FOR BOOKING <strong>KAZIRANGA JEEP SAFARI</strong> IN DIFFERENT RANGES</h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th>Range</th><th>Vehicle</th><th>Entry Fee / Indian</th><th>Entry Fee / Foreigner</th></tr></thead>
                      <tbody><tr><td>Central Range (Kohora)</td><td>INR 2700 (Incl Road Toll)</td><td>INR 100</td><td>INR 650</td></tr>
                        <tr><td>Western Range (Bagori)</td><td>INR 2800 (Incl Road Toll)</td><td>INR 100</td><td>INR 650</td></tr>
                        <tr><td>Eastern Range (Agaratoli)</td><td>INR 3200 (Incl Road Toll)</td><td>INR 100</td><td>INR 650</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <h4><a href="/Kaziranga-Shillong-Cherrapunjee-tour-Package-from-Guwahati" target="_blank"><strong>KAZIRANGA ELEPHANT SAFARI BOOKING PRICE</strong></a><br /><br /></h4>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr>
                          <th>Citizen</th>
                          <th>Ranges</th>
                          <th>Rate/Hour</th>
                        </tr>
                      </thead>
                      <tbody><tr><td>Indian</td><td>Western Range (Bagori)</td><td>INR 1,000</td></tr>
                        <tr><td>Foreigner</td><td>Central Range (Kohora)</td><td>INR 2,100</td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />

              {/* <!--By Itinerary--> */}
              <div className="row py-5">
                <div className="col-md-12">
                  <div className="site-heading text-center">
                    <h3>
                      DAY WISE ITINERARY FOR <span><strong> ASSAM, MEGHALAYA & ARUNACHAL PACKAGE TOUR</strong></span>
                    </h3>
                    <h4>19 NIGHTS / 20 DAYS <strong>Assam, Meghalaya & Arunachal Itinerary</strong> from Adorable Vacation</h4>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="accordion"
                  id="accordionPanelsStayOpenExample"
                >
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day1"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        <h4> DAY 01: Welcome to Guwahati and Local Sightseeing </h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div className="accordion-body">
                        <p>After on arrival at Guwahati Airport [GAU] for your assistance and warm traditional welcome by our representative and precede for famous <strong>Maa Kamakhya Temple Tour</strong> on the Nilachal Hills (10kms) a seat of the tantric cult in India and one of the 108 Shakti Pethas in the country. Return to the hotel after breakfast. Visits<b> Sri Shankardeva Kalakshetra-a cultural</b> center with a fine exposition of the life and culture of the people of Assam, Balaji Temple & Basistha Ashram. Evening you can set out for <strong>Brahmaputra River Cruise</strong> on the mighty river Brahmaputra (subject to availability/direct payment basis). Overnight stay at hotel in Guwahati.<br /><br /></p>
                        <div className="content-desktop">
                          <center><img src={navDay1ImageURL} alt="bhutan B2B tour packages with direct flights" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="bhutan B2B tour packages with direct flights" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day2"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        <h4>DAY 02: Guwahati to Cherrapunji Tour [4 hr 11 min (147.8 km) via NH6]</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTwo"
                    >
                      <div className="accordion-body">
                        <p>After breakfast drive to Cherrapunjee which is famed for being the wettest place in the world. En route, you will visit the <b>Umium Lake - largest man made lake of Northeast India</b>. After a drive of 4.5 hours amid pine trees and mists we reach Cherrapunjee where you can find many exotic waterfalls and impressive limestone caves. En-Route you can visit Lady Hyadri Park, Ward's Lake, Cathedral Cathotic Church - In the heart of the city, there stands a beautiful cathedral of Roman Catholics dedicated to Mary Help of Christian. Evening free for leisure. Overnight stay at hotel in Cherrapunjee.<br /><br /></p>
                        <div className="content-desktop">
                          <center><img src={navDay2ImageURL} alt="mumbai to bhutan B2B tour packages booking with direct flights" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="mumbai to bhutan B2B tour packages booking with direct flights" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day3"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 03: Full Day Cherrapunji Excursion Trip</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseThree"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingThree"
                    >
                      <div className="accordion-body">
                        <p>In the morning after sumptuous breakfast, check out from your hotel & proceed to view several attractions of Cherrapunjee. Visit -<br /><br />
                          <b>Seven Sister Falls</b>  :<br />
                          <b>Location:</b> Nongkalikhai, Cherrapunji, Meghalaya 793111<br />
                          Seven Sisters Falls also known as <b>Nohsngithiang Falls</b> or <b>Mawsmai Falls</b> is a seven-segmented waterfall located 1 kilometre south of Mawsmai village in East Khasi Hills District in Meghalaya. It is one of the highest water falls in India set amongst picturesque background. Almost seven falls flowing side by side gives a breathtaking view to traveler who visit here for the first time - is an unforgettable and lifetime experience. <br /><br /></p>

                        <p><b>Mawsmai Cave</b> :<br />
                          <b>Location:</b> Cherrapunji, Meghalaya 793108 <br />
                          Located 6 km from the Sohra (Cherapunjee) market to the south of the village of Mawsmai in the direction of the Bangladesh border, this is one of prime attractions for <a href="/kaziranga-shillong-cherrapunjee-package-tour-from-guwahati" target="_blank"><strong>Kaziranga Shillong Cherrapunjee Package Tour</strong></a>. One need to do a moderate walk to get into the entrance point of the cave. Inside the cave, you will find it illuminated, and it is the only lighted cave in India. This limestone cave is having two parts (old and new). Of the two, the new cave is yet to be lighted. It has impressive formations of large passages and chambers. <br /><br />
                          The cave is open 7 days in a week and can be explored between 9.30am to 5.30pm. It is strictly instructed to leave the cave before 5.30PM. The best time to visit Mawsamai Cave is between end of October to first half of June. During this time tourists from different parts of the world throng to explore this nature made wonder.<br /><br /></p>
                        <div id="no-more-tables">
                          <table className="col-md-12 table-bordered table-striped table-condensed cf">
                            <thead className="cf">
                              <tr>
                                <th>Entry for</th>
                                <th>Ticket Cost</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Adult</td>
                                <td>Rs. 20/Person</td>
                              </tr>
                              <tr>
                                <td>Child below 10 years</td>
                                <td>Rs. 20/Person</td>
                              </tr>
                              <tr>
                                <td>Adult</td>
                                <td>Rs. 5</td>
                              </tr>
                              <tr>
                                <td>Ticket Charges for Group less than 3 members</td>
                                <td>Rs. 60</td>
                              </tr>
                              <tr>
                                <td>Still Camera</td>
                                <td>Rs. 20</td>
                              </tr>
                              <tr>
                                <td>Video Camera</td>
                                <td>Rs. 50</td>
                              </tr>
                              <tr>
                                <td>Film Shooting Camera</td>
                                <td>Rs. 500</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br /><br />
                        <p><b>Nohkalikai Falls</b> :<br />
                          <b>Location:</b> East Khasi Hills, Meghalaya, India.<br />
                          What comes in our mind when we think of <a href="/north-east-india-package-tour-from-guwahati-airport" target="_blank"><strong>North East Package with Meghalaya</strong></a> is this gorgeous waterfall. This hauntingly beautiful waterfall, cascading down from the top of the gorge to the mystic deep green pool below, is one of the tallest plunge waterfalls (340m) in India located near Cherapunjee, one of the wettest place on Earth. It is reminding one of the tragic legends associated with it, of a grief-stricken mother who plunged to her death, unable to overcome her sorrow over the murder of her daughter by her husband. Behold the breathtaking beauty of this waterfalls which is no match with other falls in entire North East. <br /><br />
                          If time permits, we can cover many other attractions in Cherrapunji also like <b>Arwah Caves</b>, <b>Wakaba Falls</b>, <b>Eco Park</b> etc. In the evening return back to hotel and relax for your next day trip. <br /><br /></p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay3ImageURL} alt="Perfect Bhutan B2B packages for Tour Operators" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Perfect Bhutan B2B packages for Tour Operators" /></center>
                        </div>
                        <br /><br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day4"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFour"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 04: - Day Excursion trip to Mawlynnong and Dawki & Transfer to Shillong </h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseFour"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFour"
                    >
                      <div className="accordion-body">
                        <p>Wake up early and have your breakfast and drive to <b>Mawlynnong</b>. Today you can explore the cleanest village Mawlynnong in the world. You can take the sky walk. Spectacular view of the living Root Bridge. <br /><br />
                          After lunch, drive to <b>Dawki</b>. Dawki is the gateway to Bangladesh where major export and import transactions take place. It is a splendid drive of 45 kilometres passing through deep gorges and ravines, which is certainly a thrilling experience. On approaching Dawki is the Umngot river, The Umngot river is the natural boundary between Ri Pnar or Jaintia Hills with HimaKhyrim of Khasi hills over which a single span suspension bridge was constructed. Evening drive back to Shillong. - <br /><br />

                          <b>MAWLYNNONG VILLAGE :</b><br /><br />
                          <b>Shillong to Mawlynnong Distance :</b> 2 hr 26 min (78.1 km) via NH206 <br />
                          <b>Altitude :</b> 475m / 4906ft <br />
                          <b>Location :</b> 25.2018&#176; N, 91.9160&#176; E <br />
                          <b>Population :</b> 900 (2019) <br />
                          <b>Weather :</b> 27&#176; C, Wind SE at 0 km/h, 83% Humidity <br />
                          <b>District :</b> East Khasi Hills <br />
                          <b>Block :</b> Pynursla <br /><br />

                          Located in East Khasi Hills in the state of Meghalaya, this village was awarded as the <strong>Cleanest Village in Asia</strong> in 2005 by Discover India Magazine. The village is too clean to find a single piece of leaf or garbage anywhere. Plastic bags and plastic materials are strictly banned to use in the village. If you are fond of smoke or cigar, leave your plan to puff of cigarette here. It is strictly prohibited here and the villagers are very strict to it. The entire village is very clean and beautiful.
                          For the beautification, villagers decorate their home with beautiful all season colorful flowers and take care of flower plants on daily basis. In fact it is a part of their daily life. Travel these beautiful attractions of North East with NatureWings special <strong>North East Tour Package with Meghalaya</strong>, we bet, it will be your lifetime experience. <br /></p>
                        <p><b>DAWKI RIVER :</b><br /><br />
                          <b>Popularly Known as :</b> Wah Umngot <br />
                          <b>Dawki River Coordinates :</b> 25.1996&#176; N, 92.0206&#176; E <br />
                          <b>Origination :</b> Eastern part of Khasi Hills of Meghalaya, India <br /><br />

                          After spending quality time at the Mawlynnong Village, start for <strong>Dawki River Tour</strong>. The river is also known as Umngot River situated about 82kms south of Shillong. A roughly 2 and half hour drive from Shillong will take you to this beautiful place between the Kashi and the Jaintia Hills. It is one of the most spectacular destinations that you can visit during your <strong>Shillong Meghalaya Package Tour</strong>. <br /><br />
                          Dawki is the gateway to Bangladesh, where export and import transactions take place. Mawlynnong to Dawki is a splendid 1 hr drive (30km) passing through deep gorges and ravines, which is a thrilling experience. Dawki or the Umngot River is the natural boundary between Ri Pnar and Jaintia Hills with Hima Khyrim of Khasi hills over which is a single-span suspension bridge was constructed. Evening drive back to Shillong city. Overnight Stay at Shillong. <br /><br /></p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay4ImageURL} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day5"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFive"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 05: Excursion towards Mawsynram (Wettest place on Earth) </h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseFive"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFive"
                    >
                      <div className="accordion-body">
                        <p>After a breakfast drive towards Mawsynram, Enroute visits Elephanta Falls & Shillong Peak (Depending on Army Permission) situated on the outskirts of the city. Visit Mawphlang Sacred Forest, Mawjymbuin Cave (Mawsynram cave). <br /><br />
                          After sightseeing in the evening back to Shillong, visit Cathedral Cathotic Church - In the heart of the city, there stands a beautiful cathedral of Roman Catholics dedicated to Mary Help of Christian. Overnight stay at Shillong. <br /><br />

                          <b>MAWSYNRAM :</b><br /><br />
                          Mawsynram, which is renowned for being the wettest area on Earth, continues to be a favourite among those who enjoy taking in the calming, green scenery and magnificent sceneries during the monsoon season. Despite frequent downpours, Mawsynram continues to draw visitors who yearn to spend some alone time in the great outdoors while savouring the monsoon illusion. In fact, Mawsynram, the rain resort, is the perfect place to experience the beauty of rain while getting drenched from a new downpour. The pair can get snug while enjoying the rain with a cup of hot tea and pakoras during the monsoon because the temperature is still favourable. The soggy location is not only for the contented couples, but also for the singles and the lazy guys who enjoy to sleep on a warm bed with a blanket over them and listen to the lovely sound of rain as it falls on windows and makes a musical affair. Apart from the possibility that travelling during the monsoon season could result in additional savings on your <b>Mawsynram Package Tour</b>, seeing the beauty of the monsoon in Mawsynram is emerging as a brand-new concept that has gained good popularity over time. When it rains, Mawsynram comes to life with clean, lush green scenery that is incredibly relaxing to the eyes and that helps to rest and revitalise your soul in the midst of the blossoming wild, away from the busy metropolis.
                          Everyone has fond memories of rainy days from their youth, and Mawsynram is the perfect venue to relive those times while listening to some timeless tune like - <br /><br />
                          "Rim-Jhim Gire Saavan, <br />
                          Sulag Sulag Jaae Mann, <br />
                          Bheege Aaj Is Mausam Mein, <br />
                          Lagi Kaisi Ye Agan" - by Kishore Kumar <br /><br />
                          So, all the monsoon lovers out there plan your <strong>Assam Meghalaya Arunachal Pradesh Package Tour</strong> in this wettest part of India and enjoy the serenity and sound of nature from a tiring everyday routine while relaxing by the window side of your room and getting intoxicated with the smell of fresh mud blended with cool breeze after every shower. <br /><br /><br /></p>

                        <div className="content-desktop">
                          <center><img src={navDay5ImageURL} alt="Bhutan B2B Package Tour Operator" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="Bhutan B2B Package Tour Operator" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day6"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSix"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 06: Shillong To Bhalukpong Transfer [6 hr 20 min (298.5 km) via AH1/AH2]</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSix"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSix"
                    >
                      <div className="accordion-body">
                        <p>After breakfast drive to Bhalukpong. Shillong to Bhalukpong is almost 6 hrs journey via road. This place is in the border of the states of Assam and Arunachal Pradesh and is a place of enthralling scenic beauty. The sparkling river <b>Jia Bharali</b> which passes through Bhalukpong is an adventure lover's paradise. By the side of the river is the<b> Nameri National Park</b> which falls under Project Tiger. <br /><br />
                          After reaching, you can go for adventurous river rafting in Kameng River which is also known as Jia Bharali River. Evening is free for leisure. You can stroll around, enjoy camp fire beside the river bed, do some group activity, antakshari game and picnic etc. Overnight Stay at Bhalukpong / Nameri / Tezpur. <br /></p>
                        <br /><br />
                        <div className="content-desktop">
                          <center><img src={navDay6ImageURL} alt="B2B Tour Operator for Bhutan" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="B2B Tour Operator for Bhutan" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day7"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 07: BHALUKPONG TO DIRANG TOUR [4 hr 7 min (138.6 km) via Chariduar - Tawang Rd]</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>In the morning after breakfast you can enjoy <strong>River Rafting at Nameri</strong> (subject to availability). Later transfer to Dirang (200km 7hrs). En-route visit Tipi Orchidarium, a glass house with over 7500 Orchids. <br /><br />
                          Dirang is an ideal getaway in West Kameng district with beautiful apple orchards and sheep breeding farms. The entire place is surrounded by apple and kiwi orchards and the atmosphere is picturesque. After a long 5hrs long journey and some en route sightseeing activity, you must be tired. So after reaching Dirang, take rest, then you can start exploring the places around.<br /><br /></p>

                        <p><b>National Research Centre on Yak</b><br />
                          Located in the West Kameng District, Dirang, Arunachal Pradesh 790101, this centre was set up in 1989 by the Indian Council of Agricultural Research in Dirang. This is the National Research Centre on Yak which is working to the overall improvement of Yak Farming in India. It is a popular tourist destination among travelers for <strong>Arunachal Package Tour</strong>.<br /><br /></p>

                        <p><b>Dirang Dzong</b><br />
                          This is basically a fort built in 7th-century to protect Bomdila from outside invasions. This fort was used as a prison and refuge centre during the World War & Vietnam War. Set among the pristine forestry area, this Dzong is adorned with appealing & intricate designs and a must visit place during <strong>Arunachal Tour</strong>.<br /><br /></p>

                        <p><b>Sangti Valley</b><br />
                          Just 7 km away from Dirang, is a place with awesome beauty. During winter Black-necked cranes from China migrates here. These birds are known as "<b>tung tung ka uk</b>" to the local people which is one of the attraction of this pristine valley.<br /><br /></p>

                        <p><b>Kalachakra Gompa</b><br />
                          This is a 500 years old monastery that follow and practices Buddhism. This is place with complete serenity and visited by nature lovers.<br /><br /><br /></p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day8"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 08: DIRANG - TAWANG TOUR [4 hr 36 min (134.5 km) via NH13]</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>In the morning after breakfast visit the <b>Kiwi & Apple Gardens</b>. After viewing the <b>beautiful valley of Dirang overlooking the river and inhabited by colorful tribes</b>, drive for <strong>Tawang Tour</strong> (10,000 ft) crossing Sela Pass (13720 ft.) which runs through a small ridge and along the paradise lake. Stop to enjoy the Sella Pass and Paradise Lake. From the Sela pass road winds down to Jong Village. On the way to Jong visit <b>Yashwant Garh War Memorial</b>. After lunch at Jong Village starts for <strong>Tawang</strong>. On the way visit <b>Nuranang Falls</b>. Located in the Tawang district of Arunachal Pradesh, this is a spectaclar 100m high water falls which is a prime attractions during <strong>Arunachal Pradesh Tour</strong>. Overnight at Hotel in Tawang.<br /><br /></p>

                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day9"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 09: TAWANG MONASTERY TOUR [2km from Tawang Town]</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>This is the 9th day of our <strong>Arunachal Tour</strong>. Today in the morning after breakfast we will start to explore <b>Tawang Monastery</b>. The Monastery was established in the 15th century (in the year 1680-81) by <strong>Merag Lodroe Gyamtso</strong> - a contemporary of the fifth <strong>Dalai Lama</strong>. It is the second biggest and oldest Monastery in Asia. This monastery also known as the "<strong>Tawang Ganden Namgyal Lhatse</strong>" i.e. the celestial paradise of divine site chosen by the horse. <br /><br />
                          Tawang is called - <b>The Land of Dawn lit Mountains</b>. Situated at an altitude of 3,048m, Tawang gives you an unparallel view of the majestic mountain with pure beauty. This monastery is one of the largest Lamaseries of Mahayana sects in Asia and is the largest of its kind in India. Tawang Monastery is almost 400 year old and accommodates over 500 monks and houses priceless Buddhist texts and scriptures. Tawang Monastery is blessed with an 18 feet high statue of Lord Buddha situated at 10,000ft among the exotic Himalayan mountain ranges overlooking the Tawang-Chu valley. Stroll every nook and corner of this fabulous monastery and experience its grandeur. The Tibetan influence here is unmistakable. <br /><br />
                          Later we will visit the <b>War Memorial</b> commemorating the bravery of the Indian Soldiers of the <b>Indo-China war of 1962</b>. Witness the light & sound show in the evening. Overnight at Hotel in Tawang.<br /><br /></p>

                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day10"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 10: TAWANG LOCAL SIGHTSEEING TOUR [PT Tso Lake / Bumla Pass]</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>Today post breakfast we will visit some the best attractions in Tawang. First we will visit - <br /><br /></p>

                        <h4><b>PT Tso Lake:</b><br /></h4>

                        <p>Locally Known as: Penga Teng Tso Lake <br />
                          Altitude: 12,000 feet <br />
                          Location: 20 km from Tawang <br />
                          Best Time to Travel Pt Tso Lake: April to October <br />
                          Things to do in Pt Tso Lake: Awesome beauty to behold + Skiing in winter <br /><br />
                          Located just 20km from Tawang City above 20,000 feet, this is a picturesque lake surrounded by the jaw dropping beauty of Mother Nature. Adorned with snow clad Himalayan mountain peaks and setting amongst the cool & tranquil ambience, this is a major tourist attraction in Tawang. The steady calm water of the lake, the cool breezes, the meandering clouds, the fluffy snow covered surroundings and pin drop serenity will fill your mind and soul with peace. Spend some quality time beside the lake and listen the sweet chirping of birds will glide you in to a different world. Only after visiting this mesmerizing lake, you will realize that your <strong>Arunachal Trip</strong> is a success from all aspect. <br /><br /><br /></p>

                        <h4><b>Bumla Pass:</b><br /></h4>

                        <p>Elevation: 4,633 m <br />
                          Location: 37 Km from Tawang District (Arunachal Pradesh, India) <br />
                          Range: Himalayas <br /><br />

                          Located at an elevation of 15,200 feet or 4,633 meter above the sea level & 37 Km from Tawang, the Bumla Pass which lies between Indo-China borders, is a prominent attraction of <strong>Arunachal Pradesh Package Tour</strong>. This is the path from where <strong>His Holiness the 14th Dalai Lama</strong> entered India to take a refuge. <br /><br />
                          This place has strategic importance also. As it is the border point, a heavy deployment of military personal or Indian Army Jawans can be seen on the way to and from the pass. The pollution free weather is chill, minus degrees, snowing, bumpy roads and extremely cold between November to March. Very hard to survive this extrem temperature and we wonder how our Army Jawans are protecting our territories in this the harsh environment. Unless you see and experience it, it is hard to belief. After watching our soldiers that how they are protecting our Mother Land in this nerve-wracking weather condition, a respect for them and the feeling of patriotism instantly grows up. <br /><br />
                          To visit Bumla pass you have to get permit from the Office of the <strong>Deputy Commissioner in Tawang District</strong> and then <strong>Indian army cantonment of Tawang</strong>. The best time to visit here will be summers – end of June to October. <br /><br /></p>
                        <p>If time permit, we will try to cever Sangetsar Lake which is also one of the prime attractions of Tawang, Arunachal <br /><br />
                          <br /><br /></p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day11"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 11: TAWANG TO BOMDILA TRANSFER [5 HR 31 MIN (176.7 KM) VIA NH13]</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p><b>Location :</b> Bomdila <br />
                          <b>Elevation :</b> 2,415 m <br />
                          <b>District :</b> West Kameng <br />
                          <b>Coordinates :</b> 27.2645&#176; N, 92.4159&#176; E <br /><br /></p>

                        <p>After Breakfast proceed to Bomdila. Bomdila is a beautiful town located just 175km or 5 hrs journey from Tawang. With snow-capped mountains, lush green scenic valleys, dense forests around, orchids and orchards scattered around, Bomdila a boon for nature lovers. Miles away from the modern chaotic world, you will find absolute peace and tranquility while you will be in this place. Following are some of the best places which you can experience during your Bomdila Tour from Tawang. <br /><br /></p>
                        <p><b>Bomdila Monastery</b> - Established in the year 1965, this monastery resembles Tsona Gontse Monastery of South Tibet. This monastery is a sacred place and has spiritual essence of Buddhism. The temple of Buddha in side of the monastery attracts pilgrims in a large numbers. <br /><br /></p>
                        <p><b>Upper Gompa</b> - Known as Gentse Gaden Rabgyel Ling Monastery, it is a school to educate monk kids. This monastery is a symbol of traditional Buddhism and attracts lots of tourists and pilgrims. <br /><br /></p>
                        <p><b>Middle Gompa</b> - Located close to the main market in Bomdila, this is one of the oldest Gompas in the town. This Gompa is best known for the Blue Medicine Buddha. <br /><br /></p>
                        <p><b>Lower Gompa</b> - One of the sacred Gompha located in the middle of the town best known for its preserved traditions and a large prayer hall. This is one of the must visit place for tourist and pilgrims. <br /><br /></p>
                        <p><b>Tipi Orchidarium</b> - Best known to have the largest variety of Orchid species found in India. The Orchidarium has over 50,000 varieties including some rarest species found anywhere in the world. So this is also a favorite tourist destination among travelers in Arunachal. <br /><br /></p>
                        <p><b>Apple Orchards</b> - If you want to pluck fresh apples from the apple trees and enjoy a juicy bite, then travelling this orchards between September to October is best. You will fall in love with the place full of apple trees laden with apples in a large no. The apples here are so fresh, sweet and so cheap that you can’t resist yourself to buy a few Kgs. <br /><br /></p>

                        <p>Apart from that, you can explore places like - <br />
                          Bomdila View Point / Sessa Orchid Sanctuary / R.R. Hill / Eaglenest Wildlife Sanctuary / Dirang Valley / Craft Centre And Ethnographic Museum <br /><br /></p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day12"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 12: BOMDILA TO NAMERI [3 hr 25 min (122.7 km) via Chariduar - Tawang Rd]</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p><b>Address :</b> Sonitpur, Guwahati, Assam 784001 <br />
                          <b>Area :</b> 200 km<sup>2</sup> <br />
                          <b>Established:</b> 15 November 1998<br /><br /></p>

                        <p>After breakfast check out from your hotel in Bomdila and proceed for <strong>Nameri National Park</strong>, Assam famous for Tiger Reserve. <b>Nameri National Park and Forest Reserve</b> is located in the foothills of Himalaya's in the Sonitpur District of Assam. Nameri is one of the famous National Parks of India for Bird Lovers and Wildlife Photographers. Reach there, take rest. Later you can proceed for Rafring or Fishing or Jungle Walking. Evening is free for leisure so spend the time your own having bonfire in the river bank or enjoying grilled chicken in bonfire. Overnight Stay at Nameri National Park.<br /><br /></p>
                        <div id="no-more-tables">
                          <table className="col-md-12 table-bordered table-striped table-condensed cf">
                            <thead className="cf">
                              <tr>
                                <th>From</th>
                                <th>To</th>
                                <th>Distance</th>
                              </tr>
                            </thead>
                            <tbody><tr><td>Guwahati</td><td>Nameri</td><td>4 hr 35 min (217.3 km) via NH 27</td></tr>
                              <tr><td>Tezpur</td><td>Nameri</td><td>56 min (36.5 km) via NH 15 and Balipara - Bhalukpung Rd</td></tr>
                              <tr><td>Bomdila</td><td>Nameri</td><td>3 hr 25 min (122.7 km) via Chariduar - Tawang Rd</td></tr>
                              <tr><td>Dirang</td><td>Nameri</td><td>4 hr 35 min (164.9 km) via Chariduar - Tawang Rd</td></tr>
                              <tr><td>Sangti Valley</td><td>Nameri</td><td>4 hr 52 min (172.3 km) via Chariduar - Tawang Rd</td></tr>
                              <tr><td>Itanagar</td><td>Nameri</td><td>3 hr 48 min (153.4 km) via NH 15</td></tr>
                              <tr><td>Alipurduar</td><td>Nameri</td><td>9 hr 28 min (436.2 km) via NH 27 and NH 15</td></tr>
                            </tbody>
                          </table>
                        </div>
                        <br /><br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day13"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 13: NAMERI NATIONAL PARK TO ITANAGAR [3 hr 48 min (153.4 km) via NH 15]</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>Morning after breakfasts enjoy river rafting on the sparkling Jia Bharali River which passes through Nameri National Park. This park is adventure lover's paradise. You can also try angling in the river. After spending quality time in Nameri, Later drive to Itanagar. Itanagar is the capital of the Indian state of Arunachal Pradesh. Itanagar is situated at the foothills of Himalayas. After reaching Itanagar check in a city hotel. Evening is free for leisure. Overnight Stay at Itanagar.<br /><br /></p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day14"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 14: ITANAGAR TO ZIRO [4 hr 31 min (109.5 km) via NH13]</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>This is your 14<sup>th</sup> day of <strong>Assam Meghalaya Arunachal Pradesh Package Tour</strong>. Today in the morning after breakfast, we will visit Ganga Lake and Buddha Temple.  Later transfer to Ziro. <br /><br />
                          Ziro is a beautiful town and hill station located in the lap of eastern Himalaya in Lower Subansiri district in the Indian state of Arunachal Pradesh. The beauty of this hill station is enough to make you awe with its breathtaking beauty. This place is the home town to the famous <strong>Apatani Tribe</strong> - one of the major ethnic groups of eastern Himalayas with distinctive culture and tradition.<br /><br />
                          Ziro is famous for its annual music festival that is also the biggest music festival in the state of Arunachal  Pradesh and drags lots of visitors, young music enthusiast and music lovers. A trip to Ziro is more enough to spellbind every visitor with its alluring grandeur adorned by Mother Nature. With pleasant summer and cool winter, this place can peace your heart and rejuvenate your mind from inner core. <br /><br />
                          <span><b>TIPS </b>:</span> To travel Ziro, you need to produce an Inner Line Permit (ILP) for Indians and a Protected Area Permit (PAP) for foreigners. <br /><br /></p>

                        <h4><b>Places to Visit in Ziro:</b><br /><br /></h4>

                        <p><b>Talley Valley Wildlife Sanctuary</b> - Just 16 km from Ziro, this sanctuary is home to various endangered species of plants, animals and insects like leopards, butterflies, silver fir trees, Pleioblastus Simone - a kind of rare bamboo species and varieties of ferns. <br /><br />
                          <b>Shiva Lingam at Kardo Forest</b> - Just 4 km away from Hapoli Township, this is a 25 ft tall and 22 ft wide Shiva Lingam that was discovered at Kardo forest. Large number of devotees throngs here every day. This is a must visit place in Ziro. <br /><br />
                          <b>Meghna Cave Temple</b> - This is a majestic cave temple situated at an altitude of 300 ft. This place is surrounded by dense forest, lush greenery and stunning view of the valley. <br /><br />
                          <b>Kile Pakho</b> - Situated 7 km from old Ziro, this is an amazing place for nature lovers. From here one can behold a panorama of entire Ziro plateau. <br /><br />
                          <b>Tarin Fish Farm</b> - Located at a distance of approx 3 km from Hapoli Town, this place is famous for practicing the tribe Apatanis method of indigenous farming for breeding high altitude fish. This place is very famous among Ziro valley travelers. <br /><br /><br /></p>

                        <h4><b>How to Reach Ziro?</b><br /></h4>
                        <p>Ziro can be travelled in various ways : <br /><br /></p>

                        <ul><li>Ziro can be travelled from Jorhat Airport <br /></li>
                          <li>You can trvel Ziro from Tezpur Airport also <br /></li>
                          <li>Ziro can be visited from Guwahati Airport by road <br /></li>
                          <li>By train, Ziro can be travelled from Kathal Pukhuri Station, Naharalagun Station and North Lakhimpur Station <br /></li>
                          <li>With a flight from Guwahati to Lilabari Airport, we can travel to Ziro <br /></li></ul>
                        <div id="no-more-tables">
                          <table className="col-md-12 table-bordered table-striped table-condensed cf">
                            <thead className="cf">
                              <tr>
                                <th>From</th>
                                <th>To</th>
                                <th>Distance</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Jorhat Airport </td>
                                <td>Ziro</td>
                                <td>7 hr 42 min (190.2 km) via NH13</td>
                              </tr>
                              <tr><td>Tezpur Airport</td><td>Ziro</td><td>8 hr (270.6 km) via NH 15 and NH13</td></tr>
                              <tr><td>Guwahati Airport</td><td>Ziro</td><td>12 hr 11 min (444.2 km) via NH 15</td></tr>
                              <tr><td>Kathal Pukhuri Station</td><td>Ziro</td><td>4 hr 19 min (117.3 km) via NH13</td></tr>
                              <tr><td>Naharlagun Station </td><td>Ziro</td><td>3 hr 46 min (95.1 km) via NH13</td></tr>
                              <tr><td>North Lakhimpur Station</td><td>Ziro</td><td>4 hr 30 min (119.2 km) via NH13</td></tr>
                            </tbody>
                          </table>
                        </div>
                        <br /><br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day15"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 15: FULL DAY ZIRO VALLEY SIGHTSEEING</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>Early morning after breakfast visit to the village of Apatani Tribe and experience their lifestyle, culture in the valley. Enjoy some <b>cultural events of Apatani Locals</b>. Also, taste Tapyo salt - a unique herbal salt prepared by Apatani people which makes the Apatani distinct from the other tribal communities of the Arunachal Pradesh.<br /><br />
                          The other major tourist attractions of Ziro include Paddy cum fish culture, Tarin Fish Farm and Ziro Putu. Later in the day, visit Talley valley for its diverse flora and fauna ranging from sub-tropical to alpine forests. Overnight Stay at Ziro. <br /><br /></p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day16"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 16: ZIRO TO ITANAGAR (110 KM / 4 HRS)</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>After breakfast bid adieu the beautiful Ziro and start for Tezpur. As it could be a long journey, almost 280km long, so can take 5 to 6 hrs depending upon the road traffic and weather condition. It is advised to start early from Ziro. Lunch will be on the way. Then drive to Itanagar. Evening is free for leisure. Overnight Stay at Itanagar. <br /></p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day17"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 17: ITANAGAR - KAZIRANGA NATIONAL PARK [5 hr 15 min (195.5 km) via NH715]</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>After breakfast from Tezpur drive to Kaziranga National Park. From Yezpur, Kaziranga is almost 50km and can be travelled within 1.30hrs. Kaziranga is UNESCO World Heritage Site (declared in 1985) inhabited by the world's largest population of one-horned rhinoceroses, as well as many mammals, including tigers, elephants, panthers and bears, and thousands of birds. As per census 2018 by the Forest Department of Assam Government, there are now approx 2400 rhinos which are the prime attraction of this park.<br /><br />
                          On arrival check in your pre booked resort or hotel and refresh. This day is free for leisure. Post lunch you can visit nearby area of the park, or you can visit <strong>Kaziranga National Park Orchid and Biodiversity Park</strong> explore local people, their life style, try local Assamese food like Pitha made with black rice and jaggery, Peda, and most importantly <strong>Assamese Pan</strong>. In the evening you can arrange <strong>Karbi Bamboo Dance</strong> in the resort or bon fire with grill chicken and enjoy the night with songs and dance. Overnight Stay at Hotel / Resort at Kaziranga National Park. <br /><br /></p>
                      </div>
                    </div>
                  </div>
                  {/* problem */}
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day18"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 18: KAZIRANGA SAFARI [ELEPHANT + JEEP]</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>After early wake-up (6 am) in the morning, we will take you to the riding point for <strong>Kaziranga Elephant Safari</strong> in the Western Range which is known as <strong>Bagori Range</strong>. It is approx one hour safari and in a day two times it happens. If the demand is heavy due to tourist influx, an extra safari can be arranged later. First Elephant Safari starts at 6.30am and second at 8.30am and 4 to 6 elephants together depart for jungle safari. <br />
                          Point to be noted that Bagori Range is best for Elephant Safari and there is no <strong>Elephant Safari in Kaziranga National Park</strong> in the evening. <br /><br />
                          Let's proceed for your much awaited elephant safari on the back of trained elephant which generally carry 4 guests at a time with Mahoot. Soon after the safari start, you can watch the famous One-Horn Rhino within the jungle from stone throwing distance. If your luck favors, you can view other animals also like hog deer, water buffalo, wild boar, spotted deer and even tiger also. Explore the safari at its best. Enjoy the wild beauty at proximity like never before.<br /><br />
                          After the elephant safari, return to the Resort. Relax and freshen up. After lunch, drive to Kaziranga Central Range or <strong>Kohra Range</strong> for the <strong>Kaziranga Jeep Safari</strong>. The Central zone of the Kaziranga National Park is the prime zone for ecotourism in the Kaziranga forest. The entry gate to the central zone is situated just about two km from the National Highway passing through the village of Kohora. The <strong>Kaziranga Jungle Safari</strong> offers you some spectacular sightings of the Rhinos grazing or wallowing in the large grassland, a herd of elephants with the adorable baby elephants to big old elephants in the herd, and many other species of the wild flora and fauna of this tourism zone. Post safari, on the way returns to hotel you can visit local tea garden. Can experience tea leaves collecting and take some pictures in the tea garden. Evening is free for leisure. You can arrange Bihu Dance Show in the resort and shake your legs too with the dancers. Exhausted after day long activity, let’s go to sleep after dinner. <br /><br /></p>
                        <h4><strong>Kaziranga Elephant Safari Price</strong> - [Kohora Range / Bagori Range]<br /></h4>
                        <div id="no-more-tables">
                          <table className="col-md-12 table-bordered table-striped table-condensed cf">
                            <thead className="cf">
                              <tr>
                                <th>Citizen</th>
                                <th>Ranges</th>
                                <th>Rate/hour</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Indian</td>
                                <td>Western Range (Bagori)</td>
                                <td>INR 1000</td>
                              </tr>
                              <tr>
                                <td>Foreigner</td>
                                <td>Central Range (Kohora)</td>
                                <td>INR 2100</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br /><br />
                        <h4><strong>Kaziranga Jeep Safari Price</strong> - [Kohora Range / Bagori Range / Agaratoli Range]<br /></h4>
                        <div id="no-more-tables">
                          <table className="col-md-12 table-bordered table-striped table-condensed cf">
                            <thead className="cf">
                              <tr>
                                <th>Range</th>
                                <th>Vehicle</th>
                                <th>Entry Fee/Indian</th>
                                <th>Entry Fee/Foreigner</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Central Range (Kohora)</td><td>INR 2700 (Incl Road Toll)</td><td>INR 100</td><td>INR 650</td>
                              </tr>
                              <tr>
                                <td>Western Range (Bagori)</td>
                                <td>INR 2800 (Incl Road Toll)</td>
                                <td>INR 100</td>
                                <td>INR 650</td>
                              </tr>
                              <tr>
                                <td>Eastern Range (Agaratoli)</td>
                                <td>INR 3200 (Incl Road Toll)</td>
                                <td>INR 100</td><td>INR 650</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br /><br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day19"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 19: KAZIRANGA - MAJULI [4 hr 31 min (151.0 km) via NH715]</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>data goes hereMorning after breakfast we will proceed to Jorhat. En route we will cross the<b> Namdang Stone Bridge (The Namdang bridge, cut out from a single solid rock over the Namdang river, was constructed by king Rudra Singha in 1703)</b>. We will reach Nimati Ghat and take a Ferry to Majuli. After approximately one and half hours of sailing we will arrive at Majuli Island Majuli has been the cultural capital and the cradle of Assamese civilization for the past five hundred years. <br /><br />
                          After Reaching Majuli we visit Sri Sri Auniati Satra founded by Niranjan  Pathak Deva. The satras are famous for the "Paalnaam" (Monastery Prayer) and Apsara Dances (Dance of the Angels) and also its extensive assortment of ancient Assamese artifacts, utensils, jewellery and handicrafts and Natun Samaguri Satra (Shamaguri Satra is a unique feature of Vaishnavism in Assam). After exploring these places enriched with rich tradions and culture, we will proceed to the hotel for night stay. <br /><br /><br /></p>

                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day20"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 20: MAZULI - JORHAT [TOUR ENDS]</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>After breakfast Check out from the hotel and transfer to Jorhat Airport [JRH] / Jorhat Town Railway station for the onward journey. <br /><br /></p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />

              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> HOTELS ADORABLE PROVIDE FOR <strong>MEGHALAYA PACKAGE TOUR WITH GARO HILLS</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> HOTELS ADORABLE PROVIDE FOR <strong>MEGHALAYA PACKAGE TOUR WITH GARO HILLS</strong></h4>
                </div>
                <div className="card-body">
                  <h4>4 Star Hotels :</h4><br />
                  <p><b>Guwahati</b> - Gateway Grandeur</p>
                  <p><b>Shillong </b> - Hotel Polo Tower</p>
                  <p><b>Cherrpunjee</b> - Polo Orchid Resort<br /><br /></p>

                  <h4>3 Star Hotels :</h4><br />
                  <p>
                    <b>Guwahati</b> - Lilawati Grand</p>
                  <p><b>Shillong </b> - Centre Point, Blue Berry Inn, Eee Cee Hotel</p>
                  <p><b>Cherrpunjee</b> - Cherrapunjee Holiday Resort, Kutmadam Resort<br /><br /></p>

                  <h4>2 Star Hotels :</h4><br />
                  <p><b>Guwahati</b> - Hotel Siroy Liya, Hotel Siroy Classic</p>
                  <p><b>Shillong </b> - Hotel Barbareek,  Season Rate, J K Interanational</p>
                  <p><b>Kaziranga</b> - Wild Grass Lodge, Dhanshree Resort</p>
                </div>
              </div>
              {/* <!--end of package details--> */}
              <br /><br />
              {/* <!--insert table inclusion exclusion--> */}
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    {
                      <InclusionExclusion />
                    }
                  </div>
                </div>
              </div>
              {/* <!--end table inclusion exclusion--> */}
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> TRAVEL SHILLONG - THE SCOTLAND OF EAST</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> TRAVEL SHILLONG - THE SCOTLAND OF EAST</h4>
                </div>
                <div className="card-body">
                  <p>Shillong is an astonishing hill station in North East Indian State of Meghalaya literally known as the <em>"Abode of the Cloud"</em>. We know Kashmir as the Paradise on Earth. Mughal emperor Jahangir once said - <em>"Agar Firdaws ba roy-i zamin ast, hamin ast-u hamin ast-u hamin ast"</em> means "if there is a heaven on earth, it's here, it's here, it's here". Like that Shillong is known as - <em>"The Scotland of the East"</em> for its enchanting and captivating natural beauty. <br /><br />
                    For decades the land was dominated by Garo, Khasi, and Jaintia tribes. For a long it was a prime hill station for Britishers and after independence Meghalaya merged with the Indian union on 21st Jan 1972. In this <strong>Kaziranga Shillong Package Tour</strong>, travelers will visit some of its best tourist attractions like : <br /><br />
                    <b>Shillong Peak</b> - Located at an altitude of 1,965m, it is the highest point of Meghalaya providing an astonishing view of Shillong. <br /><br />
                    <b>Shillong Cave</b> - Meghalaya has India's longest cave system. Out of the 10 deepest caves in India, Meghalaya has 9 of them. Even some of the caves are still unexplored. So come explore the unexplored with your <strong>Kaziranga Shillong Cherrapunjee Package Tour</strong> from NatureWings. Some of the notable caves are: <br /><br />
                    <b>Mawjymbuin Cave</b> - a popular destination for adventure lovers, trekkers, geologists, and explorers. <br /><br />
                    <b>Mawsmai Cave</b> - a limestone caves just 6 km from Cherrapunji is the most crowed puller and vibrant enough to give traveler or explorer spellbound with its classic formation.<br /><br />
                    <b>Krem Mawmluh</b> - It is the fourth-longest cave in the Indian subcontinent with a 7 km stretch. The cave has waist-deep pools, massive caverns, several meters of belly crawl, and a few meters of climb. Most of the part remains in darkness hence guides are needed to explore this cave.<br /><br />
                    <b>Siju Cave</b> - Situated near Napak Lake and Simsang River, Siju Cave is one of the largest limestone caves in India stretching over 7 km long. With surprise in every corner and a knee-deep river, this cave is interesting to explore and greatly admired by trekkers and adventure lovers. A guide help is suggested to explore Siju Cave.<br /><br />
                    <b>Mawlynnong Village</b> - Located just 90km away from Shillong, this village is the cleanest village in India and in Asia also. In 2003 & 2005 Mawlynnong was awarded as the cleanest village in Asia by Discover India Magazine. You will not find single garbage or even dry leaves in any part of the village. The inhabitant of the village is too serious for the cleanliness so every morning the villager's together sweep to clean up the roads and surroundings and clean up every single piece of waste or dry leaves they found and dump it in the bamboo made basket.
                    Using or carrying plastic bags is banned and smoking for local villagers and travelers is strictly prohibited. Travel this amazing land of Meghalaya with our combined <strong>Shillong Kaziranga Tour Package</strong>.<br /><br /><br /><br /></p>
                </div>
              </div>
              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>Frequently Ask <span>Questions</span></h2>
                      <h4><strong>ASSAM MEGHALAYA ARUNACHAL PRADESH PACKAGE TOUR FROM GUWAHATI</strong> Trip Related Questions</h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion" id="accordionPanelsStayOpenExample">
                      <div className="accordion" id="accordionFlushExample">

                      <div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading1">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
      What are the 5 best things to do during a North East Trip?
    </button>
  </h4>
  <div id="flush-collapse1" className="accordion-collapse collapse show" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <ul>
        <li>Visit the Tibetan market in Tawang for souvenirs.</li>
        <li>Explore the Guwahati silk market for MUGA Silk Sarees.</li>
        <li>Take a boat ride on Umiam Lake, the largest man-made reservoir.</li>
        <li>Enjoy an evening cruise on the Brahmaputra River.</li>
        <li>Walk across the Chincham Bridge in Tawang.</li>
      </ul>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading2">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
      Things to carry for Assam, Meghalaya, Arunachal Pradesh Tour Package
    </button>
  </h4>
  <div id="flush-collapse2" className="accordion-collapse collapse show" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>Essentials include warm clothes, woolen caps, raincoat, gloves, water bottle, hiking shoes, dry fruits, biscuits, basic medicines, torch, power bank, and Swiss knife.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading3">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
      What is Ambubachi Mela?
    </button>
  </h4>
  <div id="flush-collapse3" className="accordion-collapse collapse show" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>Ambubachi Mela, held every June at the Kamakhya temple in Guwahati, is a major North East festival. Thousands of devotees gather as it’s believed that the Goddess Kamakhya goes through her annual menstrual cycle during this time, and the temple closes for three days. Visitors can still seek blessings nearby and later join auspicious events.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading4">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
      Can we avail Jain or Vegetarian food in Arunachal?
    </button>
  </h4>
  <div id="flush-collapse4" className="accordion-collapse collapse show" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>Yes, many restaurants offer pure vegetarian and Jain meals during trips across Arunachal, Meghalaya, and Assam. Mention your food preference during booking for arrangements.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading5">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
      Can we buy medicine from Tawang, Shillong, Cherrapunjee?
    </button>
  </h4>
  <div id="flush-collapse5" className="accordion-collapse collapse show" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>As these are remote areas, it's best to carry prescribed and general medicines since pharmacies may be hard to find along the journey through Arunachal, Assam, and Meghalaya.</p>
    </div>
  </div>
</div>
<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading1">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
      Do I need to carry high altitude medicines while traveling to Tawang, Arunachal Pradesh?
    </button>
  </h4>
  <div id="flush-collapse1" className="accordion-collapse collapse show" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>High altitude medicines aren't always necessary when visiting Arunachal Pradesh. However, to be safe, it's recommended to consult with your physician before traveling, especially to high-altitude areas like Tawang.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading2">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
      What are some offbeat destinations in Arunachal Pradesh?
    </button>
  </h4>
  <div id="flush-collapse2" className="accordion-collapse collapse show" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>Offbeat destinations in Arunachal Pradesh include Ziro, Mechuka Valley, Pasighat, and Aalo. These scenic places can make for a memorable addition to your itinerary.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading3">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
      How can I reach Arunachal Pradesh?
    </button>
  </h4>
  <div id="flush-collapse3" className="accordion-collapse collapse show" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>Arunachal Pradesh is approximately 350 km from Guwahati Airport. You can take a booked car to Itanagar, the state capital, or arrange transport through a travel agent.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading4">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
      How to reach the Single-Decker Living Root Bridge in Riwai?
    </button>
  </h4>
  <div id="flush-collapse4" className="accordion-collapse collapse show" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>The stunning Single-Decker Living Root Bridge in Riwai is accessible via the Shillong-Mawlynnong road, about a 75 km drive from Shillong. Once at Mawlynnong, a 1 km jungle trek will lead you to this natural wonder. Enjoy the scenery, take a dip in the streams, and consider staying in a nearby homestay for a peaceful retreat.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading5">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
      Is Kaziranga National Park worth visiting?
    </button>
  </h4>
  <div id="flush-collapse5" className="accordion-collapse collapse show" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>Kaziranga National Park, a UNESCO World Heritage site, is a must-visit for wildlife enthusiasts. Known for its endangered One-Horned Rhinoceros, Kaziranga also offers sightings of water buffalo, wild elephants, and a variety of birds, making it a thrilling jungle experience.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading6">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse6" aria-expanded="false" aria-controls="flush-collapse6">
      What food should I try during the Kaziranga Shillong Tour?
    </button>
  </h4>
  <div id="flush-collapse6" className="accordion-collapse collapse show" aria-labelledby="flush-heading6" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
    <p>Normally Assamese people preferred fish and rice, also they preferred boiled food. You can try local Assamese preparation like Laksa, Khar, Tenga, Mati Dal in your resort and hotels where you are staying. On the way to Kaziranga, tourists must try lunch at <b>Anurag Dhaba</b>, Veg Thali Rs. 250, non-veg dishes on extra cost. On the way to Meghalaya you can enjoy your meal at <b>Punjab Da Dhaba</b> Rs. 450 per person. In Shilong people can enjoy street food at Police Bazaar for local authentic food. During travel to Cherapunjee, tourists must try <b>Jiva Veg Restaurant</b> (Rs. 500 per person) or <b>Orange Roots Restaurant</b> (Normal Thali Rs. 220, Special Rhali Rs. 290). <br /><br /></p>
    </div>
  </div>
</div>
<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading1">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
      Which is the best family dining place in Guwahati?
    </button>
  </h4>
  <div id="flush-collapse1" className="accordion-collapse collapse show" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>For a unique family dining experience, try the <strong>Brahmaputra River Cruise</strong> for dinner, where you can enjoy an authentic Assamese meal with a stunning river view. You can also explore <strong>Naga Kitchen</strong> and <strong>Paradise</strong> for more traditional flavors or sample street food at <strong>Paltan Bazar</strong>.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading2">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
      How can I book a car for a full day Mawlynnong, Dawki Tour?
    </button>
  </h4>
  <div id="flush-collapse2" className="accordion-collapse collapse show" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>It's best to arrange car bookings through a Northeast India tour operator, especially during peak season when rates may be higher. They can arrange hassle-free transportation from Guwahati to Shillong and onwards to Mawlynnong and Dawki.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading3">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
      Are there any Gov. Transport facilities for Shillong Sightseeing?
    </button>
  </h4>
  <div id="flush-collapse3" className="accordion-collapse collapse show" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>Yes, you can book Shillong sightseeing tours with MTDC (Meghalaya Tourism Development Corporation). They offer shared coach tours that are economical for group travel. For information, contact the MTDC Tourist Information Centre in Police Bazaar (Ph: 0364-2226220).</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading4">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
      When is the best time to visit Nohkalikai Falls?
    </button>
  </h4>
  <div id="flush-collapse4" className="accordion-collapse collapse show" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>The best time depends on what you wish to see: from May to September, the falls are at their most magnificent due to monsoon rains, while October to January is ideal for trekking and scenic views.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading5">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
      How many Root Bridges are found in Meghalaya?
    </button>
  </h4>
  <div id="flush-collapse5" className="accordion-collapse collapse show" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>Meghalaya is home to around 11 living root bridges, with some notable ones:</p>
      <ul>
        <li><strong>Double Decker Root Bridge</strong> in Nongriat – a unique two-story bridge.</li>
        <li><strong>Ummunoi Root Bridge</strong> – a historic bridge over 54 feet long.</li>
        <li><strong>Ritymmen Root Bridge</strong> – one of the longest, halfway between Tyrna Village and Nongriat.</li>
        <li><strong>Mawsaw Root Bridge</strong> – located near a natural swimming pool.</li>
        <li><strong>Mawlynnong Single Decker Bridge</strong> – situated in Asia’s cleanest village, Mawlynnong.</li>
      </ul>
    </div>
  </div>
</div>
<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading1">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
      What are the Best Things To Do in Cherrapunjee?
    </button>
  </h4>
  <div id="flush-collapse1" className="accordion-collapse collapse show" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>Capturing the exquisite beauty of Cherrapunjee in the lenses.<br />
      - Bird Watching<br />
      - Taking walks across the beautiful streets of Cherrapunjee<br />
      - Shopping for handmade and bamboo items<br />
      - Tantalizing your taste buds with local delicacies<br />
      - Hiking the Double Decker Root Bridge.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading2">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
      What kind of food to try in Cherrapunjee during our Shillong Meghalaya Package Tour?
    </button>
  </h4>
  <div id="flush-collapse2" className="accordion-collapse collapse show" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>Try these drool-worthy local delicacies:<br />
      - Pork Rice, a Khasi delicacy<br />
      - Sohra Pulao, rice cooked in oil and vegetables without spices<br />
      - Pork Momo<br />
      - Various Pork Delicacies<br />
      - Thukpa<br />
      - Rice Beer (an alcoholic beverage)<br />
      - Jadoh, rice cooked in pork blood, a regional specialty.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading3">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
      Do we require a permit to visit Cherrapunji?
    </button>
  </h4>
  <div id="flush-collapse3" className="accordion-collapse collapse show" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>No permit is required for Indian citizens to visit Cherrapunjee, but carrying a valid ID card is mandatory.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading4">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
      What kind of clothes do we require to carry in North East Package Tour?
    </button>
  </h4>
  <div id="flush-collapse4" className="accordion-collapse collapse show" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>During summer, carry comfortable clothing but ensure to include rain gear such as a raincoat, umbrella, and rain shoes due to sudden downpours. In winter, heavy woolen garments are essential as it can get very cold.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading5">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
      How can I offer Puja at Kamakhya Temple avoiding rush?
    </button>
  </h4>
  <div id="flush-collapse5" className="accordion-collapse collapse show" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>To avoid the rush, obtain a VIP Pass from the counter, which opens at around 7:00 A.M. The cost is Rs. 500/- per person. After purchasing the ticket, you will get darshan within the next hour while waiting in the VIP room.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading6">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse6" aria-expanded="false" aria-controls="flush-collapse6">
      Which is the best Food to taste on a Shillong Trip?
    </button>
  </h4>
  <div id="flush-collapse6" className="accordion-collapse collapse show" aria-labelledby="flush-heading6" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>On your Shillong trip, don't miss out on tasting Pineapple and Corn. In the evenings, enjoy local street food at Police Bazaar, where you can find barbeque chicken, fish, pork, Momo, and much more.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading7">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse7" aria-expanded="false" aria-controls="flush-collapse7">
      Can I enjoy Solo Kayaking in Umiam Lake and Dawki River?
    </button>
  </h4>
  <div id="flush-collapse7" className="accordion-collapse collapse show" aria-labelledby="flush-heading7" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>Yes, solo kayaking is available in Umiam Lake and Dawki River. You can book the tickets on your own. In Dawki River, two types of boat services are offered: government-maintained and private. The cost per boat is Rs. 6,000/- for 1 hour.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading8">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse8" aria-expanded="false" aria-controls="flush-collapse8">
      Can I enter Bangladesh via Dawki border at Shillong?
    </button>
  </h4>
  <div id="flush-collapse8" className="accordion-collapse collapse show" aria-labelledby="flush-heading8" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>No, crossing the border at the Dawki River is not permitted, as the BSF closely monitors the border, making it strictly prohibited and punishable.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h4 className="accordion-header" id="flush-heading9">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse9" aria-expanded="false" aria-controls="flush-collapse9">
      What is the best festive season to choose for the Assam Meghalaya Trip?
    </button>
  </h4>
  <div id="flush-collapse9" className="accordion-collapse collapse show" aria-labelledby="flush-heading9" data-bs-parent="#accordionFlushExample">
    <div className="accordion-body">
      <p>The best time to visit Assam is from October to mid-March, while for Meghalaya, it's from October to May. Note that March, April, and May can get quite hot, with temperatures exceeding 30°C. You can travel during the following months to enjoy tribal festivals:<br />
      - Khasi Festival - Celebrated in April<br />
      - Jayantia Festival - 3 days in July<br />
      - Garo Festival - Celebrated in November<br />
      - Autumn Festival - Celebrated in October.</p>
    </div>
  </div>
</div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3><b> Meghalaya With Tura, Wari-Chora Garo Hills <span class="tomato"> Holiday Reviews</span></b></h3>
                      <h4><i>"Your Experience is our Marketing"</i></h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1">
                  <div id="testimonials">
                    <div className="testimonial-box-container">

                      {reviewersDataView}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div >
        </div >
        <div className="btn-whatsapp-pulse">
          <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>
        <div class="btn-call-pulse">
          <a style={{ color: 'white' }} href="tel:+91-967473-8480">
            <i className="fa fa-phone"></i>
          </a>
        </div>
      </>
    );
  }
}
export default Assam_Meghalaya_Arunachal_19N_20D_Package;
