import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop';
import Sikkim_Tour_9N_10D_bagdogra_Package from '../../../components/package/Sikkim_Darjeeling/Sikkim_Tour_9N_10D_bagdogra_Package';
import { Helmet } from "react-helmet";

class Sikkim_Tour_9N_10D_bagdogra_Page extends Component {
    constructor() {
        super();
        this.state = {
            HotPackagesData: [],
            HotelsData: [],
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const hotPackageDataList = [
            {
                Title: "Sikkim for Soulmates",
                ImageName: "Sikkim-Tour-4N-5D.webp",
                AllternateText: "...",
                Description: "2N Gangtok | 2N Darjeeling | 3 Star Hotel",
                PricePerNight: "12,999",
                NoOfTours: 42,
                NoOfDeparture: 35,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: 'tel://9147061467'
            },
            {
                Title: "Romanticize Sikkim",
                ImageName: "Sikkim-Tour-5N-6D.webp",
                AllternateText: "...",
                Description: "3N Gangtok | 1N Lachen | 1N Lachung | 3 Star Hotel",
                PricePerNight: "21,260",
                NoOfTours: 45,
                NoOfDeparture: 39,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: 'tel://9147061467'
            },
            {
                Title: "Mesmerizing Sikkim",
                ImageName: "North-Sikkim-Packages-from-Gangtok-6N-7D.webp",
                AllternateText: "...",
                Description: "2N Gangtok | 1N Lachen | 1N Lachung | 2N Darjeeling | 3 Star Hotel",
                PricePerNight: "22,400",
                NoOfTours: 43,
                NoOfDeparture: 38,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: 'tel://9147061467'
            },

            {
                Title: "Iconic Sikkim",
                ImageName: "North-Sikkim-Tour-5N-6D.webp",
                AllternateText: "...",
                Description: "3N Gangtok | 2N Darjeeling | 3 Star Hotel",
                PricePerNight: "15,120",
                NoOfTours: 47,
                NoOfDeparture: 40,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: 'tel://9147061467'
            },
            {
                Title: "Pleasing Sikkim",
                ImageName: "Extended-Sikkim-Packages-7N-8D.webp",
                AllternateText: "...",
                Description: "3N Gangtok | 1N Lachen | 1N Lachung | 2N Darjeeling | 3 Star Hotel",
                PricePerNight: "21,126",
                NoOfTours: 44,
                NoOfDeparture: 37,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: 'tel://9147061467'
            },
            {
                Title: "Extended Sikkim",
                ImageName: "Complete-Sikkim-Tour-Package-8N-9D.webp",
                AllternateText: "...",
                Description: "2N Gangtok | 1N Pelling | 1N Ravangla | 2 More.. | 3 Star Hotel",
                PricePerNight: "22,327",
                NoOfTours: 46,
                NoOfDeparture: 39,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: 'tel://9147061467'
            }

        ];

        const hotelDataList = [
            {
                HotelName: 'Cilantro, Gangtok',
                ImageName: "Cilantro-Gangtok.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.9,
                Reviews: '50 Reviews'
            },
            {
                HotelName: 'Alpine Hills Retreat, Gangtok',
                ImageName: "Alpine-Hills-Gangtok.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '47 Reviews'
            },
            {
                HotelName: 'Sikkim Retreat, Gangtok',
                ImageName: "Sikkim-Retreat-Gangtok.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.7,
                Reviews: '46 Reviews'
            },
            {
                HotelName: 'Royal, Lachung',
                ImageName: "Royal-Lachung.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.6,
                Reviews: '45 Reviews'
            },
            {
                HotelName: 'Park Place, Lachung',
                ImageName: "Park-Palace-Lachung.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.9,
                Reviews: '49 Reviews'
            },
            {
                HotelName: 'Norling Zinkham, Lachung',
                ImageName: "Norling-Zinkham-Lachung.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '46 Reviews'
            },
            {
                HotelName: 'Deezong, Lachen',
                ImageName: "Lachen-Deezong.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.6,
                Reviews: '48 Reviews'
            },
            {
                HotelName: 'Royal, Lachen',
                ImageName: "Royal-Lachen.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.5,
                Reviews: '45 Reviews'
            },
        ];

        const reviewersDataList = [
            {
                Name: "Shashank H",
                ImageName: "Shashank-H.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Adorable has a great team and supported us and planned very well our trip to Sikkim, Darjeeling. They were quick in response and any changes requested were taken care. We personally enjoyed Sikkim more than Darjeeling. Adorable team is highly recommended for any trip and we will definitely approach them in our next year plan.",
            },
            {
                Name: "Firoj Layek",
                ImageName: "Firoj-Layek.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "AMy Sikkim Silk Route adventure with adorable vacation was truly outstanding. From the moment I booked, their professionalism and attention to detail were evident. The journey along the Sikkim Silk Route offered breathtaking Himalayan views and serene valleys. Our accommodations were cozy and well-selected, providing a comfortable escape in nature.The local cuisine we enjoyed added a flavorful dimension to the trip, introducing us to the rich tastes of Sikkim. What truly made the journey remarkable was our knowledgeable guide, who shared insights into the local culture and history, making each stop more meaningful.Adorable vacation flawlessly managed every detail, ensuring our safety and comfort. This allowed me to fully immerse myself in the experience. In summary, the Sikkim Silk Route trip with adorable vacation is highly recommended for its unique blend of natural beauty, cultural immersion, and exceptional service. It's the perfect way to explore the hidden gems of Sikkim.",
            },
            {
                Name: "K A U S H A N I",
                ImageName: "Kaushani.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "A very satisfying experience with Adorable group. We were supposed to go to North Sikkim but just two days before the trip, it got cancelled due to natural calamities and they (adorable) arranged North Bengal trip for us in just that short notice and just saved our huge amount of expenses... Thanks.Appreciated 🤝👏",
            },
            {
                Name: "Ramakrishnan",
                ImageName: "Ramakrishnan-Easwaran.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "We were a group of 11 members (including 2 kids) planning for a trip to North Sikkim. Through word of mouth referral we got in touch with the team. Right from our first interaction through the course of the trip, the striking aspect was the prompt and proactive communication.Everything (including hotel stays, cabs, itinerary et al) was very well planned and coordinated. Taking 2 kids (less than 5 years old) to Lachung and Yumthang Valley was no mean task but you guys made it simpler for us.Thanks team for making our vacations a dream stay. You guys are awesome. Keep going.",
            },
       
        ];


        this.setState({
            HotPackagesData: hotPackageDataList,
            HotelsData: hotelDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Sikkim Tour From Bagdogra Airport</title>
                    <meta name="description" content="Sikkim Tour Packages From Bagdogra Airport - Get Sikkim tour packages at best prices. Book Sikkim Tours starting from Rs. 12,090 with assured health & safety measures from Adorable Vacation." />
                </Helmet>
                
                <MainHeader />
                <Sikkim_Tour_9N_10D_bagdogra_Package
                    HotPackagesData={this.state.HotPackagesData}
                    HotelsData={this.state.HotelsData}
                    ReviewersData={this.state.ReviewersData}
                />
                <FooterDesktop />
            </>
        );
    }
}

export default Sikkim_Tour_9N_10D_bagdogra_Page;