import React, { useState, useEffect } from "react";

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Toggle visibility of the button based on scroll position
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.scrollY > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);

    // Scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <div>
            {isVisible && (
                <button
                    onClick={scrollToTop}
                    style={{
                        position: "fixed",
                        bottom: "6rem",
                        right: "1rem",
                        width: "50px", // Set width
                        height: "50px", // Set height
                        fontSize: "1.2rem", // Adjust font size for better fit
                        backgroundColor: "#ff875b",
                        color: "#fff",
                        zIndex: 9999,
                        border: "none",
                        borderRadius: "50%", // Makes the button circular
                        cursor: "pointer",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center", // Center-align the icon
                    }}
                >
                    ↑
                </button>
            )}
        </div>
    );
};

export default ScrollToTopButton;
