import React, { Component } from 'react';

class InclusionExclusion_group extends Component {
    render() {
        return (
            <>
                <h4><u>INCLUSION: <strong className='tomato'>Kaziranga Package Tour Booking</strong></u></h4>
                <ul>
                <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;All transfers in Private exclusive vehicle (Fully Sanitized)
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Accommodation in best properties
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Driver's allowance, fuel, toll, parking</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;1 Elephant Safari + 2 Jeep Safari + Entry fees</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;All permits and applicable taxes</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;01 No. of Professional Licensed Tour Guide (Guwahati - Guwahati)</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;24x7 Tour Assistance Services</li>
                </ul><br/><br/><br/>
                <h4><u>EXCLUSIONS: <strong className='tomato'>Guwahati To Kaziranga Package Tour</strong></u></h4>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;All items which are not included in inclusion list</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Jungle Safari related Personal Expenses</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Surge charges due to Government imposed Tax / Rules</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Train and Air Fare</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Laundry, Tips, phone call, Porter or any such personal expense</li>
                </ul>
            </>
        );
    }
}

export default InclusionExclusion_group;