import React, { Component } from 'react';
import Slider from "react-slick";
import ApiURL from "../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

class GalleryExplorer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            DestinationData: []
        }
    }

    componentDidMount() {
        const destinationDataList = [
            {
                ImageName: "Bhutan1.webp",
                AllternateText: "...",
                Title: "Bhutan",
                Description: "Get ready for a whirlwind journey through Gangtok, Pelling, and Darjeeling. These three incredibly beautiful destinations.",
                BookNowURL: "/bhutan-inside"
            },
            {
                ImageName: "Bhutan2.webp",
                AllternateText: "...",
                Title: "Arunachal",
                Description: "Get ready for a whirlwind journey through Gangtok, Pelling, and Darjeeling. These three incredibly beautiful destinations.",
                BookNowURL: "/"
            },
            {
                ImageName: "Bhutan3.webp",
                AllternateText: "...",
                Title: "Sikkim",
                Description: "Get ready for a whirlwind journey through Gangtok, Pelling, and Darjeeling. These three incredibly beautiful destinations.",
                BookNowURL: "/"
            },
            {
                ImageName: "Bhutan4.webp",
                AllternateText: "...",
                Title: "Bali",
                Description: "Get ready for a whirlwind journey through Gangtok, Pelling, and Darjeeling. These three incredibly beautiful destinations.",
                BookNowURL: "/"
            },
            {
                ImageName: "Bhutan5.webp",
                AllternateText: "...",
                Title: "Azarbijan",
                Description: "Get ready for a whirlwind journey through Gangtok, Pelling, and Darjeeling. These three incredibly beautiful destinations.",
                BookNowURL: "/"
            },
        ];

        this.setState({ DestinationData: destinationDataList })
    }

    render() {

        const { DestinationData } = this.state;

        return (
            <>
                <div className="container-fluid custom-container2">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="site-heading text-center">
                                <h2>Gallery <span>Explorer</span></h2>
                                <h4>Our Tour Gallery</h4>
                            </div>
                        </div>
                    </div>

                    <div className="row gallery-grid custom-containergallery">
                        {DestinationData.map((sliderItem, i) => {
                            const sliderImageURL = `${ApiURL.BlogExplorerImageURL}/${sliderItem.ImageName}`;
                            return (
                                <div className="cards-wrapper2" key={i}>
                                    <div className="card">
                                        <div className="image-wrapper2">
                                            <img src={sliderImageURL} alt={sliderItem.AlternateText} />
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">{sliderItem.Title}</h5>
                                            <p className="card-text">{sliderItem.Description}</p>
                                            <div>
                                                <Link to={sliderItem.BookNowURL} className="btn btn-Warning btn-sm float-start">
                                                    View More
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </>
        );
    }
}

export default GalleryExplorer;