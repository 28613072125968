import React, { Component, useState } from 'react';
import BookingBox from '../BookingBox';
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Bhutan/InclusionExclusion_bhutan_b2b';

class Bhutan_B2B_7N_8D_Package_Bagdogra_Airport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            btn: "Submit",
            showLoader: false,
            isHidden: true,
            showModal: false,
            showMegamenu: false, // New state to handle megamenu
            firstName: '',
            mobile: '',
            email: '',
            comments: '',
            megamenuTimeout: null // New state for managing hover delay
        }
        this.timeoutId = null; // To store the timeout ID
        this.modalRef = React.createRef(); // Create a ref for the modal
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919883359713&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ showModal: true });
        }, 40000);
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        // Cleanup the event listener
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        // Check if the click is outside the modal
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.onCloseModal();
        }
    };

    toggleHidden = (e) => {
        e.preventDefault();
        this.setState({ isHidden: !this.state.isHidden });
    }

    handleMegamenuEnter = () => {
        // Clear any existing timeout
        if (this.state.timeoutId) {
            clearTimeout(this.state.timeoutId);
        }
        this.setState({ isHidden: false });
    };

    handleMegamenuLeave = () => {
        // Set a timeout to delay hiding the megamenu
        const timeoutId = setTimeout(() => {
            this.setState({ isHidden: true });
        }, 300); // Match this delay with the CSS transition time

        this.setState({ timeoutId });
    };


    onRequestCallback = (e) => {
        e.preventDefault();
        if (!this.state.showModal) {
            this.setState({ showModal: true });
        }
    }

    onCloseModal = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            showModal: false,
            firstName: '',
            mobile: '',
            email: '',
            comments: ''
        });
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    // Function to handle form submission
    onRequestCallbackSubmit = async (e) => {
        e.preventDefault();

        const { firstName, email, mobile, comments } = this.state;

        // Validation
        if (firstName.trim() === '') {
            cogoToast.error("Full Name Required.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
            // } else if (email.trim() === '') {
            //     cogoToast.error("Email Required.", {
            //         position: 'top-center', // Use top-center, then adjust via CSS
            //         hideAfter: 2, // optional: auto-hide after 2 seconds
            //         className: 'custom-toast' // Apply custom class
            //     });
        } else if (mobile.trim() === '' || !/^\d{10}$/.test(mobile.trim())) {
            cogoToast.error("Please Insert 10 digit Mobile No.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else {
            this.setState({ btn: "Processing..", showLoader: true });
            // Proceed with form submission
            try {
                const _ref = 'yes';
                const _url = window.location.href;

                const formData = {
                    FirstName: firstName,
                    Email: email,
                    Mobile: mobile,
                    Comments: comments,
                    Referrer: _ref,
                    URL: _url
                };

                const response = await axios.post(ApiURL.SaveCustomerEnquery, formData);
                console.log('Data submitted successfully:', response.data);
                // Optionally, reset the form state after successful submission
                this.setState({ firstName: '', email: '', mobile: '', comments: '' });

                // Optionally, show a success message to the user
                cogoToast.success("Form submitted successfully.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
                this.setState({ btn: "Submit", showLoader: false, showModal: false });
            } catch (error) {
                console.error('Error submitting data:', error);
                this.setState({ btn: "Submit", showLoader: false });
                // Optionally, show an error message to the user
                cogoToast.error("Please try again after 24 hrs. later.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
            }

        }

    };

    /* RedirectToOTPPage = () => {
        if (this.state.redirectOTPStatus === true) {
            let url = "/verifyotp/" + this.state.mobile;
            return <Redirect to={url} />
        }
    } */

    onCloseModal = () => {
        this.setState({ showModal: false }, () => {
            // Additional code to perform after the modal is closed
            setTimeout(() => {
                this.setState({ showModal: true }, () => {
                    // Additional code to perform after the next modal is displayed
                });
            }, 55000); // 55sec in milliseconds
        });
    };

    render() {
        const sixSenseBhutanTourPackagesImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-with-hotel-six-senses.webp";
        const amanKoraBhutanPackagesImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-with-hotel-aman-kora.webp";
        const sightseen1ImageURL = ApiURL.BhutanB2BPackageImageURL + "/adorable-vacation-bhutan-tour-operator-for-b2b.webp";
        const sightseen2ImageURL = ApiURL.BhutanB2BPackageImageURL + "/b2b-travel-agen-for-bhutan-tour-from-india.webp";
        const sightseen3ImageURL = ApiURL.BhutanB2BPackageImageURL + "/b2b-travel-agents-for-bhutan-tour-from-bagdogra.webp";
        const sightseen4ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-b2b-group-tour-from-bagdogra-with-adorable.webp";
        const sightseen5ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-b2b-travel-packages.webp";
        const sightseen6ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-trip-from-mumbai-with-direct-flights.webp";
        const spring1ImageURL = ApiURL.BhutanB2BPackageImageURL + "/travel-bhutan-with-best-b2b-dmc-adorable-vacation.webp";
        const spring2ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-tour-from-mumbai-during-spring-with-adorable-vacation.webp";
        const spring3ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-tour-package-from-mumbai-during-spring-with-adorable-vacation.webp";
        const winter1ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-package-tour-from-mumbai-with-direct-flight-in-winter.webp";
        const winter2ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-tour-package-from-mumbai-with-direct-flight-in-winter.webp";
        const winter3ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-trip-from-mumbai-with-direct-flight-in-winter.webp";
        const summer1ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-tour-booking-from-mumbai-during-summer-with-adorable-vacation.webp";
        const summer2ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-tour-from-mumbai-during-summer-with-adorable-vacation.webp";
        const summer3ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-tour-package-from-mumbai-with-direct-flight-in-summer.webp";
        const flight1ImageURL = ApiURL.BhutanB2BPackageImageURL + "/mumbai-to-bhutan-tour-packages-with-direct-flight-with-adorable-vacation-1.webp";
        const flight2ImageURL = ApiURL.BhutanB2BPackageImageURL + "/mumbai-to-bhutan-tour-packages-with-direct-flight-with-adorable-vacation-2.webp";
        const hotel1ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-hotel-ugyen-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const hotel2ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-hotel-vara-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const hotel3ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-hotel-mandala-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const CallButtonSoumalyaImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/call-button-bablu-adorable-vacation.webp";
        const rows = [
            { date: '24th Sept - 30th Sept', availability: 'Available', price: '84,500' },
            { date: '01st Oct - 07th Oct', availability: 'Available', price: '84,500' },
            { date: '04th Oct - 10th Oct', availability: 'Available', price: '84,500' },
            { date: '08th Oct - 14th Oct', availability: 'Available', price: '84,500' },
            { date: '11th Oct - 17th Oct', availability: 'Available', price: '84,500' },
            { date: '15th Oct - 21st Oct', availability: 'Available', price: '84,500' },
            { date: '18th Oct - 24th Oct', availability: 'Available', price: '84,500' },
            { date: '22nd Oct - 28th Oct', availability: 'Available', price: '84,500' },
            { date: '25th Oct - 31st Oct', availability: 'Available', price: '84,500' },
            { date: '29th Oct - 04th Nov', availability: 'Available', price: '84,500' },
            { date: '1st Nov - 07th Nov', availability: 'Available', price: '84,500' },
            { date: '05th Nov - 11th Nov', availability: 'Available', price: '84,500' },
        ];

        const rowsPerPage = 6;
        const totalPages = Math.ceil(rows.length / rowsPerPage);
        const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
        const { customActiveTab, daywiseActiveTab } = this.state;

        const packageTopBannerImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-b2b-package-tour-from-adorable-vacation-the-best-bhutan-b2b-dmc.webp";
        const packageTopBanner2ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-b2b-tour-packages-from-adorable-vacation-the-best-bhutan-b2b-dmc-in-india.webp";
        const FlightBannerImageURL = ApiURL.BhutanB2BPackageImageURL + "/Bhutan-Group-Tour-Package-from-Mumbai.webp";
        const navDay1ImageURL = ApiURL.BhutanB2BPackageImageURL + "/mumbai-to-bhutan-tour-packages-with-direct-flights-with-adorable-vacation.webp";
        const navDay2ImageURL = ApiURL.BhutanB2BPackageImageURL + "/mumbai-to-bhutan-tour-packages-booking-with-direct-flights-with-adorable-vacation.webp";
        const navDay3ImageURL = ApiURL.BhutanB2BPackageImageURL + "/mumbai-to-bhutan-tour-package-cost-with-direct-flights-with-adorable-vacation.webp";
        const navDay4ImageURL = ApiURL.BhutanB2BPackageImageURL + "/mumbai-to-bhutan-tour-package-itinerary-with-direct-flights-with-adorable-vacation.webp";
        const navDay5ImageURL = ApiURL.BhutanB2BPackageImageURL + "/mumbai-to-bhutan-tour-package-plan-with-direct-flights-with-adorable-vacation.webp";
        const navDay6ImageURL = ApiURL.BhutanB2BPackageImageURL + "/mumbai-to-bhutan-tour-package-price-with-direct-flights-with-adorable-vacation.webp";
        const navDay7ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-3-nights-4-days-package.webp";
        const navDay8ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-tour-3-days.webp";
        const ButtonImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-tour-packages-from-mumbai-with-chartered-flight-tickets-from-adorable-vacation.webp";
        const Button1ImageURL = ApiURL.BhutanB2BPackageImageURL + "/adorable-vacation-the-best-bhutan-b2b-dmc-in-india.webp";

        const hotPackagesDataList = this.props.HotPackagesData;
        const hotPackagesView = hotPackagesDataList.length > 0 ?
            (
                hotPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.FIFO_6N_7D_ImageURL + "/" + dataItem.ImageName;
                    const contactURL = dataItem.Url;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} | {dataItem.EXPARO}<span> &#8377; {dataItem.PricePerNight}</span> </h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue">{dataItem.SDFInclude}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href={contactURL} className="btn btn-Warning btn-sm float-end">Call Now</a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const themePackageSliderList = this.props.ThemePackagesData;
        const themePackagesDataView = themePackageSliderList.map(
            (sliderItem, i) => {
                const sliderImageURL =
                    ApiURL.BhutanB2BPackageImageURL +
                    "/" +
                    sliderItem.ImageName;
                return (
                    <div className="theme-cards-wrapper mx-2" key={i}>
                        <div className="theme-card">
                            <div className="image-wrapper">
                                <img src={sliderImageURL} alt={sliderItem.AllternateText} />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">{sliderItem.Title}</h5>
                                <p className="card-text">{sliderItem.Description}</p>
                                <a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                );
            }
        );

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.BhutanB2BPackageImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <div dangerouslySetInnerHTML={{ __html: dataItem.Comment }}></div>
                    </div>
                </div>
            )
        })


        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="bhutan b2b package tour from adorable vacation the best bhutan b2b dmc" />

                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container">
                                        <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center>Bhutan B2B Package Tour from Bagdogra Airport </center></h1>
                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center>Bhutan B2B Package Tour from Bagdogra Airport</center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="bhutan b2b tour packages from adorable vacation the best bhutan b2b dmc in india" />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Cities:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Thimphu
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Paro
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Punakha
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable Bhutan tour, you have come to the Right Place! Adorable vacation is the best DMC for <strong>Bhutan B2B Package Tour</strong> from India.
                                        </p>
                                        <hr />
                                        <br />
                                        <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}>
                                            We offer the Best Rate for <strong>Bhutan B2B packages from Bagdogra Airport via Phuentsholing</strong><br /><br /> FOR BEST DEAL |<a href='tel:9883359713' class="reviews"><strong> ☎ CALL Mr. SOUMALYA +91-98833-59713</strong></a></h2>
                                        <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> We offer the Best Rate for <strong>Bhutan B2B packages from Bagdogra Airport via Phuentsholing</strong><br /><br /> FOR BEST DEAL |<a href='tel:9883359713' class="reviews"><strong> ☎ CALL Mr. SOUMALYA +91-98833-59713</strong></a></h3>
                                    </div>
                                </div>
                            </div>
                            <br /> <br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> ADORABLE VACATION - THE <strong>BEST BHUTAN DMC IN INDIA</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> ADORABLE VACATION - THE <strong>BEST BHUTAN DMC IN INDIA</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p>Are you dreaming of a <b>Bhutan tour from India</b>? If yes, you're in the right place! If you're unsure where to stay and want to avoid the hassle of planning, don't worry. At Adorable Vacation - <strong>the Best DMC of Bhutan Tour</strong>, we offer a well-planned travel experience designed by a team of experts specializing in <strong>Bhutan B2B Package Tour from Bagdogra Airport</strong>. <br /><br />

                                        Bhutan is a small, beautiful country nestled in the Himalayas, known for its stunning landscapes, rich culture, and peaceful way of life. It is often called  - <b>The Land of the Thunder Dragon</b> because of its rugged mountains and the majestic sound of thunder that rolls through the valleys. Bhutan is unique because it values happiness over wealth and focuses on the well-being of its people. The country measures its success using a concept called Gross National Happiness (GNH), which emphasizes balance in life, environmental conservation, and cultural preservation. <br /><br />

                                        <b>Adorable Vacation</b> is here to make your <strong>Bhutan B2B Packages from Bagdogra</strong> unforgettable. We provide <strong>customized Bhutan B2B Package Tour from Bagdogra by Road</strong> based on your needs. Our goal is to give you amazing travel experiences. Our team is dedicated to ensuring your <strong>Bhutan tour package from Bagdogra</strong> is a complete success. <br /><br />

                                        At Adorable Vacation, we focus on commitment, dedication, and customer satisfaction. We aim to meet your needs with creative service options. Our <strong>Bhutan B2B Packages from Bagdogra via Phuentsholing</strong> are designed to offer you easy, secure, and affordable tours for businesses. We also have a range of packages for international and corporate clients. <br /><br />

                                        Whether you're a business professional traveling to Bhutan for work or a traveler looking for adventure, our research-based advice will help you. The way people travel is always changing, and so are their needs. <br /><br />

                                        Adorable Vacation, <strong>India's best DMC for Bhutan Tour</strong>, is proud of its integrity and commitment. We provide clear and honest information about our Bhutan services to avoid any confusion. <br /><br />

                                        To ensure your needs are fully met, we work with a network of reliable suppliers and vendors. Our experienced team of Bhutan travel experts, with firsthand knowledge of your destination, will make sure your travel plans go smoothly. We promise a well-organized and hassle-free journey. <br /><br /></p>
                                    <p>Apart from <strong>Bhutan B2B package with Airfare</strong>, Adorable Vacation offers various <strong>Bhutan tour packages</strong> like <a href="/bhutan-tour-package-from-pune" target="_blank"><b className="tomato">Bhutan Package Tour from Pune</b></a>,<a href="/bhutan-tour-from-delhi" target="_blank"><b className="tomato"> Bhutan Package Tour from Delhi</b></a>, <a href="/bhutan-tour-package-from-mumbai" target="_blank"><b className="tomato">Bhutan package tour from Mumbai</b></a>, <a href="/bhutan-tour-package-from-ahemdabad" target="_blank"><b className="tomato">Bhutan Package Tour from Ahmedabad</b></a>, <a href="/bhutan-tour-from-bengaluru" target="_blank"><b className="tomato">Bhutan Package Tour from Bangalore</b></a>, <a href="/bhutan-tour-package-from-jaigaon" target="_blank"><b className="tomato"> Bhutan Package Tour from Jaigaon</b></a>, <a href="/bhutan-tour-package-from-kolkata" target="_blank"><b className="tomato">Bhutan Package Tour from Kolkata</b></a>, <a href="/bhutan-tour-package-from-bagdogra" target="_blank"><b className="tomato">Bhutan Package Tour from Bagdogra Airport</b></a>, <a href="/bhutan-tour-package-from-surat" target="_blank"><b className="tomato">Bhutan Package Tour from Surat</b></a>, <a href="/mumbai-special-chartered-flight" target="_blank"><b className="tomato">Bhutan Package Tour from Mumbai with Chartered Flight</b></a>, etc at Best Price.</p>
                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen1ImageURL}
                                                        alt="adorable vacation - bhutan tour operator for b2b"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen2ImageURL}
                                                        alt="b2b travel agen for bhutan tour from india"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen3ImageURL}
                                                        alt="b2b travel agents for bhutan tour package booking"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /> <br />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /> <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> ADORABLE VACATION - INDIA'S NO 1 TRAVEL DMC FOR BHUTAN TOUR </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> ADORABLE VACATION - INDIA'S NO 1 TRAVEL DMC FOR BHUTAN TOUR </h3>
                                </div>
                                <div className="card-body">
                                    <h3>Duration for <strong>Bhutan B2B Package Tour from Bagdogra</strong> : 7N | 8D <br /><br /></h3>
                                    <h4>Destinations Covered in <strong>Bhutan B2B Tour Package from Bagdogra Airport</strong> : Phuentsholing 1N | Thimphu 2N | Punakha 1N | Paro 2N | Siliguri 1N</h4>
                                    <br/>
                                    <h4>Pickup & Drop Point for <strong>Bhutan B2B Package</strong>: Bagdogra International Airport [IXB] <br /><br /></h4>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DAY WISE SUMMERY FOR 7N|8D <strong>BHUTAN B2B TOUR PACKAGE</strong> FROM BAGDOGRA AIRPORT</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DAY WISE SUMMERY FOR 7N|8D <strong>BHUTAN B2B TOUR PACKAGE</strong> FROM BAGDOGRA AIRPORT</h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingOne"
                                                    >
                                                        <b>DAY 1 → </b>
                                                        <span>
                                                            BAGDOGRA ARRIVAL, TRANSFER TO PHUENTSHOLING
                                                        </span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingTwo"
                                                    >
                                                        <b>DAY 2 → </b>
                                                        <span>PHUENTSHOLING TO THIMPHU</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingThree"
                                                    >
                                                        <b>DAY 3 → </b>
                                                        <span>THIMPHU LOCAL SIGHTSEEING</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingFour"
                                                    >
                                                        <b>DAY 4 → </b>
                                                        <span>THIMPHU TO PUNAKHA TRANSFER VIA DOCHULA PASS</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingFive"
                                                    >
                                                        <b>DAY 5 → </b>
                                                        <span>PUNAKHA / WANGDUE TO PARO</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingSix"
                                                    >
                                                        <b>DAY 6 → </b>
                                                        <span>PARO LOCAL SIGHTSEEING & TAKTSHANG MONASTERY HIKING</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingSeven"
                                                    >
                                                        <b>DAY 7 → </b>
                                                        <span>PARO TO SILIGURI VIA PHUENTSHOLING </span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingTen"
                                                    >
                                                        <b>DAY 8 → </b>
                                                        <span>SILIGURI TO BAGDOGRA TRANSFER</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br /><br />
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="image-cell">
                                            <a href='#' onClick={this.onRequestCallback}><img src={Button1ImageURL} alt="adorable vacation - the best bhutan b2b dmc in india" className="image-padding" /></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br />
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>Destinations Covered in <span style={{ color: 'tomato' }}>Bhutan B2B Package Tour From Bagdogra</span></h3>
                                            <h4>Phuentsholing 1N | Thimpu 2N | Punakha 1N | Paro 2N | Siliguri 1N</h4>
                                        </div>
                                    </div>
                                </div>
                                <br /><br />
                                <table style={{ width: '100%', textAlign: 'center' }}>
                                    <tbody>
                                        <tr>
                                            <td className="image-cell" style={{ textAlign: 'center' }}>
                                                <img
                                                    src={sightseen4ImageURL}
                                                    alt="bhutan b2b group tour from bagdogra with adorable vacation"
                                                    style={{ margin: '10px' }}
                                                />
                                                <img
                                                    src={sightseen5ImageURL}
                                                    alt="bhutan b2b travel packages"
                                                    style={{ margin: '10px' }}
                                                />
                                                <img
                                                    src={sightseen6ImageURL}
                                                    alt="travel bhutan with best b2b dmc - adorable vacation"
                                                    style={{ margin: '10px' }}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br /><br /><br />

                                <h3 className='site-heading text-center'><b >Wonderful <span className='tomato'>Bhutan Tour Review from Mumbai</span> from our esteemed Guests Mr. & Mrs. Dalal</b><br /></h3>
                                <p className='p-2'><center>We are thrilled to share a heartfelt review for <strong>Bhutan tour from Mumbai</strong> from our valued guests, Mr. & Mrs. Dalal. Their experience highlights the exceptional service and memorable moments they encountered during their <a href='/mumbai-special-chartered-flight' target='_blank'><strong>Bhutan tour from Mumbai with Direct Flight</strong></a>. We take great pride in ensuring that every guest has a delightful and fulfilling journey, and it's always rewarding to hear such positive feedback.</center></p><br /><br />
                                <div class="video-container">
                                    <iframe
                                        src="https://www.youtube.com/embed/19drRVnSMCs?si=Uj5Fm4uSuwPi0SBM"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen>
                                    </iframe>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> BEST PLACES TO EXPLORE DURING BHUTAN B2B PACKAGE TOUR
                                    </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST PLACES TO EXPLORE DURING BHUTAN B2B PACKAGE TOUR
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf"><tr><th>PLACES</th><th>ATTRACTIONS</th></tr></thead>
                                            <tbody><tr><td>Thimphu</td><td>Tashichho Dzong, Simply Bhutan Museum, Buddha Dordenma Statue, National Memorial Chorten, Motithang Takin Preserve, Changangkha Lhakhang, Royal Textile Academy of Bhutan, Centenary Farmers' Market, National Folk Heritage Museum, Simtokha Dzong, Pangri Zampa Monastery</td></tr>
                                                <tr><td>Paro</td><td>Taktsang (Tiger's Nest) Monastery, Paro Dzong (Rinpung Dzong), National Museum of Bhutan, Drukgyel Dzong, Kyichu Lhakhang, Jangsarbu Lhakhang, Dungtse Lhakhang, Chele La Pass, Paro Weekend Market</td></tr>
                                                <tr><td>Punakha</td><td>Punakha Dzong, Chimi Lhakhang (The Fertility Temple), Khamsum Yulley Namgyal Chorten, Sangchhen Dorji Lhuendrup Lhakhang Nunnery, Mo Chhu River Rafting</td></tr>
                                                <tr><td>Phuentsholing</td><td>Bhutan Gate, Zangto Pelri Lhakhang, Phuentsholing Viewpoint, Torsa River Side, Karbandi Monastery, Amo Chhu Crocodile Breeding Centre, Phuentsholing Market</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-bagdogra" target='_blank' className="btn btn-Warning btn-lg">
                                            <b>Bhutan Package Tour From Bagdogra Airport</b>
                                        </a>
                                    </div>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-pune" target='_blank' className="btn btn-Warning btn-lg w-100">
                                            <b>Bhutan Package Tour Booking From Pune</b>
                                        </a>
                                    </div>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-kolkata" target='_blank' className="btn btn-Warning btn-lg w-100">
                                            <b>Bhutan Package Tour From Kolkata Airport</b>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> HOW TO REACH BHUTAN FROM INDIA?</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> HOW TO REACH BHUTAN FROM INDIA?</h3>
                                </div>
                                <div className="card-body">
                                    <p>By Flight : Traveling to Bhutan from different cities in India by flight is convenient, as several cities offer direct flights to Paro, Bhutan’s only international airport. The two main airlines operating flights between India and Bhutan are Druk Air and Bhutan Airlines. Here's how you can travel to Bhutan from various Indian cities by flight:</p>
                                    <br /><br />
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr>
                                                    <th>SECTOR</th>
                                                    <th>AIRLINES</th>
                                                    <th>FREQUENCY</th>
                                                    <th>DEP TIME</th>
                                                    <th>ARR TIME</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>CCU - PBH</td>
                                                    <td>Bhutan Airlines - B3 701</td>
                                                    <td>Daily</td>
                                                    <td>10:50</td>
                                                    <td>12:20</td>
                                                </tr>
                                                <tr>
                                                    <td>DEL - PBH</td>
                                                    <td>Druk Air - Royal Bhutan Airlines - KB 201</td>
                                                    <td>Every Sun, Tue, Wed, Fri, Sat</td>
                                                    <td>12:30</td>
                                                    <td>15:20</td>
                                                </tr>
                                                <tr>
                                                    <td>GAU - PBH</td>
                                                    <td>Drukair - Royal Bhutan Airlines – KB 541</td>
                                                    <td>Every Sunday, Every Thursday [Start from 6th April]</td>
                                                    <td>15:20</td>
                                                    <td>16:45</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <div className="content-desktop">
                                        <center><img src={flight1ImageURL} alt="Bhutan B2B Package from Bhutan DMC" /></center>
                                    </div>

                                    <div className="content-mobile2">
                                        <center><img src={flight1ImageURL} style={{ width: '320px' }} alt="B2B Travel Agents" /></center>
                                    </div>
                                    <br /><br />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={ButtonImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> HOW TO TRAVEL BHUTAN FROM BAGDOGRA?</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HOW TO TRAVEL BHUTAN FROM BAGDOGRA?</h3>
                                </div>
                                <div className="card-body">
                                    <p>To travel from Bagdogra to Bhutan, the most common and convenient option is by road. The distance is approximately 150-170 kilometers, and the journey typically takes around 4 to 6 hours, depending on road conditions and traffic. You can hire a taxi or private vehicleyou’re your <a href="https://adorablevacation.com/bhutan-tour-package-from-bagdogra/" target="_blank"><strong>Bhutan package tour from Bagdogra Airport</strong></a>. The route involves traveling via Siliguri, which is about 10 km from Bagdogra, and then continuing on National Highway 31A to reach Jaigaon, the Indian town adjacent to Phuentsholing.  <br /><br />

                                        At the border, you will need to complete immigration and customs procedures to enter Bhutan. The border crossing is open from 6:00 AM to 9:00 PM, and Indian citizens can use a valid ID like voter ID to enter.  <br /><br />

                                        For non-Indians, a passport and visa are required. Alternatively, you could take a taxi, either private or shared, from Bagdogra to Phuentsholing, although shared taxis may take longer due to additional stops. There are also limited bus services from Siliguri to Phuentsholing, though they may not be frequent. <br /><br />

                                        Adorable vacation offers different ways to travel Bhutan. The best options are <a href="https://myadorablevacation.com/bhutan-tour-package-from-bagdogra/" target="_blank"><strong>Bhutan package tour from Bagdogra Airport</strong></a>, <a href="https://adorablevacation.com/bhutan-tour-package-from-jaigaon/" target="_blank"><strong>Bhutan package tour from Jaigaon</strong></a>, <a href="https://adorablevacation.com/bhutan-package-tour-from-phuentsholing/" target="_blank"><strong>Bhutan package tour from Phuentsholing</strong></a>, <a href="https://www.adorablevacation.com/bhutan-package-tour-from-hasimara-new-alipurduar/" target="_blank"><strong>Bhutan package tour from Hasimara</strong></a> and <a href="https://adorablevacation.com/bhutan-package-tour-from-njp-station-siliguri-bus-stand/" target="_blank"><strong>Bhutan package tour from NJP/Siliguri</strong></a>.<br /><br /></p>
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen1ImageURL}
                                                        alt="bhutan b2b group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen3ImageURL}
                                                        alt="bhutan b2b travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen5ImageURL}
                                                        alt="travel bhutan with best b2b dmc adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHY CHOOSE ADORABLE VACATION FOR BEST DMC FOR BHUTAN TOUR?</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY CHOOSE ADORABLE VACATION FOR BEST DMC FOR BHUTAN TOUR?</h3>
                                </div>
                                <div className="card-body">
                                    <p><b>Expert Knowledge of Bhutan</b> - Adorable Vacation has deep expertise knowledge about Bhutan, including its culture, traditions, and hidden gems. This ensures that travelers experience the country in an authentic and immersive way, guided by professionals who understand the intricacies of Bhutanese customs, language, and the best places to visit. <br /><br />

                                        <b>Tailored Itineraries</b> - The company specializes in offering customized itineraries based on the traveler’s interests and preferences. Whether you are interested in cultural exploration, trekking, adventure, or spiritual experiences, Adorable Vacation can design a personalized itinerary that matches your expectations. This flexibility ensures you get the most out of your trip.<br /><br />

                                        <b>Well-Organized Tours</b> - As a Destination Management Company (DMC), Adorable Vacation takes care of every detail of your trip, from visa processing and permits to accommodations and transportation. This professional approach ensures that everything runs smoothly, allowing you to enjoy a stress-free vacation. Their team is experienced in handling logistics, ensuring that your travel experience in Bhutan is seamless.<br /><br />

                                        <b>Local Connections and Partnerships</b> - Adorable Vacation has strong ties with local suppliers and service providers in Bhutan, including hotels, guides, and transport services. This allows them to offer exclusive experiences, better rates, and superior service. Local partnerships ensure you get the most value for your money while supporting Bhutan’s economy.<br /><br />

                                        <b>Specialized Bhutanese Experiences</b> - Whether it’s trekking to the famous Tiger’s Nest Monastery, exploring the Paro Dzong, attending a traditional Bhutanese festival, or visiting remote villages, Adorable Vacation offers experiences that are deeply connected to Bhutan's unique culture and landscape. They curate tours that provide a meaningful and memorable experience that goes beyond the usual tourist attractions.<br /><br />

                                        <b>High Customer Satisfaction</b> - Adorable Vacation has built a reputation for delivering excellent customer service, with a focus on ensuring that all travelers have a comfortable and enjoyable experience. The positive testimonials and reviews from past customers are a testament to their commitment to quality and satisfaction.<br /><br />

                                        <b>Sustainable and Responsible Travel</b> - Bhutan is known for its commitment to sustainable tourism i.e. “High-Value Low Impact”. Adorable Vacation is dedicated to promoting responsible travel, ensuring that their tours respect Bhutan’s environmental policies, support local communities, and minimize the impact on the environment. They align with Bhutan’s philosophy of Gross National Happiness [GNH] and ensure that their travelers engage in ethical and sustainable tourism practices.<br /><br />

                                        <b>Competitive Pricing</b> - While Bhutan is generally a premium destination due to its unique tourism policies, Adorable Vacation offers competitive pricing while maintaining high standards of service. They can provide value-for-money packages, especially for groups or longer tours, ensuring that you get the best experience without compromising quality.<br /><br />

                                        <b>24*7 Support</b> - Adorable Vacation offers round-the-clock customer support during your trip, ensuring you have assistance whenever needed. Whether it’s for last-minute changes, guidance, or emergencies, their team is available to ensure you feel safe and well taken care of throughout your journey.<br /><br />

                                        <b>Specialization in Bhutan Tours</b> - Unlike other general travel agencies, Adorable Vacation specializes specifically in Bhutan, meaning they have in-depth knowledge and experience in arranging tours to the country. Their focus on Bhutan ensures that you get the best possible experience in this beautiful and culturally rich destination. <br /><br /><br /></p>

                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen1ImageURL}
                                                        alt="bhutan b2b group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen3ImageURL}
                                                        alt="bhutan b2b travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen5ImageURL}
                                                        alt="travel bhutan with best b2b dmc adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT ARE THE ENTRY FORMALITIES IN BHUTAN AS <strong>BHUTAN B2B PACKAGE</strong> </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT ARE THE ENTRY FORMALITIES IN BHUTAN AS <strong>BHUTAN B2B PACKAGE</strong> </h3>
                                </div>
                                <div className="card-body">
                                    <p><b>Visa Requirement:</b> Most travelers need a visa to enter Bhutan, except for citizens of India, Bangladesh, and the Maldives. Indian citizens do not require a visa to visit Bhutan. However, you will need to carry specific documents for entry, depending on how you travel. <br /><br />

                                        <b>Valid Passport or Voter ID:</b> Your passport must be valid for at least 6 months from the date of entry but passport is preferred for air travel. Otherwise Voter ID is accepted for land entry.<br /><br />

                                        Once you cross the border or arrive at the airport, Indian citizens will be issued a tourist permit, which allows you to visit popular destinations like Thimphu, Paro, and Punakha. The permit is usually valid for up to 7 days.<br /><br />

                                        <b>Travel beyond Thimphu and Paro:</b> If you wish to visit places outside Thimphu or Paro, such as Bumthang or Haa, you will need an additional Special Permit. You can get this permit at the Immigration Office in Thimphu. The Phuentsholing border gate and immigration office are now open 7 days a week from 9 am to 7.30 pm.
                                        <br /><br /></p>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="row py-5">
                                <div className="col-md-12">
                                    <div className="site-heading text-center">
                                        <h3>
                                            DAY WISE ITINERARY FOR <span className='tomato'><strong>BHUTAN B2B TOUR PACKAGE</strong></span>
                                        </h3>
                                        <h4>7N/8D <strong>Bhutan B2B Package Tour Itinerary</strong> from Adorable Vacation</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div
                                    className="accordion"
                                    id="accordionPanelsStayOpenExample"
                                >
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day1"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseOne"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseOne"
                                            >
                                                <h4> DAY 01: ARRIVAL AT BAGDOGRA, TRANSFER TO PHUENTSHOLING </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseOne"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingOne"
                                        >
                                            <div className="accordion-body">
                                                <p>Upon arrival at Bagdogra Airport (IXB) for your <strong>Bhutan B2B Package Tour</strong>, you will be warmly greeted and then drive towards the Indo-Bhutanese border at Jaigaon / Phuentsholing. Enjoy the beautiful scenery of the North Bengal Tea Estates along the way. If your permit is ready, you can enter the border town of Phuentsholing for an overnight stay. If your permit is not yet complete, you will stay overnight at a hotel in Jaigaon. The evening is yours to relax and enjoy at your leisure. <br /><br /></p>
                                                <div className="content-desktop">
                                                    <center><img src={navDay1ImageURL} alt="bhutan B2B tour packages with direct flights" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="bhutan B2B tour packages with direct flights" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day2"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseTwo"
                                            >
                                                <h4>DAY 02: PROCESS THE PERMIT AND TRANSFER TO THIMPU</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseTwo"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingTwo"
                                        >
                                            <div className="accordion-body">
                                                <p>After an early morning breakfast, begin the process of obtaining your offline permit. Our team will assist you in navigating the necessary permit procedures, including biometric registration. Typically, the offline permit processing can take anywhere from 2 to 6 hours, depending on the season and the volume of visitors at the immigration office in Phuentsholing. While waiting, you can enjoy your lunch. Once your permit is ready, you will set off for Thimphu, the capital city of Bhutan. The drive from Phuentsholing to Thimphu takes about 4.5 hours by car. <br /></p>

                                                <p>*** If you have already completed your online permits, head to the Bhutan Gate to finalize the biometric process. This step should take no more than 1 hour. After securing your online permit, you can easily proceed to Thimphu City.<br /></p>
                                                <p>Upon arriving in Thimphu, check in at your hotel in the afternoon or evening. Freshen up and take a leisurely stroll around Thimphu. Discover the vibrant nightlife, enjoy local beer, try karaoke, or relax with a traditional hot stone bath at a spa. After a fulfilling day, have dinner and settle in for the night in Thimphu. <br /></p>
                                                <div className="content-desktop">
                                                    <center><img src={navDay2ImageURL} alt="mumbai to bhutan B2B tour packages booking with direct flights" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="mumbai to bhutan B2B tour packages booking with direct flights" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day3"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseThree"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 03: FULL DAY THIMPHU SIGHTSEEING TOUR</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseThree"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingThree"
                                        >
                                            <div className="accordion-body">
                                                <p>As the sun casts its warm rays across the stunning Thimphu valley, your day begins with a hot cup of Suja (Butter Tea). After enjoying this delightful beverage, head to the breakfast table to savor a delicious spread before setting out for local sightseeing in Thimphu. <br /><br /></p>

                                                <p><b>The National Memorial Chorten</b> - Also known simply as Thimphu Chorten, this site serves as a gathering place for evening prayers. Built in memory of the third King, it is continuously circled by people murmuring mantras and spinning their prayer wheels. The idea for this landmark was proposed by His Majesty Jigme Dorji Wangchuk, the third King of Bhutan, who envisioned a monument symbolizing peace and prosperity for the world. Completed in 1974 after the King's untimely death, it stands as both a memorial to the late King and a beacon of peace.<br /><br /></p>


                                                <p><b>The Clock Tower</b> - Situated in the heart of Thimphu Town, the Clock Tower Square is an architectural marvel you won’t want to miss during your <strong>Bhutan B2B Packages from India</strong>. This tower is distinct from other structures in Bhutan, featuring four clock faces pointing in different directions. The design reflects traditional Bhutanese architecture, with intricate carvings and paintings of floral patterns and dragons. These dragons, symbolizing independence in Bhutanese culture, face each clock, making the tower particularly captivating.<br /><br />

                                                    This location is a popular spot for both locals and travelers, especially in the evening when visitors come to admire the exquisite carvings and vibrant atmosphere. The area around the Clock Tower is bustling with restaurants, gift shops, and hotels, often hosting trade fairs, concerts, and live music events. It's a lively area, perfect for shopping for unique gifts and enjoying a fun picnic at the nearby playground.<br /><br /></p>


                                                <p><b>Sakyamuni Buddha</b> - Atop Kuensel Phodrang hill stands the Great Buddha Dordenma, a majestic 51.5-meter gilded bronze statue that is sure to captivate you. The site, known as Buddha Viewpoint, offers breathtaking views of the valley below.<br /><br /></p>


                                                <p><b>Changangkha Lhakhang</b> - In Thimphu, you will find many monasteries and temples, but Changangkha Lhakhang is the most significant religious structure. Dedicated to Avalokiteshvara, the embodiment of compassion, this 15th-century temple is the oldest in the region and provides stunning views of the Thimphu valley.<br /><br /></p>


                                                <p><b>Takin Zoo</b> - Here, you can observe the national animal of Bhutan, the Takin. This unique species is one of the most endangered animals in the world and is found exclusively in Bhutan, preserved in an open habitat.<br /><br /></p>


                                                <p><b>Trashi Chhoe Dzong</b> - Known as the Fortress of the Glorious Region, this is another must-visit site on your <strong>Bhutan B2B Group Tour</strong>. Located on the west bank of the Wang Chhu River, Trashi Chhoe Dzong is the official coronation site of the fifth King and hosts the annual <strong>Tsechu festival</strong>. Built in 1641 by the revered Shabdrung Ngawang Namgyal, this Dzong was reconstructed in the 1960s using traditional Bhutanese techniques, without nails or architectural blueprints. Overnight at a hotel in Thimphu. <br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay3ImageURL} alt="Perfect Bhutan B2B packages for Tour Operators" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Perfect Bhutan B2B packages for Tour Operators" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day4"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFour"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 04: - THIMPHU TO PUNAKHA TRANSFER </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseFour"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFour"
                                        >
                                            <div className="accordion-body">
                                                <p>As your Hot Ngaja (a sweet Bhutanese milk tea) arrives, take a sip while enjoying the stunning view of the valley from your room. After breakfast, head towards Punakha, the former capital of Bhutan. Punakha is a peaceful escape from the chaos of city life, situated at an elevation of 1,200 meters, which gives it a warm climate in winter and a cool breeze in summer. The valley is renowned for its Red and White Rice farming along the banks of the Pho and Mo Chu rivers. En route, you will visit several attractions. <br /><br /></p>

                                                <p><b>Punakha Dzong :</b> Also known as "<b>The Palace of Great Happiness</b>," Punakha Dzong is beautifully located at the confluence of the Pho Chhu (father) and Mo Chhu (mother) rivers in the Punakha–Wangdue valley. This Dzong is considered the most picturesque of all Bhutan's ancient fortresses and is connected to the mainland by an arched wooden bridge. It houses many precious relics from the time when the successive kings ruled the kingdom from this tranquil valley. <br /><br /></p>

                                                <p><b>Suspension Bridge :</b> This hidden gem in the Punakha Valley connects the rest of the valley to the old administrative center of Punakha Dzongkhag. The entire deck is adorned with colorful prayer flags, making it a perfect spot for birdwatching and photography. <br /><br /></p>

                                                <p><b>Chimi Lhakhang (Fertility Temple) :</b> Perched atop a hillock, Chimi Lhakhang is dedicated to Lama Drukpa Kuenley, known as the 'Divine Madman.' He is famous for using songs, humor, and outrageous behavior to convey his teachings. This temple is believed to bring fertility, and many childless couples visit with the hope of being blessed by Lama Drukpa Kuenley if they pray for a child. The path to the temple follows a picturesque rural trail through lush paddy fields, leading visitors to a small stream, from where it's just a short climb to Chimi Lhakhang. Overnight stay at a hotel in Punakha. <br /><br /><br /></p>
                                                <br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay4ImageURL} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day5"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFive"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 05: PUNAKHA TO PARO TRANSFER & LOCAL SIGHTSEEING </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseFive"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFive"
                                        >
                                            <div className="accordion-body">
                                                <p>In the morning, after breakfast, begin your <strong>Paro Trip</strong> and en route visit: <br /></p>

                                                <p><b>National Museum of Bhutan:</b> Located just above Rinpung Dzong in Paro, the National Museum of Bhutan is a treasure trove that showcases the rich culture of Bhutan. Established in 1968, the museum is housed within the ancient Ta-dzong building. It features an impressive collection of Bhutanese culture and art, with highlights including exquisite paintings and bronze statues that are true masterpieces. The items are displayed in spacious galleries, allowing you to learn extensively about Bhutan's art and culture through these remarkable objects. <br /><br /></p>

                                                <p><b>Drukgyal Dzong:</b> Once a fortress and Buddhist monastery, Drukgyal Dzong is now in ruins, located in the upper part of Paro Valley in Bhutan. The Dzong was likely constructed by Tenzin Drukdra in 1649 at the request of Shabdrung Ngawang Namgyal to commemorate a victory over an invasion from Tibet. Please note that it is currently closed to the public. <br /><br /></p>

                                                <p><b>Ta Dzong:</b> Initially built as a watchtower to protect Rinpung Dzong during the inter-valley wars of the 17th century, Ta Dzong was inaugurated as Bhutan's National Museum in 1968. <br /><br /></p>

                                                <p><b>Rinpung Dzong:</b> Constructed in 1646 by Shabdrung Ngawang Namgyal, the first spiritual and temporal ruler of Bhutan, this Dzong houses the monastic body of Paro as well as the offices of the Dzongda (district administrative head) and Thrimpon (judge) of Paro district. The approach to the Dzong is via a traditional covered bridge known as Nemi Zam. <br /><br /></p>

                                                <p><b>Nya Mey Zam Bridge:</b> Known as "The Bridge with No Fish," this bridge connects Paro Town to the Dzong. It is one of the finest specimens of Bhutanese architecture, with a local belief that seeing a fish in the river is an ill omen. The previous bridge, which could be folded during wartime, was washed away by floods in 1969. <br /><br /></p>

                                                <p><b>Paro Airport View Point:</b> This is one of the best vantage points to enjoy breathtaking views of Paro Valley and Paro Airport. Overlooking the Paro River, it also offers scenic vistas of the Dzongs and monasteries in the area. Overnight at a hotel in Paro. <br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay5ImageURL} alt="Bhutan B2B Package Tour Operator" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="Bhutan B2B Package Tour Operator" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day6"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSix"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 06: PARO, TAKTSANG MONASTERY HIKING</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSix"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSix"
                                        >
                                            <div className="accordion-body">
                                                <p>As the sun rays illuminate the stunning Paro valley, awaken amidst the beauty of Mother Nature and savor the breathtaking views of the river and mountains all in one frame. Enjoy a lavish breakfast spread, ensuring you include some protein, as the climb to the top of the Taksang Monastery will require plenty of energy. <br /><br /></p>

                                                <p><b>Taktsang Monastery:</b> After ascending 1,200 meters on a steep cliff, you will arrive at this sacred site known as the Tiger Nest. Once you reach the summit, take a deep breath of the fresh air and feast your eyes on the spectacular views of the surrounding landscape and the valley below. The Taktsang Monastery was established by Guru Rinpoche, who arrived in Bhutan in 747 CE, and its construction was completed in 1694. Following an adventurous trek lasting about 3 hours, you will discover the Tiger Nest monastery, and if you prefer, you can opt for a horse ride from the parking lot to the top. During your trek, enjoy the glorious sights of Paro town, with its lush green valleys, sparkling river, clear blue sky, and drifting clouds—truly a celebration of nature at its finest. Overnight in Paro. <br /><br /><br /></p>

                                                <h4>SOME FACTS TO NOTE ABOUT - THE TIGER'S NEST MONASTERY</h4>
                                                <br /><br />
                                                <div id="no-more-tables">
                                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">

                                                        <tbody><tr><td>Location</td><td>Approx 11.7 km from Paro town, Bhutan</td></tr>
                                                            <tr><td>Elevation</td><td>3,120 meters (10,236ft) above sea level</td></tr>
                                                            <tr><td>Duration of the Tiger's Nest hike</td><td>5-6 hours (Back and forth)</td></tr>
                                                            <tr><td>Tiger's Nest Trekking Distance</td><td>4 Miles (6.43 Kilometers) - Round Trip</td></tr>
                                                            <tr><td>Tiger's Nest Trek Difficulty</td><td>Moderate - Steep Ascend</td></tr>
                                                            <tr><td>Best Months to Visit Tiger's Nest Monastery</td><td>March, April, May and September, October, November, December</td></tr>
                                                            <tr><td>Gears needed to hike Tiger's Nest</td><td>Normal hiking shoes, Water bottle, Some snacks, energy bars, Camera, Hat, Sunglasses, Sunscreen, Walking Pole/Stick</td></tr>
                                                            <tr><td>Opening time of Tiger's Nest Monastery</td><td>(October-March) 8 am-1 pm and 2 pm-5 pm <br /> (April-September) 8 am-1 pm and 2 pm-6 pm daily</td></tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br /><br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay6ImageURL} alt="B2B Tour Operator for Bhutan" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="B2B Tour Operator for Bhutan" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day7"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 07: PARO TO LATAGURI VIA PHUENTSHOLING</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>After enjoying breakfast at your hotel in Paro, embark on your journey to Lataguri, marking the beginning of your Dooars Package Tour. Lataguri serves as the gateway to Gorumara National Park in West Bengal. This mid-sized national park, nestled in the Dooars region, is characterized by its lush grasslands and dense forests. It is renowned for its diverse wildlife, including the One-Horned Rhino, Wild Elephants, Bison, and numerous Peacocks. The evening is yours to relax and explore at your leisure. Overnight stay in Lataguri.<br /><br /></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day8"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 08: LATAGURI TO BAGDOGRA - <strong>BHUTAN B2B TOUR </strong> ENDS</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>In the early morning, enjoy the <strong>Gorumara Jungle Safari</strong> before heading to Bagdogra Airport. After an early breakfast, carry with you sweet memories of your Bhutan holiday. Cherish your experiences from the <strong>Bhutan B2B Tour Package ex Bagdogra</strong> forever.
                                                    <br /><br /></p>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <br /><br />
                            <div className="container-fluid py-5">
                                <div className="row placetoVisit">
                                    <h3>TOP 10 ATTRACTIONS OF <strong>BHUTAN B2B PACKAGE TOUR FROM BAGDOGRA</strong>
                                        <br /><br /></h3>
                                    <h4>Paro Taktsang (Tiger's Nest Monastery)</h4>
                                    <p>This famous monastery, a top highlight of any <strong>Bhutan B2B itinerary</strong>, is perched on a cliff 900 meters above the Paro Valley. It’s one of Bhutan’s most iconic landmarks and offers stunning views. Ideal for a <strong>Bhutan B2B package operator</strong> looking to offer their clients a unique cultural experience. <br /><br /><br /></p>

                                    <h4>Punakha Dzong</h4>
                                    <p>Known as the <b>Palace of Great Happiness</b>, this beautiful fortress sits at the meeting point of two rivers. It's a must-visit for any <strong>Bhutan B2B package</strong>, offering a blend of stunning architecture and peaceful surroundings that make it an unforgettable part of any <strong>Bhutan B2B cost</strong> analysis for operators. <br /><br /><br /></p>

                                    <h4>Thimphu</h4>
                                    <p>The capital city of Bhutan is a perfect combination of modern life and tradition. From the Buddha Dordenma statue to the National Memorial Chorten, there’s much to explore here. For <strong>Bhutan B2B packages from Bagdogra</strong>, Thimphu’s vibrant markets and historical sites are essential for an authentic Bhutan experience. <br /><br /><br /></p>

                                    <h4>Bumthang Valley</h4>
                                    <p>Known as the spiritual heart of Bhutan, Bumthang Valley is home to many ancient temples and monasteries, making it an important destination in any <strong>Bhutan B2B package tour from Bagdogra Airport</strong>. For operators focusing on culture and nature, this is a key destination for any <strong>Bhutan B2B itinerary</strong>. <br /><br /><br /></p>

                                    <h4>Gangtey Valley</h4>
                                    <p>A peaceful valley famous for the Gangtey Monastery and its incredible hiking trails, Gangtey is a must-see for <strong>Bhutan B2B operators</strong> offering adventure and wildlife experiences. This is especially popular among clients wishing to see the rare black-necked cranes. <br /><br /><br /></p>

                                    <h4>Dochula Pass</h4>
                                    <p>Located between Thimphu and Punakha, this scenic pass offers amazing views of the Himalayan mountain range. The 108 chortens (stupas) here, built in memory of Bhutanese soldiers, make it a unique cultural stop for any <strong>Bhutan B2B package from Bagdogra</strong> or elsewhere. <br /><br /><br /></p>

                                    <h4>Wangdue Phodrang Dzong</h4>
                                    <p>This impressive fortress sits atop a hill offering stunning views of surrounding valleys. It’s a perfect location for a <strong>Bhutan B2B package operator</strong> to highlight Bhutanese architecture and history. The dzong is a perfect place for those interested in Bhutan's royal past. <br /><br /><br /></p>

                                    <h4>The National Museum of Bhutan</h4>
                                    <p>Located in Paro, the National Museum is an essential stop on any <strong>Bhutan B2B itinerary</strong>. It showcases Bhutan’s history, traditional artifacts, and stunning artwork. This is a must-see for art and culture lovers in any <strong>Bhutan B2B package from Bagdogra</strong>. <br /><br /><br /></p>

                                    <h4>Chimi Lhakhang</h4>
                                    <p>Known as the Fertility Temple, this small temple in Punakha is dedicated to the "Divine Madman," Lama Drukpa Kuenley. Often included in <strong>Bhutan B2B itineraries for couples</strong>, it’s believed to bless those wishing to have children. A unique stop in any Bhutan B2B package. <br /><br /><br /></p>

                                    <h4>Rinpung Dzong</h4>
                                    <p>Located in Paro, Rinpung Dzong is an excellent example of traditional Bhutanese architecture. Housing a monastery, this dzong is an important cultural site in Bhutan, ideal for any <strong>Bhutan B2B package operator</strong> aiming to provide a deep dive into Bhutanese heritage. <br /><br /><br /></p>
                                </div>
                                <br />   <br />   <br />
                                <div className="row placetoVisit">
                                    <h3>PLACES YOU MUST VISIT WITH <strong>BHUTAN B2B TRAVEL PACKAGE</strong></h3><br />
                                    <ul>
                                        <li>Thimphu - Buddha Dordenma <br /></li>
                                        <li>Paro - Taktsang Monastery <br /></li>
                                        <li>Thimphu - Dagala Thousand Lakes <br /></li>
                                        <li>Punakha - Punakha Suspension Bridge <br /></li>
                                        <li>Thimphu - National Memorial Chorten <br /></li>
                                        <li>Trashigang Dzong - Trashigang District <br /></li>
                                        <li>Thimphu - Tashichho Dzong <br /></li>
                                        <li>Thimphu - Simtokha Dzong <br /></li>
                                        <li>Trongsa - Trongsa Dzong <br /></li>
                                        <li>Paro - Rinpung Dzong <br /></li>
                                        <li>Punakha - Punakha Dzong <br /></li>
                                        <li>Gangtey Monastery - Wangdue Phodrang <br /></li>
                                        <li>Punakha - Chimi Lhakhang Temple <br /></li>
                                        <li>Dochula Pass - On the way from Thimphu to Punakha <br /></li>
                                        <br /><br /><br />
                                    </ul>
                                    <h3>RELATED SEARCHES FOR <strong>BHUTAN B2B PACKAGES FROM INDIA</strong><br /></h3>
                                    <ul><li>Bhutan B2B Travel Packages</li>
                                        <li>Bhutan B2B Tours for Businesses</li>
                                        <li>Corporate Travel Packages to Bhutan</li>
                                        <li>Bhutan B2B Group Tours</li>
                                        <li>Bhutan B2B Adventure Travel</li>
                                        <li>Bhutan B2B Cultural Tours</li>
                                        <li>Bhutan B2B Wellness Retreats</li>
                                        <li>Bhutan B2B Incentive Travel</li>
                                        <li>Bhutan B2B Conference Packages</li>
                                        <li>Bhutan B2B Customized Tours</li>
                                        <li>Bhutan B2B Travel Agents</li>
                                        <li>India's No 1 Travel DMC for Bhutan</li>
                                        <li>Bhutan B2B Agents</li>
                                        <li>Best B2B Travel Agency of Bhutan</li>
                                        <li>Best Bhutan B2B Travel Agency in India</li>
                                        <li>Bhutan B2B Tour Operator in India</li>
                                        <li>Bhutan B2B Tour Packages</li>
                                        <li>Bhutan B2B DMC Travel Company</li>
                                    </ul>
                                </div>
                                <br /><br />
                            </div>
                            <br />
                            {/* <!--FOQ--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                Frequently Ask <span>Questions</span>
                                            </h2>
                                            <h4>Related Questions for <strong>Bhutan B2B Tour Packages</strong></h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="accordion accordion-flush faQStyle"
                                        id="accordionFlushExample"
                                    >
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingPackages">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapsePackages"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapsePackages"
                                                >
                                                    What is included in your Bhutan B2B packages?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapsePackages"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingPackages"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Our <strong>Bhutan B2B packages</strong> typically include accommodation, transportation, guided tours, meals, and necessary permits. We customize these packages based on client needs to ensure a seamless experience. <br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingPermits">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapsePermits"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapsePermits"
                                                >
                                                    How can Adorable Vacation assist with Bhutan travel permits?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapsePermits"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingPermits"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    As a specialist <strong>Bhutan DMC</strong> and TCB License Holder [License No: 1053228], we handle the entire permit application process online/offline for your clients, ensuring they receive the necessary documentation for a hassle-free experience. Our dedicated staff in Bhutan ensures 100% confirmation of permits.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingBusiness">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseBusiness"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseBusiness"
                                                >
                                                    What types of businesses can benefit from Bhutan B2B packages?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseBusiness"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingBusiness"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Our <strong>Bhutan B2B packages</strong> cater to travel agencies, corporate clients, MICE, and tour operators. We offer tailored solutions for group tours, incentive trips, and special events.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingGroups">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseGroups"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseGroups"
                                                >
                                                    Are your Bhutan tours suitable for large groups?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseGroups"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingGroups"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Yes, we specialize in managing large group tours. We recently completed an 80-person Rotary Club tour with almost zero complaints. All guests shared positive feedback and enjoyed their experience with Adorable Vacation.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingDestinations">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseDestinations"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseDestinations"
                                                >
                                                    What destinations are included in your Bhutan B2B packages?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseDestinations"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingDestinations"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Our <strong>Bhutan B2B packages</strong> cover popular destinations like Thimphu, Paro, Punakha, and Phobjikha Valley. We also offer customized trips to more remote areas such as Gasa, Throngsa, Trashigang, Dagana, Bumthang, Zhemgang, and Trashiyangtse.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingGuides">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseGuides"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseGuides"
                                                >
                                                    Do you provide local guides for Bhutan B2B Tour Package?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseGuides"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingGuides"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Absolutely! We provide experienced, humble, and knowledgeable local guides who are well-versed in Bhutanese culture, history, and geography, ensuring a rich experience for your clients.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingQuality">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseQuality"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseQuality"
                                                >
                                                    How do you ensure the quality of your services?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseQuality"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingQuality"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    We prioritize high-quality services through partnerships with local vendors, staff training, and a commitment to customer satisfaction.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingCustomize">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseCustomize"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseCustomize"
                                                >
                                                    Can we customize our Bhutan B2B package?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseCustomize"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingCustomize"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Yes, we offer fully customizable packages to meet client needs, including itinerary adjustments, special accommodations, and activities.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingClimate">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseClimate"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseClimate"
                                                >
                                                    What should clients expect regarding the climate in Bhutan?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseClimate"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingClimate"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Bhutan’s climate varies by region and season. We provide detailed information on the best times to visit and what to expect to help clients prepare.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingContact">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseContact"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseContact"
                                                >
                                                    How can I get in touch with Adorable Vacation for booking Bhutan B2B Package?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseContact"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingContact"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    You can contact us through our website or directly via phone or email. Our Bhutan Specialists, Mr. Soumalya [+91-98833-59713] and Ms. Sonali [+91-96747-38480], are ready to assist you with any inquiries.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>
                                                <b>
                                                    Bhutan <span className="tomato">Theme Package</span>
                                                </b>
                                            </h3>
                                            <h4>Similar Mountain Trip</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        id="carouselExampleControls"
                                        className="carousel slide"
                                        data-bs-ride="carousel"
                                    >
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <Slider {...settings}>{themePackagesDataView}</Slider>
                                            </div>
                                        </div>
                                    </div>

                                    <br />
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>
                                                <b>
                                                    Bhutan <span className="tomato">Tour Reviews</span>
                                                </b>
                                            </h3>
                                            <h4>
                                                <i>"Travel without Trouble"</i>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1 ">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">
                                            {reviewersDataView}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3">

                            <BookingBox />

                        </div>
                        {this.state.showModal && (
                            <div className="modal d-flex align-items-center justify-content-center" style={{ display: this.state.showModal ? "block" : "none" }}>
                                <div className="modal-dialog">
                                    <div className="modal-content cust-modContent" ref={this.modalRef}>
                                        <div className="modal-header">
                                            <h4 className="modal-title ms-auto" id="exampleModalLabel">Get a Free Callback &nbsp;&nbsp;<i className="fa fa-phone fa-rotate-90"></i>
                                            </h4>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.onCloseModal}></button>
                                        </div>
                                        <div className="modal-body">
                                            <form className="row g-3">
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name *"
                                                        name="firstName"
                                                        value={this.state.firstName}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="10 Digit Mobile No.*"
                                                        name="mobile"
                                                        value={this.state.mobile}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Email "
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Comments"
                                                        rows="2"
                                                        name="comments"
                                                        value={this.state.comments}
                                                        onChange={this.onChangeHandler}></textarea>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <div className='container'>
                                                <div className="row">
                                                    <div className='col-sm-4'>
                                                        {
                                                            (this.state.showLoader) &&
                                                            <div className='pull-left'><Digital size={25} /></div>
                                                        }
                                                    </div>
                                                    <div className='col-sm-8 '>
                                                        <div className='pull-right'>
                                                            {/* <button type="button" className="btn btn-Warning mr-1" onClick={this.onCloseModal}>Close</button> */}
                                                            <button type="button" className="btn btn-Warning btn-lg" onClick={this.onRequestCallbackSubmit}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default Bhutan_B2B_7N_8D_Package_Bagdogra_Airport;