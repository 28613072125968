import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from "../Egypt/InclusionExclusion_Egypt_Tour_Package_8N_9D";

class Egypt_8N_9D_Tour_Package extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: 'tab1',
      daywiseActiveTab: 'day1',
      currentPage: 1,
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  handleDayWiseTabClick = (tab) => {
    this.setState({
      daywiseActiveTab: tab,
    });
  };
  handleClick1 = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919883359713&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
  handlePageChange = (direction) => {
    this.setState((prevState) => {
      const newPage = prevState.currentPage + direction;
      return { currentPage: newPage };
    });
  };
  render() {
    const rows = [
      { date: '24th Sept - 30th Sept', availability: 'Available', price: '84,500' },
      { date: '01st Oct - 07th Oct', availability: 'Available', price: '84,500' },
      { date: '04th Oct - 10th Oct', availability: 'Available', price: '84,500' },
      { date: '08th Oct - 14th Oct', availability: 'Available', price: '84,500' },
      { date: '11th Oct - 17th Oct', availability: 'Available', price: '84,500' },
      { date: '15th Oct - 21st Oct', availability: 'Available', price: '84,500' },
      { date: '18th Oct - 24th Oct', availability: 'Available', price: '84,500' },
      { date: '22nd Oct - 28th Oct', availability: 'Available', price: '84,500' },
      { date: '25th Oct - 31st Oct', availability: 'Available', price: '84,500' },
      { date: '29th Oct - 04th Nov', availability: 'Available', price: '84,500' },
      { date: '1st Nov - 07th Nov', availability: 'Available', price: '84,500' },
      { date: '05th Nov - 11th Nov', availability: 'Available', price: '84,500' },
    ];

    const rowsPerPage = 6;
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
    const { customActiveTab, daywiseActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Tour-Package-from-Kolkata.webp";
    const packageTopBanner2ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Package-Tour-from-Kolkata.webp";
    const sightseen1ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Book-Egypt-Tour-Packages-from-kolkata.webp";
    const sightseen2ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Holiday-Package-from-Kolkata.webp";
    const sightseen3ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Holiday-Tour-Package-from-Kolkata.webp";
    const sightseen4ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-to-Kolkata-Tour-Package.webp";
    const sightseen5ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Tour-from-Kolkata.webp";
    const sightseen6ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Tour-Itinerary-from-Kolkata.webp";
    const sightseen7ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Tour-Package-Cost-from-Kolkata.webp";
    const sightseen8ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Tour-Package-from-India.webp";
    const sightseen9ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Tour-Package-from-Kolkata-With-Flight.webp";
    const sightseen10ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Tour-Package-Itinerary-from-Kolkata.webp";
    const sightseen11ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Tour-Plan-From-Kolkata.webp";
    const sightseen12ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Tour-Cost-From-India.webp";

    const navDay1ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Package-Tour-Itinerary-from-Kolkata.webp";
    const navDay2ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Family-Tour-Package-from-Kolkata.webp";
    const navDay3ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Tour-Packages-from-Kolkata.webp";
    const navDay4ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Trip-from-Kolkata.webp";
    const navDay5ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Tour-Booking-from-Kolkata.webp";
    const navDay6ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Holiday-Packages-from-Kolkata.webp";
    const navDay7ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Travel-Packages-from-Kolkata.webp";
    const navDay8ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Package-Tour-Cost.webp";
    const navDay9ImageURL = ApiURL.Egypt_8N_9D_PackageImageURL + "/Egypt-Packages-from-Kolkata.webp";
    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL =
        ApiURL.Egypt_8N_9D_PackageImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container">
                    <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center><strong>Egypt Package Tour from Kolkata</strong> - Best Deal From Adorable Vcation LLP</center></h1>
                    <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Egypt Package Tour from Kolkata </strong> - Best Deal From Adorable Vcation LLP</center>
                      <hr />
                    </h3>
                    <div className="row">
                      <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                        3 Cities:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Cairo
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Nile Cruise
                        </a>{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Hughada
                        </a>
                      </div>
                      <div className="reviews mx-5">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                    <p className="p-2">
                      If you are looking for a memorable <strong>Egypt Trip from Kolkata</strong>, you have come to the right place!
                    </p>
                    <hr />
                    <br />
                    <h2 className="content-desktop p-2" style={{ fontSize: "1.8vw" }}>IF YOU ARE LOOKING FOR A WONDERFUL <strong>EGYPT TRIP FROM KOLKATA</strong>, INDIA, YOU HAVE COME TO THE RIGHT PLACE <a href='tel:9883359713' class="tomato">☎ CALL Mr. SOUMALYA +91-98833-59713</a></h2>
                    <h2 className="content-mobile p-2" style={{ fontSize: "4.5vw" }}>IF YOU ARE LOOKING FOR A WONDERFUL <strong>EGYPT TRIP FROM KOLKATA</strong>, INDIA, YOU HAVE COME TO THE RIGHT PLACE <a href='tel:9883359713' class="tomato">☎ CALL Mr. SOUMALYA +91-98833-59713</a></h2>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> <b>Egypt: A Land of Mystery</b></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> <b>Egypt: A Land of Mystery</b></h4>
                </div>
                <div className="card-body">
                  <p>The first time you probably heard about Egypt was in your school days, with stories of Pyramids and Mummies in your textbooks. Egypt has always been an exciting subject in books and movies like Gods of Egypt, The Mummy, and Death on the Nile. It's one of the world's oldest and greatest civilizations, which you can experience through an <strong>Egypt Tour Package from Kolkata</strong>.</p>
                  <p>Egypt is not just about the famous Pyramids of Giza or the Great Sphinx; it's also about the mighty Nile River, its friendly people, and its rich cultural and historical heritage. An <strong>Egypt Tour Package from India</strong> can offer much more than you imagine if you take the time to explore this incredible country, often called "The Gift of the Nile." Cruise along the Nile, discover Egypt's deep history, and experience the best of it with Adorable Vacation LLP – specialists in <strong>Egypt Packages from India</strong>.</p>
                  <p>Adorable Vacation LLP offers a wide range of <strong>Egypt Tour Booking from Kolkata</strong> with fantastic deals and discounts. Plus, all tours can be customized to match your preferences. The <strong>Egypt Tour Packages from Kolkata</strong> are carefully planned and locally guided to ensure you have the best experience. The Adorable Vacation team is also available 24/7 to assist you with any travel questions.</p>
                  <p>Whether you're planning a group or family trip, Adorable Vacation has the perfect <strong>Egypt Holiday Packages from Kolkata</strong> for you. Travel back in time as you take in the breathtaking beauty of Egypt, including the mythical Sphinx and the awe-inspiring Pyramids of Giza. Beyond its deserts and ancient landmarks, Egypt’s beautiful landscapes and modern comforts will leave you amazed. Don’t wait any longer – choose your <strong>Egypt Tour from Kolkata</strong> with flights at unbeatable prices and explore this land of wonders today!</p>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen1ImageURL} alt="Book Egypt Tour Packages from kolkata" />
                          <img src={sightseen2ImageURL} alt="Egypt Holiday Package from Kolkata" />
                          <img src={sightseen3ImageURL} alt="Egypt Holiday Tour Package from Kolkata" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <br /> <br /> <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>EGYPT TOUR PACKAGE DURATION : 8N | 9D</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>EGYPT TOUR PACKAGE DURATION : 8N | 9D</strong></h4>
                </div>
                <div className="card-body">
                  <p>Destinations Covered in <strong>Egypt Package Tour from Kolkata</strong> : Cairo (2N), Overnight Train (1N) | Nile Cruise (3N) | Hughada (2N)
                  </p>
                  <p><b>Pickup / Fly-in</b> : Cairo International Airport [CAI]</p>
                  <p><b>Drop / Fly-out</b> : Luxor International Airport [LXR]</p>
                  <p><b>Egypt Package Tour Cost</b> : 1,85,000 /PP GST & TCS EXTRA (Inc.Airfare)</p>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen4ImageURL} alt="Egypt to Kolkata Tour Package" />
                          <img src={sightseen5ImageURL} alt="Egypt Tour from Kolkata" />
                          <img src={sightseen6ImageURL} alt="Egypt Tour Itinerary from Kolkata" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>HOW CAN YOU TRAVEL</b> <strong>EGYPT TOUR PACKAGE FROM KOLKATA WITH FLIGHT</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>HOW CAN YOU TRAVEL</b> <strong>EGYPT TOUR PACKAGE FROM KOLKATA WITH FLIGHT</strong>?</h4>
                </div>
                <div className="card-body">
                  <p>Currently, there are no direct flights from Kolkata International Airport (CCU) to Cairo International Airport (CAI). <strong>Flights from Kolkata to Cairo</strong> usually have one or more stopovers at other airports. Flight schedules and routes can change, so it's important to check with airlines or travel agencies for the latest details. Below are some possible stopover options for <strong>Egypt Tour Packages from Kolkata with Airfare/Flight</strong>.</p>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>Flight Name</th>
                          <th>Dep. Time</th>
                          <th>Arr. Time</th>
                          <th>Travel Time</th>
                          <th>Stop Over</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="Flight Name">Qatar Airways
                            (QR 541, QR 1303)
                          </td>
                          <td data-title="Dep. Time">15:50 (CCU)</td>
                          <td data-title="Arr. Time">12:10 (CAI)</td>
                          <td data-title="Travel Time">10h 50m</td>
                          <td data-title="Stop Over">Doha</td>
                        </tr>
                        <tr>
                          <td data-title="Flight Name">Emirates (EK 571, EK 923)</td>
                          <td data-title="Dep. Time">09:45 (CCU)</td>
                          <td data-title="Arr. Time">18:05 (CAI)</td>
                          <td data-title="Travel Time">10h 50m</td>
                          <td data-title="Stop Over">Dubai</td>
                        </tr>
                        <tr>
                          <td data-title="Flight Name">Air Arabia Abu Dhabi (3L 167, 3L 422)</td>
                          <td data-title="Dep. Time">01:00 (CCU)</td>
                          <td data-title="Arr. Time">11:05 (CAI)</td>
                          <td data-title="Travel Time">13h 35m</td>
                          <td data-title="Stop Over">Abu Dhabi</td>
                        </tr>
                        <tr>
                          <td data-title="Flight Name">Emirates (EK 571, MS 913)</td>
                          <td data-title="Dep. Time">09:10 (CCU)</td>
                          <td data-title="Arr. Time">18:20 (CAI)</td>
                          <td data-title="Travel Time">12h 40m</td>
                          <td data-title="Stop Over">Dubai</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />

              {/* start short itinerary */}

              <div className="card">
                <div className="card-body">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="table-danger">
                        <th>ITINERARY FOR 8 NIGHT 9 DAYS EGYPT TOUR</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingOne"
                        >
                          <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                            <span>
                              CAIRO  ARRIVAL
                            </span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingTwo"
                        >
                          <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                            <span>CAIRO LOCAL SIGHTSEEING (B/L)</span>
                          </a>

                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingThree"
                        >
                          <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                            <span>CAIRO TO ALEXANDRIA DAY EXCURSION (B/L)</span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingFour"
                        >
                          <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                            <span>CAIRO / ASWAN / NILE CRUISE (B/L/D)</span>
                          </a>

                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingFive"
                        >
                          <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                            <span>ASWAN – KOM EMBO - EDFU (B/L/D)</span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingSix"
                        >
                          <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                            <span>EDFU – ESNA – LUXOR (B/L/D)</span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingSeven"
                        >
                          <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                            <span>LUXOR TO HURGHADA (B/L/D) </span>
                          </a>

                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingEight"
                        >
                          <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                            <span>HURGHADA(B/L/D)</span>
                          </a>

                        </td>
                      </tr>
                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingNine"
                        >
                          <a href="#day9" style={{ color: 'black' }}><b>DAY 9 → </b>
                            <span>HURGHADA / CAIRO (B)</span>
                          </a>

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <!--end Short description--> */}

              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>WHEN IS THE BEST TIME TO BOOK</b> <strong>EGYPT TOUR PACKAGES FROM KOLKATA</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>WHEN IS THE BEST TIME TO BOOK</b> <strong>EGYPT TOUR PACKAGES FROM KOLKATA</strong>?</h4>
                </div>
                <div className="card-body">
                  <p>To choose the best time to book an <strong>Egypt Packages from Kolkata</strong>, it's important to think about the weather and tourist seasons. Here's some advice from Adorable Vacation LLP to help you plan:</p>
                  <br />
                  <h4><b>Peak Tourist Season: </b></h4>
                  <p>The most popular time to visit Egypt is during the winter months, from November to February. The weather is mild and comfortable, making it ideal for sightseeing.</p>
                  <br />
                  <h4><b>Shoulder Seasons:</b></h4>
                  <p>March to April and September to October are also good times to visit. The weather is still pleasant, and there are fewer tourists compared to the winter season.</p>
                  <br />
                  <h4><b>Avoid Summer:</b></h4>
                  <p>Egypt's summer, from May to August, is extremely hot. Temperatures can go over 40°C (104°F), making outdoor activities uncomfortable.</p>
                  <br />
                  <h4><b>Trip Duration:</b></h4>
                  <p>Most <strong>Egypt Tour Plan from Kolkata</strong> last 7 to 10 days, depending on the itinerary. Keep this in mind when planning your trip.<br />
                    In summary, the best time to <strong>Book Egypt Tour Packages from Kolkata</strong> is during the winter months (November to February) or the shoulder seasons (March to April and September to October) for a more comfortable and enjoyable experience.
                  </p>

                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen7ImageURL} alt="Egypt Tour Package Cost from Kolkata" />
                          <img src={sightseen8ImageURL} alt="Egypt Tour Package from India" />
                          <img src={sightseen9ImageURL} alt="Egypt Tour Package Itinerary from Kolkata" />

                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>

              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>IMPORTANT FACTS TO KNOW BEFORE BOOKING EGYPT TRAVEL PACKAGES FROM INDIA</b></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>IMPORTANT FACTS TO KNOW BEFORE BOOKING EGYPT TRAVEL PACKAGES FROM INDIA</b></h4>
                </div>
                <div className="card-body">
                  <p>If you're planning a trip to <strong>Egypt Tour Package from India</strong>, Adorable Vacation offers a variety of <strong>Egypt Holiday Package from India</strong> to suit your needs. Whether it's a family vacation, a romantic getaway, a guided tour for your parents, or a unique solo trip, we can help make your dream <strong>Egypt trip</strong> a reality. Before booking, here are some key things to keep in mind:</p>
                  <p><b>Visa Requirements</b> : Most tourists need a visa to enter Egypt. You can get it on arrival or from Egyptian embassies or consulates in advance.</p>
                  <p><b>Travel Insurance</b> : Make sure you have travel insurance that covers medical emergencies, trip cancellations, and other unexpected events.</p>
                  <p><b>Peak Tourist Seasons</b> : November to February is the peak season. Prices are higher, and popular sites may be crowded.</p>
                  <p><b>Shoulder Seasons</b>: For lower prices and fewer crowds, consider traveling in the shoulder seasons, from March to April or September to October.</p>
                  <p><b>Weather</b>: Egypt can be very hot, especially from May to August. Be prepared for high temperatures.</p>
                  <p><b>Health Precautions</b>: Consult your doctor for any recommended vaccinations and health advice before your trip.</p>
                  <p><b>Currency</b>: The local currency is the Egyptian Pound (EGP). Make sure to carry some local currency or international credit/debit cards.</p>
                  <p><b>Language</b>: Arabic is the official language, but English is widely spoken in tourist areas. Learning a few basic Arabic phrases can be helpful.</p>
                  <p><b>Cultural Etiquette</b>: Respect local customs and dress modestly, especially when visiting religious sites.</p>
                  <p><b>Safety</b>: Egypt is generally safe for tourists, but stay alert in crowded areas and follow local advice.</p>
                  <p><b>Food and Water</b>: Drink bottled water and be cautious with street food. Stick to well-known restaurants and hotels.</p>
                  <p><b>Guided Tours</b>: Booking guided tours for major attractions can enhance your experience and provide valuable insights.</p>
                  <p><b>Currency Exchange</b>: Always exchange money at authorized banks or currency exchange offices. Avoid street exchanges.</p>
                  <p><b>Electricity</b>: Egypt uses 220V electricity with Type C and F plugs. Bring an adapter if needed.</p>
                  <p><b>Photography</b>: Ask permission before taking photos of locals or restricted areas, such as military sites.</p>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen10ImageURL} alt="Egypt Tour Package Itinerary from Kolkata" />
                          <img src={sightseen11ImageURL} alt="Egypt Tour Plan From Kolkata" />
                          <img src={sightseen12ImageURL} alt="Egypt Tour Cost From India" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>

              <br /><br />
              <div className="row py-5">
                <div className="col-md-12">
                  <div className="site-heading text-center">
                    <h2>
                      DAY WISE <strong><span>EGYPT TOUR</span> PLAN FROM KOLKATA</strong>
                    </h2>
                    <h4>7 Nights 8 Days Egypt Tour Itinerary from Kolkata</h4>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="accordion"
                  id="accordionPanelsStayOpenExample"
                >
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day1"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        DAY 01: CAIRO ARRIVAL
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div className="accordion-body">
                        <p>Welcome to Cairo - The capital of Egypt. Upon arrival at Cairo International Airport, our local representative welcomes you. You will be dropped to your hotel in Cairo.Check in & Freshen up, Overnight in Cairo.
                        </p>
                        <br /> <br />
                        <div className="content-desktop">
                          <center><img src={navDay1ImageURL} alt="Egypt Package Tour Itinerary from Kolkata" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Egypt Package Tour Itinerary from Kolkata" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day2"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        DAY 02: CAIRO LOCAL SIGHTSEEING(B/L)
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTwo"
                    >
                      <div className="accordion-body">
                        <p>
                          Breakfast at the hotel, then we start exploring ancient Egypt by visiting the sole surviving Wonder of the Ancient World, Giza’s Pyramids and the surrounding area.The Giza plateau; Visit the Pyramids & Sphinx Then move to the Papyrus, perfume & Jewelry Shops.Lunch at Indian Restaurant Proceed to visit the National Museum of Civilization & Mummies Hall, Overnight in Cairo.
                        </p>
                        <br /><br />
                        <div className="content-desktop">
                          <center><img src={navDay2ImageURL} alt="Egypt Family Tour Package from Kolkata" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="Egypt Family Tour Package from Kolkata" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day3"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 03: CAIRO TO Alexandria DAY EXCURSION (B/L)
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseThree"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingThree"
                    >
                      <div className="accordion-body">
                        <p>Breakfast at the hotel
                          Your guide will be waiting for you to visit 2nd capital of Egypt, the pearl of Mediterranean, Alexandria which is around 3 hrs. drive.Visit the Pompey’s Pillar & Catacombs.Lunch at Local Restaurant,Photo Stop at Qaitbay Fort,Drop to Cairo station for Aswan.Overnight on board train (Dinner incl).
                        </p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay3ImageURL} alt="Egypt Tour Packages from Kolkata" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Egypt Tour Packages from Kolkata" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day4"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFour"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        Day 04: - CAIRO / ASWAN / NILE CRUISE (B/L/D)
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseFour"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFour"
                    >
                      <div className="accordion-body">
                        <p>After on board Breakfast Meet & assist upon arrival at Aswan Railway Station & transfer to the City,Visit the High Dam, the Philae temple Check-In in the World Famous Nile Criuse, Lunch on board, Dinner on board, Overnight in Aswan.
                        </p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay4ImageURL} alt="Egypt Trip from Kolkata" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="Egypt Trip from Kolkata" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day5"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFive"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 05: ASWAN – KOM EMBO - EDFU (B/L/D)
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseFive"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFive"
                    >
                      <div className="accordion-body">
                        <p>
                          Breakfast Box
                          Early morning transfer by A/C Vehicle to visit Abu Simbel Temple
                          Drive back to Aswan & start Sailing to Kom Ombo
                          Lunch on board
                          Visit the Temple shared by two gods Sobek &Haeroris in Kom Ombo,
                          Sailing to Edfu,
                          Dinner on board,
                          Overnight in Edfu.
                        </p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay5ImageURL} alt="Egypt Tour Booking from Kolkata" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="Egypt Tour Booking from Kolkata" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day6"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSix"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 06: EDFU – ESNA – LUXOR (B/L/D)
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseSix"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSix"
                    >
                      <div className="accordion-body">
                        <p>
                          Visit of Horus Temple in Edfu.
                          Breakfast on board.
                          sailing to Luxor via Esna
                          Lunch on board.
                          Dinner on board.
                          Overnight in Luxor.
                        </p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay6ImageURL} alt="Egypt Holiday Packages from Kolkata" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="Egypt Holiday Packages from Kolkata" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day7"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 07: LUXOR TO  HURGHADA (B/L/D)
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>
                          Breakfast on board & Check- out after breakfast
                          Visit West Bank; Valley of the Kings, Temple of Hatshepsut & Colossi of Memnon, Lunch at Local Restaurant,
                          Visit Karnak & Luxor Temples.
                        </p>
                        <p>
                          <b>Optional Tour</b> : Early morning Hot air balloon ride in Luxor - Experience a new side of Luxor, from the sky, with a 45 minutes hot air balloon experience. Breakfast box will be provided. Our representative will pick you up from your hotel around 4am and take you to the launch spot, where you’ll get a quick safety briefing before floating up to the sky. As you soar over the ancient city, your pilot guide will point out various historic attractions and tell you a bit about the area’s rich ancient history. Drop to hotel post tour. (Booking with Certified supplier only),    Drive by Private A/C Vehicle to Hurghada, Dinner & Overnight in Hurghada
                        </p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay7ImageURL} alt="Egypt Travel Packages from Kolkata" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="Egypt Travel Packages from Kolkata" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day8"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTen"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 08: HURGHADA(B/L/D)
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTen"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTen"
                    >
                      <div className="accordion-body">
                        <p>
                          Breakfast
                          Free at Leisure
                          Lunch & Dinner at Hotel
                          Overnight Hurghada
                        </p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay8ImageURL} alt="Egypt Package Tour Cost" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="Egypt Package Tour Cost" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day9"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTen"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 09: HURGHADA  / CAIRO (B)
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTen"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTen"
                    >
                      <div className="accordion-body">
                        <p>
                          Breakfast & Check out and Drive to Cairo Airport for Depart
                        </p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay9ImageURL} alt="Egypt Packages from Kolkata" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay9ImageURL} style={{ width: '320px' }} alt="Egypt Packages from Kolkata" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br /><br />

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">{<InclusionExclusion />}</div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHY YOU SHOULD VISIT <strong>EGYPT TRIP FROM KOLKATA</strong> AT LEAST ONCE IN YOUR LIFETIME</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY YOU SHOULD VISIT <strong>EGYPT TRIP FROM KOLKATA</strong> AT LEAST ONCE IN YOUR LIFETIME</h4>
                </div>
                <div className="card-body">
                  <p>Egypt is a magical land with one of the oldest civilizations in the world. Known for its ancient treasures and rich history, Egypt offers much more than just its famous landmarks. From beautiful beaches and unique natural wonders to impressive architecture and cultural experiences, an <strong>Magical Egypt Tour from Kolkata</strong>, India is definitely worth adding to your bucket list.</p>
                  <p><b>Explore the Oldest Civilization on Earth</b>:
                    Egypt is home to one of the most ancient and fascinating civilizations. The Great Pyramids of Giza, the last remaining wonder of the ancient world, along with ancient statues and tombs, continue to amaze visitors. Archaeologists are still uncovering new relics from ancient Egypt today.
                  </p>
                  <p><b>Cruise the Longest River on Earth</b>:
                    Don't miss a cruise on the Nile, the world’s longest river, stretching 6,695 km (4,160 miles). Sail from Luxor to Aswan, passing by ancient temples and villages along the way.
                  </p>
                  <p><b>Follow in the Footsteps of Moses</b> :
                    Mount Sinai, also called Mount Moses, is a holy site for Muslims, Christians, and Jews. It's believed to be where the prophet Moses received the Ten Commandments from God. This beautiful peninsula is also popular for hiking and climbing.
                  </p>
                  <p><b>Dive into the Red Sea</b>: The Red Sea is one of the best places in Egypt for diving. With colorful coral reefs and unique fish, it offers an unforgettable underwater experience. You can explore shipwrecks, walls, pinnacles, and more.</p>
                  <p><b>Savor Delicious Egyptian Cuisine</b>: Enjoy mouthwatering Egyptian dishes like kebab, kofta, shawarma, and baba ghanoush. For international food lovers, cities like Cairo also offer a wide range of global cuisines.</p>
                  <p><b>Go on a Desert Safari</b>: With much of Egypt being desert, it’s a perfect place for an adventure safari. Discover the vast landscape by taking a safari camp trip to fully experience the desert.</p>
                  <p><b>Admire Egypt’s Incredible Art and Architecture</b>: Cairo is home to some of the finest Islamic architecture and art in the world. Visit historical mosques like the Mosque of Ibn Tulun, the Mosque of Muhammad Ali, and the Mosque-Madrassa of Sultan Hassan to explore Egypt’s artistic and cultural history.</p>
                  <p><b>Relax on Egypt’s Beautiful Beaches</b>: After exploring the cities, unwind on Egypt’s stunning beaches along the Red Sea. Whether you're at a popular resort or a hidden beach, you can enjoy water sports, shopping, and entertainment, or simply relax by the shore.</p>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>BEST CITIES TO VISIT DURING AN MESMERISING <strong>EGYPT TOUR FROM INDIA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST CITIES TO VISIT DURING AN MESMERISING <strong>EGYPT TOUR FROM INDIA</strong></h4>
                </div>
                <div className="card-body">
                  <p>
                    Egypt is a fascinating country with one of the oldest civilizations on Earth. Famous for its ancient treasures, it also offers stunning beaches, unique architecture, and many exciting activities. Here are some of the best cities to explore during your <strong>Egypt Packages from India</strong>:
                  </p>
                  <p><b>Cairo</b>:
                    Cairo, the capital of Egypt, sits on the Nile River and is full of historical wonders. Visit the Giza Pyramids, Saqqara Complex, and the Egyptian Museum to experience Egypt’s rich history. The Giza Pyramids, once the tallest structures in the world, are a must-see.
                  </p>
                  <p><b>Luxor</b>:
                    Luxor is known for its vast ancient temples and royal tombs. Explore the Karnak Temple, the Valley of the Kings, and other ancient treasures. Luxor also offers modern amenities like hotels and restaurants, making it a great place to enjoy history and comfort.
                  </p>
                  <p><b>Alexandria</b>: Located on the Mediterranean Sea, Alexandria is Egypt’s second-largest city. Founded by Alexander the Great, it was once home to the famous Lighthouse of Alexandria, one of the Seven Wonders of the Ancient World. Today, the city offers beautiful streets, beaches, and historic sites.</p>
                  <p><b>Sharm el-Sheikh</b>: A resort town on the Red Sea, Sharm el-Sheikh is perfect for beach lovers and diving enthusiasts. Visit Ras Mohammed National Park and Naama Bay to enjoy the natural beauty and vibrant marine life.</p>
                  <p><b>Aswan</b>: Aswan is a peaceful city on the Nile, offering a more relaxed atmosphere after visiting busier cities. Popular attractions include Elephantine Island, the Philae Temple, and the Nubian Museum.</p>
                  <p><b>Hurghada</b>: Hurghada is a popular beach city on the Red Sea coast. Known for its stunning beaches and resorts, it’s a great spot for water sports like scuba diving, windsurfing, and kitesurfing.</p>
                  <p><b>Siwa Oasis</b>: Located in the western desert, Siwa Oasis is a peaceful place with natural beauty and a unique cultural heritage. The Berber tribes have preserved their traditions here, and you can explore the simple lifestyle with mud-brick houses and donkey carts.</p>
                  <p><b>Dahab</b>: A small town on the Sinai Peninsula, Dahab is ideal for those looking to relax by the beach or try exciting activities like windsurfing, kite surfing, and desert trekking.</p>
                  <p><b>Taba Heights</b>: Taba Heights is a scenic resort town in the Sinai Peninsula, offering breathtaking views of the sea, desert, and mountains. It’s a great spot for water sports and exploring the natural beauty of Egypt.</p>
                  <p><b>Ismailia</b>: Known as "The city of beauty and enchantment," Ismailia is located along the Suez Canal. With its green gardens, lakes, and European-style architecture, it’s a peaceful place to spend some time during your trip.</p>

                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>THINGS TO DO ON YOUR <strong>EGYPT TOUR PACKAGES</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>THINGS TO DO ON YOUR <strong>EGYPT TOUR PACKAGES</strong></h4>
                </div>
                <div className="card-body">
                  <p>
                    <b>Sail on the River Nile</b>:
                    Enjoy a relaxing cruise on the world’s longest river, the Nile, and take in the beautiful scenery.
                  </p>
                  <p><b>Explore Mummies at the Mummification Museum</b>:
                    Discover ancient mummies and learn about the fascinating process of mummification at this unique museum.
                  </p>
                  <p><b>Quad Bike Ride at Giza</b>:
                    Experience the thrill of riding a quad bike through the desert near the Giza Pyramids.
                  </p>
                  <p><b>Watch the Sunset at Mount Sinai</b>:
                    Climb Mount Sinai and enjoy a breathtaking sunset from this sacred mountain.
                  </p>
                  <p><b>Scuba Diving at Hurghada</b>:
                    Dive into the crystal-clear waters of the Red Sea in Hurghada and explore its colorful coral reefs and marine life.
                  </p>
                </div>
              </div>
              <br /><br />

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4>Egypt Trip From Kolkata Questions</h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          What are the best places to visit in Egypt ?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                        Adorable Vacation recommends visiting Cairo, Luxor, Aswan, and Hurghada during your <strong>Egypt Tour Cost from India</strong>.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                         How much is the entry fee for the Pyramids of Egypt ?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                        The entry fee for the Pyramids is 80 Egyptian pounds. If you want to go deeper inside the Pyramid, it's an additional 200 Egyptian pounds.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          What is the best time to visit Egypt ?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                        The best time to visit Egypt is from the end of September to early April. The peak season is in December and January.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                         What is Egypt famous for ?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                        Egypt is famous for the ancient Pyramids, which are the only surviving man-made wonders from the Seven Wonders of the World.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          What are the best dishes to try during an Egypt Package Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                        Egypt has delicious dishes beyond its famous monuments and deserts. During your Egypt tour, you must try falafel, kushari, babaganoush, kabab and kofta (grilled meatballs), Alexandria liver, and sausage. You’ll also love the sweet treats like Mahshi, Um Ali, Kanafe, Basbousa, and Feteer Meshaltet.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                         What are the top attractions to see during my Egypt Package Tour Booking ?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                        During your trip, you can visit the Pyramids, Khan El Khalili, the Egyptian Museum, Coptic Churches, the Citadel and Mohamed Ali Mosque, Luxor Temple, Karnak Temple, Valley of the Kings, Temple of Hatshepsut, the Temple of Horus at Edfu, Temple of Kom Ombo, Aswan High Dam, Philae Temple, and Abu Simbel Temples.
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Egypt <span className="tomato"> Tour Reviews</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Your Experience is our Marketing"</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Egypt_8N_9D_Tour_Package;
