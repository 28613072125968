import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./InclusionExclusion";
import InclusionExclusion from "./InclusionExclusion_MeghalayaArunachal_12n_13d";

class MeghalayaArunachalPackageTour_12N_13D_Package extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: 'tab1',
      daywiseActiveTab: 'day1'
    }
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  handleDayWiseTabClick = (tab) => {
    this.setState({
      daywiseActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919674738480&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
  render() {
    const { customActiveTab, daywiseActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.MeghalayaArunachal_12N_13D_PackageImageURL + "/Arunachal-Pradesh-Package-Tour-from-Kolkata.webp";
    const packageTopBanner2ImageURL = ApiURL.MeghalayaArunachal_12N_13D_PackageImageURL + "/Arunachal-Pradesh-Tour-Package-from-Kolkata.webp";
    const sightseen1ImageURL = ApiURL.MeghalayaArunachal_12N_13D_PackageImageURL + "/ARUNACHAL PRADESH TOUR PACKAGES FROM KOLKATA.webp";
    const sightseen2ImageURL = ApiURL.MeghalayaArunachal_12N_13D_PackageImageURL + "/Arunachal-Pradesh-Package-Tour-from-Guwahati.webp";
    const sightseen3ImageURL = ApiURL.MeghalayaArunachal_12N_13D_PackageImageURL + "/Shillong-Package-Tour.webp";

    const navDay1ImageURL = ApiURL.MeghalayaArunachal_12N_13D_PackageImageURL + "/BHALUKPONG-TO-DIRANG-TOUR.webp";
    const navDay2ImageURL = ApiURL.MeghalayaArunachal_12N_13D_PackageImageURL + "/DIRANG-TO-TAWANG.webp";
    const navDay3ImageURL = ApiURL.MeghalayaArunachal_12N_13D_PackageImageURL + "/TAWANG-LOCAL-SIGHTSEEING.webp";
    const navDay4ImageURL = ApiURL.MeghalayaArunachal_12N_13D_PackageImageURL + "/TAWANG-BUMLA PASS.webp";
    const navDay5ImageURL = ApiURL.MeghalayaArunachal_12N_13D_PackageImageURL + "/TEZPUR-KAZIRANGA.webp";
    const navDay6ImageURL = ApiURL.MeghalayaArunachal_12N_13D_PackageImageURL + "/TAWANG-BOMDILA.webp";
    const navDay7ImageURL = ApiURL.MeghalayaArunachal_12N_13D_PackageImageURL + "/BOMDILA-TEZPUR";
    const navDay8ImageURL = ApiURL.MeghalayaArunachal_12N_13D_PackageImageURL + "/JUNGLE-SAFARI-KAZIRANGA.webp";
    const navDay9ImageURL = ApiURL.MeghalayaArunachal_12N_13D_PackageImageURL + "/GUWAHATI-TO-BHALUKPONG.webp";
    const navDay10ImageURL = ApiURL.MeghalayaArunachal_12N_13D_PackageImageURL + "/GUWAHATI.webp";
    const navDay11ImageURL = ApiURL.MeghalayaArunachal_12N_13D_PackageImageURL + "/GUWAHATI.webp";
    const navDay12ImageURL = ApiURL.MeghalayaArunachal_12N_13D_PackageImageURL + "/GUWAHATI.webp";
    const navDay13ImageURL = ApiURL.MeghalayaArunachal_12N_13D_PackageImageURL + "/GUWAHATI.webp";
    const TrendingPackagesDataList = this.props.TrendingPackagesData;
    const hotPackagesView = TrendingPackagesDataList.length > 0 ?
      (
        TrendingPackagesDataList.map((dataItem, i) => {
          const hotelImgURL = ApiURL.MeghalayaArunachal_12N_13D_PackageImageURL + "/" + dataItem.ImageName;
          return (<div className="col-md-12 p-0" key={i}>
            <div className="card h-100">
              <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
              <div className="card-body">
                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text"><span>{dataItem.Description}</span></p>
                <p>
                  <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                  <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                  <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                </p>
              </div>
              <div className="card-footer">
                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                <button type="button" className="btn btn-Warning btn-sm float-end">Book Now</button>
              </div>
            </div>
          </div>)
        })
      ) : (<div></div>)


    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL = ApiURL.MeghalayaArunachal_12N_13D_PackageImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      )
    })

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
          </div>
        </div>
        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container py-1">


                    <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center>Book <strong>Meghalaya Arunachal Pradesh Package Tour</strong> Ex Guwahati - BEST PRICE FOR 2024</center></h1>

                    <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center>Book <strong>Meghalaya Arunachal Pradesh Package Tour</strong> Ex Guwahati - BEST PRICE FOR 2024</center>
                      <hr />
                    </h1>
                    <div className="container-fluid">
                      <div className="row">
                        <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                      </div>
                    </div>
                    <hr />
                  </div>
                  <h2 className="content-desktop p-4" style={{ fontSize: "2.0vw" }}>Looking For <strong>Arunachal Pradesh Meghalaya Package Tour</strong>? <a href="tel:+91-9674738480" class="tomato"> ☎ CALL +91-96747-38480</a><br /></h2>
                  <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> Looking For <strong>Arunachal Pradesh Meghalaya Package Tour</strong>? <a href="tel:+91-9674738480" class="tomato"> ☎ CALL +91-96747-38480</a><br /></h3>
                  <div className="card">
                    <div
                      className="card-header custHeaer"
                    >
                      <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Embark on an Enchanting Journey with <strong>Meghalaya Arunachal Package Tour from Guwahati</strong></h3>
                      <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Embark on an Enchanting Journey with <strong>Meghalaya Arunachal Package Tour from Guwahati</strong></h4>
                    </div>
                    <div className="card-body">
                      <p>Arunachal Pradesh, literally known as the <b>Land of Rising Sun</b> is one of the most fascinating places on Earth with stunning landscapes, high hills, snow clad mountains, serene lakes and endless valleys. Arunachal Pradesh is the land of indigenous tribes like Aka, Apatani, Monpa, Tagin etc, countless Buddhist monasteries, pristine lakes, high altitude mountains, quaint hillside villages with awesome scenic beauty. During your <a href="/arunachal-pradesh-package-tour-from-guwahati" target="_blank"><strong>Arunachal Package Tour from Guwahati</strong></a> pollution free air and absolute serenity will comfort your soul and mind. The mountains in Arunachal Pradesh is described as <b>the place where the sun rises</b> as it receives the first morning sun rays in the entire country, being the easternmost state. Because of this, Arunachal is also known as <b>the land of dawn-lit mountains</b>. <br /><br />
                        If you are planning your holiday trip, then keep in consideration this less explored but nature packed destination. We bet if you travel Arunachal by booking a <strong>Meghalaya Arunachal Pradesh Package Tour</strong>, your visit here will be worth it - you will not regret. Indeed Arunachal Pradesh offers a distinctive natural beauty that you cannot view anywhere in India. The sky kissed Himalayan mountains, the crystal clear flowing rivers, the colorful monasteries with flattering flags bearing holy mantras and the ever smiling amazing people mainly the tribes who are the integral part of Arunachal's culture, history and tradition will blow your mind. The tribes with their distinctive culture, food habit, traditions and festivals are one of the main reasons that attracts tourist not only from India, but also from different corner of the World. <br /><br />
                        Along with Arunachal Pradesh, there are thousands of reasons also to travel Meghalaya which is one of the most sensation destinations amongst the travelers across the world. If you've ever wondered to be alone within the clouds & mist and get lost in the nature's amazement, Meghalaya should be your next destination! Meghalaya is popular for being the <b>abode of clouds</b> with incredible living root bridges, the place of wettest place on Earth and many other hidden gems of nature! There is plenty of nature wonder you will find during your <a href="/north-east-india-package-tour-from-guwahati-airport" target="_blank"><strong>North East Package Tour</strong></a> that allow you to explore Meghalaya in the best possible way. All you have to do is manage a couple of days and pack your bags! Let's start your trip with Adorable. <br /><br /></p>
                      <div className="content-desktop">
                        <center><img src={sightseen1ImageURL} alt="..." /></center>
                      </div>

                      <div className="content-mobile2">
                        <center><img src={sightseen1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                      </div>
                      <br />

                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>MEGHALAYA ARUNACHAL PRADESH TOUR DURATION</strong> : 12 NIGHTS | 13 DAYS </h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>MEGHALAYA ARUNACHAL PRADESH TOUR DURATION</strong> : 12N | 13D </h4>
                </div>
                <div className="card-body">
                  <p>Destinations Covered in <strong>Arunachal Pradesh Meghalaya Tour Packages from Guwahati Airport</strong> : Bhalukpong - 1N / Dirang - 2N / Tawang - 3N / Bomdila - 1N / Nameri - 1N / Shillong - 3N / Cherrapunjee - 1N <br /><br /></p>
                  <p><b>Pickup & Drop point</b> : Guwahati Airport [GAU] / Guwahati Railway Station [GHY] <br /><br /></p>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>ALTITUDE</th>
                          <th>HEIGHT</th>
                          <th>COORDINATES</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="ALTITUDE">Bhalukpong</td>
                          <td data-title="HEIGHT">213 m</td>
                          <td data-title="COORDINATES">27.0137° N, 92.6345° E</td>
                        </tr>
                        <tr>
                          <td data-title="ALTITUDE">Dirang</td>
                          <td data-title="HEIGHT">1,560 m</td>
                          <td data-title="COORDINATES">27.3584° N, 92.2409° E</td>
                        </tr>
                        <tr>
                          <td data-title="ALTITUDE">Tawang</td>
                          <td data-title="HEIGHT">3,048 m</td>
                          <td data-title="COORDINATES">27.5861° N, 91.8594° E</td>
                        </tr>
                        <tr>
                          <td data-title="ALTITUDE">Bomdila</td>
                          <td data-title="HEIGHT">2,415 m</td>
                          <td data-title="COORDINATES">27.2645° N, 92.4159° E</td>
                        </tr>
                        <tr>
                          <td data-title="ALTITUDE">Shillong</td>
                          <td data-title="HEIGHT">1,965 m</td>
                          <td data-title="COORDINATES">25.576° N, 91.883° E</td>
                        </tr>
                        <tr>
                          <td data-title="ALTITUDE">Cherrapunjee</td>
                          <td data-title="HEIGHT">1,430 m</td>
                          <td data-title="COORDINATES">25.2702° N, 91.7323° E</td>
                        </tr>
                        <tr>
                          <td data-title="ALTITUDE">Mawsynram</td>
                          <td data-title="HEIGHT">1,400 m</td>
                          <td data-title="COORDINATES">25.2975° N, 91.5826° E</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-danger">
                          <th>
                            Summary of <strong>Meghalaya and Arunachal Package Tour from Guwahati</strong>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                          >
                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                              <span>
                                GUWAHATI ARRIVAL - BHALUKPONG TRANSFER
                              </span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                              <span>BHALUKPONG - DIRANG TRIP</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                              <span>DIRANG LOCAL SIGHTSEEING</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                              <span> DIRANG - TAWANG TRIP</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                              <span> TAWANG LOCAL SIGHTSEEING TOUR</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                              <span> TAWANG - BUMLA PASS - TAWANG</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                              <span> TAWANG - BOMDILA TRANSFER</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                              <span>BOMDILA - TEZPUR/BHALUKPONG/NAMERI</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day9" style={{ color: 'black' }}><b>DAY 9 → </b>
                              <span>TEZPUR / BHALUKPONG / NAMERI TO SHILLONG</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day10" style={{ color: 'black' }}><b>DAY 10 → </b>
                              <span>EX SHILLONG MAWLYNNONG + DAWKI TO CHERRAPUNJI</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day11" style={{ color: 'black' }}><b>DAY 11 → </b>
                              <span>CHERRAPUNJI SIGHTSEEING TO SHILLONG</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day12" style={{ color: 'black' }}><b>DAY 12 → </b>
                              <span>SHILLONG - MAWSYNRAM - SHILLONG</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day13" style={{ color: 'black' }}><b>DAY 13 → </b>
                              <span>SHILLONG - GUWAHATI</span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHICH IS THE BEST TIME TO TRAVEL <strong>MEGHALAYA & ARUNACHAL PRADESH</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHICH IS THE BEST TIME TO TRAVEL <strong>MEGHALAYA & ARUNACHAL PRADESH</strong>?</h4>
                </div>
                <div className="card-body">
                  <p>Before you choose this <strong>North East India Tour Package</strong>, lets first understand that this is going to be a long trip as you are travelling three most astonishing and nature pact states - <b>Assam</b>, <b>Arunachal</b> & <b>Meghalaya</b>. Some of the destinations during this tour will be above 3,000 meters like Tawang (3,048 m) in Arunachal Pradesh and some the destination is in plain grass land surrounded by large river valleys. That's why you need to plan your travel date very wisely. Here comes NatureWings - The <strong>North East Package Tour</strong> specialist to suggest you season wise best time to book your <strong>Assam Meghalaya Arunachal Pradesh Package Tour</strong>.<br /><br /></p>
                  <h4><b>Summer in North East</b> <br /></h4>
                  <p>When the entire plains of India burns due to unbearable heat of summer, Arunachal comes as an angle to save you up from boiling temperature. If you want to beat the heat of summer, <a href="/arunachal-pradesh-package-tour-from-kolkata" target="_blank"><strong>Arunachal Pradesh Package Tour from Kolkata</strong></a> is best option. You will experience temperature between 8&#176;c to 14&#176;c which makes <strong>Arunachal a perfect place for Summer Holidays</strong>. Summer in Arunachal is the best time to behold the breathtaking beauties of mountains, streams, waterfalls, valleys, wild life and monasteries also. Same weather is noticed in Shillong also. With moderate temperature between 10&#176;c to 18&#176;c Shillong and Cherrapunji is cold and little bit rainy in summer which is enjoyable. <br /><br />
                    Now, comes the issue with Kaziranga National Park. Every year from 30th April to 31th October (in 2021 it was 1st Oct) Kaziranga remains close being the breathing season for wild animals in this national park. More over due to heavy rain most of the park goes under flood water of Brahmaputra River. So, during summer you will not be able to explore all the destinations that you are planning for. But if you are looking for only <strong>Arunachal Pradesh Package Tour</strong>  or <a href="/shillong-meghalaya-package-tour-from-guwahati" target="_blank"><strong>Shillong Meghalaya Package Tour</strong></a>, you can definitely plan for your trip.<br /><br /><br /></p>

                  <h4><b>Winter in North East</b> <br /></h4>
                  <p>Winter is probably the best time to run away from the mad crowds, hectic schedule of 9 to 6 office hours, suffocating city smog and monotonous life. If you want to spend your holidays in winter, want to play with snow ball, make snow man, then travelling Arunachal during winter (Oct to March) will be an amazing experience.<br /><br />
                    In winter Shillong & Cherrapunji of Meghalaya also remains very cold and mystic hills of Garo, Khasi and Jaintia makes this place with unadulterated beauty. Travelling North East during winter is really worth value.<br /><br />
                    Now comes to Kaziranga. It's wide spread grassy lands with some of the best wild inhabitant's draws lots of tourist in winter. Having mystic chill weather, <a href="/kaziranga-shillong-cherrapunjee-package-tour-from-guwahati" target="_blank"><strong>Elephant Safari in Kaziranga National Park</strong></a> and Jeep Safari in four of its ranges such as <b>Central Range or Kohora Range</b>, <b>Western Range or Bagori Range</b>, <b>Eastern Range or Agartoli Range</b>, <b>Burhapahaar Range or Ghorakati Range</b> attracts visitors to explore and enjoy the park at its best. So, winter is the best time to book your <strong>Assam Meghalaya Arunachal Pradesh Package Tour from Guwahati</strong>.<br /><br /><br /></p>

                  <h4><b>Monsoon in North East</b> <br /></h4>
                  <p>Monsoon generally consists of July to September in North East. In monsoon, Cherapunji and Mawsynrum has a special attraction that you cannot experience anywhere in India. During monsoon Cherapunji and Mawsynrum turns into a rainy paradise with gorgeous waterfalls, lush greenery and lakes with full brink. It is best time for adventure lovers who want to experience the rain sitting idle by the window for whole day. Nothing is comparable to the feeling of sipping a cup of warm tea or coffee and listening to the songs of rains or some romantic old melodies of legendary singer Mohd. Rafi sahab<br /><br />

                    <b>"Zindagi Bhar Nahin Bhoolegi Woh<br />
                      Barsaat Ki Raat<br />
                      Ek Anjaan Haseena Se Mulaqaat Ki Raat"</b><br /><br />

                    After all rain has its own beauty that brings life in to action. So if you want to experience rain washed refreshing North East, book your <strong>Shillong Meghalaya Package Tour</strong> from NatureWings between July to September.<br /><br />
                    <span><b>Please Note :</b></span> During monsoon travel you can experience some difficulties like road block, land slide, incessant rain for several days. More over Kaziranga National Park also remain close from April to October i.e. entire monsoon season. So, booking a complete <strong>19 Nights/20 Days Assam Meghalaya & Arunachal Package Tour</strong> is not suggestible at all during this time.<br /><br />
                    In spite of that, if you are too much travel enthusiast, keen to explore the rain washed valleys, gushing waterfalls, over flooded streams and life style of people of North East, travel here in monsoon only. We bet thrilling and excitement will be waiting in every corner of your tour.<br /><br /></p>
                  <h4>Month Wise Weather in Shillong</h4><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th>Travel Month</th><th>Min / Max Temperature</th><th>Season</th></tr></thead>
                      <tbody><tr><td>March to June</td><td>12&#176; - 24&#176;C</td><td>Pleasant</td></tr>
                        <tr><td>July to September</td><td>10&#176; - 22&#176;C</td><td>Rainy Season</td></tr>
                        <tr><td>October to February</td><td>5&#176; - 14&#176;C</td><td>Cold</td></tr>
                      </tbody></table>
                  </div><br /><br />
                  <h4>Month Wise Weather in Arunachal Pradesh</h4><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th>Travel Month</th><th>Min / Max Temperature</th><th>Season</th></tr></thead>
                      <tbody><tr><td>March to June</td><td>10&#176;C to 30&#176;C</td><td>Summer</td></tr>
                        <tr><td>July to September</td><td>18&#176;C to 32&#176;C</td><td>Monsoon</td></tr>
                        <tr><td>November to February</td><td>6&#176; C to 22&#176; C</td><td>Winter</td></tr>
                      </tbody></table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DISTANCES TO NOTE FOR <strong>MEGHALAYA ARUNACHAL PACKAGE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DISTANCES TO NOTE FOR <strong>MEGHALAYA ARUNACHAL PACKAGE TOUR</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr>
                          <th><b>PLACES</b></th>
                          <th><b>DISTANCES (By Road)</b></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Guwahati to Bhalukpong Distance</td>
                          <td>4 hr 56 min (234.4 km) via NH 27</td>
                        </tr>
                        <tr>
                          <td>Bhalukpong to Dirang Distance</td>
                          <td>4 hr 7 min (138.6 km) via Chariduar - Tawang Rd</td>
                        </tr>
                        <tr>
                          <td>Dirang to Tawang Distance</td>
                          <td>4 hr 30 min (134.5 km) via NH13</td>
                        </tr>
                        <tr>
                          <td>Tawang to Bomdila Distance</td>
                          <td>5 hr 31 min (176.7 km) via NH13</td>
                        </tr>
                        <tr>
                          <td>Bomdila to Nameri Distance</td>
                          <td>3 hr 25 min (122.7 km) via Chariduar - Tawang Rd</td>
                        </tr>
                        <tr>
                          <td>Nameri to Itanagar Distance</td>
                          <td>3 hr 56 min (153.4 km) via NH 15</td>
                        </tr>
                        <tr>
                          <td>Itanagar to Ziro Distance</td>
                          <td>4 hr 26 min (109.5 km) via NH13</td>
                        </tr>
                        <tr>
                          <td>Ziro to Tezpur Distance</td>
                          <td>8 hr 32 min (279.0 km) via NH13 and NH 15</td>
                        </tr>
                        <tr>
                          <td>Tezpur to Shillong Distance</td>
                          <td>5 hr 9 min (236.9 km) via NH 27 and NH6</td>
                        </tr>
                        <tr>
                          <td>Cherrapunji to Shillong Distance</td>
                          <td>1 hr 45 min (53.9 km) via SH 5</td>
                        </tr>
                        <tr>
                          <td>Shillong to Mawlynnong Distance</td>
                          <td>2 hr 41 min (78.1 km) via NH206</td>
                        </tr>
                        <tr>
                          <td>Shillong to Dawki Distance</td>
                          <td>2 hr 45 min (81.8 km) via NH206</td>
                        </tr>
                        <tr>
                          <td>Cherrapunji to Guwahati Distance</td>
                          <td>4 hr 16 min (147.8 km) via NH6</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                </div>
              </div>
              <br /><br />
              {/* <!--By Itinerary--> */}
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3 className="content-desktop" style={{ fontSize: "2.0vw" }}>
                        DETAIL ITINERARY FOR <strong style={{ color: "tomato" }}>MEGHALAYA & ARUNACHAL PACKAGE TOUR</strong>
                      </h3>
                      <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>
                        DETAIL ITINERARY FOR <strong style={{ color: "tomato" }}>MEGHALAYA & ARUNACHAL PACKAGE TOUR</strong>
                      </h3>
                    </div>
                  </div>
                </div>

                <br />
                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpen"
                  >
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day1"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          DAY 1 : ARRIVAL AT GUWAHATI & TRANSFER TO BHALUKPONG
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>Welcome on arrival at Guwahati airport and transfer to Bhalukpong bordering the Indian states Assam & Arunachal Pradesh. Overnight stay in Bhalukpong. <br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay1ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          DAY 2 : BHALUKPONG TO DIRANG TOUR
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>This is the second day of your Meghalaya Arunachal Pradesh Package Tour. After breakfast, Drive to Dirang. Enter the Arunachal by Bhalukpong check post. Dirang is an ideal getaway in West Kameng district with beautiful apple orchards and sheep breeding farms. The entire place is surrounded by apple & kiwi orchards and the atmosphere is picturesque. Overnight stay in Dirang. <br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay2ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 3 : DIRANG LOCAL SIGHTSEEING
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>Morning after breakfast, visit to Sangti Valley. Later return back to Dirang, have lunch and visit to the Dirang Dzong, Orchid & Wiki Garden. Evening at leisure. Overnight stay in Dirang. <br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay3ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day4">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFour"
                        >
                          DAY 4 : DIRANG TO TAWANG TOUR
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>Drive to Tawang crossing Sela Pass (13720 ft) which runs through a small ridge and along the paradise lake. Stop to enjoy the Sella Pass and Paradise Lake. From the Sela pass road winds down to Jong Village. On the way to Jong visit Yashwant Garh War Memorial. After lunch at Jong Village starts for Tawang. On the way visit Nuranang Fall. Overnight stay in Tawang. <br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay4ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day5">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFive"
                        >
                          DAY 5 : TAWANG LOCAL SIGHTSEEING
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>Sightseeing in and around Tawang city. Visit the famous Tawang Monastery, which is 400 years old, accommodates over 500 monks and houses priceless Buddhist texts and scriptures. The Tibetan influence here is unmistakable, with the elaborately painted wooden windows and other motifs. An 8 mt high gilded statue of the Buddha is seated here, as are numerous ancient Thankas or Traditional paintings and manuscripts. Also visit Craft Centre, Tawang War Memorial. Evening free time. Overnight stay in Tawang.<br /><br /></p>
                          {/* <div className="content-desktop">
                            <center><img src={navDay5ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day6">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSix"
                        >
                          DAY 6 : TAWANG - BUMLA PASS - TAWANG
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>Post breakfast full day excursion to visit the PT Tso Lake, Bumla Pass, Sangetsar Lake. Drive back to Tawang. Overnight in Tawang. <br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay6ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day7">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSeven"
                        >
                          DAY 7 : TAWANG TO BOMDILA
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>After Breakfast proceed to Bomdila. Reach at hotel & check in. If time permits, visit to BomdilaGompa& local market. Overnight stay in Bomdila.<br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay7ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day8">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseEight"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseEight"
                        >
                          DAY 8 : BOMDILA - TEZPUR/BHALUKPONG/NAMERI
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingEight"
                      >
                        <div className="accordion-body">
                          <p>After Breakfast Drive towards Tezpur. After Arrival Check in to Hotel. Overnight stay in Bhalukpong.<br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay8ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day9">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseNine"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseNine"
                        >
                          DAY 9 : TEZPUR/BHALUKPONG/NAMERI TO SHILLONG PACKAGE TOUR
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseNine"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingNine"
                      >
                        <div className="accordion-body">
                          <p>Post breakfast, check out from your hotel & proceed towards Shillong. Shillong, also known as 'The Scotland of the East' is the capital city of Meghalaya. En route you will visit the largest man made lake of Northeast India - Umium Lake. Reaching Shillong, check-in at a city Hotel. Evening free for leisure. Overnight stay in Shillong. <br /><br /></p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay9ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay9ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day10">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTen"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTen"
                        >
                          DAY 10 : DAY EXCURSION TRIP TO MAWLYNNONG AND DAWKI & TRANSFER FOR CHERRAPUNJEE TOUR
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTen"
                      >
                        <div className="accordion-body">
                          <p>Wake up early and have your breakfast and drive to Mawlynnong. Today you can explore the cleanest village Mawlynnong in the world. You can take the sky walk. Spectacular view of the living Root Bridge. After lunch drive to Dawki. Dawki is the gateway to Bangladesh where major export and import transactions take place. it is a splendid drive of 45 kilometres passing through deep gorges and ravines, which is certainly a thrilling experience. On approaching Dawki is the Umngotriver, The Umngot river is the natural boundary between RiPnar or Jaintia Hills with HimaKhyrim of Khasi hills over which is a single span suspension bridge was constructed. Evening drive to Cherrapunjee city. Overnight stay in Cherrapunjee.<br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay10ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay10ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day10">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTen"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTen"
                        >
                          DAY 11 : CHERRAPUNJEE SIGHTSEEING & TRANSFER TO SHILLONG
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTen"
                      >
                        <div className="accordion-body">
                          <p>After breakfast proceed to visit Living Root Bridge. Also Visit Nohkalikai Falls, Mawsmai Caves, Eco Park, Seven Sisters falls, Ram Krishna Mission and the Thangkharang Park. Evening return back to Shillong city. Overnight stay in Shillong. <br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay11ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay11ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day10">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTen"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTen"
                        >
                          DAY 12 : SHILLONG - MAWSYNRAM - SHILLONG
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTen"
                      >
                        <div className="accordion-body">
                          <p>After breakfast drive to Mawsynram and visit Mawphlang Sacred Forest, Mawjyngbuin Cave (Mawsynram cave), Naturally formed Shiva Linga, Jakrem (Hot water Spring). After sightseeing back to Shillong. Overnight stay in Shillong. <br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay12ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay12ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day10">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTen"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTen"
                        >
                          DAY 13 : CHERISH YOUR MEMORIES - SHILLONG TO GUWAHATI TRANSFAR
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTen"
                      >
                        <div className="accordion-body">
                          <p>After breakfast proceed to visit Living Root Bridge. Also Visit Nohkalikai Falls, Mawsmai Caves, Eco Park, Seven Sisters falls, Ram Krishna Mission and the Thangkharang Park. Evening return back to Shillong city. Overnight stay in Shillong. <br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay13ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay13ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> HOTELS ADORABLE PROVIDE FOR <strong>MEGHALAYA ARUNACHAL TOUR PACKAGE</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> HOTELS ADORABLE PROVIDE FOR <strong>MEGHALAYA ARUNACHAL TOUR PACKAGE</strong></h4>
                </div>
                <div className="card-body">
                  <h4><b>4 Star Hotels :</b></h4><br />
                  <p><b>Guwahati</b> - Gateway Grandeur</p>
                  <p><b>Shillong </b> - Hotel Polo Tower</p>
                  <p><b>Cherrpunjee</b> - Polo Orchid Resort<br /><br /></p>
                  <h4><b>3 Star Hotels :</b></h4><br />
                  <p><b>Guwahati</b> - Lilawati Grand</p>
                  <p><b>Shillong </b> - Centre Point, Blue Berry Inn, Eee Cee Hotel</p>
                  <p><b>Cherrpunjee</b> - Cherrapunjee Holiday Resort, Kutmadam Resort<br /><br /></p>
                  <h4><b>2 Star Hotels :</b></h4><br />
                  <p><b>Guwahati</b> - Hotel Siroy Liya, Hotel Siroy Classic</p>
                  <p><b>Shillong </b> - Hotel Barbareek,  Season Rate, J K Interanational</p>
                  <p><b>Kaziranga</b> - Wild Grass Lodge, Dhanshree Resort</p>
                </div>
              </div>
              <br /><br />
              {/* <!--insert table inclusion exclusion--> */}
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    {
                      <InclusionExclusion />
                    }
                  </div>
                </div>
              </div>
              {/* <!--end table inclusion exclusion--> */}
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> TRAVEL SHILLONG - THE SCOTLAND OF EAST</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>TRAVEL SHILLONG - THE SCOTLAND OF EAST</h4>
                </div>
                <div className="card-body">
                  <p>Shillong is an astonishing hill station in North East Indian State of Meghalaya literally known as the <em>"Abode of the Cloud"</em>. We know Kashmir as the Paradise on Earth. Mughal emperor Jahangir once said - <em>"Agar Firdaws ba roy-i zamin ast, hamin ast-u hamin ast-u hamin ast"</em> means "if there is a heaven on earth, it's here, it's here, it's here". Like that Shillong is known as - <em>"The Scotland of the East"</em> for its enchanting and captivating natural beauty. <br /><br />
                    For decades the land was dominated by Garo, Khasi, and Jaintia tribes. For a long it was a prime hill station for Britishers and after independence Meghalaya merged with the Indian union on 21st Jan 1972. In this <strong>Kaziranga Shillong Package Tour</strong>, travelers will visit some of its best tourist attractions like : <br /><br />
                    <b>Shillong Peak</b> - Located at an altitude of 1,965m, it is the highest point of Meghalaya providing an astonishing view of Shillong. <br /><br />
                    <b>Shillong Cave</b> - Meghalaya has India's longest cave system. Out of the 10 deepest caves in India, Meghalaya has 9 of them. Even some of the caves are still unexplored. So come explore the unexplored with your <strong>Kaziranga Shillong Cherrapunjee Package Tour</strong> from NatureWings. Some of the notable caves are: <br /><br />
                    <b>Mawjymbuin Cave</b> - a popular destination for adventure lovers, trekkers, geologists, and explorers. <br /><br />
                    <b>Mawsmai Cave</b> - a limestone caves just 6 km from Cherrapunji is the most crowed puller and vibrant enough to give traveler or explorer spellbound with its classic formation.<br /><br />
                    <b>Krem Mawmluh</b> - It is the fourth-longest cave in the Indian subcontinent with a 7 km stretch. The cave has waist-deep pools, massive caverns, several meters of belly crawl, and a few meters of climb. Most of the part remains in darkness hence guides are needed to explore this cave.<br /><br />
                    <b>Siju Cave</b> - Situated near Napak Lake and Simsang River, Siju Cave is one of the largest limestone caves in India stretching over 7 km long. With surprise in every corner and a knee-deep river, this cave is interesting to explore and greatly admired by trekkers and adventure lovers. A guide help is suggested to explore Siju Cave.<br /><br />
                    <b>Mawlynnong Village</b> - Located just 90km away from Shillong, this village is the cleanest village in India and in Asia also. In 2003 & 2005 Mawlynnong was awarded as the cleanest village in Asia by Discover India Magazine. You will not find single garbage or even dry leaves in any part of the village. The inhabitant of the village is too serious for the cleanliness so every morning the villager's together sweep to clean up the roads and surroundings and clean up every single piece of waste or dry leaves they found and dump it in the bamboo made basket.
                    Using or carrying plastic bags is banned and smoking for local villagers and travelers is strictly prohibited. Travel this amazing land of Meghalaya with our combined <strong>Shillong Kaziranga Tour Package</strong>.<br /><br /><br /><br /></p>
                </div>
              </div>
              {/* <!--start of package details--> */}

              {/* <!--end of package details--> */}
              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>Frequently Ask <span>Questions</span></h2>
                      <h4><strong>MEGHALAYA AND ARUNACHAL PRADESH PACKAGE TOUR FROM GUWAHATI</strong></h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion" id="accordionPanelsStayOpenExample">
                      <div className="accordion" id="accordionFlushExample">

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading1">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
                              What is Ambubachi Mela?
                            </button>
                          </h4>
                          <div id="flush-collapse1" className="accordion-collapse collapse show" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              It is one of the major festivals of North East India, held in the Kamakhya temple in Guwahati every June. Thousands of devotees from India and abroad visit, making it known as the 'Mahakumbh of the East.' During this time, Goddess Kamakhya undergoes her annual menstrual cycle, and the temple remains closed for three days. However, devotees visit the premises to seek blessings. You can book an <strong>Assam Tour Package</strong> with <strong>Kamakhya Temple Tour</strong> and <strong>Kaziranga Safari</strong> from NatureWings to participate in this auspicious event.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading2">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
                              Can we avail Jain or Veg. food in Arunachal?
                            </button>
                          </h4>
                          <div id="flush-collapse2" className="accordion-collapse collapse show" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              Many restaurants offer pure vegetarian or Jain meals during Arunachal, Meghalaya, and Assam trips. It is advisable to specify your food preferences while booking to ensure suitable arrangements.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading3">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                              Can we buy medicine from Tawang, Shillong, or Cherrapunji?
                            </button>
                          </h4>
                          <div id="flush-collapse3" className="accordion-collapse collapse show" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              These areas are remote, and not all types of medicine may be available. It is recommended to carry prescribed and general medicines during your travel to Arunachal, Assam, and Meghalaya.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading4">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                              Do I need high altitude medicines for Tawang, Arunachal Pradesh?
                            </button>
                          </h4>
                          <div id="flush-collapse4" className="accordion-collapse collapse show" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              Although high-altitude medicines may not always be necessary, it is advisable to consult your physician before traveling to Tawang or other parts of Arunachal Pradesh.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading5">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                              What are the offbeat destinations in Arunachal Pradesh?
                            </button>
                          </h4>
                          <div id="flush-collapse5" className="accordion-collapse collapse show" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              Some offbeat destinations include Ziro, Mechuka Valley, Pasighat, and Aalo. These places are excellent additions to your travel itinerary during an <strong>Arunachal Tour</strong>.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading6">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse6" aria-expanded="false" aria-controls="flush-collapse6">
                              How to reach Arunachal Pradesh?
                            </button>
                          </h4>
                          <div id="flush-collapse6" className="accordion-collapse collapse show" aria-labelledby="flush-heading6" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              Arunachal Pradesh is about 350 km from Guwahati Airport. You can book a car to reach Itanagar, the state capital, or consult your travel agent for transportation arrangements.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading1">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapse1"
                              aria-expanded="false"
                              aria-controls="flush-collapse1">
                              How to reach Single-Decker Living Root Bridge in Riwai?
                            </button>
                          </h4>
                          <div id="flush-collapse1" className="accordion-collapse collapse show" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              Riwai hosts the most spectacular single-decker living root bridge. Surrounded by lush greenery and a stream flowing beneath, travelers can experience the breathtaking beauty of Meghalaya’s forests along stone steps and water streams.

                              To reach Riwai, take the Shillong-Mawlynnong road, approximately 75 km away, a 2.5-hour drive from Shillong. From Mawlynnong, trek for about 1 km along a jungle road to witness the scenic root bridge. Relax by the streams and enjoy a shower in the fresh waters.

                              Many homestays are available nearby for those wanting to spend a night amidst nature. Essentials to pack include a backpack, woolen clothes, track pants, a raincoat, mosquito repellent, torch, and a power bank.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading2">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapse2"
                              aria-expanded="false"
                              aria-controls="flush-collapse2">
                              Is Kaziranga National Park worth visiting?
                            </button>
                          </h4>
                          <div id="flush-collapse2" className="accordion-collapse collapse show" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              Kaziranga National Park, a UNESCO World Heritage Site, is one of India's top destinations for wildlife enthusiasts. It offers a rare opportunity to see the endangered One-Horned Rhino up close.

                              Visitors can also spot water buffalo, wild hogs, deer, elephants, and various birds. If you enjoy wildlife, Kaziranga is a must-visit for an unforgettable jungle safari experience.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading3">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapse3"
                              aria-expanded="false"
                              aria-controls="flush-collapse3">
                              What food should I try during my Kaziranga-Shillong tour?
                            </button>
                          </h4>
                          <div id="flush-collapse3" className="accordion-collapse collapse show" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              During your tour, try Assamese dishes like Laksa, Khar, and Tenga. On your way to Kaziranga, enjoy lunch at **Anurag Dhaba** (Veg Thali: ₹250). In Shillong, savor street food at Police Bazaar.

                              In Cherrapunjee, visit **Jiva Veg Restaurant** (₹500 per person) or **Orange Roots Restaurant** (Thali: ₹220). These places offer delicious local dishes that reflect the flavors of Northeast India.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading4">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapse4"
                              aria-expanded="false"
                              aria-controls="flush-collapse4">
                              How can I book a car for a full-day Mawlynnong and Dawki tour?
                            </button>
                          </h4>
                          <div id="flush-collapse4" className="accordion-collapse collapse show" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              You can book a car from Guwahati to Shillong, and from Shillong, arrange transport to Mawlynnong and Dawki River. During peak season, local drivers may charge high rates, so it's better to book through a Northeast India specialized tour operator for a hassle-free experience.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading5">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapse5"
                              aria-expanded="false"
                              aria-controls="flush-collapse5">
                              When is the best time to visit Nohkalikai Falls?
                            </button>
                          </h4>
                          <div id="flush-collapse5" className="accordion-collapse collapse show" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              The ideal time to visit Nohkalikai Falls depends on your preferences.

                              - **May to September (monsoon)**: Witness the waterfall at its peak flow.
                              - **October to January**: Perfect for trekking and scenic views of the falls.
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3><b> North East India Tour<span class="tomato"> Holiday Reviews</span></b></h3>
                      <h4><i>"Your Experience is our Marketing"</i></h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1">
                  <div id="testimonials">
                    <div className="testimonial-box-container">

                      {reviewersDataView}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div >
        </div >
        <div className="btn-whatsapp-pulse">
          <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>
        <div class="btn-call-pulse">
          <a style={{ color: 'white' }} href="tel:+91-967473-8480">
            <i className="fa fa-phone"></i>
          </a>
        </div>
      </>
    );
  }
}
export default MeghalayaArunachalPackageTour_12N_13D_Package;
