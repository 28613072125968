import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop_bhutan';
import Bhutan_PackageTourSixsenseDirectFlightIndia from '../../../components/package/Bhutan/Bhutan_PackageTourSixsenseDirectFlightIndia';
import { Helmet } from "react-helmet";
class Bhutan_TourSixsenseDirectFlightIndiaPage extends Component {
    constructor() {
        super();
        this.state = {
            HotPackagesData: [],
            ReviewersData: [],
            ThemePackagesData: [],

        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const hotPackageDataList = [
            {
                Title: "Blissful Bhutan",
                ImageName: "6N-7D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 1N Punakha | 1N Paro...more | Free Sim Card | E-Permit",
                PricePerNight: "29,900/-",
                NoOfTours: 43,
                NoOfDeparture: 38,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book: "Book Now",
                Url: 'tel://9883359713',
                EXPARO: "EX-IXB"
            },
            {
                Title: "Untouched Bhutan",
                ImageName: "7N-8D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "3N Thimphu | 2N Paro...more | Free Sim Card | E-Permit",
                PricePerNight: "35,100/-",
                NoOfTours: 47,
                NoOfDeparture: 40,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book: "Book Now",
                Url: 'tel://9883359713',
                EXPARO: "EX-IXB"
            },
            {
                Title: "Explore Bhutan",
                ImageName: "8N-9D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "40,200/-",
                NoOfTours: 44,
                NoOfDeparture: 37,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book: "Book Now",
                Url: 'tel://9883359713',
                EXPARO: "EX-IXB"
            },
            {
                Title: "Excape Bhutan",
                ImageName: "10N-11D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro...more | Free Sim Card | E-Permit",
                PricePerNight: "48,200/-",
                NoOfTours: 46,
                NoOfDeparture: 39,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book: "Book Now",
                Url: 'tel://9883359713',
                EXPARO: "EX-IXB"
            }

        ];

        const themePackageDataList = [
            {
                Title: "Bhutan Honeymoon",
                ImageName: "Bhutan-Honeymoon-Package.webp",
                AllternateText: "...",
                Description: "Bhutan Package is your ultimate destination. 7N/8D is the decent duration to explore the relation between Nature & Better Half.",
            },
            {
                Title: "Wedding at Bhutan",
                ImageName: "Destination-Wedding-at-Bhutan.webp",
                AllternateText: "...",
                Description: "Make your iconic marriage day very very special at Bhutan. Marriage like VIP, execute a super destination wedding 'LIKE NEVER BEFORE'.available.",
            },
            {
                Title: "Bhutan Solo Trip (Woman)",
                ImageName: "Bhutan-Solo-Trip-Woman-Special.webp",
                AllternateText: "...",
                Description: "Love to explore everything by yourself ? Then Bhutan is the god gifted gem for you. Numerous things are present to explore,experience & recreate.",
            },
            {
                Title: "Bhutan Adventure Trip",
                ImageName: "Bhutan-Adventure-Packages.webp",
                AllternateText: "...",
                Description: "After NEPAL, Bhutan is an amazing counter destiation for Adventure lover. Explore with thrilling activities - River Rafting, Paragliding & Kayaking etc.",
            },
            {
                Title: "Bhutan Bike Trip",
                ImageName: "Bhutan-Bike-Package-Tour.webp",
                AllternateText: "...",
                Description: "Throughout the Bhutan bike trip, you will pass through curvy roads with most picturesque surroundings with high eastern Himalayan snow-capped peaks.",
            },
            {
                Title: "Pilgrimage of Bhutan",
                ImageName: "Bhutan-Monastery-Packages.webp",
                AllternateText: "...",
                Description: "Bhutan is the only country in the world that officially practices Mahayana Buddhism or Vajrayana Buddhism. Buddhism is a way of life in Bhutan.",
            },
            {
                Title: "Bhutan Snowman Trek",
                ImageName: "Bhutan-Snowman-Trek.webp",
                AllternateText: "...",
                Description: "The Snowman Trek is the longest hiking trail of Bhutan that extends from Laya to the high Bhutanese Himalayas, covering up the northern Kingdom.",
            },
            {
                Title: "Bhutan Foreigners Package",
                ImageName: "Bhutan-Forigners-Package.webp",
                AllternateText: "...",
                Description: "As a foreigner, living in Bhutan can be a rewarding and fascinating experience,a small landlocked country in the eastern Himalayas, is known for its unique culture.",
            },

        ];

        const reviewersDataList = [
            {
                Name: "Angela osta",
                ImageName: "angela_osta.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "Our vacation for Bhutan was booked through Rewa LLP and was one of the best experiences we have had. Everyone, from the driver in India to the Bhutan guide, Mr. Sagar and the driver Mr. Leki,was very supportive, cooperative and helpful.The Team was constantly in touch with us over whatsApp and if we ever had a query, it was very promptly and seamlessly resolved.The Hotel in Thimpu and the Resort in Paro, both were well chosen and were very good properties indeed.Highly recommend booking your vacation through Rewa. They seem to have a customer first policy and every action os well thought out and highly customer/guest centric.",
            },
            {
                Name: "Vanshi Goradia",
                ImageName: "vanshi_godia.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "Hi Reva Adorable, we had a time of our lives in Bhutan with your amazing itinerary... Our tour manager wasn't with us but he operated everything from a good distance. Our guide Sonam ji and Pilot ji made our trip memorable by showing us Bhutan's amazing hidden gems. We are looking forward to come back to Bhutan and explore the other hidden gems soon in the near future with greater Travel Spirit, enthusiasm, Curiosity to see and know more places... I would like to add one more thing, I really appreciate that Bhutan is preserving their culture and traditions for the next generation.",
            },
            {
                Name: "Ruchir Mehta",
                ImageName: "ruchir_mahata.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "Had the most comfortable stay in Bhutan, thanks to adorable vacation LLp. Our guide Mr.Gyeltshen and our driver were extremely helpful and nice. They were always ready to help us out with everything. I would highly recommend Adorable Vacation LLP to everyone and would love to book our next trip with them too. Thank you team for taking care of us",
            },
            {
                Name: "Harish Kamal",
                ImageName: "Harish-Kamal-Singh.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "I had an excellent experience with Mr. Soumalya Ghosh as our tour manager for the Bhutan trip. He was a very experienced and friendly guide and made out tour very memorable. I would love to recommend Adorable Vacation to anyone who's planning a tour to Bhutan.",
            },
            {
                Name: "Himanshu Shekhar",
                ImageName: "Hemanshu.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "We took Bhutan package by Adorable Vacation. Thank you team Adorable for making our vacation a memorable one. Special shoutout to Soumalya, Sonali, Sneheta, Deepak and Tashi.Bhutan is a beautiful country with honest and humble people. We enjoyed every moment of our stay. Thank you Sneheta for accomodating all our requiremets and arranging such a comfortable stay. Thank you Deepak for explaining the history and culture of each and every place we visited. Thank you Tashi for driving us safe throughout the journey.We were hesistant to trust a private travel agency initially, however, now would recommend Adorable Vacation to everyone.Thank you team adorable. Cheers to you team!!!!",
            },
        ];

        this.setState({
            HotPackagesData: hotPackageDataList,
            ReviewersData: reviewersDataList,
            ThemePackagesData: themePackageDataList,
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Bhutan Hotel Six Senses Package Tour with Direct Flight from India | Six Senses Luxury Tours in Bhutan - BEST DEAL | BOOK NOW!</title>
                    <meta name="description" content="Experience Luxury with a Bhutan Hotel Six Senses Package Tour with Direct Flight from India. Get the Best Rate. ☎ CALL +91-98833-59713" />
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{
                            __html: JSON.stringify({
                                "@context": "http://schema.org",
                                "@type": "FAQPage",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "What is Six Senses Hotel Bhutan, and what makes it unique among hotels in the region?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "<p>Six Senses Hotel Bhutan is a luxury resort located in the heart of the Bhutanese Himalayas, offering guests an exclusive and immersive experience. What sets it apart from other hotels in the region is its strong commitment to sustainability, wellness, and preserving Bhutanese culture. The hotel blends traditional Bhutanese elements with modern comforts, making it the perfect base for a luxury Bhutan tour. With its beautiful location and focus on sustainable practices, a stay at Six Senses Bhutan offers more than just luxury—it offers a true connection with nature and culture. You can experience this unique blend firsthand when you book a Bhutan tour with Six Senses Hotel through Adorable Vacation, one of the top Bhutan tour operators in Asia.</p>"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What is the cancellation policy for reservations made at Six Senses Hotel Bhutan, and are there any penalties or fees for cancellations?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "The cancellation policy at Six Senses Hotel Bhutan may vary depending on the room type, package, and seasonal factors. For specific details, you can contact Six Senses Hotel directly or reach out to Adorable Vacation, who can assist you with the cancellation policy and any associated fees."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Are there any special discounts or promotions currently available for Six Senses Hotel Bhutan, and how can I take advantage of them?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Six Senses Hotel Bhutan sometimes offers special discounts and promotions. You can check their website or directly contact their reservations team to learn about current offers. If you need assistance, the Bhutan travel experts at Adorable Vacation can also provide information about available discounts and help you make the most of your stay at Six Senses Bhutan."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What are the dining options available at Six Senses Hotel Bhutan, and what types of cuisine are served?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Six Senses Hotel Bhutan offers a variety of dining options, including a main restaurant, a lounge, and private dining areas. The food is centered around fresh, organic ingredients, with a focus on traditional Bhutanese flavors combined with international cuisine. The talented chefs at Six Senses Bhutan are passionate about crafting dishes that showcase both local ingredients and global culinary techniques, ensuring a unique and delicious dining experience."
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What activities and amenities are available at Six Senses Hotel Bhutan, and how can I make the most of my stay there?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Six Senses Hotel Bhutan offers a wide range of activities and amenities designed to enhance your stay. These include spa treatments, yoga and meditation classes, cultural experiences, and outdoor adventures like hiking, cycling, and archery. You can also experience traditional Bhutanese activities, such as making ara (local moonshine) and participating in the Bhutanese game of khuru (dart throwing). To make the most of your stay, it's recommended to plan ahead and contact Adorable Vacation to help schedule the best activities for your interests."
                                        }
                                    }
                                ]
                            })
                        }}
                    ></script>


                </Helmet>
                <MainHeader />
                <Bhutan_PackageTourSixsenseDirectFlightIndia
                    HotPackagesData={this.state.HotPackagesData}
                    ReviewersData={this.state.ReviewersData}
                    ThemePackagesData={this.state.ThemePackagesData}
                />
                <FooterDesktop />

            </>
        );
    }
}

export default Bhutan_TourSixsenseDirectFlightIndiaPage;