import React, { Component } from 'react';

class Facilities extends Component {
    
    render() {
        return (
            <>
             <div id="no-more-tables">
                 <table className="col-md-12 table-bordered table-striped table-condensed cf">
                    <tbody>
                        <tr>
                            <td style={{ fontSize: "16px" }}> Double/Twin Sharing Room Hotels </td>
                            <td style={{ fontSize: "16px" }}> Banquet facilities </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: "16px" }}>Barbecue </td>
                            <td style={{ fontSize: "16px" }}>Earth Lab </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: "16px" }}>Indoor swimming pool </td>
                            <td style={{ fontSize: "16px" }}>Fitness Center </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: "16px" }}>Yoga room </td>
                            <td style={{ fontSize: "16px" }}>Private dining room for 10 guests</td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: "16px" }}>Boutique</td>
                            <td style={{ fontSize: "16px" }}>Prayer pavilion</td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: "16px" }}>In-villa massage available for singles or doubles </td>
                            <td style={{ fontSize: "16px" }}>Library, books, and magazines</td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: "16px" }}>Unlimited WiFi</td>
                            <td style={{ fontSize: "16px" }}>Sunken outdoor performance deck</td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <br />
                
            </>
        );
    }
}

export default Facilities;