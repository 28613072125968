import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Andaman/InclusionExclusion_Andaman_5N_6D_Honeymoon_Tour_Package';

class Andaman_5N_6D_Honeymoon_Tour_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };

    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/Andaman-Honeymoon-Package-Tour.webp";
        const packageTopBanner2ImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/Andaman-Honeymoon-Tour-Package.webp";

        const sightseen1ImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/Customized-Andaman-Trip-from-Kolkata.webp";
        const sightseen2ImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/Andaman-Tour-Packages.webp";
        const sightseen3ImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/Andaman-Tour-Package-Including-Airfare.webp";
        const sightseen4ImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/Andaman-Tour-Package-from-Kolkata.webp";
        const sightseen5ImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/Andaman-Tour-Package-Cost.webp";
        const sightseen6ImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/Andaman-Tour-from-Kolkata.webp";
        const sightseen7ImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/Andaman-Package-Tour-Cost-from-Kolkata.webp";
        const sightseen8ImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/Andaman-Packages-Tour.webp";
        const sightseen9ImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/Andaman-Nicobar-Tour-Package-Price.webp";
        const sightseen10ImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/Andaman-nicobar-Tour-Package-from-Kolkata.webp";
        const sightseen11ImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/Andaman-Luxury-Tour-Package.webp";
        const sightseen12ImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/Andaman-and-nicobar-tour-Package-from-Kolkata.webp";
        const navDay1ImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/Andaman-Tour-Itinerary-from-Kolkata.webp";
        const navDay2ImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/Andaman-and-nicobar-islands-tour-package-from-Kolkata.webp";
        const navDay3ImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/Kolkata-to-Andaman-Package-Tour.webp";
        const navDay4ImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/Andaman-nicobar-Package-from-Kolkata.webp";
        const navDay5ImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/Andaman-Honeymoon-Tour-Cost-from-Kolkata.webp";
        const navDay6ImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/Andaman-Package-Tour-Plan-from-Kolkata.webp";

        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/" + dataItem.ImageName;
                    const contactURL = dataItem.Url;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <Link to={contactURL} target="_blank" className="btn btn-Warning btn-sm float-end">Call Now</Link>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Andaman_5N_6D_HoneymoonPackageImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">
                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>ANDAMAN HONEYMOON TOUR PACKAGE FROM KOLKATA</strong></center></h1>
                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>ANDAMAN HONEYMOON TOUR PACKAGE FROM KOLKATA</strong></center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Destinations:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    PORT BLAIR
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    NEIL
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    HAVELOCK
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Andaman Honeymoon Tour Package From Kolkata</strong>, you have come to the right place!
                                        </p>
                                        <hr />
                                        <br />
                                        <h2 className="content-desktop p-2" style={{ fontSize: "2vw" }}>SEARCHING FOR <strong>ANDAMAN HONEYMOON TOUR PACKAGES FROM KOLKATA</strong>? <a href='tel:9147061467' class="black"> Call Mr.Soumya <span className='tomato'>☎  +91-91470 61467 </span></a> </h2>
                                        <h2 className="content-mobile p-2" style={{ fontSize: "5.0vw" }}> SEARCHING FOR <strong>ANDAMAN HONEYMOON TOUR PACKAGES FROM KOLKATA</strong>? <a href='tel:9147061467' class="black"> Call Mr.Soumya <span className='tomato'>☎  +91-91470 61467 </span></a></h2>
                                    </div>
                                    <br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>India's #1 Recommended <strong>Andaman Honeymoon Tour Package</strong> From Kolkata</h3>
                                            <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>India's #1 Recommended <strong>Andaman Honeymoon Tour Package</strong> From Kolkata</h3>
                                        </div>
                                        <div className="card-body">
                                            <p>Honeymoon! Just hearing the word makes us excited. When it’s finally our turn, the first question is "Where?" It can be a bit tricky to pick the perfect spot for this special time. If you’re planning your honeymoon in India, Andaman is one of the few places that offers the peace and space you need to enjoy quality time with your partner. Located far from the mainland in the Bay of Bengal, the Andaman Islands are beautiful and offer many spots for honeymooners to explore. For a truly memorable vacation, plan your romantic getaway in Andaman. Book your <strong>Andaman Honeymoon Package Tour from Kolkata</strong> with Adorable Vacation – the top choice for <strong>Best Customized Honeymoon Tour Operator from Kolkata</strong>.</p>
                                            <p>Andaman is a great choice for a honeymoon in India. It's a popular spot for couples from all over the world. With its long white sandy beaches, gentle breezes, mangroves, beautiful islands, top-notch resorts, turquoise waters, stunning coral reefs, and clear skies, Andaman offers a perfect romantic getaway.</p><br />
                                            <h5><b>ANDAMAN'S PERFECT PLACE FOR HONEYMOON COUPLESTO ITS UNDENIABLE CHARM</b>:</h5>
                                            <br />
                                            <p>The beaches are peaceful, with a mix of lush mangrove forests, blue waters, and white sandy shores.<br /><br />
                                                On your <strong>Andaman honeymoon tour</strong>, you can enjoy stunning sunrises and sunsets from the long beaches of Ross and Smith Islands, overlooking the Bay of Bengal.<br /><br />
                                                Explore vibrant underwater life with colourful corals and diverse marine flora and fauna at Jolly Buoy Island, North Bay Island, and Elephant Beach.<br /><br />
                                                Get an adrenaline rush by exploring the mangrove creeks of Baratang Island.<br /><br />
                                                The beaches are picture-perfect with long stretches of soft white sand, and they look especially beautiful at sunset, just like in travel magazines.<br /><br />
                                                Your <strong>Kolkata to Andaman Honeymoon Package Tour</strong> will be enhanced by tropical flavours, with freshly caught seafood and tropical fruits served with a refreshing sea breeze.<br /><br />
                                                Stay in sea-facing exotic resorts where you can enjoy the first rays of the sun in the morning and the last rays at dusk, making your <strong>Andaman Honeymoon Trip</strong> even more special
                                            </p>
                                            <br /> <br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen1ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                                                            <img src={sightseen2ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                                                            <img src={sightseen3ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br /> <br />
                                            <a href="tel://9147061467" className="btn btn-Warning">Call Now</a>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><strong>ANDAMAN PACKAGE TOUR DURATION : 5N | 6D</strong></h3>
                                            <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><strong>ANDAMAN PACKAGE TOUR DURATION : 5N | 6D</strong></h3>
                                        </div>
                                        <div className="card-body">
                                            <p>Destinations Covered in <strong>Andaman Package from Kolkata :</strong> PORT BLAIR 3N | NEIL 1N | HAVELOCK 1N </p>
                                            <p> Pickup & Drop point for <strong> Andaman Tour Package</strong> : Veer Savarkar International Airport, Port Blair [IXZ], Port Blair</p>
                                            <p><strong>Andaman Honeymoon Tour Package Cost</strong> : Rs - 44,000/- (Total Package Price)</p>
                                            <p><b>Pickup & Drop:</b> Veer Savarkar International Airport, Port Blair (IXZ)</p>
                                            <br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf" style={{ width: '100%', textAlign: 'center' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>Andaman</strong></td>
                                                            <td>11.7401° N, 92.6586° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Havelock</strong></td>
                                                            <td>11.9761° N, 92.9876° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Neil Island</strong></td>
                                                            <td>11.8329° N, 93.0526° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Highest Point</strong></td>
                                                            <td>Saddle Peak, 732 m (2,402 ft)</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Currency</strong></td>
                                                            <td>Indian Rupee (INR)</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Time Zone</strong></td>
                                                            <td>GMT+5:30</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>5 BEST PLACES TO VISIT ON YOUR ANDAMAN HONEYMOON TOUR</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>5 BEST PLACES TO VISIT ON YOUR ANDAMAN HONEYMOON TOUR</h4>
                                </div>
                                <div className="card-body">
                                    <p>The Andaman Islands are all about natural beauty. If you're on your honeymoon here, you're in the perfect place. The soft, white sandy beaches offer peace and serenity, making it ideal for romantic walks with your special someone. At night, the atmosphere becomes even more magical as you stroll under a starry sky, with the gentle sound of waves and a cool sea breeze. Ready to discover some amazing spots? Here are the top places to visit:</p>
                                    <p><b>Havelock Island</b></p>
                                    <p>Havelock Island is 70km from Port Blair by sea. You can reach it in just 1.5 hours by AC Catamaran Ferry. Known for its stunning natural beauty and beaches, <strong>Andaman Havelock Island Package Tour</strong> is an ideal spot for honeymooners. With clear blue-green waters, it’s perfect for activities like diving, snorkelling, and kayaking. Havelock Island offers a peaceful escape similar to popular Southeast Asian destinations like Bangkok, Pattaya, and Bali, but within India. It has some of the most beautiful beaches where you can unwind and relax.</p>

                                    <p><b>Beaches to visit in Havelock Island:</b><br /><br />
                                        <li>Radhanagar Beach &nbsp; &#8226; Elephant Beach &nbsp; &#8226; Kalapathar Beach &nbsp; &#8226; Govind Nagar Beach &nbsp; &#8226; Vijay Nagar Beach</li><br />
                                    </p>
                                    <p><b>Neil Island</b></p>
                                    <p>Neil Island is a top destination for honeymooners visiting the Andaman Islands. Its serene turquoise sea and long white sand beaches, framed by lush green mangroves, create a perfect setting for a romantic sunset walk. The cool, salty sea water and fresh breeze make it a rejuvenating experience. Sunset on Neil Island, with its colourful sky, is a memory worth capturing. Enjoy a candlelight dinner on the beach under the starry sky for a truly romantic experience.</p>
                                    <p><b>Beaches to visit in Neil Island:</b></p>
                                    <ul>
                                        <li>•	Laxmanpur Beach  &nbsp; •	Sitapur Beach &nbsp;•	Bharatpur Beach &nbsp;•	Howrah Bridge &nbsp;•	Neil Island Jetty &nbsp;•	Ram Nagar Beach</li><br />
                                    </ul><br />
                                    <p><b>Little Andaman:</b></p>
                                    <p>On your <b>Andaman Honeymoon Tour</b>, a visit to Little Andaman is a must. Located about 120km from Port Blair, it is the fourth largest island in the group. You can reach it by an overnight ship journey from Port Blair, taking about 6 to 7 hours. Little <b>Andaman Trip</b> offers a quieter experience with fewer tourists, making it perfect for honeymooners. The island features beautiful beaches, waterfalls, and a scenic lighthouse. Enjoy the cool sea breeze, gentle waves, and mangroves while exploring this stunning island.</p>
                                    <br />
                                    <p><b>Beaches to visit in Little Andaman:</b></p>
                                    <ul>
                                        <li>•	Butler Bay Beach &nbsp;•	Kalapathar Limestone Caves &nbsp;• White Surf Waterfall &nbsp;•  Whisper Wave Waterfall &nbsp;•   Richardson's Lighthouse &nbsp;•   Red Palm Oil Plantation &nbsp;•   Chattan Snorkeling Beach</li><br />
                                    </ul>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>HERE'S A SIMPLE CHART FOR YOUR ANDAMAN HONEYMOON TOUR ACTIVITIES</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HERE'S A SIMPLE CHART FOR YOUR ANDAMAN HONEYMOON TOUR ACTIVITIES</h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>Activity</th>
                                                    <th>Where to Do</th>
                                                    <th>Cost/Person (approx.)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="Activity">Snorkelling</td>
                                                    <td data-title="To Do">North Island Bay / Elephant Beach</td>
                                                    <td data-title="Cost/PP">INR 500 to 1000</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Activity">Bio-Luminescent Beach</td>
                                                    <td data-title="To Do">Havelock Island</td>
                                                    <td data-title="Cost/PP">FREE</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Activity">Scuba Diving</td>
                                                    <td data-title="To Do">Havelock Island, Elephant Beach, North Bay Island, Neil Island</td>
                                                    <td data-title="Cost/PP">INR 1,800 onwards</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Activity">Banana Boat Ride</td>
                                                    <td data-title="To Do">Rajiv Gandhi Water Sports Complex, North Bay Island in Port Blair</td>
                                                    <td data-title="Cost/PP">INR 620 onwards</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Activity">Glass Bottom Boat Ride</td>
                                                    <td data-title="To Do">North Bay Coral Island, Jolly Buoy Island in Port Blair</td>
                                                    <td data-title="Cost/PP">INR 500 onwards</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Activity">Parasailing</td>
                                                    <td data-title="To Do">Jolly Buoy Island, North Bay Island, Ross Island, Havelock Island</td>
                                                    <td data-title="Cost/PP">INR 3,480 onwards</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Activity">Sea Plane Ride</td>
                                                    <td data-title="To Do">Port Blair to Havelock</td>
                                                    <td data-title="Cost/PP">INR 4,100 (1-way)</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Activity">Cellular Jail Visit</td>
                                                    <td data-title="To Do">Port Blair</td>
                                                    <td data-title="Cost/PP">INR 30 (Indian), INR 100 (Foreigners)</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHEN IS THE BEST TIME TO BOOK AN ANDAMAN HONEYMOON TOUR PACKAGE?</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHEN IS THE BEST TIME TO BOOK AN ANDAMAN HONEYMOON TOUR PACKAGE?</h4>
                                </div>
                                <div className="card-body">
                                    <p>The Andaman Islands are a great travel spot all year round due to their mild weather. According to Adorable Vacation, the ideal time to book your <strong>Andaman Tour Package from Kolkata</strong> is from late October to the end of March. This period includes the winter and early summer seasons, which offer calm, clear, and pleasant weather. The seas are stable, making it perfect for island-hopping and water activities. So, this is the best time to plan your <strong>Andaman Tour Package</strong> and enjoy the island’s beauty.</p>
                                    <br /> <br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen4ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                                                    <img src={sightseen5ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                                                    <img src={sightseen6ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /> <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>HOW TO REACH ANDAMAN?</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HOW TO REACH ANDAMAN?</h4>
                                </div>
                                <div className="card-body">
                                    <p>For Indian travellers, there are no special travel restrictions to visit Andaman. It's just like traveling to any other state in India. You don't need a visa, passport, or special permit.
                                        So, how can you get to Andaman? There are only two ways to reach Andaman:
                                    </p>
                                    <h5><b>By Air: </b></h5><br />
                                    <p>Since the Andaman Islands are far from the mainland, flying is the easiest and fastest way to get there. You can catch direct flights from major Indian cities like Kolkata, Chennai, and Delhi. From Kolkata, it takes about 2.5 hours to reach Veer Savarkar International Airport (IXZ) in Port Blair. Daily flights to Andaman are available from airlines such as Indigo, Air India, Indian Airlines, SpiceJet, Go Air, and Vistara.</p>
                                    <h5><b>By Sea:</b></h5><br />
                                    <p>Andaman is well-connected to major cities like Kolkata, Chennai, and Vishakhapatnam. Ships leave from these cities to Port Blair 3 to 4 times a month. The journey takes about 3 to 4 days (60 to 66 hours), depending on the weather.</p>
                                    <p><b>Andaman Tour Package from Kolkata by Ship:</b></p>
                                    <p><b>MV Nancowry:</b> A passenger and cargo ship that leaves from Chennai.</p>
                                    <p><b>MV Nicobar:</b> A passenger and cargo ship that leaves from Chennai.</p>
                                    <p><b>MV Harshavardhana:</b> A passenger and cargo ship that leaves from Kolkata.</p>
                                    <p><b>MV Swaraj Dweep:</b> A passenger and cargo ship that leaves from Chennai.</p>
                                    <p><b>MV Akbar:</b> The oldest ship of its kind, leaving from Kolkata.</p>
                                    <p>Because these trips are very popular, make sure to book your tickets well in advance if you want to visit <strong>Andaman Tour Package by Ship</strong> and enjoy the sea journey.</p>
                                    <br /> <br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen7ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                                                    <img src={sightseen8ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                                                    <img src={sightseen9ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /> <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DISTANCES BETWEEN PLACES TO NOTE FOR <strong>ANDAMAN AND NICOBAR PACKAGE FROM KOLKATA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DISTANCES BETWEEN PLACES TO NOTE FOR <strong>ANDAMAN AND NICOBAR PACKAGE FROM KOLKATA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>Places From</th>
                                                    <th>Places To</th>
                                                    <th>Distance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="From">Kolkata</td>
                                                    <td data-title="To">Andaman</td>
                                                    <td data-title="Distance">1303 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Chennai</td>
                                                    <td data-title="To">Andaman</td>
                                                    <td data-title="Distance">1370 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Hyderabad</td>
                                                    <td data-title="To">Andaman</td>
                                                    <td data-title="Distance">1661 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Delhi</td>
                                                    <td data-title="To">Andaman</td>
                                                    <td data-title="Distance">3518 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Mumbai</td>
                                                    <td data-title="To">Andaman</td>
                                                    <td data-title="Distance">2279 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Port Blair</td>
                                                    <td data-title="To">Havelock Island (Swaraj Dweep)</td>
                                                    <td data-title="Distance">70 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Port Blair</td>
                                                    <td data-title="To">Neil Island (Shahid Dweep)</td>
                                                    <td data-title="Distance">42.4 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Havelock Island</td>
                                                    <td data-title="To">Neil Island</td>
                                                    <td data-title="Distance">1 hr 58 min (40.0 KM) via SH4</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Neil Island</td>
                                                    <td data-title="To">Port Blair</td>
                                                    <td data-title="Distance">43 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Port Blair</td>
                                                    <td data-title="To">Radhanagar Beach</td>
                                                    <td data-title="Distance">3 hr 17 min (72.0 KM)</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Port Blair</td>
                                                    <td data-title="To">Elephant Beach</td>
                                                    <td data-title="Distance">3 hr 13 min (69.8 KM)</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Port Blair</td>
                                                    <td data-title="To">Kalapathar Beach</td>
                                                    <td data-title="Distance">3 hr 10 min (67.5 KM)</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Port Blair</td>
                                                    <td data-title="To">Baratang</td>
                                                    <td data-title="Distance">101 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Port Blair</td>
                                                    <td data-title="To">Ross Island</td>
                                                    <td data-title="Distance">11 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Ross Island</td>
                                                    <td data-title="To">North Bay Island</td>
                                                    <td data-title="Distance">49 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Port Blair</td>
                                                    <td data-title="To">Jolly Buoy Island</td>
                                                    <td data-title="Distance">30 KM</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TOP TRAVEL TIPS FOR YOUR ANDAMAN HONEYMOON TRIP FROM KOLKATA</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>TOP TRAVEL TIPS FOR YOUR ANDAMAN HONEYMOON TRIP FROM KOLKATA</h4>
                                </div>
                                <div className="card-body">
                                    <p>→ Visit the historic Cellular Jail and watch the amazing Light & Sound Show.</p>
                                    <p>→ Try scuba diving in Havelock Island.</p>
                                    <p>→ Enjoy snorkelling at Jolly Buoy Island and North Bay Island.</p>
                                    <p>→ See the beautiful Coral Reef from a Glass Bottom Boat at Jolly Buoy Island.</p>
                                    <p>→ Try fun water activities like Banana Boat Rides, Scuba Diving, and Speed Boat Riding.</p>
                                    <p>→ Don’t miss out on authentic Andaman cuisine.</p>
                                    <p>→ Rent a Scooty or Royal Enfield Bullet to explore the island.</p>
                                    <p>→ Get a great view of the Andaman Islands from a Sea Plane.</p>
                                    <p>→ Capture stunning sunrises and sunsets with your DSLR.</p>
                                    <p>→ Take a cruise on the M.V Makruzz to visit different islands.</p>
                                    <p>→ Visit the Barren Island, the only active volcano in India and Southeast Asia.</p>
                                    <br /> <br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen10ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                                                    <img src={sightseen11ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                                                    <img src={sightseen12ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /> <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>DOES AND DON'T FOR YOUR ANDAMAN HONEYMOON TOUR</b></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>DOES AND DON'T FOR YOUR ANDAMAN HONEYMOON TOUR</b></h4>
                                </div>
                                <div className="card-body">
                                    <p><b>→ Do not</b> litter plastics on the islands. It’s against the law.</p>
                                    <p><b>→ Dispose of</b> garbage properly. The Swachh Bharat Abhiyaan applies here too.</p>
                                    <p><b>→ No smoking</b> in public places.</p>
                                    <p><b>→ Hunting</b> birds, animals, or sea creatures is forbidden.</p>
                                    <p><b>→ Do not</b> contact tribal people. It’s illegal.</p>
                                    <p><b>→ Do not</b> collect or remove shells from the beach.</p>
                                    <p><b>→ Avoid swimming</b> in restricted areas.</p>
                                    <p><b>→ Always wear</b> a life jacket while boating.</p>
                                    <p><b>→ Dress decently</b> on beaches and in public areas. Nudity is not allowed.</p>
                                    <p><b>→ Avoid diving</b> with unregistered operators.</p>
                                    <p><b>→ Do not photograph</b> or film tribal people like the Jarwa Tribe.</p>
                                    <p><b>→ Follow traffic rules</b> in Port Blair.</p>
                                    <p><b>→ Carry</b> important documents like your passport, permit, and driving license.</p>
                                    <p><b>→ Use sunscreen, hats, sunglasses, and slippers</b> during day trips.</p>
                                    <p><b>→ Always have</b> bottled water with you.</p>
                                    <p><b>→ Be cautious</b> while cruising.</p>
                                    <p><b>→ Respect</b> the history and culture of Andaman.</p>
                                    <p><b>→ Keep quiet</b> while visiting the Cellular Jail.</p>
                                    <p><b>→ Book</b> motorcycles or scooters in advance due to high demand.</p>
                                    <p><b>→ Bring</b> a raincoat and umbrella if traveling between May and September.</p>
                                    <p><b>→ Don’t leave</b> important documents at the hotel or resort.</p>
                                    <p><b>→ No bonfires</b> on the beaches.</p>
                                    <p><b>→ Foreign tourists</b> must not stay after their travel documents expire.</p>
                                    <p><b>→ Do not</b> stay overnight on beaches or in forests.</p>
                                </div>
                            </div>
                            <br /><br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                <span>ANDAMAN HONEYMOON TOUR ITINERARY FROM KOLKATA</span>
                                            </h2>
                                            <h4>5 Nights 6 Days <strong>Andaman Tour Package Summary</strong></h4>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--end Short description--> */}
                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    DAY 1  – ARRIVAL, CORBYN'S COVE BEACH, CELLULAR JAIL & SOUND AND LIGHT SHOW
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Welcome to Andaman for your exciting honeymoon tour! When you arrive at Veer Savarkar Airport, Port Blair, our representative will greet you and help with the hotel check-in. After settling in and relaxing, you'll visit:
                                                    </p>
                                                    <p><b>• Corbyn's Cove Beach </b>
                                                        The coconut-palm fringed beach, 3 kilometers away town is ideal for swimming and sun- basking. Facilities like hotel, restaurant, bar, changing room, adventure water-sports are available here. Historical remains like Japanese Bunkers can be seen on the way to this beach. The Snake Island situated just across the beach is popular for scuba diving. The Restaurant & Beer Bar run by Tourism department provides delightful cuisine at reasonable rates in a soothing ambiance on the beach.
                                                    </p>
                                                    <p><b>• Light and Sound Show </b>
                                                        The Sound and Light show in the Cellular Jail narrates the saga of the Indian freedom struggle, brought alive through sound and light effect of the brutal and barbaric atrocities piled on the freedom fighters deported to these islands and incarcerated in the small cells of this jail. This show is very popular among the tourists visiting these islands.
                                                    </p>
                                                    <p><b>• Cellular Jail </b>
                                                        The Cellular jail, declared a National Memorial, is located at Port Blair which had stood as a mute witness to the most brutal and barbaric atrocities meted out to national freedom fighters, who were incarcerated in this jail. The jail, completed in the year 1906 acquired the name „Cellular' because it was entirely made up of individual cells for solitary confinement. It originally was a seven- block structure with three stories and a central tower acting as its fulcrum comprising of honeycomb like corridors. This jail, now a place of pilgrimage for all
                                                    </p>
                                                    <p>freedom-loving people draws everyone down memory lane to the years of freedom struggle. Jail Museum open from 9:00 am to 12:00 noon and from 1:30 pm to 4:45 pm.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day2"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    DAY 02: PORT BLAIR TO HAVELOCK ISLAND - VISIT RADHANAGAR BEACH
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        In the morning, you'll be taken to Phoenix Bay Harbor to board a luxury cruise to Havelock Island. Upon arrival at the harbor, our representative will meet you and take you to your hotel for check-in. After checking in, you’ll be taken to Radhanagar Beach, known as Asia's 7th most beautiful beach. At Radhanagar Beach, you can swim, dip your feet in the water, or simply enjoy the stunning view.
                                                    </p>
                                                    <p><b>Havelock Island:</b> Named after a British Army officer, Havelock Island offers great facilities for snorkelling and scuba diving. It's famous for its white sand and clear, transparent waters. You’ll stay overnight on Havelock Island.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day3"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 03: VISIT KALA PATHAR BEACH & ELEPHANTA BEACH, HAVELOCK
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Start your day early to catch the beautiful sunrise at Havelock Beach. Enjoy the fresh air and take in as much oxygen as possible. It’s a perfect time for Yoga or Surya Namaskar on this serene beach.<br />
                                                        After breakfast, head to Kala Pathar Beach. It’s about 10 km from Havelock Island, and you can get there by vehicle or TukTuk in just 30 minutes.
                                                    </p>
                                                    <p>Kala Pathar Beach is located on one side of Havelock Island. It features a long stretch of white sandy beach with large black rocks on the shore. The sparkling white sand and clear turquoise water make this beach unique. It’s less crowded, making it an ideal spot for a romantic walk. Spend some quality time with your partner, enjoy the peaceful surroundings, and create special memories together.
                                                    </p>
                                                    <p>Enjoy a leisurely day with lunch at an Indian restaurant by the beach. In the evening, return to your hotel for dinner and an overnight stay.</p>
                                                    <br />

                                                    <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="Kashmir Tour Packages" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Packages" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day4"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 04: HAVELOCK TO NEIL ISLAND BY CRUISE + NEIL BEACHES + SUNSET
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <p>Wake up early to enjoy your last morning in Havelock. After breakfast, check out from your hotel and head to the harbor to catch a ferry to Neil Island. Our representative will meet you at the harbor and take you to your hotel for check-in. The rest of the day is free for you to explore on your own. Enjoy the beaches and the sunset. Spend the night on Neil Island.
                                                    </p>
                                                    <p><b>Note:</b> Before your trip, check out of the hotel. Your luggage will be safely stored in a kiosk, and our representative will bring it to you before you board the ferry to the next island.</p>
                                                    <p><b>Neil Island:</b> Named after James George Smith Neill, a British soldier involved in the 1857 Mutiny, Neil Island has a single jetty at Bharathpur, which is the only entry and exit point. Overnight stay on Neil Island.</p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay4ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day5"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 05: NEIL TO PORT BLAIR BY CRUISE + SHOPPING
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, check out from the hotel and visit Neil Island’s local sights, including Bharatpur Beach, Laxmanpur Beach, and the natural rock formation known locally as Howrah Bridge. In the evening, start your cruise back to Port Blair, enjoying the sea breeze, sunset colours, and sometimes even dolphins and flying fish.
                                                    </p>
                                                    <p>Upon arrival in Port Blair, check into your hotel and freshen up. The evening is free for leisure. You can hire an auto or tuk-tuk to explore the island or rent a bike to discover less-visited areas. Enjoy driving or biking through coconut and banana plantations alongside beautiful beaches.
                                                    </p>
                                                    <p>In the evening, you can shop at local stores for pearl jewellery, shell home decor, coconut lampshades, and tribal masks. In <strong>Andaman Tour</strong> Don’t miss trying some freshly caught seafood and local wine. Spend the night at your hotel in Port Blair.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day6"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 06: PORT BLAIR TO AIRPORT WITH SWEET MEMORIES
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, it's time to say goodbye to the Andaman Islands. Head to the airport for your flight and start your journey home. Your <strong>Andaman Honeymoon Package Tour</strong> ends with wonderful memories.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay6ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            {/* <!--By Itinerary--> */}

                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}

                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Frequently Ask <span>Questions</span></h2>
                                            <h4>Andaman Havelock Island Package Tour</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                    1. What is included in the Andaman Havelock Island package?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <ul>
                                                        <li>Accommodation</li>
                                                        <li>Meals (as specified in the itinerary)</li>
                                                        <li>Transfers and sightseeing as per the itinerary</li>
                                                        <li>Entry fees to sites and activities mentioned</li>
                                                        <li>Assistance from our local tour representatives</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                    2. How do I reach Havelock Island from Kolkata?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>You can fly from Kolkata to Port Blair. From Port Blair, take a ferry to Havelock Island.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                    3. What documents do I need to carry?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <ul>
                                                        <li>Valid ID proof (e.g., passport, Aadhar card)</li>
                                                        <li>Travel permit (if applicable)</li>
                                                        <li>Booking confirmation and tickets</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSix">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                                    4. Are there any specific rules for visiting Havelock Island?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <ul>
                                                        <li>Follow local regulations regarding littering and environmental protection.</li>
                                                        <li>Respect local customs and traditions.</li>
                                                        <li>Obtain necessary permits for certain activities.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                    5. What should I pack for the trip?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <ul>
                                                        <li>Comfortable clothing and swimwear</li>
                                                        <li>Sunscreen, hats, and sunglasses</li>
                                                        <li>Personal medications</li>
                                                        <li>Waterproof bags for your belongings</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEight">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                                    6. Is there an option for vegetarian or special dietary meals?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>Yes, you can request vegetarian or special dietary meals at the time of booking.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingNine">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                                    7. What are the best activities to do in Havelock Island?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <ul>
                                                        <li>Snorkeling and scuba diving</li>
                                                        <li>Visiting Radhanagar Beach</li>
                                                        <li>Exploring Elephant Beach</li>
                                                        <li>Enjoying water sports and boat rides</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                    8. Can I book additional excursions or activities?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>Yes, you can book additional excursions or activities through our local representatives.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEleven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                                                    9. What should I do if I encounter any issues during the tour?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseEleven" className="accordion-collapse collapse show" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>Contact our local tour representative for assistance. For emergencies, use the contact numbers provided in your Andaman Tour Package from Kolkata.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTwelve">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                                    10. Are there any restrictions on photography?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseTwelve" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>Photography is allowed in most places, but always check for any specific restrictions, especially in tribal areas or protected sites.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingThirteen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                                                    11. How do I handle local transportation?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseThirteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>Local transportation is usually arranged as part of the Andaman Honeymoon Tour Plan from Kolkata. You may also hire taxis or rental vehicles for additional convenience.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFourteen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourteen" aria-expanded="false" aria-controls="flush-collapseFourteen">
                                                    12. What is the best time to visit Havelock Island?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFourteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingFourteen" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>The best time to visit is from October to April when the weather is pleasant and ideal for beach activities.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFifteen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFifteen" aria-expanded="false" aria-controls="flush-collapseFifteen">
                                                    13. What happens if I need to cancel my tour?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFifteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingFifteen" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>Cancellation policies depend on the terms of your booking. Contact our customer service for details on cancellation procedures and potential refunds.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSixteen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSixteen" aria-expanded="false" aria-controls="flush-collapseSixteen">
                                                    14. Are there any health or safety precautions I should take?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSixteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingSixteen" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <ul>
                                                        <li>Ensure you have necessary vaccinations.</li>
                                                        <li>Use mosquito repellent and drink bottled water to avoid health issues.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeventeen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeventeen" aria-expanded="false" aria-controls="flush-collapseSeventeen">
                                                    15. Is travel insurance included in the package?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSeventeen" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeventeen" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>Travel insurance is usually not included. We recommend purchasing it separately for comprehensive coverage.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b>Andaman Package <span class="tomato">Tour Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div >
                </div >
            </>
        );
    }
}

export default Andaman_5N_6D_Honeymoon_Tour_Package;