import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from "./InclusionExclusion_TawangBumlaPass_7N_8D";

class TawangBumlaPass_7N_8D_Package extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: 'tab1',
      daywiseActiveTab: 'day1'
    }
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  handleDayWiseTabClick = (tab) => {
    this.setState({
      daywiseActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919674738480&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
  render() {
    const { customActiveTab, daywiseActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.TawangBumlaPass_7N_8D_PackageImageURL + "/Arunachal-Pradesh-Package-Tour-from-Kolkata.webp";
    const packageTopBanner2ImageURL = ApiURL.TawangBumlaPass_7N_8D_PackageImageURL + "/Arunachal-Pradesh-Tour-Package-from-Kolkata.webp";
    const sightseen1ImageURL = ApiURL.TawangBumlaPass_7N_8D_PackageImageURL + "/ARUNACHAL PRADESH TOUR PACKAGES FROM KOLKATA.webp";
    const sightseen2ImageURL = ApiURL.TawangBumlaPass_7N_8D_PackageImageURL + "/Arunachal-Pradesh-Package-Tour-from-Guwahati.webp";
    const sightseen3ImageURL = ApiURL.TawangBumlaPass_7N_8D_PackageImageURL + "/Shillong-Package-Tour.webp";

    const navDay1ImageURL = ApiURL.TawangBumlaPass_7N_8D_PackageImageURL + "/BHALUKPONG-TO-DIRANG-TOUR.webp";
    const navDay2ImageURL = ApiURL.TawangBumlaPass_7N_8D_PackageImageURL + "/DIRANG-TO-TAWANG.webp";
    const navDay3ImageURL = ApiURL.TawangBumlaPass_7N_8D_PackageImageURL + "/TAWANG-LOCAL-SIGHTSEEING.webp";
    const navDay4ImageURL = ApiURL.TawangBumlaPass_7N_8D_PackageImageURL + "/TAWANG-BUMLA PASS.webp";
    const navDay5ImageURL = ApiURL.TawangBumlaPass_7N_8D_PackageImageURL + "/TEZPUR-KAZIRANGA.webp";
    const navDay6ImageURL = ApiURL.TawangBumlaPass_7N_8D_PackageImageURL + "/TAWANG-BOMDILA.webp";
    const navDay7ImageURL = ApiURL.TawangBumlaPass_7N_8D_PackageImageURL + "/BOMDILA-TEZPUR";
    const navDay8ImageURL = ApiURL.TawangBumlaPass_7N_8D_PackageImageURL + "/JUNGLE-SAFARI-KAZIRANGA.webp";
    const navDay9ImageURL = ApiURL.TawangBumlaPass_7N_8D_PackageImageURL + "/GUWAHATI-TO-BHALUKPONG.webp";
    const navDay10ImageURL = ApiURL.TawangBumlaPass_7N_8D_PackageImageURL + "/GUWAHATI.webp";

    const TrendingPackagesDataList = this.props.TrendingPackagesData;
    const hotPackagesView = TrendingPackagesDataList.length > 0 ?
      (
        TrendingPackagesDataList.map((dataItem, i) => {
          const hotelImgURL = ApiURL.TawangBumlaPass_7N_8D_PackageImageURL + "/" + dataItem.ImageName;
          return (<div className="col-md-12 p-0" key={i}>
            <div className="card h-100">
              <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
              <div className="card-body">
                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text"><span>{dataItem.Description}</span></p>
                <p>
                  <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                  <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                  <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                </p>
              </div>
              <div className="card-footer">
                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                <button type="button" className="btn btn-Warning btn-sm float-end">Book Now</button>
              </div>
            </div>
          </div>)
        })
      ) : (<div></div>)


    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL = ApiURL.TawangBumlaPass_7N_8D_PackageImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      )
    })

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
          </div>
        </div>
        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container py-1">


                    <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center>Book <strong>Tawang Bumla Pass Package Tour from Guwahati</strong> - BEST DEAL</center></h1>

                    <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center>Book <strong>Tawang Bumla Pass Package Tour from Guwahati</strong> - BEST DEAL</center>
                      <hr />
                    </h3>
                    <div className="container-fluid">
                      <div className="row">
                        <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                      </div>
                    </div>
                    <hr />
                  </div>
                  <h2 className="content-desktop p-4" style={{ fontSize: "2.0vw" }}>Looking For <strong>Tawang Bumla Pass Tour Packages from Guwahati</strong>? <br /><br /> <a href="tel:+91-9674738480"> FOR BOOKING <strong>TAWANG TOUR WITH BUMLA-PASS</strong> ☎ CALL +91-96747-38480</a><br /><br /></h2>
                  <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}>Looking For <strong>Tawang Bumla Pass Tour Packages from Guwahati</strong>? <br /><br /> <a href="tel:+91-9674738480"> FOR BOOKING <strong>TAWANG TOUR WITH BUMLA-PASS</strong> ☎ CALL +91-96747-38480</a><br /><br /></h2>
                  <div className="card">
                    <div
                      className="card-header custHeaer"
                    >
                      <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> India's Largest <b>North East Tour Operator</b></h3>
                      <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> India's Largest <b>North East Tour Operator</b></h4>
                    </div>
                    <div className="card-body">
                      <p>Nestled amidst the majestic Himalayas, the Tawang Bumla Pass is a key destination for those seeking awe-inspiring landscapes and a rich cultural heritage, making it an integral part of the <strong>Tawang Bumla Pass package tour from Guwahati</strong>. Situated in the Tawang district of Arunachal Pradesh, India, this pass, standing at an elevation of over 15,000 feet above sea level, offers adventure enthusiasts and history buffs an unforgettable experience. <br /><br />
                        The journey to <strong>Tawang package tours with Bumla Pass</strong> is an adventure in itself, with winding roads that unveil panoramic views of snow-capped peaks, dense forests, and meandering rivers. Visitors are treated to an immersive experience in nature's lap, where each turn reveals a new facet of the region's untamed splendour. <br /><br />
                        Beyond its natural allure, Tawang and Bumla Pass is deeply ingrained in the historical narrative of the region, having played a pivotal role during the Indo-China war of 1962. Remnants of the conflict, such as the war memorial, add a poignant touch to the landscape. Travellers can explore the historical significance of the pass while soaking in the serenity of the surrounding mountains. <br /><br />
                        For those seeking an unforgettable journey with the <strong>Tawang Bumla Pass package tour from Guwahati Airport</strong>, tailor-made experiences are available, ensuring a seamless and enriching adventure. The <strong>Tawang Bumla Pass tour</strong> opens doors to a world of culture and natural beauty, guided by seasoned professionals. This <strong>Tawang tour from Guwahati with Bumla Pass excursions</strong> cover not only the pass but also nearby attractions, monasteries, and local delicacies. <br /><br />
                        Embark on an once-in-a-lifetime adventure with the <a href="#" target="_blank"><strong>Tawang tour package</strong></a>, where every moment is a brushstroke on the canvas of a remarkable journey. Whether you are an adventure seeker or a cultural explorer, <strong>Tawang tour with Bumla Pass</strong> promises an experience that transcends the ordinary, leaving an indelible mark on your travel memories. <br /><br /></p>
                      <br />
                      <div className="content-desktop">
                        <center><img src={sightseen1ImageURL} alt="..." /></center>
                      </div>

                      <div className="content-mobile2">
                        <center><img src={sightseen1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>SUMMARY OF <strong>TAWANG & BUMLA PASS TOUR PACKAGE FOR 8 DAYS</strong> </h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>SUMMARY OF <strong>TAWANG & BUMLA PASS TOUR PACKAGE FOR 8 DAYS</strong> </h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <b>DAY 1 → </b>
                            <span>
                              AIRPORT PICKUP, GUWAHATI TO NAMERI
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTwo"
                          >
                            <b>DAY 2 → </b>
                            <span>NAMERI TO DIRANG</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingThree"
                          >
                            <b>DAY 3 → </b>
                            <span>DIRANG TO TAWANG TRANSFER</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFour"
                          >
                            <b>DAY 4 → </b>
                            <span>AROUND TAWANG EXCURSION</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFive"
                          >
                            <b>DAY 5 → </b>
                            <span>TAWANG SIGHTSEEING</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSix"
                          >
                            <b>DAY 6 → </b>
                            <span>TAWANG TO BOMDILA TRANSFER</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSeven"
                          >
                            <b>DAY 7 → </b>
                            <span>BOMDILA TO TEZPUR, TEZPUR SIGHTSEEING</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTen"
                          >
                            <b>DAY 8 → </b>
                            <span>TEZPUR TO GUWAHATI, AIRPORT DROP</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br /><br />
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>TAWANG BUMLA-PASS TOUR PACKAGE</strong> DURATION : 7N | 8D </h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>TAWANG BUMLA-PASS TOUR PACKAGE</strong> DURATION : 7N | 8D </h4>
                </div>
                <div className="card-body">
                  <p>Places to visit in <strong>Tawang packages from Guwahati with Bumla Pass</strong> :  Nameri [1N] | Dirang [1N] | Tawang [3N] | Bomdila [1N] |Tezpur [1N] <br /></p>
                  <p>Pickup & Drop point for <strong>Tawang to Bumla Pass by car</strong> : Lokpriya Gopinath Bordoloi International Airport [GAU] <br /><br /></p>
                  <br /><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr>
                          <th>ALTITUDE</th>
                          <th>HEIGHT</th>
                          <th>COORDINATES</th>
                        </tr>
                      </thead>
                      <tbody><tr><td colspan="2">Pickup & Drop point for Tawang & Bumla-Pass Tour</td><td>Guwahati</td></tr>
                        <tr><td colspan="2">Tawang & Bumla-Pass Tour Duration</td><td>7N | 8D</td></tr>
                        <tr><td colspan="2">Tawang to Bumla-Pass Distance</td><td>1 hr 12 min (34.1 km) via Bum La Post Rd</td></tr>
                        <tr><td colspan="2">Bumla-Pass to Madhuri Lake Distance</td><td>56 min (25.2 km) via Bum La Post Rd</td></tr>
                        <tr><td>Bhalukpong</td><td>213 m</td><td>27.0137° N, 92.6345° E</td></tr>
                        <tr><td>Dirang</td><td>1,560 m</td><td>27.3584° N, 92.2409° E</td></tr>
                        <tr><td>Tawang</td><td>3,048 m</td><td>27.5861° N, 91.8594° E</td></tr>
                        <tr><td>Bomdila</td><td>2,415 m</td><td>27.2645° N, 92.4159° E</td></tr>
                        <tr><td>Nameri</td><td>90 m</td><td>26.5132° N, 92.3939° E</td></tr>
                        <tr><td>Bumla Pass</td><td>4600 m</td><td>27.4360° N, 91.5400° E</td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>PLACES OF ATTRACTIONS IN <strong>TAWANG BUMLA PASS TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>PLACES OF ATTRACTIONS IN <strong>TAWANG BUMLA PASS TOUR</strong></h4>
                </div>
                <div className="card-body">
                  <p>During <strong>Tawang and Bumla-Pass tour</strong>, we will cover some of the best places of Arunachal like <br /><br />
                    <div id="no-more-tables">
                      <table className="col-md-12 table-bordered table-striped table-condensed cf">
                        <tbody>
                          <tr>
                            <td style={{ fontSize: "16px" }}>Tawang Monastery</td>
                            <td style={{ fontSize: "16px" }}>Sela Pass</td>
                            <td style={{ fontSize: "16px" }}>Tawang War Memorial</td>
                          </tr>
                          <tr>
                            <td style={{ fontSize: "16px" }}>Pankang Teng Tso Lake</td>
                            <td style={{ fontSize: "16px" }}>Nuranang Falls (Jang Falls)</td>
                            <td style={{ fontSize: "16px" }}>Bap Teng Kang Waterfall</td>
                          </tr>
                          <tr>
                            <td style={{ fontSize: "16px" }}>Bum La Pass</td>
                            <td style={{ fontSize: "16px" }}>Gorichen Peak</td>
                            <td style={{ fontSize: "16px" }}>Shonga-tser Lake</td>
                          </tr>
                          <tr>
                            <td style={{ fontSize: "16px" }}>Tawang Craft Centre</td>
                            <td style={{ fontSize: "16px" }}>Jaswant Garh War Memorial</td>
                            <td style={{ fontSize: "16px" }}>Urgelling Monastery</td>
                          </tr>
                          <tr>
                            <td style={{ fontSize: "16px" }}>Gyangong Ani Gompa</td>
                            <td style={{ fontSize: "16px" }}>Sangeshar Lake</td>
                            <td style={{ fontSize: "16px" }}>Bomdila Viewpoint (en route to Tawang)</td>
                          </tr>
                          <tr>
                            <td style={{ fontSize: "16px" }}>Nameri National Park</td>
                            <td style={{ fontSize: "16px" }}>Jia Bhoroli River</td>
                            <td style={{ fontSize: "16px" }}>Nameri Eco Camp</td>
                          </tr>
                          <tr>
                            <td style={{ fontSize: "16px" }}>Bhairabi Temple</td>
                            <td style={{ fontSize: "16px" }}>Dirang Dzong</td>
                            <td style={{ fontSize: "16px" }}>Sangti Valley</td>
                          </tr>
                          <tr>
                            <td style={{ fontSize: "16px" }}>Kalachakra Gompa</td>
                            <td style={{ fontSize: "16px" }}>Dirang Hot Water Springs</td>
                            <td style={{ fontSize: "16px" }}>Dirang Fort</td>
                          </tr>
                          <tr>
                            <td style={{ fontSize: "16px" }}>Thembang Heritage Village</td>
                            <td style={{ fontSize: "16px" }}>National Research Centre on Yak</td>
                            <td style={{ fontSize: "16px" }}>Bomdila Monastery</td>
                          </tr>
                          <tr>
                            <td style={{ fontSize: "16px" }}>Bomdila Viewpoint</td>
                            <td style={{ fontSize: "16px" }}>Eaglenest Wildlife Sanctuary</td>
                            <td style={{ fontSize: "16px" }}>Thembang Village</td>
                          </tr>
                          <tr>
                            <td style={{ fontSize: "16px" }}>Sangti Wildlife Sanctuary</td>
                            <td style={{ fontSize: "16px" }}>Bomdila Craft Centre</td>
                            <td style={{ fontSize: "16px" }}>Upper Gompa</td>
                          </tr>
                          <tr>
                            <td style={{ fontSize: "16px" }}>R.R. Hill</td>
                            <td style={{ fontSize: "16px" }}>Bomdila View Tower</td>
                            <td style={{ fontSize: "16px" }}>Agnigarh</td>
                          </tr>
                          <tr>
                            <td style={{ fontSize: "16px" }}>Bamuni Hills</td>
                            <td style={{ fontSize: "16px" }}>Cole Park</td>
                            <td style={{ fontSize: "16px" }}>Chitralekha Udyan</td>
                          </tr>
                          <tr>
                            <td style={{ fontSize: "16px" }}>Mahabhairab Temple</td>
                            <td style={{ fontSize: "16px" }}>Nag-Sankar Temple</td>
                            <td style={{ fontSize: "16px" }}>Padum Pukhuri</td>
                          </tr>
                          <tr>
                            <td style={{ fontSize: "16px" }}>Bhairabi Temple</td>
                            <td style={{ fontSize: "16px" }}>Kalia Bhomora Setu (Bridge)</td>
                            <td style={{ fontSize: "16px" }}>Ketakeshwar Dewal</td>
                          </tr>
                          <tr>
                            <td style={{ fontSize: "16px" }}>Bura-Chapori Wildlife Sanctuary</td>
                            <td style={{ fontSize: "16px" }}>Kolia Bhomora Setu</td>
                            <td style={{ fontSize: "16px" }}></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <br /><br /></p>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>HOW IS THE WEATHER IN TAWANG?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HOW IS THE WEATHER IN TAWANG?</h4>
                </div>
                <div className="card-body">
                  <p><b>Tawang</b>, a gem in Northeast India, is situated at an elevation of approximately 3,048 meters (10,000 feet) above sea level. Its predominantly temperate climate showcases distinct seasons, providing a delightful experience throughout the year. Embrace mild summers, crisp winters, and refreshing monsoons, as Tawang extends a warm welcome with its ever-changing yet consistently enchanting weather. Whether you are in search of vibrant autumn colors, the invigorating freshness of summer, or the cozy charm of winter, Tawang caters to a diverse range of preferences, making it an alluring destination for every season. <br /><br /></p>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody><tr><td>March - April</td><td>Summer</td><td>10° to 25°C</td></tr>
                        <tr><td>October - February</td><td>Winter</td><td>-5° to 10°C</td></tr>
                        <tr><td>May - September</td><td>Monsoon</td><td>5° to 20°C</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>HOW IS THE WEATHER IN NAMERI?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HOW IS THE WEATHER IN NAMERI?</h4>
                </div>
                <div className="card-body">
                  <p><b>Nameri</b>, a tranquil destination in the Indian state of Assam, boasts a delightful climate that transitions through distinct seasons. Nestled in the embrace of nature, at an elevation ranging from 80 to 200 meters (262 to 656 feet) above sea level, Nameri offers a climate conducive to a variety of activities. The town experiences a pleasant spectrum of temperatures, providing warmth in summer, mildness in winter, and the rejuvenating touch of rainfall during the monsoon months. Whether you are drawn to the mild summers, the comfortable winters, or the invigorating monsoons, Nameri beckons travelers with its ever-changing and enchanting weather, creating an idyllic retreat for nature enthusiasts and those seeking tranquility. <br /><br /></p>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody><tr><td>March - April</td><td>Summer</td><td>11° to 29°C</td></tr>
                        <tr><td>October - February</td><td>Winter</td><td>1° to 13°C</td></tr>
                        <tr><td>May - September</td><td>Monsoon</td><td>16° to 28°C</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>HOW IS THE WEATHER IN DIRANG?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HOW IS THE WEATHER IN DIRANG?</h4>
                </div>
                <div className="card-body">
                  <p><b>Dirang</b>, a captivating town in the Indian state of Arunachal Pradesh, is blessed with a diverse climate that unfolds across distinct seasons. Perched amidst the Himalayas, at an elevation ranging from 1,400 to 2,500 meters (4,593 to 8,202 feet) above sea level, Dirang enjoys a climate that accommodates various preferences and activities. The town experiences a delightful range of temperatures, offering warmth in summer, coolness in winter, and the invigorating touch of rainfall during the monsoon season. Whether you're captivated by the mild summers, the cozy winters, or the refreshing monsoons, Dirang extends a warm welcome to visitors with its ever-changing and enchanting climate, making it a year-round haven for nature enthusiasts and adventurers alike. <br /><br /></p>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody><tr><td>March - April</td><td>Summer</td><td>10° to 30°C</td></tr>
                        <tr><td>October - February</td><td>Winter</td><td>0° to 15°C</td></tr>
                        <tr><td>May - September</td><td>Monsoon</td><td>15° to 30°C</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>BUMLA PASS & BEST TIME TO VISIT</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BUMLA PASS & BEST TIME TO VISIT</h4>
                </div>
                <div className="card-body">
                  <p>Exploring Bumla Pass in Arunachal Pradesh is like finding a piece of heaven on earth. It's one of the most beautiful places you can visit during your <strong>trip to Arunachal Pradesh from Guwahati</strong>. This pass is special because Dalai Lama used it to come to India a long time ago. It's a historic place and a great adventure for tourists visiting Tawang. <br /><br />
                    Bumla Pass marks where India's border with China is today. It's very high up and quite cold at 15,200 feet. The Indian army looks after this area, and you can even see Chinese soldiers not too far away. When you visit Bumla Pass, the army will guide you and tell you about its history and why it's important. <br /><br />
                    You need a special permit to visit Bumla Pass and see the amazing wilderness there. It's snowy all year round and sits about 5000 meters above sea level. <strong>Best time to visit Bumla Pass</strong> is from May to October when it's not as snowy. From November to March, it's covered in thick snow, and the temperature drops to below -25 degrees. Despite the freezing weather, Indian soldiers stand guard at the border, protecting our country. <br /><br />
                    The view at Bumla Pass is stunning with snow-covered mountains all around. It's a tough job for the Indian army, but they do it to keep us safe. Seeing them in such harsh conditions is really impressive and something every Indian should witness. <br /><br />
                    When you reach Bumla Pass, from the Army canteen you can buy hot food like Maggi noodles, samosas, soup, and tea, which feels amazing in the cold. You have to show your permit, and then you'll be assigned a group and taken to the border by an army guide. They'll explain why the border is important, but you can't take pictures because it's a military area. <br /><br />
                    It's important to eat a light breakfast before going and carry warm water to drink because it's very cold up there. Kids and elderly people might feel dizzy, so it's a good idea to give them something sweet to suck on. Make sure to wear warm clothes like gloves, a jacket, and thick socks, especially in winter. <br /><br /><br /></p>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr><td>March - April</td><td>Summer</td><td>-7° to 10°C</td></tr>
                        <tr><td>October - February</td><td>Winter</td><td>-11° to 8°C</td></tr>
                        <tr><td>May - September</td><td>Monsoon</td><td>9° to 18°C</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> HOW TO REACH TAWANG - BUMLA PASS?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> HOW TO REACH TAWANG - BUMLA PASS?</h4>
                </div>
                <div className="card-body">
                  <p>Reaching Tawang during your <strong>Tawang Bumla-Pass package tour</strong> is a journey filled with scenic landscapes and diverse transportation options. Here's how you can reach Tawang by train, by air, and by road: <br /><br /></p>

                  <p><strong>Tawang Bumla-pass package tour from Guwahati by Train</strong> - The nearest railway station to Tawang is Tezpur Railway Station, located approximately 385 kilometers away. From Tezpur, you can hire a taxi or take a bus to reach Tawang. Although the journey involves a road trip, the train ride to Tezpur offers a comfortable and picturesque route through the heart of Assam <br /><br /></p>
                  <p><strong>Tawang Bumla-pass package tour from Guwahati by Flight</strong> - The closest airport to Tawang is Salonibari Airport in Tezpur, Assam. After reaching Tezpur by air, you can proceed to Tawang by road. Alternatively, you can fly to Lokpriya Gopinath Bordoloi International Airport in Guwahati, which is well-connected to major cities in India. From Guwahati, embark on a road trip to Tawang, enjoying the breathtaking scenery of the Eastern Himalayas. <br /><br /></p>
                  <p><strong>Tawang Bumla-pass package tour from Guwahati by Road</strong> - Tawang is well-connected by road, and road travel is a popular choice for reaching this picturesque destination. The journey offers panoramic views of the mountains and valleys. You can opt for private taxis, shared cabs, or buses from Tezpur or Guwahati. The road trip takes you through scenic landscapes, including the famous Sela Pass, enhancing the overall experience of your Tawang Bumla Pass Package Tour. <br /><br /></p>
                  <p>Tawang to Bumla Pass spans approximately 37 kilometers, offering a thrilling adventure through diverse terrains and challenging altitudes. Navigating through mountainous landscapes and winding roads, the route treats travelers to breathtaking views of snow-capped peaks, valleys, and alpine meadows. Beyond its scenic beauty, the journey holds historical significance, with Bumla Pass being a key location during the 1962 Sino-Indian War. The road trip is a memorable experience, combining natural wonders with a touch of the region's compelling history. <br /><br />
                    Whichever mode of transportation you choose, the journey to Tawang is an integral part of the adventure, providing a sneak peek into the natural beauty and cultural richness of the region. The <strong>Tawang Bumla-Pass package tour</strong> ensures that your travel arrangements align seamlessly, allowing you to focus on the breathtaking landscapes and the unique experiences that Tawang has to offer. <br /><br /></p>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT ARE THE PLACES OF ATTRACTIONS IN <strong>ARUNACHAL TOUR PACKAGE FROM GUWAHATI WITH TAWANG</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> WHAT ARE THE PLACES OF ATTRACTIONS IN <strong>ARUNACHAL TOUR PACKAGE FROM GUWAHATI WITH TAWANG</strong>?</h4>
                </div>
                <div className="card-body">
                  <p>The Best Places of Attractions in <strong>Tawang tour package with Bum-La Pass</strong> are : Sela Pass, Tawang Monastery, Shonga-Tser Lake, Nuranang Falls, Tawang War Memorial, Peng Teng Tso Lake, Urgelling Gompa, Jaswant Garh War Memorial, Bumla Pass, Samten Yongcha Monastery, Nagula Lake, Gorichen Peak, Taktsang Gompa, Gorsam Chorten. <br /><br /></p>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th><b>Places</b></th><th><b>Altitude</b></th><th><b>Location</b></th></tr></thead>
                      <tbody><tr><td>Bum La Pass</td><td>4,633 m</td><td>Location: Tawang district (Arunachal Pradesh, India)</td></tr>
                        <tr><td>Gorichen Peak</td><td>6,526 m</td><td>Gorichen is the highest scalable peak in Arunachal Pradesh</td></tr>
                        <tr><td>Geshila Peak</td><td>4,170 m</td><td>Location: Tawang, Arunachal Pradesh</td></tr>
                        <tr><td>Jaswant Garh War Memorial</td><td>3,048 m</td><td>Location: Tawang, Arunachal Pradesh</td></tr>
                        <tr><td>Nagula Lake</td><td>4,120 m</td><td>Location: Tawang, Arunachal Pradesh</td></tr>
                        <tr><td>Nuranang Falls (Jung falls)</td><td>1,828 m</td><td>Location: Tawang, Arunachal Pradesh, India</td></tr>
                        <tr><td>Penga Teng Tso Lake (P.T. Tso)</td><td>3,657 m</td><td>Location: Tawang, Arunachal Pradesh, India</td></tr>
                        <tr><td>Sela Pass</td><td>4,170 m</td><td>Location: Tawang, Arunachal Pradesh, India</td></tr>
                        <tr><td>Sangestar Tso (Madhuri Lake)</td><td>3,708 m</td><td>Location: Tawang district, Arunachal Pradesh, India</td></tr>
                        <tr><td>Tawang War Memorial</td><td>4,170 m</td><td>Address: Cona, Shannan, Arunachal Pradesh 790104</td></tr>
                        <tr><td>Taktsang Gompa</td><td>3,810 m</td><td>Location: Tawang, Arunachal Pradesh</td></tr>
                        <tr><td>Tawang Monastery</td><td>3,048 m</td><td>Location: Cona, Tawang, Arunachal Pradesh 790104</td></tr>
                        <tr><td>Urguelling Monastery</td><td>4,170 m</td><td>4km from Tawang, it is the birthplace of the sixth Dalai</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DISTANCES TO REMEMBER IN <strong>TAWANG BUMLA-PASS PACKAGE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> DISTANCES TO REMEMBER IN <strong>TAWANG BUMLA-PASS PACKAGE TOUR</strong></h4>
                </div>
                <div className="card-body">
                  <br /><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th><b>From</b></th><th><b>To</b></th><th><b>Distance/Time</b></th></tr></thead>
                      <tbody><tr><td>Guwahati</td><td>Tawang</td><td>12 hr 49 min (432.4 km) via NH13</td></tr>
                        <tr><td>Guwahati</td><td>Nameri</td><td>4 hr 32 min (222.1 km) via NH 27</td></tr>
                        <tr><td>Tawang</td><td>Dirang</td><td>4 hr 2 min (134.3 km) via NH13</td></tr>
                        <tr><td>Dirang</td><td>Sangti Valley</td><td>29 min (12.2 km) via NH13</td></tr>
                        <tr><td>Tawang</td><td>Bomdila</td><td>5 hr 14 min (176.6 km) via NH13</td></tr>
                        <tr><td>Dirang</td><td>Bomdila</td><td>56 min (37.4 km) via NH13</td></tr>
                        <tr><td>Bomdila</td><td>Tezpur</td><td>3 hr 45 min (150.6 km)</td></tr>
                        <tr><td>Tezpur</td><td>Guwahati</td><td>3 hr 34 min (183.2 km) via NH 27</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                </div>
              </div>
              <br /><br />
              {/* <!--By Itinerary--> */}
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3 className="content-desktop" style={{ fontSize: "2.0vw" }}>
                        DAY WISE <strong className="tomato">TAWANG BUMLA PASS TOUR ITINERARY</strong>
                      </h3>
                      <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>
                        DAY WISE <strong className="tomato">TAWANG BUMLA PASS TOUR ITINERARY</strong>
                      </h3>
                    </div>
                  </div>
                </div>

                <br />
                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpen"
                  >
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day1"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          DAY 1 : ARRIVAL AT GUWAHATI, TRANSFER TO NAMERI
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>Your <strong>Tawang tour package from Guwahati with Bumla-pass</strong> begins with a warm welcome as we pick you up at the Guwahati International Airport at the specified time of your arrival. From there, embark on an exciting journey to Nameri National Park, approximately 240 kilometres away, ensuring a picturesque drive of about 5 hours.
                            Nestled in the lap of pristine nature, Nameri is renowned for its diverse wildlife, including the majestic tiger, elusive leopard, and the mighty bear. The region is also hailed as a birding paradise, offering enthusiasts the chance to witness a variety of avian species in their natural habitat. Upon reaching Nameri, you'll discover the allure of the national park through wildlife trekking, providing an intimate encounter with the rich biodiversity. For those seeking an adrenaline rush, the option of river rafting on the Jia Bhoroli River awaits, promising an exhilarating experience surrounded by pristine landscapes.
                            As the day unfolds, immerse yourself in the natural wonders of Nameri, capturing the essence of its flora and fauna. The night will be spent in the tranquil surroundings of Nameri, offering a peaceful rest to rejuvenate for the adventures that lie ahead on your <strong>Tawang Bumla Pass package tour</strong>. <br /><br /></p>

                          <div className="content-desktop">
                            <center><img src={navDay1ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          DAY 2 : NAMERI TO DIRANG EXPLORATION
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>As your <strong>Guwahati to Tawang tour packages with Bumla-pass</strong> unfolds, Day 2 marks the journey from Nameri to the enchanting Dirang Valley, promising a scenic drive spanning approximately 160 kilometres, taking around 6 hours. <br /><br />
                            Dirang Valley, nestled in the foothills of the Himalayan ranges, unveils its captivating beauty as you venture deeper into its heart. Renowned for its diverse attractions, Dirang is a haven for nature lovers, birding enthusiasts, and those seeking cultural experiences. The valley boasts of monasteries that resonate with tranquillity, the historic Dirang Dzong, showcasing timeless architecture, and the remnants of an old electricity mill, echoing tales of the past. <br /><br />
                            The allure of Dirang extends to its Tibetan villages, providing a glimpse into the rich cultural tapestry of the region. An added highlight is the presence of a hot water spring just outside the valley, offering a therapeutic retreat amidst nature's bounty. As you explore Dirang's treasures, absorb the serene ambiance and immerse yourself in the cultural and natural wonders that define this Himalayan jewel. <br /><br />
                            The night will be spent in the embrace of Dirang, allowing you to savour the experiences of the day and prepare for the next leg of your <strong>Tawang Bumla-Pass tour</strong>.<br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay2ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 3 : DIRANG TO TAWANG EXPEDITION
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>On the third day of your <strong>package tour from Guwahati to Tawang with Bumla-pass</strong>, embark on a breath-taking journey from Dirang to the enchanting town of Tawang, covering approximately 145 kilometres over an awe-inspiring 7-hour drive. <br /><br />
                            The day begins with a visit to the invigorating hot water spring located just outside the picturesque Dirang Valley. Continuing the scenic drive, we'll make a pitstop at Vaisakhi, known for its Army canteen and a delightful Dosa point, offering a taste of local flavours. <br /><br />
                            As the journey unfolds, be prepared to be captivated by the natural wonders along the way. The route includes stops at some remarkable sites, such as the high-altitude Sela Pass (13700 feet), offering panoramic views of the surrounding mountains. Pause to admire the serene Paradise Lake, also known as Sela Lake, reflecting the beauty of the Himalayan landscape. A visit to the War Memorial of Jaswantgarh pays homage to the heroes of the past, adding a touch of history to your exploration. <br /><br />
                            The adventure continues with a stop at the Nuranang Waterfalls, a majestic cascade that adds to the scenic charm of the journey. Finally, as the day unfolds, reach the tranquil destination of Tawang, where you will halt for the night. The town's serene ambiance and cultural richness set the stage for further exploration on the upcoming days of your <strong>Tawang Bumla Pass tour package</strong>.<br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay3ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day4">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFour"
                        >
                          DAY 4 : TAWANG EXPLORATION
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>Your <strong>customized Tawang and Bumla Pass tour</strong> continues with a day dedicated to exploring the local treasures of Tawang, covering approximately 70 kilometres over a leisurely 6-hour period.
                            Commence the day with a visit to the iconic Tawang Monastery, the second-largest monastery in Asia, where the spiritual ambiance and intricate architecture create a serene atmosphere. Next, delve into the cultural heritage of the region with a visit to the Nun Monastery, offering insights into the Buddhist way of life. <br /><br />
                            Continue your journey to the Craft Centre and Emporium, where the intricate local craftsmanship and vibrant artefacts showcase the rich cultural tapestry of Tawang. The Apple Garden adds a touch of nature's bounty, providing a refreshing backdrop to your explorations. <br /><br />
                            As you wander through Tawang, don't miss the opportunity to visit the birthplace of the Sixth Dalai Lama, a site of historical and cultural significance. The day concludes with a visit to the Tawang War Memorial, paying homage to the brave souls who sacrificed for the nation. <br /><br />
                            For a unique perspective, consider enjoying the ropeways to the Nun Monastery, offering a bird's-eye view of the breath-taking landscapes surrounding Tawang. After a day filled with cultural immersion and exploration, return to your accommodation in Tawang for a well-deserved night's rest, anticipating the adventures that await in the coming days of your <strong>Tawang tour with Bumla Pass</strong>. <br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay4ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day5">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFive"
                        >
                          DAY 5 : TAWANG TO BUMLA-PASS EXCURSION
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>On the fifth day of your <strong>Tawang Bumla Pass package tour from Guwahati</strong>, embark on an expansive exploration of the stunning locales surrounding Tawang, covering approximately 150 kilometres with a captivating 7-hour journey. <br /><br />
                            Tawang's beauty extends beyond its town limits, and this day is dedicated to unravelling the hidden gems scattered in different directions. Venture towards the Heart Lake, a tranquil water body that mirrors the serene ambiance of its surroundings. The journey continues to the enchanting PTSO Lake, offering a picturesque backdrop framed by the Himalayan peaks. <br /><br />
                            Nagula Lake awaits with its pristine charm, providing a serene escape amidst nature. The scenic Y Junction adds to the itinerary, providing panoramic views of the landscapes that define the Eastern Himalayas. Sangester Lake, with its crystal-clear waters, offers another glimpse into the natural wonders of the region. <br /><br />
                            The pinnacle of the day's adventure is the visit to Bum-La-Pass, situated at the border of India and China. This strategic pass, surrounded by awe-inspiring mountain landscapes, offers a profound experience. Please note that obtaining the permit for Bum La Pass requires a minimum three-day stay in Tawang. The pass cannot be secured on the same day as the visit. Immerse yourself in the breath-taking vistas at this border pass, and let the significance of the location resonate. <br /><br />
                            After a day filled with exploration and encounters with nature's wonders, return to the comfort of your accommodation in Tawang, cherishing the memories created during your <strong>7N 8D Tawang with Bumla Pass tour packages starting from Guwahati</strong>.<br /><br /></p>
                          {/* <div className="content-desktop">
                            <center><img src={navDay5ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day6">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSix"
                        >
                          DAY 6 : TAWANG TO BOMDILA EXPLORATION [185KM/8HRS]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>Embark on the sixth day of your <strong>Arunachal tour</strong>, retracing your journey from Tawang to the charming town of Bomdila, covering approximately 185 kilometres in an enriching 8-hour drive.<br /><br />
                            Bomdila, nestled amid the Eastern Himalayas, welcomes you with its serene ambiance and cultural treasures. The town boasts a beautiful Bomdila Monastery, situated on a hilltop, offering breath-taking vistas of the surrounding landscapes. Immerse yourself in the spiritual tranquillity of the monastery as you explore its intricate architecture and absorb the serenity it provides. <br /><br />
                            Bomdila is also a gateway to the Eagle nest Wildlife Sanctuary, known for its diverse flora and fauna. Nature enthusiasts can explore this sanctuary, which is a haven for birdwatchers and wildlife photographers. The apple orchards and orchid farms add to the natural allure of Bomdila, offering a delightful escape into the region's biodiversity. <br /><br />
                            Don't miss the opportunity to stroll through Bomdila's vibrant market, a hub for tourists seeking souvenirs and local delights. Engage with the lively atmosphere, indulge in shopping, and savour the local food, immersing yourself in the cultural vibrancy of Arunachal Pradesh. <br /><br />
                            As the day winds down, find respite in the comfort of Bomdila, where you'll stay for the night. The town's unique blend of natural beauty and cultural richness ensures a memorable stop on your <strong>Arunachal Pradesh tour with Tawang and Bumla Pass</strong>.<br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay6ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day7">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSeven"
                        >
                          DAY 7 : BOMDILA TO TEZPUR EXPLORATION, TEZPUR SIGHTSEEING [150KM/5HRS]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>On the seventh day of your <strong>Arunachal tour package with Tawang, Bumla Pass, Dirang & Bomdila</strong>, set out from Bomdila towards the heritage city of Tezpur, covering approximately 150 kilometers in a comfortable 5-hour journey. <br /><br />
                            Tezpur, nestled on the north bank of the Brahmaputra River, unfolds as a cultural gem and the cleanest city in Assam. Begin your exploration of this historical city in the morning, delving into its rich heritage and mythological tales. <br /><br />
                            Agnigarh, a hillock with mythological significance, sets the stage for your Tezpur exploration. Visit various temples dedicated to Lord Shiva, each showcasing unique architecture and spiritual tranquility. Don't miss the opportunity to witness the mesmerizing sunset over the Brahmaputra River, offering a serene and picturesque experience. <br /><br />
                            The Kolia-Bhumura Bridge, an architectural marvel spanning the Brahmaputra, beckons you for a visit, providing panoramic views of the river and its surroundings. Tezpur's beautiful parks add a touch of greenery and tranquility to your city tour. <br /><br />
                            As the day unfolds, immerse yourself in Tezpur's cultural heritage, exploring its attractions and learning about the city's historical significance. The night will be spent in Tezpur city, allowing you to unwind and reflect on the experiences of the day during your <strong>Arunachal tour packages with Tawang Dirang & Bum-La-Pass</strong>.<br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay7ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day8">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseEight"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseEight"
                        >
                          DAY 8 : TEZPUR TO GUWAHATI, AIRPORT DROP [210KM/4HRS]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingEight"
                      >
                        <div className="accordion-body">
                          <p>On the final day of your <strong>Tawang Bum-La-Pass trip</strong>, bid adieu to Tezpur as you journey towards Guwahati, covering approximately 210 kilometers in a smooth 4-hour drive. <br /><br />
                            In the morning, set out from Tezpur, relishing the scenic landscapes of Assam during your return to Guwahati. Depending on the available time, consider exploring a sightseeing destination or two within the city of Guwahati, adding a final touch to your enriching journey. <br /><br />
                            As the required time for your airport drop approaches, our team will ensure a timely and comfortable transfer to the Guwahati airport. Reflect on the memories, cultural experiences, and breathtaking landscapes encountered throughout your <strong>Tawang tour with Dirang, Bomdila and Bumla Pass</strong>. <br /><br />
                            Your adventure through the mesmerizing landscapes of Arunachal Pradesh concludes, leaving you with a treasure trove of memories and a deep appreciation for the diverse beauty that Northeast India has to offer. Safe travels, and we hope your trip has been a journey to remember.<br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay8ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              {/* <!--insert table inclusion exclusion--> */}
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    {
                      <InclusionExclusion />
                    }
                  </div>
                </div>
              </div>
              {/* <!--end table inclusion exclusion--> */}
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHAT ARE THE TOP MOST ATTRACTIONS IN <strong>TAWANG PACKAGE TOUR BOOKING WITH BUM-LA-PASS</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> WHAT ARE THE TOP MOST ATTRACTIONS IN <strong>TAWANG PACKAGE TOUR BOOKING WITH BUM-LA-PASS</strong>?</h4>
                </div>
                <div className="card-body">
                  <li>Tawang, the most popular tourist spot known to grab the eye ball of every visitor visiting the peaceful state of North East has some of the top attraction points such as : <br /><br /></li>
                  <li><b>Tawang Monastery</b> - Perched at an altitude of 10,000 ft, Tawang Monastery is where culture echoes, beauty enthralls, and tradition speaks in the lap of the bumpy ridges. While being the religious site for the Buddhists, this 400 years old monastery is the largest in India and the second largest in the world, which houses over 500 monks, with an increase in number every day. This monastery is the storehouse of priceless Buddhist manuscripts, texts, and scriptures that holds an 8 m statue of Lord Buddha, which commands the sanctum and is decorated, with rotating prayer wheels, paintings, and colorful thankas. <br /><br /></li>
                  <li><b>Tawang War Memorial</b> - Visiting this war memorial is a pride for every Indian citizen, where a beautifully crafted monument is dedicated to the brave souls who fought during the 1962 Indo –China war. As the dusk sets in, one can enjoy the light and sound show organized by the Indian Army, which portrays the scenario during the war times. <br /><br /></li>
                  <li><b>Bumla Pass</b> - An important Border Meeting point of the Indian Army with the Chinese Army is this pass. The stunning white Bumla Pass remains covered under the snow throughout the year, and visiting this pass requires a special permit (to be arranged by our driver) so that one can roam and breathe the fresh air with proper permission. <br /><br /></li>
                  <li><b>Sangetsar Lake</b> - This Lake should top on the sightseeing list for diehard fans of Bollywood diva Madhuri Dixit as this lake is named after her when she matched her steps with a hit number in front of that lake. Madhuri lake or Sangetsar Lake is located only 35 Km from Tawang city with mountains and forest in its surroundings, along with fluttering prayer flags to add more charm to this place. <br /><br /><br /><br /></li>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>IMPORTANT FACTS TO KNOW BEFORE <strong>TAWANG BUMLA PASS TOUR BOOKING</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>IMPORTANT FACTS TO KNOW BEFORE <strong>TAWANG BUMLA PASS TOUR BOOKING</strong></h4>
                </div>
                <div className="card-body">

                  <li>Permit Requirements: Obtain necessary permits for Bum La Pass visit and entry into Arunachal Pradesh. <br /></li>
                  <li>Weather Considerations: Be aware of varying weather conditions, especially during winter, and plan accordingly. <br /></li>
                  <li>Altitude Challenges: Bum La Pass is at a high altitude; acclimatize properly to avoid altitude-related issues. <br /></li>
                  <li>Restricted Access: Some areas may require special permits due to proximity to international borders. <br /></li>
                  <li>Sightseeing Restrictions: Certain sights, like Bum La Pass, may have restrictions and can't be obtained on the same day. <br /></li>
                  <li>Travel Time: Distances between attractions may be long; plan for sufficient travel time. <br /></li>
                  <li>Local Customs: Respect local customs and traditions, especially at monasteries and cultural sites. <br /></li>
                  <li>Pack Essentials: Carry essentials like warm clothing, comfortable shoes, and necessary documents. <br /></li>
                  <li>Health Precautions: Check health advisories, carry basic medications, and consult a healthcare professional if needed. <br /></li>
                  <li>Tour Operator Coordination: Coordinate with the tour operator for a seamless experience and adherence to guidelines. <br /><br /></li>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>LAKES TO VISIT DURING <strong>TAWANG TOUR PACKAGE FROM GUWAHATI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>LAKES TO VISIT DURING <strong>TAWANG TOUR PACKAGE FROM GUWAHATI</strong></h4>
                </div>
                <div className="card-body">
                  <li><b>Sungester Lake / Popularly known as the Madhuri Lake</b> <br />
                    Location: Tawang district, Arunachal Pradesh, India <br /><br /></li>
                  <li><b>Ganga Lake / Geker Sinying</b> <br />
                    Location: Itanagar, Arunachal Pradesh<br /><br /></li>
                  <li><b>Mehao Lake</b><br />
                    Location: Mehao Wildlife Sanctuary, Lower Dibang Valley District, Arunachal Pradesh <br /><br /></li>
                  <li><b>Glow Lake</b><br />
                    Location: Kamlang Wildlife Sanctuary, Lohit District, Arunachal Pradesh <br /><br /></li>
                  <li><b>Nawang Yang Lake / Lake of No Return</b><br />
                    Location: On the Border of Arunachal Pradesh and Myanmar<br /><br /></li>
                  <li><b>Panga Teng Tso lake</b><br />
                    Location: Panga Teng Tso lake, Arunachal Pradesh, India, Tawang <br /><br /><br /><br /></li>
                </div>
              </div>
              <br /><br />

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>Frequently Ask <span>Questions</span></h2>
                      <h4>FOR <strong>TAWANG TRIP WITH BUMLA PASS</strong></h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion" id="accordionPanelsStayOpenExample">
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingThree">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            Q. Do we need a permit to travel to Tawang?
                          </button>
                        </h4>
                        <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Certainly, obtaining an Inner Line Permit (ILP) is mandatory for tourists intending to visit Tawang. To secure the permit, tourists have the option to apply online through the Arunachal Pradesh Tourism website, incurring a fee ranging from Rs. 150 to Rs. 200. The processing time for the permit is approximately 5 to 8 working days. Alternatively, travelers can acquire the permit directly from the Arunachal Pradesh tourism office at Guwahati Airport.<br /><br /></p></div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingFour">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                            Q. Where is Tawang located?
                          </button>
                        </h4>
                        <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Nestled in the northeastern state of Arunachal Pradesh, Tawang is situated near the India-China border, boasting an elevation of 10,000 ft. Positioned 448 km northwest of the state capital, Itanagar, and 444 km from Guwahati, the 'City of Eastern Light,' situated along the southern banks of the mighty Brahmaputra River, Tawang serves as a gateway to the entire northeastern states. <br /><br /></p></div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingFive">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                            Q.How can we travel to Tawang from Guwahati on our North East Tour?
                          </button>
                        </h4>
                        <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Tawang has located 445 Km from Guwahati so it is not possible to cover the distance by road on a single day and requires a 1-night stay at Bhalukpong and Dirang before finally reaching Tawang? <br /><br /></p></div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingSix">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                            Q. Which is the Nearest Airport of Tawang?
                          </button>
                        </h4>
                        <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Salonibari Airport, Tezpur is the nearest Airport of Tawang.<br /><br /></p></div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingSeven">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                            Q. Which is the nearest Railway Station of Tawang?
                          </button>
                        </h4>
                        <div id="flush-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Tezpur Railway Station is the nearest Railway Station of Tawang.<br /><br /><br /></p></div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingEight">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                            Q. Is Tawang safe for travelers?
                          </button>
                        </h4>
                        <div id="flush-collapseEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Certainly, Tawang is a secure destination for travel, barring occasional off-season snowfall or rains. Whether embarking on a solo backpacking adventure or a family vacation, Tawang offers a safe and serene retreat. <br /><br />
                            Arunachal Pradesh, encompassing Tawang, is recognized as one of the safest states in northeast India. The locals are exceptionally generous and helpful, contributing to the overall sense of security. Traveling with family or venturing solo is made safer by the presence of numerous army camps in the area. <br /><br />
                            Safety concerns are virtually non-existent in Arunachal Pradesh, making it one of the securest places in India. The region boasts an exceptionally low crime rate, further reinforced by the significant presence of army camps. While off-season snowfall or rains may pose occasional challenges, the locals readily extend their assistance. <br /><br />
                            For both family vacations and solo backpacking excursions to Tawang, safety is assured, with particular note to the positive experiences shared by female travelers who regard the region as one of the safest for women. The humility of the locals is noteworthy, and their willingness to assist tourists is a testament to the warm hospitality. Politeness, along with respect for local culture and traditions, ensures a seamless and memorable journey through Tawang.<br /></p></div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingNine">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                          Q. Which Lake in India is known as the Bermuda Triangle of India?
                          </button>
                        </h4>
                        <div id="flush-collapseNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Nawang Yang Lake located on the border of Arunachal Pradesh and Myanmar is known as the Bermuda Triangle of India. The lake is also known as the "Lake of No Return". Originally known as "Nawang Yang Lake" by the local
                          Tangsa tribe, the lake is often regarded as haunted and local people generally avoid it. Some mysterious activity and loss of human lives in the lake has claimed this fame to the lake. Often visited by the curious traveler, this 1,4 km span lake offer some spectacular view of itself and its surroundings. <br /></p></div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingTen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                          Is there electricity available in Tawang?
                          </button>
                        </h4>
                        <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Being nestled at an altitude of 10,000 ft. it is not surprising that Tawang might not have electricity 24 hours, but hotels have power back up which gives fair support for few hours. <br /></p></div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingEleven">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                          Q. Is there a geyser available in hotels and home stays in Tawang?
                          </button>
                        </h4>
                        <div id="flush-collapseEleven" className="accordion-collapse collapse show" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Yes, in maximum hotels and homestays, geyser is available, and in hotels with no geyser facilities, hot water will be provided in buckets on request.<br /></p></div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingTwelve">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                          Q. What kind of food to expect during our Tawang tour?
                          </button>
                        </h4>
                        <div id="flush-collapseTwelve" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Just like the whole of India, Rice is the staple food throughout Arunachal Pradesh along with other vegetables and meat served as side dishes. The people of Tawang love to use more dairy products and eat boiled or smoked vegetables and fish with extra chilies, but less fried stuff. <br /></p></div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h4 className="accordion-header" id="flush-headingThirteen">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                          Q. What is the best time to visit Tawang?
                          </button>
                        </h4>
                        <div id="flush-collapseThirteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body"><p>Without a doubt, summer and the pre monsoon season are the best time to visit Tawang. The weather is pleasant and suitable for sightseeing. March, April, May, June, September, and October are the best months to visit Tawang.<br /></p></div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3><b> Arunachal Tour Kolkata<span class="tomato">Holiday Reviews</span></b></h3>
                      <h4><i>"Your Experience is our Marketing"</i></h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1">
                  <div id="testimonials">
                    <div className="testimonial-box-container">

                      {reviewersDataView}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div >
        </div >
        <div className="btn-whatsapp-pulse">
          <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>
        <div class="btn-call-pulse">
          <a style={{ color: 'white' }} href="tel:+91-9674738480">
            <i className="fa fa-phone"></i>
          </a>
        </div>
      </>
    );
  }
}
export default TawangBumlaPass_7N_8D_Package;
