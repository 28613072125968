import React, { Component, useState } from 'react';
import BookingBox from '../BookingBox';
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Bhutan/InclusionExclusion_bhutanPackageTourNjpSiliguri';
import Activityfecilities from '../Bhutan/Activity_bhutanPackageTourNjpSiliguri';
class Bhutan_PackageTourNjpSiliguri extends Component {
    constructor(props) {
        super(props);

        this.state = {
            btn: "Submit",
            showLoader: false,
            isHidden: true,
            showModal: false,
            showMegamenu: false, // New state to handle megamenu
            firstName: '',
            mobile: '',
            email: '',
            comments: '',
            megamenuTimeout: null // New state for managing hover delay
        }
        this.timeoutId = null; // To store the timeout ID
        this.modalRef = React.createRef(); // Create a ref for the modal
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919883359713&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ showModal: true });
        }, 40000);
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        // Cleanup the event listener
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        // Check if the click is outside the modal
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.onCloseModal();
        }
    };

    toggleHidden = (e) => {
        e.preventDefault();
        this.setState({ isHidden: !this.state.isHidden });
    }

    handleMegamenuEnter = () => {
        // Clear any existing timeout
        if (this.state.timeoutId) {
            clearTimeout(this.state.timeoutId);
        }
        this.setState({ isHidden: false });
    };

    handleMegamenuLeave = () => {
        // Set a timeout to delay hiding the megamenu
        const timeoutId = setTimeout(() => {
            this.setState({ isHidden: true });
        }, 300); // Match this delay with the CSS transition time

        this.setState({ timeoutId });
    };


    onRequestCallback = (e) => {
        e.preventDefault();
        if (!this.state.showModal) {
            this.setState({ showModal: true });
        }
    }

    onCloseModal = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            showModal: false,
            firstName: '',
            mobile: '',
            email: '',
            comments: ''
        });
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    // Function to handle form submission
    onRequestCallbackSubmit = async (e) => {
        e.preventDefault();

        const { firstName, email, mobile, comments } = this.state;

        // Validation
        if (firstName.trim() === '') {
            cogoToast.error("Full Name Required.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
            // } else if (email.trim() === '') {
            //     cogoToast.error("Email Required.", {
            //         position: 'top-center', // Use top-center, then adjust via CSS
            //         hideAfter: 2, // optional: auto-hide after 2 seconds
            //         className: 'custom-toast' // Apply custom class
            //     });
        } else if (mobile.trim() === '' || !/^\d{10}$/.test(mobile.trim())) {
            cogoToast.error("Please Insert 10 digit Mobile No.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else {
            this.setState({ btn: "Processing..", showLoader: true });
            // Proceed with form submission
            try {
                const _ref = 'yes';
                const _url = window.location.href;

                const formData = {
                    FirstName: firstName,
                    Email: email,
                    Mobile: mobile,
                    Comments: comments,
                    Referrer: _ref,
                    URL: _url
                };

                const response = await axios.post(ApiURL.SaveCustomerEnquery, formData);
                console.log('Data submitted successfully:', response.data);
                // Optionally, reset the form state after successful submission
                this.setState({ firstName: '', email: '', mobile: '', comments: '' });

                // Optionally, show a success message to the user
                cogoToast.success("Form submitted successfully.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
                this.setState({ btn: "Submit", showLoader: false, showModal: false });
            } catch (error) {
                console.error('Error submitting data:', error);
                this.setState({ btn: "Submit", showLoader: false });
                // Optionally, show an error message to the user
                cogoToast.error("Please try again after 24 hrs. later.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
            }

        }

    };

    /* RedirectToOTPPage = () => {
        if (this.state.redirectOTPStatus === true) {
            let url = "/verifyotp/" + this.state.mobile;
            return <Redirect to={url} />
        }
    } */

    onCloseModal = () => {
        this.setState({ showModal: false }, () => {
            // Additional code to perform after the modal is closed
            setTimeout(() => {
                this.setState({ showModal: true }, () => {
                    // Additional code to perform after the next modal is displayed
                });
            }, 55000); // 55sec in milliseconds
        });
    };

    render() {
        const packageTopBannerImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-guwahati-mobile-long-banner.webp";
        const packageTopBanner2ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-guwahati-mobile-banner.webp";
        const FlightBannerImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/Bhutan-Group-Tour-Package-from-Mumbai.webp";
        const navDay1ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/guwahati-to-bhutan-tour-packages-with-direct-flights-with-adorable-vacation.webp";
        const navDay2ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/guwahati-to-bhutan-tour-packages-booking-with-direct-flights-with-adorable-vacation.webp";
        const navDay3ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/guwahati-to-bhutan-tour-package-cost-with-direct-flights-with-adorable-vacation.webp";
        const navDay4ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/guwahati-to-bhutan-tour-package-itinerary-with-direct-flights-with-adorable-vacation.webp";
        const navDay5ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/guwahati-to-bhutan-tour-package-plan-with-direct-flights-with-adorable-vacation.webp";
        const navDay6ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/guwahati-to-bhutan-tour-package-price-with-direct-flights-with-adorable-vacation.webp";
        const navDay7ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-3-nights-4-days-package.webp";
        const navDay8ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-3-days.webp";
        const CallButtonSoumalyaImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/call-button-bablu-adorable-vacation.webp";
        const CallButtonSonaliImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/call-button-sonali-adorable-vacation.webp";
        const PriceImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-from-guwahati-call-price-button.webp";
        const sightseen1ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-package-from-guwahati-8.webp";
        const sightseen2ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-package-tour-from-guwahati-airport-with-adorable-vacation-llp-9.webp";
        const sightseen3ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-package-tour-from-guwahati-with-adorable-vacation-8.webp";
        const sightseen4ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-package-tours-from-guwahati-airport-with-adorable-vacation-10.webp";
        const sightseen5ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-booking-from-guwahati-7.webp";
        const sightseen6ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-trip-tour-from-guwahati-with-adorable-vacation-9.webp";
        const sightseen7ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/guwahati-to-bhutan-tour-by-direct-flight-11.webp";
        const sightseen8ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/travel-bhutan-from-guwahati-with-adorable-vacation.webp";
        const sightseen9ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-trip-from-guwahati-airport-with-adorable-vacation-4.webp";
        const sightseen10ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-itinerary-from-guwahati-airport-with-adorable-vacation-5.webp";
        const sightseen11ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-from-guwahati-airport-with-adorable-vacation-2.webp";
        const sightseen12ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-cost-from-guwahati-airport-with-adorable-vacation-6.webp";
        const sightseen13ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-package-tour-from-guwahati-airport-with-adorable-vacation-1.webp";
        const sightseen14ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-package-from-guwahati-airport-with-adorable-vacation-3.webp";
        const sightseen15ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-booking-from-guwahati-during-summer-with-adorable-vacation.webp";
        const sightseen16ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-from-guwahati-during-summer-with-adorable-vacation.webp";
        const sightseen17ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-package-tour-from-guwahati-with-direct-flight-in-winter.webp";
        const sightseen18ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-booking-from-guwahati-during-spring-with-adorable-vacation.webp";
        const sightseen19ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-from-guwahati-during-spring-with-adorable-vacation.webp";
        const sightseen20ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-booking-from-guwahati-with-direct-flights.webp";
        const sightseen21ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-from-guwahati-with-direct-flight-tickets.webp";
        const sightseen22ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-trip-from-guwahati-with-direct-flights.webp";
        const flight1ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-package-from-guwahati-with-direct-flight.webp";
        const flight2ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-from-guwahati-with-direct-flight.webp";
        const flight3ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-trip-from-guwahati-with-direct-flight.webp";
        const hotel1ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-hotel-ugyen-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const hotel2ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-hotel-vara-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const hotel3ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-hotel-mandala-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const rows = [
            { date: '24th Sept - 30th Sept', availability: 'Available', price: '84,500' },
            { date: '01st Oct - 07th Oct', availability: 'Available', price: '84,500' },
            { date: '04th Oct - 10th Oct', availability: 'Available', price: '84,500' },
            { date: '08th Oct - 14th Oct', availability: 'Available', price: '84,500' },
            { date: '11th Oct - 17th Oct', availability: 'Available', price: '84,500' },
            { date: '15th Oct - 21st Oct', availability: 'Available', price: '84,500' },
            { date: '18th Oct - 24th Oct', availability: 'Available', price: '84,500' },
            { date: '22nd Oct - 28th Oct', availability: 'Available', price: '84,500' },
            { date: '25th Oct - 31st Oct', availability: 'Available', price: '84,500' },
            { date: '29th Oct - 04th Nov', availability: 'Available', price: '84,500' },
            { date: '1st Nov - 07th Nov', availability: 'Available', price: '84,500' },
            { date: '05th Nov - 11th Nov', availability: 'Available', price: '84,500' },
        ];

        const rowsPerPage = 6;
        const totalPages = Math.ceil(rows.length / rowsPerPage);
        const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
        const { customActiveTab, daywiseActiveTab } = this.state;

        const hotPackagesDataList = this.props.HotPackagesData;
        const hotPackagesView = hotPackagesDataList.length > 0 ?
            (
                hotPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.FIFO_6N_7D_ImageURL + "/" + dataItem.ImageName;
                    const contactURL = dataItem.Url;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} | {dataItem.EXPARO}<span> &#8377; {dataItem.PricePerNight}</span> </h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue">{dataItem.SDFInclude}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href={contactURL} className="btn btn-Warning btn-sm float-end">Call Now</a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const themePackageSliderList = this.props.ThemePackagesData;
        const themePackagesDataView = themePackageSliderList.map(
            (sliderItem, i) => {
                const sliderImageURL =
                    ApiURL.BhutanTourPackageFromHasimaraImageURL +
                    "/" +
                    sliderItem.ImageName;
                return (
                    <div className="theme-cards-wrapper mx-2" key={i}>
                        <div className="theme-card">
                            <div className="image-wrapper">
                                <img src={sliderImageURL} alt={sliderItem.AllternateText} />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">{sliderItem.Title}</h5>
                                <p className="card-text">{sliderItem.Description}</p>
                                <a href="#" className="btn btn-success btn-sm" onClick={this.handleClick1}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                );
            }
        );

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })


        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="Bhutan Packages From Guwahati tour from adorable vacation the best Bhutan Guwahati dmc" />

                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container">
                                        <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center> Book <strong>Bhutan Package Tour from NJP</strong> - BEST DEAL 2025 </center></h1>
                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center> Book <strong>Bhutan Package Tour from NJP</strong> - BEST DEAL 2025</center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="Bhutan Package Tour From Guwahati from adorable vacation the best Bhutan Guwahati dmc in india" />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Cities:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Thimphu
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Paro
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Punakha
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Bhutan tour</strong>, you have come to the Right Place! Adorable vacation is the best DMC for <strong>Bhutan Package Tour From Njp Siliguri</strong>
                                        </p>
                                        <hr />
                                        <br />
                                        <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}> Looking for <strong>Bhutan Tour Package from NJP Station</strong>? <br /><br /> <a href="tel:+91-9883359713">FOR BOOKING ☎ CALL +91-98833-59713</a>  <br /><br /></h2>
                                        <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> Looking for <strong>Bhutan Tour Package from NJP Station</strong>? <br /><br /> <a href="tel:+91-9883359713">FOR BOOKING ☎ CALL +91-98833-59713</a> <br /><br /></h3>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="card">
                                <div className="card-body">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr className="yellow">
                                                <th rowSpan="2">DAY WISE SUMMERY FOR <strong>BHUTAN PACKAGE TOUR FROM NJP STATION</strong></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingOne"
                                                >
                                                    <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                        <span>
                                                            ARRIVAL AT NJP & THIMPHU TRANSFER
                                                        </span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingTwo"
                                                >
                                                    <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                        <span>THIMPHU LOCAL SIGHTSEEING</span>
                                                    </a>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingThree"
                                                >
                                                    <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                        <span>THIMPHU TO PUNAKHA TRANSFER & SIGHTSEEINGS</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingFour"
                                                >
                                                    <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                        <span>PUNAKHA TO PHOBJIKHA VALLEY DAY TRIP</span>
                                                    </a>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingFive"
                                                >
                                                    <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                        <span>PUNAKHA TO PARO TRANSFER & SIGHTSEEINGS</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingSix"
                                                >
                                                    <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                        <span>PARO TO TAKTSANG MONASTERY HIKING / CHELE-LA-PASS DAY TRIP</span>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingSix"
                                                >
                                                    <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                        <span>PARO TO NJP DROP</span>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div className="card-body">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr className="yellow">
                                                <th rowSpan="2">INCLUSIONS: <strong>NJP TO BHUTAN PACKAGE TOUR</strong></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingOne"
                                                >
                                                    <a href="#" style={{ color: 'black' }}>
                                                        Accommodation in TCB listed Hotels (Double/Twin Sharing Room)
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                    <a href="#" style={{ color: 'black' }}>
                                                        Meal Plan MAP (Breakfast and Dinner)
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                    <a href="#" style={{ color: 'black' }}>
                                                        Transfers & all sightseeing in an exclusive Non-AC Vehicle (Pvt. Basis)
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                    <a href="#" style={{ color: 'black' }}>
                                                        Parking, Toll Tax, E-Permits
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                    <a href="#" style={{ color: 'black' }}>
                                                        Thimphu Local Sightseeing
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                    <a href="#" style={{ color: 'black' }}>
                                                        Paro City Tour
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                    <a href="#" style={{ color: 'black' }}>
                                                        English / Hindi Speaking Tour Guide
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                    <a href="#" style={{ color: 'black' }}>
                                                        All transfer will be on point-to-point basis
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                    <a href="#" style={{ color: 'black' }}>
                                                        SDF Charges (Sustainable Development Fee)
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                    <a href="#" style={{ color: 'black' }}>
                                                        01 Bottle Packaged Drinking Water Per Day on Vehicle
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                    <a href="#" style={{ color: 'black' }}>
                                                        Guest Service Support during the trip from Bhutan & India Office 24/7
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {/* <br />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> */}
                                </div>
                            </div>
                            <br /> <br />
                            {/* <!--end table inclusion exclusion--> */}
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="image-cell">
                                            <a href='#' onClick={this.onRequestCallback}><img src={PriceImageURL} alt="bhutan-tour-from-guwahati-call-price-button.webp" className="image-padding" /></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DURATION FOR <strong>BHUTAN TOUR PACKAGES FROM NJP STATION</strong> : 6N | 7D</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> DURATION FOR <strong>BHUTAN TOUR PACKAGES FROM NJP STATION</strong> : 5N | 6D</h3>
                                </div>
                                <div className="card-body">
                                    <h4>DESTINATIONS COVERED IN 6 NIGHTS 7 DAYS <strong>BHUTAN PACKAGE TOUR FROM NJP STATION </strong> : THIMPHU 2N | PUNAKHA 2N | PARO 2N <br /><br /></h4>

                                    <h4>PICKUP & DROP POINT : NEW JALPAIGURI STATION  </h4><br />
                                    <p>Apart from <strong>Bhutan Package Tour From Hasimara</strong>, Adorable Vacation offers various <strong>Bhutan tour packages</strong> like <a href="/bhutan-tour-package-from-pune" target="_blank"><b className="tomato">Bhutan Package Tour from Pune</b></a>,<a href="/bhutan-tour-from-delhi" target="_blank"><b className="tomato"> Bhutan Package Tour from Delhi</b></a>, <a href="/bhutan-tour-package-from-mumbai" target="_blank"><b className="tomato">Bhutan package tour from Mumbai</b></a>, <a href="/bhutan-tour-package-from-ahemdabad" target="_blank"><b className="tomato">Bhutan Package Tour from Ahmedabad</b></a>, <a href="/bhutan-tour-from-bengaluru" target="_blank"><b className="tomato">Bhutan Package Tour from Bangalore</b></a>, <a href="/bhutan-tour-package-from-jaigaon" target="_blank"><b className="tomato"> Bhutan Package Tour from Jaigaon</b></a>, <a href="/bhutan-tour-package-from-kolkata" target="_blank"><b className="tomato">Bhutan Package Tour from Kolkata</b></a>, <a href="/bhutan-tour-package-from-bagdogra" target="_blank"><b className="tomato">Bhutan Package Tour from Bagdogra Airport</b></a>, <a href="/bhutan-tour-package-from-surat" target="_blank"><b className="tomato">Bhutan Package Tour from Surat</b></a>, <a href="/mumbai-special-chartered-flight" target="_blank"><b className="tomato">Bhutan Package Tour from Mumbai with Chartered Flight</b></a>,<a href="/bhutan-b2b-package-tour-operator-in-india-best-bhutan-dmc" target="_blank"><b className="tomato">Bhutan B2B Package Tour</b></a>, etc at Best Price.</p>
                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen1ImageURL}
                                                        alt="adorable vacation - bhutan tour operator for b2b"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen2ImageURL}
                                                        alt="b2b travel agen for bhutan tour from india"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen3ImageURL}
                                                        alt="b2b travel agents for bhutan tour package booking"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {/* <br /><br /><br />
                                    <div class="d-grid gap-2 d-md-block">
                                        <a href="tel://9883359713" className="btn btn-Warning btn-lg">
                                            Talk To Our Bhutan Specialist Now
                                        </a>
                                    </div> */}
                                    <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DISCOVER BHUTAN FROM NJP STATION <strong> - A PERFECT GATEWAY FOR YOUR BHUTAN ADVENTURE</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DISCOVER BHUTAN FROM NJP STATION <strong> - A PERFECT GATEWAY FOR YOUR BHUTAN ADVENTURE</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p>Bhutan, the "<b>Land of the Thunder Dragon</b>" is a destination like no other, known for its majestic landscapes, vibrant culture, and commitment to preserving its traditional way of life. The country offers a unique travel experience with its beautiful monasteries, lush valleys, and charming villages. Whether you are drawn to the spiritual serenity of its Buddhist heritage, the allure of its pristine nature, or its stunning architecture, Bhutan never fails to leave a lasting impression. A <strong>Bhutan package tour from NJP</strong> is a perfect blend of natural beauty, adventure, and cultural immersion, making it a must-visit for travellers seeking an unforgettable experience in the heart of the Himalayas. <br /><br />

                                        For those planning a <strong>Bhutan tour</strong>, NJP (New Jalpaiguri Station) is an excellent starting point. Located in West Bengal, NJP serves as a major transportation hub and the closest railway station for tourists traveling to Bhutan. Situated approximately 150 kilometres from Phuentsholing, NJP is well-connected by train to major Indian cities and is a key transit point for travellers heading to Bhutan. The distance from NJP to Bhutan's border town of Phuentsholing can be covered in about 4-5 hours, making it a convenient and time-efficient route. From Phuentsholing, the journey continues to Bhutan's capital, Thimphu, which can be reached in a day, despite the travel being a bit hectic. As Adorable Vacation is <a href="https://adorablevacation.com/bhutan-b2b-package-tour-operator-in-india-best-bhutan-dmc/" target="_blank"><strong>Best B2B DMC for Bhutan tour</strong></a>, company also offers <a href="https://adorablevacation.com/bhutan-package-tour-from-phuentsholing/" target="_blank"><strong>Bhutan Package Tour from Phuentsholing</strong></a> & <a href="https://adorablevacation.com/bhutan-package-tour-from-hasimara-new-alipurduar/" target="_blank"><strong>Bhutan Package tour from Hasimara Station</strong></a>. The difference of Bhutan tour origination points depends on availability of Train & Bus tickets. Hence Adorable Vacation always suggest you to book your <a href="https://adorablevacation.com/bhutan-tour-package-from-bagdogra/" target="_blank"><strong>Bhutan package tour from Bagdogra Airport</strong></a> well in advance to avoid any peak season rush and high package cost. <br /><br />

                                        The <strong>NJP to Bhutan tour</strong> is a popular choice for many, as it offers a hassle-free and scenic route to one of Asia’s most captivating destinations. With regular train services from NJP, travellers can easily board trains from cities like Kolkata, New Delhi, and Guwahati, and reach NJP in a matter of hours. So, you can easily embark on a memorable <strong>Bhutan Package tour from NJP</strong>, starting with a drive through the picturesque landscapes of the region, eventually crossing into Bhutan through Phuentsholing, where you can complete your border formalities and proceed with your tour. <br /><br />

                                        A <strong>Bhutan tour packages from NJP</strong> typically cover the main highlights of the country, such as Thimphu, Paro, and Punakha. These three towns offer a diverse Bhutan experience — from the bustling streets of Thimphu, with its blend of modernity and tradition, to the serene beauty of Paro, home to the iconic Tiger’s Nest Monastery, and the enchanting Punakha, with its majestic Dzong and stunning landscapes. A well-organized <strong>Bhutan tour from NJP</strong> ensures that you visit the best of these destinations while enjoying the convenience of a smooth journey. <br /><br />

                                        Though the travel might seem a bit long, the journey is an essential part of the <strong>Bhutan Package from NJP Station</strong>, giving you a chance to experience the scenic beauty of the Indian plains and the foothills of the Himalayas before entering the mystical world of Bhutan. The tour offers a unique combination of adventure, culture, and nature, making it an unforgettable travel experience. <br /><br />

                                        NJP Station is an ideal gateway for those looking to explore Bhutan, thanks to its easy connectivity and proximity to the border. Whether you're booking a <strong>Bhutan Package tour from NJP</strong> or opting for a custom <strong>Bhutan package tour from Siliguri</strong>, the convenience of the route, combined with the charm of Bhutan’s cultural richness and scenic beauty, makes this an excellent option for a memorable Himalayan getaway. <br /><br /><br /></p>

                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>FAMOUS PLACES OF BHUTAN TO NOTE BEFORE BOOKING <strong>NJP TO BHUTAN TOUR</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>FAMOUS PLACES OF BHUTAN TO NOTE BEFORE BOOKING <strong>NJP TO BHUTAN TOUR</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">

                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr><th>CITIES</th><th>ALTITUDE</th><th>COORDINATES</th></tr>
                                                <tr><td> Thimphu</td><td>2,334 m</td><td>27.4712° N, 89.6339° E</td></tr>
                                                <tr><td>Paro</td><td>2200 m</td><td>27.4287° N, 89.4164° E</td></tr>
                                                <tr><td>Punakha</td><td>1,274 m</td><td>27.5921° N, 89.8797° E</td></tr>
                                                <tr><td>Tigers Nest Monastery</td><td>3,120 m</td><td>27.2930° N, 89.2148° E</td></tr>
                                                <tr><td>Chele-La-Pass</td><td>3,988 m</td><td>27.3698° N, 89.3466° E</td></tr>
                                                <tr><td>Haa Valley</td><td>2,670 m</td><td>27.3333° N, 89.1833° E</td></tr>
                                                <tr><td>Dochula Pass</td><td>3,100 m</td><td>27.2924° N, 89.4501° E</td></tr>
                                                <tr><td>Phobjikha Valley</td><td>3,000 m</td><td>27.4594° N, 90.1802° E</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHY ADORABLE VACATION IS THE BEST CHOICE FOR BOOKING <strong>BHUTAN TOUR PACKAGE FROM NJP STATION</strong> </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY ADORABLE VACATION IS THE BEST CHOICE FOR BOOKING <strong>BHUTAN TOUR PACKAGE FROM NJP STATION</strong> </h3>
                                </div>
                                <div className="card-body">
                                    <ul>
                                        <li><b>Trusted Brand : </b> Adorable Vacation has been the most trusted brand for <strong>Bhutan tour from NJP Station</strong>. <br /><br /></li>
                                        <li><b>Passenger Service Agent (PSA) : </b> We are proud to be the PSA for Bhutan Airlines and Druk Airlines. <br /><br /></li>
                                        <li><b>Luxury Packages : </b> Experience the best <a href="https://adorablevacation.com/bhutan-hotel-six-senses-package-tour-with-direct-flight-from-india/" target="_blank"><strong>Bhutan Luxury Package Tour with Hotel Six Senses</strong></a>, Amankora, COMO Uma, Le Méridien, and Pemako. <br /><br /></li>
                                        <li><b>Super Peak Season Inventory : </b> We have pre-purchased hotel room inventory for peak season for <a href="https://adorablevacation.com/mumbai-special-chartered-flight/ " target="_blank"><strong>Bhutan package tour from Mumbai with chartered flight</strong></a>. <br /><br /></li>
                                        <li><b>Authorized Travel House : </b> We are an authorized travel house by the Tourism Council of Bhutan [License No: 1053228]. <br /><br /></li>
                                        <li><b>Direct Employment : </b> Our employees in Bhutan are directly on our payroll, ensuring quality service.<br /><br /></li>
                                        <li><b>Customized Itineraries : </b> We offer tailored <strong>Bhutan tour itineraries from NJP</strong> with unique and signature programs. <br /><br /></li>
                                        <li><b>In-Depth Knowledge : </b> Our team possesses extensive knowledge about Bhutan's territory, hotels, and local laws. <br /><br /></li>
                                        <li><b>Assured Services : </b> We ensure premium services to enrich your experience while in Bhutan. <br /><br /></li>
                                        <li><b>E-Permit Processing : </b> We handle the e-permit process in advance for all your sightseeing needs. <br /><br /></li>
                                        <li><b>Personalized Attention : </b> Enjoy safe and secure personal attention throughout your stay in Bhutan. <br /><br /></li>
                                        <li><b>Complete Responsibility : </b> Adorable Vacation takes full responsibility for your entire <strong>Bhutan tour from NJP</strong>. <br /><br /></li>
                                        <li><b>Hassle-Free Experience : </b> We guarantee hassle-free and trustworthy services in Bhutan. <br /><br /></li>
                                        <li><b>Best Deals : </b> Access the best deals for top properties and services based on your preferences. <br /><br /></li>
                                        <li><b>Flexible Travel Options : </b>Plan a customized Bhutan private trip or join a <strong>Bhutan group tour</strong> with Adorable Vacation. <br /><br /></li>
                                    </ul>

                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <br /><br />
                                <table style={{ width: '100%', textAlign: 'center' }}>
                                    <tbody>
                                        <tr>
                                            <td className="image-cell" style={{ textAlign: 'center' }}>
                                                <img
                                                    src={sightseen4ImageURL}
                                                    alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"
                                                    style={{ margin: '10px' }}
                                                />
                                                <img
                                                    src={sightseen5ImageURL}
                                                    alt="Bhutan Guwahati travel packages"
                                                    style={{ margin: '10px' }}
                                                />
                                                <img
                                                    src={sightseen6ImageURL}
                                                    alt="travel bhutan with best b2b dmc - adorable vacation"
                                                    style={{ margin: '10px' }}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br /><br /><br />

                                <h3 className='site-heading text-center'><b >Wonderful <span className='tomato'>Bhutan Tour Review from Mumbai</span> from our esteemed Guests Mr. & Mrs. Dalal</b><br /></h3>
                                <p className='p-2'><center>We are thrilled to share a heartfelt review for <strong>Bhutan tour from Mumbai</strong> from our valued guests, Mr. & Mrs. Dalal. Their experience highlights the exceptional service and memorable moments they encountered during their <a href='/mumbai-special-chartered-flight' target='_blank'><strong>Bhutan tour from Mumbai with Direct Flight</strong></a>. We take great pride in ensuring that every guest has a delightful and fulfilling journey, and it's always rewarding to hear such positive feedback.</center></p><br /><br />
                                <div class="video-container">
                                    <iframe
                                        src="https://www.youtube.com/embed/19drRVnSMCs?si=Uj5Fm4uSuwPi0SBM"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen>
                                    </iframe>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> QUICK FACTS NEED TO KNOW WHILE PLANNING <strong>BHUTAN PACKAGE TOUR FROM NJP / SILIGURI STATION</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> QUICK FACTS NEED TO KNOW WHILE PLANNING <strong>BHUTAN PACKAGE TOUR FROM NJP / SILIGURI STATION</strong> </h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr><th>Fact</th><th>Details</th></tr>
                                                <tr><td>Capital</td><td>Thimphu</td></tr>
                                                <tr><td>Largest Cities</td><td>Thimphu, Punakha, Paro, Phuentsholing, Samdrup Jongkhar, Wangdue Phodrang, Samtse, Jakar </td></tr>
                                                <tr><td>Official Language</td><td>Dzongkha</td></tr>
                                                <tr><td>Population</td><td>7.87 lakhs (2023)</td></tr>
                                                <tr><td>Area</td><td>38,394 km²</td></tr>
                                                <tr><td>Currency</td><td>The ngultrum (BTN)</td></tr>

                                                <tr><td>Independence Day</td><td>National Day is a public holiday in the Kingdom of Bhutan on December 17th each year</td></tr>
                                                <tr><td>National Flower</td><td>Himalayan blue poppy</td></tr>
                                                <tr><td>National Animal</td><td>the Takin</td></tr>
                                                <tr><td>Major Religion</td><td>Buddhism</td></tr>
                                                <tr><td>UNESCO World Heritage Sites</td>
                                                    <td>Ancient Ruin of Drukgyel Dzong <br /><br />
                                                        Dzongs: Punakha Dzong, Wangdue Phodrang Dzong, Paro Dzong, Trongsa Dzong and Dagana Dzong<br /><br />
                                                        Sacred Sites associated with Phajo Drugom Zhigpo and his descendants<br />
                                                        Tamzhing Monastery<br />
                                                        Royal Manas National Park (RMNP)<br />
                                                        Jigme Dorji National Park (JDNP)<br />
                                                        Bumdeling Wildlife Sanctuary<br />
                                                        Sakteng Wildlife Sanctuary (SWS)</td></tr>

                                                <tr><td>Famous Cuisine</td><td>Ema datshi, Jasha Maroo, Phaksha paa, Kewa Datshi, Shakam datshi, Shamu Datshi</td></tr>

                                                <tr><td>Notable Attractions in Bhutan</td><td>
                                                    Paro Taktsang, Dochula Pass, Punakha Dzong, National Memorial Chhorten, Tashichho Dzong, National Museum of Bhutan, Gangtey Monastery, Punakha Suspension Bridge, Royal Textile Academy, Trashigang Dzong, Ura valley, Dechen Phodrang Monastery, National Library & Archives Of Bhutan</td></tr>

                                                <tr><td>Notable River of Bhutan</td><td>Manas River, Mo Chhu, Paro Chu, Jaldhaka, Pho Chhu, Tang Chhu River, Shil Torsa River, Raidak River</td></tr>

                                                <tr><td>Highest Mountain</td><td>Kangkar Pünzum [7,570 metres]</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> <strong>BHUTAN PACKAGE TOUR COST FROM NJP</strong>  </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BHUTAN PACKAGE TOUR COST FROM NJP</strong> </h3>
                                </div>
                                <div className="card-body">

                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen7ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen8ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen9ImageURL}
                                                        alt="travel bhutan with best b2b dmc - adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br /><br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> HOW TO REACH NJP FOR BHUTAN TOUR</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HOW TO REACH NJP FOR BHUTAN TOUR</h3>
                                </div>
                                <div className="card-body">
                                    <h4> NJP TO BHUTAN TOUR BY TRAIN</h4>

                                    <p>There are lots of train that runs from Kolkata, Sealdah and Howrah Junction Station to NJP on regular or interval basis. Every day trains like Darjeeling Mail, Padatik Express, Kanchan Kanya Express, Uttar Banga Express, Teesta Torsha Express, Sarai Ghat Express from Howrah, Garib Rath Express from Howrah etc. These trains covers almost 510km within 8 to 10hrs depending upon haults and speed. Following are the train schedule which will guide you to choose the train if you are looking for <strong>Bhutan tour package from NJP Station</strong>. <br /><br /><br /></p>



                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">

                                            <thead className="cf">

                                                <tr><th><b>Train No</b></th><th><b>Train Name</b></th><th><b>Dep. Stn</b></th><th><b>Dep. Time</b></th><th><b>Arr. Stn</b></th><th><b>Arr. Time</b></th><th><b>Frequency</b></th></tr></thead>
                                            <tbody><tr><td>22301</td><td>Vande Bharat Express</td><td>Howrah (HWH)</td><td>05:55</td><td>New Jalpaiguri Jn (NJP)</td><td>13:25</td><td>Except Wednesday</td></tr>
                                                <tr><td>22302</td><td>Vande Bharat Express</td><td>New Jalpaiguri Jn (NJP)</td><td>15:05</td><td>Howrah (HWH)</td><td>22:35</td><td>Except Wednesday</td></tr>
                                                <tr><td>12343</td><td>Darjeeling Mail</td><td>Sealdah (SDAH)</td><td>22:05</td><td>New Jalpaiguri Jn (NJP)</td><td>08:15</td><td>Daily</td></tr>
                                                <tr><td>12377</td><td>Padatik Express</td><td>Sealdah (SDAH)</td><td>23:00</td><td>New Jalpaiguri Jn (NJP)</td><td>09:15</td><td>Daily</td></tr>
                                                <tr><td>13149</td><td>Kanchan Kanya Express</td><td>Sealdah (SDAH)</td><td>20:30</td><td>New Mal Jn(NMZ)</td><td>09:31</td><td>Daily</td></tr>
                                                <tr><td>13147</td><td>UttarBanga Express</td><td>Sealdah (SDAH)</td><td>19:35</td><td>Falakata (FLK)</td><td>08:38</td><td>Daily</td></tr>
                                                <tr><td>13141</td><td>Teesta Torsa Express</td><td>Sealdah (SDAH)</td><td>13:40</td><td>New Maynaguri (NMX)</td><td>04:22</td><td>Daily</td></tr>
                                                <tr><td>12345</td><td>SaraiGhat Express</td><td>Howrah (HWH)</td><td>15:50</td><td>New Jalpaiguri (NJP)</td><td>01:40</td><td>Daily</td></tr>
                                                <tr><td>12517</td><td>Garib Rath</td><td>Kolkata (KOOA)</td><td>21:40</td><td>New Jalpaiguri (NJP)</td><td>07:45</td><td>Sun, Thrus</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <h3>FOR <strong>BHUTAN TOUR PACKAGE FROM NJP</strong>, YOU CAN LOOK FOR HOWRAH JUNCTION TO NEW JALPAIGURI VANDE BHARAT 22301 EXPRESS  [EXCEPT WEDNESDAY]</h3><br /><br />
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr><th><b>Station</b></th><th><b>Arrival <br /> Departure</b></th><th><b>Halt</b></th><th><b>Distance</b></th></tr></thead>
                                            <tbody>
                                                <tr><td>Howrah Jn [HWH]</td><td>Starts <br />05:55</td><td>Howrah (HWH)</td><td>0 km</td></tr>
                                                <tr><td>Bolpur Shantiniketan [BHP]</td><td>07:43 <br />07:45</td><td>2 min</td><td>145 km</td></tr>
                                                <tr><td>Malda Town [MLTD]</td><td>10:32 <br />10:35</td><td>3 min</td><td>329.5 km</td></tr>
                                                <tr><td>Barsoi Jn [BOE]</td><td>11:50 <br />11:52</td><td>2 min</td><td>419.7 km</td></tr>
                                                <tr><td>New Jalpaiguri Jn [NJP]</td><td>13:25<br />ends</td><td></td><td>564.6</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <h4> NJP TO BHUTAN TOUR BY BUS</h4><br /><br />
                                    <p>Guests looking for Kolkata to Bhutan tour, easily can reach Siliguri by bus. Everyday 100+ Busses runs between Esplanade and Siliguri.  High end luxury bus operator like Lokenath Bus Service, Express Line, Prarthana Bus Service, Shyamoli Paribahan Pvt Ltd, Greenline, Rubi Paribahan, Maulik Paribahan, Shantanu Travels, Annesha Travels operates Kolkata to Siliguri Night Bus service with reasonable fare. Tickets are available in Online & Offline. Online Apps like Abhibus & RedBus offers bus tickets from Esplanade to NJP with Seat available and fare. So, login these apps and buy tickets for Kolkata to NJP and book your <strong>Bhutan package tour from Siliguri</strong>. <br /><br /><br /></p>
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">

                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr>
                                                    <th><b>OPERATOR</b></th>
                                                    <th><b>DEP TIME
                                                        [ESPLANADE]</b></th>
                                                    <th><b>ARV TIME [SILIGURI] </b></th>
                                                    <th><b>DURATION</b></th></tr>
                                            </thead>
                                            <tbody>
                                                <tr><td>Lokenath Bus Service</td><td>20:00</td><td>08:00</td><td>12h 0m</td></tr>
                                                <tr><td>Prarthana Bus Service</td><td>19:30</td><td>07:30</td><td>12h 0m</td></tr>
                                                <tr><td>Shyamoli Paribahan</td><td>20:45</td><td>08:45</td><td>12h 0m</td></tr>
                                                <tr><td>Easy Ride</td><td>19:50</td><td>08:45</td><td>12h 55m</td></tr>
                                                <tr><td>Greenline</td><td>18:30</td><td>07:45</td><td>13h 15m</td></tr>
                                                <tr><td>Express Line</td><td>18:30</td><td>07:40</td><td>13h 10m</td></tr>
                                                <tr><td>Maa Durga Bus Service</td><td>19:00</td><td>09:00</td><td>14h 0m</td></tr>
                                                <tr><td>Aradhana Bus Service</td><td>16:45</td><td>07:00</td><td>14h 15m</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> SIGHTSEEING PLACES & ENTRY FEES TO NOTE FOR <strong>BHUTAN TOUR FROM NJP STATION</strong> </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> SIGHTSEEING PLACES & ENTRY FEES TO NOTE FOR <strong>BHUTAN TOUR FROM NJP STATION</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">

                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr><th><b>PLACES</b></th><th><b>CITY</b></th><th><b>ENTRY FEES</b></th><th><b>REMARKS</b></th></tr></thead>
                                            <tbody><tr><td colspan="4">THIMPHU SIGHTSEEING PLACES AND THE ENTRY FEES (IN NGULTRUM)</td></tr>
                                                <tr><td>Tashichhoe Dzong (Fort)</td><td>Thimphu</td><td>NU 500</td><td>Mon-Fri from 5pm & Sat-Sun from 9am to 6pm</td></tr>
                                                <tr><td>Buddha Dordenma Statue</td><td>Thimphu</td><td>No Entry Fee</td><td>Time-9am to 5pm</td></tr>
                                                <tr><td>Thimphu Memorial Chorten</td><td>Thimphu</td><td>NU 500</td><td>Anytime</td></tr>
                                                <tr><td>Changangkha Lhakhang (Temple)</td><td>Thimphu</td><td>NU 500</td><td>Anytime between 8am to 5pm</td></tr>
                                                <tr><td>Takin Preservation Centre</td><td>Thimphu</td><td>NU 500</td><td>9am to 5pm</td></tr>
                                                <tr><td>Simtokha Dzong (Fort)</td><td>Thimphu</td><td>NU 500</td><td>9am to 5pm</td></tr>
                                                <tr><td>National Library</td><td>Thimphu</td><td>NU 100</td><td>9am to 5pm on Mon to Fri</td></tr>
                                                <tr><td>The Royal Textile Museum</td><td>Thimphu</td><td>NU 250</td><td>Mon to Sat from 9am to 4pm</td></tr>
                                                <tr><td>National Institute for Zorig Chusum (Painting School)</td><td>Thimphu</td><td>NU 200</td><td>Mon to Fri 10am to 3:30pm</td></tr>
                                                <tr><td>Folk Heritage Museum</td><td>Thimphu</td><td>NU 200</td><td>Open from 9am to 4pm on Mon to Sun</td></tr>
                                                <tr><td>Simply Bhutan Museum</td><td>Thimphu</td><td>NU 1000</td><td>Open from 9am to 4pm on Mon to Sun</td></tr>

                                                <tr><td colspan="4">PUNAKHA SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                                                <tr><td>Punakha Dzong (Fort)</td><td>Punakha</td><td>NU 500</td><td>11am to 1pm & 3pm to 5pm (Nov to May) and other time anytime bwtn 9am to 5pm</td></tr>
                                                <tr><td>Chimmi Lhakhang (Temple)</td><td>Punakha</td><td>NU 500</td><td>9am to 1pm and 2pm to 5pm </td></tr>
                                                <tr><td>Khamsum Yueely Namgyal Chorten</td><td>Punakha</td><td>NU 100</td><td>9am to 1pm and 2pm to 5pm </td></tr>

                                                <tr><td colspan="4">PARO SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                                                <tr><td>Kyichu Lhakhang (temple)</td><td>Paro</td><td>NU 500</td><td>9am to 12pm & 1pm to 4pm </td></tr>
                                                <tr><td>Rinpung Dzong (Fort)</td><td>Paro</td><td>No Entry Fee</td><td>9am to 5pm (Summer) & 9am to 4pm (Winter) </td></tr>
                                                <tr><td>Dobji Dzong</td><td>Paro</td><td>No Entry Fee</td><td>9am to 5pm </td></tr>
                                                <tr><td>National Museum of Bhutan</td><td>Paro</td><td>NU 300</td><td>Opens from 9am to 4pm throughout the week except on govt & local holidays </td></tr>
                                                <tr><td>Taktshang (Tiger Nest Monastery)</td><td>Paro</td><td>NU 1000</td><td>9am to 5pm </td></tr>

                                                <tr><td colspan="4">TRONGSA SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                                                <tr><td>Trongsa Dzong</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>
                                                <tr><td>Trongsa Ta Dzong (Watch tower)</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>

                                                <tr><td colspan="4">BUMTHANG SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                                                <tr><td>Jampa Lhakhang</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>
                                                <tr><td>Kurjey Lhakhang</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>
                                                <tr><td>Jakar Dzong</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>

                                                <tr><td colspan="4">PHUENTSHOLING SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                                                <tr><td>Rinchending Goenpa (temple) </td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm </td></tr>
                                                <tr><td>Palden Tashi Choling Shedra </td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm </td></tr>
                                                <tr><td colspan="4">NOTE : 50% monument fees exemption on entry fees for children of 6 years to 18 years and no fee for children of 5 years and below. </td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={flight1ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={flight2ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={flight3ImageURL}
                                                        alt="travel bhutan with best b2b dmc - adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-bagdogra" target='_blank' className="btn btn-Warning btn-lg">
                                            <b>Bhutan Package Tour From Bagdogra Airport</b>
                                        </a>
                                    </div>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-pune" target='_blank' className="btn btn-Warning btn-lg w-100">
                                            <b>Bhutan Package Tour Booking From Pune</b>
                                        </a>
                                    </div>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-kolkata" target='_blank' className="btn btn-Warning btn-lg w-100">
                                            <b>Bhutan Package Tour From Kolkata Airport</b>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHICH IS THE BEST TIME TO BOOK <strong>BHUTAN PACKAGE TOUR FROM NJP</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> WHICH IS THE BEST TIME TO BOOK <strong>BHUTAN PACKAGE TOUR FROM NJP</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p><b>Summer</b> in Bhutan (June to September) offers pleasant temperatures between 20-25°C, with sunny days and chilly nights. The landscapes are lush, and the skies are clear, offering stunning views of the Himalayas. Summer is also the time for the Haa Festival, a vibrant celebration of Bhutanese culture. With fewer tourists, it’s a peaceful time to explore. So, summer is a great season to book a <strong>Bhutan Holiday Package from NJP Station</strong>. <br /><br /><br /></p>

                                    <h4>AUTUMN [SEPTEMBER - NOVEMBER]</h4>
                                    <p>Autumn (September to November) is perfect for booking a <strong>Bhutan tour from Siliguri</strong>. Temperatures are comfortable (10-15°C), and Bhutan's festivals like the Paro Tshechu and Punakha Tshechu bring the country’s culture to life. The weather is ideal for sightseeing, and booking early ensures availability during the busy season. <br /><br /><br /></p>

                                    <h4>WINTER [DECEMBER - FEBRUARY]</h4>
                                    <p><b>Winter</b> (December to February) in Bhutan can be cold, with snow in places like Thimphu, Paro, and Punakha. While temperatures can drop below freezing, this season is also when festivals like Monggar Tshechu take place. If you love snow and winter festivals, a <strong>Bhutan package tour in December</strong> is perfect. Just be prepared for the cold! <br /><br /><br /></p>

                                    <h4>SPRING [MARCH - MAY]</h4>
                                    <p>Spring (March to May) is one of the best times to visit Bhutan. The weather is mild and dry, with clear skies and blooming flowers like Jacaranda and Rhododendrons. This season hosts major festivals, including Paro Tshechu and Gasa Tshechu, making it a lively time to visit. To avoid high-season costs, it’s best to book your <strong>Bhutan package in May</strong> in advance. <br /><br /><br /></p>


                                    <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr><th>SEASON</th><th>MONTH</th><th>WEATHER</th><th>TEMPERATURE</th></tr>
                                                <tr><td><b>Winter</b></td><td>December</td><td>Cool and Dry</td><td>15°C - 24°C</td></tr>
                                                <tr><td></td><td>January</td><td>Cool and Dry</td><td>14°C - 24°C</td></tr>
                                                <tr><td></td><td>February</td><td>Cool and Dry</td><td>15°C - 25°C</td></tr>
                                                <tr><td><b>Spring</b></td><td>March</td><td>Warm and sunny</td><td>17°C - 29°C</td></tr>
                                                <tr><td></td><td>April</td><td>Warm and sunny</td><td>21°C - 32°C</td></tr>
                                                <tr><td><b>Summer</b></td><td>May</td><td>Hot and Humid</td><td>24°C - 34°C</td></tr>
                                                <tr><td></td><td>June</td><td>Hot and Humid</td><td>25°C - 35°C</td></tr>
                                                <tr><td></td><td>July</td><td>Hot and Humid</td><td>26°C - 35°C</td></tr>
                                                <tr><td></td><td>August</td><td>Hot and Humid</td><td>25°C - 34°C</td></tr>
                                                <tr><td><b>Autumn</b></td><td>September</td><td>Warm and rainy</td><td>24°C - 32°C</td></tr>
                                                <tr><td></td><td>October</td><td>Cool and Dry</td><td>22°C - 30°C</td></tr>
                                                <tr><td></td><td>November</td><td>Cool and Dry</td><td>18°C - 28°C</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {/* <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen10ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen11ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen12ImageURL}
                                                        alt="travel bhutan with best b2b dmc - adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> */}
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> BEST HOTELS ADORABLE VACATION PROVIDE FOR <strong>BHUTAN TOUR PACKAGE FROM NJP STATION</strong> </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> BEST HOTELS ADORABLE VACATION PROVIDE FOR <strong>BHUTAN TOUR PACKAGE FROM NJP STATION</strong> </h3>
                                </div>
                                <div className="card-body">

                                    <br /><br /><br />
                                    {/* <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br /> */}
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen13ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen14ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen15ImageURL}
                                                        alt="travel bhutan with best b2b dmc adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="row py-5">
                                <div className="col-md-12">
                                    <div className="site-heading text-center">
                                        <h3>
                                            <span>DAY WISE DETAIL ITINERARY FOR <strong>BHUTAN TOUR PACKAGE FROM NJP STATION</strong></span>
                                        </h3>
                                        <h4>5N/6D <strong>Bhutan Tour Package Itinerary From Njp</strong> from Adorable Vacation</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div
                                    className="accordion"
                                    id="accordionPanelsStayOpenExample"
                                >
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day1"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseOne"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseOne"
                                            >
                                                <h4> DAY 01: WELCOME TO NJP/SILIGURI - TRANSFER TO THIMPHU </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseOne"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingOne"
                                        >
                                            <div className="accordion-body">
                                                <p>To day marks the start of your exciting <strong>Bhutan tour from NJP</strong>. Upon arrival at NJP Station, our representative will greet you and drive you to Phuentsholing. The journey from NJP to Phuentsholing is full of scenic beauty, with tea gardens, pineapple orchards, rivers, and streams along the way. Take a break to enjoy the views and snap some photos amidst the lush greenery. Once you arrive in Phuentsholing, our representative will assist you with the permit process, including biometric data collection. The permit process typically takes between 2 to 6 hours, depending on the time of year and the crowd at the immigration office. During this waiting period, feel free to have lunch. After your permit is ready, you will continue your journey to Thimphu, the capital of Bhutan, which is about 4.5 hours away by car.<br /><br />
                                                    Upon reaching Thimphu, check in at your hotel in the afternoon or evening. After freshening up, take a relaxing stroll around the city. Explore the local nightlife, try a local beer, enjoy karaoke, or unwind with a traditional hot stone bath at a spa. End your day with a delicious dinner and get a restful night's sleep in Thimphu. <br /><br /></p>

                                                <h4>BARS & NIGHT CLUBS TO HANGOUT NIGHTLIFE IN THIMPHU</h4><br /><br />

                                                <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                        <thead className="cf"><tr><th>NAME</th><th>ADDRESS</th><th>TIMINGS</th></tr></thead>
                                                        <tbody><tr><td>Mojo Park</td><td>Chang Lam, Thimphu, Bhutan</td><td>5 PM - 12 AM</td></tr>
                                                            <tr><td>Space 34</td><td>Chang Lam, Main Town, Thimphu</td><td>9 PM - 1 AM</td></tr>
                                                            <tr><td>Om Bar</td><td>Thimphu, Bhutan</td><td>5 PM - 1 AM</td></tr>
                                                            <tr><td>Vivacity</td><td>Sheten Lam, Thimphu, Bhutan</td><td>7 PM - 12 AM</td></tr>
                                                            <tr><td>Club Ace</td><td>Thimphu, Bhutan</td><td>9 PM - 1 AM</td></tr>
                                                            <tr><td>Barz Brothers</td><td>Thimphu, Bhutan</td><td>Open 24 hours</td></tr>
                                                            <tr><td>Benez Bar</td><td>Jangchhub Lam, Thimphu, Bhutan</td><td>10 AM - 11 PM</td></tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br /> <br /><br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay1ImageURL} alt="Bhutan Package Tour From Guwahati with direct flights" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Bhutan Package Tour From Guwahati with direct flights" /></center>
                                                </div>
                                                <br /> <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day2"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseTwo"
                                            >
                                                <h4>DAY 02: FULL DAY THIMPHU SIGHTSEEING TOUR</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseTwo"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingTwo"
                                        >
                                            <div className="accordion-body">
                                                <p>This is the 2nd day of this wonderful <strong>Bhutan tour packages from NJP Station</strong>. Start your day with a hot cup of <b>Suja (Butter Tea)</b> as the sun shines over the beautiful Thimphu valley. After breakfast, get ready for a tour of Thimphu's local attractions. <br /><br /></p>

                                                <p><b>The National Memorial Chorten (Thimphu Chorten)</b>: A popular spot for evening prayers, this chorten was built in memory of Bhutan’s third king, Jigme Dorji Wangchuk, and completed in 1974. Locals walk around, spinning prayer wheels and chanting mantras. It is a symbol of peace and prosperity. <br /><br />

                                                    <b>The Clock Tower</b>: Located at the heart of Thimphu, the Clock Tower Square is an excellent example of Bhutanese architecture. The tower features four clock faces, with intricate carvings of flowers and dragons, symbolizing Bhutan’s independence. The square is a lively spot surrounded by shops, restaurants, and hotels, and often hosts live music events. It’s a great place to shop for souvenirs or enjoy a picnic in the nearby park. <br /><br />

                                                    <b>Sakyamuni Buddha</b>: Perched atop Kuensel Phodrang hill, the giant statue of Buddha Dordenma stands at 51.5 meters tall and is made of gilded bronze. This peaceful site offers panoramic views of the Thimphu valley, making it a must-see landmark, also known as Buddha Viewpoint. <br /><br />

                                                    <b>Changangkha Lhakhang</b>: One of Thimphu’s oldest and most important monasteries, dating back to the 15th century. Dedicated to Avalokiteshvara, the symbol of compassion, it offers stunning views of the Thimphu valley. <br /><br />

                                                    <b>Takin Zoo</b>: Home to Bhutan’s national animal, the Takin, this zoo provides an open habitat where you can see these unique, endangered animals in a natural setting. <br /><br />

                                                    <b>Trashi Chhoe Dzong</b>: Known as the Fortress of the Glorious Region, this site is a must-visit. Located on the west bank of the Wang Chhu River, it is the coronation site of Bhutan’s fifth king and hosts the annual <strong>Tsechu Festival</strong>. Built in 1641 and later rebuilt in the 1960s using traditional Bhutanese techniques, this dzong is a symbol of Bhutanese heritage. You’ll spend the night at a hotel in Thimphu. <br /><br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay2ImageURL} alt="mumbai to Bhutan Package Tour From Guwahati booking with direct flights" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="mumbai to Bhutan Package Tour From Guwahati booking with direct flights" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day3"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseThree"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 03: THIMPHU TO PUNAKHA TRANSFER & SIGHTSEEING </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseThree"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingThree"
                                        >
                                            <div className="accordion-body">
                                                <p>Start your 3rd day of the <strong>Bhutan Trip from Siliguri</strong> in Thimphu with a warm cup of Ngaja (sweet Bhutanese milk tea) while enjoying the stunning view of the valley from your room. After breakfast, set off for Punakha, the former capital of Bhutan. Located at 1,200 meters, Punakha is a peaceful, quieter destination compared to the bustling cities. It enjoys a warm climate in winter and cool weather in summer. The valley is known for its rice fields along the Pho and Mo Chu rivers. On the way, you’ll visit: <br /><br />

                                                    <b>Punakha Dzong</b>: Known as "<b>The Palace of Great Happiness</b>", this breathtaking fortress sits at the confluence of the Pho Chhu (father) and Mo Chhu (mother) rivers. It's one of Bhutan’s most iconic dzongs, connected to the mainland by an arched wooden bridge. The dzong is home to many valuable relics and served as the political and religious center when Bhutan’s kings ruled from here. <br /><br />

                                                    <b>Suspension Bridge</b>: This picturesque bridge in the Punakha Valley connects the valley to the old administrative center of Punakha Dzongkhag. Covered in prayer flags, it offers a scenic spot for birdwatching and photography. <br /><br />

                                                    <b>Chimi Lhakhang (Fertility Temple)</b>: Situated on a hill, this temple is dedicated to Lama Drukpa Kuenley, known as the 'Divine Madman'. Using humor and songs to teach, Lama Drukpa Kuenley is believed to bless childless couples who visit the temple. The walk to the temple is through beautiful paddy fields and a small stream, followed by a short climb to reach the temple. You will spend the night at a hotel in Punakha. <br /><br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay3ImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day4"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFour"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 04: - PUNAKHA TO PHOBJIKHA VALLEY DAY EXCURSION & RETURN </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseFour"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFour"
                                        >
                                            <div className="accordion-body">
                                                <p>As the Sun shines up high in the Land of Happiness, open your eyes to a beautiful morning in the lap of serene nature, and sip into your cup of hot tea while admiring the green valley from your bed before moving towards Phobjikha Valley post breakfast. <br /><br />

                                                    <b>Phobjikha Valley</b> : A treat for all nature lovers, and an idle place for spotting various birds, including the famous Black Necked Cranes that migrate to this valley. Being one of the most important wildlife preserves in Bhutan, this serene and untouched beauty of nature away from the hustle-bustle of the city life is a pleasure to enjoy with the happiest people surrounding the entire region that makes this place worth the visit. The Phobjikha Valley is the largest and the widest in Bhutan and is known for the panoramic vistas with tranquil nature that not only call the nature lovers to witness its beauty but also traps the adventure buffs who wish to follow the trekking and hiking routes while exploring the untouched beauty of nature. Overnight at hotel in Punakha. <br /><br /><br /></p>


                                                <h4>Following are the places to explore in <b>Phobjikha Valley</b> : <br /><br /></h4>
                                                <div id="no-more-tables">
                                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ fontSize: "16px" }}>Black-Necked Crane Information Centre</td>
                                                                <td style={{ fontSize: "16px" }}>Gangtey (Hilltop) Monastery or Gangtey Goemba</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: "16px" }}>Gangtey Nature Trails</td>
                                                                <td style={{ fontSize: "16px" }}>Dotsho (Traditional Hot Stone Bath)</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: "16px" }}>Experience Stay in Bhutanese Farm Stay</td>
                                                                <td style={{ fontSize: "16px" }}>Khewang Lhakhang</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: "16px" }}>Experience Archery with Local People</td>
                                                                <td style={{ fontSize: "16px" }}>Open Air Campsite at Phobjikha</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: "16px" }}>Picnic with Family And Friends</td>
                                                                <td style={{ fontSize: "16px" }}>Kumbhu Lhakhang</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: "16px" }}>Restaurant in Phobjikha</td>
                                                                <td style={{ fontSize: "16px" }}>Nyelung Dechenling</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: "16px" }}>Bird Watching in Phobjikha</td>
                                                                <td style={{ fontSize: "16px" }}>Wangmo Hand-Woven Carpet Factory (Phobjikha)</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay4ImageURL} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day5"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFive"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 05: PUNAKHA TO PARO BACK & LOCAL SIGHTSEEING </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseFive"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFive"
                                        >
                                            <div className="accordion-body">
                                                <p>This is 5th Day of your <strong>Bhutan trip from NJP</strong>. After breakfast, begin your journey to Paro and visit the following places along the way: <br /><br />

                                                    <b>National Museum of Bhutan</b>: Situated above Rinpung Dzong in Paro, the National Museum of Bhutan showcases the country’s culture. It was built in 1968 inside the old Ta Dzong building. The museum displays beautiful Bhutanese art, paintings, and bronze statues. It also has large galleries where you can learn about Bhutan’s history and culture. <br /><br />

                                                    <b>Drukgyal Dzong</b>: Drukgyal Dzong is a ruined fortress and monastery in the upper Paro Valley. It was built in 1649 to celebrate Bhutan’s victory over a Tibetan invasion. The site is currently closed to visitors. <br /><br />

                                                    <b>Ta Dzong</b>: Ta Dzong was originally built as a watchtower to protect Rinpung Dzong during the 17th-century wars. In 1968, it became the National Museum of Bhutan. <br /><br />

                                                    <b>Rinpung Dzong</b>: Built in 1646 by Shabdrung Ngawang Namgyal, the first ruler of Bhutan, Rinpung Dzong is home to monks, the district head (Dzongda), and the judge (Thrimpon). To reach the dzong, you will cross a traditional covered bridge called Nemi Zam. <br /><br />

                                                    <b>Nya Mey Zam Bridge</b>: Known as "The Bridge with No Fish," this bridge connects Paro Town to the Dzong. Locals believe that seeing a fish in the river is a bad omen. The original bridge, which could be folded during wars, was destroyed in a flood in 1969. <br /><br />

                                                    <b>Paro Airport View Point</b>: This viewpoint offers a stunning view of Paro Valley and the airport. You can see the Paro River, dzongs, and monasteries from here. Overnight stay at a hotel in Paro. <br /><br /><br /></p>
                                                <br /><br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay5ImageURL} alt="Bhutan Packages From Guwahati Tour Operator" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="Bhutan Packages From Guwahati Tour Operator" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day6"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSix"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 06: PARO, TAKTSANG MONASTERY HIKING / CHELE-LA PASS EXCURSION</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSix"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSix"
                                        >
                                            <div className="accordion-body">
                                                <p>Today is the most exciting day of your <strong>Bhutan Trip</strong> - the hike to Taktsang Monastery! Start your day early in the beautiful Paro Valley, with the sun rising over the mountains and river. Enjoy a hearty breakfast to fuel up, as this hike will need some energy. <br /><br />

                                                    <b>Taktsang Monastery</b>: The hike to Taktsang, also known as the Tiger’s Nest, takes you up a steep cliff for 1,200 meters. Once you reach the top, you’ll be rewarded with fresh air and amazing views of the valley below. The monastery was built in 1694 and is a sacred site founded by Guru Rinpoche, who introduced Buddhism to Bhutan in 747 CE. The hike takes around 3 hours, but if you prefer, you can ride a horse from the parking lot to the top. Along the way, you’ll enjoy beautiful views of Paro town, its green valleys, the clear blue sky, and the sparkling river. It’s a great spot to take in nature. Overnight stay in Paro. <br /><br /></p>

                                                <div id="no-more-tables" itemscope="" itemtype="https://schema.org/Table">
                                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                        <tbody>
                                                            <tr><td>Location</td><td>Approx 11.7 km from Paro town, Bhutan</td></tr>
                                                            <tr><td>Elevation</td><td>3,120 meters (10,236ft) above sea level</td></tr>
                                                            <tr><td>Duration of the Tiger's Nest hike</td><td>5-6 hours (Back and forth)</td></tr>
                                                            <tr><td>Tiger's Nest Trekking Distance</td><td>4 Miles (6.43 Km) - Round Trip</td></tr>
                                                            <tr><td>Tiger's Nest Trek Difficulty</td><td>Moderate - Steep Ascend</td></tr>
                                                            <tr><td>Best Months to Visit Tiger's Nest Monastery</td><td>March, April, May and September, October, November, December</td></tr>
                                                            <tr><td>Gears needed to hike Tiger's Nest</td><td>Normal hiking shoes, Water bottle, Some snacks, energy bars, Camera, Hat, Sunglasses, Sunscreen, Walking Pole/Stick</td></tr>
                                                            <tr><td>Opening time of Tiger's Nest Monastery</td><td>(October-March) 8 am-1 pm and 2 pm-5 pm <br /> (April-September) 8 am-1 pm and 2 pm-6 pm daily</td></tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br /><br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day7"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 07: PARO TO NJP DROP VIA PHUENTSHOLING</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>After breakfast, say goodbye to the Last Buddhist Kingdom from Paro and head to NJP, taking with you unforgettable memories of your Bhutan holiday. <br /><br /></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>IMPORTANT FACTS TO KNOW FOR <strong>BHUTAN TRIP FROM NJP</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>IMPORTANT FACTS TO KNOW FOR <strong>BHUTAN TRIP FROM NJP</strong></h3>
                                </div>
                                <div className="card-body">

                                    <li>Locals call their country "Druk Yul," which means "the Land of the Dragons." <br /><br /></li>
                                    <li>Bhutan has never been conquered or ruled by outside forces. <br /><br /></li>
                                    <li>Bhutan is the only carbon-negative country in the world. <br /><br /></li>
                                    <li>Bhutan was isolated from the rest of the world until the 1970s. <br /><br /></li>
                                    <li>You won’t find any traffic lights in Bhutan. <br /><br /></li>
                                    <li>Bhutan is a constitutional monarchy. <br /><br /></li>
                                    <li>Bhutan does not use GDP as a measure of economic growth. <br /><br /></li>
                                    <li><b>GNH</b> (Gross National Happiness) is the measure of growth and development in Bhutan. <br /><br /></li>
                                    <li>Bhutan is a plastic-free country. <br /><br /></li>
                                    <li>There are fewer than 20 qualified pilots who can fly into Bhutan's only airport. <br /><br /></li>
                                    <li>Smoking is not allowed in Bhutan and is considered a punishable offense. <br /><br /></li>
                                    <li>Bhutan has some of the world’s highest unclimbed mountain peaks, including Gangkhar Puensum at 7,570m (24,981ft). <br /><br /></li>
                                    <li>The national animal of Bhutan is the Takin, a goat-antelope. <br /><br /></li>
                                    <li>Bhutanese food is very spicy, and you will find chilies in almost every dish. <br /><br /></li>
                                    <li>Education and healthcare are free for all citizens. <br /><br /></li>
                                    <li>Archery is the national sport of Bhutan. <br /><br /></li>
                                    <li>Bhutanese people love their king, and you’ll find his photo in every home. <br /><br /></li>
                                    <li>Bhutan has a very low crime rate. <br /><br /></li>
                                    <li>Wearing the national dress (Gho for men and Kira for women) is mandatory in schools, government offices, and during festivals. <br /><br /></li>
                                    <li>Cleanliness is very important in Bhutan, and the environment is highly prioritized. <br /><br /></li>
                                    <li>Bhutanese people often chew betel leaf and areca nut. <br /><br /></li>
                                    <li>Ema Datshi is the national dish of Bhutan, made with spicy chilies and local cheese. <br /><br /></li>


                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen13ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen16ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen18ImageURL}
                                                        alt="travel bhutan with best b2b dmc adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> ACTIVITIES TO ENJOY DURING YOUR <strong>BHUTAN TOUR FROM NJP</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> ACTIVITIES TO ENJOY DURING YOUR <strong>BHUTAN TOUR FROM NJP</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                <Activityfecilities />
                                            }
                                        </div>
                                    </div>
                                    <br /><br />

                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen14ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen17ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen20ImageURL}
                                                        alt="travel bhutan with best b2b dmc adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            {/* <!--FOQ--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                Frequently Ask <span>Questions</span>
                                            </h2>
                                            <h4>Related Questions for <strong>BHUTAN TOUR PACKAGES FROM NJP</strong></h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="accordion accordion-flush faQStyle"
                                        id="accordionFlushExample"
                                    >
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingIdealTime">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseIdealTime"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseIdealTime"
                                                >
                                                    When is the ideal time to Bhutan tour from NJP?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseIdealTime"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingIdealTime"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    October to December is the best time to enjoy a pleasant holiday experience in the country. During summer, March to June is also considered the best time for NJP to Bhutan tour.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSafety">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSafety"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseSafety"
                                                >
                                                    Is it safe to visit Bhutan from NJP?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSafety"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingSafety"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Bhutan is typically safe and has an extremely low crime rate. However, one should use caution and abstain from going alone at night into remote locations.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingVisa">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseVisa"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseVisa"
                                                >
                                                    Do I need a visa to visit Bhutan from NJP?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseVisa"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingVisa"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Yes, foreign nationals (except Indian, Bangladesh, and Maldivian nationals) require a visa to visit Bhutan. Bhutanese visas are issued only through a licensed tour operator. You can arrange your visa through Adorable Vacation - handling your <strong>Bhutan package tour from NJP</strong>.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingDocuments">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseDocuments"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseDocuments"
                                                >
                                                    What documents do I need to travel to Bhutan from NJP?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseDocuments"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingDocuments"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    For Indian nationals, a valid passport or Voter ID card is required to enter Bhutan. For other foreign nationals, you will need a visa and a passport with at least six months validity. Additionally, you will need to carry a photograph for the visa process.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTravelTime">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseTravelTime"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseTravelTime"
                                                >
                                                    How long does it take to reach Bhutan from NJP?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseTravelTime"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingTravelTime"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    The drive from NJP to the Phuentsholing border takes around 4-5 hours. After crossing the border, you will need to undergo immigration and customs checks, which can take additional time. From Phuentsholing, it typically takes about 5-6 hours to reach Thimphu, the capital of Bhutan.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingCurrency">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseCurrency"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseCurrency"
                                                >
                                                    What is the currency used in Bhutan? Can I exchange money at Phuentsholing?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseCurrency"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingCurrency"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Bhutan's currency is the Bhutanese Ngultrum (BTN), which is pegged to the Indian Rupee (INR). Indian Rupees (INR) are also widely accepted in Bhutan. You can exchange currency at banks or exchange centers in Phuentsholing or Thimphu.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingRestrictions">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseRestrictions"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseRestrictions"
                                                >
                                                    Are there any travel restrictions for Bhutan from NJP?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseRestrictions"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingRestrictions"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Travel to Bhutan is relatively straightforward for Indian nationals. However, foreign nationals are required to arrange their visa through a licensed Bhutanese tour operator. There may also be some restrictions on entry during the Bhutanese New Year (Tshechu) or national festivals, so it’s a good idea to check in advance.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingCustomization">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseCustomization"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseCustomization"
                                                >
                                                    Can I customize my Bhutan package tour from NJP?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseCustomization"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingCustomization"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Yes, with Adorable Vacation – the best Bhutan tour operator in India you can have customized Bhutan packages with an itinerary based on your interests, budget, and time constraints. You can choose from various options like trekking, cultural tours, or exploring offbeat destinations.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFood">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFood"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFood"
                                                >
                                                    Is food available in Bhutan for vegetarians and non-vegetarians?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseFood"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingFood"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Bhutan offers a wide variety of food options, including both vegetarian and non-vegetarian dishes. Bhutanese cuisine includes rice, meat (mostly pork, chicken, and beef), and vegetables, with dishes like Ema Datshi (chili and cheese), Phaksha Paa (pork with red chili), and Red Rice. International cuisines are also available in most tourist areas.
                                                    <br />
                                                    With Adorable Vacation, you don’t need to worry about foods. The company offers various food options based on your requirement.
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>
                                                <b>
                                                    Bhutan <span className="tomato">Theme Package</span>
                                                </b>
                                            </h3>
                                            <h4>Similar Mountain Trip</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        id="carouselExampleControls"
                                        className="carousel slide"
                                        data-bs-ride="carousel"
                                    >
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <Slider {...settings}>{themePackagesDataView}</Slider>
                                            </div>
                                        </div>
                                    </div>

                                    <br />
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>
                                                <b>
                                                    Bhutan <span className="tomato">Tour Reviews</span>
                                                </b>
                                            </h3>
                                            <h4>
                                                <i>"Travel without Trouble"</i>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1 ">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">
                                            {reviewersDataView}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3">

                            <BookingBox />

                        </div>
                        {this.state.showModal && (
                            <div className="modal d-flex align-items-center justify-content-center" style={{ display: this.state.showModal ? "block" : "none" }}>
                                <div className="modal-dialog">
                                    <div className="modal-content cust-modContent" ref={this.modalRef}>
                                        <div className="modal-header">
                                            <h4 className="modal-title ms-auto" id="exampleModalLabel">Get a Free Callback &nbsp;&nbsp;<i className="fa fa-phone fa-rotate-90"></i>
                                            </h4>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.onCloseModal}></button>
                                        </div>
                                        <div className="modal-body">
                                            <form className="row g-3">
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name *"
                                                        name="firstName"
                                                        value={this.state.firstName}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="10 Digit Mobile No.*"
                                                        name="mobile"
                                                        value={this.state.mobile}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Email "
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Comments"
                                                        rows="2"
                                                        name="comments"
                                                        value={this.state.comments}
                                                        onChange={this.onChangeHandler}></textarea>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <div className='container'>
                                                <div className="row">
                                                    <div className='col-sm-4'>
                                                        {
                                                            (this.state.showLoader) &&
                                                            <div className='pull-left'><Digital size={25} /></div>
                                                        }
                                                    </div>
                                                    <div className='col-sm-8 '>
                                                        <div className='pull-right'>
                                                            {/* <button type="button" className="btn btn-Warning mr-1" onClick={this.onCloseModal}>Close</button> */}
                                                            <button type="button" className="btn btn-Warning btn-lg" onClick={this.onRequestCallbackSubmit}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default Bhutan_PackageTourNjpSiliguri;