import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop';
import Andaman_7N_8D_Package_Tour_Kolkata from '../../../components/package/Andaman/Andaman_7N_8D_Package_Tour_Kolkata';
import { Helmet } from "react-helmet";


class Andaman_7N_8D_Package_Tour_Kolkata_Page extends Component {
    constructor() {
        super();
        this.state = {
            TrendingPackagesData: [],
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const TrendingPackageDataList = [
            {
                Title: "Untouched Manipur",
                ImageName: "5N-6D-manipur.webp",
                AllternateText: "...",
                Description: "2N Kohima | 1N Imphal | 1N Moreh | 1N Imphal | Private Transfer | Deluxe Hotel  ",
                PricePerNight: "24,990",
                NoOfTours: 47,
                NoOfDeparture: 40,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9163676037'
            },
            {
                Title: "Peaceful Meghalaya",
                ImageName: "6N-7D-meghalaya.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 3N Shillong | 1N Guwahati | Private Transfer | Deluxe Hotel",
                PricePerNight: "22,800",
                NoOfTours: 43,
                NoOfDeparture: 38,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9163676037'
            },
            {
                Title: "Unexplored Manipur",
                ImageName: "7N-8D-manipur.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 2N Kohima | 3N Imphal | Private Transfer | Deluxe Hotel",
                PricePerNight: "27,900",
                NoOfTours: 44,
                NoOfDeparture: 37,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9163676037'
            },

            {
                Title: "Extended Meghalaya",
                ImageName: "8N-9D-meghalaya.webp",
                AllternateText: "...",
                Description: "2N Kaziranga | 3N Shillong | 1N Cherrapunjee...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "33,700",
                NoOfTours: 46,
                NoOfDeparture: 39,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9163676037'
            },
            {
                Title: "Glimpses of North East",
                ImageName: "10N-11D-sangti-vally.webp",
                AllternateText: "...",
                Description: "2N Guwahati | 1N Kaziranga | 1N Bhalukpong...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "28,300",
                NoOfTours: 44,
                NoOfDeparture: 37,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9163676037'
            },
            {
                Title: "Extended North East",
                ImageName: "11N-12D-madhuri.webp",
                AllternateText: "...",
                Description: "2N Guwahati | 3N Shillong | 1N Kaziranga...more | Private Transfer | Deluxe Hotel",
                PricePerNight: "53,899",
                NoOfTours: 47,
                NoOfDeparture: 40,
                Include: "EX-GAU",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9163676037'
            }

        ];

        const reviewersDataList = [
            {
                Name: "Gaurav & Priya",
                ImageName: "Gaurav-Priya.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Our honeymoon trip to Andaman with Adorable Vacation was nothing short of magical! From the moment we arrived, every detail was taken care of. The stunning beaches, candlelight dinners, and personalized excursions made our trip unforgettable. A special thanks to our travel agent, who was always available to assist us. We highly recommend Adorable Vacation for anyone looking to create beautiful memories!",
            },
            {
                Name: "Rahul & Sneha",
                ImageName: "Rahul-Sneha.webp",
                AllternateText: "...",
                Location: "@Bengaluru",
                Comment: "We had the most amazing honeymoon in Andaman, thanks to Adorable Vacation! The accommodations were luxurious, and the staff went above and beyond to ensure our comfort. The island hopping tours were breathtaking, especially the sunset at Radhanagar Beach. This trip exceeded our expectations, and we can’t wait to book our next adventure with them!",
            },
            {
                Name: "Karan & Anjali",
                ImageName: "Karan-Anjali.webp",
                AllternateText: "...",
                Location: "@Hyderabad",
                Comment: "Adorable Vacation made our honeymoon in Andaman a dream come true! Every moment was filled with joy and adventure. We loved exploring the pristine beaches and snorkeling in crystal-clear waters. The team’s attention to detail, from the welcome package to our romantic beach dinner, truly made our experience special. We can’t recommend them enough!",
            },
            {
                Name: "Vikram & Neha",
                ImageName: "Vikram-Neha.webp",
                AllternateText: "...",
                Location: "@Pune",
                Comment: "Our honeymoon trip to Andaman was a fairytale, all thanks to Adorable Vacation! The itinerary was perfectly planned, allowing us to relax and enjoy each destination. The beautiful resorts, combined with amazing service, made our stay truly luxurious. We loved the local cuisine and the intimate experiences arranged for us. It was a trip of a lifetime!",
            },
            {
                Name: "Arjun & Riya",
                ImageName: "Arjun-Riya.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "Thank you, Adorable Vacation, for an unforgettable honeymoon in Andaman! Every aspect of our trip was handled with care, from the warm welcome to the personalized surprises. We had the best time exploring the islands and relaxing on the beaches. The memories we created will last forever, and we can’t wait to plan our next journey with Adorable Vacation!",
            },
            
        ]

        this.setState({
            TrendingPackagesData: TrendingPackageDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Andaman Package Tour from Kolkata– BEST OFFER FROM 2024</title>
                    <meta name="description" content="We offer best deal for Andaman Package Tour From Kolkata. For booking please ☎ CALL +91-91636-76037" />
                </Helmet>
                <MainHeader />
                <Andaman_7N_8D_Package_Tour_Kolkata
                    TrendingPackagesData={this.state.TrendingPackagesData}
                    ReviewersData={this.state.ReviewersData}
                />
                <FooterDesktop />
            </>
        );
    }
}

export default Andaman_7N_8D_Package_Tour_Kolkata_Page;