import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Bhutan/InclusionExclusion";
import InclusionExclusion from "../Bhutan/InclusionExclusion";

class MumbaiToBhutanPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: 'tab1',
      daywiseActiveTab: 'day1',
      currentPage: 1,
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  handleDayWiseTabClick = (tab) => {
    this.setState({
      daywiseActiveTab: tab,
    });
  };
  handleClick1 = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919883359713&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
  handlePageChange = (direction) => {
    this.setState((prevState) => {
      const newPage = prevState.currentPage + direction;
      return { currentPage: newPage };
    });
  };
  render() {
    const rows = [
      { date: '24th Sept - 30th Sept', availability: 'Available', price: '84,500' },
      { date: '01st Oct - 07th Oct', availability: 'Available', price: '84,500' },
      { date: '04th Oct - 10th Oct', availability: 'Available', price: '84,500' },
      { date: '08th Oct - 14th Oct', availability: 'Available', price: '84,500' },
      { date: '11th Oct - 17th Oct', availability: 'Available', price: '84,500' },
      { date: '15th Oct - 21st Oct', availability: 'Available', price: '84,500' },
      { date: '18th Oct - 24th Oct', availability: 'Available', price: '84,500' },
      { date: '22nd Oct - 28th Oct', availability: 'Available', price: '84,500' },
      { date: '25th Oct - 31st Oct', availability: 'Available', price: '84,500' },
      { date: '29th Oct - 04th Nov', availability: 'Available', price: '84,500' },
      { date: '1st Nov - 07th Nov', availability: 'Available', price: '84,500' },
      { date: '05th Nov - 11th Nov', availability: 'Available', price: '84,500' },
    ];

    const rowsPerPage = 6;
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
    const { customActiveTab, daywiseActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/Bhutan-Tour-Packages-from-Mumbai-with-Nonstop-Flight.webp";
    const packageTopBanner2ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/Bhutan-Group-Tour-Packages-from-Mumbai.webp";
    const bhutanTravelAgentImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/Bhutan-Travel-Agents.webp";
    const sixSenseBhutanTourPackagesImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-with-hotel-six-senses.webp";
    const amanKoraBhutanPackagesImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-with-hotel-aman-kora.webp";
    const flightBhutanPackages1ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/Bhutan-Dirict-Flight-on-Tuesday-Monday.webp";
    const flightBhutanPackages2ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/Bhutan-Dirict-Flight-on-Friday-Thursday.webp";
    const flightBhutanPackagesmobile0ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/Mumbai-to-Bhutan-Flight-Every-Tuesday.webp";
    const flightBhutanPackagesmobile1ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/Bhutan-to-Mumbai-Flight-Every-Monday.webp";
    const flightBhutanPackagesmobile2ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/Mumbai-to-Bhutan-Flight-Every-Friday.webp";
    const flightBhutanPackagesmobile3ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/Bhutan-to-Mumbai-Flight-Every-Thursday.webp";
    const sightseen1ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-tour-from-mumbai.webp";
    const sightseen2ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-tour-package-from-mumbai-airport.webp";
    const sightseen3ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-tour-package-from-mumbai-flight.webp";
    const sightseen4ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-tour-packages-from-mumbai.webp";
    const sightseen5ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/Places-of-attractions-in-Bhutan.webp";
    const sightseen6ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/BEST-TIME-TO-BOOK-A-PUNE-TO-BHUTAN-TOUR-PACKAGES.webp";
    const navDay1ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-tour-package-from-mumbai-and-transfer-to-thimphu.webp";
    const navDay2ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-booking-from-mumbai-city-airport-explore-buddha-dordenma.webp";
    const navDay3ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/with-bhutan-package-tour-from-mumbai-with-direct-flight-explore-dochula-pass.webp";
    const navDay4ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-and-explore-phobjhika-valley.webp";
    const navDay5ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/with-bhutan-packages-from-mumbai-explore-punkha-suspension-bridge.webp";
    const navDay6ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-booking-from-mumbai-and-explore-tigers-nest-monastery.webp";
    const navDay7ImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-cost-from-mumbai-and-explore-chele-la-pass.webp";

    const hotPackagesDataList = this.props.HotPackagesData;
    const hotPackagesView = hotPackagesDataList.length > 0 ? (
      hotPackagesDataList.map((dataItem, i) => {
        const hotelImgURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/" + dataItem.ImageName;
        const packageURL = dataItem.Url;
        return (
          <div className="col-md-4" key={i}>
            <div className="card h-100">
              <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
              <div className="card-body">
                <h5 className="card-title">{dataItem.Title} | {dataItem.EXPARO}<span> &#8377; {dataItem.PricePerNight}/-</span></h5>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text">{dataItem.Description}</p>
                <p>
                  <span>
                    <b className="tomato">{dataItem.NoOfTours} </b>Tours
                  </span>{" "}
                  |
                  <span>
                    <b className="tomato">{dataItem.NoOfDeparture} </b>
                    Departures
                  </span> |
                  <span><b className="sdf-blue"> {dataItem.SDFInclude}</b></span>
                </p>
              </div>
              <div className="card-footer">
                <small className="text-muted">{dataItem.MutedText}</small>
                <Link
                  to={packageURL}
                  target="_blank"
                  className="btn btn-Warning btn-sm float-end"
                >
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div></div>
    );

    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    // const hotelDataList = this.props.HotelsData;
    // const hotelDataView = hotelDataList.map((hotelItem, i) => {
    //   const hotelImageURL =
    //     ApiURL.BhutanTourPackageFromMumbaiImageURL + "/" + hotelItem.ImageName;
    //   return (
    //     <div className="col-md-3" key={i}>
    //       <img
    //         src={hotelImageURL}
    //         className="rounded mx-auto d-block"
    //         alt={hotelItem.AllternateText}
    //       />
    //       <br />
    //       <span>
    //         <b>{hotelItem.HotelName}</b> |{" "}
    //         <span className="tomato">{hotelItem.HotelStar}</span>
    //       </span>
    //       <br />
    //       <span className="rating-box">
    //         <span className="star">{hotelItem.RatingBoxStar}</span>
    //         <b className="rating-value">{hotelItem.RatingValue}</b>
    //       </span>{" "}
    //       &nbsp;<b>{hotelItem.Reviews}</b>
    //     </div>
    //   );
    // });

    const themePackageSliderList = this.props.ThemePackagesData;
    const themePackagesDataView = themePackageSliderList.map(
      (sliderItem, i) => {
        const sliderImageURL =
          ApiURL.BhutanTourPackageFromMumbaiImageURL +
          "/" +
          sliderItem.ImageName;
        return (
          <div className="theme-cards-wrapper mx-2" key={i}>
            <div className="theme-card">
              <div className="image-wrapper">
                <img src={sliderImageURL} alt={sliderItem.AllternateText} />
              </div>
              <div className="card-body">
                <h5 className="card-title">{sliderItem.Title}</h5>
                <p className="card-text">{sliderItem.Description}</p>
                <a href="#" className="btn btn-success btn-sm" onClick={this.handleClick1}>
                  <i className="fab fa-whatsapp"></i> WhatsApp
                </a>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        );
      }
    );

    const trendingBhutanDataList = this.props.TrendingBhutanDataList;
    const trendingBhutanDataView = trendingBhutanDataList.map(
      (sliderItem, i) => {
        const trendingBhutanImgURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/" + sliderItem.ImageName;
        const contactURL = sliderItem.Url;
        return (
          <div className="theme-cards-wrapper mx-2" key={i}>
            <div className="theme-card">
              <div className="image-wrapper">
                <img src={trendingBhutanImgURL} alt="..." />
              </div>
              <div className="card-body">
                <h6 className="card-title">{sliderItem.Title}<span> &#8377; {sliderItem.PricePerNight}/-</span></h6>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text"><span>{sliderItem.Description}</span></p>
                <p>
                  <span><b className="tomato">{sliderItem.NoOfTours} </b>Tours</span> |
                  <span><b className="tomato">{sliderItem.NoOfDeparture} </b> Departures</span> |
                  <span><b className="sdf-blue"> SDF Inc.</b></span>
                </p>
                <div className="card-footer">
                  <small className="text-muted"><strong className="tomato">EX-IXB</strong></small>
                  <a href={contactURL} className="btn btn-Warning btn-sm float-end">Call Now</a>
                </div>
              </div>
            </div>
          </div>
        );
      }
    );

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL =
        ApiURL.BhutanTourPackageFromMumbaiImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container">
                    <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center><strong>Bhutan Package Tour from Mumbai</strong> Return Flight</center></h1>
                    <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Bhutan Package Tour from Mumbai</strong> Return Flight</center>
                      <hr />
                    </h3>
                    <div className="row">
                      <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                        3 Cities:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Thimphu
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Paro
                        </a>{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Punakha
                        </a>
                      </div>
                      <div className="reviews mx-5">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                    <p className="p-2">
                      If you are looking for a memorable <strong>Bhutan Tour from Mumbai</strong>, you have come to the right place!
                    </p>
                    <hr />
                    <br />
                    <h2 className="content-desktop p-2" style={{ fontSize: "1.4vw" }}> FOR BOOKING <strong>BHUTAN TOUR PACKAGES FROM MUMBAI</strong> <a href='tel:9883359713' class="tomato">☎ CALL Mr. SOUMALYA +91-98833-59713</a></h2>
                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}>FOR BOOKING <strong>BHUTAN TOUR PACKAGES FROM MUMBAI</strong> <a href='tel:9883359713' class="tomato">☎ CALL Mr. SOUMALYA +91-98833-59713</a></h2>
                    <br />
                    <div className="card">
                      <div
                        className="card-header custHeaer"
                      >
                        <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> <b>FLIGHT SCHEDULE FOR MUMBAI TO PARO DIRECT</b> - <strong>NON-STOP FLIGHT</strong></h3>
                        <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>  <b>FLIGHT SCHEDULE FOR MUMBAI TO PARO DIRECT</b> - <strong>NON-STOP FLIGHT</strong></h4>
                      </div>
                      <div className="card-body">

                        <table className="table table-bordered">
                          <tr className="table-danger">
                            <th>TRAVEL DATE</th>
                            <th>SECTOR</th>
                            <th>DEPART TIME</th>
                            <th>ARRIVAL TIME</th>

                          </tr>
                          <tr>
                            <td>JOURNRY DATE : 05th NOV, TUESDAY</td>
                            <td>B3-718 - BOM - PBH</td>
                            <td>08:35 AM</td>
                            <td>12:20 PM</td>
                          </tr>
                          <tr>
                            <td>RETURN DATE : 11th NOV</td>
                            <td>B3-717 - PBH - BOM</td>
                            <td>15:15 PM</td>
                            <td>19:05 PM</td>
                          </tr>
                        </table>
                        <br /><br />

                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <br /><br />
              <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> INDIA'S LARGEST <strong>BHUTAN TOUR OPERATOR IN MUMBAI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>  INDIA'S LARGEST <strong>BHUTAN TOUR OPERATOR IN MUMBAI</strong></h4>
                </div>
                <div className="card-body">
                  <p>Welcome to Bhutan - "Kuzu Zangpo La"!</p>
                  <p>Are you feeling exhausted from the daily grind of work and traffic? Do you dream of escaping into nature and experiencing ancient cultures? If so, our <strong>Bhutan package tour from Mumbai</strong> is perfect for you! Explore Bhutan like never before, where every moment celebrates its stunning natural beauty, rich culture, and pursuit of happiness. Bhutan, a Buddhist Kingdom, has a magical charm that touches your soul, offering peace and happiness amidst hectic schedules. Don't wait—book your <strong>Bhutan tour package from Mumbai</strong> now and immerse yourself in luxury and tranquillity, far from the hustle and bustle of Mumbai's chaos.</p>
                  <p>Nestled in the majestic Himalayas, Bhutan is known for its pristine beauty and spiritual tranquillity. Our <strong>Bhutan tour packages from Mumbai</strong> give you a rare chance to discover this enchanting kingdom, where lush valleys, snowy mountains, and sparkling rivers create breath-taking landscapes at every turn.</p>
                  <p>Bhutan is a unique country celebrated for its stunning scenery, rich culture, and dedication to preserving its environment and traditions. If you're considering <strong>Bhutan tour packages from Mumbai with flights</strong>, Adorable Vacation offers several options. As a leading <strong>Bhutan travel agency in Mumbai</strong>, Adorable Vacation provides unparalleled expertise, ensuring top accommodations and services for discerning travellers from across India. Known for its excellent service throughout Bhutan, Adorable Vacation has quickly become the most trusted name for <strong>Bhutan tours from Mumbai</strong>, offering competitive prices and group packages. Let's explore the wonders of Bhutan together.</p>
                  <p>In Bhutan, happiness isn't just a goal but a way of life. As the only country that measures success by Gross National Happiness, Bhutan offers a refreshing perspective on what truly matters. Our <strong>Bhutan tour from Mumbai</strong> allows you to delve into this philosophy, interact with friendly Bhutanese people, and discover the secrets to genuine happiness.</p>
                  <p>Escape the hustle and bustle of city life and rejuvenate your mind, body, and soul in Bhutan's unspoiled environment. With its lush forests, fresh air, and peaceful atmosphere, Bhutan is ideal for yoga, meditation, and spiritual renewal. Let go of stress as you connect with nature and find inner peace in this land of the thunder dragon.</p>
                  <p>Our <strong>Bhutan package tour from Mumbai</strong> includes exclusive opportunities for yoga and meditation retreats led by experienced instructors. Whether you're a seasoned yogi or new to meditation, Bhutan's serene landscapes and spiritual energy provide the perfect setting to awaken your senses, calm your mind, and refresh your spirit.</p>
                  <p>Don't miss the chance to embark on a transformative journey to Bhutan—the land of happiness and harmony. With our carefully crafted <strong>Bhutan package from Mumbai</strong>, you'll create memories to cherish forever as you explore this magical destination. Discover Bhutan with Adorable Vacation, your premier <strong>Bhutan DMC in Mumbai</strong>. We specialize in <strong>Bhutan tours from Mumbai</strong>, offering luxurious direct chartered flights from Mumbai to Paro, making your journey comfortable and memorable. While there's no regular Mumbai to Paro flight, our special non-stop chartered flights ensure a premium travel experience for Bhutan-bound guests. Come, explore, and embark on an unforgettable <strong>Bhutan tour package from Mumbai</strong> with Adorable Vacation LLP – your premier <strong>Bhutan tour guide from Mumbai</strong>.</p>
                  <p>In addition to that <b>Mumbai to Bhutan Packages,</b> Adorable vacation LLP- the <b>Best Bhutan DMC in India</b> also offers personalized and flexible <a href="/mumbai-special-chartered-flight" target="_blank"><b className="tomato">Special Chertered Mumbai to Bhutan </b></a>,<a href="/bhutan-tour-package-from-pune" target="_blank"><b className="tomato">Bhutan Package Tour from Pune</b></a>,<a href="/bhutan-tour-from-delhi" target="_blank"><b className="tomato"> Bhutan Package Tour from Delhi</b></a>, <a href="/bhutan-tour-package-from-kolkata" target="_blank"><b className="tomato">Bhutan Package Tour from Kolkata</b></a>, <a href="/bhutan-tour-package-from-ahemdabad" target="_blank"><b className="tomato">Bhutan Package Tour from Ahmedabad</b></a>, <a href="/bhutan-tour-from-bangalore" target="_blank"><b className="tomato">Bhutan Package Tour from Bangalore</b></a>,<a href="/bhutan-tour-package-from-jaigaon" target="_blank"><b className="tomato"> Bhutan Package Tour from Jaigaon</b></a>, <a href="/bhutan-tour-package-from-chennai" target="_blank"><b className="tomato">Bhutan Package Tour from Chennai</b></a>, <a href="/bhutan-tour-package-from-bagdogra" target="_blank"><b className="tomato">Bhutan Package Tour from Bagdogra</b></a>,<a href="/bhutan-tour-package-from-surat" target="_blank"><b className="tomato">Bhutan Package Tour from Surat</b></a> at Best Price.</p>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen1ImageURL} alt="bhutan-tour-from-mumbai" />
                          <img src={sightseen2ImageURL} alt="bhutan-tour-package-from-mumbai-airport" />
                          <img src={sightseen3ImageURL} alt="bhutan-tour-package-from-mumbai-flight" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>
                    <strong>BHUTAN PACKAGE TOUR COST FROM MUMBAI</strong>
                  </h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BHUTAN PACKAGE TOUR COST FROM MUMBAI</strong></h3>
                </div>
                <div className="card-body">

                  <p>The <strong>Bhutan tour cost from Mumbai</strong> can vary based on several factors like how long the tour lasts, the type of hotels you stay, how many people are in your group, and when is your <strong>Bhutan travel Plan</strong>.
                  </p>
                  <p>Every visitor from <strong>Mumbai to Bhutan</strong> has to pay a minimum fee each day set by the Bhutanese government. This fee covers things like your place to stay, meals, transportation, and a licensed guide. The minimum daily fee for tourism in Bhutan was set in 2023 when the country opened again after closing due to COVID-19. Indian tourists need to pay ₹ 1,200 per person per night as a sustainable development fee which is mandatory for anyone looking for <strong>Bhutan package tour booking from Mumbai</strong>.</p>
                  <p>Some <strong>Bhutan packages from Mumbai with airfare</strong> might also include flights to and from Bhutan, which can vary in price, as well as visa fees, travel insurance, and personal expenses like souvenirs and drinks. Bhutan has a policy of "high value, low impact" tourism to support sustainable tourism and protect its culture and environment. The daily fee helps make sure tourism helps local communities and helps the country grow.</p>
                  <p>It's feasible to compare different <strong>Bhutan tour operators from Mumbai</strong> and what they offer to get the best deal. Costs might be higher during busy times, so it's good to book your <strong>Bhutan trip from Mumbai</strong> early to avoid paying more or not finding a place to stay in Bhutan.</p>
                  <br /> <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf" style={{ width: '100%', height: '50%' }}>
                      <thead className="cf">
                        <tr className="text-center">
                          <th colSpan="5">
                            <h4 style={{ fontSize: '1.3vw' }} className="card-header custHeaer content-desktop">
                              <strong>BHUTAN GROUP TOUR COST FROM MUMBAI</strong>
                            </h4>
                          </th>
                        </tr>
                        <tr className="table text-center">
                          <th>BHUTAN PACKAGES FROM MUMBAI</th>
                          <th>TOUR DURATION</th>
                          <th>ROOM CATEGORY</th>
                          <th>TOUR PRICE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="table text-center">
                          <td>Bhutan Package (MIN 6PAX)</td>
                          <td>6N/7D</td>
                          <td>3 STAR</td>
                          <td>34,399/-</td>
                        </tr>
                        <tr className="table text-center">
                          <td>Bhutan Tour (MIN 6PAX)</td>
                          <td>7N/8D</td>
                          <td>3 STAR</td>
                          <td>38,399/-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* <a href="tel://9883359713" className="btn btn-Warning">
                    Book Now
                  </a> */}
                </div>
              </div>
              <br /><br />
              {/* start short itinerary */}

              <div className="card">
                <div className="card-body">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="table-danger">
                        <th>DAY WISE SUMMARY FOR 7N/8D BHUTAN TOUR</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingOne"
                        >
                          <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                            <span>
                              WELCOME TO PARO AND TRANSFER TO THIMPHU
                            </span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingTwo"
                        >
                          <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                            <span>THIMPU LOCAL SIGHTSEEING</span>
                          </a>

                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingThree"
                        >
                          <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                            <span>THIMPHU TO PUNAKHA TRANSFER</span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingFour"
                        >
                          <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                            <span>EXCURSION TOUR TO GANGTEY/ PHOBJIKHA</span>
                          </a>

                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingFive"
                        >
                          <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                            <span>PUNAKHA / WANGDUE TO PARO</span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingSix"
                        >
                          <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                            <span>PARO LOCAL SIGHT-SEEING / TAKTSANG MONASTERY HIKING / KYICHU LHAKHANG</span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingSeven"
                        >
                          <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                            <span>EXCURSION TOUR TO CHELE LA PASS </span>
                          </a>

                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingTen"
                        >
                          <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                            <span>PARO TO AIRPORT DROP FOR MUMAI TRANSFER</span>
                          </a>

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <!--end Short description--> */}

              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>HOW TO REACH BHUTAN FROM MUMBAI?</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>HOW TO REACH BHUTAN FROM MUMBAI?</strong></h4>
                </div>
                <div className="card-body">
                  <p><b>OPTION 1: DIRECT NON-STOP FLIGHT FROM MUMBAI TO PARO</b></p>
                  <p>We're excited to announce that Adorable Vacation will be offering a direct non-stop chartered flight from Mumbai to Paro in May 2025 again. This special flight is eagerly awaited by Mumbaikars who wish to explore Bhutan, the Land of Happiness. Don't miss this exciting opportunity! Come and join us with Adorable Vacation for an unforgettable <strong>Mumbai to Bhutan Trip</strong></p>
                  <br /><br />


                  <p><b>OPTION 2 : CONNECTING FLIGHT VIA KOLKATA AIRPORT</b></p>
                  <p>Bhutan Airlines operates direct flights from Kolkata Airport [CCU] to Paro International Airport [PBH] on regular basis. So, travellers from Mumbai can first arrive at Kolkata Airport and from there they can catch Kolkata to Paro direct Flight. Following are the flight schedule as published by Bhutan Airlines upto December 2024.</p>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>SECTOR</th>
                          <th>FLIGHT NO</th>
                          <th>DEP DAY</th>
                          <th>DEP TIME</th>
                          <th>ARR TIME</th>
                          <th>DURATION</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>CCU - PBH</td>
                          <td>B3 701</td>
                          <td>EVERYDAY</td>
                          <td>08:15am</td>
                          <td>09:55pm</td>
                          <td>01 hrs 10 mins</td>
                        </tr>
                        <tr>
                          <td>PBH - CCU</td>
                          <td>B3 700</td>
                          <td>EVERYDAY</td>
                          <td>10:35am</td>
                          <td>11:15pm</td>
                          <td>01 hrs 10 mins</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br /><br />
                  <p><b>OPTION 3 [BY AIR]:</b> <strong>CONNECTING FLIGHT VIA DELHI AIRPORT</strong></p>
                  <p>Druk Air [Royal Bhutan Airlines] operates Delhi to Paro direct Non-Stop Flight on every Sunday, Tuesday, Wednesday, Friday, Saturday. It's convenient and time saving options to fly directly Bhutan. The flight duration is merely 2.20 hrs. So any travellers looking for <strong>Mumbai to Bhutan trip</strong>, also can enter Bhutan via Delhi. Following are the Delhi to Paro flight schedule.</p>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>DEPARTURE</th>
                          <th>SECTOR</th>
                          <th>OUTWARD JOURNEY TIME</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Sunday</td>
                          <td>Delhi → Paro</td>
                          <td>Depart 10:55 - Arrive 14:35</td>
                        </tr>
                        <tr>
                          <td>Monday</td>
                          <td>Delhi → Paro</td>
                          <td>Depart 10:55 - Arrive 14:35</td>
                        </tr>
                        <tr>
                          <td>Wednesday</td>
                          <td>Delhi → Paro</td>
                          <td>Depart 10:55 - Arrive 14:35</td>
                        </tr>
                        <tr>
                          <td>Saturday</td>
                          <td>Delhi → Paro</td>
                          <td>Depart 10:55 - Arrive 14:35</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>DEPARTURE</th>
                          <th>SECTOR</th>
                          <th>INWARD JOURNEY TIME</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Sunday</td>
                          <td>Paro → Delhi</td>
                          <td>Depart 07:05 - Arrive 09:55</td>
                        </tr>
                        <tr>
                          <td>Monday</td>
                          <td>Paro → Delhi</td>
                          <td>Depart 07:05 - Arrive 09:55</td>
                        </tr>
                        <tr>
                          <td>Wednesday</td>
                          <td>Paro → Delhi</td>
                          <td>Depart 07:05 - Arrive 09:55</td>
                        </tr>
                        <tr>
                          <td>Saturday</td>
                          <td>Paro → Delhi</td>
                          <td>Depart 07:05 - Arrive 09:55</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <p><b>OPTION 4 :</b> <strong>BHUTAN TOUR BY ROAD VIA BAGDOGRA AIRPORT</strong></p>
                  <p>Bagdogra Airport [IXB] is located just the 170 KM (4 hrs approx) away from border town of Bhutan - Phuentsholing. Bagdogra Airport has well flight connectivity from Mumbai. So, Guests from Mumbai can easily fly to Bagdogra Airport from where Adorable Vacation offers its exclusive customized <strong>Bagdogra to Bhutan Package</strong>. This is one of the best way to <strong>travel Bhutan from Mumbai</strong>.</p>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>Airlines</th>
                          <th>Flight Number</th>
                          <th>City</th>
                          <th>Departure Time</th>
                          <th>Arrival Time</th>
                          <th>Travel Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>IndiGo</td>
                          <td>6E - 5305</td>
                          <td>Mumbai</td>
                          <td>8:05</td>
                          <td>10:50</td>
                          <td>2h 55m</td>
                        </tr>
                        <tr>
                          <td>Akasa Air</td>
                          <td>QP - 1385</td>
                          <td>Mumbai</td>
                          <td>11:30</td>
                          <td>14:25</td>
                          <td>2h 55m</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen4ImageURL} alt="Sightseeing 4" />
                          <img src={sightseen5ImageURL} alt="Sightseeing 5" />
                          <img src={sightseen6ImageURL} alt="Sightseeing 6" />

                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br />
              <div className="row py-5">
                <div className="col-md-12">
                  <div className="site-heading text-center">
                    <h2>
                      DAY WISE <strong><span>BHUTAN TOUR</span> PLAN FROM MUMBAI</strong>
                    </h2>
                    <h4>7 Nights 8 Days Bhutan Tour Itinerary from Paro</h4>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="accordion"
                  id="accordionPanelsStayOpenExample"
                >
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day1"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        DAY 01: WELCOME TO PARO & TRANSFER TO THIMPHU
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div className="accordion-body">
                        Welcome to Bhutan, the Land of Thunder Dragon for your much awaited <strong>Bhutan package tour from Mumbai</strong>. Bhutan is like a painter's dream, a colourful country surrounded by the Himalayas, a small dreamy paradise with vast green valleys, beautiful landscapes, rugged terrain, crystalline rivers, and happy people. Once the aircraft enters the Paro valley, look down to see the first glimpse of the Paro Dzong on the hillside overlooking Paro Chu (river) with Ta Dzong. Our representative will welcome you in a Bhutanese style with Tashi Khada (white scarf) and guide you at the airport, which marks the beginning of your <strong>Bhutan Tour from Mumbai</strong> with transfer to Thimphu (2334 m). Overnight stay at hotel in Thimphu.
                        <br /> <br />
                        <div className="content-desktop">
                          <center><img src={navDay1ImageURL} alt="bhutan tour package from mumbai and transfer to thimphu" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="bhutan tour package from mumbai and transfer to thimphu" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day2"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        DAY 02: FULL DAY THIMPHU SIGHTSEEING TOUR
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTwo"
                    >
                      <div className="accordion-body">
                        <p>
                          Explore the bustling city of Thimphu on Day 2 of your <strong>Bhutan tour from Mumbai</strong>. Thimphu, the capital of Bhutan, blends ancient traditions with modern life. Start your day with a visit to:
                        </p><br />
                        <p>
                          <b>The National Memorial Chorten (Thimphu Chorten)</b> : This is a place for evening prayers, built in memory of Bhutan's third King, His Majesty Jigme Dorji Wangchuk, known as "the father of modern Bhutan". Completed in 1974, it serves as a memorial to the late King and symbolizes peace and prosperity.
                        </p><br />
                        <p><b>The Clock Tower</b> : Located in the heart of Thimphu Town, this architectural marvel has four clock faces and intricate Bhutanese designs painted or engraved on wood. Dragons, revered in Bhutanese culture, adorn the tower, symbolizing the country's independence.</p><br />
                        <p><b>Sakyamuni Buddha (Buddha Dordenma)</b> : Situated atop Kuensel Phodrang hill, this 51.5-meter tall gilded bronze statue offers stunning views of the valley and is also known as Buddha Viewpoint.</p><br />
                        <p><b>Changangkha Lhakhang</b> : One of Thimphu's oldest temples, dating back to the 15th century and dedicated to Avalokiteshvara, the deity of compassion. It offers a panoramic view of Thimphu valley.</p><br />
                        <p><b>Trashi Chhoe Dzong</b> : This fortress on the banks of Wang Chhu River is the ceremonial site for Bhutan's fifth King and hosts the annual Tsechu festival. Built in 1641 and reconstructed in the 1960s using traditional Bhutanese methods, it showcases stunning architecture without nails or architectural plans.</p><br />
                        <p><b>Simply Bhutan</b> : During your <strong>Bhutan tour from Mumbai</strong>, visit Simply Bhutan, a project aimed at preserving Bhutanese culture and creating job opportunities. The museum showcases ancient Bhutanese architecture and offers interactive experiences, unlike traditional museums.</p>
                        <br />
                        <p>After exploring, you can take a leisurely stroll around Thimphu city and enjoy the rest of the evening at your own pace. Overnight stay at a hotel in Thimphu.</p>
                        <br /><br />
                        <div className="content-desktop">
                          <center><img src={navDay2ImageURL} alt="bhutan package tour booking from mumbai city airport explore buddha dordenma" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="bhutan package tour booking from mumbai city airport explore buddha dordenma" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day3"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 03: THIMPHU TO PUNAKHKA TRANSFER
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseThree"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingThree"
                    >
                      <div className="accordion-body">
                        <p>This is the 3rd day of your Bhutan tour package from Mumbai. Wake up in your cozy hotel room and enjoy a cup of tea with stunning view of Thimphu. After a delicious breakfast, head towards Punakha, Bhutan's former capital nestled between the Pho Chhu and Mo Chhu rivers. Punakha sits at 1,200 meters above sea level, offering a peaceful retreat from city life with a mild climate year-round. The valley is famous for its lush rice fields along the winding rivers, creating a beautiful natural landscape. In Punakha, explore these attractions:
                        </p><br />
                        <p>
                          <b>Punakha Dzong</b>: Known as "the palace of great happiness," this impressive fortress stands where the Pho Chhu and Mo Chhu rivers meet. Cross the arched wooden bridge to enter the dzong and immerse yourself in its serene atmosphere. Admire the intricate woodwork, vibrant murals, and the towering central watchtower, showcasing Bhutan's cultural and architectural heritage.
                        </p><br />
                        <p><b>Chimi Lhakhang</b>: Visit this sacred temple atop a hill dedicated to Lama Drukpa Kuenley, the "Divine Madman," known for his unconventional teachings. The temple and surrounding paddy fields offer a peaceful escape.</p><br />

                        <p><b>Kaja Throm (Market)</b>: In the evening, experience the lively atmosphere of Punakha's Kaja Throm market, inaugurated in 2023. Browse stalls filled with local handicrafts, textiles, and fresh produce, each item reflecting Bhutan's rich culture. Enjoy authentic Bhutanese food, live music, and interact with friendly locals to learn more about their way of life.</p><br />
                        <p>Enjoy your evening in Punakha as part of your <strong>Bhutan trip tour from Mumbai</strong> with Adorable Vacation, soaking in the local culture and natural beauty.</p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay3ImageURL} alt="with bhutan package tour from mumbai with direct flight explore dochula pass" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="with bhutan package tour from mumbai with direct flight explore dochula pass" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day4"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFour"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        Day 04: - PUNAKHA TO PHOBJIKHA DAY EXCURSION
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseFour"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFour"
                    >
                      <div className="accordion-body">
                        <p>Say a very Good Morning Punakha on your 4th day <strong>Bhutan trip from Mumbai</strong>. As the sun bathes the Land of Happiness in golden light, wake up to a peaceful morning surrounded by nature's embrace. Enjoy a warm cup of tea while taking in the lush green valley spread out before you. After a delightful breakfast, set off on an adventure to the enchanting Phobjikha Valley, perfect for nature lovers and birdwatchers.</p>
                        <p><strong>Phobjikha Valley:</strong> Experience the serene beauty of Phobjikha Valley, renowned for its diverse birdlife, especially the majestic Black-necked Cranes that visit during their migration. This valley is one of Bhutan's key wildlife sanctuaries, offering a serene escape from city life. Wander through its vast landscapes, known for breath-taking views and a tranquil atmosphere. Adventure enthusiasts can explore trekking and hiking trails that lead through untouched wilderness.</p>
                        <p>Following are the places and activities you can experience in Phobjikha Valley:</p><br />
                        <li>Visit Black-Necked Crane Information Centre</li><br />
                        <li>Explore Gangtey (Hilltop) Monastery </li><br />
                        <li>Take on a Gangtey Nature Trails</li><br />
                        <li>Try a Dotsho (Traditional Hot Stone Bath)</li><br />
                        <li>Visit Khewang Lhakhang / Kumbhu Lhakhang / Nyelung Dechenling</li><br />
                        <li>Experience Archery with Local People / Open Air Campsite / Picnic with Family
                          / Stay in Bhutanese Farm Stay / go for bird watching
                        </li><br />
                        <p>Later in the day, take a scenic drive through upper Punakha Valley, passing traditional villages and lush farms. Embark on a leisurely hike to Khamsum Yuelly Namgyal Chorten, a stupa built to promote peace by Ashi Tshering Yangdon Wangchuk. Perched atop a hill, it offers breath-taking views of the valley below.</p><br />
                        <p>In the evening, return to Punakha where you can enjoy a local cultural program at your hotel or indulge in Bhutanese cuisine. Embrace the serene vibes of nature and end your day with sweet dreams.</p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay4ImageURL} alt="bhutan package tour from mumbai and explore phobjhika valley" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="bhutan package tour from mumbai and explore phobjhika valley" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day5"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFive"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 05: PUNAKHA TO PARO TRANSFER & SIGHTSEEING
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseFive"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFive"
                    >
                      <div className="accordion-body">
                        <p>
                          Today marks the 5th day of your Mumbai to Bhutan trip. After a delicious breakfast in the beautiful Punakha Valley, you'll begin your trip to Paro, a charming town nestled among the stunning Himalayan foothills.
                        </p><br />
                        <p>Paro Valley sits at an elevation of 2,200 meters, enticing travellers with its natural beauty. Here, you'll find lush rice fields, deep valleys, and a clear river winding through the peaceful landscape. The majestic mountains, clear skies, and dense forests filled with birdsong create a mesmerizing first impression that will surely captivate you. Along the way to Paro, you'll have the opportunity to visit:</p><br />
                        <p><b>National Museum of Bhutan</b> : Explore Bhutanese culture at the National Museum, housed in the historic Ta-dzong building overlooking Rinpung Dzong. Marvel at a collection of paintings, bronze statues, and intricate crafts that highlight Bhutan's rich heritage.</p><br />
                        <p><b>Rinpung Dzong</b> : Step into history at this fortress built in 1646 by Shabdrung Ngawang Namgyal, Bhutan's first ruler. Known as "Paro Dzong," it serves as both an administrative center and a religious site. Admire the vibrant murals and sacred relics as you wander through its corridors.</p><br />
                        <p>
                          <b>Nya MeyZam Bridge</b> : This iconic bridge, known as "The Bridge with No Fish," connects Paro town to the dzong. According to local beliefs, seeing a fish in the river here is considered unlucky.
                        </p><br />
                        <p><b>Paro Airport View Point</b> :  Enjoy a panoramic view of Paro Valley and its surroundings, including the dzongs and monasteries scattered across the landscape.<br />
                          In Paro town itself, you can visit more dzongs (monasteries), observe monks in their red robes, join prayers, or relax by dipping your feet in the cool waters of Paro Chu.<br />
                          After a fulfilling day of exploration, you'll spend the night at a hotel in Paro.
                        </p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay5ImageURL} alt="with bhutan packages from mumbai explore punkha suspension bridge" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="with bhutan packages from mumbai explore punkha suspension bridge" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day6"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSix"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 06: PARO TAKTSANG MONASTERY HIKING
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseSix"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSix"
                    >
                      <div className="accordion-body">
                        <p>
                          As the sun rises over the stunning Paro Valley, awaken to the embrace of nature, surrounded by the breath-taking view of rivers and mountains stretching out before you. Enjoy a hearty breakfast, making sure to include protein to fuel your adventure to the Tiger's Nest Monastery, one of the highlights of your <strong>Bhutan trip from Mumbai</strong>.
                        </p>
                        <p><b>Taktsang Monastery</b> : Begin an exhilarating trek to this revered site, perched dramatically on a cliff 1,200 meters above the valley floor. Follow winding trails that offer stunning views of lush greenery, a sparkling river, and majestic mountains in the backdrop.</p>
                        <p>
                          After about three hours of trekking, arrive at Taktsang Monastery, also known as the Tiger's Nest. This monastery, founded by Guru Rinpoche in 747 CE and meticulously built in 1694, holds deep spiritual significance. As you ascend the final steps, take in panoramic vistas that showcase the monastery's beauty and grandeur.
                        </p>
                        <p>For those preferring an easier journey, consider a horse ride from the parking lot to the monastery. Feel the serenity of the surroundings as prayer flags gently sway along the path.</p>
                        <p>In the evening, indulge in a Traditional Hot Stone Bath (available for an additional fee). This Bhutanese experience is perfect for unwinding after a day of sightseeing, renowned for its health benefits including relief for joint pains, hypertension, stomach disorders, and arthritis, thanks to local herbs used in the bath. Enjoy your overnight stay in Paro, reflecting on the day's memorable experiences.</p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay6ImageURL} alt="bhutan package tour booking from-mumbai and explore tigers nest monastery" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="bhutan package tour booking from-mumbai and explore tigers nest monastery" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day7"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 07: EXCURSION TOUR TO CHELE-LA PASS
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>
                          Leaving the beautiful valley behind, we head towards Chela-La-Pass (3,998m) on 7th day of this amazing <strong>Bhutan tour package from Mumbai</strong>. After a delicious breakfast, embark on an adventure to Bhutan's highest motor able pass, offering stunning views of snow-covered peaks and lush valleys. Located just a few hours from Paro town, Chela-La-Pass is often referred to as the gateway to a miniature heaven.
                        </p>
                        <p>
                          As we drive along the winding mountain roads, immerse yourself in the natural symphony around you. Verdant green valley’s stretch out before us, accompanied by the melodious songs of birds. Majestic snow-capped mountains, including the revered peak of Mount Jomolhari (7,314 meters), dominate the horizon, their peaks reaching into the clear blue sky.
                        </p>
                        <p>On a clear day, the panoramic views from Chele La Pass are truly breathtaking. Marvel at the awe-inspiring beauty of Mount Jomolhari and the serene snow-capped ranges that surround you. As you reach the summit, the spectacular scenery will captivate you, leaving indelible memories.</p>
                        <p>After a full day of exploration, return to your hotel and soak in the essence of Bhutan, reflecting on the day's adventures with dreams of more to come.</p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay7ImageURL} alt="bhutan package tour cost from mumbai and explore chele la pass" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="bhutan package tour cost from mumbai and explore chele la pass" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day8"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTen"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        DAY 08:DEPARTURE
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTen"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTen"
                    >
                      <div className="accordion-body">
                        <p>
                          After enjoying a hearty breakfast, say goodbye to the captivating Kingdom of Bhutan, taking with you cherished memories from your unforgettable journey. As you head towards Paro Airport, reflect on the mantra "Life is fleeting, and the world is waiting to be explored" reminding yourself of the vast opportunities awaiting exploration.
                        </p>
                        <p>Think back on the cultural immersion, stunning landscapes, and deep tranquillity you discovered during your <strong>Bhutan trip from Mumbai</strong>. Let these experiences be a treasure, inspiring you to embrace each moment and continue exploring the richness of our world and the beauty of life's journey.</p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br /><br />

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">{<InclusionExclusion />}</div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DISTANCE & TRAVEL DURATION TO NOTE FOR <strong>BHUTAN TOUR PACKAGES FROM MUMBAI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DISTANCE & TRAVEL DURATION TO NOTE FOR <strong>BHUTAN TOUR PACKAGES FROM MUMBAI</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>PLACES FROM</th>
                          <th>PLACES TO</th>
                          <th>ROAD DISTANCES</th>
                          <th>TRAVEL DURATION</th>
                        </tr>
                      </thead>
                      <tbody><tr><td>Paro Airport</td><td>Thimphu</td><td>47 km</td><td>1 hrs 15 min</td></tr>
                        <tr><td>Thimphu</td><td>Punakha</td><td>73 Km</td><td>2 hrs 5 min</td></tr>
                        <tr><td>Punakha</td><td>Paro</td><td>115 km</td><td>3 hrs 10 min</td></tr>
                        <tr><td>Paro</td><td>Haa Valley</td><td>66 km</td><td>2 hrs 10 min</td></tr>
                        <tr><td>Bagdogra</td><td>Phuentsholing</td><td>180 km</td><td>4 hrs</td></tr>
                        <tr><td>Phuentsholing</td><td>Thimphu</td><td>163 km</td><td>4 hrs 30 min</td></tr>
                        <tr><td>Punakha</td><td>Phobjikha valley</td><td>79 km</td><td>2 hrs 30 min</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />

                  <br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TCB APPROVED HOTELS WE OFFER FOR <strong>BHUTAN TOUR PACKAGE FROM MUMBAI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>TCB APPROVED HOTELS WE OFFER <strong>BHUTAN TOUR PACKAGE FROM MUMBAI</strong></h4>
                </div>
                <div className="card-body">
                  <div className="overflow-table">
                    <table className="table table-bordered">
                      <tr className="table-primary text-center">
                        {/* <!-- Add the "text-center" className to center content --> */}
                        <th colspan="6">HOTEL CATEGORY - 3 STAR (TCB APPROVED)</th>
                      </tr>
                      <tr className="table-danger">
                        <th className="text-center">Destination</th>
                        <th className="text-center">Hotel Names</th>
                        <th className="text-center">Hotel Type</th>
                        <th className="text-center">No of Nights</th>
                        <th className="text-center">Meal Plan</th>
                      </tr>
                      <tr>
                        <td>Thimpu</td>
                        <td>Hotel Ugyan / Hotel Peaceful / Hotel Nordenma / Hotel Riverside Bhutan</td>
                        <td>3 Star</td>
                        <td>2N</td>
                        <td>Room with Breakfast & Dinner</td>
                      </tr>
                      <tr>
                        <td>Punakha</td>
                        <td>Hotel Khuru Khuru / Kingaling Resort & Spa / White Dragon / Punakha Residency</td>
                        <td>3 Star</td>
                        <td>2N</td>
                        <td>Room with Breakfast & Dinner</td>
                      </tr>
                      <tr>
                        <td>Paro</td>
                        <td>Lha Yhul Hotel / Tsheringma Resort / Hotel Gawaling / Tshongdu Boutique</td>
                        <td>3 Star</td>
                        <td>2N</td>
                        <td>Room with Breakfast & Dinner</td>
                      </tr>
                    </table>
                    <br /><br />
                    <table className="table table-bordered">
                      <tr className="table-primary text-center">
                        {/* <!-- Add the "text-center" className to center content --> */}
                        <th colspan="6">HOTEL CATEGORY - 4 STAR (TCB APPROVED)</th>
                      </tr>
                      <tr className="table-danger">
                        <th className="text-center">Destination</th>
                        <th className="text-center">Hotel Names</th>
                        <th className="text-center">Hotel Type</th>
                        <th className="text-center">No of Nights</th>
                        <th className="text-center">Meal Plan</th>
                      </tr>
                      <tr>
                        <td>Thimpu</td>
                        <td>Asura / Pelyang Boutique / Tashi Yid-Wong Grand</td>
                        <td>4 Star</td>
                        <td>2N</td>
                        <td>Room with Breakfast & Dinner</td>
                      </tr>
                      <tr>
                        <td>Punakha</td>
                        <td>Zhingkham / Rivervalley / Wangdue Ecolodge</td>
                        <td>3 Star</td>
                        <td>2N</td>
                        <td>Room with Breakfast & Dinner</td>
                      </tr>
                      <tr>
                        <td>Paro</td>
                        <td>Tashi Namgay / Khangkhu Resort / Thim Dorji / Paro Grand</td>
                        <td>3 Star</td>
                        <td>2N</td>
                        <td>Room with Breakfast & Dinner</td>
                      </tr>
                    </table>
                    {/* <table className="table table-bordered">
                                                        <tr className="table-primary text-center">
                                                            
                                                            <th colspan="6">PACKAGE PRICE IN 3 STAR CATEGORY HOTEL</th>
                                                        </tr>
                                                        <tr className="table-danger">
                                                            <th className="text-center">No of Pax</th>
                                                            <th className="text-center">Package Price</th>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">02 Adults</td>
                                                            <td className="text-center">57,900/- PP</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">
                                                                04 Adults
                                                            </td>
                                                            <td className="text-center">
                                                                44,600/- PP
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">
                                                                06 Adults
                                                            </td>
                                                            <td className="text-center">
                                                                39,900/- PP
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <br /> */}

                  </div>
                  {/* <div className="row row-cols-1 row-cols-md-4 g-4">

                    {hotelDataView}

                  </div> */}
                </div>
              </div>
              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Our Bhutan <span>Tour Packages</span>
                      </h2>
                      <h4>Trending Bhutan Trip from Mumbai</h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 row-cols-md-4 g-4">
                  {hotPackagesView}
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHY BOOK <strong>BHUTAN PACKAGE TOUR FROM MUMBAI</strong> WITH ADORABLE VACATION ?</h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY BOOK <strong>BHUTAN PACKAGE TOUR FROM MUMBAI</strong> WITH ADORABLE VACATION ?</h3>
                </div>
                <div className="card-body">
                  <p>
                    When it comes to booking your <strong>Bhutan tour packages from Mumbai</strong>, Adorable Vacation LLP stands out extraordinary for numerous reasons:
                  </p>
                  <p>
                    <b>Years of Expertise:</b> With more than 10+ years of experience in the travel industry, we have honed our skills in creating unforgettable journeys for our clients. Our expertise ensures that your <strong>Bhutan tour from Mumbai</strong> is seamless and truly memorable.
                  </p>
                  <p><b>Bhutan Experts:</b> Having our own office in Bhutan, located at Olakha, Thimphu sets us apart as true Bhutan experts. Our in-depth understanding of the country, its culture, and its hidden gems ensures that you experience the authentic essence of Bhutan during your <strong>Bhutan packages from Mumbai</strong> with Adorable Vacation.</p>
                  <p><b>Quality Accommodation:</b> We offer stays in some of the best hotels in Bhutan ranging from TCB approved 3 Star hotels to luxurious 5 Star property providing you with comfort and luxury throughout your journey. We work with some of the best hotels in Bhutan like Terma Linca Resort & Spa, Pemako, Le Méridien Hotels & Resorts, COMO by UMA, Six Senses, Dhensa Boutique Resort, RKPO Green Resort, Zhingkham Resort, Tashi Namgay Resort, and Hotel Vara etc.</p>
                  <p><b>Ethical and Responsible Tourism:</b> At Adorable Vacation LLP, we are committed to promoting sustainable tourism practices. By traveling with us, you can rest assured that your journey contributes positively to Bhutan’s environment and communities. Choose Adorable Vacation LLP for an extraordinary <strong>Bhutan tour from Mumbai Airport</strong> and immerse yourself in the <b>Land of Happiness</b>. Our TCB License No. 1051529 and registration with the Tourism Council of Bhutam assure you of our credibility and dedication to offering you the best travel experience. Let us be your trusted travel companion as you embark on this magical journey to the Land of Happiness.</p>
                  <p><b>Local Connections:</b> Our local team in Bhutan is dedicated to providing you with the best local guides, immersive experiences, and valuable insights into the country’s culture and traditions. Our experienced Bhutan tour manager takes pride in sharing his/her profound insights and ensuring that you have a deeper understanding of Bhutan’s way of life, values, and customs.</p>
                  <p><b>Personalized Itineraries:</b> At Adorable Vacation LLP, we believe in tailoring your journey to suit your preferences and interests. Our personalised <strong>Bhutan tour itineraries from Mumbai Airport</strong> guarantee that every moment of your <strong>Bhutan trip</strong> is unique and tailored just for you.</p>
                  <a href="tel://9883359713" className="btn btn-Warning">
                    <b>CALL NOW & TALK TO OUR EXPERT</b>
                  </a>
                </div>
              </div>
              <br /><br />

              <div className="container-fluid py-5">
                <div className="row placetoVisit">
                  <h2>7 BEST PLACES TO VISIT DURING <strong>BHUTAN PACKAGE TOUR FROM MUMBAI</strong></h2>
                  <br /><br />
                  <ul>

                    <p>
                      <strong>Punakha Dzong - </strong> The Punakha Dzong is
                      located in a serene surroundings. You have to cross the
                      Punakha river on a swinging bridge to reach the fort which
                      houses the monastery and the royal living quarters. The
                      whole place is very majestic and decorative. It is quite a
                      large space and it takes almost an hour to do even a quick
                      tour tour of the place. The doors, windows and the
                      courtyard are all so ornate and colourful. The prayer area
                      is very colourful and the statues are large sized and
                      realistic.<br /><br />
                    </p>

                    <p>
                      <strong>Paro Taktsang - </strong> If you google Bhutan,
                      it's impossible not to have come across an image of a
                      monastery perched precariously in the cliff side. Yes,
                      that's Paro Taktsang, also known as Tiger's Nest
                      monastery. If it is your first time there, you ought to
                      visit. According to legend, back in 8th century, Guru
                      Rinpoche (second Buddha) flew here on the back of a
                      tigress. Due to its location, the only way to reach up is
                      to hike or you could hire a horse for a major part of the
                      distance.<br /><br />
                    </p>
                    <p>
                      <strong>Buddha Dordenma - </strong> Great Buddha Dordenma
                      is a gigantic Shakyamuni Buddha statue in the mountains of
                      Bhutan celebrating the 60th anniversary of fourth king
                      Jigme Singye Wangchuck. The statue houses over one hundred
                      thousand smaller Buddha statues, each of which, like the
                      Great Buddha Dordenma itself, are made of bronze and
                      gilded in gold. The Great Buddha Dordenma is sited amidst
                      the ruins of Kuensel Phodrang, the palace of Sherab
                      Wangchuck, the thirteenth Desi Druk, overlooking the
                      southern approach to Thimphu, the capital of Bhutan.<br /><br />
                    </p>
                    <p>
                      <strong>Dochula (Pass) - </strong> Nestled in the eastern
                      edge of the Himalayas, the Kingdom of Bhutan boasts of
                      spectacular mountain views and some of the happiest people
                      in the world. And with this beautiful outlook of life came
                      the creation of Dochula Pass, a breath-taking mountain
                      pass, in commemoration of Bhutanese soldiers who passed
                      away in military operations for the country. Along the
                      Dochula Pass, there are 108 memorial chortens (also known
                      as stupas) called the 'Druk Wangyal Chortens' for each
                      soldier's life lost in war. The hills are also decorated
                      with colourful religious flags in five colours to
                      represent the natural elements: blue for the sky, red for
                      fire, green for water, white for clouds, and yellow for
                      earth. To the local Buddhist people, these flags are
                      symbols of veneration and the inscriptions of prayers on
                      each flag signals peace and prosperity for Bhutan.<br /><br />
                    </p>
                    <p>
                      <strong>Tashichho Dzong - </strong> Tashichho Dzong is a
                      fortress located about 2 km away from the city of Thimphu
                      in Bhutan. It had been constructed in 1641 and underwent
                      several restorations since then. It currently serves both
                      as a monastery and the central secretariat of the present
                      government. Also known as the "fortress of the glorious
                      religion", it is found right on the banks of the Wangchhu
                      River. Originally built in 1216, the original structure of
                      the Tashichho Dzong was destroyed in a fire, with the
                      current dzong structure being newly built. A lovely rose
                      garden in front of the building welcomes the visitors to
                      the fortress. The manicured garden surrounding the
                      building is a beautiful sight too. The tall building is a
                      prominent landmark of Thimphu with almost all sightseeing
                      tours of the city beginning with a visit to this beautiful
                      Dzong resplendent with a golden roof.<br /><br />
                    </p>
                    <p>
                      <strong>Chele La Pass - </strong> Bhutan, being a country
                      with a mountainous landscape, is linked with a series of
                      passes. Located at approximately 13,000 feet between the
                      valley of Paro and Haa, Chele La Pass is the highest
                      motorable road pass in Bhutan. The pass is famous for the
                      stunning Himalayan views it offers, especially Mt.
                      Jhomolari which is Bhutan’s most sacred peak at 22,000
                      feet, Tsherimgang mountains, Jichu Drake as well as views
                      of both the valleys, Paro and Haa. Just a two-hour drive
                      from the valley floor in Paro, you will reach Chele La
                      Pass, covered in untouched forests, home to thriving flora
                      and fauna. The surrounding area of this pass has several
                      ancient trails, perfect for hikers.<br /><br />
                    </p>
                    <p>
                      <strong>Pobjikha valley - </strong> Against the backdrop
                      of western slopes of the Black Mountains in Wangdue
                      Phodrang, lies the bowl-shaped Phobjikha valley that
                      offers breathtaking views of vast expanses of green
                      fields. It is home to the endangered black-necked crane
                      that migrates to this area during winters. Phobjikha
                      valley also called Gangtey, is one of the few glacial
                      valleys in the Kingdom of Bhutan. Bordering the Jigme
                      Singye Wangchuck National Park, the valley is located at
                      an altitude of 3000m above sea level, and hence
                      comparatively cooler. It boasts being the winter home to
                      black-necked cranes that fly from Tibet to escape the
                      harsh winter and also one of the most prominent
                      conservative sites of Bhutan. Relatively untouched by
                      outsiders, the use of electricity in the valley began only
                      a few years ago.<br /><br />
                    </p>

                  </ul>
                </div>
                <br />
                <div className="container-fluid py-5">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="site-heading text-center">
                        <h2>
                          Branded Bhutan Tour <span>- The SIX SENSE</span>
                        </h2>
                        <h4>
                          <i>Next Level Luxury Sense</i>
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="row py-3">
                    <img src={sixSenseBhutanTourPackagesImageURL} alt="bhutan package tour from mumbai with hotel six senses" />
                  </div>
                </div>
                <div id="no-more-tables">
                  <table className="col-md-12 table-bordered table-striped table-condensed cf">
                    <thead>
                      <tr className="table-danger">
                        <th>Packages</th>
                        <th>Duration</th>
                        <th>Hotel Brand</th>
                        <th>Price (Per Head)</th>
                        <th>Book Now</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Luxury Trip</td>
                        <td>5N/6D</td>
                        <td>
                          The SIX SENSE (Ultra Luxury)
                          <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                          </div>
                        </td>
                        <td>Rs 5,36,789/- </td>
                        <td><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick1}>
                          <i className="fab fa-whatsapp"></i> WhatsApp
                        </a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="card mx-0 my-5">
                  <div className="card-header custHeaer">
                    Six Sense Bhutan Packages - An Ultra Luxury Five Star Brand
                  </div>
                  <div className="card-body">
                    <p>
                      Adorable can adjust your itinerary depending on your needs
                      & preference. We are happy to arrange your itinerary to
                      fit your interests, allowing you to explore and enjoy the
                      Kingdom of Bhutan at your own peace.<br /><br />
                    </p>
                    <h3 className="tomato">Unbeatable GEM of Bhutan</h3>
                    <p>
                      Travel back in time and take a journey that few people
                      have had the opportunity to experience. Druk Yul, Bhutan’s
                      official name, is known as the Land of the Dragon People
                      with a rich cultural heritage that has largely remained
                      hidden until its recent emergence onto the world stage.{" "}
                      <b>Your Six Senses GEM (Guest Experience Maker)</b> will
                      meet you upon arrival and accompany you on your khamsa
                      ‘royal stroll’ through the Kingdom, sharing insights to
                      the culture, history and local life of each valley as you
                      go. Discover the country’s breathtaking natural beauty,
                      meet and mingle with our Bhutanese friends as you embrace
                      the philosophy of a nation that is guided by the
                      principles of ‘Gross National Happiness’. Our khamsas are
                      designed with just the right balance of meaningful
                      encounters and experiences in mind along with a healthy
                      dose of fun. The following is only a guideline. Your GEM
                      will help to tailor your 5-day Bhutan itinerary to your
                      needs and interests each day.
                    </p>
                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Service available at Paro, Punakha, Thimphu, Bumthang
                      and Gangtey.<br /><br /></h3>

                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b> Service available at Paro, Punakha, Thimphu, Bumthang
                      and Gangtey.</b></h3>
                    <a href="tel://9883359713" className="btn btn-Warning">
                      Book Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Branded Bhutan Tour <span>- The AMAN KORA</span>
                      </h2>
                      <h4>
                        <i>Stay with Trust</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row py-3">
                  <img src={amanKoraBhutanPackagesImageURL} alt="bhutan package tour from mumbai with hotel aman kora" />
                </div>
                <div id="no-more-tables">
                  <table className="col-md-12 table-bordered table-striped table-condensed cf">
                    <thead>
                      <tr className="table-danger">
                        <th>Packages</th>
                        <th>Duration</th>
                        <th>Hotel Brand</th>
                        <th>Price (Per Head)</th>
                        <th>Book Now</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Premium Trip</td>
                        <td>5N/6D</td>
                        <td>
                          The AMAN KORA (Premium)
                          <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                          </div>
                        </td>
                        <td>Rs 4,70,389/- </td>
                        <td><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick1}>
                          <i className="fab fa-whatsapp"></i> WhatsApp
                        </a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="card mx-0 my-5">
                  <div
                    className="card-header custHeaer"
                  >
                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>  AMAN KORA Bhutan Packages - A Super Premium Five Star Brand</h3>

                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>  AMAN KORA Bhutan Packages - A Super Premium Five Star Brand</b></h3>

                  </div>
                  <div className="card-body">
                    <p>
                      Adorable can adjust your itinerary depending on your needs
                      & preference. We are happy to arrange your itinerary to
                      fit your interests, allowing you to explore and enjoy the
                      Kingdom of Bhutan at your own peace.<br /><br />
                    </p>
                    <h3 className="tomato">Unbeatable GEM of Bhutan</h3>
                    <p>
                      Travel back in time and take a journey that few people
                      have had the opportunity to experience. Druk Yul, Bhutan’s
                      official name, is known as the Land of the Dragon People
                      with a rich cultural heritage that has largely remained
                      hidden until its recent emergence onto the world stage.{" "}
                      <b>Your Six Senses GEM (Guest Experience Maker)</b> will
                      meet you upon arrival and accompany you on your khamsa
                      ‘royal stroll’ through the Kingdom, sharing insights to
                      the culture, history and local life of each valley as you
                      go. Discover the country’s breathtaking natural beauty,
                      meet and mingle with our Bhutanese friends as you embrace
                      the philosophy of a nation that is guided by the
                      principles of ‘Gross National Happiness’. Our khamsas are
                      designed with just the right balance of meaningful
                      encounters and experiences in mind along with a healthy
                      dose of fun. The following is only a guideline. Your GEM
                      will help to tailor your 5-day Bhutan itinerary to your
                      needs and interests each day.
                    </p>

                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Service available at Paro, Punakha, Thimphu, Bumthang
                      and Gangtey.</h3>

                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b> Service available at Paro, Punakha, Thimphu, Bumthang
                      and Gangtey.</b></h3>

                    <a href="tel://9883359713" className="btn btn-Warning">
                      Book Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Our Bhutan <span>Tour Packages</span>
                      </h2>
                      <h4>Trending Bhutan Packages from Adorable Vacation</h4>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    id="carouselExampleControls"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <Slider {...settings}>{trendingBhutanDataView}</Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /> <br />
              <div className="container-fluid py-5">
                <div className="card">
                  <div
                    className="card-header custHeaer"
                  >
                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>Discover the Best Time to Book Bhutan Tour Package from
                      Mumbai with Adorable Vacation LLP</h3>

                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>Discover the Best Time to Book Bhutan Tour Package from
                      Mumbai with Adorable Vacation LLP</b></h3>

                  </div>
                  <div className="card-body">
                    <p>
                      Embarking on a Bhutan tour from Mumbai is a journey of a
                      lifetime, and choosing the right time to experience the
                      Land of Happiness is crucial to ensure a delightful and
                      hassle-free vacation. Adorable Vacation LLP presents you
                      with the ideal seasons to book your Bhutan package tour
                      from Mumbai, promising unforgettable memories and
                      immersive cultural experiences.<br /><br />
                    </p>
                    <span className="icon-img-color">
                      <i className="fa fa-sun-o" aria-hidden="true"></i>&nbsp;
                      <span className="black">
                        <strong>Summer Serenity (June to September)</strong>
                      </span>
                    </span>
                    <br />
                    <span>
                      <b>If you prefer sunny days and green landscapes,</b>{" "}
                      summer in Bhutan, from June to the end of September, is
                      the perfect time to visit. With temperatures ranging
                      between 20 to 25 degrees Celsius, the weather is warm with
                      a slight touch of humidity. Nights might get cold, making
                      it ideal to carry light woolen garments or jackets. The
                      surrounding cities and tourist spots are painted in lush
                      greenery, while the clear skies offer captivating views of
                      the snow-clad Himalayan peaks. Summer is a time to witness
                      the Haa Festival, celebrating Bhutanese food, culture,
                      tradition, and way of life. The lesser crowds during this
                      season allow you to explore various sightseeing spots in
                      tranquility, making it one of the best times to choose
                      Bhutan holidays from Mumbai.<br /><br />
                    </span>
                    <br />
                    <br />
                    <span className="icon-img-color">
                      <i className="fa fa-cloud" aria-hidden="true"></i>
                      &nbsp;
                      <span className="black">
                        <strong>Autumn Bliss (September to November)</strong>
                      </span>
                    </span>
                    <br />
                    <span>
                      <b>For a perfect Bhutan holiday tour from Mumbai,</b>{" "}
                      autumn takes center stage. The months of September to
                      November offer pleasant weather with temperatures ranging
                      from 10 to 15 degrees Celsius during the day, making
                      sightseeing and traveling comfortable. While nights might
                      be chilly, they are equally enjoyable. Autumn is the
                      festive season in Bhutan, where travelers can immerse
                      themselves in the authentic cultural heritage and witness
                      vibrant festivals like the Black-Necked Crane Festival,
                      Paro Tshechu, and Punakha Tshechu. Festivities come alive
                      with colorful flags fluttering in the wind, grand Thonkas,
                      and mesmerizing masked dances. To avoid peak season rush
                      and ensure availability of hotels and flight tickets,
                      Adorable Vacation LLP suggests booking your Bhutan package within
                      the summer season.<br /><br />
                    </span>
                    <br />
                    <br />
                    <span className="icon-img-color">
                      <i className="fa fa-snowflake-o" aria-hidden="true"></i>
                      &nbsp;
                      <span className="black">
                        <strong>Winter Wonder (December to February)</strong>
                      </span>
                    </span>
                    <br />
                    <span>
                      <b>
                        While winter in Bhutan can be harsh with rapidly
                        dropping temperatures due to the high Himalayan
                        altitude,
                      </b>
                      it still offers a unique charm. Cities like Thimphu, Paro,
                      and Punakha get covered under a thick blanket of snow,
                      creating a picturesque winter wonderland. The northern
                      parts experience sub-zero temperatures, leading to school,
                      college, and government office holidays. Despite the harsh
                      weather, winter festivals like Monggar Tshechu, Trashigang
                      Tshechu, Lhuntse Tshechu, and Trongsa Tshechu add a touch
                      of cultural grandeur. If you wish to experience the snow
                      and cold while witnessing these festivals, booking your
                      Bhutan package tour during winter is an extraordinary
                      choice. Winter in Bhutan brings forth its own
                      awe-inspiring charm!<br /><br />
                    </span>
                    <br />
                    <br />
                    <span className="icon-img-color">
                      <i className="fa fa-tree" aria-hidden="true"></i>
                      &nbsp;
                      <span className="black">
                        <strong>Spring Spectacle (March to May)</strong>
                      </span>
                    </span>
                    <br />
                    <span>
                      <b>After a long and cold winter,</b> Bhutan springs to
                      life during March to May. The blooming Jacaranda and
                      Rhododendron flowers cover the roads, valleys, and
                      surroundings in beautiful hues of purple, creating a
                      heavenly ambience. With little to no rain, the sky becomes
                      crystal clear, marking the beginning of world-famous treks
                      like Jomolhari and Drukpath. Spring is also a time for
                      some of the most auspicious festivals like Paro Tshechu,
                      Gasa Tshechu, Rhododendron festivals, Talo Tshechu, etc.,
                      often graced by the presence of the King of Bhutan
                      himself. During these months, Bhutan is usually packed
                      with tourists, making it advisable to book your Bhutan
                      package from Mumbai as early as possible, along with
                      flights, to avoid peak season surcharges and ensure
                      accommodation and flight availability.<br /><br />
                    </span>
                    <br />
                    <h5>TEMPERATURE IN BHUTAN EACH MONTH</h5><br />
                    <br />
                    <div id="no-more-tables">
                      <table className="col-md-12 table-bordered table-striped table-condensed cf">
                        <thead className="cf">
                          <tr className="table-danger">
                            <th>MONTH</th>
                            <th>THIMPHU</th>
                            <th>PARO</th>
                            <th>PUNAKHA</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>JANUARY</td>
                            <td>12.4 / -2.5°C</td>
                            <td>9.5 / -5.8°C</td>
                            <td>17 / 4.3°C</td>
                          </tr>
                          <tr>
                            <td>FEBRUARY</td>
                            <td>14.5 / 0.7°C</td>
                            <td>13 / 1.6°C</td>
                            <td>19 / 7.9°C</td>
                          </tr>
                          <tr>
                            <td>MARCH</td>
                            <td>16.7 / 3.9°C</td>
                            <td>14.5 / 0.6°C</td>
                            <td>22.8 / 10.4°C</td>
                          </tr>
                          <tr>
                            <td>APRIL</td>
                            <td>20 / 7.2°C</td>
                            <td>17.5 / 4.6°C</td>
                            <td>26.2 / 12.9°C</td>
                          </tr>
                          <tr>
                            <td>MAY</td>
                            <td>22.6 / 13.2°C</td>
                            <td>23.5 / 10.6°C</td>
                            <td>29.1 / 17.7°C</td>
                          </tr>
                          <tr>
                            <td>JUNE</td>
                            <td>24.5 / 15.3°C</td>
                            <td>25.4 / 14.2°C</td>
                            <td>29.2 / 20.1°C</td>
                          </tr>
                          <tr>
                            <td>JULY</td>
                            <td>25.8 / 15.5°C</td>
                            <td>26.8 / 14.9°C</td>
                            <td>30.4 / 20.6°C</td>
                          </tr>
                          <tr>
                            <td>AUGUST</td>
                            <td>25 / 15.7°C</td>
                            <td>25.3 / 14.7°C</td>
                            <td>29.1 / 20°C</td>
                          </tr>
                          <tr>
                            <td>SEPTEMBER</td>
                            <td>23.2 / 15°C</td>
                            <td>23.4 / 11.8°C</td>
                            <td>27.5 / 19.1°C</td>
                          </tr>
                          <tr>
                            <td>OCTOBER</td>
                            <td>21.8 / 10.5°C</td>
                            <td>18.7 / 7.4°C</td>
                            <td>26.1 / 14.3°C</td>
                          </tr>
                          <tr>
                            <td>NOVEMBER</td>
                            <td>17.8 / 5°C</td>
                            <td>13.8 / 1.4°C</td>
                            <td>22.6 / 9.6°C</td>
                          </tr>
                          <tr>
                            <td>DECEMBER</td>
                            <td>14.4 / -1.3°C</td>
                            <td>11.2 / -1.5°C</td>
                            <td>19.1 / 6.2°C</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
              <br /> <br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4>Bhutan Trip Related Questions</h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          Is Bhutan visa free for Indian?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, Indian citizens do not require a visa to enter
                          Bhutan.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          How can I go to Bhutan from India?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          You can take a flight to Bagdogra (IXB). After that
                          enter to Bhutan by road via PHUENTSHOLING. This will
                          be an economical option. In terms of luxury option,
                          you can avail Direct Non Stop flight to PARO (PBH)
                          airport. Operated from three major cities DELHI,
                          KOLKATA & GUWAHATI.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          Do Bhutanese Speak English?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Dzongkha is the national language of the Bhutan, which
                          is predominantly spoken in the western region of
                          Bhutan. However, there are 25 dialects spoken in
                          Bhutan. Bhutanese people speak English as it is the
                          medium of instruction in the schools. Hence it is easy
                          to talk to Bhutanese people if you are fluent in
                          English. The official documents, road signs, and even
                          the major national newspapers are published in English
                          making it easier for tourists to understand.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          Is Alcohol cheaper in Bhutan?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, alcohol is cheaper in Bhutan, and the locally
                          made alcohol is good in taste, and these beverages act
                          as a great souvenir for the boozers. Do try out Ara,
                          the traditional hot alcoholic beverage consumed by the
                          majority of the population and is made of rice,
                          barley, wheat, or millet, and herbs which is either
                          fermented or diluted.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          Can I use Indian money in Bhutan Tour from Mumbai?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, you can carry Indian Note to Bhutan. Except Rs
                          2000/- Note, which is not a legal tender in Bhutan?
                          However, many merchants in Bhutan now days accept Rs
                          2000/- Note.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          How are the Bhutan Mobile / Internet Connectivity?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          ndian Mobile Operators provide the Global Roaming
                          Connectivity in Bhutan, However, it is expensive.
                          Please check with your operator about Global Roaming
                          Activation / Incoming and Out Going Charges. Tourists
                          can easily get the Bhutan Tourists SIM card (Tashi
                          Cell and BMobile ) at Rs 200/- with data option at Rs
                          99/- for 400 MB data.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeven"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeven"
                        >
                          Can I get wine in Bhutan including imported Brands?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSeven"
                        className="accordion-collapse collapseShow"
                        aria-labelledby="flush-headingSeven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          In Paro Duty free shop, you can get various brands of
                          imported foreign Whisky, Wine, Vodka, Beer etc and
                          price is relatively cheaper compared to any Indian
                          Duty Free shop. In Thimphu, you can get the imported
                          wine brand, however in Paro and Punakha, you may get
                          the imported brands only in your hotel, not in any off
                          shop. However, in Bhutan, availability of local brand
                          wines is abundant and they are really worthy to buy.
                          While you are in Bhutan Package Tour, you must buy
                          Local Peach Wine and am confident you will love it.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Bhutan <span className="tomato">Theme Package</span>
                        </b>
                      </h3>
                      <h4>Similar Mountain Trip</h4>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    id="carouselExampleControls"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <Slider {...settings}>{themePackagesDataView}</Slider>
                      </div>
                    </div>
                  </div>

                  <br />
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Bhutan <span className="tomato">Tour Reviews</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Your Experience is our Marketing"</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default MumbaiToBhutanPackage;
