import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ApiURL from "../../../api/ApiURL";

class IndividualIntervalsExample extends Component {
    
  render() {
    const flightnev1ImageURL = ApiURL.BhutanTourPackageViaKolkataAirportImageURL + "/bhutan-package-tour-from-bangalore-with-flight-via-kolkata-airport.webp";
    const flightnev2ImageURL = ApiURL.BhutanTourPackageViaKolkataAirportImageURL + "/bangalore-to-bhutan-tour-by-flight-from-kolkata-airport.webp";
    const sixsenses1ImageURL = ApiURL.BhutanTourPackageViaKolkataAirportImageURL + "/bhutan-package-tour-from-bangalore-with-hotel-six-senses-thimphu.webp";
    const sixsenses2ImageURL = ApiURL.BhutanTourPackageViaKolkataAirportImageURL + "/bhutan-package-tour-from-bangalore-with-flight-and-hotel-six-senses-thimphu.webp";
    const Amankora1ImageURL = ApiURL.BhutanTourPackageViaKolkataAirportImageURL + "/bhutan-package-tour-from-bangalore-with-flight-and-hotel-amankora-thimphu.webp";
    const Amankora2ImageURL = ApiURL.BhutanTourPackageViaKolkataAirportImageURL + "/bhutan-package-tour-booking-from-bangalore-with-hotel-amankora-thimphu.webp";
    const Sightseen1ImageURL = ApiURL.BhutanTourPackageViaKolkataAirportImageURL + "/bhutan-tour-package-from-bangalore-with-direct-flight-from-kolkata.webp";
    const Sightseen2ImageURL = ApiURL.BhutanTourPackageViaKolkataAirportImageURL + "/bhutan-tour-package-from-bangalore-with-flight-fare.webp";
    return (
      <Carousel fade>
        <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src={flightnev1ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
            <h3 className='crousel-slide-h3'>Bhutan Tour from Bangalore</h3>
            {/* <p className='carousel-text-p'>Fly directly from Bangalore to Bhutan for a faster, more convenient travel experience</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={sixsenses1ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
          <h3 className='crousel-slide-h3'>Bhutan Tour with Hotel Six Senses</h3>
          {/* <p className='carousel-text-p'>Enjoy luxury at Hotel Six Senses with stunning views and serene comfort</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block w-100"
          src={Amankora1ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
          <h3 className='crousel-slide-h3'>Bhutan Tour with Hotel Amankora</h3>
          {/* <p className='carousel-text-p'>Stay at Hotel Aman Kora for a blend of luxury, tradition, and tranquility in Bhutan</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block w-100"
          src={Sightseen1ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
          <h3 className='crousel-slide-h3'>Bhutan Trip from Bangalore</h3>
          {/* <p className='carousel-text-p'>Enjoy a seamless, comfortable journey to Bhutan with a direct flight from Bangalore</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src={flightnev2ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
            <h3 className='crousel-slide-h3'>Bhutan tour from Bangalore with Flight</h3>
            {/* <p className='carousel-text-p'>Fly hassle-free to Bhutan with a direct flight from Bangalore for a quick, smooth journey</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={sixsenses2ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
          <h3 className='crousel-slide-h3'>Luxury Stay with Hotel Six Senses, Bhutan</h3>
          {/* <p className='carousel-text-p'>Enjoy a lavish stay at Hotel Six Senses with world-class amenities and Himalayan views</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block w-100"
          src={Amankora2ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
          <h3 className='crousel-slide-h3'>Bhutan Trip with Amankora, Thimphu</h3>
          {/* <p className='carousel-text-p'>Aman Kora offers an exclusive retreat, blending Bhutanese culture with luxurious hospitality</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block w-100"
          src={Sightseen2ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
          <h3 className='crousel-slide-h3'>Bhutan Tour from Bangalore</h3>
          {/* <p className='carousel-text-p'>Enjoy a quick, non-stop flight from Bangalore to Bhutan for a smooth journey</p> */}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default IndividualIntervalsExample;
