import React, { Component } from 'react';
import ApiURL from "../../api/ApiURL";

class HotPackageTour extends Component {
    constructor() {
        super();
        this.state={
          HotPackagesData:[],
          slidesToShow: 4
        }    
      }
    
      componentDidMount() {       
        const hotPackagesDataList = [
            {
                ImageName: "Bhutan-Bike-Trip.webp",
                AllternateText: "...",
                Description: "Bhutan, known as the Paradise of Biker, and hidden paradise Himalayan ranges. It is unsurpassed in raw natural beauty.",
                Title: "Bike Trip",
                PricePerNight: "29,399/Person",
                MutedText: "6N/7D"
            },
            {
                ImageName: "egyptside.webp",
                AllternateText: "...",
                Description: "Egypt stimulates the imagination of western tourists like few other countries is probably one of the most popular tourist destinations world-wide.",
                Title: "Egypt Tour",
                PricePerNight: "98,499/Person",
                MutedText: "6N/7D",
                url:"/egypt-package-tour-from-kolkata-8night-9days"
            },
            {
                ImageName: "Sandakphu.webp",
                AllternateText: "...",
                Description: "The Unforgettable View of Four of the World's Tallest Mountains. There is nothing else like the Sandakphu Phalut Trek.",
                Title: "Sandakphu",
                PricePerNight: "18,999/Person",
                MutedText: "4N/5D",
                url:"/sandakphu-trekking-package-booking"
            },
            {
                ImageName: "Tea-Tourism.webp",
                AllternateText: "...",
                Description: "The sprawling tea gardens situated across the northern side of West Bengal, The luxurious mountain resorts expansive tea estates.",
                Title: "Tea Tourism",
                PricePerNight: "72,699/Person",
                MutedText: "4N/5D"
            },
    
        ];
    
        this.setState({ HotPackagesData: hotPackagesDataList })
    }
    render() {

        const hotPackagesDataList = this.state.HotPackagesData;
        const MyView = hotPackagesDataList.length > 0 ?
            (
                hotPackagesDataList.map((packageItem, i) => {
                    const packageImgURL = ApiURL.HotPackageImageURL + "/" + packageItem.ImageName;
                    return (<div className="col" key={i}>
                        <div className="card h-100">
                            <img src={packageImgURL} className="card-img-top" alt={packageItem.AllternateText} />
                            <div className="card-body">
                                <h5 className="card-title">{packageItem.Title} <span> &#8377; {packageItem.PricePerNight}</span></h5>
                                <p className="card-text">{packageItem.Description}</p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">{packageItem.MutedText}</small>
                                <button type="button" className="btn btn-Warning btn-sm float-end"><a href={packageItem.url} target='_blank' className='white'>Book Now</a></button>
                            </div>
                        </div>
                    </div>)
                })
            ) :
            (<div></div>)


        return (
            <>
                <div className="container-fluid py-5 bg-gray">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="site-heading text-center">
                                <h2>Our Hot <span>Package Tour</span></h2>
                                <h4>Across The World</h4>
                            </div>
                        </div>
                    </div>
                    {
                        (this.props.slidesToShow === 3) ?
                            <div className="row row-cols-1 row-cols-md-3 g-3">{MyView}</div>
                            : <div className="row row-cols-1 row-cols-md-4 g-4">{MyView}</div>
                    }

                </div>
            </>
        );
    }
}

export default HotPackageTour;