import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class ImagePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            currentIndex: 0, // Tracks the currently displayed image
        };
        this.modalRef = React.createRef();
        this.autoSlideInterval = null;
    }

    toggleModal = (index = 0) => {
        this.setState({
            showModal: !this.state.showModal,
            currentIndex: index,
        },
            () => {
                if (this.state.showModal) {
                    // Start auto-sliding when the modal is shown
                    this.startAutoSlide();
                } else {
                    // Clear the interval when the modal is closed
                    this.clearAutoSlide();
                }
            }
        );
    };

    // Close the modal if clicked outside the modal area
    handleClickOutside = (event) => {
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.setState({ showModal: false });
        }
    };

    // Start the automatic slide after a 3-second delay
    startAutoSlide = () => {
        // Set a timeout to delay the start of the interval by 3 seconds
        setTimeout(() => {
            this.autoSlideInterval = setInterval(() => {
                this.handleNext(); // Call next image after 3 seconds interval
            }, 6000); // 6 seconds interval
        }, 6000); // Delay before starting the auto slide
    };

    // Stop the auto-slide interval
    clearAutoSlide = () => {
        if (this.autoSlideInterval) {
            clearInterval(this.autoSlideInterval); // Clear the interval when modal is closed
        }
    };

    // Navigate to the next image
    handleNext = () => {
        const { images } = this.props;
        const { currentIndex } = this.state;

        this.setState((prevState) => ({
            currentIndex: (prevState.currentIndex + 1) % images.length,
        }));
        if (currentIndex < images.length - 1) {
            this.setState({
                currentIndex: currentIndex + 1,
            });
        }
    };

    // Navigate to the previous image
    handlePrev = () => {
        const { images } = this.props;
        const { currentIndex } = this.state;
        this.setState((prevState) => ({
            currentIndex: (prevState.currentIndex - 1 + images.length) % images.length,
        }));
        if (currentIndex > 0) {
            this.setState({
                currentIndex: currentIndex - 1,
            });
        }
    };


    // Render the modal content with navigation buttons
    renderModalContent = () => {
        const { images } = this.props;
        const { currentIndex } = this.state;

        return (
            <div
                className={`image-popup-modal modal d-flex align-items-center justify-content-center ${this.state.showModal ? 'show' : ''}`}
                style={{
                    display: this.state.showModal ? 'block' : 'none',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Dimmed background
                    zIndex: 1050,
                }}
            >
                <div className="image-popup-dialog modal-dialog modal-xl">
                    <div className="modal-content" ref={this.modalRef}>
                     
                            {/* Image */}
                            <img
                                src={images[currentIndex]}
                                alt={`Slide ${currentIndex + 1}`}
                                className={`img-fluid w-100 ${this.state.showModal ? 'active' : ''}`}
                                style={{
                                    maxHeight: '100%', // Maximum height to fit within the viewport
                                    maxWidth: '100%',  // Maximum width to fit within the viewport
                                    objectFit: 'contain', // Preserve aspect ratio
                                }}
                            />

                            {/* Previous Button */}
                            <button className="image-popup-prev" onClick={this.handlePrev} disabled={this.state.currentIndex <= 0}>
                                &#9664;
                            </button>

                            {/* Next Button */}
                            <button className="image-popup-next" onClick={this.handleNext} disabled={this.state.currentIndex >= this.props.images.length - 1}>
                                &#9654;

                            </button>
                        </div>
                    
                </div>
            </div>
        );
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside); // Listen for outside clicks
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside); // Clean up the event listener
        this.clearAutoSlide();
    }


    render() {
        const { sliderImageURL, alternateText, images } = this.props;

        return (
            <>
                {/* Thumbnail Image */}
                <div
                    className="image-wrapper2"
                    onClick={() => this.toggleModal(this.props.index)} // Pass specific index
                    style={{ cursor: 'pointer' }}
                >
                    <img src={sliderImageURL} alt={alternateText} />
                </div>

                {/* Render Modal using React Portal */}
                {this.state.showModal &&
                    ReactDOM.createPortal(
                        this.renderModalContent(),
                        document.body // Render the modal at the top of the DOM tree
                    )}
            </>
        );
    }
}

export default ImagePopup;
