import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./InclusionExclusion";
import InclusionExclusion from "./InclusionExclusion_northeastindia_10n_11d";

class NorthEastIndiaTour_10N_11D_Package extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: 'tab1',
      daywiseActiveTab: 'day1'
    }
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  handleDayWiseTabClick = (tab) => {
    this.setState({
      daywiseActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919674738480&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
  render() {
    const { customActiveTab, daywiseActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.NortEastTourIndia_10N_11D_PackageImageURL + "/Arunachal-Pradesh-Package-Tour-from-Kolkata.webp";
    const packageTopBanner2ImageURL = ApiURL.NortEastTourIndia_10N_11D_PackageImageURL + "/Arunachal-Pradesh-Tour-Package-from-Kolkata.webp";
    const sightseen1ImageURL = ApiURL.NortEastTourIndia_10N_11D_PackageImageURL + "/ARUNACHAL PRADESH TOUR PACKAGES FROM KOLKATA.webp";
    const sightseen2ImageURL = ApiURL.NortEastTourIndia_10N_11D_PackageImageURL + "/Arunachal-Pradesh-Package-Tour-from-Guwahati.webp";
    const sightseen3ImageURL = ApiURL.NortEastTourIndia_10N_11D_PackageImageURL + "/Shillong-Package-Tour.webp";

    const navDay1ImageURL = ApiURL.NortEastTourIndia_10N_11D_PackageImageURL + "/BHALUKPONG-TO-DIRANG-TOUR.webp";
    const navDay2ImageURL = ApiURL.NortEastTourIndia_10N_11D_PackageImageURL + "/DIRANG-TO-TAWANG.webp";
    const navDay3ImageURL = ApiURL.NortEastTourIndia_10N_11D_PackageImageURL + "/TAWANG-LOCAL-SIGHTSEEING.webp";
    const navDay4ImageURL = ApiURL.NortEastTourIndia_10N_11D_PackageImageURL + "/TAWANG-BUMLA PASS.webp";
    const navDay5ImageURL = ApiURL.NortEastTourIndia_10N_11D_PackageImageURL + "/TEZPUR-KAZIRANGA.webp";
    const navDay6ImageURL = ApiURL.NortEastTourIndia_10N_11D_PackageImageURL + "/TAWANG-BOMDILA.webp";
    const navDay7ImageURL = ApiURL.NortEastTourIndia_10N_11D_PackageImageURL + "/BOMDILA-TEZPUR";
    const navDay8ImageURL = ApiURL.NortEastTourIndia_10N_11D_PackageImageURL + "/JUNGLE-SAFARI-KAZIRANGA.webp";
    const navDay9ImageURL = ApiURL.NortEastTourIndia_10N_11D_PackageImageURL + "/GUWAHATI-TO-BHALUKPONG.webp";
    const navDay10ImageURL = ApiURL.NortEastTourIndia_10N_11D_PackageImageURL + "/GUWAHATI.webp";

    const TrendingPackagesDataList = this.props.TrendingPackagesData;
    const hotPackagesView = TrendingPackagesDataList.length > 0 ?
      (
        TrendingPackagesDataList.map((dataItem, i) => {
          const hotelImgURL = ApiURL.NortEastTourIndia_10N_11D_PackageImageURL + "/" + dataItem.ImageName;
          return (<div className="col-md-12 p-0" key={i}>
            <div className="card h-100">
              <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
              <div className="card-body">
                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text"><span>{dataItem.Description}</span></p>
                <p>
                  <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                  <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                  <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                </p>
              </div>
              <div className="card-footer">
                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                <button type="button" className="btn btn-Warning btn-sm float-end">Book Now</button>
              </div>
            </div>
          </div>)
        })
      ) : (<div></div>)


    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL = ApiURL.NortEastTourIndia_10N_11D_PackageImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      )
    })

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
          </div>
        </div>
        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container py-1">


                    <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center>Amazing <strong>North East Package Tour from Guwahati</strong> - BEST DEAL</center></h1>

                    <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center>Amazing <strong>North East Package Tour from Guwahati</strong> - BEST DEAL</center>
                      <hr />
                    </h1>
                    <div className="container-fluid">
                      <div className="row">
                        <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                      </div>
                    </div>
                    <hr />
                  </div>
                  <h2 className="content-desktop p-4" style={{ fontSize: "2.0vw" }}> Looking For <strong>North East Tour Package from Guwahati</strong>? For Best <strong>North East Tour</strong> <a href="tel:+91-9674738480" class="tomato"> ☎ CALL +91-96747-38480</a><br /></h2>
                  <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> Looking For <strong>North East Tour Package from Guwahati</strong>? <br /> For Best <strong>North East Tour</strong> <a href="tel:+91-9674738480" class="tomato"> ☎ CALL +91-96747-38480</a><br /></h3>
                  <div className="card">
                    <div
                      className="card-header custHeaer"
                    >
                      <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Embark on an Enchanting Journey with <strong>North East Tour Package from Guwahati</strong></h3>
                      <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Embark on an Enchanting Journey with <strong>North East Tour Package from Guwahati</strong></h4>
                    </div>
                    <div className="card-body">
                      <p>Are you ready to immerse yourself in the mesmerizing beauty of North East India? Adorable invites travel enthusiasts on an unforgettable adventure with its <strong>North East package tour</strong>, specially designed to explore the wonders of the '7 Sister States.' Begin your journey from Guwahati Airport, the gateway to this enchanting region. <br /><br /></p>
                      <h4><b>Unveiling the charm of Meghalaya: Shillong and Cherrapunjee</b></h4>
                      <p>Your <a href="/kaziranga-shillong-cherrapunjee-package-tour-from-guwahati" target="_blank">Kaziranga Shillong Cherrapunji package tour</a> kicks off with the vibrant city of Shillong, the capital of Meghalaya. Nestled amidst hills, Shillong offers a perfect blend of modernity and tradition. Marvel at the pristine landscapes, explore local markets, and indulge in lip-smacking organic cuisine. <br /><br />
                        Cherrapunjee, known for its perpetual mist and lush greenery, awaits your visit. Discover the cascading waterfalls that adorn the landscape, creating a picturesque setting. The enchanting waterfalls in Cherrapunjee are a sight to behold, providing the perfect backdrop for your travel escapade. <br /><br /></p>

                      <h4><b>Living Root Bridges: A unique marvel of Meghalaya</b></h4>
                      <p>Meghalaya is renowned for its Living Root Bridges, masterpieces crafted by the Khasi and Jainitia tribes. These extraordinary structures are formed by intertwining stones and the roots of Rubber fig and banyan trees. Your journey through Meghalaya includes a visit to the iconic Double Decker Root Bridges, a true testament to indigenous engineering.  During our <strong>North East Tour from Guwahati</strong>, we will explore some of these engineering marvels like Ummunoi Root Bridge, Umkar Root Bridge, Ritymmen Root Bridge, Umshiang Double Decker Root Bridge, Mawsaw Root Bridge etc.<br /><br /></p>

                      <h4><b>Dive into the mystical caves of Cherrapunjee</b></h4>
                      <p>Explore the mysterious caves of Cherrapunjee, revealing a hidden world beneath the surface. Marvel at the stalactite and stalagmite formations that have taken centuries to shape, creating an otherworldly atmosphere. Explore some of the best caves [Mawsmai Cave, Arwah Caves, Krem Mawmluh Cave, Krem Chympe, Krem Liat Prah] with <strong>North East package from Guwahati</strong> beautifully crafted by Adorable Holidays.<br /><br /></p>

                      <h4><b>Crystal Clear Dawaki River and the cleanest village in Asia - Mawlynnong</b></h4>
                      <p>Proceed to the crystal-clear Dawaki River, where the pristine waters invite you for a refreshing experience. Capture breath-taking views of the surrounding landscapes and immerse yourself in the tranquillity of this hidden gem. Your journey also takes you to Mawlynnong, recognized as the cleanest village in Asia. Witness the efforts of the local community in maintaining a pristine environment.<br /><br /></p>

                      <h4><b>Why choose Adorable for your North East Package Tour?</b></h4>
                      <p>Adorable Holidays is the top choice for <strong>North East Tour from Guwahati</strong> for several reasons. They carefully provides the best hotels, ensuring comfort for travelers. With Adorable Holidays, you can enjoy luxury stays in premium hotels like <b>Ri Kynjai - Serenity By The Lake</b>, <b>Windermere Inn - Shillong</b>, <b>San Naela Resort - Cherrapunji</b>, <b>Polo Orchid Resort - Cherrapunji</b> etc. Their deep knowledge of the region means they can offer unique experiences, like exploring hidden spots and local culture.<br /><br />
                        With Adorable specialized <strong>North East package tour itinerary from Guwahati</strong>, you're guaranteed safe and comfortable travel thanks to their well-maintained cars and experienced drivers. They also understand the local language and customs, making interactions smoother and more meaningful. <br /><br />
                        Moreover, Adorable is familiar with the laws and regulations of the North East, handling permit procedures hassle-free. Overall, Adorable Holidays promises unforgettable adventures in the beautiful North East of India.<br /><br /></p>

                      <h4>Book your <strong>North East India package tour</strong> today!</h4>
                      <p>Embark on a journey of a lifetime with Adorable. Book your <strong>North East India tour packages from Guwahati Airport</strong> now and discover the treasures hidden in the heart of North East India. Immerse yourself in the diverse landscapes, vibrant cultures, and unparalleled hospitality of the '7 Sister States.' Don't miss the chance to create lasting memories with Adorable - your gateway to the enchanting North East!</p>
                      <br />
                      <div className="content-desktop">
                        <center><img src={sightseen1ImageURL} alt="..." /></center>
                      </div>

                      <div className="content-mobile2">
                        <center><img src={sightseen1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                      </div>
                      <br />

                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>NORTH EAST TOUR PACKAGE</strong> DURATION : 10N | 11D </h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>NORTH EAST TOUR PACKAGE</strong> DURATION : 10N | 11D </h4>
                </div>
                <div className="card-body">
                  <p>Destinations Covered in <strong>North East package tour from Guwahati</strong> : Shillong 3N | Cherrapunjee 1N | Kaziranga 2N | Jorhat 1N | Nameri 2N | Guwahati 1N <br /><br /></p>
                  <p>Pickup & Drop point for <strong>North East tour</strong> : Lokpriya Gopinath Bordoloi International Airport [GAU] <br /><br /></p>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-danger">
                          <th>
                            <strong>NORTH EAST TOUR PLAN FROM GUWAHATI</strong>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                          >
                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                              <span>
                                GUWAHATI TO SHILLONG TRANSFER
                              </span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                              <span>SHILLONG TO CHERRAPUNJEE TRIP</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                              <span>CHERRAPUNJEE - MAWLYNNONG, DAWKI - SHILLONG</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                              <span>SHILLONG - MĀWSYNRĀM - SHILLONG TRIP</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                              <span> SHILLONG TO KAZIRANGA NATIONAL PARK TOUR</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                              <span> KAZIRANGA JUNGLE SAFARI [ELEPHANT + JEEP SAFARI]</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                              <span>KAZIRANGA TO JORHAT [HOOLLONGAPAR GIBBON WILDLIFE SANCTUARY]</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                              <span>JORHAT TO NAMERI NATIONAL PARK</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day9" style={{ color: 'black' }}><b>DAY 9 → </b>
                              <span>DAY SIGHTSEEING / ACTIVITIES AT NAMERI</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day10" style={{ color: 'black' }}><b>DAY 10 → </b>
                              <span>NAMERI TO GUWAHATI [OPTIONAL - BRAHMAPUTRA RIVER DINNER CRUISE]</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="clickable">
                            <a href="#day11" style={{ color: 'black' }}><b>DAY 11 → </b>
                              <span>GUWAHATI AIRPORT / STATION DROP</span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>

              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Places of Attractions of <strong>North East India Tour Package from Guwahati</strong> :</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>Places of Attractions of <strong>North East India Tour Package from Guwahati</strong> :</h4>
                </div>
                <div className="card-body">
                  <p>In this <strong>North East package</strong> guests can travel most visited tourist attractions of Meghalaya like - Don Bosco Museum, Ward's Lake, Meghalaya State Museum, Lady Hydari Park, Shillong Peak, Elephant Falls, Cherrapunji, Nohkalikai Falls, Mawsmai Caves, Eco Park, Seven Sisters falls, Ram Krishna Mission, Thangkharang Park, Mawlynnong Village, Living Root Bridge, Dawki River, Mawsynram, Mawphlang Sacred Forest, Mawjymbuin Cave (Mawsynram cave), Naturally formed Shiva Linga, Jakrem, Kaziranga National Park, Kaziranga Jeep Safari, Kaziranga Elephant Safari, Hoollongapar Gibbon Wildlife Sanctuary, Nameri National Park, sankardev, kalakshetra, Brahmaputra River Cruise (optional) etc. <br /><br /></p>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody><tr><td><strong>North East Tour</strong> Pickup & Drop</td><td>Guwahati Airport [GAU]</td></tr>
                        <tr><td><strong>North East Package Tour Itinerary from Guwahati</strong></td><td>10 Nights | 11 Days</td></tr>
                        <tr><td>Altitude of Shillong</td><td>1,525 m [Coordinates - 25.5788° N, 91.8933° E]</td></tr>
                        <tr><td>Altitude of Cherrapunjee</td><td>1,430 m [27.2702° N, 91.7323° E] </td></tr>
                        <tr><td>Altitude of Mawsynram</td><td>1,400 m [Coordinates - 25.2975° N, 91.5826° E]</td></tr>
                        <tr><td>Altitude of Mawlynnong</td><td>475 m [Coordinates - 25.2018° N, 91.9160° E]</td></tr>
                        <tr><td>Altitude of Jowai</td><td>1,380 m [Coordinates - 25.4509° N, 92.2089° E]</td></tr>
                        <tr><td>Altitude of Dawki</td><td>28 m [Coordinates - 25.1840° N, 92.0249° E]</td></tr>
                        <tr><td>Altitude of Kaziranga</td><td>40 m [Coordinates - 26.5775° N, 93.1711° E]</td></tr>
                        <tr><td>Altitude of Jorhat</td><td>116 m [Coordinates - 23.8917° N, 84.0326° E]</td></tr>
                        <tr><td>Altitude of Nameri</td><td>80 to 225 m [Coordinates - 26.9324° N, 92.8772° E]</td></tr>
                        <tr><td>Altitude of Guwahati</td><td>50 m [Coordinates - 26.1158° N, 91.7086° E]</td></tr>
                      </tbody></table>
                  </div>
                  <br />
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr>
                          <th><b>NORTH EAST PACKAGE CATEGORY</b></th>
                          <th><b>NORTH EAST TOUR DURATION</b></th>
                          <th><b>NORTH EAST TOUR COST</b></th>
                          <th><b>BOOK NOW</b></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Arunachal Pradesh Tour Package from Guwahati</td>
                          <td>15 Nights 16 Days</td>
                          <td>Rs. 49,900/P</td>
                          <td>
                          </td>
                        </tr>
                        <tr>
                          <td>Arunachal Pradesh Tour Package from Mumbai</td>
                          <td>14 Nights 15 Days</td>
                          <td>Rs. 54,999/P</td>
                          <td>
                          </td>
                        </tr>
                        <tr>
                          <td>Arunachal Pradesh Tour Package from Kolkata</td>
                          <td>9 Nights 10 Days</td>
                          <td>Rs. 38,199/P</td>
                          <td>
                          </td>
                        </tr>
                        <tr>
                          <td>Shillong Meghalaya Tour Package from Guwahati</td>
                          <td>6 Nights 7 Days</td>
                          <td>Rs. 20,900/P</td>
                          <td>
                          </td>
                        </tr>
                        <tr>
                          <td>Kaziranga Shillong Cherapunjee Package Tour from Guwahati</td>
                          <td>6 Nights 7 Days</td>
                          <td>Rs. 25,399/P</td>
                          <td>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <div className="content-desktop">
                    <center><img src={sightseen2ImageURL} alt="..." /></center>
                  </div>

                  <div className="content-mobile2">
                    <center><img src={sightseen2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHY NORTH EAST INDIA IS A DREAM DESTINATION FOR ONCE IN A LIFETIME EXPERIENCE?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY NORTH EAST INDIA IS A DREAM DESTINATION FOR ONCE IN A LIFETIME EXPERIENCE?</h4>
                </div>
                <div className="card-body">
                  <p>Embark on an unforgettable journey to the mesmerizing landscapes of Shillong and Meghalaya in North East with <strong>North East tour package booking from Guwahati</strong>. Marvel at nature's masterpiece with cascading waterfalls and rolling hills, immerse yourself in the rich cultural tapestry of indigenous tribes, and indulge in thrilling adventures like trekking through living root bridges and exploring mysterious caves. Delight your taste buds with Meghalaya's diverse cuisine, from Jadoh ('Ja' means rice and 'Doh' means meat) to bamboo shoot delicacies. Opting for a <strong>North East tour package from Guwahati</strong> ensures a convenient and seamless travel experience, covering all the must-visit attractions. Discover the extraordinary charm of Shillong and Cherrapunjee with Jowai, Mawsynram, Mawlynnong, Dawki, create lasting memories, and let the allure of this dream destination captivate your soul.<br /></p>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr>
                          <th><b>NORTH EAST PACKAGE CATEGORY</b></th>
                          <th><b>NORTH EAST TOUR DURATION</b></th>
                          <th><b>NORTH EAST TOUR COST</b></th>
                          <th><b>BOOK NOW</b></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Arunachal Pradesh Tour Package from Guwahati</td>
                          <td>15 Nights 16 Days</td>
                          <td>Rs. 49,900/P</td>
                          <td>
                          </td>
                        </tr>
                        <tr>
                          <td>Arunachal Pradesh Tour Package from Mumbai</td>
                          <td>14 Nights 15 Days</td>
                          <td>Rs. 54,999/P</td>
                          <td>
                          </td>
                        </tr>
                        <tr>
                          <td>Arunachal Pradesh Tour Package from Kolkata</td>
                          <td>9 Nights 10 Days</td>
                          <td>Rs. 38,199/P</td>
                          <td>
                          </td>
                        </tr>
                        <tr>
                          <td>Shillong Meghalaya Tour Package from Guwahati</td>
                          <td>6 Nights 7 Days</td>
                          <td>Rs. 20,900/P</td>
                          <td>
                          </td>
                        </tr>
                        <tr>
                          <td>Kaziranga Shillong Cherapunjee Package Tour from Guwahati</td>
                          <td>6 Nights 7 Days</td>
                          <td>Rs. 25,399/P</td>
                          <td>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <div className="content-desktop">
                    <center><img src={sightseen3ImageURL} alt="..." /></center>
                  </div>

                  <div className="content-mobile2">
                    <center><img src={sightseen3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHICH IS THE BEST TIME FOR <strong>NORTH EAST TOUR PACKAGE BOOKING FROM GUWAHATI</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHICH IS THE BEST TIME FOR <strong>NORTH EAST TOUR PACKAGE BOOKING FROM GUWAHATI</strong>?</h4>
                </div>
                <div className="card-body">
                  <p>Discovering the optimal time to secure your <strong>North East India travel package</strong> depends upon your specific travel intentions. Each season in the North East unfolds its own unique allure. For those yearning to partake in the wonders of Kaziranga National Park, including its famed Elephant and Jeep Safari, the recommended booking window extends from November to the end of March, encompassing the winter and mid-summer months. Kaziranga's winter offers a comfortably chilly experience with temperatures ranging between 6 to 24 degrees, ideal for exploring its diverse wildlife. Summer, from April to June, provides another opportunity for animal sightseeing, but the heat can be intense. Monsoon, spanning from July to September, renders the park inaccessible due to flooding. If Kaziranga tops your travel wish list, opt for a winter holiday package in North East India. <br /><br />
                    Conversely, if your travel aspirations involve relishing the cascading waterfalls of North East, particularly in Cherrapunjee, Adorable recommends to book your <strong>North East tour package</strong> between the end of July and October. This period sees the waterfalls in their full splendour, brimming with gushing water - a spectacle best enjoyed during the monsoon in Cherrapunjee. Two seasons stand out as optimal for booking your <strong>North East travel package</strong>: <br /><br />
                    <b>Winter</b> - Perfect for Kaziranga wildlife safaris and trekking to the various caves and root bridges of Cherrapunjee. <br /><br />
                    <b>Monsoon</b> - For those yearning to experience the enchantment of North-East waterfalls. <br /><br />
                    It's advisable to steer clear of the summer season, characterized by excessive heat and humidity, potentially diminishing the appeal of waterfalls and wildlife safaris. However, it's worth noting that summer provides an excellent opportunity to explore Arunachal Pradesh, offering respite from the scorching heat prevalent in other parts of India.
                    In conclusion, different seasons cater to various North East India travel preferences—whether it's the allure of snow, wildlife encounters, or the magic of waterfalls. Tailor your travel intent accordingly and unlock the myriad wonders of the Seven Sisters states. <br /><br /></p>
                  <h4 style={{ color: 'tomato' }}>MONTH WISE SHILLONG WEATHER AND TEMPERATURE TO NOTE</h4><br />
                  <p>Shillong has a subtropical highland climate with cool and rainy summer and cold dry winter. Have a look of the Shillong Weather Table.<br /><br /></p>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th>Travel Month</th><th>Min / Max Temperature</th><th>Season</th></tr></thead>
                      <tbody><tr><td>March to June</td><td>12&#176; - 24&#176;C</td><td>Pleasant</td></tr>
                        <tr><td>July to September</td><td>10&#176; - 22&#176;C</td><td>Rainy Season</td></tr>
                        <tr><td>October to February</td><td>5&#176; - 14&#176;C</td><td>Cold</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <h4 style={{ color: 'tomato' }}>HOW IS THE WEATHER IN CHERRAPUNJEE?</h4><br />
                  <p>Cherrapunjee with so much rainfall throughout the year makes the place quite muggy and since it remains covered under clouds, so the weather remains cool and pleasant to visit mostly any time of the year. August seems as the warmest and wetest month of the year and January seems to be the coolest and dry. Through following table we can a glimps of weather of Cherrapunji.<br /><br /></p>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead><tr><th>Travel Month</th><th>Min / Max Temperature</th><th>Season</th></tr></thead>
                      <tbody><tr><td>March to June</td><td>15&#176; - 25&#176;C</td><td>Warm but Pleasant</td></tr>
                        <tr><td>July to September</td><td>18&#176; - 28&#176;C</td><td>Warm and Wet</td></tr>
                        <tr><td>October to February</td><td>8&#176; - 15&#176;C</td><td>Cold & Pleasant</td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> HOTELS ADORABLE PROVIDE FOR <strong>NORTH EAST TOUR PACKAGE</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> HOTELS ADORABLE PROVIDE FOR <strong>NORTH EAST TOUR PACKAGE</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><th>HOTEL NAME</th><th>ADDRESS</th></tr></thead>
                      <tbody><tr><td>Windermere Inn, Shillong</td><td>Address: Lumheh, Umpling, Near B.S.F Gate, No.04, Neigrihms Cir Rd, Shillong, Meghalaya 793006</td></tr>
                        <tr><td>Golf View Residency shillong</td><td>Address: Dog squad, Golf course, Pynthorumkhrah, Shillong, Meghalaya 793001</td></tr>
                        <tr><td>Rishatsngi Resort</td><td>Address: Umiam, Dyke, GS Rd, Mawlyngkhung, Meghalaya 793103</td></tr>
                        <tr><td>Ri Kynjai - Serenity By The Lake</td><td>Address: Umniuh Khwan, Road, UCC, Meghalaya 793122</td></tr>
                        <tr><td>Hotel Orchid Annex</td><td>Address: Tourist Information Center, Polo Marg, Near CM Bungalow, next to Meghalaya, Police Bazar, Shillong, Meghalaya 793001</td></tr>
                        <tr><td>La Nicholas Dei Da Kine Resort</td><td>Address: UMBIR-MAIRANG ROAD, NEAR SILOAM , Umbir Village, Shillong, Meghalaya 793122</td></tr>
                        <tr><td>Cherrapunji Holiday Resort</td><td>Address: Laitkynsew View Point Rd, Village, Cherrapunji, Sohsarat, Meghalaya 793108</td></tr>
                        <tr><td>Sulawado Resort</td><td>Address: Nongthymmai, Cherrapunji, Meghalaya 793108</td></tr>
                        <tr><td>San Naela Resort</td><td>Address: Land mark - Near NIT , SOHRA , Pynshadkhurai - Shella Road Saitsohpen, Cherrapunji, Meghalaya 793108</td></tr>
                        <tr><td>Saimika Resort</td><td>Address: Cherrapunji, Meghalaya 793108</td></tr>
                        <tr><td>Jiva Resort</td><td>Address: Saitsohpen,Near Kutmadan, Cherrapunji, Meghalaya 793108</td></tr>
                        <tr><td>Royal View Resort</td><td>Address: Lumsohphie, Via, Tyrna, Cherrapunji, Meghalaya 793108</td></tr>
                        <tr><td>CORDIAL lodge Cherrapunjee</td><td>Address: Sohra-Shella Rd, Cherrapunji, Meghalaya 793111</td></tr>
                        <tr><td>Four Season Eco Resort</td><td>Address: Eco Park, Cherrapunji, Meghalaya 793108</td></tr>
                        <tr><td>Polo Orchid Resort, Cherrapunji</td><td>Address: Mawsmai, Nongthymmai, Cherrapunji, Meghalaya 793108</td></tr>
                        <tr><td>Pala Resort</td><td>Address: Kutmadan, Sohra, Cherrapunji, Shillong, Meghalaya 793111</td></tr>
                      </tbody></table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHY ADORABLE IS NO #1 SUPER SPECIALIST FOR <strong>NORTH EAST TOUR PACKAGE WITH KAZIRANGA SHILLONG CHERRAPUNJEE</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY ADORABLE IS NO #1 SUPER SPECIALIST FOR <strong>NORTH EAST TOUR PACKAGE WITH KAZIRANGA SHILLONG CHERRAPUNJEE</strong>?</h4>
                </div>
                <div className="card-body">
                  <p><ul><li>We have a wide variety of Pre-Purchased Room Inventory in Shillong, Cherrapunjee and Kaziranga which ensures assured valley/mountain/river/lake view room based on your <strong>North East India Package Booking Date</strong>.<br /><br /></li>
                    <li>With our Premium & Deluxe Hotel Stay, you get best facilities and amenities during your <strong>North East Tour</strong>. We provide best hotel options in Shillong & Cherrapunjee. We have a team of experience Passionate Travellers. Every hotels / homestays is personally visited & certified by Adorable team. <br /><br /> </li>
                    <li>Your vehicle for <strong>North East tour</strong> will be operated from Guwahati. We provide best car as per your preference and budget like Innova / Ertiga / Scorpio / XUV 500 (Sunroof).<br /><br /></li>
                    <li>We have separate war room and specialized staff from North East tour operations, so that you get best uninterrupted service throughout your <strong>Meghalaya Tour from Guwahati</strong>.<br /><br /></li>
                    <li>Being Meghalaya super specialist, we always guide you for your <strong>Shillong Cherrapunjee trip</strong> in accordance with Peak Season Rush / Weather / Off-Season, because we are best among the responsible North East India Tour Operators in India and always care for your safety and hard earned money.<br /><br /></li>
                    <li>Adorable is specialist for <strong>Shillong Meghalaya tour package from Guwahati</strong> for more than 12 years. Company Offers Deluxe Homestays / Premium Stays / Luxury Boutique Resorts / Flavoured Properties. <br /><br /></li>
                    <li>Guests will be given complete day to day guidance and assistance from Guwahati to Guwahati by Adorable team member and their representation on field. Every North East Trip is always handled by Adorable Team itself, not handed over to a Sub-Agent.  <br /><br /><br /></li></ul></p>
                  <h4 style={{ color: 'tomato' }}>DISTANCES TO NOTE FOR <strong>NORTH EAST PACKAGE BOOKING</strong></h4><br />
                  <p>Shillong has a subtropical highland climate with cool and rainy summer and cold dry winter. Have a look of the Shillong Weather Table.<br /><br /></p>
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead><tr><th><b>Places</b></th><th><b>Distances/Travel Time [By Road]</b></th></tr></thead>
                      <tbody><tr><td>Guwahati to Shillong Distance</td><td>2 hr 40 min (99.2 km) via NH6</td></tr>
                        <tr><td>Guwahati to Kaziranga</td><td>4 hr 5 min (192.8 km) via NH 27</td></tr>
                        <tr><td>Guwahati to Cherrapunji</td><td>4 hr 8 min (147.8 km) via NH6</td></tr>
                        <tr><td>Shillong to Cherrapunji</td><td>1 hr 45 min (54.0 km) via SH 5</td></tr>
                        <tr><td>Shillong to Mawsynram</td><td>2 hr 4 min (60.3 km) via Mawsynram-Shillong Rd</td></tr>
                        <tr><td>Shillong to Kaziranga</td><td>28 min (7.9 km) via NH6</td></tr>
                        <tr><td>Shillong to Mawlynnong</td><td>2 hr 30 min (78.1 km) via NH206</td></tr>
                        <tr><td>Shillong to Dawki</td><td>2 hr 38 min (81.8 km) via NH206</td></tr>
                        <tr><td>Mawlynnong to Dawki</td><td>1 hr 3 min (29.4 km) via NH206</td></tr>
                        <tr><td>Kaziranga to Nameri</td><td>2 hr (87.1 km) via NH715</td></tr>
                        <tr><td>Kaziranga to Cherrapunji</td><td>7 hr 10 min (301.5 km) via NH 27 and NH6</td></tr>
                        <tr><td>Cherrapunji to Mawlynnong</td><td>2 hr 28 min (80.9 km) via SH 5 and NH206</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <h4 style={{ color: 'tomato' }}>FLIGHTS TO CHOOSE FROM DIFFERENT CITIES FOR <strong>BOOKING NORTH EAST PACKAGE TOUR FROM GUWAHATI AIRPORT</strong><br /><br /></h4>

                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr><td><b>AIRLINES</b></td><td><b>ORIGIN</b></td><td><b>DEPARTURE</b></td><td><b>DESTINATION</b></td><td><b>ARRIVAL</b></td><td><b>DURATION</b></td></tr></thead>
                      <tbody><tr><td>IndiGo</td><td>Mumbai [BOM]</td><td>06:25</td><td>Guwahati [GAU]</td><td>09:20</td><td>02 h 55 m (non-stop)</td></tr>
                        <tr><td>SpiceJet</td><td>Mumbai [BOM]</td><td>06:45</td><td>Guwahati [GAU]</td><td>10:00</td><td>03 h 15 m (non-stop)</td></tr>
                        <tr><td>AirAsia</td><td>Delhi [DEL]</td><td>06:05</td><td>Guwahati [GAU]</td><td>08:25</td><td>02 h 20 m (non-stop)</td></tr>
                        <tr><td>AirAsia</td><td>Delhi [DEL]</td><td>07:15</td><td>Guwahati [GAU]</td><td>09:40</td><td>02 h 25 m (non-stop)</td></tr>
                        <tr><td>IndiGo</td><td>Delhi [DEL]</td><td>05:00</td><td>Guwahati [GAU]</td><td>07:20</td><td>02 h 20 m (non-stop)</td></tr>
                        <tr><td>IndiGo</td><td>Delhi [DEL]</td><td>07:25</td><td>Guwahati [GAU]</td><td>09:45</td><td>02 h 20 m (non-stop)</td></tr>
                        <tr><td>AirAsia</td><td>Bangalore [BLR]</td><td>08:25</td><td>Guwahati [GAU]</td><td>11:20</td><td>02 h 55 m (non-stop)</td></tr>
                        <tr><td>IndiGo</td><td>Bangalore [BLR]</td><td>08:20</td><td>Guwahati [GAU]</td><td>11:20</td><td>03 h 00 m (non-stop)</td></tr>
                        <tr><td>IndiGo</td><td>Chennai [MAA]</td><td>05:30</td><td>Guwahati [GAU]</td><td>08:15</td><td>02 h 45 m (non-stop)</td></tr>
                      </tbody></table>
                  </div>
                </div>
              </div>
              <br /><br />
              {/* <!--By Itinerary--> */}
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3 className="content-desktop" style={{ fontSize: "2.0vw" }}>
                        DAY WISE DETAIL <strong style={{ color: 'tomato' }}>NORTH EAST PACKAGE TOUR ITINERARY FROM GUWAHATI</strong>
                      </h3>
                      <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>
                        DAY WISE DETAIL <strong>NORTH EAST PACKAGE TOUR ITINERARY FROM GUWAHATI</strong>
                      </h3>
                    </div>
                  </div>
                </div>

                <br />
                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpen"
                  >
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day1"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          DAY 1 : ARRIVAL GUWAHATI - TRANSFER TO SHILLONG [2 hr 36 min | 99.9 km]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>Welcome on arrival at Guwahati Airport/Railway Station of Assam for your <strong>North East package tour</strong>. Proceed towards Shillong - known as 'The Scotland of the East' is the capital city of Meghalaya. Enroute, you will visit the largest man-made lake of Northeast India - The Umium Lake.<br /><br />
                            Proceed for the local sightseeing, visit - Don Bosco Museum, Ward's Lake located at the heart of the city which is popular for its garden walks and boating, Meghalaya State Museum where Meghalaya's tribal culture and heritage are well displayed, Lady Hydari Park, Shillong Peak and Elephant Falls situated on the outskirts of the city. Overnight Stay at a Hotel in Shillong. <br /><br /></p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay1ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          DAY 2 : SHILLONG TO CHERRAPUNJEE TOUR [1 hr 45 min | 54.0 km]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>After breakfast, check out from your hotel & proceed for your Cherrapunji local sightseeing tour. Visit Nohkalikai Falls, Mawsmai Caves, Eco Park, Seven Sisters falls, Ram Krishna Mission and the Thangkharang Park. Evening checks in at hotel. Overnight Stay at Hotel in Cherrapunjee. <br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay2ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 3 : CHERRAPUNJEE TO MAWLYNNONG + DAWKI TRIP & BACK TO SHILLONG [5 HR 47 MIN | 175.7 KM]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>Your spectacular 3rd day <strong>North East tour</strong> starts today. Wake up early and have your breakfast, later check out from your Cherrapunjee hotel and drive to Mawlynnong. Today you can explore the cleanest village of Asia - Mawlynnong located in the east khasi hills of Meghalaya. This village is world famous for its cleanliness. You can take the sky walk. Behold and feel the touch of the spectacular <em>Living Root Bridge</em>. After lunch drive to Dawki.<br /><br />
                            Dawki is the gateway to Bangladesh where major export and import transactions take place. It is a splendid drive of 45 kilometers passing through deep gorges and ravines, which is certainly a thrilling experience. In local, Dawki is known as the Wah Umngot river, The Umngot river is the natural boundary between RiPnar or Jaintia Hills with HimaKhyrim of Khasi hills over which is a single span suspension bridge was constructed. Evening returns back to Shillong city. Overnight stay in Shillong Hotel. <br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay3ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day4">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFour"
                        >
                          DAY 4 : SHILLONG - MĀWSYNRĀM - SHILLONG TRIP [2 hr 18 min | 60.3 km]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>After breakfast drive to Māwsynrām and visit Mawphlang Sacred Forest, Mawjyngbuin Cave (Mawsynram cave), Naturally formed Shiva Linga, Jakrem (Hot water Spring). After wholeday sightseeing tour back to Shillong. Overnight stay at Hotel in Shillong. <br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay4ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day5">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFive"
                        >
                          DAY 5 : SHILLONG TO KAZIRANGA NATIONAL PARK TRANSFER [5 hr 16 min | 255.2 km]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>After early breakfast, check out of the hotel & start your journey for the Kaziranga National Park. Kaziranga is located almost 200+ KM from Guwahati, covering an area of 430 sq-kms, is inhabited by the world's largest population of one-horned rhinoceroses, and many mammals including tigers, elephants, panthers and bears, and thousands of birds. In 1985, it was declared a World Heritage Site by <abbr title="The United Nations Educational, Scientific and Cultural Organization" style={{ color: 'tomato' }}>UNESCO</abbr> for its unique natural environment. Go to bed early for the next day early morning <a href="/kaziranga-shillong-cherrapunjee-package-tour-from-guwahati" target="_blank"><strong>Kaziranga Safari</strong></a>. Overnight Stay at Kaziranga National Park. <br /><br /></p>
                          {/* <div className="content-desktop">
                            <center><img src={navDay5ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day6">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSix"
                        >
                          DAY 6 : KAZIRANGA ELEPHANT SAFARI + JEEP SAFARI [INCLUDED]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>After an early wake-up, we will take you to the riding point for <a href="/kaziranga-shillong-cherrapunjee-package-tour-from-guwahati" target="_blank" style={{ color: 'tomato' }}><strong>Kaziranga Elephant Safari</strong></a> in the Western Range. Watch the famous One-Horn Rhino within the jungle from stone throwing distance. After the elephant safari, return to the Resort. <br /><br />
                            After lunch, drive to Kaziranga Central Range for the <strong>Kaziranga Jeep Safari</strong>. The Central zone of the Kaziranga National Park is the prime zone for ecotourism in the Kaziranga forest. The entry gate to the central zone is situated just about two km from the National Highway passing through the village of Kohora. The Kaziranga Jungle Safari offers you some spectacular sightings of the Rhinos grazing or wallowing in the large grassland, a herd of elephants with the adorable baby elephants to big old elephants in the herd, and many other species of the wild flora and fauna of this tourism zone. Overnight Stay at Kaziranga National Park. <br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay6ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day7">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSeven"
                        >
                          DAY 7 : KAZIRANGA TO HOOLLONGAPAR GIBBON WILDLIFE SANCTUARY [JORHAT - 112 KM / 3 HRS]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSeven"
                      >
                        <div className="accordion-body">
                          <p>After Breakfast, check out of the Hotel & transfer to Jorhat. Visit Hoollongapar Gibbon Wildlife Sanctuary. <br /><br />
                            Located around 20 km from the main town, this isolated wildlife sanctuary is famous for its Hoolock Gibbon population. Surrounded by the Brahmaputra River on one side and with tea gardens everywhere else, this is a good enclosure for the 40 or so species of Hoolock Gibbons that reside here. <br /><br />
                            Apart from the Gibbon, one can see Capped Langur, Stump-Tailed Macaque, Pigtail Macaque, Assamese Macaque, Rhesus Macaque and Slow Loris, Elephant and so many other species of animals here. Overnight Stay at Jorhat. <br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay7ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day8">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseEight"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseEight"
                        >
                          DAY 8 : JORHAT TO NAMERI NATIONAL PARK [4 hr 49 min | 198.9 km]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingEight"
                      >
                        <div className="accordion-body">
                          <p>After breakfast check out and proceed for <strong>Nameri National Park Tour</strong>, Assam famous for Tiger Reserve. <b>Nameri National Park and Forest Reserve</b> is located in the foothills of Himalaya's in the Sonitpur District of Assam. Nameri is one of the famous National Parks of India for Bird Lovers and Wildlife Photographers. Reach there, take rest. Evening free for leisure. Overnight Stay at Nameri National Park. <br /><br /></p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay8ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day9">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseNine"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseNine"
                        >
                          DAY 9 : DAY SIGHTSEEING / ACTIVITIES AT NAMERI
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseNine"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingNine"
                      >
                        <div className="accordion-body">
                          <p>Nameri National Park covers an area of 200 sq km and is in the Sonitpur dist, bordering Arunachal Pradesh. It is also the core area of Nameri Tiger Reserve. It has beautiful deciduous forests and the adjacent river Jia Bharali fringe the border of Assam and Arunachal Pradesh. This is, therefore, a popular stop for fishing/river rafting, ethnic Assamese Village & sacred forest walk for watching various species of birds. <br /><br />
                            Animals are seen here: Leopard, Clouded Leopard, Elephant, Gaur, Wild Pigs, Sambar, Capped Languretcs. Overnight Stay at Nameri National Park. <br /><br /></p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay9ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay9ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day10">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTen"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTen"
                        >
                          DAY 10 : NAMERI TO GUWAHATI [4 hr 43 min | 218.2 km]
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTen"
                      >
                        <div className="accordion-body">
                          <p>After Breakfast proceed towards Guwahati. Later in the afternoon, visit sankardev kalakshetra,'Jagran' - the shop of Assamese handicrafts & culture. Evening experience the Dinner Cruise on mighty Brahmaputra River (optional). Time free for leisure. Overnight Stay at Hotel in Guwahati. <br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay10ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay10ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="day10">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTen"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTen"
                        >
                          DAY 11 : NOTRH EAST TOUR ENDS WITH SWEET MEMORIES
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTen"
                      >
                        <div className="accordion-body">
                          <p>Morning after breakfast, check out from your hotel & then transfer to Guwahati Airport / Rly. Stn. with sweet memories & cherish your memories forever. <br /><br /></p>
                          <div className="content-desktop">
                            <center><img src={navDay10ImageURL} alt="..." /></center>
                          </div>
                          <div className="content-mobile2">
                            <center><img src={navDay10ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              {/* <!--insert table inclusion exclusion--> */}
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    {
                      <InclusionExclusion />
                    }
                  </div>
                </div>
              </div>
              {/* <!--end table inclusion exclusion--> */}
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> BEST SIGHTSEEING PLACES TO EXPLORE DURING <strong>NORTH EAST TOUR PACKAGE FROM GUWAHATI AIRPORT</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST SIGHTSEEING PLACES TO EXPLORE DURING <strong>NORTH EAST TOUR PACKAGE FROM GUWAHATI AIRPORT</strong>?</h4>
                </div>
                <div className="card-body">
                  <h4><b>MAWLYNNONG VILLAGE</b><br /><br /></h4>
                  <p>Located in East Khasi Hills in the state of Meghalaya, this village was awarded as the cleanest village in Asia in 2005 by Discover India Magazine. The village is too clean to find a single piece of leaf or garbage anywhere. Using of plastic bags and plastic materials are strictly banned in the village. If you are fond of smoking, it's a no no for you as the village is smoking free applicable for both villagers and tourists also. For the beautification, villagers plant and take care of flower plants on daily basis. In fact it is a part of their daily life. Tour this beautiful place of Meghalaya along with Arunachal Pradesh also with Adorable special <a href="/shillong-meghalaya-package-tour-from-guwahati" target="_blank"><strong>Meghalaya Arunachal Pradesh package tour</strong></a>, it will be your lifetime experience. <br /><br /></p>
                  <h4><b>ROOT BRIDGES OF CHERRAPUNJI</b><br /><br /></h4>
                  <p>With Adorable special <a href="/arunachal-pradesh-package-tour-from-guwahati" target="_blank"><strong>Assam Meghalaya Arunachal Pradesh package tour</strong></a> you can behold this man made amazing creations - the Living Roof Bridge of Meghalaya. It is located deep inside of dense lush green tropical forest of Meghalaya. A good amount of hiking is required to gt proximity of this amazing living structure. Yes it is living as it is build with rubber and banyan plant reinforced with stones that give it the form of suspension bridge. <br /><br />
                    There are no of this kind of living Root Bridge that can be explored, among which Single-Decker living root bridge in Riwai, Double Decker Root Bridge of Umshiang is famous and visited by millions of travelers from around the world. The serenity and calmness will amaze you. It's amazing. Travel this beautiful land. It is worth visiting. <br /><br /></p>
                  <h4><b>DAWKI RIVER</b><br /><br /></h4>
                  <p>Have you ever imagined swimming in a river which is crystal clear surrounded with lush green forest? You might think that place exist in only fairy tales. But no - it exist in reality. The place rather the river is Dawki River, also known as Umngot River situated about 82kms south of Shillong. A roughly 2 and half hour drive from Shillong will take you to this beautiful place between the Kashi and the Jaintia Hills. It is one of the most spectacular destinations that you can visit during your <strong>Shillong Cherrapunjee tour</strong>.<br />
                    The best way to travel and view this amazing phenomenon is to rent a private car or cab which you can hire at around Rs 2000 to Rs 2500 (both way). You can also take bus of Assam State Transport Corporation (ASTC) which runs on daily basis from Guwahati Station. The bus fair can cost you Rs. 150/- per person which is quite reasonable. <br /><br /></p>
                  <h4><b>UMIAM LAKE</b><br /><br /></h4>
                  <p>Umiam Lake rather Barapani is one of the most popular tourist attractions and on the top list of <strong>North East Family Tour Packages</strong> seeker. It's a man made artificial lake located 15km north of Shillong - the capital of Indian state of Meghalaya. Spreading about 220 sq km in between Khasi Hills, the lake is surrounded by lush green thick coniferous forests that make the place more panoramic and alluring. The serenity and tranquillity of the place is healing for mind peace. <br /><br />
                    Apart from the abundance of natural beauty, the lake offer many sports and adventure activity like boating, angling, kayaking etc. A one day trip in this beautiful place will be a cherry on cake if you stay there and watch magnificent sunrise at the lake which will be an unforgettable experience.<br /><br /></p>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>THE ULTIMATE GUIDE FOR <strong>NORTH EAST INDIA TRAVEL PACKAGE FROM GUWAHATI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>THE ULTIMATE GUIDE FOR <strong>NORTH EAST INDIA TRAVEL PACKAGE FROM GUWAHATI</strong></h4>
                </div>
                <div className="card-body">
                  <ul><li>Always try to travel with a local guide who have significant knowledge about the state <br /><br /></li>
                    <li>Follow the designated trails for trekking so that the vegetation is not interrupted <br /><br /></li>
                    <li>Always take PAP (Protected Area Permits) for visiting restricted areas <br /><br /></li>
                    <li>Keep your identity card handy. Consider making photo copies of those <br /><br /></li>
                    <li>Keep your valuables in hotel lockers/safe deposit vaults to avoid any misadventure <br /><br /></li>
                    <li>Do not buy goods, fruits or vegetables from road side sellers until you feel safe <br /><br /></li>
                    <li>Keep the pollutants away from the streams and lakes <br /><br /></li>
                    <li>Respect the local culture, dress modestly while visiting monastery or shrine <br /><br /></li>
                    <li>Follow the photography rules strictly <br /><br /></li>
                    <li>Do not carry plastics. Use reusable water bottle <br /><br /><br /></li>
                  </ul>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHICH ARE THE BEST PLACES TO EXPLORE IN NORTH EAST?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHICH ARE THE BEST PLACES TO EXPLORE IN NORTH EAST?</h4>
                </div>
                <div className="card-body">
                  <ul><li><b>Majuli Island, Assam :</b> : Explore the serenity of the world's largest river island, Majuli. <br /><br /></li>
                    <li><b>Loktak Lake, Manipur :</b> Immerse yourself in the vastness of Loktak, India's largest freshwater lake. <br /><br /></li>
                    <li><b>Dzukou Valley, Nagaland :</b> Wander through the enchanting Dzukou Valley, celebrated as one of India's most beautiful flower valleys. <br /><br /></li>
                    <li><b>Nohkalikai Falls, Meghalaya :</b> Witness the awe-inspiring Nohkalikai Falls, the tallest plunge waterfall in India. <br /><br /></li>
                    <li><b>Mechuka Landscapes, Arunachal Pradesh :</b> Discover the breath-taking landscapes of Mechuka, known as the "Switzerland of India." <br /><br /></li>
                    <li><b>Kaziranga National Park :</b> Encounter the diverse wildlife, including the iconic One-Horned Rhinoceros, at Kaziranga. <br /><br /></li>
                    <li><b>Cherrapunji :</b> Experience the mystical beauty of Cherrapunji, the wettest place on Earth. <br /><br /></li>
                    <li><b>Dawki :</b> Behold the crystal-clear waters of Dawki, recognized as one of the cleanest rivers globally. <br /><br /></li>
                    <li><b>Tawang Monastery, Arunachal Pradesh :</b> Marvel at the historical and spiritual significance of Tawang Monastery. <br /><br /></li>
                    <li><b>Ziro Valley, Arunachal Pradesh :</b> Immerse yourself in the cultural richness of Ziro Valley, the native place for the Apatani tribe. <br /><br /></li>
                    <li><b>Gorichen Peak :</b> Conquer the heights of Gorichen Peak, standing tall at 22,500 feet between Tawang and West Kameng districts<br /><br /></li>
                    <li><b>Umiam Lake :</b> Enjoy the tranquil surroundings of Umiam Lake, a man-made reservoir located 5 kilometers north of Shillong.<br /><br /></li>
                    <li><b>Mawsynram :</b> Witness the relentless beauty of Mawsynram, the wettest place on Earth<br /><br /></li>
                    <li><b>Mawlynnong Village :</b> Experience the eco-friendly charm of Mawlynnong, recognized as the cleanest village in India and Asia. <br /><br /></li>
                    <li><b>Dibrugarh :</b> Serving as the gateway, Dibrugarh invites you to savor its tea heritage, alongside serene riverfronts and a touch of urbanity. <br /><br /></li></ul>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div className="card-header custHeaer">
                  <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}>ADVENTURE ACTIVITIES TO INDULGE WITH <strong>SHILLONG MEGHALAYA TOUR FROM GUWAHATI</strong>?</h3>
                  <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}>ADVENTURE ACTIVITIES TO INDULGE WITH <strong>SHILLONG MEGHALAYA TOUR FROM GUWAHATI</strong>?</h3>
                </div>

                <div className="card-body">
                  <p>Diverse landscapes of Meghalaya makes it an adventure seeker's paradise, with activities catering to various levels of expertise and preferences. <br /><br /></p>
                  <ul><li><b>Trekking :</b> Embark on the David Scott Trail in Meghalaya, a 16km trek from Mawphlang to Lad-Mawphlang. It is a historical trek passing through scenic landscapes and ancient Khasi villages. <br /><br /></li>
                    <li><b>White Water River Rafting :</b> Conquer the rapids of Jia-Bhoroli river in Nameri, offering an exhilarating rafting experience amid breathtaking scenery. River Rafting also can be experienced at Dawki river. <br /><br /></li>
                    <li><b>Mountaineering :</b> Explore the Garo Hills in Meghalaya for a unique mountaineering experience, with Nokrek Peak [highest peak of the Garo hills, 1,412 metres] providing a challenging ascent. <br /><br /></li>
                    <li><b>Caving :</b> Delve into the mystical Mawsmai Caves in Meghalaya, known for their impressive limestone formations and intriguing passages. <br /><br /></li>
                    <li><b>Wildlife Safaris :</b> Immerse yourself in the biodiversity of Kaziranga National Park in Assam, home to the iconic one-horned rhinoceros and diverse wildlife. <br /><br /></li>
                    <li><b>Mountain Biking :</b> Ride through the scenic hills of Meghalaya, particularly in Shillong and its surrounding areas, offering diverse terrains for mountain biking. <br /><br /></li>
                    <li><b>Fishing and Angling :</b> Enjoy angling in the Jia-Bhoroli river in Nameri and Umngot River in Meghalaya, known for its crystal-clear waters and the opportunity to catch various fish species. <br /><br /></li>
                    <li><b>Rock Climbing :</b> Conquer the rock faces of Meghalaya's Sohra, also known as Cherrapunjee, offering challenging routes for rock climbing enthusiasts. <br /><br /></li>
                    <li><b>Bird Watching :</b> Explore the Nongkhyllem Wildlife Sanctuary in Meghalaya, a haven for bird watchers with its diverse avian population. <br /><br /></li>
                    <li><b>Motorcycling Expeditions :</b> Ride through the scenic roads of Assam, particularly in the Kaziranga National Park area, experiencing the beauty of the region on a motorcycle. <br /><br /></li>
                    <li><b>Camping :</b> Set up camp near the picturesque Dawki River in Meghalaya, surrounded by serene landscapes and the tranquility of nature. <br /><br /></li></ul>

                </div>
              </div>

              {/* <!--start of package details--> */}

              {/* <!--end of package details--> */}
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>SOME OFFBEAT PLACES TO EXPLORE IN <strong>NORTH-EAST INDIA TOUR FROM GUWAHATI</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>SOME OFFBEAT PLACES TO EXPLORE IN <strong>NORTH-EAST INDIA TOUR FROM GUWAHATI</strong>?</h4>
                </div>
                <div className="card-body">
                  <p>Offbeat destinations in Meghalaya provide a chance to explore untouched nature, engage with indigenous cultures, and experience a different side of the state beyond the more well-known tourist spots. Meghalaya is replete with hidden gems that offer unique and offbeat experiences. Here are some lesser-known Offbeat places you can explore during Meghalaya Tour: <br /><br /></p>
                  <ul><li><b>Jaintia Hills :</b> Explore the lesser-known Jaintia Hills, where you can discover the Krang Suri Falls, a hidden gem surrounded by dense forests and natural pools. <br /><br /></li>
                    <li><b>Mawphlang Sacred Forest :</b> Delve into the mystical Mawphlang Sacred Forest, a biodiversity hotspot with ancient trees, unique flora, and an ethereal atmosphere. <br /><br /></li>
                    <li><b>Baghmara :</b> Head to Baghmara in the South Garo Hills for the Sasatgre village, where you can witness the age-old tradition of beekeeping in tree trunks. <br /><br /></li>
                    <li><b>Siju Caves :</b> Explore the remote Siju Caves, part of the Siju Wildlife Sanctuary near Napak Lake and Simsang River game reserve, featuring impressive limestone formations and a bat population. <br /><br /></li>
                    <li><b>Balpakram National Park :</b> Discover Balpakram National Park, a lesser-visited destination known for its deep gorges, rugged landscapes, and diverse wildlife. <br /><br /></li>
                    <li><b>Nartiang Monoliths :</b> Visit Nartiang to witness the ancient monoliths, a collection of towering stone pillars, some dating back centuries, providing insight into the Jaintia culture. <br /><br /></li>
                    <li><b>Sohra Market :</b> Experience the local life at the bustling Sohra market, where you can interact with locals, taste traditional Khasi cuisine, and shop for handicrafts. <br /><br /></li>
                    <li><b>Ranikor :</b> Head to Ranikor for the stunning Umiew River and the Ranikor Beach, a hidden gem with serene surroundings and a peaceful atmosphere. <br /><br /></li>
                    <li><b>Lyngkhoi Village :</b> Discover the quaint Lyngkhei village located in Mawphlang subdivision of East Khasi Hills district known for its traditional Khasi architecture, serene surroundings, and a glimpse into the rural way of life. <br /><br /></li>
                    <li><b>Mendipathar :</b> Explore Mendipathar for its tranquil beauty, surrounded by lush greenery and dotted with charming villages, providing an authentic Meghalayan experience. <br /><br /></li>
                    <li><b>Mairang :</b> Visit Mairang to explore the Nartiang Durga Temple and the adjacent Nartiang Monoliths, which hold historical and cultural significance. <br /><br /></li>
                    <li><b>Nongkhnum Island :</b> Escape to the largest river island in Meghalaya, Nongkhnum, offering a peaceful retreat with sandy beaches and scenic landscapes.<br /><br /></li>
                    <li><b>Rongjeng Rock :</b> Trek to Rongjeng Rock, a lesser-known destination in the East Garo Hills, known for its impressive rock formations and panoramic views.<br /><br /></li>
                    <li><b>Garo Hills Wildlife Sanctuary :</b> Discover the biodiversity of Garo Hills Wildlife Sanctuary, home to various flora and fauna, including elephants, tigers, and rare bird species. <br /><br /></li></ul>
                </div>
              </div>
              {/* <!--start of package details--> */}

              {/* <!--end of package details--> */}
              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>Frequently Ask <span>Questions</span></h2>
                      <h4><strong>Arunachal Pradesh tour from Kolkata</strong> Trip Related Questions</h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion" id="accordionPanelsStayOpenExample">
                      <div className="accordion" id="accordionFlushExample">

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading1">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
                              Is it safe for a solo women traveler to visit North East?
                            </button>
                          </h4>
                          <div id="flush-collapse1" className="accordion-collapse collapse show" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              Yes, every nook and corner of North East is safe, and we encourage solo women travelers from India and beyond to visit and enjoy the calmness of this region.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading2">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
                              Which are the best spots to watch birds during my North East trip?
                            </button>
                          </h4>
                          <div id="flush-collapse2" className="accordion-collapse collapse show" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              Kaziranga National Park, Nameri National Park, Eagle Nest Wildlife Sanctuary, and Dipor Bil are well-known places among bird watchers, famous for their variety of exotic birds and scenic beauty.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading3">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                              Can I do trekking during my North East trip?
                            </button>
                          </h4>
                          <div id="flush-collapse3" className="accordion-collapse collapse show" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              North East offers exciting trekking routes, and trekking can be a part of your tour package with prior coordination with your tour provider.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading4">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                              Can we do river rafting during our North East trip?
                            </button>
                          </h4>
                          <div id="flush-collapse4" className="accordion-collapse collapse show" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              Yes, you can enjoy river rafting at popular spots like Aalong, Pasighat, and Nameri, known for their thrilling rapids.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading5">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                              Which are the North East states of India? Why are they called the Seven Sisters?
                            </button>
                          </h4>
                          <div id="flush-collapse5" className="accordion-collapse collapse show" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              The North East region comprises eight states: Arunachal Pradesh, Assam, Manipur, Meghalaya, Mizoram, Nagaland, Sikkim, and Tripura. The term 'Seven Sisters' refers to Arunachal Pradesh, Assam, Manipur, Meghalaya, Mizoram, Nagaland, and Tripura due to their socio-economic and cultural similarities.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading6">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse6" aria-expanded="false" aria-controls="flush-collapse6">
                              Do I require a permit to visit North East India?
                            </button>
                          </h4>
                          <div id="flush-collapse6" className="accordion-collapse collapse show" aria-labelledby="flush-heading6" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              Yes, certain states require permits. Indian and foreign nationals need an ILP (Inner Line Permit), while PAP (Protected Area Permit) is required only for foreign tourists. No permit is needed for Meghalaya, Assam, and Tripura.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading7">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse7" aria-expanded="false" aria-controls="flush-collapse7">
                              What are the best things to do in Dawki?
                            </button>
                          </h4>
                          <div id="flush-collapse7" className="accordion-collapse collapse show" aria-labelledby="flush-heading7" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              Dawki is famous for its crystal-clear river. You can enjoy boating, visit a small island, and try activities like snorkeling, zip-lining, and rappelling.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h4 className="accordion-header" id="flush-heading8">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse8" aria-expanded="false" aria-controls="flush-collapse8">
                              Are medical shops available widely across Arunachal Pradesh?
                            </button>
                          </h4>
                          <div id="flush-collapse8" className="accordion-collapse collapse show" aria-labelledby="flush-heading8" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              It is recommended to carry prescribed and general medicines, as many remote areas in Arunachal Pradesh may lack proper medical shops.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3><b> North East India Tour<span class="tomato"> Holiday Reviews</span></b></h3>
                      <h4><i>"Your Experience is our Marketing"</i></h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1">
                  <div id="testimonials">
                    <div className="testimonial-box-container">

                      {reviewersDataView}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div >
        </div >
        <div className="btn-whatsapp-pulse">
          <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>
        <div class="btn-call-pulse">
          <a style={{ color: 'white' }} href="tel:+91-967473-8480">
            <i className="fa fa-phone"></i>
          </a>
        </div>
      </>
    );
  }
}
export default NorthEastIndiaTour_10N_11D_Package;
