import React, { Component, useState } from 'react';
import BookingBox from '../BookingBox';
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Bhutan/InclusionExclusion_bhutanPackageTourGuwahati';

class Bhutan_PackageTourGuwahati extends Component {
    constructor(props) {
        super(props);

        this.state = {
            btn: "Submit",
            showLoader: false,
            isHidden: true,
            showModal: false,
            showMegamenu: false, // New state to handle megamenu
            firstName: '',
            mobile: '',
            email: '',
            comments: '',
            megamenuTimeout: null // New state for managing hover delay
        }
        this.timeoutId = null; // To store the timeout ID
        this.modalRef = React.createRef(); // Create a ref for the modal
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919883359713&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ showModal: true });
        }, 55000);
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        // Cleanup the event listener
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        // Check if the click is outside the modal
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.onCloseModal();
        }
    };

    toggleHidden = (e) => {
        e.preventDefault();
        this.setState({ isHidden: !this.state.isHidden });
    }

    handleMegamenuEnter = () => {
        // Clear any existing timeout
        if (this.state.timeoutId) {
            clearTimeout(this.state.timeoutId);
        }
        this.setState({ isHidden: false });
    };

    handleMegamenuLeave = () => {
        // Set a timeout to delay hiding the megamenu
        const timeoutId = setTimeout(() => {
            this.setState({ isHidden: true });
        }, 300); // Match this delay with the CSS transition time

        this.setState({ timeoutId });
    };


    onRequestCallback = (e) => {
        e.preventDefault();
        if (!this.state.showModal) {
            this.setState({ showModal: true });
        }
    }

    onCloseModal = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            showModal: false,
            firstName: '',
            mobile: '',
            email: '',
            comments: ''
        });
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    // Function to handle form submission
    onRequestCallbackSubmit = async (e) => {
        e.preventDefault();

        const { firstName, email, mobile, comments } = this.state;

        // Validation
        if (firstName.trim() === '') {
            cogoToast.error("Full Name Required.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
            // } else if (email.trim() === '') {
            //     cogoToast.error("Email Required.", {
            //         position: 'top-center', // Use top-center, then adjust via CSS
            //         hideAfter: 2, // optional: auto-hide after 2 seconds
            //         className: 'custom-toast' // Apply custom class
            //     });
        } else if (mobile.trim() === '' || !/^\d{10}$/.test(mobile.trim())) {
            cogoToast.error("Please Insert 10 digit Mobile No.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else {
            this.setState({ btn: "Processing..", showLoader: true });
            // Proceed with form submission
            try {
                const _ref = 'yes';
                const _url = window.location.href;

                const formData = {
                    FirstName: firstName,
                    Email: email,
                    Mobile: mobile,
                    Comments: comments,
                    Referrer: _ref,
                    URL: _url
                };

                const response = await axios.post(ApiURL.SaveCustomerEnquery, formData);
                console.log('Data submitted successfully:', response.data);
                // Optionally, reset the form state after successful submission
                this.setState({ firstName: '', email: '', mobile: '', comments: '' });

                // Optionally, show a success message to the user
                cogoToast.success("Form submitted successfully.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
                this.setState({ btn: "Submit", showLoader: false, showModal: false });
            } catch (error) {
                console.error('Error submitting data:', error);
                this.setState({ btn: "Submit", showLoader: false });
                // Optionally, show an error message to the user
                cogoToast.error("Please try again after 24 hrs. later.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
            }

        }

    };

    /* RedirectToOTPPage = () => {
        if (this.state.redirectOTPStatus === true) {
            let url = "/verifyotp/" + this.state.mobile;
            return <Redirect to={url} />
        }
    } */

    onCloseModal = () => {
        this.setState({ showModal: false }, () => {
            // Additional code to perform after the modal is closed
            setTimeout(() => {
                this.setState({ showModal: true }, () => {
                    // Additional code to perform after the next modal is displayed
                });
            }, 55000); // 55sec in milliseconds
        });
    };

    render() {
        const packageTopBannerImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-guwahati-mobile-long-banner.webp";
        const packageTopBanner2ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-guwahati-mobile-banner.webp";
        const FlightBannerImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/Bhutan-Group-Tour-Package-from-Mumbai.webp";
        const navDay1ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/guwahati-to-bhutan-tour-packages-with-direct-flights-with-adorable-vacation.webp";
        const navDay2ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/guwahati-to-bhutan-tour-packages-booking-with-direct-flights-with-adorable-vacation.webp";
        const navDay3ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/guwahati-to-bhutan-tour-package-cost-with-direct-flights-with-adorable-vacation.webp";
        const navDay4ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/guwahati-to-bhutan-tour-package-itinerary-with-direct-flights-with-adorable-vacation.webp";
        const navDay5ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/guwahati-to-bhutan-tour-package-plan-with-direct-flights-with-adorable-vacation.webp";
        const navDay6ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/guwahati-to-bhutan-tour-package-price-with-direct-flights-with-adorable-vacation.webp";
        const navDay7ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-3-nights-4-days-package.webp";
        const navDay8ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-tour-3-days.webp";
        const CallButtonSoumalyaImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/call-button-bablu-adorable-vacation.webp";
        const CallButtonSonaliImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/call-button-sonali-adorable-vacation.webp";
        const PriceImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-tour-from-guwahati-call-price-button.webp";
        const sightseen1ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-package-from-guwahati-8.webp";
        const sightseen2ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-package-tour-from-guwahati-airport-with-adorable-vacation-llp-9.webp";
        const sightseen3ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-package-tour-from-guwahati-with-adorable-vacation-8.webp";
        const sightseen4ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-package-tours-from-guwahati-airport-with-adorable-vacation-10.webp";
        const sightseen5ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-tour-booking-from-guwahati-7.webp";
        const sightseen6ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-trip-tour-from-guwahati-with-adorable-vacation-9.webp";
        const sightseen7ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/guwahati-to-bhutan-tour-by-direct-flight-11.webp";
        const sightseen8ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/travel-bhutan-from-guwahati-with-adorable-vacation.webp";
        const sightseen9ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-trip-from-guwahati-airport-with-adorable-vacation-4.webp";
        const sightseen10ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-tour-itinerary-from-guwahati-airport-with-adorable-vacation-5.webp";
        const sightseen11ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-tour-from-guwahati-airport-with-adorable-vacation-2.webp";
        const sightseen12ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-tour-cost-from-guwahati-airport-with-adorable-vacation-6.webp";
        const sightseen13ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-package-tour-from-guwahati-airport-with-adorable-vacation-1.webp";
        const sightseen14ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-package-from-guwahati-airport-with-adorable-vacation-3.webp";
        const sightseen15ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-tour-booking-from-guwahati-during-summer-with-adorable-vacation.webp";
        const sightseen16ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-tour-from-guwahati-during-summer-with-adorable-vacation.webp";
        const sightseen17ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-package-tour-from-guwahati-with-direct-flight-in-winter.webp";
        const sightseen18ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-tour-booking-from-guwahati-during-spring-with-adorable-vacation.webp";
        const sightseen19ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-tour-from-guwahati-during-spring-with-adorable-vacation.webp";
        const sightseen20ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-tour-booking-from-guwahati-with-direct-flights.webp";
        const sightseen21ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-tour-from-guwahati-with-direct-flight-tickets.webp";
        const sightseen22ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-trip-from-guwahati-with-direct-flights.webp";
        const flight1ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-package-from-guwahati-with-direct-flight.webp";
        const flight2ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-tour-from-guwahati-with-direct-flight.webp";
        const flight3ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-trip-from-guwahati-with-direct-flight.webp";
        const hotel1ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-hotel-ugyen-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const hotel2ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-hotel-vara-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const hotel3ImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/bhutan-hotel-mandala-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const rows = [
            { date: '24th Sept - 30th Sept', availability: 'Available', price: '84,500' },
            { date: '01st Oct - 07th Oct', availability: 'Available', price: '84,500' },
            { date: '04th Oct - 10th Oct', availability: 'Available', price: '84,500' },
            { date: '08th Oct - 14th Oct', availability: 'Available', price: '84,500' },
            { date: '11th Oct - 17th Oct', availability: 'Available', price: '84,500' },
            { date: '15th Oct - 21st Oct', availability: 'Available', price: '84,500' },
            { date: '18th Oct - 24th Oct', availability: 'Available', price: '84,500' },
            { date: '22nd Oct - 28th Oct', availability: 'Available', price: '84,500' },
            { date: '25th Oct - 31st Oct', availability: 'Available', price: '84,500' },
            { date: '29th Oct - 04th Nov', availability: 'Available', price: '84,500' },
            { date: '1st Nov - 07th Nov', availability: 'Available', price: '84,500' },
            { date: '05th Nov - 11th Nov', availability: 'Available', price: '84,500' },
        ];

        const rowsPerPage = 6;
        const totalPages = Math.ceil(rows.length / rowsPerPage);
        const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
        const { customActiveTab, daywiseActiveTab } = this.state;

        const hotPackagesDataList = this.props.HotPackagesData;
        const hotPackagesView = hotPackagesDataList.length > 0 ?
            (
                hotPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.FIFO_6N_7D_ImageURL + "/" + dataItem.ImageName;
                    const contactURL = dataItem.Url;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} | {dataItem.EXPARO}<span> &#8377; {dataItem.PricePerNight}</span> </h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue">{dataItem.SDFInclude}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href={contactURL} className="btn btn-Warning btn-sm float-end">Call Now</a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const themePackageSliderList = this.props.ThemePackagesData;
        const themePackagesDataView = themePackageSliderList.map(
            (sliderItem, i) => {
                const sliderImageURL =
                    ApiURL.BhutanTourPackageFromGuwahatiImageURL +
                    "/" +
                    sliderItem.ImageName;
                return (
                    <div className="theme-cards-wrapper mx-2" key={i}>
                        <div className="theme-card">
                            <div className="image-wrapper">
                                <img src={sliderImageURL} alt={sliderItem.AllternateText} />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">{sliderItem.Title}</h5>
                                <p className="card-text">{sliderItem.Description}</p>
                                <a href="#" className="btn btn-success btn-sm" onClick={this.handleClick1}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                );
            }
        );

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })


        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="Bhutan Packages From Guwahati tour from adorable vacation the best Bhutan Guwahati dmc" />

                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container">
                                        <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center>Book <strong>Bhutan Package Tour from Guwahati</strong> | with <strong>Guwahati - Paro Direct Flight Tickets</strong> </center></h1>
                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center>Book <strong>Bhutan Package Tour from Guwahati</strong> | with <strong>Guwahati - Paro Direct Flight Tickets</strong> </center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="Bhutan Package Tour From Guwahati from adorable vacation the best Bhutan Guwahati dmc in india" />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Cities:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Thimphu
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Paro
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Punakha
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Bhutan tour</strong>, you have come to the Right Place! Adorable vacation is the best DMC for <strong>Bhutan Package Tour From Guwahati</strong>
                                        </p>
                                        <hr />
                                        <br />
                                        <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}> Looking For <strong> Bhutan Tour Package from Guwahati </strong> with Direct Flight ?<br /><br /> For Booking <a href='tel:9883359713' class="reviews"><strong> ☎ CALL Mr. SOUMALYA +91-98833-59713</strong></a></h2>
                                        <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> Looking For <strong> Bhutan Tour Package from Guwahati </strong> with Direct Flight ?<br /><br /> For Booking <a href='tel:9883359713' class="reviews"><strong> ☎ CALL Mr. SOUMALYA +91-98833-59713</strong></a></h3>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="card">
                                <div className="card-body">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr className="yellow">
                                                <th rowSpan="2">DAY WISE SUMMERY FOR <strong>BHUTAN TOUR PACKAGES FROM GUWAHATI</strong></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingOne"
                                                >
                                                    <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                        <span>
                                                            ARRIVAL AT PARO AIRPORT AND THIMPHU TRANSFER
                                                        </span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingTwo"
                                                >
                                                    <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                        <span>THIMPHU LOCAL SIGHTSEEING</span>
                                                    </a>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingThree"
                                                >
                                                    <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                        <span>THIMPHU TO PUNAKHA TRANSFER & SIGHTSEEINGS</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingFour"
                                                >
                                                    <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                        <span>PUNAKHA TO PHOBJIKHA VALLEY DAY TRIP</span>
                                                    </a>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingFive"
                                                >
                                                    <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                        <span>PUNAKHA TO PARO TRANSFER & SIGHTSEEINGS</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingSix"
                                                >
                                                    <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                        <span>PARO TO CHELE-LA-PASS DAY TRIP / VISIT TAKTSANG MONASTERY</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingTen"
                                                >
                                                    <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                        <span>PARO AIRPORT TRANSFER [TOUR ENDS]</span>
                                                    </a>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <br />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br /><br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            <br /> <br />
                            {/* <!--end table inclusion exclusion--> */}
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="image-cell">
                                            <a href='#' onClick={this.onRequestCallback}><img src={PriceImageURL} alt="bhutan-tour-from-guwahati-call-price-button.webp" className="image-padding" /></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DURATION FOR <strong>GUWAHATI TO BHUTAN TOUR PACKAGE</strong> : 6N | 7D</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> DURATION FOR <strong>GUWAHATI TO BHUTAN TOUR PACKAGE</strong> : 6N | 7D</h3>
                                </div>
                                <div className="card-body">
                                    <h4>DESTINATIONS COVERED IN <strong>6 NIGHTS 7 DAYS BHUTAN PACKAGE FROM GUWAHATI</strong> : THIMPHU 1N | PUNAKHA 1N | PARO 3N <br /><br /></h4>

                                    <h4>PICKUP & DROP : PARO INTERNATIONAL AIRPORT </h4><br />
                                    <p><b>Bhutan</b>, also known as <b>The Land of the Thunder Dragon</b>, is a beautiful and magical country surrounded by the towering Himalayan mountains. If you want an adventure holiday that offers stunning natural beauty, rich culture, and peaceful spiritual experiences, Bhutan is the perfect place for you. Right now, Bhutan has direct flights from <b>Delhi, Kolkata, and Guwahati</b> to Paro Airport, which is the only international airport in Bhutan. This makes a <strong>Bhutan package tour from Guwahati</strong> a great and affordable way to visit Bhutan, the Land of the Thunder Dragon. <br /><br />

                                        Bhutan is known for its amazing landscapes, snow-capped mountains, peaceful valleys, rivers, beautiful dzongs (fortresses), monasteries, prayer flags, sacred lakes, and the always-smiling people. A <strong>Bhutan tour package from Guwahati</strong> is the best way to experience all of this. This <strong>tour from Guwahati</strong> will take you to some of Bhutan’s most stunning places, such as <strong>Paro, Thimphu, Punakha, Bumthang, Haa Valley, Phobjikha, Wangdue, Trongsa, Phuentsholing, Gasa, Dagana, Zhemgang, Lhuntse, Trashiyangtse, Trashigang, Damphu, Mongar, and Gangtey</strong>. Each of these places has something special to offer, from ancient monasteries to beautiful rivers and hills. <br /><br />

                                        <b>Paro</b>, located at an altitude of 2,250 meters, is one of Bhutan's most beautiful valleys. Here, you'll find the famous <strong>Tiger's Nest Monastery</strong>, which is one of the most sacred places in Bhutan. On your <strong>Bhutan package from Guwahati</strong>, you will have the chance to hike to Tiger's Nest and enjoy the stunning views of the valley below. <br /><br />

                                        <b>Thimphu</b>, Bhutan’s capital city, is a must-visit on your <strong>Bhutan tour from Guwahati</strong>. Thimphu blends modern life with traditional Bhutanese culture, making it an exciting place to explore. You can visit important attractions like <strong>Buddha Dordenma</strong>, <strong>Trashi Chho Dzong</strong>, <strong>Changangkha Lhakhang</strong>, <strong>Weekend Market</strong>, <strong>National Memorial Chorten</strong>, <strong>Motithang Takin Preserve</strong>, <strong>Dechen Phodrang</strong>, <strong>National Library of Bhutan</strong>, and many more. <br /><br />

                                        <b>Punakha</b>, located at 1,200 meters, is another beautiful valley in Bhutan, known for its amazing landscapes and cultural history. The <strong>Punakha Dzong</strong>, one of Bhutan’s most famous dzongs, is located here. It represents the spiritual and political strength of Bhutan. In addition to the dzong, you can explore other places like <strong>Khamsum Yulley Namgyal Chorten</strong>, <strong>Ritsha</strong>, <strong>Mo Chhu River</strong>, <strong>Chimi Lhakhang</strong>, <strong>Sangchhen Dorji Lhuendrup Lhakhang</strong>, <strong>Jigme Dorji National Park</strong>, <strong>Dochula Pass</strong>, and <strong>Limbhukha Village</strong>. <br /><br />

                                        <b>Gangtey</b>, located in the beautiful <strong>Phobjikha Valley</strong>, is another must-see spot on your <strong>Bhutan tour from Guwahati</strong>. The peaceful Gangtey Valley is home to the <strong>Gangtey Monastery</strong>, one of Bhutan's largest and most important monasteries. The valley is also famous for its black-necked cranes, which migrate here every winter. <br /><br />

                                        In conclusion, a <strong>Bhutan package tour from Guwahati</strong> is a convenient and affordable way to experience the beauty of Bhutan. With Adorable Vacation, the best <strong>Bhutan tour expert in India</strong>, and the top <strong>B2B DMC for Bhutan tours</strong>, you can explore Bhutan’s most beautiful destinations, rich culture, and peaceful spiritual sites. So, why wait? Book your <strong>Bhutan package tour from Guwahati</strong> today and experience the Land of the Thunder Dragon for yourself! <br /></p>
                                    <p>Apart from <strong>Bhutan Package Tour From Guwahati</strong>, Adorable Vacation offers various <strong>Bhutan tour packages</strong> like <a href="/bhutan-tour-package-from-pune" target="_blank"><b className="tomato">Bhutan Package Tour from Pune</b></a>,<a href="/bhutan-tour-from-delhi" target="_blank"><b className="tomato"> Bhutan Package Tour from Delhi</b></a>, <a href="/bhutan-tour-package-from-mumbai" target="_blank"><b className="tomato">Bhutan package tour from Mumbai</b></a>, <a href="/bhutan-tour-package-from-ahemdabad" target="_blank"><b className="tomato">Bhutan Package Tour from Ahmedabad</b></a>, <a href="/bhutan-tour-from-bengaluru" target="_blank"><b className="tomato">Bhutan Package Tour from Bangalore</b></a>, <a href="/bhutan-tour-package-from-jaigaon" target="_blank"><b className="tomato"> Bhutan Package Tour from Jaigaon</b></a>, <a href="/bhutan-tour-package-from-kolkata" target="_blank"><b className="tomato">Bhutan Package Tour from Kolkata</b></a>, <a href="/bhutan-tour-package-from-bagdogra" target="_blank"><b className="tomato">Bhutan Package Tour from Bagdogra Airport</b></a>, <a href="/bhutan-tour-package-from-surat" target="_blank"><b className="tomato">Bhutan Package Tour from Surat</b></a>, <a href="/mumbai-special-chartered-flight" target="_blank"><b className="tomato">Bhutan Package Tour from Mumbai with Chartered Flight</b></a>,<a href="/bhutan-b2b-package-tour-operator-in-india-best-bhutan-dmc" target="_blank"><b className="tomato">Bhutan B2B Package Tour</b></a>, etc at Best Price.</p>
                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen1ImageURL}
                                                        alt="adorable vacation - bhutan tour operator for b2b"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen2ImageURL}
                                                        alt="b2b travel agen for bhutan tour from india"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen3ImageURL}
                                                        alt="b2b travel agents for bhutan tour package booking"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {/* <br /><br /><br />
                                    <div class="d-grid gap-2 d-md-block">
                                        <a href="tel://9883359713" className="btn btn-Warning btn-lg">
                                            Talk To Our Bhutan Specialist Now
                                        </a>
                                    </div> */}
                                    <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>FAMOUS PLACES OF BHUTAN TO NOTE </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>FAMOUS PLACES OF BHUTAN TO NOTE</h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">

                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr><th>CITIES</th><th>ALTITUDE</th><th>COORDINATES</th></tr>
                                                <tr><td> Thimphu</td><td>2,334 m</td><td>27.4712° N, 89.6339° E</td></tr>
                                                <tr><td>Paro</td><td>2200 m</td><td>27.4287° N, 89.4164° E</td></tr>
                                                <tr><td>Punakha</td><td>1,274 m</td><td>27.5921° N, 89.8797° E</td></tr>
                                                <tr><td>Gasa</td><td>2,850 m</td><td>27.8983° N, 89.7310° E</td></tr>
                                                <tr><td>Dagana</td><td>1,464 m</td><td>27.0970° N, 89.8739° E</td></tr>
                                                <tr><td>Tigers Nest Monastery</td><td>3,120 m</td><td>27.2930° N, 89.2148° E</td></tr>
                                                <tr><td>Chele-La-Pass</td><td>3,988 m</td><td>27.3698° N, 89.3466° E</td></tr>
                                                <tr><td>Haa Valley</td><td>2,670 m</td><td>27.3333° N, 89.1833° E</td></tr>
                                                <tr><td>Dochula Pass</td><td>3,100 m</td><td>27.2924° N, 89.4501° E</td></tr>
                                                <tr><td>Phobjikha Valley</td><td>3,000 m</td><td>27.4594° N, 90.1802° E</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <br /><br />
                                <table style={{ width: '100%', textAlign: 'center' }}>
                                    <tbody>
                                        <tr>
                                            <td className="image-cell" style={{ textAlign: 'center' }}>
                                                <img
                                                    src={sightseen4ImageURL}
                                                    alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"
                                                    style={{ margin: '10px' }}
                                                />
                                                <img
                                                    src={sightseen5ImageURL}
                                                    alt="Bhutan Guwahati travel packages"
                                                    style={{ margin: '10px' }}
                                                />
                                                <img
                                                    src={sightseen6ImageURL}
                                                    alt="travel bhutan with best b2b dmc - adorable vacation"
                                                    style={{ margin: '10px' }}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br /><br /><br />

                                <h3 className='site-heading text-center'><b >Wonderful <span className='tomato'>Bhutan Tour Review from Mumbai</span> from our esteemed Guests Mr. & Mrs. Dalal</b><br /></h3>
                                <p className='p-2'><center>We are thrilled to share a heartfelt review for <strong>Bhutan tour from Mumbai</strong> from our valued guests, Mr. & Mrs. Dalal. Their experience highlights the exceptional service and memorable moments they encountered during their <a href='/mumbai-special-chartered-flight' target='_blank'><strong>Bhutan tour from Mumbai with Direct Flight</strong></a>. We take great pride in ensuring that every guest has a delightful and fulfilling journey, and it's always rewarding to hear such positive feedback.</center></p><br /><br />
                                <div class="video-container">
                                    <iframe
                                        src="https://www.youtube.com/embed/19drRVnSMCs?si=Uj5Fm4uSuwPi0SBM"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen>
                                    </iframe>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHY ADORABLE VACATION IS THE BEST CHOICE FOR BOOKING <strong>A BHUTAN TOUR FROM GUWAHATI AIRPORT</strong>? </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> WHY ADORABLE VACATION IS THE BEST CHOICE FOR BOOKING <strong>A BHUTAN TOUR FROM GUWAHATI AIRPORT</strong>? </h3>
                                </div>
                                <div className="card-body">
                                    <ul>
                                        <li><b>Trusted Brand : </b> Adorable Vacation has been the most trusted brand for <strong>Bhutan tour from Guwahati</strong>. <br /><br /></li>
                                        <li><b>Passenger Service Agent (PSA) : </b> We are proud to be the PSA for Bhutan Airlines and Druk Airlines. <br /><br /></li>
                                        <li><b>Luxury Packages : </b> Experience the best of Bhutan with our luxury packages featuring 5-star Luxury Hotels, including Six Senses, Amankora, COMO Uma, Le Méridien, Le Méridien Paro, Riverfront, and Pemako. <br /><br /></li>
                                        <li><b>Super Peak Season Inventory : </b> We have pre-purchased hotel room inventory for peak season tours, along with chartered flight tickets. <br /><br /></li>
                                        <li><b>Authorized Travel House : </b> We are an authorized travel house by the Tourism Council of Bhutan [License No: 1053228]. <br /><br /></li>
                                        <li><b>Direct Employment : </b> Our employees in Bhutan are directly on our payroll, ensuring quality service.
                                            <br /><br /></li>
                                        <li><b>Customized Itineraries : </b> We offer tailored Bhutan tour itineraries from Guwahati with unique and signature programs. <br /><br /></li>
                                        <li><b>In-Depth Knowledge : </b> Our team possesses extensive knowledge about Bhutan's territory, hotels, and local laws. <br /><br /></li>
                                        <li><b>Assured Services : </b> We ensure premium services to enrich your experience while in Bhutan. <br /><br /></li>
                                        <li><b>E-Permit Processing : </b> We handle the e-permit process in advance for all your sightseeing needs. <br /><br /></li>
                                        <li><b>Personalized Attention : </b> Enjoy safe and secure personal attention throughout your stay in Bhutan. <br /><br /></li>
                                        <li><b>Complete Responsibility : </b> Adorable Vacation takes full responsibility for your entire Adorable Vacation to Bhutan tour with Chartered Flights. <br /><br /></li>
                                        <li><b>Hassle-Free Experience : </b> We guarantee hassle-free and trustworthy services in Bhutan. <br /><br /></li>
                                        <li><b>Best Deals : </b> Access the best deals for top properties and services based on your preferences. <br /><br /></li>
                                        <li><b>Flexible Travel Options : </b>Plan a customized Bhutan private trip or join a Bhutan group tour with Adorable Vacation. <br /><br /></li>
                                    </ul>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> QUICK FACTS NEED TO KNOW BEFORE PLANNING  <strong>BHUTAN PACKAGE TOUR FROM GUWAHATI</strong> </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> QUICK FACTS NEED TO KNOW BEFORE PLANNING  <strong>BHUTAN PACKAGE TOUR FROM GUWAHATI</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">

                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr><th>Fact</th><th>Details</th></tr>
                                                <tr><td>Capital</td><td>Thimphu</td></tr>
                                                <tr><td>Largest Cities</td><td>Thimphu, Punakha, Paro, Phuentsholing, Samdrup Jongkhar, Wangdue Phodrang, Samtse, Jakar </td></tr>
                                                <tr><td>Official Language</td><td>Dzongkha</td></tr>
                                                <tr><td>Population</td><td>7.87 lakhs (2023)</td></tr>
                                                <tr><td>Area</td><td>38,394 km²</td></tr>
                                                <tr><td>Currency</td><td>The ngultrum (BTN)</td></tr>

                                                <tr><td>Independence Day</td><td>National Day is a public holiday in the Kingdom of Bhutan on December 17th each year</td></tr>
                                                <tr><td>National Flower</td><td>Himalayan blue poppy</td></tr>
                                                <tr><td>National Animal</td><td>the Takin</td></tr>
                                                <tr><td>Major Religion</td><td>Buddhism</td></tr>
                                                <tr><td>UNESCO World Heritage Sites</td>
                                                    <td>Ancient Ruin of Drukgyel Dzong <br /><br />
                                                        Dzongs: Punakha Dzong, Wangdue Phodrang Dzong, Paro Dzong, Trongsa Dzong and Dagana Dzong<br/><br/>
                                                        Sacred Sites associated with Phajo Drugom Zhigpo and his descendants<br/><br/>
                                                        Tamzhing Monastery<br/>
                                                        Royal Manas National Park (RMNP)<br/>
                                                        Jigme Dorji National Park (JDNP)<br/>
                                                        Bumdeling Wildlife Sanctuary
                                                        Sakteng Wildlife Sanctuary (SWS)<br/></td></tr>

                                                <tr><td>Famous Cuisine</td><td>Ema datshi, Jasha Maroo, Phaksha paa, Kewa Datshi, Shakam datshi, Shamu Datshi</td></tr>
                                                <tr><td>Notable Attractions in Bhutan</td><td>
                                                    Paro Taktsang, Dochula Pass, Punakha Dzong, National Memorial Chhorten, Tashichho Dzong, National Museum of Bhutan, Gangtey Monastery, Punakha Suspension Bridge, Royal Textile Academy, Trashigang Dzong, Ura valley, Dechen Phodrang Monastery, National Library & Archives Of Bhutan</td></tr>
                                                <tr><td>Notable River of Bhutan</td><td>Manas River, Mo Chhu, Paro Chu, Jaldhaka, Pho Chhu, Tang Chhu River, Shil Torsa River, Raidak River</td></tr>
                                                <tr><td>Highest Mountain</td><td>Kangkar Pünzum [7,570 metres]</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen7ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen8ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen9ImageURL}
                                                        alt="travel bhutan with best b2b dmc - adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br /><br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>BHUTAN PACKAGE TOUR COST FROM GUWAHATI </strong>WITH 3 STAR CATEGORY HOTELS</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BHUTAN PACKAGE TOUR COST FROM GUWAHATI </strong>WITH 3 STAR CATEGORY HOTELS</h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th className="text-center">No of Pax</th>
                                                    <th className="text-center">Bhutan Package Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="text-center">
                                                    <td>02 Adults</td>
                                                    <td>57,900/P</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <td>04 Adults</td>
                                                    <td>44,600/P</td>
                                                </tr>
                                                <tr className="text-center">
                                                    <td>06 Adults</td>
                                                    <td>39,900/P</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHY <strong>BHUTAN PACKAGE TOUR FROM GUWAHATI AIRPORT</strong> WITH GUWAHATI - PARO DIRECT FLIGHT IS THE BEST OPTION? </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> WHY <strong>BHUTAN PACKAGE TOUR FROM GUWAHATI AIRPORT</strong> WITH GUWAHATI - PARO DIRECT FLIGHT IS THE BEST OPTION? </h3>
                                </div>
                                <div className="card-body">
                                    <p><b>Bhutan</b> is a beautiful country, known as one of the cleanest in Asia, with untouched nature and a peaceful atmosphere. It's a popular travel destination for 2024/25 holidays. Now, you can travel to this amazing place from many major cities in India, including Mumbai, Pune, Delhi, Kolkata, Bangalore, Chennai, Hyderabad, and Guwahati. Among these, the <strong>Bhutan Package Tour from Guwahati</strong> is considered the best option. But why is that? <br /><br />

                                        The reason is simple: only three cities in India [Delhi, Kolkata, and Guwahati] have direct flights connectivity to Bhutan <br /><br />

                                        A direct flight from Delhi to Paro can be quite expensive, with return fares around Rs. 48,800 per person. On the other hand, a return flight from Guwahati to Paro costs only Rs. 22,600 per person. If you're traveling from Delhi, you can fly to Guwahati first for about Rs. 10,000 return. So, if you fly from Delhi to Paro via Guwahati, the total cost comes to Rs. 32,600 per person (Rs. 22,600 + Rs. 10,000). This means you save nearly Rs. 15,000 per person! For three people, that’s a savings of around Rs. 50,000! Isn't that amazing? <br /><br />

                                        So, for travelers from Delhi, booking a <strong>Bhutan Package via Guwahati Airport</strong> is the best option to save money and enjoy a wonderful trip. The same procedure can be applicable for Mumbai & Bangalore travelers also. <br /><br /></p>


                                    <h4 className='dark-blue'>FOLLOWING ARE THE FLIGHTS FROM DELHI TO GUWAHATI, GUEST CAN OPT FOR <br /> <br /></h4>
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf"><tr><td><b>Airlines</b></td><td><b>Origin</b></td><td><b>Departure</b></td><td><b>Destination</b></td><td><b>Arrival</b></td><td><b>Duration</b></td><td><b>Fare [approx]</b></td></tr></thead>
                                            <tbody><tr><td>IndiGo</td><td>Delhi [DEL]</td><td>04:55</td><td>Guwahati [GAU]</td><td>07:20</td><td>02 h 25 m (non-stop)</td><td>Rs. 5,489/-</td></tr>
                                                <tr><td>IndiGo</td><td>Delhi [DEL]</td><td>07:20</td><td>Guwahati [GAU]</td><td>09:45</td><td>02 h 25 m (non-stop)</td><td>Rs. 5,489/-</td></tr>
                                                <tr><td>Air Asia</td><td>Delhi [DEL]</td><td>07:20</td><td>Guwahati [GAU]</td><td>09:45</td><td>02 h 25 m (non-stop)</td><td>Rs. 5,489/-</td></tr>
                                                <tr><td>Air Asia</td><td>Delhi [DEL]</td><td>05:45</td><td>Guwahati [GAU]</td><td>08:15</td><td>02 h 30 m (non-stop)</td><td>Rs. 5,489/-</td></tr>
                                                <tr><td>Air India</td><td>Delhi [DEL]</td><td>09:30</td><td>Guwahati [GAU]</td><td>12:05</td><td>02 h 35 m (non-stop)</td><td>Rs. 6,690/-</td></tr>
                                                <tr><td>SpiceJet</td><td>Delhi [DEL]</td><td>10:20</td><td>Guwahati [GAU]</td><td>12:35</td><td>02 h 15 m (non-stop)</td><td>Rs. 7,884/-</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                    <p>The best part is, the <strong>Guwahati to Paro Direct Flight</strong> departs at 3:20 PM every Sunday. This gives guests plenty of time to reach <strong>Guwahati Airport from Delhi by morning flight</strong> and catch the flight to Bhutan. <br /><br />

                                        An even more exciting update is that, starting April 6th, there will be an additional <strong>Guwahati to Paro Direct Flight</strong>. This new flight will depart Guwahati on Thursdays, and the return flight from <strong>Paro to Guwahati</strong> will be on Wednesdays. So, the <b>Guwahati to Paro Flight Schedule</b> will be as follows: <br /><br /></p>
                                    <div id="no-more-tables" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf"><tr><td><b>Airlines</b></td><td><b>Flight No</b></td><td><b>Origin</b></td><td><b>Departure</b></td><td><b>Destination</b></td><td><b>Dep Days</b></td></tr></thead>
                                            <tbody><tr><td>Drukair-Royal Bhutan Airlines</td><td>KB 541</td><td>[GAU]</td><td>15:20</td><td>[PBH]</td><td>Every Sunday</td></tr>
                                                <tr><td>Drukair-Royal Bhutan Airlines</td><td>KB 541</td><td>[GAU]</td><td>15:20</td><td>[PBH]</td><td>Every Thursday [Start from 6th April]</td></tr>
                                                <tr><td>Drukair-Royal Bhutan Airlines</td><td>KB 540</td><td>[PBH]</td><td>08:05</td><td>[GAU]</td><td>Every Saturday</td></tr>
                                                <tr><td>Drukair-Royal Bhutan Airlines</td><td>KB 540</td><td>[PBH]</td><td>08:05</td><td>[GAU]</td><td>Every Wednesday [Start from 12th April]</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={flight1ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={flight2ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={flight3ImageURL}
                                                        alt="travel bhutan with best b2b dmc - adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br /><br />
                                    <p>We all know that <strong>Mumbai to Bhutan direct flight</strong> don't operate throughout the year. So, for <b>Mumbai to Bhutan travellers</b>, there is a great solution too. From Mumbai, there is a direct morning flight to Guwahati. The Indigo Airlines flight departs from Mumbai Terminal at around 06:10 AM and arrives at Guwahati Airport at 09:20 AM. The flight duration is approximately 3 hours and 10 minutes. According to international travel norms, passengers should arrive at the airport 3 hours before the flight time, so Mumbai travellers will have enough time to catch the flight from Guwahati to Paro on the same day. <br /><br />
                                        This means that Mumbai travellers can reach Bhutan on the same day by connecting through Guwahati, and it’s also a more cost-effective option. So, we can confidently say that booking a <strong>Bhutan Package Tour from Guwahati Airport</strong> is the best budget-friendly choice. <br /><br /></p>
                                    <h4 className='dark-blue'>FLIGHTS TO CHOOSE FROM DIFFERENT CITIES FOR <strong>BHUTAN PACKAGE TOUR FROM GUWAHATI</strong><br /><br /></h4>
                                    <div id="no-more-tables" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr>
                                                    <td>Airlines</td>
                                                    <td>Origin</td>
                                                    <td>Departure</td>
                                                    <td>Destination</td>
                                                    <td>Arrival</td>
                                                    <td>Duration</td>
                                                </tr></thead>
                                            <tbody>
                                                <tr><td>IndiGo</td><td>Mumbai [BOM]</td><td>06:25</td><td>Guwahati [GAU]</td><td>09:20</td><td>02 h 55 m (non-stop)</td></tr>
                                                <tr><td>SpiceJet</td><td>Mumbai [BOM]</td><td>06:45</td><td>Guwahati [GAU]</td><td>10:00</td><td>03 h 15 m (non-stop)</td></tr>
                                                <tr><td>AirAsia</td><td>Delhi [DEL]</td><td>06:05</td><td>Guwahati [GAU]</td><td>08:25</td><td>02 h 20 m (non-stop)</td></tr>
                                                <tr><td>AirAsia</td><td>Delhi [DEL]</td><td>07:15</td><td>Guwahati [GAU]</td><td>09:40</td><td>02 h 25 m (non-stop)</td></tr>
                                                <tr><td>IndiGo</td><td>Delhi [DEL]</td><td>05:00</td><td>Guwahati [GAU]</td><td>07:20</td><td>02 h 20 m (non-stop)</td></tr>
                                                <tr><td>IndiGo</td><td>Delhi [DEL]</td><td>07:25</td><td>Guwahati [GAU]</td><td>09:45</td><td>02 h 20 m (non-stop)</td></tr>
                                                <tr><td>AirAsia</td><td>Bangalore [BLR]</td><td>08:25</td><td>Guwahati [GAU]</td><td>11:20</td><td>02 h 55 m (non-stop)</td></tr>
                                                <tr><td>IndiGo</td><td>Bangalore [BLR]</td><td>08:20</td><td>Guwahati [GAU]</td><td>11:20</td><td>03 h 00 m (non-stop)</td></tr>
                                                <tr><td>IndiGo</td><td>Chennai [MAA]</td><td>05:30</td><td>Guwahati [GAU]</td><td>08:15</td><td>02 h 45 m (non-stop)</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>SIGHTSEEING PLACES & ENTRY FEES TO NOTE FOR <strong>BHUTAN TOUR FROM GUWAHATI</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> SIGHTSEEING PLACES & ENTRY FEES TO NOTE FOR <strong>BHUTAN TOUR FROM GUWAHATI</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">

                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr>
                                                    <th>PLACES</th>
                                                    <th>CITY</th>
                                                    <th>ENTRY FEES</th>
                                                    <th>REMARKS</th>
                                                </tr>
                                            </thead>
                                            <tbody><tr><td colspan="4">THIMPHU SIGHTSEEING PLACES AND THE ENTRY FEES (IN NGULTRUM)</td></tr>
                                                <tr><td>Tashichhoe Dzong (Fort)</td><td>Thimphu</td><td>NU 500</td><td>Mon-Fri from 5pm & Sat-Sun from 9am to 6pm</td></tr>
                                                <tr><td>Buddha Dordenma Statue</td><td>Thimphu</td><td>No Entry Fee</td><td>Time-9am to 5pm</td></tr>
                                                <tr><td>Thimphu Memorial Chorten</td><td>Thimphu</td><td>NU 500</td><td>Anytime</td></tr>
                                                <tr><td>Changangkha Lhakhang (Temple)</td><td>Thimphu</td><td>NU 500</td><td>Anytime between 8am to 5pm</td></tr>
                                                <tr><td>Takin Preservation Centre</td><td>Thimphu</td><td>NU 500</td><td>9am to 5pm</td></tr>
                                                <tr><td>Simtokha Dzong (Fort)</td><td>Thimphu</td><td>NU 500</td><td>9am to 5pm</td></tr>
                                                <tr><td>National Library</td><td>Thimphu</td><td>NU 100</td><td>9am to 5pm on Mon to Fri</td></tr>
                                                <tr><td>The Royal Textile Museum</td><td>Thimphu</td><td>NU 250</td><td>Mon to Sat from 9am to 4pm</td></tr>
                                                <tr><td>National Institute for Zorig Chusum (Painting School)</td><td>Thimphu</td><td>NU 200</td><td>Mon to Fri 10am to 3:30pm</td></tr>
                                                <tr><td>Folk Heritage Museum</td><td>Thimphu</td><td>NU 200</td><td>Open from 9am to 4pm on Mon to Sun</td></tr>
                                                <tr><td>Simply Bhutan Museum</td><td>Thimphu</td><td>NU 1000</td><td>Open from 9am to 4pm on Mon to Sun</td></tr>

                                                <tr><td colspan="4">PUNAKHA SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                                                <tr><td>Punakha Dzong (Fort)</td><td>Punakha</td><td>NU 500</td><td>11am to 1pm & 3pm to 5pm (Nov to May) and other time anytime bwtn 9am to 5pm</td></tr>
                                                <tr><td>Chimmi Lhakhang (Temple)</td><td>Punakha</td><td>NU 500</td><td>9am to 1pm and 2pm to 5pm </td></tr>
                                                <tr><td>Khamsum Yueely Namgyal Chorten</td><td>Punakha</td><td>NU 100</td><td>9am to 1pm and 2pm to 5pm </td></tr>

                                                <tr><td colspan="4">PARO SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                                                <tr><td>Kyichu Lhakhang (temple)</td><td>Paro</td><td>NU 500</td><td>9am to 12pm & 1pm to 4pm </td></tr>
                                                <tr><td>Rinpung Dzong (Fort)</td><td>Paro</td><td>No Entry Fee</td><td>9am to 5pm (Summer) & 9am to 4pm (Winter) </td></tr>
                                                <tr><td>Dobji Dzong</td><td>Paro</td><td>No Entry Fee</td><td>9am to 5pm </td></tr>
                                                <tr><td>National Museum of Bhutan</td><td>Paro</td><td>NU 300</td><td>Opens from 9am to 4pm throughout the week except on govt & local holidays </td></tr>
                                                <tr><td>Taktshang (Tiger Nest Monastery)</td><td>Paro</td><td>NU 1000</td><td>9am to 5pm </td></tr>

                                                <tr><td colspan="4">TRONGSA SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                                                <tr><td>Trongsa Dzong</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>
                                                <tr><td>Trongsa Ta Dzong (Watch tower)</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>

                                                <tr><td colspan="4">BUMTHANG SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                                                <tr><td>Jampa Lhakhang</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>
                                                <tr><td>Kurjey Lhakhang</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>
                                                <tr><td>Jakar Dzong</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>

                                                <tr><td colspan="4">PHUENTSHOLING SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                                                <tr><td>Rinchending Goenpa (temple) </td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm </td></tr>
                                                <tr><td>Palden Tashi Choling Shedra </td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm </td></tr>
                                                <tr><td colspan="4">NOTE : 50% monument fees exemption on entry fees for children of 6 years to 18 years and no fee for children of 5 years and below. </td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-bagdogra" target='_blank' className="btn btn-Warning btn-lg">
                                            <b>Bhutan Package Tour From Bagdogra Airport</b>
                                        </a>
                                    </div>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-pune" target='_blank' className="btn btn-Warning btn-lg w-100">
                                            <b>Bhutan Package Tour Booking From Pune</b>
                                        </a>
                                    </div>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-kolkata" target='_blank' className="btn btn-Warning btn-lg w-100">
                                            <b>Bhutan Package Tour From Kolkata Airport</b>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> ENTRY FORMALITIES IN BHUTAN BY GUWAHATI TO PARO DIRECT FLIGHT</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> ENTRY FORMALITIES IN BHUTAN BY GUWAHATI TO PARO DIRECT FLIGHT</h3>
                                </div>
                                <div className="card-body">
                                    <h4>FOR FIFO [FLY IN FLY OUT] TOURIST [PARO AIRPORT]</h4><br /><br />
                                    <p>Bhutan entry permit are done online and manual permit on arrival at Paro Airport. For this travelers need to produce following documents. <br /><br /></p>

                                    <ul>
                                        <li>A valid passport (valid for at least 6 months), or a Voter ID issued by the Election Commission of India. <br /><br /></li>
                                        <li>Two recent passport-size photos of the traveler with a white background. <br /><br /></li>
                                        <li>Travel insurance with the signature & seal of the insurance provider. <br /><br /></li>
                                        <li>SDF (Sustainable Development Fee) in INR for regional tourists. <br /><br /></li>
                                        <li>A licensed tour guide is required. <br /><br /></li>
                                    </ul>
                                    <br /><br /><br />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {/* <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen10ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen11ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen12ImageURL}
                                                        alt="travel bhutan with best b2b dmc - adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> */}
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> BEST TIME TO BOOK <strong> BHUTAN PACKAGE TOUR FROM GUWAHATI</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST TIME TO BOOK <strong> BHUTAN PACKAGE TOUR FROM GUWAHATI</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p><b>Summer</b> in Bhutan spans from June to September, with temperatures in central Bhutan typically ranging between 20 to 25 degrees Celsius. The weather is generally sunny and mildly humid, while the nights can get quite chilly, so light woolen clothes or jackets are advisable. The landscapes around cities and tourist attractions are lush and green, and the clear skies offer a breathtaking view of the snow-capped Himalayan peaks. If you visit during this period, you can also experience the Haa Festival, which showcases Bhutanese cuisine, culture, traditions, and lifestyle. With fewer crowds, you can peacefully explore numerous sightseeing spots. Therefore, summer is one of the best seasons to opt for <strong>Bhutan Holiday Packages from Guwahati with Adorable Vacation</strong>. <br /><br /><br /></p>


                                    <h4>AUTUMN [SEPTEMBER - NOVEMBER]</h4>
                                    <p>Autumn is widely regarded as the best season for <strong>Bhutan tour booking from Adorable Vacation via Guwahati Airport</strong>. The weather during this time is quite pleasant, ranging from 10 to 15 degrees, making sightseeing and travel enjoyable throughout the day, while the nights are comfortably chilly. This is also the festive season in Bhutan, offering travelers a glimpse into the rich cultural heritage and vibrant festivals of the country. Festivals such as the Black-Necked Crane Festival, Paro Tshechu, and Punakha Tshechu are celebrated with great enthusiasm, with dzongs and monasteries coming alive with colorful flags, large Thonkas, and masked dances. For tourists, it’s the perfect time to visit. However, Adorable Vacation recommends booking your in advance during the summer months to avoid the peak season rush and the unavailability of flights and hotel rooms. <br /><br /><br /></p>


                                    <h4>WINTER [DECEMBER - FEBRUARY]</h4>
                                    <p><b>Winter</b> in Bhutan can be quite harsh. Due to the high Himalayan altitude, temperatures drop rapidly, and cities like Thimphu, Paro, and Punakha are often blanketed in snow, creating a serene and still atmosphere. In the northern, high-altitude regions, temperatures can even fall to sub-zero levels. As the weather becomes severe, the government of Bhutan typically declares holidays for schools, colleges, and government offices. Travel between different regions may become difficult due to heavy snowfalls. However, if you're keen to experience the snow and cold and want to enjoy the festivals that occur during winter, you can certainly plan your <strong>Bhutan package tour in December</strong>. Some of the notable festivals, like Monggar Tshechu, Trashigang Tshechu, Lhuntse Tshechu, and Trongsa Tshechu, take place between November and December. So, if you’re excited about experiencing Bhutan's winter charm, don't hesitate to book your <strong>Bhutan tour from Guwahati with Adorable Vacation</strong> this season. Winter in Bhutan is truly magical! <br /><br /><br /></p>


                                    <h4>SPRING [MARCH - MAY]</h4>
                                    <p>After the cold and harsh winter, Bhutan comes to life during spring. The blooming of Jacaranda and Rhododendron flowers marks the arrival of spring, carpeting the roads, valleys, and surroundings in purple petals, creating a heavenly atmosphere. From March to May, the weather is typically dry with minimal rainfall, and the skies become crystal clear, signaling the perfect time for the world-famous Jomolhari and Drukpath treks. Additionally, this period hosts some of the most significant and auspicious festivals, including Paro Tshechu, Gasa Tshechu, Rhododendron festivals, and Talo Tshechu, where the King of Bhutan also actively participates. Since Bhutan is usually bustling with tourists during these months, Adorable Vacation advises booking your <strong>Bhutan package in May</strong> well in advance, along with your flights, to avoid the peak season surcharges, extra hotel costs, and increased flight prices. <br /><br /></p>
                                    <div id="no-more-tables" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr>
                                                    <th>SEASON</th>
                                                    <th>MONTH</th>
                                                    <th>WEATHER</th>
                                                    <th>TEMPERATURE</th>
                                                </tr>
                                                <tr><td><b>Winter</b></td><td>December</td><td>Cool and Dry</td><td>15°C - 24°C</td></tr>
                                                <tr><td></td><td>January</td><td>Cool and Dry</td><td>14°C - 24°C</td></tr>
                                                <tr><td></td><td>February</td><td>Cool and Dry</td><td>15°C - 25°C</td></tr>
                                                <tr><td><b>Spring</b></td><td>March</td><td>Warm and sunny</td><td>17°C - 29°C</td></tr>
                                                <tr><td></td><td>April</td><td>Warm and sunny</td><td>21°C - 32°C</td></tr>
                                                <tr><td><b>Summer</b></td><td>May</td><td>Hot and Humid</td><td>24°C - 34°C</td></tr>
                                                <tr><td></td><td>June</td><td>Hot and Humid</td><td>25°C - 35°C</td></tr>
                                                <tr><td></td><td>July</td><td>Hot and Humid</td><td>26°C - 35°C</td></tr>
                                                <tr><td></td><td>August</td><td>Hot and Humid</td><td>25°C - 34°C</td></tr>
                                                <tr><td><b>Autumn</b></td><td>September</td><td>Warm and rainy</td><td>24°C - 32°C</td></tr>
                                                <tr><td></td><td>October</td><td>Cool and Dry</td><td>22°C - 30°C</td></tr>
                                                <tr><td></td><td>November</td><td>Cool and Dry</td><td>18°C - 28°C</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br /><br />
                                    {/* <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br /> */}
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen13ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen14ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen15ImageURL}
                                                        alt="travel bhutan with best b2b dmc adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> BEST HOTELS ADORABLE VACATION PROVIDE FOR <strong>BHUTAN PACKAGE TOUR FROM GUWAHATI</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST HOTELS ADORABLE VACATION PROVIDE FOR <strong>BHUTAN PACKAGE TOUR FROM GUWAHATI</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr>
                                                    <th>PLACE</th>
                                                    <th>HOTEL NAME</th>
                                                    <th>ROOM CATEGORY</th>
                                                    <th>HOTEL CATEGORY</th>
                                                    <th>NO. OF NIGHTS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Thimphu</td>
                                                    <td>Hotel White Tara/Hotel Ugyen</td>
                                                    <td>Deluxe</td>
                                                    <td>3* TCB</td>
                                                    <td>2</td>
                                                </tr>
                                                <tr>
                                                    <td>Punakha</td>
                                                    <td>Hotel Pema Karpo / Hotel Vara </td>
                                                    <td>Deluxe</td>
                                                    <td>3* TCB</td>
                                                    <td>2</td></tr>
                                                <tr>
                                                    <td>Paro</td>
                                                    <td>Bhutan Mandala/ Resort Thim-Dorji</td>
                                                    <td>Deluxe</td>
                                                    <td>3* TCB</td>
                                                    <td>2</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen16ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen17ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen18ImageURL}
                                                        alt="travel bhutan with best b2b dmc adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="row py-5">
                                <div className="col-md-12">
                                    <div className="site-heading text-center">
                                        <h3>
                                            DETAIL ITINERARY FOR 7 DAYS <span className='tomato'><strong>BHUTAN TOUR PACKAGE FROM GUWAHATI</strong></span>
                                        </h3>
                                        <h4>7N/8D <strong>Bhutan Tour Package Itinerary From Guwahati</strong> from Adorable Vacation</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div
                                    className="accordion"
                                    id="accordionPanelsStayOpenExample"
                                >
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day1"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseOne"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseOne"
                                            >
                                                <h4> DAY 01: WELCOME TO PARO AIRPORT FROM GUWAHATI AND TRANSFER TO THIMPHU </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseOne"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingOne"
                                        >
                                            <div className="accordion-body">
                                                <p>Welcome to Bhutan, also called <b>the Land of the Thunder Dragon</b>. Bhutan is a beautiful country in the Himalayas with green valleys, mountains, rivers, and friendly people. As you land in Paro, look out the window to see the Paro Dzong on the hillside above the Paro Chu river and Ta Dzong. <br /><br />

                                                    Our guide will meet you at the airport with a traditional Bhutanese welcome, the Tashi Khada (a white scarf). This marks the start of your <strong>Bhutan Package Tour from Guwahati</strong>. They will help you and take you to Thimphu, which is 2334 meters above sea level. The rest of the day is free for you to relax or explore Thimphu’s pubs and nightclubs. You’ll stay overnight in a hotel in Thimphu. Bhutan is a colorful and dreamy place, surrounded by beautiful mountains, waiting for you to discover. <br /> <br /><br /></p>
                                                <h4 className="dark-blue">BARS & NIGHT CLUBS TO HANGOUT NIGHTLIFE IN THIMPHU<br /></h4>
                                                <div id="no-more-tables">
                                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                        <thead className="cf">
                                                            <tr><th>NAME</th><th>ADDRESS</th><th>TIMINGS</th></tr></thead>
                                                        <tbody><tr><td>Mojo Park</td><td>Chang Lam, Thimphu, Bhutan</td><td>5 PM - 12 AM</td></tr>
                                                            <tr><td>Space 34</td><td>Chang Lam, Main Town, Thimphu</td><td>9 PM - 1 AM</td></tr>
                                                            <tr><td>Om Bar</td><td>Thimphu, Bhutan</td><td>5 PM - 1 AM</td></tr>
                                                            <tr><td>Vivacity</td><td>Sheten Lam, Thimphu, Bhutan</td><td>7 PM - 12 AM</td></tr>
                                                            <tr><td>Club Ace</td><td>Thimphu, Bhutan</td><td>9 PM - 1 AM</td></tr>
                                                            <tr><td>Barz Brothers</td><td>Thimphu, Bhutan</td><td>Open 24 hours</td></tr>
                                                            <tr><td>Benez Bar</td><td>Jangchhub Lam, Thimphu, Bhutan</td><td>10 AM - 11 PM</td></tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br /> <br /><br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay1ImageURL} alt="Bhutan Package Tour From Guwahati with direct flights" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Bhutan Package Tour From Guwahati with direct flights" /></center>
                                                </div>
                                                <br /> <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day2"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseTwo"
                                            >
                                                <h4>DAY 02: FULL DAY THIMPHU SIGHTSEEING TOUR</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseTwo"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingTwo"
                                        >
                                            <div className="accordion-body">
                                                <p>Start your day with a hot cup of <b>Suja (Butter Tea)</b> as the sun shines over the beautiful Thimphu valley. After enjoying breakfast, get ready for a tour of Thimphu's local attractions. <br /><br /></p>

                                                <p><b>The National Memorial Chorten (Thimphu Chorten)</b> : This place is where many locals come for evening prayers. It was built in memory of Bhutan’s third king, Jigme Dorji Wangchuk, and was completed in 1974 after his untimely death. People walk around the chorten, spinning prayer wheels and chanting mantras. It stands as a symbol of peace and prosperity. <br /><br />

                                                    <b>The Clock Tower</b> : Located in the center of Thimphu, the Clock Tower Square is a beautiful example of Bhutanese architecture. The tower has four clock faces and is decorated with intricate carvings of flowers, paintings, and dragons. The dragons are facing each clock and represent the country’s independence. This area is popular for both locals and tourists, and it’s surrounded by shops, restaurants, and hotels. The square often hosts live music events, making it a lively spot. You can also buy gifts here or enjoy a picnic in the nearby playground. <br /><br />

                                                    <b>Sakyamuni Buddha</b> : On top of Kuensel Phodrang hill stands the giant statue of Buddha Dordenma. This 51.5-meter statue is made of gilded bronze, and it offers a stunning view of the valley. It’s a peaceful and breathtaking site, also known as Buddha Viewpoint. <br /><br />

                                                    <b>Changangkha Lhakhang</b> : One of the oldest and most important monasteries in Thimphu, dating back to the 15th century. It’s dedicated to Avalokiteshvara, the symbol of compassion. From here, you can enjoy a panoramic view of the Thimphu valley. <br /><br />

                                                    <b>Takin Zoo</b> : This zoo is home to Bhutan's national animal, the Takin, an endangered species found only in Bhutan. The animals live in an open habitat, allowing you to see them in a more natural environment. <br /><br />

                                                    <b>Trashi Chhoe Dzong</b> : Known as the Fortress of the Glorious Region, this site is a must-visit. It sits on the west bank of the Wang Chhu River and is the coronation site of Bhutan’s fifth king. It also hosts the annual <strong>Tsechu Festival</strong>. Built in 1641, it was later rebuilt in the 1960s using traditional Bhutanese methods, without nails or architectural plans. You’ll spend the night at a hotel in Thimphu.
                                                    <br /><br /></p>
                                                <div className="content-desktop">
                                                    <center><img src={navDay2ImageURL} alt="mumbai to Bhutan Package Tour From Guwahati booking with direct flights" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="mumbai to Bhutan Package Tour From Guwahati booking with direct flights" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day3"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseThree"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 03: THIMPHU TO PUNAKHA TRANSFER & SIGHTSEEING</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseThree"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingThree"
                                        >
                                            <div className="accordion-body">
                                                <p>Start your day with a cup of Hot Ngaja (sweet Bhutanese milk tea) and enjoy the view of the beautiful valley from your room. After breakfast, head to Punakha, the former capital of Bhutan. Punakha is a peaceful place, much quieter than the busy cities, sitting at an altitude of 1200 meters. It’s warm in winter and cool in summer. The valley is famous for growing red and white rice along the Pho and Mo Chu rivers. On the way, you’ll visit: <br /><br />

                                                    <b>Punakha Dzong</b>: Also called "<b>The Palace of Great Happiness</b>", this stunning fortress sits at the meeting point of the Pho Chhu (father) and Mo Chhu (mother) rivers. It’s one of the most beautiful dzongs in Bhutan. The dzong is connected to the mainland by an arched wooden bridge and is home to many valuable relics from the time when the kings ruled Bhutan from this valley. <br /><br />

                                                    <b>Suspension Bridge</b>: This hidden gem in the Punakha Valley connects the valley to the old administrative center of Punakha Dzongkhag. The bridge is covered with prayer flags and offers a great spot for birdwatching and taking photos. <br /><br />

                                                    <b>Chimi Lhakhang (Fertility Temple)</b>: Located on a hill, this temple is dedicated to Lama Drukpa Kuenley, also known as the 'Divine Madman.' He used humor and songs to teach his lessons. Chimi Lhakhang is considered a fertility temple, and it is believed that childless couples who visit and pray here will be blessed by Lama Drukpa Kuenley. The temple is reached by walking along a beautiful rural path through paddy fields. After crossing a small stream, a short climb will take you to the temple. You will stay overnight at a hotel in Punakha. <br /><br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay3ImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day4"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFour"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 04: - PUNAKHA TO PHOBJIKHA VALLEY DAY EXCURSION & RETURN </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseFour"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFour"
                                        >
                                            <div className="accordion-body">
                                                <p>As the Sun shines up high in the Land of Happiness, open your eyes to a beautiful morning in the lap of serene nature, and sip into your cup of hot tea while admiring the green valley from your bed before moving towards Phobjikha Valley post breakfast. <br /><br />

                                                    <b>Phobjikha Valley</b> : A treat for all nature lovers, and an idle place for spotting various birds, including the famous Black Necked Cranes that migrate to this valley. Being one of the most important wildlife preserves in Bhutan, this serene and untouched beauty of nature away from the hustle-bustle of the city life is a pleasure to enjoy with the happiest people surrounding the entire region that makes this place worth the visit. The Phobjikha Valley is the largest and the widest in Bhutan and is known for the panoramic vistas with tranquil nature that not only call the nature lovers to witness its beauty but also traps the adventure buffs who wish to follow the trekking and hiking routes while exploring the untouched beauty of nature. Overnight at hotel in Punakha. <br /><br /><br /></p>
                                                <br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay4ImageURL} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day5"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFive"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 05: PUNAKHA TO PARO TRANSFER & LOCAL SIGHTSEEING </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseFive"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFive"
                                        >
                                            <div className="accordion-body">
                                                <p>After breakfast, start your journey to Paro and visit the following places on the way: <br /><br />

                                                    <b>National Museum of Bhutan</b> : Located above Rinpung Dzong in Paro, the National Museum of Bhutan showcases Bhutan’s culture. The museum was built in 1968 inside the old Ta Dzong building. It displays some of the best examples of Bhutanese art and culture, including beautiful paintings and bronze statues. The museum has large galleries where you can learn about Bhutan’s history and culture. <br /><br />

                                                    <b>Drukgyal Dzong</b> : Drukgyal Dzong was a fortress and Buddhist monastery, now in ruins, located in the upper Paro Valley. It was built in 1649 to celebrate Bhutan’s victory over a Tibetan invasion. The site is currently closed. <br /><br />

                                                    <b>Ta Dzong</b> : Ta Dzong was originally a watchtower built to protect Rinpung Dzong during the 17th-century wars. It became the National Museum of Bhutan in 1968. <br /><br />

                                                    <b>Rinpung Dzong</b> : Built in 1646 by Shabdrung Ngawang Namgyal, the first ruler of Bhutan, Rinpung Dzong houses the monks of Paro, the district head (Dzongda), and the judge (Thrimpon). To reach the dzong, you cross a traditional covered bridge called Nemi Zam. <br /><br />

                                                    <b>Nya Mey Zam Bridge</b> : Known as "The Bridge with No Fish," this bridge connects Paro Town to the Dzong. Locals believe that seeing a fish in the river is a bad omen. The original bridge, which could be folded during times of war, was destroyed in a flood in 1969. <br /><br />

                                                    <b>Paro Airport View Point</b> : This viewpoint offers the best view of Paro Valley and Paro Airport. You can see the Paro River, the dzongs, and monasteries from here. Overnight stay at a hotel in Paro. <br /><br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay5ImageURL} alt="Bhutan Packages From Guwahati Tour Operator" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="Bhutan Packages From Guwahati Tour Operator" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day6"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSix"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 06: PARO, TAKTSANG MONASTERY HIKING / CHELE-LA PASS EXCURSION</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSix"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSix"
                                        >
                                            <div className="accordion-body">
                                                <p>Wake up in the beautiful Paro Valley as the sun rises, surrounded by nature. Enjoy the amazing view of the river and mountains. Have a hearty breakfast with plenty of protein, because the hike to Taktsang Monastery will require some energy. <br /><br />

                                                    <b>Taktsang Monastery</b>: The hike to Taktsang Monastery, also known as the Tiger's Nest, takes you up a steep cliff for 1,200 meters. Once you reach the top, take a deep breath and enjoy the fresh air and the incredible view of the valley below. The monastery was built in 1694 and is a sacred site founded by Guru Rinpoche, who brought Buddhism to Bhutan in 747 CE. After a 3-hour trek, you’ll reach the monastery. If you prefer, you can take a horse ride from the parking lot to the top. During your hike, you’ll be treated to beautiful views of Paro town, with its green valleys, clear blue sky, and sparkling river. It’s a perfect place to enjoy nature. Overnight in Paro. <br /><br /><br /><br /></p>

                                                <h4>SOME FACTS TO NOTE ABOUT - THE TIGER'S NEST MONASTERY</h4>
                                                <br /><br />
                                                <div id="no-more-tables">
                                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                        <tbody><tr><td>Location</td><td>Approx 11.7 km from Paro town, Bhutan</td></tr>
                                                            <tr><td>Elevation</td><td>3,120 meters (10,236ft) above sea level</td></tr>
                                                            <tr><td>Duration of the Tiger's Nest hike</td><td>5-6 hours (Back and forth)</td></tr>
                                                            <tr><td>Tiger's Nest Trekking Distance</td><td>4 Miles (6.43 Kilometers) - Round Trip</td></tr>
                                                            <tr><td>Tiger's Nest Trek Difficulty</td><td>Moderate - Steep Ascend</td></tr>
                                                            <tr><td>Best Months to Visit Tiger's Nest Monastery</td><td>March, April, May and September, October, November, December</td></tr>
                                                            <tr><td>Gears needed to hike Tiger's Nest</td><td>Normal hiking shoes, Water bottle, Some snacks, energy bars, Camera, Hat, Sunglasses, Sunscreen, Walking Pole/Stick</td></tr>
                                                            <tr><td>Opening time of Tiger's Nest Monastery</td><td>(October-March) 8 am-1 pm and 2 pm-5 pm <br /> (April-September) 8 am-1 pm and 2 pm-6 pm daily</td></tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br /><br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay6ImageURL} alt="B2B Tour Operator for Bhutan" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="B2B Tour Operator for Bhutan" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day7"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 07: CHERISH YOUR MEMORIES</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>After breakfast, say goodbye to the Last Buddhist Kingdom and head to Paro Airport, taking with you unforgettable memories of your Bhutan holiday. Remember this mantra: "Life is short, and the world is wide." <br /><br /><br /></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="container-fluid py-5">
                                <table className="table-borderDodont">
                                    <thead>
                                        <tr>
                                            <td className="tomato" style={{ fontSize: "1.5vw" }}>DO'S : <strong>BHUTAN TRIP FROM GUWAHATI</strong></td>
                                            <td className="tomato" style={{ fontSize: "1.5vw" }}>DONT'S : <strong>BHUTAN TRIP FROM GUWAHATI</strong></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Listen to your tour guide and follow their instructions. </td>
                                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Do not take photographs of local people, monks, events, or objects without their permission.  </td>
                                        </tr>
                                        <tr>
                                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Bring enough cash, as many places don’t take credit cards. </td>
                                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Do not take photographs inside dzongs, temple, monasteries, sacred places, and religious sites.  </td>
                                        </tr>
                                        <tr>
                                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Be polite and respectful to older people.</td>
                                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Do not climb on to the top of any of the religious sites or sacred places. </td>
                                        </tr>
                                        <tr>
                                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Only enter temples and monasteries if you have permission.</td>
                                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Do not touch holy objects inside the monastery or any sacred sites. </td>
                                        </tr>
                                        <tr>
                                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Dress modestly; avoid wearing sleeveless tops and short skirts.</td>
                                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Do not point your finger or feet at anyone while talking.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Take off your shoes before going into a temple.</td>
                                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Do not touch the forehead of any person as it is considered a sign of disrespect.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> If photography isn’t allowed, keep your camera safe.</td>
                                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Do not wear revealing or skimpy clothes when entering any place of religious significance. </td>
                                        </tr>
                                        <tr>
                                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Don’t wear hats or sunglasses inside religious sites.</td>
                                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Do not speak loudly inside the religious premises</td>
                                        </tr>
                                        <tr>
                                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Keep your valuables, like wallets and passports, in a safe place.</td>
                                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Use Dustbin when needed. Do not through rubbish inside temples or any scared sites. </td>
                                        </tr>
                                        <tr>
                                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Always follow traffic rules when on the road.</td>
                                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Do not say anything negative about the country, its Royal couple, or discuss political issues with locals.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br /><br />
                            <table style={{ width: '100%', textAlign: 'center' }}>
                                <tbody>
                                    <tr>
                                        <td className="image-cell">
                                            <img
                                                src={sightseen19ImageURL}
                                                alt="Bhutan Guwahati group tour from bagdogra with adorable"
                                                style={{ margin: '10px' }}
                                            />
                                            <img
                                                src={sightseen20ImageURL}
                                                alt="Bhutan Guwahati travel packages"
                                                style={{ margin: '10px' }}
                                            />
                                            <img
                                                src={sightseen21ImageURL}
                                                alt="travel bhutan with best b2b dmc adorable vacation"
                                                style={{ margin: '10px' }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> SOME IMPORTANT FACTS YOU MUST KNOW BEFORE <strong>GUWAHATI TO BHUTAN TRIP</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>SOME IMPORTANT FACTS YOU MUST KNOW BEFORE <strong>GUWAHATI TO BHUTAN TRIP</strong></h3>
                                </div>
                                <div className="card-body">
                                    <ul>
                                        <li>Locals call their country "Druk Yul," which means "the Land of the Dragons." <br /><br /></li>
                                        <li>Bhutan has never been conquered or ruled by outside forces. <br /><br /></li>
                                        <li>Bhutan is the only carbon-negative country in the world. <br /><br /></li>
                                        <li>Bhutan was isolated from the rest of the world until the 1970s. <br /><br /></li>
                                        <li>You won’t find any traffic lights in Bhutan. <br /><br /></li>
                                        <li>Bhutan is a constitutional monarchy. <br /><br /></li>
                                        <li>Bhutan does not use GDP as a measure of economic growth. <br /><br /></li>
                                        <li><b>GNH</b> (Gross National Happiness) is the measure of growth and development in Bhutan. <br /><br /></li>
                                        <li>Bhutan is a plastic-free country. <br /><br /></li>
                                        <li>There are fewer than 20 qualified pilots who can fly into Bhutan's only airport. <br /><br /></li>
                                        <li>Smoking is not allowed in Bhutan and is considered a punishable offense. <br /><br /></li>
                                        <li>Bhutan has some of the world’s highest unclimbed mountain peaks, including Gangkhar Puensum at 7,570m (24,981ft). <br /><br /></li>
                                        <li>The national animal of Bhutan is the Takin, a goat-antelope. <br /><br /></li>
                                        <li>Bhutanese food is very spicy, and you will find chilies in almost every dish. <br /><br /></li>
                                        <li>Education and healthcare are free for all citizens. <br /><br /></li>
                                        <li>Archery is the national sport of Bhutan. <br /><br /></li>
                                        <li>Bhutanese people love their king, and you’ll find his photo in every home. <br /><br /></li>
                                        <li>Bhutan has a very low crime rate. <br /><br /></li>
                                        <li>Wearing the national dress (Gho for men and Kira for women) is mandatory in schools, government offices, and during festivals. <br /><br /></li>
                                        <li>Cleanliness is very important in Bhutan, and the environment is highly prioritized. <br /><br /></li>
                                        <li>Bhutanese people often chew betel leaf and areca nut. <br /><br /></li>
                                        <li>Ema Datshi is the national dish of Bhutan, made with spicy chilies and local cheese. <br /><br /></li>
                                    </ul>

                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen13ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen16ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen18ImageURL}
                                                        alt="travel bhutan with best b2b dmc adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> ACTIVITIES TO ENJOY DURING YOUR <strong>BHUTAN TOUR FROM GUWAHATI</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>ACTIVITIES TO ENJOY DURING YOUR <strong>BHUTAN TOUR FROM GUWAHATI</strong></h3>
                                </div>
                                <div className="card-body">
                                    <ul>
                                        <li>Hike to Taktsang Monastery (Tiger's Nest) - Paro <br /><br /></li>
                                        <li>Take the Dagala Thousand Lakes Trek - Thimphu <br /><br /></li>
                                        <li>Walk across the Punakha Suspension Bridge - Punakha <br /><br /></li>
                                        <li>Explore the National Memorial Chorten - Thimphu <br /><br /></li>
                                        <li>Explore Simtokha Dzong - Thimphu <br /><br /></li>
                                        <li>Explore the Chele La Pass <br /><br /></li>
                                        <li>Visit the Buddha Cave & Buddha Falls <br /><br /></li>
                                        <li>Visit Trongsa Dzong - Trongsa <br /><br /></li>
                                        <li>Visit Rinpung Dzong - Paro <br /><br /></li>
                                        <li>Visit Trashigang Dzong - Trashigang District <br /><br /></li>
                                        <li>Take a Bike Trip around Bhutan <br /><br /></li>
                                        <li>Go Camping in Thimphu <br /><br /></li>
                                        <li>Try Archery - Bhutan’s National Sport <br /><br /></li>
                                        <li>Go Mountain Biking in Bhutan <br /><br /></li>
                                        <li>Visit the Tashichho Dzong - Thimphu <br /><br /></li>
                                        <li>Visit the Buddha Dordenma - Thimphu <br /><br /></li>
                                        <li>Hike in the beautiful Haa Valley - Haa <br /><br /></li>
                                        <li>Relax with a Hot Spring Therapy <br /><br /></li>
                                        <li>Experience the Nimalung Tsechu Festival <br /><br /></li>
                                        <li>Take a scenic drive at Dochula Pass <br /><br /></li>
                                        <li>Attend the Annual Black-Necked Crane Festival <br /><br /></li>
                                        <li>Try Kayaking on the Wang Chhu River - Paro <br /><br /></li>
                                        <li>Go Rafting in the Mo Chhu River - Punakha <br /><br /></li>
                                        <li>Explore Punakha Dzong - Punakha <br /><br /></li>
                                        <li>Get a Blessing at Gangtey Monastery - Wangdue Phodrang <br /><br /></li>
                                        <li>Pray at Chimi Lhakhang Temple - Punakha <br /><br /></li>
                                        <li>Spot Snow Leopards at Jigme Dorji National Park <br /><br /></li>
                                        <li>Try Bhutanese Cuisine - especially Ema Datshi <br /><br /></li>
                                        <li>Visit Kyichu Lhakhang - A beautiful temple </li>
                                    </ul><br /><br /><br />

                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen14ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen17ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen20ImageURL}
                                                        alt="travel bhutan with best b2b dmc adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            {/* <!--FOQ--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                Frequently Ask <span>Questions</span>
                                            </h2>
                                            <h4>Related Questions for <strong>Bhutan Package Tour From Guwahati</strong></h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="accordion accordion-flush faQStyle"
                                        id="accordionFlushExample"
                                    >
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingPackages">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapsePackages"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapsePackages"
                                                >
                                                    What is included in your Bhutan Tour Package From Guwahati?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapsePackages"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingPackages"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Our <strong>Bhutan Tour Package From Guwahati</strong> typically include accommodation, transportation, guided tours, meals, and necessary permits. We customize these packages based on client needs to ensure a seamless experience. <br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingPermits">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapsePermits"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapsePermits"
                                                >
                                                    How can Adorable Vacation assist with Bhutan travel permits?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapsePermits"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingPermits"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    As a specialist <strong>Bhutan DMC</strong> and TCB License Holder [License No: 1053228], we handle the entire permit application process online/offline for your clients, ensuring they receive the necessary documentation for a hassle-free experience. Our dedicated staff in Bhutan ensures 100% confirmation of permits.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingBusiness">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseBusiness"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseBusiness"
                                                >
                                                    What types of businesses can benefit from Bhutan Tour Package From Guwahati?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseBusiness"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingBusiness"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Our <strong>Bhutan Tour Package From Guwahati</strong> cater to travel agencies, corporate clients, MICE, and tour operators. We offer tailored solutions for group tours, incentive trips, and special events.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingGroups">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseGroups"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseGroups"
                                                >
                                                    Are your Bhutan tours suitable for large groups?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseGroups"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingGroups"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Yes, we specialize in managing large group tours. We recently completed an 80-person Rotary Club tour with almost zero complaints. All guests shared positive feedback and enjoyed their experience with Adorable Vacation.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingDestinations">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseDestinations"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseDestinations"
                                                >
                                                    What destinations are included in your Bhutan Tour Package From Guwahati?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseDestinations"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingDestinations"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Our <strong>Bhutan Tour Package From Guwahati</strong> cover popular destinations like Thimphu, Paro, Punakha, and Phobjikha Valley. We also offer customized trips to more remote areas such as Gasa, Throngsa, Trashigang, Dagana, Bumthang, Zhemgang, and Trashiyangtse.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingGuides">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseGuides"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseGuides"
                                                >
                                                    Do you provide local guides for Bhutan Package Tour From Guwahati?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseGuides"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingGuides"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Absolutely! We provide experienced, humble, and knowledgeable local guides who are well-versed in Bhutanese culture, history, and geography, ensuring a rich experience for your clients.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingQuality">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseQuality"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseQuality"
                                                >
                                                    How do you ensure the quality of your services?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseQuality"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingQuality"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    We prioritize high-quality services through partnerships with local vendors, staff training, and a commitment to customer satisfaction.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingCustomize">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseCustomize"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseCustomize"
                                                >
                                                    Can we customize our Bhutan Packages From Guwahati?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseCustomize"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingCustomize"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Yes, we offer fully customizable packages to meet client needs, including itinerary adjustments, special accommodations, and activities.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingClimate">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseClimate"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseClimate"
                                                >
                                                    What should clients expect regarding the climate in Bhutan?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseClimate"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingClimate"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Bhutan’s climate varies by region and season. We provide detailed information on the best times to visit and what to expect to help clients prepare.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingContact">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseContact"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseContact"
                                                >
                                                    How can I get in touch with Adorable Vacation for booking Bhutan Packages From Guwahati?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseContact"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingContact"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    You can contact us through our website or directly via phone or email. Our Bhutan Specialists, Mr. Soumalya [+91-98833-59713] and Ms. Sonali [+91-96747-38480], are ready to assist you with any inquiries.<br /><br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>
                                                <b>
                                                    Bhutan <span className="tomato">Theme Package</span>
                                                </b>
                                            </h3>
                                            <h4>Similar Mountain Trip</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        id="carouselExampleControls"
                                        className="carousel slide"
                                        data-bs-ride="carousel"
                                    >
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <Slider {...settings}>{themePackagesDataView}</Slider>
                                            </div>
                                        </div>
                                    </div>

                                    <br />
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>
                                                <b>
                                                    Bhutan <span className="tomato">Tour Reviews</span>
                                                </b>
                                            </h3>
                                            <h4>
                                                <i>"Travel without Trouble"</i>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1 ">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">
                                            {reviewersDataView}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3">

                            <BookingBox />

                        </div>
                        {this.state.showModal && (
                            <div className="modal d-flex align-items-center justify-content-center" style={{ display: this.state.showModal ? "block" : "none" }}>
                                <div className="modal-dialog">
                                    <div className="modal-content cust-modContent" ref={this.modalRef}>
                                        <div className="modal-header">
                                            <h4 className="modal-title ms-auto" id="exampleModalLabel">Get a Free Callback &nbsp;&nbsp;<i className="fa fa-phone fa-rotate-90"></i>
                                            </h4>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.onCloseModal}></button>
                                        </div>
                                        <div className="modal-body">
                                            <form className="row g-3">
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name *"
                                                        name="firstName"
                                                        value={this.state.firstName}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="10 Digit Mobile No.*"
                                                        name="mobile"
                                                        value={this.state.mobile}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Email "
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Comments"
                                                        rows="2"
                                                        name="comments"
                                                        value={this.state.comments}
                                                        onChange={this.onChangeHandler}></textarea>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <div className='container'>
                                                <div className="row">
                                                    <div className='col-sm-4'>
                                                        {
                                                            (this.state.showLoader) &&
                                                            <div className='pull-left'><Digital size={25} /></div>
                                                        }
                                                    </div>
                                                    <div className='col-sm-8 '>
                                                        <div className='pull-right'>
                                                            {/* <button type="button" className="btn btn-Warning mr-1" onClick={this.onCloseModal}>Close</button> */}
                                                            <button type="button" className="btn btn-Warning btn-lg" onClick={this.onRequestCallbackSubmit}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default Bhutan_PackageTourGuwahati;