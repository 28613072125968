import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Andaman/InclusionExclusion_Andaman_7N_8D_Package_Tour_Kolkata';

class Andaman_7N_8D_Package_Tour_Kolkata extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919163676037&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };
    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Andaman-Tour-Package.webp";
        const packageTopBanner2ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Andaman-Package-Tour.webp";
        const sightseen1ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Customized-Andaman-Trip-from-Kolkata.webp";
        const sightseen2ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Andaman-Tour-Packages.webp";
        const sightseen3ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Andaman-Tour-Package-Including-Airfare.webp";
        const sightseen4ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Andaman-Tour-Package-from-Kolkata.webp";
        const sightseen5ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Andaman-Tour-Package-Cost.webp";
        const sightseen6ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Andaman-Tour-from-Kolkata.webp";
        const sightseen7ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Andaman-Package-Tour-Cost-from-Kolkata.webp";
        const sightseen8ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Andaman-Packages-Tour.webp";
        const sightseen9ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Andaman-Nicobar-Tour-Package-Price.webp";
        const sightseen10ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Andaman-nicobar-Tour-Package-from-Kolkata.webp";
        const sightseen11ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Andaman-Luxury-Tour-Package.webp";
        const sightseen12ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Andaman-and-nicobar-tour-Package-from-Kolkata.webp";
        const navDay1ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Andaman-Tour-Itinerary-from-Kolkata.webp";
        const navDay2ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Andaman-Tour-Cost-from-Kolkata.webp";
        const navDay3ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Andaman-Havelock-Island-Package-Tour.webp";
        const navDay4ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Kolkata-to-Andaman-Package-Tour.webp";
        const navDay5ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Andaman-and-nicobar-islands-tour-package-from-Kolkata.webp";
        const navDay6ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Andaman-nicobar-Package-from-Kolkata.webp";
        const navDay7ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Andaman-Trip-Package-from-Kolkata.webp";
        const navDay8ImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Andaman-Package-Tour-Plan-from-Kolkata.webp";
        const TopbannerImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/Assam-Meghalaya-Tour-Plan-from-Guwahati-Airport.webp";

        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/" + dataItem.ImageName;
                    const contactURL = dataItem.Url;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <Link to={contactURL} target="_blank" className="btn btn-Warning btn-sm float-end">Call Now</Link>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Andaman_7N_8D_Package_KolkataImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">
                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>ANDAMAN PACKAGE TOUR FROM KOLKATA</strong></center></h1>
                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>ANDAMAN PACKAGE TOUR FROM KOLKATA</strong></center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Destinations:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    PORT BLAIR
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    NEIL
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    HAVELOCK
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Andaman Package Tour From Kolkata</strong>, you have come to the right place!
                                        </p>
                                        <hr />
                                        <br />
                                        <h2 className="content-desktop p-2" style={{ fontSize: "1.8vw" }}> Looking for <strong>Andaman Tour Packages from Kolkata ?</strong> <a href='tel:9163676037' class="tomato">To Book a <strong>Andaman and Nicobar Islands Tour Package from Kolkata</strong> ☎ Call Mr.Akash +91-91636-76037</a></h2>
                                        <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> Looking for <strong>Andaman Tour Packages from Kolkata ?</strong> <a href='tel:9163676037' class="tomato">To Book a <strong>Andaman and Nicobar Islands Tour Package from Kolkata</strong> ☎ Call Mr.Akash +91-91636-76037</a></h2>
                                    </div>
                                    <br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>BEST ANDAMAN PACKAGE TOUR FROM KOLKATA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BEST ANDAMAN PACKAGE TOUR FROM KOLKATA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>Located in the southern Bay of Bengal, the Andaman Islands are a true gem of the Indian subcontinent. With white sandy beaches, clear blue waters, and lots of sunshine, the Andaman Islands are a beach paradise for anyone looking to book an <strong>Andaman Tour Package from Kolkata</strong>.</p>
                                    <p>Only 1,287 km away from Kolkata, the connection between Andaman and Kolkata dates back to the British period. Kolkata, once the capital of British India, played a big role in the fight for India's independence. Many brave freedom fighters like Khudiram Bose, Binoy-Badal-Dinesh, Matangini Hazra, Chittaranjan Das, and Netaji Subhash Chandra Bose fought against British rule. Some of these heroes were sent to the Andaman Islands and imprisoned in the infamous Cellular Jail, where they faced harsh treatment. Because of this history, Bengalis share a deep emotional connection with the Andaman Islands and love visiting the place by booking an <strong>Andaman Package from Kolkata</strong>.</p>
                                    <p>Today, a large part of the Andaman population is Bengali. The culture, festivals, and food are very similar to those in Kolkata. Bengali is widely spoken, making travelers from Kolkata feel like they are in a home away from home.
                                        Come and explore the beauty of the Andaman Islands. There's so much more waiting for you here!
                                    </p>
                                    <br /> <br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen1ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                                                    <img src={sightseen2ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                                                    <img src={sightseen3ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /> <br />
                                </div>
                            </div>
                            <br /><br /><br />
                                </div>
                            </div>
                            {/* <!--By Itinerary--> */}
                           
                            {/* <!--By Itinerary--> */}
                            <div className="card">
                                <div className="card-body">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>DAY WISE ANDAMAN TOUR ITINERARY FROM KOLKATA</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingOne"
                                                >
                                                    <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                        <span>
                                                            Port Blair – City Tour
                                                        </span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingTwo"
                                                >
                                                    <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                        <span> Port Blair to Excursion to North Bay & Ross Island</span>
                                                    </a>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingThree"
                                                >
                                                    <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                        <span>Port Blair – SwarajDweep Island (Havelock) - Radhanagar Beach</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingFour"
                                                >
                                                    <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                        <span>(SwarajDweep) Havelock – Kalapathar Beach</span>
                                                    </a>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingFive"
                                                >
                                                    <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                        <span>SwarajDweep (Havelock Island) – ShaheedDweep (Neil Island)</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingSix"
                                                >
                                                    <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                        <span>ShaheedDweep (Neil Island) - Port Blair</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingSeven"
                                                >
                                                    <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                        <span>Port Blair –Baratang</span>
                                                    </a>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingEight"
                                                >
                                                    <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                                                        <span>Port Blair – Airport Dropping</span>
                                                    </a>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>ANDAMAN PACKAGE TOUR DURATION : 7N | 8D</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>ANDAMAN PACKAGE TOUR DURATION : 7N | 8D</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p><b>Destinations Covered in</b> <strong>Andaman Tour from Kolkata :</strong>  PORT BLAIR 4N | NEIL 1N | HAVELOCK 2N </p>
                                    <p> Pickup & Drop point for <strong> Andaman Tour Package</strong> : Veer Savarkar International Airport, Port Blair [IXZ], Port Blair</p>
                                    <p><strong>Andaman Tour Package Cost</strong> : Rs - 29,990/- (Based on 6 PAX)</p>
                                    
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>ATTRACTIONS IN ANDAMAN TOUR FROM KOLKATA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>ATTRACTIONS IN ANDAMAN TOUR FROM KOLKATA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p><b>Beaches in Andaman:</b> Radhanagar Beach, Elephant Beach, Kalapathar Beach, Bharatpur Beach, Lalaji Bay Beach, Vijaynagar Beach, Sitapur Beach, Pathi Level Beach, Avis Island Beach, Karmatang Beach, Butler Bay Beach, Sandbar Beach, Amkunj Beach, Stewart Island Beach, Corbyn's Cove Beach, North Bay Beach, and more.</p>
                                    <p><b>Islands in Andaman:</b> Havelock Island, Baratang Island, Ross Island, Neil Island, Katchal Island, Viper Island, Jolly Buoy Island, Long Island, Barren Island, Red Skin Island, Rutland Island, and others.</p>
                                    <p><b>Popular Places:</b> Cellular Jail, National Memorial, Saddle Peak National Park, Rajiv Gandhi Water Sports Complex, Samudrika Naval Marine Museum, Mahatma Gandhi Marine National Park, Diglipur, Chidiya Tapu, Mount Harriet and Madhuban, Chatham Saw Mill, and more.</p>
                                    <br /><br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen4ImageURL} alt="kashmir package tour from srinagar" />
                                                    <img src={sightseen5ImageURL} alt="kashmir package price from srinagar airport" />
                                                    <img src={sightseen6ImageURL} alt="kashmir packages from srinagar airport" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>WHEN IS THE BEST TIME TO BOOK AN ANDAMAN PACKAGE TOUR PLAN FROM KOLKATA ?</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>WHEN IS THE BEST TIME TO BOOK AN ANDAMAN PACKAGE TOUR PLAN FROM KOLKATA ?</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>Andaman can be visited all year because of its mild weather. However, the best time to book your <strong>Andaman and nicobar tour Package from Kolkata</strong> is between late October and the end of March. This period covers the winter and early summer, making the weather calm, clear, and pleasant for travelers. Seas are stable, allowing for smooth island-hopping and water activities, making it an ideal time to explore the beauty of Andaman.</p>
                                    <br /><br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen7ImageURL} alt="kashmir package tour from srinagar" />
                                                    <img src={sightseen8ImageURL} alt="kashmir package price from srinagar airport" />
                                                    <img src={sightseen9ImageURL} alt="kashmir packages from srinagar airport" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>HOW TO REACH ANDAMAN ?</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>HOW TO REACH ANDAMAN ?</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>For Indian citizens, traveling to Andaman is like traveling to any other state in India. No visa, passport, or special permit is needed.There are two ways to reach Andaman:</p>
                                    <p><strong>Andaman Tour Package Including Airfare</strong>: The fastest way to reach Andaman is by flight. There are direct flights from major cities like Kolkata, Chennai, and Delhi. It takes around 2.5 hours from Kolkata to reach Veer Savarkar International Airport (IXZ) in Port Blair. Airlines that fly to Andaman daily include Indigo, Air India, SpiceJet, GoAir, and Vistara.</p>
                                    <p><b>By Sea</b>: You can also travel to Andaman by ship from ports in Kolkata, Chennai, and Vishakhapatnam. Ships depart 3-4 times a month, taking 3 to 4 days (60 to 66 hours) to reach Port Blair, depending on weather. Ships like MV Nancowry, MV Nicobar, MV Harshavardhana, M.V. Swaraj Dweep, and M.V. Akbar operate these routes. Due to high demand, it's important to book your Andaman Trip in advance if you plan to travel by sea.</p>
                                    <br /><br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen10ImageURL} alt="kashmir package tour from srinagar" />
                                                    <img src={sightseen11ImageURL} alt="kashmir package price from srinagar airport" />
                                                    <img src={sightseen12ImageURL} alt="kashmir packages from srinagar airport" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DISTANCES BETWEEN PLACES TO NOTE FOR <strong>ANDAMAN AND NICOBAR PACKAGE FROM KOLKATA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DISTANCES BETWEEN PLACES TO NOTE FOR <strong>ANDAMAN AND NICOBAR PACKAGE FROM KOLKATA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>Places From</th>
                                                    <th>Places To</th>
                                                    <th>Distance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="From">Kolkata</td>
                                                    <td data-title="To">Andaman</td>
                                                    <td data-title="Distance">1303 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Chennai</td>
                                                    <td data-title="To">Andaman</td>
                                                    <td data-title="Distance">1370 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Hyderabad</td>
                                                    <td data-title="To">Andaman</td>
                                                    <td data-title="Distance">1661 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Delhi</td>
                                                    <td data-title="To">Andaman</td>
                                                    <td data-title="Distance">3518 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Mumbai</td>
                                                    <td data-title="To">Andaman</td>
                                                    <td data-title="Distance">2279 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Port Blair</td>
                                                    <td data-title="To">Havelock Island (Swaraj Dweep)</td>
                                                    <td data-title="Distance">70 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Port Blair</td>
                                                    <td data-title="To">Neil Island (Shahid Dweep)</td>
                                                    <td data-title="Distance">42.4 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Havelock Island</td>
                                                    <td data-title="To">Neil Island</td>
                                                    <td data-title="Distance">1 hr 58 min (40.0 KM) via SH4</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Neil Island</td>
                                                    <td data-title="To">Port Blair</td>
                                                    <td data-title="Distance">43 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Port Blair</td>
                                                    <td data-title="To">Radhanagar Beach</td>
                                                    <td data-title="Distance">3 hr 17 min (72.0 KM)</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Port Blair</td>
                                                    <td data-title="To">Elephant Beach</td>
                                                    <td data-title="Distance">3 hr 13 min (69.8 KM)</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Port Blair</td>
                                                    <td data-title="To">Kalapathar Beach</td>
                                                    <td data-title="Distance">3 hr 10 min (67.5 KM)</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Port Blair</td>
                                                    <td data-title="To">Baratang</td>
                                                    <td data-title="Distance">101 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Port Blair</td>
                                                    <td data-title="To">Ross Island</td>
                                                    <td data-title="Distance">11 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Ross Island</td>
                                                    <td data-title="To">North Bay Island</td>
                                                    <td data-title="Distance">49 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Port Blair</td>
                                                    <td data-title="To">Jolly Buoy Island</td>
                                                    <td data-title="Distance">30 KM</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>BEST TRAVEL TIPS FOR <strong>ANDAMAN HOLIDAY PACKAGE FROM KOLKATA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST TRAVEL TIPS FOR <strong>ANDAMAN HOLIDAY PACKAGE FROM KOLKATA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <ul>
                                        <li>•	Visit the historic Cellular Jail and enjoy the Light & Sound Show during your trip.</li><br />
                                        <li>• Try scuba diving in Havelock Island for an unforgettable experience.</li><br />
                                        <li>• Go snorkeling at Jolly Buoy Island and North Bay Island.</li><br />
                                        <li>• See the amazing coral reefs with a Glass Bottom Boat ride in Jolly Buoy Island.</li><br />
                                        <li>• Enjoy fun water activities like Banana Boat rides, scuba diving, and speed boat rides.</li><br />
                                        <li>• Taste authentic Andaman cuisine.</li><br />
                                        <li>• Rent a scooter or Royal Enfield Bullet to explore the island.</li><br />
                                        <li>• Take a seaplane ride for breathtaking views of Andaman.</li><br />
                                        <li>• Capture the stunning sunrises and sunsets.</li><br />
                                        <li>• Travel between islands on a cruise with The M.V Makruzz.</li><br />
                                        <li>• Visit India's only active volcano, Barren Island.</li><br />
                                    </ul>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DISTANCES BETWEEN ANDAMAN TO NEAREST FOREIGN CITIES</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DISTANCES BETWEEN ANDAMAN TO NEAREST FOREIGN CITIES</h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>Places From</th>
                                                    <th>Places To</th>
                                                    <th>Distance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="From">ANDAMAN & NICOBAR</td>
                                                    <td data-title="To">PHUKET</td>
                                                    <td data-title="Distance">751 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">ANDAMAN & NICOBAR</td>
                                                    <td data-title="To">BANGKOK</td>
                                                    <td data-title="Distance">880 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">ANDAMAN & NICOBAR</td>
                                                    <td data-title="To">KRABI</td>
                                                    <td data-title="Distance">795 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">ANDAMAN & NICOBAR</td>
                                                    <td data-title="To">SURATANI</td>
                                                    <td data-title="Distance">784 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">ANDAMAN & NICOBAR</td>
                                                    <td data-title="To">CAPE TOWN</td>
                                                    <td data-title="Distance">9350 KM</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">ANDAMAN & NICOBAR</td>
                                                    <td data-title="To">BANG SAPHAN</td>
                                                    <td data-title="Distance">750 KM</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /> <br /> <br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                <span>ANDAMAN HAVELOCK TOUR SUMMARY FROM KOLKATA</span>
                                            </h2>
                                            <h4>7 Nights 8 Days <strong>Andaman Havelock Tour Itinerary</strong></h4>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--end Short description--> */}
                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    DAY 1  – Port Blair – City Tour
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        On arrival at Port Blair airport, our representative will receive and escorted to the hotel. After check-in  at  the  hotel  and  little  relax, we proceed to Carbyn‟s Cove Beach & Cellular Jail.
                                                        Evening enjoy the enthralling Light and  Sound Show at Cellular  Jail where  the  saga  of  the freedom struggle brought alive. Overnight stay at Port Blair.
                                                    </p>
                                                    <p><b>• Corbyn's Cove Beach </b>
                                                        The coconut-palm fringed beach, 3 kilometers away town is ideal for swimming and sun- basking. Facilities like hotel, restaurant, bar, changing room, adventure water-sports are available here. Historical remains like Japanese Bunkers can be seen on the way to this beach. The Snake Island situated just across the beach is popular for scuba diving. The Restaurant & Beer Bar run by Tourism department provides delightful cuisine at reasonable rates in a soothing ambiance on the beach.
                                                    </p>
                                                    <p><b>• Light and Sound Show </b>
                                                        The Sound and Light show in the Cellular Jail narrates the saga of the Indian freedom struggle, brought alive through sound and light effect of the brutal and barbaric atrocities piled on the freedom fighters deported to these islands and incarcerated in the small cells of this jail. This show is very popular among the tourists visiting these islands.
                                                    </p>
                                                    <p><b>• Cellular Jail </b>
                                                        The Cellular jail, declared a National Memorial, is located at Port Blair which had stood as a mute witness to the most brutal and barbaric atrocities meted out to national freedom fighters, who were incarcerated in this jail. The jail, completed in the year 1906 acquired the name „Cellular' because it was entirely made up of individual cells for solitary confinement. It originally was a seven- block structure with three stories and a central tower acting as its fulcrum comprising of honeycomb like corridors. This jail, now a place of pilgrimage for all
                                                    </p>
                                                    <p>freedom-loving people draws everyone down memory lane to the years of freedom struggle. Jail Museum open from 9:00 am to 12:00 noon and from 1:30 pm to 4:45 pm.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day2"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    DAY 02: Port Blair to Excursion to North Bay & Ross Island
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After Breakfast you will be escorted to Andaman Water Sports Complex to catch a ferry which will be your mate for the entire excursion to Ross Island and North Bay. Overnight Stay at Port Blair.
                                                    </p>
                                                    <p><b>• North Bay</b>
                                                        It is also called “The Gate Way to Port Blair”. This place is famous for its coral reefs, exotic verities‟ of beautiful ornamental fishes and water sports activities. The breathtaking stretch of coral bed leaves you spellbound. North Bay is the foremost snorkeling point of Andaman. Apart from snorkeling and glass-bottom boat ride, scuba diving, underwater sea walk facility is also available at North Bay.
                                                    </p>
                                                    <p><b>• Ross Island </b>
                                                        It was the Capital of Port Blair during British and  Japanese regime, prior to India‟s Independence. It was named after Sir Daniel Ross a marine surveyor. It was nick named
                                                        “The Paris of the East”. It is now under the supervision of the Indian Navy. This island is an ideal destination for nature walk amidst sylvan surroundings with deer‟s, peacocks and exotic birds. Then back to Hotel.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day3"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 03: Port Blair – SwarajDweep Island (Havelock) - Radhanagar Beach
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Transfer to SwarajDweep (Havelock Island) by Cruise. On arrival, our representative  will receive from Havelock jetty and escort you to check-in to the selected hotel. After little relax, proceed for the world‟s most renowned beaches; Radhanagar Beach.
                                                        The Island also has the biggest concentration of breath-taking diving and snorkeling sites, thus acting as a paradise to marine world explorers. Overnight stay at Havelock.
                                                    </p>
                                                    <p><b>• SwarajDweep (Havelock) </b>
                                                        The Island (often just called Havelock) is one of the most beautiful and largest islands of Ritchie's Archipelago and is a placid heaven like no other. Situated 57 km north east of the South Andaman with an area of 92 Sq.Kms she offers a breathe taking trail of nature expeditions. The island is primarily populated by Bengali settlers. This picturesque Island with beautiful sandy beaches and lush green forest is also rated as the seventh most beautiful island in the world. The Island also has the biggest concentration of breath- taking diving and snorkeling sites, thus acting as a paradise to marine world explorers.
                                                    </p>
                                                    <p><b>Note:</b> Collect the packed Breakfast from the hotel reception, if you are traveling by early morning Cruise. Ferry timings is mentioned in the tickets provided to you, please report 45 minutes prior to departure with a valid id card. Ferry operation is purely subject to Govt. clearance and weather condition. Class of travel and timings of the ferry is subject to availability at the time of confirmation.</p>
                                                    <br />

                                                    <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="Kashmir Tour Packages" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Packages" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day4"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 04: (SwarajDweep) Havelock – Kalapathar Beach
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast, proceed for Kala Pathar Beach. Overnight stay at SwarajDweep (Havelock Island).<br />

                                                        <b>• Kalapathar Beach </b>
                                                        It is located in one corner of the Havelock Island surrounded by thick forests and excellent for solitude seekers and guest looking to do a bit of bird photography. The emerald seas, the tropical forests on one side of the road, the Kalapathars (black rocks) that decorates the coastline, the silken smooth silver sands and the sheer solitude makes this beach a great place to relax.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay4ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day5"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 05: SwarajDweep (Havelock Island) – ShaheedDweep (Neil Island).
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        At morning have your breakfast in the hotel after that our representative will help you to board the Boat which will sail to the ShaheedDweep (Neil Island Island). On arrival at Neil Island, our representative will receive and escort you to check-in to the resort/hotel. After that start a trip for sightseeing Laxmanpur, Bharatpur& Howrah Bridge. Overnight stay at Neil Island.
                                                    </p>
                                                    <p><b>• Bharatpur Beach </b>
                                                        The Bharatpur Beach is a beach of a low tide located just half a kilometer from the jetty, considered to be the best beach in the Neil Island. The calm, tranquil and perchance the picturesque beach is also popular with various water activities like swimming, snorkeling and scuba diving.
                                                    </p>
                                                    <p><b>• Natural Bridge</b>
                                                        Neil Island is famous for its naturally shaped- like rock also known as the Howrah Bridge. It has two living natural corals formed just like a bridge. The rock formation was called by the Bengalis who settled in the island in the early days and they refer it as the RabindraSetu and later called it the Howrah Bridge. It is best seen during low tide.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day6"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 06: ShaheedDweep (Neil Island) - Port Blair
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, board Nautika / Green Ocean Cruise / Makruzz and return to Port Blair. Evening free for local marketing. Overnight stay at Port Blair.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay6ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day7"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 07: Port Blair –Baratang
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Early morning, tour starts for Baratang. Visit Limestone Cave and Mud Volcano. On the way, one may see the local triballs called Jarawas. Overnight stay at Port Blair.
                                                    </p>
                                                    <p><b>• Baratang </b>
                                                        Island is 100 Kms by road from Port Blair is between South and Middle Andaman and popular for mangrove, Mud Volcanoes and Limestone Caves. The prime attraction of the journey to the island through lush green tropical rain forest on Andaman
                                                        Trunk Road and crossing water channel where boats carry vehicles. The boat ride to the Limestone Caves pass through magnificient narrow mangroves creeks.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay7ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day8"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 08: Port Blair – Airport Dropping
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Dropping to airport with memories and terrific holiday at Andaman Islands.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay8ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}

                            {/* <!--Review details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Frequently Ask <span>Questions</span></h2>
                                            <h4>Andaman Havelock Island Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                    What are the best Andaman Luxury Tour Packages?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    It depends on how many days you plan to stay and your budget. A basic 4N/5D trip with 3 nights in Port Blair and 1 night in Havelock starts from INR 10,000 per person (land cost). For more details, you can contact Adorable Vacation for personalized guidance.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                    Is it safe to visit Andaman year-round?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    Yes, it is safe to visit Andaman anytime, but it’s best to avoid the monsoon (July to September) when the sea can be rough, and ferry services may be disrupted.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                    Is 3 days enough to visit Andaman?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    3 days is enough if you only visit Port Blair. For a more fulfilling trip, it's recommended to add 3–4 more days to explore other islands and enjoy activities.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSix">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                                    What should I pack for an Andaman Nicobar Package from Kolkata?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    Pack essentials like medicines, sunscreen, hats, sunglasses, light cotton clothes, swimwear, slippers, power banks, and enough cash as ATMs are limited.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                    What should I research before booking Andaman Nicobar Tour Package from Kolkata?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    Check information about Andaman's geography, history, weather, best places to visit, and activities like water sports.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEight">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                                    What are the top activities to do in Andaman?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    Scuba diving, snorkeling, parasailing, glass-bottom boating, game fishing, and trekking are popular activities in Andaman.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingNine">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                                    Which islands are best to visit in Andaman Trip Package from Kolkata?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    Popular islands include Port Blair, Havelock Island, Neil Island, Ross Island, Baratang Island, and Jolly Buoy Island.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                    Can I customize my Andaman Tour from Kolkata?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    Yes, you can customize your Andaman trip based on your travel style, preferred activities, and accommodation. Contact Adorable Vacation for help planning.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEleven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                                                    What currency should I carry for Andaman?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseEleven" className="accordion-collapse collapse show" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    Carry Indian Rupees (INR). You can exchange currency at Port Blair Airport, but it’s recommended to bring small denominations.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTwelve">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                                    Is Andaman a part of India?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseTwelve" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    Yes, the Andaman and Nicobar Islands are a Union Territory of India.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingThirteen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                                                    Which are the most popular destinations in Andaman Tour Plan?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseThirteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    Top spots include Cellular Jail, Radhanagar Beach, Havelock Island, Neil Island, and Chidiya Tapu.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFourteen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourteen" aria-expanded="false" aria-controls="flush-collapseFourteen">
                                                    Do Indian nationals need a permit to visit Andaman Tour Packages?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFourteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingFourteen" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    No permit is needed for Indian nationals to visit Andaman. However, certain tribal areas are restricted.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFifteen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFifteen" aria-expanded="false" aria-controls="flush-collapseFifteen">
                                                    Do foreigners need a passport and visa to visit Andaman Travel Plan?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFifteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingFifteen" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    Yes, foreigners need a valid passport and Indian visa. They also require a Restricted Area Permit (RAP) upon arrival in Port Blair.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSixteen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSixteen" aria-expanded="false" aria-controls="flush-collapseSixteen">
                                                    What are the best beaches to visit in Havelock Island?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSixteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingSixteen" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    Radhanagar Beach, Vijaynagar Beach, Elephant Beach, and Kalapathar Beach are must-visit beaches.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeventeen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeventeen" aria-expanded="false" aria-controls="flush-collapseSeventeen">
                                                    Is Cellular Jail open every day?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSeventeen" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeventeen" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    No, Cellular Jail is closed on Mondays. It is open from Tuesday to Sunday, 9 AM to 5 PM.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEighteen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEighteen" aria-expanded="false" aria-controls="flush-collapseEighteen">
                                                    Are ATMs available in Port Blair and Havelock Island?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseEighteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingEighteen" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    ATMs are available in Port Blair, but there are only two ATMs on Havelock Island (SBI and Axis Bank), which may not always be functional due to connectivity issues. Carry enough cash for Havelock.
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b>Andaman Package <span class="tomato">Tour Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div>
                </div>
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91636-76037">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                    </a>
                </div>
            </>
        );
    }
}

export default Andaman_7N_8D_Package_Tour_Kolkata;