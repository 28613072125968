import React, { Component } from 'react';

class AuthenticBhutaneseCuisine extends Component {

    render() {
        return (
            <>
                <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                        <tbody>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Ema Datshi (Chillies and Cheese)</td>
                                <td style={{ fontSize: "16px" }}>Kewa Datshi (Potatoes and Bhutanese Cheese)</td>
                                <td style={{ fontSize: "16px" }}>Shamu Datshi (Mushrooms and Cheese)</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Shakam Ema Datshi (Dried Beef with Chillies and Cheese)</td>
                                <td style={{ fontSize: "16px" }}>Gondo Datshi (Egg and Cheese Mixed)</td>
                                <td style={{ fontSize: "16px" }}>Shakam Paa (Dried Beef, Chillies, and Radish)</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Phaksha Paa (Pork with Red Chillies)</td>
                                <td style={{ fontSize: "16px" }}>Sikam Paa (Bhutanese Bacon)</td>
                                <td style={{ fontSize: "16px" }}>Yaksha Shakam (Dried Yak)</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Juma (Bhutanese Sausage)</td>
                                <td style={{ fontSize: "16px" }}>Jasha Maru (Spicy Chicken)</td>
                                <td style={{ fontSize: "16px" }}>Momos (Dumpling)</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Jaju (Traditional Bhutanese Soup)</td>
                                <td style={{ fontSize: "16px" }}>Goen Hogay (Bhutanese Cucumber Salad)</td>
                                <td style={{ fontSize: "16px" }}>Khur-Le (Buckwheat Pancake)</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Puta (Buckwheat Noodles)</td>
                                <td style={{ fontSize: "16px" }}>Tshampa (Flour Dough)</td>
                                <td style={{ fontSize: "16px" }}>Zaow (Crunchy Rice Snack)</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Chogo (Chhurpi)</td>
                                <td style={{ fontSize: "16px" }}>Ezay (Chilli Sauce)</td>
                                <td style={{ fontSize: "16px" }}>Ara (Traditional Alcoholic Beverage)</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />

            </>
        );
    }
}

export default AuthenticBhutaneseCuisine;