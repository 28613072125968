import React, { Component, Fragment } from "react";
import FooterDesktop from "../../../components/common/FooterDesktop";
import MainHeader from "../../../components/common/MainHeader";
import BlogExplorer from "../../../components/Blog/BlogExplorer";

import { Helmet } from "react-helmet";

class BlogPage extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Blog</title>
        </Helmet>

        <MainHeader />

        <BlogExplorer />

        <FooterDesktop />

      </Fragment>
    );
  }
}

export default BlogPage;
