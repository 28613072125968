import React, { Component, useState } from 'react';
import BookingBox from '../BookingBoxInternational';
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Vietnam/InclusionExclusion_vietnam_7N_8D_Kolkata';

class Vietnam_7N_8D_Kolkata_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            btn: "Submit",
            showLoader: false,
            isHidden: true,
            showModal: false,
            showMegamenu: false, // New state to handle megamenu
            firstName: '',
            mobile: '',
            email: '',
            comments: '',
            megamenuTimeout: null // New state for managing hover delay
        }
        this.timeoutId = null; // To store the timeout ID
        this.modalRef = React.createRef(); // Create a ref for the modal
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919163676037&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ showModal: true });
        }, 40000);
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        // Cleanup the event listener
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        // Check if the click is outside the modal
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.onCloseModal();
        }
    };

    toggleHidden = (e) => {
        e.preventDefault();
        this.setState({ isHidden: !this.state.isHidden });
    }

    handleMegamenuEnter = () => {
        // Clear any existing timeout
        if (this.state.timeoutId) {
            clearTimeout(this.state.timeoutId);
        }
        this.setState({ isHidden: false });
    };

    handleMegamenuLeave = () => {
        // Set a timeout to delay hiding the megamenu
        const timeoutId = setTimeout(() => {
            this.setState({ isHidden: true });
        }, 300); // Match this delay with the CSS transition time

        this.setState({ timeoutId });
    };


    onRequestCallback = (e) => {
        e.preventDefault();
        if (!this.state.showModal) {
            this.setState({ showModal: true });
        }
    }

    onCloseModal = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            showModal: false,
            firstName: '',
            mobile: '',
            email: '',
            comments: ''
        });
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    // Function to handle form submission
    onRequestCallbackSubmit = async (e) => {
        e.preventDefault();

        const { firstName, email, mobile, comments } = this.state;

        // Validation
        if (firstName.trim() === '') {
            cogoToast.error("Full Name Required.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
            // } else if (email.trim() === '') {
            //     cogoToast.error("Email Required.", {
            //         position: 'top-center', // Use top-center, then adjust via CSS
            //         hideAfter: 2, // optional: auto-hide after 2 seconds
            //         className: 'custom-toast' // Apply custom class
            //     });
        } else if (mobile.trim() === '' || !/^\d{10}$/.test(mobile.trim())) {
            cogoToast.error("Please Insert 10 digit Mobile No.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else {
            this.setState({ btn: "Processing..", showLoader: true });
            // Proceed with form submission
            try {
                const _ref = 'yes';
                const _url = window.location.href;

                const formData = {
                    FirstName: firstName,
                    Email: email,
                    Mobile: mobile,
                    Comments: comments,
                    Referrer: _ref,
                    URL: _url
                };

                const response = await axios.post(ApiURL.SaveCustomerEnquery, formData);
                console.log('Data submitted successfully:', response.data);
                // Optionally, reset the form state after successful submission
                this.setState({ firstName: '', email: '', mobile: '', comments: '' });

                // Optionally, show a success message to the user
                cogoToast.success("Form submitted successfully.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
                this.setState({ btn: "Submit", showLoader: false, showModal: false });
            } catch (error) {
                console.error('Error submitting data:', error);
                this.setState({ btn: "Submit", showLoader: false });
                // Optionally, show an error message to the user
                cogoToast.error("Please try again after 24 hrs. later.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
            }

        }

    };


    onCloseModal = () => {
        this.setState({ showModal: false }, () => {
            // Additional code to perform after the modal is closed
            setTimeout(() => {
                this.setState({ showModal: true }, () => {
                    // Additional code to perform after the next modal is displayed
                });
            }, 55000); // 55sec in milliseconds
        });
    };

    render() {
        const sightseen1ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/book-vietnam-tour-packages-from-kolkata-with-adorable-vacation.webp";
        const sightseen2ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-family-package-tour-from-kolkata-with-adorable-vacation.webp";
        const sightseen3ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-holidays-tour-packages-from-kolkata.webp";
        const sightseen4ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-holiday-tour-packages-from-kolkata.webp";
        const sightseen5ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-packages-from-kolkata-for-couple.webp";
        const sightseen6ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-packages-from-kolkata-trip.webp";
        const sightseen7ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-packages-tour-cost-from-kolkata.webp";
        const sightseen8ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-package-tour.webp";
        const sightseen9ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-package-tour-booking-from-kolkata.webp";
        const sightseen10ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-package-tour-cost-from-kolkata-with-adorable-vacation.webp";
        const sightseen11ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-package-tour-itinerary-from-kolkata.webp";
        const sightseen12ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-tour-and-travel-packages-from-kolkata.webp";
        const sightseen13ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-tour-booking-from-kolkata.webp";
        const sightseen14ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-tour-packages-from-kolkata-at-best-price-from-adorable-vacation.webp";
        const sightseen15ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-tour-packages-from-kolkata-with-flight.webp";
        const sightseen16ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-tour-plan-from-kolkata-with-direct-flight.webp";
        const sightseen17ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-travel-packages-from-kolkata-with-direct-flight.webp";
        const sightseen18ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-trip-from-kolkata-with-adorable-vacation.webp";
        const sightseen19ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-trip-packages-from-kolkata-with-adorable-vacation.webp";
        const sightseen20ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-trip-plan-from-kolkata-with-airfare.webp";
        const sightseen21ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-vacation-packages-from-kolkata.webp";


        const rows = [
            { date: '24th Sept - 30th Sept', availability: 'Available', price: '84,500' },
            { date: '01st Oct - 07th Oct', availability: 'Available', price: '84,500' },
            { date: '04th Oct - 10th Oct', availability: 'Available', price: '84,500' },
            { date: '08th Oct - 14th Oct', availability: 'Available', price: '84,500' },
            { date: '11th Oct - 17th Oct', availability: 'Available', price: '84,500' },
            { date: '15th Oct - 21st Oct', availability: 'Available', price: '84,500' },
            { date: '18th Oct - 24th Oct', availability: 'Available', price: '84,500' },
            { date: '22nd Oct - 28th Oct', availability: 'Available', price: '84,500' },
            { date: '25th Oct - 31st Oct', availability: 'Available', price: '84,500' },
            { date: '29th Oct - 04th Nov', availability: 'Available', price: '84,500' },
            { date: '1st Nov - 07th Nov', availability: 'Available', price: '84,500' },
            { date: '05th Nov - 11th Nov', availability: 'Available', price: '84,500' },
        ];

        const rowsPerPage = 6;
        const totalPages = Math.ceil(rows.length / rowsPerPage);
        const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
        const { customActiveTab, daywiseActiveTab } = this.state;

        const packageTopBannerImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-package-tour-from-kolkata-airport-for-couple-family.webp";
        const packageTopBanner2ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-tour-packages-from-kolkata-airport-for-couple.webp";
        const FlightBannerImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/Bhutan-Group-Tour-Package-from-Mumbai.webp";
        const navDay1ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-hanoi-package-tour-from-kolkata-with-flight-tickets.webp";
        const navDay2ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-halong-bay-cruise-package-tour-from-kolkata-with-flight-tickets.webp";
        const navDay3ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/mumbai-to-bhutan-tour-package-cost-with-direct-flights-with-adorable-vacation.webp";
        const navDay4ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-bamboo-basket-boat-tour-danang-from-kolkata-with-flight-tickets.webp";
        const navDay5ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-danang-golden-bridge-package-tour-from-kolkata-with-flight-tickets.webp";
        const navDay6ImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/vietnam-ho-chi-minh-city-tour-from-kolkata-with-flight.webp";

        const ButtonImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/call-for-vietnam-tour-packages-from-kolkata.webp";
        const PriceBannerImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/best-price-for-vietnam-tour-packages-from-kolkata.webp";

        const hotPackagesDataList = this.props.HotPackagesData;
        const hotPackagesView = hotPackagesDataList.length > 0 ?
            (
                hotPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.FIFO_6N_7D_ImageURL + "/" + dataItem.ImageName;
                    const contactURL = dataItem.Url;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} | {dataItem.EXPARO}<span> &#8377; {dataItem.PricePerNight}</span> </h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue">{dataItem.SDFInclude}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href={contactURL} className="btn btn-Warning btn-sm float-end">Call Now</a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const themePackageSliderList = this.props.ThemePackagesData;
        const themePackagesDataView = themePackageSliderList.map(
            (sliderItem, i) => {
                const sliderImageURL =
                    ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL +
                    "/" +
                    sliderItem.ImageName;
                return (
                    <div className="theme-cards-wrapper mx-2" key={i}>
                        <div className="theme-card">
                            <div className="image-wrapper">
                                <img src={sliderImageURL} alt={sliderItem.AllternateText} />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">{sliderItem.Title}</h5>
                                <p className="card-text">{sliderItem.Description}</p>
                                <a href="#" className="btn btn-success btn-sm" onClick={this.handleClick1}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                );
            }
        );

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Vietnam_7N_8D_Kolkata_PackageImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })


        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="bhutan b2b package tour from adorable vacation the best bhutan b2b dmc" />

                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container">
                                        <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center>Book <strong>Vietnam Package Tour from Kolkata</strong> - BEST DEAL 2024/25 </center></h1>
                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center>Book <strong>Vietnam Package Tour from Kolkata</strong> - BEST DEAL 2024/25 </center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="bhutan b2b tour packages from adorable vacation the best bhutan b2b dmc in india" />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                4 Cities:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Hanoi
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Halong Bay Cruise
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Da Nang
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Ho Chi Minh
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable Vietnam tour, you have come to the Right Place! Adorable vacation Offer the best <strong>Vietnam Package Tour from Kolkata</strong>.
                                        </p>
                                        <hr />
                                        <br />
                                        <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}> LOOKING FOR <strong>VIETNAM TOUR PACKAGES FROM KOLKATA </strong> ? <br /><br />GET BEST DEAL FOR <a href="tel:+91-9163676037" class="reviews"><strong>KOLKATA TO VIETNAM TOUR </strong> &nbsp;&nbsp;☎ CALL +91-91636-76037</a></h2>
                                        <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> LOOKING FOR <strong>VIETNAM TOUR PACKAGES FROM KOLKATA</strong> ? <br /><br />GET BEST DEAL FOR <a href="tel:+91-9163676037" class="reviews"><strong>KOLKATA TO VIETNAM TOUR </strong> ☎ CALL +91-91636-76037</a></h3>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="card">
                                <div className="card-body">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr className="yellow">
                                                <th rowSpan="2"><h3>DAY WISE ITINERARY :<strong> VIETNAM PACKAGE TOUR FROM KOLKATA</strong></h3></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingOne"
                                                >
                                                    <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                        <span>
                                                            HANOI ARRIVAL – CITY TOUR & NIGHT STAY AT HOTEL
                                                        </span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingTwo"
                                                >
                                                    <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                        <span>HANOI - HALONG BAY & OVER NIGHT ON CRUISE</span>
                                                    </a>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingThree"
                                                >
                                                    <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                        <span>HALONG – HANOI TRIP & OVER NIGHT STAY IN DANANG</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingFour"
                                                >
                                                    <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                        <span>DANANG - CAM THANH VILLAGE & NIGHT STAY IN DANANG</span>
                                                    </a>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingFive"
                                                >
                                                    <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                        <span>DANANG - BA NA HILLS DAY TRIP & NIGHT STAY IN HOTEL</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingSix"
                                                >
                                                    <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                        <span>DANANG - HO CHI MINH TRIP & NIGHT STAY IN HOTEL</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingTen"
                                                >
                                                    <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                        <span>HO CHI MINH - CU CHI TUNNELS & OVER NIGHT STAY AT HO CHI MINH</span>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingTen"
                                                >
                                                    <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                                                        <span>HO CHI MINH DEPARTURE</span>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:+91-9163676037'><img src={ButtonImageURL} alt="call for vietnam tour packages from kolkata" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br /> <br />
                            <div className="card">
                                <div className="card-body" itemtype="https://schema.org/Table">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr className="yellow">
                                                <th rowSpan="2"><h3>INCLUSIONS : <strong>VIETNAM PACKAGE TOUR FROM KOLKATA</strong></h3></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span style={{ color: 'black' }}>06 night accommodation based on triple sharing basis</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <span style={{ color: 'black' }}>01 night cruise based on on triple sharing basis boat in Halong Bay</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <span style={{ color: 'black' }}>Meals as mentioned in the program</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <span style={{ color: 'black' }}>All entrance fees and sightseeing as mentioned in the program</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <span style={{ color: 'black' }}>Boat trip(s) as mentioned in the itinerary</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <span style={{ color: 'black' }}>SIC tours as mentioned in the itinerary</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <span style={{ color: 'black' }}>All Transportation by A/C vehicle</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span style={{ color: 'black' }}>Water during tour day</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span style={{ color: 'black' }}>Local English speaking guide(s) in Vietnam</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span style={{ color: 'black' }}>Vietnam single entry tourist visa charges</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span style={{ color: 'black' }}>All Taxes (Except TCS 5%)</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:+91-9163676037'><img src={PriceBannerImageURL} alt="best price for vietnam tour packages from kolkata" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DURATION FOR <strong>VIETNAM PACKAGE TOUR FROM KOLKATA</strong> : 7N|8D </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> DURATION FOR <strong>VIETNAM PACKAGE TOUR FROM KOLKATA</strong> : 7N|8D </h3>
                                </div>
                                <div className="card-body">
                                    <h3 style={{ fontSize: "1.5vw" }}>DESTINATIONS COVERED IN <strong>VIETNAM TOUR PACKAGES FROM KOLKATA</strong> : HANOI 1N | HALONG BAY CRUISE 1N | DANANG 3N | HO CHI MINH 2N <br /><br /></h3>

                                    <h3 style={{ fontSize: "1.5vw" }}><b>PICKUP & DROP</b> : NOI BAI INTERNATIONAL AIRPORT [HAN] </h3>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WE ARE THE BEST OPERATOR FOR <strong>VIETNAM PACKAGE TOUR FROM KOLKATA</strong> </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> WE ARE THE BEST OPERATOR FOR <strong>VIETNAM PACKAGE TOUR FROM KOLKATA</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p>Looking for an amazing vacation? A <strong>Vietnam package tour from Kolkata</strong> is the ideal option for travelers wanting a blend of adventure, culture, and history. Vietnam has quickly become a popular travel destination for people from Kolkata due to its stunning landscapes, rich heritage, lively cities, and affordable packages. Imagine exploring the breathtaking views of <b>Halong Bay</b>, a UNESCO World Heritage site known for its emerald waters and thousands of limestone islands. You can also stroll through the charming lantern-lit streets of <b>Hoi An</b>, where ancient architecture meets vibrant local markets, or immerse yourself in the bustling energy of <b>Hanoi</b> and <b>Ho Chi Minh City</b>, where history and modernity coexist. Each of these locations offers something unique and unforgettable.<br /><br />

                                        Vietnam's history is significant, having faced challenges from war between 1955 and 1975. However, the country has transformed dramatically since then, emerging as a thriving tourist hotspot in Southeast Asia. The visa process is straightforward, making it easy for travelers planning a <strong>Vietnam tour from Kolkata</strong>. Additionally, the package prices are very reasonable, often covering flights, accommodations, meals, and guided tours, which provide great value for your money.<br /><br />

                                        A <strong>Vietnam tour package from Kolkata</strong> not only fits your budget but also ensures a smooth and enjoyable travel experience. Discover Vietnam's cultural and historical sites, such as the War Remnants Museum in Ho Chi Minh City, which offers deep insights into the country’s past, and ancient temples like the One Pillar Pagoda in Hanoi, a symbol of Vietnamese culture. Experience the delicious local cuisine, from pho and banh mi to fresh seafood and tropical fruits. Vietnam's diverse landscape also allows for exciting activities like trekking in the Sapa mountains, relaxing on the pristine beaches of Da Nang, or taking a boat ride through the Mekong Delta.<br /><br />

                                        Despite its turbulent history, Vietnam has shown incredible resilience and growth, making it an enticing destination for millions of tourists each year. Whether you seek a relaxing beach holiday, an adventurous trek, or a cultural exploration, a <strong>Vietnam package tour from Kolkata</strong> promises an enriching and unforgettable journey. With so much to see and do, your trip to Vietnam will be filled with lasting memories and experiences.<br /></p>

                                    <p>Apart from this <strong>Vietnam tour packages from Kolkata</strong>, Adorable Vacation - also offers personalized and flexible <a href="/Thailand-Tour-Package-from-kolkata-Airport" className="tomato" target='_blank'><b className="tomato">Thailand package tour from Kolkata</b></a>, <a href="/egypt-package-tour-from-kolkata-8night-9days" target="_blank" ><b className="tomato">Egypt package tour from Kolkata</b></a>,  <a href="/bhutan-tour-package-from-pune" target="_blank"><b className="tomato">Bhutan Package Tour from Pune</b></a>,<a href="/bhutan-tour-from-delhi" target="_blank"><b className="tomato"> Bhutan Package Tour from Delhi</b></a>, <a href="/bhutan-tour-package-from-mumbai" target="_blank"><b className="tomato">Bhutan package tour from Mumbai</b></a>, <a href="/bhutan-tour-package-from-ahemdabad" target="_blank"><b className="tomato">Bhutan Package Tour from Ahmedabad</b></a>, <a href="/bhutan-tour-from-bengaluru" target="_blank"><b className="tomato">Bhutan Package Tour from Bangalore</b></a>, <a href="/bhutan-tour-package-from-jaigaon" target="_blank"><b className="tomato"> Bhutan Package Tour from Jaigaon</b></a>, <a href="/bhutan-tour-package-from-kolkata" target="_blank"><b className="tomato">Bhutan Package Tour from Kolkata</b></a>, <a href="/bhutan-tour-package-from-bagdogra" target="_blank"><b className="tomato">Bhutan Package Tour from Bagdogra Airport</b></a>, <a href="/bhutan-tour-package-from-surat" target="_blank"><b className="tomato">Bhutan Package Tour from Surat</b></a>, <a href="/mumbai-special-chartered-flight" target="_blank"><b className="tomato">Bhutan Package Tour from Mumbai with Chartered Flight</b></a> at Best Price. <br /><br />So, pickup your phone and give us a call for <strong> Vietnam package tour booking from Kolkata </strong> <a href='tel:+91-9163676037' className='tomato'>☎ CALL +91-91636-76037</a> <br /><br /><br /></p>

                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen1ImageURL}
                                                        alt="book vietnam tour packages from kolkata with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen2ImageURL}
                                                        alt="vietnam family package tour from kolkata with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen3ImageURL}
                                                        alt="vietnam holidays tour packages from kolkata"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> FACTS NEED TO KNOW BEFORE PLANNING A <strong>VIETNAM PACKAGE TOUR FROM KOLKATA</strong> </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> FACTS NEED TO KNOW BEFORE PLANNING A <strong>VIETNAM PACKAGE TOUR FROM KOLKATA</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div itemscope="" id="no-more-tables" itemtype="https://schema.org/Table">
                                        <table class="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody><tr><th>Fact</th><th>Details</th></tr>
                                                <tr><td>Capital</td><td>Hanoi</td></tr>
                                                <tr><td>Largest City</td><td>Ho Chi Minh City (Saigon)</td></tr>
                                                <tr><td>Official Language</td><td>Vietnamese</td></tr>
                                                <tr><td>Population</td><td>Approximately 98 million (as of 2021)</td></tr>
                                                <tr><td>Area</td><td>331,212 square kilometers</td></tr>
                                                <tr><td>Currency</td><td>Vietnamese Dong (VND)</td></tr>
                                                <tr><td>Government</td><td>Socialist Republic</td></tr>
                                                <tr><td>Time Zone</td><td>Indochina Time (ICT) UTC+7</td></tr>
                                                <tr><td>Independence Day</td><td>September 2, 1945</td></tr>
                                                <tr><td>National Flower</td><td>Lotus</td></tr>
                                                <tr><td>National Animal</td><td>Water Buffalo</td></tr>
                                                <tr><td>Major Religion</td><td>Buddhism</td></tr>
                                                <tr>
                                                    <td>UNESCO World Heritage Sites</td>
                                                    <td>Ha Long Bay - Hoi An Ancient Town - Hue Monuments - My Son Sanctuary - Phong Nha-Ke Bang National Park</td>
                                                </tr>
                                                <tr><td>Famous Cuisine</td><td>Pho (noodle soup), Banh Mi (Vietnamese sandwich), Spring Rolls, Com Tam (broken rice)</td></tr>
                                                <tr><td>Notable Landmarks</td><td>Hoan Kiem Lake and Ngoc Son Temple (Hanoi), Cu Chi Tunnels (Ho Chi Minh City), Imperial City (Hue), My Son Sanctuary (Quang Nam Province)</td></tr>
                                                <tr><td>Longest River</td><td>Mekong River</td></tr>
                                                <tr><td>Highest Mountain</td><td>Fansipan in the Hoang Lien Son mountain range</td></tr>
                                                <tr><td>War History</td><td>Vietnam War (1955 - 1975)</td></tr>
                                                <tr><td>Popular Beach Destinations</td><td>Nha Trang, Da Nang, Phu Quoc Island</td></tr>
                                                <tr><td>Climate</td><td>Tropical monsoon climate, with distinct wet and dry seasons</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen4ImageURL}
                                                        alt="vietnam holiday tour packages from kolkata"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen5ImageURL}
                                                        alt="vietnam packages from kolkata for couple"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen6ImageURL}
                                                        alt="vietnam packages from kolkata trip"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>

                            <br /><br /><br />
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="image-cell">
                                            <a href='#' onClick={this.onRequestCallback}><img src={PriceBannerImageURL} className="image-padding" alt="best price for vietnam tour packages from kolkata" /></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> <strong>VIETNAM PACKAGE TOUR COST FROM KOLKATA</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> <strong>VIETNAM PACKAGE TOUR COST FROM KOLKATA</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables" itemtype="https://schema.org/Table">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr>
                                                    <th>TOUR DURATION</th>
                                                    <th>2 PAX</th>
                                                    <th>4 PAX</th>
                                                    <th>6 PAX</th>
                                                    <th>Book Now</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>4N/5D [3 Star Hotel]</td>
                                                    <td>33,200</td>
                                                    <td>30,900</td>
                                                    <td>29,600</td>
                                                    <td><button onClick={this.onRequestCallback} className="btn btn-Warning btn-lg">
                                                        Book Now
                                                    </button></td>
                                                </tr>
                                                <tr>
                                                    <td>4N/5D [4 Star Hotel]</td>
                                                    <td>38,100</td>
                                                    <td>35,700</td>
                                                    <td>34,300</td>
                                                    <td><button onClick={this.onRequestCallback} className="btn btn-Warning btn-lg">
                                                        Book Now
                                                    </button></td>
                                                </tr>
                                                <tr>
                                                    <td>6N/7D [3 Star Hotel]</td>
                                                    <td>45,100</td>
                                                    <td>42,500</td>
                                                    <td>40,700</td>
                                                    <td><button onClick={this.onRequestCallback} className="btn btn-Warning btn-lg">
                                                        Book Now
                                                    </button></td>
                                                </tr>
                                                <tr>
                                                    <td>6N/7D [4 Star Hotel]</td>
                                                    <td>50,600</td>
                                                    <td>47,900</td>
                                                    <td>46,400</td>
                                                    <td><button onClick={this.onRequestCallback} className="btn btn-Warning btn-lg">
                                                        Book Now
                                                    </button></td>
                                                </tr>
                                                <tr>
                                                    <td>7N/8D [3 Star Hotel]</td>
                                                    <td>43,200</td>
                                                    <td>41,100</td>
                                                    <td>38,400</td>
                                                    <td><button onClick={this.onRequestCallback} className="btn btn-Warning btn-lg">
                                                        Book Now
                                                    </button></td>
                                                </tr>
                                                <tr>
                                                    <td>7N/8D [4 Star Hotel]</td>
                                                    <td>50,200</td>
                                                    <td>47,600</td>
                                                    <td>46,200</td>
                                                    <td><button onClick={this.onRequestCallback} className="btn btn-Warning btn-lg">
                                                        Book Now
                                                    </button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> <strong>MOST IMPORTANT CITIES OF VIETNAM AND LOCATION</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> <strong>MOST IMPORTANT CITIES OF VIETNAM AND LOCATION</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div itemscope="" itemtype="https://schema.org/Table" id="no-more-tables">

                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr><th>CITIES</th><th>ALTITUDE</th><th>COORDINATES</th></tr>
                                                <tr><td>Hanoi</td><td>20 m</td><td>21.0278° N, 105.8342° E</td></tr>
                                                <tr><td>Ha Long Bay</td><td>100 m</td><td>20.9101° N, 107.1839° E</td></tr>
                                                <tr><td>Danang</td><td>700m</td><td>16.0544° N, 108.2022° E</td></tr>
                                                <tr><td>Ho Chi Minh</td><td>19 m</td><td>10.8231° N, 106.6297° E</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>EXPLORE ENCHANTING VIETNAM : INCREDIBLE <strong>VIETNAM TOUR PACKAGES FROM KOLKATA</strong> - WHEN TO VISIT AND WHAT TO EXPERIENCE!</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> EXPLORE ENCHANTING VIETNAM : INCREDIBLE <strong>VIETNAM TOUR PACKAGES FROM KOLKATA</strong> - WHEN TO VISIT AND WHAT TO EXPERIENCE!</h3>
                                </div>
                                <div className="card-body">
                                    <p>Vietnam is an amazing country known for its diverse landscapes, rich history, and lively culture. Whether you love nature, are interested in history, or are searching for exotic <strong>Vietnam holiday packages</strong>, Vietnam has something special for everyone. To make the most of your <strong>Vietnam tour from Kolkata</strong>, it's important to know the <strong>best time to visit Vietnam</strong>.<br /><br /></p>

                                    <p>The weather in Vietnam can be very different depending on where you are. Generally, the country has a tropical climate, which means it is often humid and has a lot of rain throughout the year. However, the <strong>best time to visit Vietnam</strong> depends on which area you plan to explore.<br /><br /></p>

                                    <p><b>Northern Vietnam</b>, including cities like Hanoi, Halong Bay, and Sapa, experiences four distinct seasons. The best times to visit this region are from September to November and from March to May when the weather is mild and pleasant. During these months, expect clear skies and comfortable temperatures, perfect for discovering the area's natural beauty.<br /><br /></p>

                                    <p><b>Central Vietnam</b>, which includes Hue, Da Nang, and Hoi An, has a tropical monsoon climate with high temperatures and frequent rainfall. The best time to visit this region is from February to May, when the weather is dry and sunny. These months are great for enjoying the beautiful beaches, exploring ancient temples and pagodas, and participating in various cultural festivals.<br /><br /></p>

                                    <p><b>Southern Vietnam</b>, home to Ho Chi Minh City, the Mekong Delta, and Phu Quoc Island, also has a tropical climate with warm temperatures and rainfall year-round. The best time to visit this region is from December to April, when the weather is dry and sunny. This is an excellent time to explore the vibrant cities, taste delicious local dishes, and take a boat ride on the Mekong Delta.<br /><br /></p>

                                    <p>If you are planning a <strong>Vietnam trip from Kolkata</strong>, it’s a good idea to book your Vietnam package well in advance. This ensures you get the best availability and prices. With Adorable Vacation, <strong>Vietnam tour packages from Kolkata</strong> are available all year round, but prices tend to be higher during the peak season, which runs from December to February.<br /><br /></p>

                                    <p>In conclusion, the best time to visit Vietnam largely depends on which part of the country you want to see and what activities you plan to do. Whether you are looking for cultural experiences, beach vacations, or outdoor adventures, Vietnam has something for everyone. So, start planning your <strong>Vietnam package tour from Kolkata</strong> today and get ready to explore the enchanting beauty of Vietnam!<br /><br /><br /></p>

                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen7ImageURL}
                                                        alt="vietnam packages tour cost from kolkata"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen8ImageURL}
                                                        alt="vietnam package tour"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen9ImageURL}
                                                        alt="vietnam package tour booking from kolkata"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer">
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>UNLOCK THE PERFECT TIMING: A MONTH-BY-MONTH GUIDE FOR BOOKING YOUR <strong>VIETNAM TOUR FROM KOLKATA</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> UNLOCK THE PERFECT TIMING: A MONTH-BY-MONTH GUIDE FOR BOOKING YOUR <strong>VIETNAM TOUR FROM KOLKATA</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div itemscope="" id="no-more-tables" itemtype="https://schema.org/Table">

                                        <table class="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody><tr><th>Season</th><th>Month</th><th>Weather</th><th>Temperature</th><th>Airfare</th></tr>
                                                <tr><td><b>Winter</b></td><td>December</td><td>Cool and Dry</td><td>15°C - 24°C</td><td>High</td></tr>
                                                <tr><td></td><td>January</td><td>Cool and Dry</td><td>14°C - 24°C</td><td>High</td></tr>
                                                <tr><td></td><td>February</td><td>Cool and Dry</td><td>15°C - 25°C</td><td>High</td></tr>
                                                <tr><td><b>Spring</b></td><td>March</td><td>Warm and sunny</td><td>17°C - 29°C</td><td>Moderate</td></tr>
                                                <tr><td></td><td>April</td><td>Warm and sunny</td><td>21°C - 32°C</td><td>Moderate</td></tr>
                                                <tr><td><b>Summer</b></td><td>May</td><td>Hot and Humid</td><td>24°C - 34°C</td><td>Low</td></tr>
                                                <tr><td></td><td>June</td><td>Hot and Humid</td><td>25°C - 35°C</td><td>Low</td></tr>
                                                <tr><td></td><td>July</td><td>Hot and Humid</td><td>26°C - 35°C</td><td>Low</td></tr>
                                                <tr><td></td><td>August</td><td>Hot and Humid</td><td>25°C - 34°C</td><td>Low</td></tr>
                                                <tr><td><b>Autumn</b></td><td>September</td><td>Warm and rainy</td><td>24°C - 32°C</td><td>High</td></tr>
                                                <tr><td></td><td>October</td><td>Cool and Dry</td><td>22°C - 30°C</td><td>High</td></tr>
                                                <tr><td></td><td>November</td><td>Cool and Dry</td><td>18°C - 28°C</td><td>High</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TRAVELING FROM KOLKATA TO HANOI : A LEISURE TRIP GUIDE</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> TRAVELING FROM KOLKATA TO HANOI : A LEISURE TRIP GUIDE</h3>
                                </div>
                                <div className="card-body">
                                    <p>If you're planning a <strong>Kolkata to Vietnam package Tour</strong> with Hanoi, you're in for a wonderful experience! Hanoi, the capital of Vietnam, is known for its rich history, stunning architecture, and vibrant culture. Here's everything you need to know about your journey. <br /><br /></p>


                                    <h4 className="dark-blue">Distance from Kolkata to Hanoi</h4>
                                    <p>The distance between Kolkata and Hanoi is approximately 1,700 kilometers (about 1,056 miles). This makes for a relatively short international flight, allowing you to reach your destination quickly. <br /><br /><br /></p>

                                    <h4 className="dark-blue">Flight Timing</h4>
                                    <p>Direct flights from Kolkata to Hanoi generally take around 3 to 4 hours, depending on the flight path and weather conditions. However, some flights may have layovers that can extend travel time. <br /><br /><br /></p>

                                    <h4 className="dark-blue">Best Flight Options from Kolkata to Hanoi</h4>
                                    <p>When considering flight options, it’s essential to choose airlines that provide good service and comfort for your journey. Some popular airlines offering flights from Kolkata to Hanoi include: <br /><br /></p>

                                    <li>IndiGo</li>
                                    <li>VietJet Air</li>
                                    <li>Air India</li>
                                    <li>Vietnam Airlines</li>
                                    <li>Singapore Airlines (with layovers)</li>
                                    <br /><br />

                                    <h4>Tips for <strong>Kolkata to Vietnam Trip</strong></h4><br />
                                    <ul>
                                        <li><b>Visa Requirements:</b> Ensure that you check the visa requirements for Indian citizens traveling to Vietnam. You can apply for an e-visa online for convenience.<br /><br /></li>
                                        <li><b>Currency Exchange:</b> It's advisable to exchange some money into Vietnamese Dong before you arrive or withdraw cash from ATMs in Hanoi.<br /><br /></li>
                                        <li><b>Local Transport:</b> Once in Hanoi, consider using ride-hailing apps or local taxis for easy navigation around the city.<br /><br /></li>
                                    </ul>
                                    <p>This trip from Kolkata to Hanoi promises to be an enriching experience filled with beautiful sights and delightful culinary adventures! Safe travels! <br /><br /></p>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> FLIGHT OPTIONS FROM KOLKATA TO HANOI</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> FLIGHT OPTIONS FROM KOLKATA TO HANOI</h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr>
                                                    <th>Flight No.</th>
                                                    <th>Sector</th>
                                                    <th>Flight Company</th>
                                                    <th>Depart Time from Kolkata</th>
                                                    <th>Arrival Time in Hanoi</th>
                                                    <th>Flight Duration</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>VJ 702</td>
                                                    <td>Kolkata to Hanoi</td>
                                                    <td>VietJet Air</td>
                                                    <td>06:00 AM</td>
                                                    <td>09:00 AM</td>
                                                    <td>3h 30m</td>
                                                </tr>
                                                <tr>
                                                    <td>AI 194</td>
                                                    <td>Kolkata to Hanoi</td>
                                                    <td>Air India</td>
                                                    <td>09:30 AM</td>
                                                    <td>12:30 PM</td>
                                                    <td>3h 30m</td>
                                                </tr>
                                                <tr>
                                                    <td>VN 989</td>
                                                    <td>Kolkata to Hanoi</td>
                                                    <td>Vietnam Airlines</td>
                                                    <td>12:45 PM</td>
                                                    <td>03:45 PM</td>
                                                    <td>3h 30m</td>
                                                </tr>
                                                <tr>
                                                    <td>6E 4012</td>
                                                    <td>Kolkata to Hanoi</td>
                                                    <td>IndiGo</td>
                                                    <td>02:15 PM</td>
                                                    <td>05:15 PM</td>
                                                    <td>3h 30m</td>
                                                </tr>
                                                <tr>
                                                    <td>SQ 449</td>
                                                    <td>Kolkata to Hanoi</td>
                                                    <td>Singapore Airlines</td>
                                                    <td>05:30 PM</td>
                                                    <td>09:00 PM (via SIN)</td>
                                                    <td>7h (1 stop)</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>UNCOVER THE BEST DESTINATIONS WITH OUR <strong>VIETNAM PACKAGE TOUR FROM KOLKATA</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> UNCOVER THE BEST DESTINATIONS WITH OUR <strong>VIETNAM PACKAGE TOUR FROM KOLKATA</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p>Vietnam is famous for its rich cultural heritage, beautiful landscapes, and delicious cuisine. It is a popular tourist destination for travelers from around the world. With stunning beaches, bustling cities, and serene countryside, Vietnam has something for everyone. In this article, we will explore the top destinations in Vietnam that you should consider during your <strong>Vietnam package tour from Kolkata</strong>.<br /><br /></p>

                                    <h4>Hanoi</h4>
                                    <p>Hanoi, the capital city of Vietnam, is known for its deep history and vibrant culture. The city features many museums, temples, and historical sites that showcase Vietnam's past. Key attractions in Hanoi include the Ho Chi Minh Mausoleum, the Temple of Literature, and the bustling streets of the Old Quarter. A visit to Hanoi is a must on your <strong>Vietnam tour packages from Kolkata</strong>.<br /><br /></p>

                                    <h4>Ha Long Bay</h4>
                                    <p>Ha Long Bay is a UNESCO World Heritage Site and one of the most breathtaking destinations in Vietnam. Famous for its stunning scenery, this bay features thousands of limestone islands and islets rising from the water. The best way to experience Ha Long Bay is by taking a boat tour. You can explore beautiful caves, go kayaking, and enjoy amazing views during your <strong>Vietnam trip from Kolkata</strong>.<br /><br /></p>

                                    <h4>Hoi An</h4>
                                    <p>Hoi An is a charming town on Vietnam's central coast, known for its well-preserved old town, which is also a UNESCO World Heritage Site. Here, you will find many temples, museums, and art galleries, along with some of the best restaurants in the country. Hoi An is especially famous for its talented tailors, who can create custom-made clothing in just a few hours, making it a highlight of your <strong>Vietnam packages from Kolkata</strong>.<br /><br /></p>

                                    <h4>Ho Chi Minh City</h4>
                                    <p>Ho Chi Minh City, also called Saigon, is the largest city in Vietnam. It is known for its lively culture and busy streets. The city is filled with museums, historical sites, and bustling markets where you can shop for souvenirs and taste delicious street food. Don’t miss the War Remnants Museum, the Cu Chi Tunnels, and Ben Thanh Market during your <strong>Vietnam tour from Kolkata</strong>.<br /><br /></p>

                                    <h4>Hue</h4>
                                    <p>Hue is an ancient city in central Vietnam, known for its rich history. Once the capital, Hue features many temples, palaces, and the tombs of the Nguyen dynasty. Top attractions include the Imperial City, the Thien Mu Pagoda, and the Tomb of Tu Duc, making it a great stop on your <strong>Vietnam trip from Kolkata</strong>.<br /><br /></p>

                                    <h4>Nha Trang</h4>
                                    <p>Nha Trang is a beautiful coastal city in southern Vietnam, famous for its stunning beaches and vibrant nightlife. The city is also home to cultural sites like the Po Nagar Cham Towers and Long Son Pagoda. It’s a perfect place to relax and enjoy during your <strong>Vietnam package tour from Kolkata</strong>.<br /><br /></p>

                                    <h4>Phu Quoc Island</h4>
                                    <p>Phu Quoc Island is located in the Gulf of Thailand and is known for its pristine beaches and lush nature. It’s the perfect spot for relaxation in a tropical paradise. The island features many luxury resorts and spas, making it an ideal getaway as part of your <strong>Vietnam packages from Kolkata</strong>.<br /><br /></p>

                                    <h4>Da Lat</h4>
                                    <p>Da Lat is a lovely mountain town in the central highlands of Vietnam, known for its cool climate and beautiful gardens. It features French colonial architecture, waterfalls, lakes, and hiking trails, attracting nature lovers during their <strong>Vietnam tour packages from Kolkata</strong>.<br /><br /></p>

                                    <h4>Mekong Delta</h4>
                                    <p>The Mekong Delta is located in southern Vietnam and is famous for its fertile land and floating markets. This region is home to rice paddies, fruit orchards, and traditional villages. It’s a wonderful place to learn about local life on your <strong>Vietnam tour from Kolkata</strong>.<br /><br /></p>

                                    <h4>Phong Nha-Ke Bang National Park</h4>
                                    <p>Phong Nha-Ke Bang National Park, in central Vietnam, is known for its stunning caves and unique karst formations. The park includes underground rivers, beautiful waterfalls, and limestone caves, including the world’s largest cave, Hang Son Doong. This park is a must-visit for adventure seekers on a <strong>Vietnam trip from Kolkata</strong>.<br /><br /></p>

                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen10ImageURL}
                                                        alt="vietnam package tour cost from kolkata with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen11ImageURL}
                                                        alt="vietnam package tour itinerary from kolkata"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen12ImageURL}
                                                        alt="vietnam tour and travel packages from kolkata"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>BEST HOTELS WE PROVIDE FOR <strong>VIETNAM PACKAGE TOUR FROM KOLKATA</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> BEST HOTELS WE PROVIDE FOR <strong>VIETNAM PACKAGE TOUR FROM KOLKATA</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">

                                        <table className="col-md-12 table-bordered table-striped table-condensed cf" itemtype="https://schema.org/Table">
                                            <tbody>
                                                <tr><td rowspan="2"><center>Location</center></td>
                                                    <td colspan="3"><center>Hotels (or equivalent)</center></td>
                                                </tr>
                                                <tr><td>03 star</td><td>04 star</td><td>05 star</td></tr>
                                                <tr><td>Hanoi</td><td>Anise Hotel<br />Deluxe City View</td><td>Thang Long Opera Hotel<br />Superior</td><td>Grand Vista Hotel<br />Deluxe</td></tr>
                                                <tr><td>Halong Bay</td><td>Le Journey Halong Cruise<br />Deluxe</td><td>Syrena Cruise<br />Deluxe</td><td>La Regina Royal Cruise<br />Noble Suite</td></tr>
                                                <tr><td>Danang</td><td>Soho Boutique Hotel<br />Superior</td><td>Mandila Beach Hotel<br />Deluxe ROH</td><td>Rosamia Hotel<br />ROH</td></tr>
                                                <tr><td>Ho Chi Minh</td><td>White Lotus Hotel<br />Superior</td><td>Aristo Saigon Hotel<br />Premier Deluxe</td><td>Hotel Grand Saigon<br />Deluxe</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen13ImageURL}
                                                        alt="vietnam tour booking from kolkata"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen14ImageURL}
                                                        alt="vietnam tour packages from kolkata at best price from adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen15ImageURL}
                                                        alt="vietnam tour packages from kolkata with flight"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="row py-5">
                                <div className="col-md-12">
                                    <div className="site-heading text-center">
                                        <h3>
                                            DETAIL ITINERARY FOR 8 DAYS <strong>VIETNAM TOUR PACKAGE FROM KOLKATA</strong>
                                        </h3>
                                        <h4>7N/8D <strong>Vietnam Tour Itinerary</strong> from Adorable Vacation</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div
                                    className="accordion"
                                    id="accordionPanelsStayOpenExample"
                                >
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day1"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseOne"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseOne"
                                            >
                                                <h4> DAY 01: HANOI ARRIVAL – CITY TOUR </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseOne"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingOne"
                                        >
                                            <div className="accordion-body">
                                                <p>Welcome to Hanoi, the lively capital of Vietnam! We are excited to have you join our <strong>Vietnam tour from Kolkata</strong>. Your adventure begins as soon as we pick you up from Noi Bai Airport, making your transition to the city smooth and easy. In the afternoon, we will visit Ba Dinh Square, the largest square in Hanoi, where many important cultural and political events happen. You can take pictures of the Ho Chi Minh Mausoleum from the outside, then we’ll head to the Temple of Literature, Vietnam's first university built in 1070. The beautiful gardens and well-preserved architecture give you a peaceful look into Vietnam's past. After that, we’ll stroll around Hoan Kiem Lake, a scenic spot in the city center, home to lovely temples and pagodas, popular among both locals and tourists. Finally, we’ll explore the Old Quarter, known as "The 36 Streets," where each street is named after the goods that were traditionally sold there. This vibrant area is full of life and is perfect for experiencing local culture. Overnight stay in Hanoi. <br /><br /></p>
                                                <div className="content-desktop">
                                                    <center><img src={navDay1ImageURL} alt="vietnam hanoi package tour from kolkata with flight tickets" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="vietnam hanoi package tour from kolkata with flight tickets" /></center>
                                                </div>
                                                <br /><br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day2"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseTwo"
                                            >
                                                <h4>DAY 02: HANOI - HALONG BAY – OVERNIGHT ON CRUISE</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseTwo"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingTwo"
                                        >
                                            <div className="accordion-body">
                                                <p>After breakfast at the hotel, we check out and get ready for an exciting day! Between 08:30 - 09:00 am, you’ll be picked up from your hotel in the Old Quarter and taken to Ha Long Bay, a UNESCO World Heritage Site known for its stunning natural beauty.<br /><br /></p>
                                                <p>Upon arrival at the Cruise Port, the friendly cruise staff will welcome you as you check in for your overnight cruise. Explore the magnificent beauty of Ha Long Bay, famous for its thousands of limestone islands and unique karst formations. After enjoying lunch on board, you can visit grottos, go swimming or kayaking, and see floating houses up close. Later, join a cooking demonstration and enjoy a delicious dinner. In the evening, relax on the boat or participate in fun activities. Overnight on the cruise.<br /><br /></p>
                                                <div className="content-desktop">
                                                    <center><img src={navDay2ImageURL} alt="vietnam halong bay cruise package tour from kolkata with flight tickets" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="vietnam halong bay cruise package tour from kolkata with flight tickets" /></center>
                                                </div>
                                                <br /><br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day3"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseThree"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 03: HALONG – HANOI – FLIGHT AFTER 18:00 TO DANANG</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseThree"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingThree"
                                        >
                                            <div className="accordion-body">
                                                <p>Wake up early to witness the beautiful sunrise over the bay. After breakfast, our boat will continue cruising to explore more of Ha Long Bay’s famous islets, each with its own unique shape and name. After brunch on board, we’ll check out and head back to the pier. A private driver will take you to Noi Bai airport for your flight to Danang (airfare not included). Upon arrival in Danang, you’ll be greeted by a local tour guide and taken to your hotel. Overnight in Danang.<br /><br /></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day4"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFour"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 04: DANANG - CAM THANH COCONUT VILLAGE </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseFour"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFour"
                                        >
                                            <div className="accordion-body">
                                                <p>After breakfast, we will visit Cam Thanh village and the Bay Mau coconut forest. Here, you can experience traditional local life. Enjoy a bamboo basket boat tour through the coconut groves, learn to paddle a traditional boat, and try your hand at catching fish and shrimp using local techniques. Relax in the beautiful countryside and enjoy the view. You can also watch local artisans create handicrafts from coconut leaves and even try making your own.<br /><br /></p>

                                                <p>In the afternoon, we’ll transfer to Hoi An Ancient Town, a magical place full of Oriental charm. We’ll walk over the Japanese Bridge, visit Chinese Assembly Halls, and explore ancient houses. You can listen to folk music or stroll through the local market by the Thu Bon River. Hoi An is famous for its affordable yet high-quality tailoring services, perfect for those looking to shop. The evening in Hoi An is enchanting, with streets lit by lanterns, creating a warm and inviting atmosphere. Afterward, we will drive back to the hotel in Danang. Overnight in Danang.<br /><br /></p>
                                                <div className="content-desktop">
                                                    <center><img src={navDay4ImageURL} alt="vietnam bamboo basket boat tour danang from kolkata with flight tickets" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="vietnam bamboo basket boat tour danang from kolkata with flight tickets" /></center>
                                                </div>
                                                <br /><br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day5"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFive"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 05: DANANG – BA NA HILLS – DANANG </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseFive"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFive"
                                        >
                                            <div className="accordion-body">
                                                <p>After breakfast, we’ll pick you up from your hotel around 07:00-08:00 am and transfer to Ba Na Hills, a stunning French-inspired hilltop resort. We will take the iconic cable car, which holds five world records, to the Ba Na Hills station. From the famous Golden Bridge, enjoy breathtaking views of the Truong Son Mountains and the East Sea. We’ll also visit Linh Ung Pagoda, home to a 27-meter-high Buddha statue, and explore the beautifully landscaped gardens. The tour includes time at Fantasy Park, where you can join thrilling modern games. Enjoy a buffet lunch at the restaurant.<br /><br /></p>

                                                <p>In the afternoon, we’ll visit the peak of Chua Mountain at 1,487 meters, where you can see Ba Chua Thuong Ngan Temple and take in panoramic views of Da Nang. After a fantastic day, we’ll head back to our hotel for the evening. Overnight in Danang.<br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay5ImageURL} alt="vietnam danang golden bridge package tour from kolkata with flight tickets" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="vietnam danang golden bridge package tour from kolkata with flight tickets" /></center>
                                                </div>
                                                <br /><br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day6"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSix"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 06: DANANG - MORNING FLIGHT TO HO CHI MINH</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSix"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSix"
                                        >
                                            <div className="accordion-body">
                                                <p>After breakfast and check-out, we will take you to the airport (without a tour guide) for your flight to Ho Chi Minh City (airfare not included). Upon arrival, you will be welcomed and taken to your hotel. In the afternoon, start your city tour at the War Remnants Museum, a significant historical site in Saigon that tells the story of Vietnam's struggle for independence. You’ll see exhibits that include photographs and documents that reflect the war's impact. We will also visit the Reunification Palace, which played a crucial role during the Vietnam War and is now a museum. Nearby, you can see the Notre Dame Cathedral and the Central Post Office, both beautiful examples of French colonial architecture. Finally, we’ll visit Ben Thanh Market, a famous spot for shopping and local food. Overnight in Ho Chi Minh City.<br /><br /></p>
                                                <div className="content-desktop">
                                                    <center><img src={navDay6ImageURL} alt="vietnam danang golden bridge package tour from kolkata with flight tickets" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="vietnam danang golden bridge package tour from kolkata with flight tickets" /></center>
                                                </div>
                                                <br /><br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day7"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 07: HO CHI MINH - CU CHI TUNNELS</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>Enjoy breakfast at the hotel. You’ll be picked up around 07:30-08:00 am for a morning trip to the Cu Chi Tunnels. This incredible network of tunnels, stretching over 200 km, played a crucial role during the war. After a brief introduction, you’ll have the chance to crawl into a section of the tunnels to experience their narrowness and heat. For an extra cost, you can also fire weapons like the M15 and AK-47 at the National Defense Sports Shooting Range, providing a unique insight into a soldier's experience. Return to Ho Chi Minh City for free time in the afternoon to relax or shop. Overnight in Ho Chi Minh City.<br /><br /></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day8"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 08: HO CHI MINH DEPARTURE</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>After breakfast, enjoy some leisure time until check-out. We will transfer you to the airport (without a guide) for your flight home. Thank you for joining our <strong>Kolkata to Vietnam tour</strong>! We hope you had an unforgettable experience and look forward to welcoming you on another adventure.<br /><br /></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}

                            <table style={{ width: '100%', textAlign: 'center' }}>
                                <tbody>
                                    <tr>
                                        <td className="image-cell" style={{ textAlign: 'center' }}>
                                            <img
                                                src={sightseen16ImageURL}
                                                alt="vietnam tour plan from kolkata with direct flight"
                                                style={{ margin: '10px' }}
                                            />
                                            <img
                                                src={sightseen17ImageURL}
                                                alt="vietnam travel packages from kolkata with direct flight"
                                                style={{ margin: '10px' }}
                                            />
                                            <img
                                                src={sightseen18ImageURL}
                                                alt="vietnam trip from kolkata with adorable vacation"
                                                style={{ margin: '10px' }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br /><br />

                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DISCOVER THE FINEST HILL STATIONS WITH OUR <strong>VIETNAM PACKAGES FROM KOLKATA</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DISCOVER THE FINEST HILL STATIONS WITH OUR <strong>VIETNAM PACKAGES FROM KOLKATA</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p>One of the most famous attractions on a <strong>Kolkata to Vietnam tour</strong> is its stunning hill stations, which offer breathtaking views and peaceful surroundings. Discover the best hill stations in Vietnam that you can visit on your next <strong>Vietnam package tour</strong>.<br /><br /></p>

                                    <p>Among the many wonders of a <strong>Vietnam tour</strong>, its hill stations stand out as true havens of tranquility and natural beauty. Prepare for an exciting journey to uncover the finest hill stations in Vietnam, promising spectacular vistas and moments of profound peace. Here, we introduce you to the top hill stations awaiting your exploration on your next <strong>Vietnam package tour from Kolkata</strong>.<br /><br /></p>

                                    <h4>Sapa</h4>
                                    <p>Nestled in the northwest of Vietnam, Sapa is renowned as one of the most famous hill stations in the country. Known for its picturesque rice terraces, cool climate, and rich cultural heritage, Sapa is home to several ethnic minorities, including the Hmong, Dao, and Tay. Visitors can explore hillside villages, trek through lush rice fields, and engage with the locals to gain insights into their vibrant way of life. Don't miss the chance to witness the mesmerizing sunrise over Fansipan, the highest mountain in Vietnam, making your <strong>Vietnam tour for couples from Kolkata</strong> even more special.<br /><br /></p>

                                    <h4>Dalat</h4>
                                    <p>Dalat is a charming hill station located in Vietnam's central highlands. Famous for its cool climate, lush forests, and stunning waterfalls, Dalat is often called the "City of Flowers" due to its many gardens and parks bursting with colorful blooms. Visitors can explore the city's French colonial architecture, ride a cable car to a nearby mountain, or go hiking in the surrounding hills. Enjoy local delicacies in its vibrant markets and make memories that will last a lifetime.<br /><br /></p>

                                    <h4>Mai Chau</h4>
                                    <p>Mai Chau is a picturesque town located in the Hoa Binh province of Vietnam. Known for its breathtaking scenery, expansive rice paddies, and traditional stilt houses, it offers a perfect escape from the hustle and bustle. Visitors can hike through rolling hills, cycle around the charming town, or simply relax in serene surroundings. Mai Chau is also home to several ethnic minority groups, including the White Thai and Black Thai, who preserve their traditional way of life, giving you a unique glimpse into Vietnam's rich culture.<br /><br /></p>

                                    <h4>Tam Dao</h4>
                                    <p>Tam Dao is a stunning mountain range located in northern Vietnam, near the border with China. Known for its refreshing climate, panoramic views, and dense forests, it is an ideal spot for nature lovers. Visitors can hike through the mountains, visit ancient temples and pagodas, or simply enjoy the fresh air and breathtaking scenery. Tam Dao is home to several rare and endangered species, making it a must-visit for wildlife enthusiasts on their <strong>Vietnam trip from Kolkata</strong>.<br /><br /></p>

                                    <h4>Ba Vi National Park</h4>
                                    <p>Ba Vi National Park is a protected area located in northern Vietnam, near Hanoi. It is famous for its diverse flora and fauna, including various bird species, monkeys, and butterflies. Visitors can hike through the park, explore its temples and pagodas, or unwind in hot springs. The park also boasts several beautiful waterfalls, including the famous Silver Waterfall, providing an ideal backdrop for relaxation and adventure alike.<br /><br /></p>

                                    <p>Vietnam truly is a testament to beauty, showcasing a rich tapestry of cultural heritage intertwined with stunning landscapes. Its hill stations emerge as sanctuaries, providing a peaceful retreat from urban life and an opportunity to immerse yourself in the country's natural wonders. Whether you're seeking adventure, relaxation, or cultural enlightenment, Vietnam's hill stations invite you to experience something extraordinary.<br /><br /></p>

                                    <p>If you are planning a <strong>tour package to Vietnam from Kolkata</strong>, make sure to check out the various <strong>Vietnam holiday packages</strong> offered by Adorable Vacation. These packages typically include visits to popular attractions like Sapa, Dalat, Mai Chau, Tam Dao, and Ba Vi National Park, as well as other breathtaking destinations throughout the country. So, pack your bags and prepare for an unforgettable journey through the best hill stations in Vietnam!<br /><br /></p>

                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen10ImageURL}
                                                        alt="vietnam package tour cost from kolkata with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen11ImageURL}
                                                        alt="vietnam package tour itinerary from kolkata"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen12ImageURL}
                                                        alt="vietnam tour and travel packages from kolkata"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>SAVOR AUTHENTIC VIETNAMESE CUISINE ON YOUR <strong>VIETNAM TOUR FROM KOLKATA</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> SAVOR AUTHENTIC VIETNAMESE CUISINE ON YOUR <strong>VIETNAM TOUR FROM KOLKATA</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p>Vietnamese cuisine is famous for its incredible mix of flavors, textures, and aromas. From vibrant street food to elegant dining experiences, Vietnam offers a wide range of delicious dishes that will delight your taste buds. If you're planning a <strong>Vietnam trip</strong>, you absolutely must try these popular foods that showcase the country's culinary richness.<br /><br /><br /></p>


                                    <ul>

                                        <li><b>Pho:</b> Pho is Vietnam's national dish and a must-try when you visit. This flavorful noodle soup is made with either beef or chicken broth, rice noodles, and an array of fresh herbs and spices. It's typically garnished with bean sprouts, lime wedges, and fresh herbs, making it a delightful and aromatic dish. Pho is often enjoyed for breakfast, but you can savor it any time of day!<br /><br /><br /></li>

                                        <li><b>Banh Mi:</b> Banh Mi is a tasty Vietnamese sandwich that has gained popularity worldwide. It features a crispy baguette filled with various ingredients, including meats, vegetables, and herbs. While pork is the most common filling, you can also find Banh Mi with chicken, beef, or tofu. This sandwich is perfect for a quick snack or a light meal.<br /><br /><br /></li>

                                        <li><b>Bun Cha:</b> Bun Cha is a beloved dish in Hanoi, the capital of Vietnam. It includes grilled pork patties served with rice noodles, fresh herbs, and a delicious dipping sauce made from fish sauce, vinegar, sugar, and chili. Bun Cha is often accompanied by crispy spring rolls, making it a well-rounded and flavorful meal.<br /><br /><br /></li>

                                        <li><b>Cao Lau:</b> Cao Lau is a unique noodle dish from the central region, especially popular in Hoi An. This dish features thick rice noodles, slices of tender pork, bean sprouts, and fresh herbs, all enhanced by a special broth made from local herbs and spices that gives it a distinctive taste.<br /><br /><br /></li>

                                        <li><b>Com Tam:</b> Known as "broken rice," Com Tam is a popular Vietnamese dish made with rice grains that are broken during milling. This dish is typically served with grilled pork, a fried egg, and a side of fresh vegetables. You can find Com Tam at many street food stalls and restaurants, making it a convenient and delicious option.<br /><br /><br /></li>

                                        <li><b>Goi Cuon:</b> Goi Cuon, or Vietnamese summer rolls, are fresh and healthy spring rolls filled with vegetables, herbs, and sometimes meat. Wrapped in rice paper, these rolls are served with a dipping sauce made of peanut butter, hoisin sauce, and chili. Goi Cuon is a refreshing snack, especially on warm days!<br /><br /><br /></li>

                                        <li><b>Banh Xeo:</b> Banh Xeo are savory pancakes made with rice flour, turmeric, coconut milk, and sometimes beer for extra flavor. They are filled with bean sprouts, shrimp, and slices of pork or beef. Served with fresh herbs and a dipping sauce of fish sauce, lime juice, and sugar, Banh Xeo is a delightful dish that is fun to eat.<br /><br /><br /></li>

                                        <li><b>Hu Tieu:</b> Hu Tieu is a versatile noodle soup that can be found in various regions of Vietnam. It typically includes a clear broth with rice noodles and various toppings, such as seafood, pork, or beef. Hu Tieu is often garnished with fresh herbs, lime, and chili, making each bowl a flavorful experience.<br /><br /><br /></li>

                                        <li><b>Mi Quang:</b> Mi Quang is a traditional noodle dish from Quang Nam Province. It features wide rice noodles topped with shrimp, pork, and fresh herbs, all served in a rich broth that is not as soupy as Pho. Often garnished with crushed peanuts and sesame rice crackers, Mi Quang is both filling and flavorful.<br /><br /><br /></li>

                                        <li><b>Chè:</b> Chè is a popular Vietnamese dessert that comes in various forms, usually made with beans, fruits, and coconut milk. Served cold, Chè is a sweet and refreshing treat that is perfect for ending a meal. There are many types of Chè, each with its own unique flavors and ingredients, making it a delightful way to experience Vietnamese sweetness.<br /><br /><br /></li>

                                    </ul>

                                    <p>Vietnamese cuisine is a vibrant tapestry of flavors and traditions, each dish telling a story of the country's rich culture. Make sure to indulge in these culinary delights during your <strong>Vietnam tour from Kolkata</strong>. Whether you're exploring street stalls or dining in fine restaurants, the food in Vietnam is sure to leave a lasting impression on your taste buds and memories. So get ready to embark on a delicious journey through Vietnam's amazing culinary landscape!</p>
                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen19ImageURL}
                                                        alt="vietnam trip packages from kolkata with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen20ImageURL}
                                                        alt="vietnam trip plan from kolkata with airfare"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen21ImageURL}
                                                        alt="vietnam vacation packages from kolkata"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TOP SOUVENIRS TO BUY ON <strong>VIETNAM PACKAGE TOUR FROM KOLKATA</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> TOP SOUVENIRS TO BUY ON <strong>VIETNAM PACKAGE TOUR FROM KOLKATA</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p>When visiting Vietnam, you'll discover a range of unique and culturally significant items that make for great souvenirs or gifts. Here are some of the top items to consider buying during your <strong>Vietnam tour from Kolkata</strong>:<br /></p>

                                    <ul>
                                        <li>Traditional Vietnamese Clothing (Ao Dai)</li><br /><br />
                                        <li>Vietnamese Silk Products (scarves, shawls, clothing)</li><br /><br />
                                        <li>Handicrafts and Lacquerware (lacquer bowls, vases, trays, wooden sculptures)</li><br /><br />
                                        <li>Vietnamese Coffee and Tea (coffee beans, ground coffee, coffee filters, tea leaves, tea sets)</li><br /><br />
                                        <li>Vietnamese Ceramics and Pottery (ceramic dishes, vases, decorative items)</li><br /><br />
                                        <li>Handmade Silk Lanterns (from Hoi An)</li><br /><br />
                                        <li>Vietnamese Conical Hats (Non La)</li><br /><br />
                                        <li>Vietnamese Rice Paper Products (notebooks, greeting cards, decorative items)</li><br /><br />
                                        <li>Local Spices and Seasonings (cinnamon, star anise, lemongrass, chili peppers)</li><br /><br />
                                        <li>Pearl Jewelry (necklaces, earrings, bracelets)</li><br /><br />
                                    </ul>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TOP INDIAN RESTAURANTS TO EXPERIENCE ON YOUR <strong>KOLKATA TO VIETNAM TRIP</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> TOP INDIAN RESTAURANTS TO EXPERIENCE ON YOUR <strong>KOLKATA TO VIETNAM TRIP</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p>Vietnam is a vibrant country celebrated for its unique blend of cultures and cuisines. While the local Vietnamese food is undeniably delicious, there are times when you might crave the comforting flavors of Indian cuisine, especially during your <strong>Vietnam trip</strong>. Fortunately, Vietnam boasts a variety of Indian restaurants that serve authentic and mouthwatering dishes. Here are some of the top Indian restaurants in Vietnam that you shouldn't miss on your <strong>Kolkata to Vietnam trip</strong><br /><br /></p>

                                    <h4>Baba's Kitchen</h4>
                                    <p>Baba's Kitchen is a well-loved chain of Indian restaurants in Vietnam, with locations in Hanoi, Ho Chi Minh City, and Hoi An. The menu features an extensive array of Indian dishes, catering to both vegetarian and non-vegetarian preferences. Don't miss the butter chicken, biryani, and freshly baked naan. For dessert, try their delectable gulab jamun and ras malai.<br /><br /></p>

                                    <h4>Taj Mahal Restaurant</h4>
                                    <p>Taj Mahal is another popular Indian restaurant, located in both Hanoi and Ho Chi Minh City. This restaurant offers a diverse menu showcasing dishes from various regions of India, including North Indian, South Indian, and Mughlai cuisine. Must-try dishes include chicken tikka masala, dal makhani, and butter naan, along with an array of vegetarian and vegan options.<br /><br /></p>

                                    <h4>Ganges Indian Restaurant</h4>
                                    <p>Situated in the heart of Ho Chi Minh City, Ganges Indian Restaurant is renowned for its authentic Indian flavors. Their menu features a variety of dishes, including tandoori chicken, lamb rogan josh, and vegetable biryani. Be sure to save room for their delicious Indian sweets like kulfi and ras malai.<br /><br /></p>

                                    <h4>Namaste Hanoi</h4>
                                    <p>Namaste Hanoi is a favorite among locals and tourists alike, offering a delightful selection of vegetarian and non-vegetarian dishes. The menu includes popular items like tandoori chicken, lamb vindaloo, and paneer tikka, along with various Indian breads such as garlic naan and roti. Known for its warm service and cozy atmosphere, Namaste Hanoi is a great place to enjoy a meal.<br /><br /></p>

                                    <h4>Ganesh Indian Restaurant</h4>
                                    <p>Ganesh is a beloved Indian restaurant located in Ho Chi Minh City, offering a wide range of both vegetarian and non-vegetarian options. Standout dishes include chicken tikka masala, lamb korma, and vegetable biryani. Ganesh is celebrated for its authentic flavors and generous portions, making it a must-visit.<br /><br /></p>

                                    <h4>Spice India Restaurant</h4>
                                    <p>Spice India is a popular dining spot in Hanoi, known for its diverse menu featuring dishes from across India. Highlights include tandoori chicken, butter chicken, and lamb rogan josh. This restaurant is praised for its friendly service and inviting atmosphere, making it an excellent choice for a relaxing meal.<br /><br /></p>

                                    <h4>Tandoor Indian Restaurant</h4>
                                    <p>Tandoor is a highly-rated Indian restaurant in Ho Chi Minh City that offers an array of dishes such as tandoori chicken, lamb vindaloo, and vegetable biryani. Their menu also includes various Indian breads like garlic naan and roti. Tandoor is recognized for its authentic flavors and welcoming ambiance, perfect for a delightful dining experience.<br /><br /></p>

                                    <p>In conclusion, if you find yourself in Vietnam craving Indian cuisine, you're in luck! These Indian restaurants provide delicious and authentic dishes that will surely satisfy your cravings. Enjoy your culinary adventure during your <strong>Kolkata to Vietnam trip</strong>!<br /><br /><br /></p>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> MUST-VISIT TEMPLES IN VIETNAM: YOUR ULTIMATE GUIDE FOR <strong> VIETNAM TOUR FROM KOLKATA</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>  MUST-VISIT TEMPLES IN VIETNAM: YOUR ULTIMATE GUIDE FOR <strong> VIETNAM TOUR FROM KOLKATA</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p>Vietnam is a country rich in cultural heritage and vibrant history, boasting numerous temples that showcase its spiritual traditions and architectural wonders. As you prepare for your <strong>Vietnam package tour from Kolkata</strong>, exploring these temples will provide an enriching experience that deepens your understanding of Vietnam's past and present. Here’s a comprehensive guide to the temples you must visit during your <strong>Vietnam vacation packages from Kolkata</strong>.<br /><br /></p>


                                    <h4>Tran Quoc Pagoda, Hanoi</h4>
                                    <ul>
                                        <li>Situated on the peaceful West Lake, Tran Quoc Pagoda is the oldest Buddhist temple in Hanoi.</li>
                                        <li>Its breathtaking architecture and tranquil environment make it a must-visit for both spiritual seekers and tourists.</li>
                                        <li>Admire the intricate carvings and statues that adorn the pagoda, showcasing the artistry of Vietnamese Buddhism.</li>
                                        <li>Tran Quoc Pagoda offers a serene escape from the busy streets of Hanoi, perfect for meditation and reflection.</li>
                                    </ul><br /><br />


                                    <h4>Temple of Literature, Hanoi</h4>
                                    <ul>
                                        <li>Constructed in 1070, the Temple of Literature symbolizes Vietnam's commitment to education and Confucian values.</li>
                                        <li>Explore its well-preserved courtyards, pavilions, and altars dedicated to scholars and Confucian teachings.</li>
                                        <li>The temple is home to the Imperial Academy, Vietnam's first national university, making it a significant historical site.</li>
                                        <li>Visitors can stroll through the beautiful gardens and learn about Vietnam's academic traditions and literary heritage.</li>
                                    </ul><br /><br />


                                    <h4>One Pillar Pagoda, Hanoi</h4>
                                    <ul>
                                        <li>One of Hanoi's most iconic landmarks, the One Pillar Pagoda is known for its unique design and spiritual significance.</li>
                                        <li>The pagoda's structure, resembling a lotus flower rising from the water, symbolizes purity and enlightenment in Buddhism.</li>
                                        <li>Though small, the One Pillar Pagoda radiates charm and elegance, attracting visitors from around the globe.</li>
                                        <li>Don't forget to include this architectural gem in your <strong>Kolkata to Vietnam tour itinerary</strong> to appreciate its beauty and cultural significance.</li>
                                    </ul><br /><br />


                                    <h4>Temples in Ho Chi Minh City (Saigon)</h4>
                                    <ul>
                                        <li>Ho Chi Minh City, formerly known as Saigon, features several temples that reflect its rich cultural diversity.</li>
                                        <li>Visit the Jade Emperor Pagoda, dedicated to the Taoist Jade Emperor, famous for its intricate woodcarvings and statues.</li>
                                        <li>Experience the vibrant rituals and practices at Mariamman Hindu Temple, a true testament to Vietnam's multicultural spirit.</li>
                                        <li>The Vinh Nghiem Pagoda, with its stunning architecture and calm atmosphere, offers a glimpse into Vietnamese Buddhism.</li>
                                        <li>Be sure to include these temples in your <strong>Vietnam vacation packages</strong> to explore the spiritual richness of Ho Chi Minh City.</li>
                                    </ul><br /><br />


                                    <h4>My Son Sanctuary, Hoi An</h4>
                                    <ul>
                                        <li>Nestled in lush greenery near Hoi An, My Son Sanctuary is a UNESCO World Heritage Site.</li>
                                        <li>These ancient temple ruins date back to the Champa Kingdom and showcase the architectural brilliance of the Cham civilization.</li>
                                        <li>Discover intricate carvings, sculptures, and red-brick towers that testify to Vietnam's cultural heritage.</li>
                                        <li>My Son Sanctuary is a must-visit for history lovers and anyone seeking a deeper connection to Vietnam's past.</li>
                                    </ul><br /><br />

                                    <h4>Perfume Pagoda, Hanoi</h4>
                                    <ul>
                                        <li>Located approximately 60 kilometers southwest of Hanoi, the Perfume Pagoda is a complex of Buddhist temples nestled in the Huong Tich Mountains.</li>
                                        <li>Accessible by boat along the scenic Yen Stream, the journey to the Perfume Pagoda is just as captivating as the site itself.</li>
                                        <li>Explore the various pagodas, shrines, and caves throughout the complex, including the famous Huong Tich Cave.</li>
                                        <li>This sacred site attracts Vietnamese Buddhists who seek blessings and spiritual fulfillment.</li>
                                    </ul>
                                    <br /><br />

                                    <h4>Tay Phuong Pagoda, Hanoi</h4>
                                    <ul>
                                        <li>Situated in the picturesque countryside of Ha Tay Province, Tay Phuong Pagoda is renowned for its collection of wooden Buddhist statues.</li>
                                        <li>Climb the steps decorated with intricate dragon carvings to reach the pagoda, which houses hundreds of ancient statues.</li>
                                        <li>The architecture and artwork of the pagoda provide valuable insights into Vietnam's religious and artistic heritage.</li>
                                        <li>Incorporate Tay Phuong Pagoda into your <strong>Vietnam package tour</strong> for an immersive cultural experience in the Vietnamese countryside.</li>
                                    </ul><br /><br />


                                    <h4>Cao Dai Temple, Tay Ninh</h4>
                                    <ul>
                                        <li>The Cao Dai Temple in Tay Ninh Province serves as the spiritual heart of the Cao Dai religion, which combines elements of Buddhism, Taoism, Confucianism, and Christianity.</li>
                                        <li>Witness the lively rituals and colorful ceremonies performed by followers adorned in symbolic robes representing various faiths.</li>
                                        <li>The temple's eclectic architecture highlights the diverse influences of its belief system, making it a fascinating cultural site.</li>
                                        <li>Visiting the Cao Dai Temple offers a unique view of religious harmony and spiritual devotion in Vietnam.</li>
                                    </ul>
                                    <p>Exploring these temples during your <strong>Vietnam packages from Kolkata</strong> ensures a journey rich in cultural discovery and spiritual enlightenment. From ancient pagodas to sacred sanctuaries, each temple provides a window into Vietnam's rich history and enduring traditions. As you organize your <strong>Vietnam package tour from Kolkata for family</strong>, be sure to include these iconic landmarks in your itinerary for an unforgettable experience steeped in Vietnam's spiritual heritage. </p>
                                    <br /><br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen7ImageURL}
                                                        alt="book vietnam tour packages from kolkata with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen10ImageURL}
                                                        alt="vietnam family package tour from kolkata with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen16ImageURL}
                                                        alt="vietnam holidays tour packages from kolkata"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br /><br /><br />
                            {/* <!--FOQ--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                Frequently Ask <span>Questions</span>
                                            </h2>
                                            <h4><strong>VIETNAM TOUR PACKAGES FROM KOLKATA</strong></h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="accordion accordion-flush faQStyle"
                                        id="accordionFlushExample"
                                    >
                                        <div className="accordion" id="accordionFlushExample">
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingHoChiMinh">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapseHoChiMinh"
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapseHoChiMinh"
                                                    >
                                                        Q. What are the must-visit destinations in Ho Chi Minh City?
                                                    </button>
                                                </h4>
                                                <div
                                                    id="flush-collapseHoChiMinh"
                                                    className="accordion-collapse collapse show"
                                                    aria-labelledby="flush-headingHoChiMinh"
                                                    data-bs-parent="#accordionFlushExample"
                                                >
                                                    <div className="accordion-body">
                                                        Ho Chi Minh City boasts several must-visit destinations, including the War Remnants Museum, Cu Chi Tunnels, Notre-Dame Cathedral Basilica of Saigon, Independence Palace, Ben Thanh Market, and Bitexco Financial Tower. These attractions provide a glimpse into the city's rich history, culture, and daily life. Strolling through District 1 and savoring local street food is also a fantastic way to experience the vibrant atmosphere of this bustling city.
                                                        <br /><br />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingDaNang">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapseDaNang"
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapseDaNang"
                                                    >
                                                        Q. What are the top tourist attractions in Da Nang?
                                                    </button>
                                                </h4>
                                                <div
                                                    id="flush-collapseDaNang"
                                                    className="accordion-collapse collapse show"
                                                    aria-labelledby="flush-headingDaNang"
                                                    data-bs-parent="#accordionFlushExample"
                                                >
                                                    <div className="accordion-body">
                                                        Da Nang is a stunning city in Vietnam with a wealth of popular tourist attractions. Key sites include the Marble Mountains, My Khe Beach, Dragon Bridge, Son Tra Mountain, Han River Bridge, Museum of Cham Sculpture, and Ba Na Hills. Each of these locations offers unique experiences, making Da Nang a must-visit on your Vietnam tour itinerary.
                                                        <br /><br />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingHanoiToHalong">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapseHanoiToHalong"
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapseHanoiToHalong"
                                                    >
                                                        Q. What is the best way to travel from Hanoi to Halong Bay?
                                                    </button>
                                                </h4>
                                                <div
                                                    id="flush-collapseHanoiToHalong"
                                                    className="accordion-collapse collapse show"
                                                    aria-labelledby="flush-headingHanoiToHalong"
                                                    data-bs-parent="#accordionFlushExample"
                                                >
                                                    <div className="accordion-body">
                                                        The most scenic way to travel from Hanoi to Halong Bay is by taking a cruise. Numerous cruise companies offer various packages and itineraries to explore this breathtaking natural wonder. Alternatively, you can opt for a bus, taxi, or private car. However, the cruise experience provides the most enjoyable journey to Halong Bay.
                                                        <br /><br />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingVisa">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapseVisa"
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapseVisa"
                                                    >
                                                        Q. Do I need a visa to enter Vietnam and how do I go about getting one?
                                                    </button>
                                                </h4>
                                                <div
                                                    id="flush-collapseVisa"
                                                    className="accordion-collapse collapse show"
                                                    aria-labelledby="flush-headingVisa"
                                                    data-bs-parent="#accordionFlushExample"
                                                >
                                                    <div className="accordion-body">
                                                        Yes, a visa is required to enter Vietnam. You can apply for a visa through the Vietnamese embassy or consulate in your country, or opt for a visa on arrival online through a travel agency. Visa requirements and procedures vary by nationality and purpose of visit, so it’s advisable to check with the Vietnamese embassy for specific guidelines.
                                                        <br /><br />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingBeachesNhaTrang">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapseBeachesNhaTrang"
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapseBeachesNhaTrang"
                                                    >
                                                        Q. Are there any beaches near Nha Trang city?
                                                    </button>
                                                </h4>
                                                <div
                                                    id="flush-collapseBeachesNhaTrang"
                                                    className="accordion-collapse collapse show"
                                                    aria-labelledby="flush-headingBeachesNhaTrang"
                                                    data-bs-parent="#accordionFlushExample"
                                                >
                                                    <div className="accordion-body">
                                                        Nha Trang is famous for its stunning beaches, including Nha Trang Beach, Doc Let Beach, and Bai Dai Beach. Each beach offers a unique experience worth exploring. Nha Trang Beach is centrally located and perfect for relaxation and sunbathing. Doc Let Beach is more secluded and less crowded, while Bai Dai Beach features crystal-clear waters and pristine white sand. Whichever beach you choose, you'll have a memorable time in Nha Trang!
                                                        <br /><br />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingCurrency">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapseCurrency"
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapseCurrency"
                                                    >
                                                        Q. What is the currency used in Vietnam and can it be easily exchanged?
                                                    </button>
                                                </h4>
                                                <div
                                                    id="flush-collapseCurrency"
                                                    className="accordion-collapse collapse show"
                                                    aria-labelledby="flush-headingCurrency"
                                                    data-bs-parent="#accordionFlushExample"
                                                >
                                                    <div className="accordion-body">
                                                        The currency in Vietnam is the Vietnamese dong (VND). You can easily exchange it at banks, currency exchange booths, and some hotels. To avoid scams, it’s best to exchange money at authorized locations.
                                                        <br /><br />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingPacking">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapsePacking"
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapsePacking"
                                                    >
                                                        Q. What are the essential items to pack for a Vietnam vacation?
                                                    </button>
                                                </h4>
                                                <div
                                                    id="flush-collapsePacking"
                                                    className="accordion-collapse collapse show"
                                                    aria-labelledby="flush-headingPacking"
                                                    data-bs-parent="#accordionFlushExample"
                                                >
                                                    <div className="accordion-body">
                                                        When planning your Vietnam vacation, it's essential to pack lightweight clothing suitable for warm weather, comfortable walking shoes, sunscreen, and a reusable water bottle. Additionally, consider packing a small umbrella or raincoat, especially if traveling during the rainy season. Don’t forget to bring your travel documents, including your visa, passport, and travel insurance.
                                                        <br /><br />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingVietnamPackages">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapseVietnamPackages"
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapseVietnamPackages"
                                                    >
                                                        Q. How can I find the best Vietnam packages from Kolkata?
                                                    </button>
                                                </h4>
                                                <div
                                                    id="flush-collapseVietnamPackages"
                                                    className="accordion-collapse collapse show"
                                                    aria-labelledby="flush-headingVietnamPackages"
                                                    data-bs-parent="#accordionFlushExample"
                                                >
                                                    <div className="accordion-body">
                                                        To find the best Vietnam package from Kolkata, consider researching online travel agencies that specialize in Vietnam tours. Look for customer reviews and compare packages that include flights, accommodations, and guided tours. Additionally, signing up for newsletters from travel agencies can alert you to special deals and discounts for your Vietnam tour from Kolkata.
                                                        <br /><br />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingLocalDishes">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapseLocalDishes"
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapseLocalDishes"
                                                    >
                                                        Q. What local dishes should I try during my Vietnam travel?
                                                    </button>
                                                </h4>
                                                <div
                                                    id="flush-collapseLocalDishes"
                                                    className="accordion-collapse collapse show"
                                                    aria-labelledby="flush-headingLocalDishes"
                                                    data-bs-parent="#accordionFlushExample"
                                                >
                                                    <div className="accordion-body">
                                                        Vietnamese cuisine is diverse and delicious, so be sure to try local dishes such as pho (noodle soup), banh mi (Vietnamese sandwich), spring rolls, and bun cha (grilled pork with noodles). Street food is a must-try; exploring local markets will provide an authentic taste of Vietnam's culinary delights.
                                                        <br /><br />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingStreetFood">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapseStreetFood"
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapseStreetFood"
                                                    >
                                                        Q. Is street food safe to eat in Vietnam?
                                                    </button>
                                                </h4>
                                                <div
                                                    id="flush-collapseStreetFood"
                                                    className="accordion-collapse collapse show"
                                                    aria-labelledby="flush-headingStreetFood"
                                                    data-bs-parent="#accordionFlushExample"
                                                >
                                                    <div className="accordion-body">
                                                        Yes, street food in Vietnam is generally safe to eat. Many vendors prepare food fresh, which helps reduce the risk of contamination. To ensure safety, choose vendors with high customer turnover and clean cooking areas. It's also wise to stick to hot, cooked foods and avoid raw or undercooked items. Always use your judgment and consider local recommendations when trying street food to have a safe and enjoyable experience.
                                                        <br /><br />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingBestTime">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapseBestTime"
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapseBestTime"
                                                    >
                                                        Q. What is the best time to plan my Vietnam tour?
                                                    </button>
                                                </h4>
                                                <div
                                                    id="flush-collapseBestTime"
                                                    className="accordion-collapse collapse show"
                                                    aria-labelledby="flush-headingBestTime"
                                                    data-bs-parent="#accordionFlushExample"
                                                >
                                                    <div className="accordion-body">
                                                        The best time to plan your Vietnam tour is typically between February and April or August to October when the weather is mild and dry. However, this can vary depending on the region you plan to visit, so be sure to research the specific weather patterns for each area of Vietnam you want to explore.
                                                        <br /><br />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br /><br />

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>
                                                <b>
                                                    International <span className="tomato">Tour Reviews</span>
                                                </b>
                                            </h3>
                                            <h4>
                                                <i>"Travel without Trouble"</i>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1 ">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">
                                            {reviewersDataView}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3">

                            <BookingBox />

                        </div>
                        {this.state.showModal && (
                            <div className="modal d-flex align-items-center justify-content-center" style={{ display: this.state.showModal ? "block" : "none" }}>
                                <div className="modal-dialog">
                                    <div className="modal-content cust-modContent" ref={this.modalRef}>
                                        <div className="modal-header">
                                            <h4 className="modal-title ms-auto" id="exampleModalLabel">Get a Free Callback &nbsp;&nbsp;<i className="fa fa-phone fa-rotate-90"></i>
                                            </h4>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.onCloseModal}></button>
                                        </div>
                                        <div className="modal-body">
                                            <form className="row g-3">
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name *"
                                                        name="firstName"
                                                        value={this.state.firstName}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="10 Digit Mobile No.*"
                                                        name="mobile"
                                                        value={this.state.mobile}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Email "
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Comments"
                                                        rows="2"
                                                        name="comments"
                                                        value={this.state.comments}
                                                        onChange={this.onChangeHandler}></textarea>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <div className='container'>
                                                <div className="row">
                                                    <div className='col-sm-4'>
                                                        {
                                                            (this.state.showLoader) &&
                                                            <div className='pull-left'><Digital size={25} /></div>
                                                        }
                                                    </div>
                                                    <div className='col-sm-8 '>
                                                        <div className='pull-right'>
                                                            {/* <button type="button" className="btn btn-Warning mr-1" onClick={this.onCloseModal}>Close</button> */}
                                                            <button type="button" className="btn btn-Warning btn-lg" onClick={this.onRequestCallbackSubmit}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                    </a>
                </div>
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-9163676037">
                        <i className="fa fa-phone"></i>
                    </a>
                </div>
            </>
        );
    }
}

export default Vietnam_7N_8D_Kolkata_Package;