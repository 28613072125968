import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import ApiURL from '../../api/ApiURL';
import cogoToast from "cogo-toast";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import ScrollToTopButton from "./ScollerButtom";

class MainHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btn: "Submit",
            showLoader: false,
            isHidden: true,
            showModal: false,
            showMegamenu: false, // New state to handle megamenu
            firstName: '',
            mobile: '',
            email: '',
            comments: '',
            megamenuTimeout: null // New state for managing hover delay
        }
        this.timeoutId = null; // To store the timeout ID
        this.modalRef = React.createRef(); // Create a ref for the modal
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ showModal: true });
        }, 55000); 
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        // Cleanup the event listener
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        // Check if the click is outside the modal
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.onCloseModal();
        }
    };

    toggleHidden = (e) => {
        e.preventDefault();
        this.setState({ isHidden: !this.state.isHidden });
    }

    handleMegamenuEnter = () => {
        // Clear any existing timeout
        if (this.state.timeoutId) {
            clearTimeout(this.state.timeoutId);
        }
        this.setState({ isHidden: false });
    };

    handleMegamenuLeave = () => {
        // Set a timeout to delay hiding the megamenu
        const timeoutId = setTimeout(() => {
            this.setState({ isHidden: true });
        }, 300); // Match this delay with the CSS transition time

        this.setState({ timeoutId });
    };


    onRequestCallback = (e) => {
        e.preventDefault();
        if (!this.state.showModal) {
            this.setState({ showModal: true });
        }
    }

    onCloseModal = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            showModal: false,
            firstName: '',
            mobile: '',
            email: '',
            comments: ''
        });
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    // Function to handle form submission
    onRequestCallbackSubmit = async (e) => {
        e.preventDefault();

        const { firstName, email, mobile, comments } = this.state;

        // Validation
        if (firstName.trim() === '') {
            cogoToast.error("Full Name Required.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else if (mobile.trim() === '' || !/^\d{10}$/.test(mobile.trim())) {
            cogoToast.error("Please Insert 10 digit Mobile No.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else {
            this.setState({ btn: "Processing..", showLoader: true });
            // Proceed with form submission
            try {
                const _ref = 'yes';
                const _url = window.location.href;

                const formData = {
                    FirstName: firstName,
                    Email: email,
                    Mobile: mobile,
                    Comments: comments,
                    Referrer: _ref,
                    URL: _url
                };

                const response = await axios.post(ApiURL.SaveCustomerEnquery, formData);
                console.log('Data submitted successfully:', response.data);
                // Optionally, reset the form state after successful submission
                this.setState({ firstName: '', email: '', mobile: '', comments: '' });

                // Optionally, show a success message to the user
                cogoToast.success("Form submitted successfully.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
                this.setState({ btn: "Submit", showLoader: false, showModal: false });
            } catch (error) {
                console.error('Error submitting data:', error);
                this.setState({ btn: "Submit", showLoader: false });
                // Optionally, show an error message to the user
                cogoToast.error("Please try again after 24 hrs. later.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
            }

        }

    };

    /* RedirectToOTPPage = () => {
        if (this.state.redirectOTPStatus === true) {
            let url = "/verifyotp/" + this.state.mobile;
            return <Redirect to={url} />
        }
    } */

    onCloseModal = () => {
        this.setState({ showModal: false }, () => {
            // Additional code to perform after the modal is closed
            setTimeout(() => {
                this.setState({ showModal: true }, () => {
                    // Additional code to perform after the next modal is displayed
                });
            }, 55000); // repeate 55sec in milliseconds
        });
    };

    render() {
        const logoImgURL = ApiURL.BaseImageURL + "/vacationLLP.png";
        return (
            <Fragment>
                <div className="container-fluid fixed-top">
                    <div className="row">
                        <div className="col-12 blackStrap">
                            <div className="centerAlignPh">
                                <ul>
                                    <li><i className="fa fa-phone fa-rotate-90" aria-hidden="true"></i>&nbsp;&nbsp;International - <a href="tel://+91-9163676037" style={{ color: 'white' }}>+91 91636-76037</a></li>
                                    <li><i className="fa fa-phone fa-rotate-90"></i>&nbsp;&nbsp;Bhutan - <a href='tel://+91-9883359713' style={{ color: 'white' }}>+91 98833-59713</a></li>
                                    <li><i className="fa fa-phone fa-rotate-90"></i>&nbsp;&nbsp;Sikkim & Darjeeling - <a href='tel://+91-9147061467' style={{ color: 'white' }}>+91 91470-61467</a></li>
                                    <li><i className="fa fa-phone fa-rotate-90"></i>&nbsp;&nbsp;North East -<a href='tel://+91-9674738480' style={{ color: 'white' }}>+91 96747-38480</a></li>
                                    <li><i className="fa fa-phone fa-rotate-90"></i>&nbsp;&nbsp;Kerala - <a href='tel://+91-9147061493' style={{ color: 'white' }}>+91 91470-61493</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 px-0">
                            <nav className="navbar navbar-expand-lg navbar-dark">
                                <div className="container-fluid">
                                    <Link className="navbar-brand" to={"/"}>
                                        <img src={logoImgURL} className="img-fluid-logo" alt="logo" /> &nbsp; Adorable Vacation LLP
                                    </Link>
                                    <span height="20px" className="phoneno-ontop">Adorable Vacation LLP <br /> <a href='tel://+91-9163676037' style={{ color: 'white' }}>+91 91636-76037</a> | <a href='tel://+91-9883359713' style={{ color: 'white' }}>+91 98833-59713</a></span>

                                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>

                                    <div className="collapse navbar-collapse" id="main_nav">
                                        <ul className="navbar-nav">
                                        </ul>
                                        <ul className="navbar-nav ms-auto">
                                            <li className="nav-item">
                                                <Link className="nav-link" to={"/"}> Home </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/about"> About </Link>
                                            </li>
                                            <li className="nav-item dropdown has-megamenu" onMouseEnter={this.handleMegamenuEnter}
                                                onMouseLeave={this.handleMegamenuLeave}>
                                                <Link className="nav-link dropdown-toggle" to={"#"} onClick={this.toggleHidden}> Packages  </Link>
                                                <div className={this.state.isHidden === true ? "dropdown-menu megamenu" : "dropdown-menu megamenu show"} role="menu">
                                                    <div className="container-fluid">
                                                        <div className="row full-width">
                                                            <div className="col-md-3 borderRgt">
                                                                <h5>BHUTAN PACKAGES</h5>
                                                                <Link className="dropdown-item" to={"/bhutan-tour-package-from-bagdogra"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bagdogra to Bhutan 6N 7D</Link>
                                                                <Link className="dropdown-item" to={"/bhutan-b2b-package-tour-operator-in-india-best-bhutan-dmc"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bhutan B2B Tour Package</Link>
                                                                <Link className="dropdown-item" to={"/bhutan-tour-package"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bhutan Package Tour</Link>
                                                                <Link className="dropdown-item" to={"/bhutan-tour-package-from-mumbai"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bhutan Package Tour From Mumbai</Link>
                                                                <Link className="dropdown-item" to={"/bhutan-tour-from-delhi"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bhutan Package Tour From Delhi</Link>
                                                                <Link className="dropdown-item" to={"/bhutan-tour-package-from-chennai"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bhutan Package Tour From Chennai </Link>
                                                                <Link className="dropdown-item" to={"/bhutan-tour-package-from-kolkata"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bhutan Package Tour From Kolkata </Link>
                                                                <Link className="dropdown-item" to={"/bhutan-tour-package-from-ahemdabad"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bhutan Package Tour From Ahmedabad </Link>
                                                                <Link className="dropdown-item" to={"/bhutan-tour-package-from-hyderabad"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bhutan Package Tour From Hyderabad </Link>
                                                                <Link className="dropdown-item" to={"/bhutan-tour-package-from-pune"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bhutan Package Tour From Pune </Link>
                                                                <Link className="dropdown-item" to={"/bhutan-tour-package-from-bangalore"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bhutan Package Tour From Bangalore </Link>
                                                                <Link className="dropdown-item2" to={"/bhutan-tour-package-from-surat"}><i className="fa fa-caret-right" aria-hidden="true"></i>Bhutan Package Tour From Surat </Link>
                                                                <Link className="dropdown-item" to={"/bhutan-package-tour-from-guwahati-airport"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bhutan Tour From Guwahati Airport </Link>
                                                                <Link className="dropdown-item" to={"/bhutan-luxury-package-tour-with-six-senses-hotel"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bhutan Tour With SixSense Hotel </Link>
                                                                <Link className="dropdown-item2" to={"/bhutan-hotel-six-senses-package-tour-with-direct-flight-from-india"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bhutan Tour With SixSense Direct Flight India </Link>
                                                                <Link className="dropdown-item2" to={"/bhutan-package-tour-from-hasimara-new-alipurduar"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bhutan Tour From Hasimara & New Alipurduar </Link>
                                                                <Link className="dropdown-item2" to={"/bhutan-package-tour-from-phuentsholing"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bhutan Tour From Phuentsholing </Link>
                                                                <Link className="dropdown-item2" to={"/bhutan-package-tour-from-njp-station-siliguri-bus-stand"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bhutan Tour From NJP Siliguri </Link>
                                                                <Link className="dropdown-item2" to={"/bhutan-tour-package-from-jaigaon"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bhutan Package Tour From Jaigaon </Link>
                                                                <Link className="dropdown-item" to={"/bhutan-package-tour-from-bangalore-with-flight-from-kolkata-airport-guwahati-airport"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bhutan Tour Ex Bangalore via CCU </Link>
                                                                <Link className="dropdown-item2" to={"/bhutan-DMC-b2b-packages-for-Bhutan-tour-from-bagdogra-airport-via-phuentsholing"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bhutan B2B Package Tour From Bagdogra Airport </Link>
                                                            </div>
                                                            <div className="col-md-3 borderRgt">
                                                                <h5>EGYPT PACKAGES</h5>
                                                                <Link className="dropdown-item" to={"/egypt-package-tour-from-kolkata-8night-9days"}><i className="fa fa-caret-right" aria-hidden="true"></i> Egypt Tour Package 8N 9D</Link>
                                                                <h5>BHUTAN TOUR EX PARO</h5>
                                                                {/* <Link className="dropdown-item" to={"/bhutan-tour-package-from-jaigaon"}><i className="fa fa-caret-right" aria-hidden="true"></i> jaigaon to bhutan 7N 8D</Link> */}
                                                                <Link className="dropdown-item" to={"/mumbai-special-chartered-flight"}><i className="fa fa-caret-right" aria-hidden="true"></i> Mumbai Special Charterd Flight 7N 8D</Link>
                                                                <Link className="dropdown-item" to={"/4N-5D-FIFO-package"}><i className="fa fa-caret-right" aria-hidden="true"></i> 4N 5D FIFO</Link>
                                                                <Link className="dropdown-item" to={"/5N-6D-FIFO-package"}><i className="fa fa-caret-right" aria-hidden="true"></i> 5N 6D FIFO</Link>
                                                                <Link className="dropdown-item" to={"/6N-7D-FIFO-package"}><i className="fa fa-caret-right" aria-hidden="true"></i> 6N 7D FIFO</Link>
                                                                <Link className="dropdown-item" to={"/8N-9D-FIFO-package"}><i className="fa fa-caret-right" aria-hidden="true"></i> 8N 9D FIFO</Link>
                                                                <Link className="dropdown-item" to={"/10N-11D-FIFO-package"}><i className="fa fa-caret-right" aria-hidden="true"></i> 10N 11D FIFO</Link>
                                                                {/* <Link className="dropdown-item" to={"/mumbai-special-chartered-flight"}><i className="fa fa-caret-right" aria-hidden="true"></i> Mumbai Special Charterd Flight</Link> */}
                                                                <h5>ANDAMAN PACKAGES</h5>
                                                                <Link className="dropdown-item" to={"/andaman-package-tour-from-kolkata"}><i className="fa fa-caret-right" aria-hidden="true"></i> Andaman Tour From Kolkata 7N 8D</Link>
                                                                <Link className="dropdown-item" to={"/andaman-honeymoon-package-tour-from-kolkata"}><i className="fa fa-caret-right" aria-hidden="true"></i> Andaman Honeymoon Package 5N 6D</Link>

                                                                <h5>LADAKH PACKAGES</h5>
                                                                <Link className="dropdown-item2" to={"/Ladakh-Tour-Package"}><i className="fa fa-caret-right" aria-hidden="true"></i> Ladakh Tour 6N 7D</Link>
                                                                <Link className="dropdown-item2" to={"/Leh-Ladakh-Kargil-Package-Tour"}><i className="fa fa-caret-right" aria-hidden="true"></i> Leh Ladakh Kargil 8N 9D</Link>
                                                                <Link className="dropdown-item" to={"/Leh-Ladakh-Turtuk-Package-Tour-from-Mumbai-with-Flight"}><i className="fa fa-caret-right" aria-hidden="true"></i> Leh Ladakh Turtuk Tour Package</Link>
                                                               
                                                            </div>
                                                            <div className="col-md-3 borderRgt">
                                                                {/* <h5>LADAKH PACKAGES</h5>
                                                                <Link className="dropdown-item" to={"/Ladakh-Tour-Package"}><i className="fa fa-caret-right" aria-hidden="true"></i> Ladakh Tour 6N 7D</Link> */}
                                                                <h5>NORTH EAST PACKAGES</h5>
                                                                <Link className="dropdown-item" to={"/arunachal-pradesh-package-tour-from-guwahati"}><i className="fa fa-caret-right" aria-hidden="true"></i> Arunachal Tour Package Guwahati</Link>
                                                                <Link className="dropdown-item" to={"/arunachal-pradesh-package-tour-from-mumbai"}><i className="fa fa-caret-right" aria-hidden="true"></i> Arunachal Tour Package Mumbai</Link>
                                                                <Link className="dropdown-item" to={"/arunachal-pradesh-package-tour-from-kolkata"}><i className="fa fa-caret-right" aria-hidden="true"></i> Arunachal Package Tour from Kolkata</Link>
                                                                <Link className="dropdown-item" to={"/shillong-meghalaya-package-tour-from-guwahati"}><i className="fa fa-caret-right" aria-hidden="true"></i> Shillong Meghalaya Tour Package</Link>
                                                                <Link className="dropdown-item" to={"/kaziranga-shillong-cherrapunjee-package-tour-from-guwahati"}><i className="fa fa-caret-right" aria-hidden="true"></i> Kaziranga Shillong Cherapunjee Tour</Link>
                                                                <Link className="dropdown-item" to={"/north-east-india-package-tour-from-guwahati-airport"}><i className="fa fa-caret-right" aria-hidden="true"></i> North East india Tour From Guwahati</Link>
                                                                <Link className="dropdown-item2" to={"/meghalaya-shillong-cherrapunji-package-tour-from-guwahati-with-garo-hills-tura-wari-chora"}><i className="fa fa-caret-right" aria-hidden="true"></i> Meghalaya Shillong Wari Chora Tour </Link>
                                                                <Link className="dropdown-item2" to={"/meghalaya-package-tour-with-wari-chora-tura-south-garo-hills-mawsynram-jowai-mawlynnong-dawki"}><i className="fa fa-caret-right" aria-hidden="true"></i> Meghalaya Mawlynnong dawki Tour </Link>
                                                                <Link className="dropdown-item2" to={"/assam-meghalaya-arunachal-pradesh-package-tour-from-guwahati"}><i className="fa fa-caret-right" aria-hidden="true"></i> Assam Meghalaya Arunachal Tour </Link>
                                                                <Link className="dropdown-item2" to={"/meghalaya-arunachal-pradesh-package-tour-from-guwahati"}><i className="fa fa-caret-right" aria-hidden="true"></i>Meghalaya Arunachal Package Tour From Guwahati </Link>
                                                                <Link className="dropdown-item2" to={"/kaziranga-shillong-cherrapunjee-group-tour-packages-from-guwahati"}><i className="fa fa-caret-right" aria-hidden="true"></i> Kaziranga Shillong Cherrapunjee Group Tour From Guwahati </Link>
                                                                <Link className="dropdown-item2" to={"/kaziranga-package-tour-from-guwahati-with-elephant-safari-jeep-safari"}><i className="fa fa-caret-right" aria-hidden="true"></i> Kaziranga Package Tour With Elephant Safari </Link>
                                                                {/* <Link className="dropdown-item" to={"/meghalaya-tour-package"}><i className="fa fa-caret-right" aria-hidden="true"></i> Meghalaya Tour Package</Link> */}
                                                                {/* <Link className="dropdown-item" to={"/Arunachal-Pradesh-Tawang-Tour-Package-10N-11D"}><i className="fa fa-caret-right" aria-hidden="true"></i> Arunachal Tour 10N 11D</Link> */}
                                                                <Link className="dropdown-item" to={"/Assam-Meghalaya-5N-6D-Tour-Itinerary"}><i className="fa fa-caret-right" aria-hidden="true"></i> Meghalaya Tour 5N 6D</Link>
                                                                <Link className="dropdown-item" to={"/Meghalaya-Assam-Tour-Packages-6N-7D"}><i className="fa fa-caret-right" aria-hidden="true"></i> Meghalaya Tour 6N 7D</Link>
                                                                <Link className="dropdown-item" to={"/Meghalaya-Kaziranga-Tour-Packages-7N-8D"}><i className="fa fa-caret-right" aria-hidden="true"></i> Meghalaya Tour 7N 8D</Link>
                                                                <Link className="dropdown-item" to={"/Arunachal-Pradesh-11N-12D-Tour-Package"}><i className="fa fa-caret-right" aria-hidden="true"></i> Arunachal Tour 11N 12D</Link>
                                                                <Link className="dropdown-item" to={"/"}><i className="fa fa-caret-right" aria-hidden="true"></i> Manipur & Nagaland Tour</Link>
                                                                <h5>SUNDARBAN PACKAGES</h5>
                                                                <Link className="dropdown-item" to={"/sundarban-package-tour-from-kolkata-with-hotel-sonar-bangla"}><i className="fa fa-caret-right" aria-hidden="true"></i> Sundarban Sonar Bangla Tour Package</Link>
                                                                <Link className="dropdown-item" to={"/sundarban-package-tour-from-kolkata"}><i className="fa fa-caret-right" aria-hidden="true"></i> Sundarban Kolkata Tour Package</Link>
                                                                {/* sandakphu */}
                                                                <Link className="dropdown-item2" to={"/sandakphu-land-rover-package-tour-booking"}><i className="fa fa-caret-right" aria-hidden="true"></i> Sandakphu Land Rover</Link>
                                                                <Link className="dropdown-item2" to={"/sandakphu-trekking-package-booking"}><i className="fa fa-caret-right" aria-hidden="true"></i> Sandakphu Treakeing package</Link>
                                                                
                                                            </div>
                                                            <div className="col-md-3">
                                                                <h5>SIKKIM PACKAGES</h5>
                                                                {/* <Link className="dropdown-item" to={"/"}><i className="fa fa-caret-right" aria-hidden="true"></i> Sikkim Tour Package</Link> */}
                                                                <Link className="dropdown-item" to={"/sikkim-tour-package"}><i className="fa fa-caret-right" aria-hidden="true"></i> Sikkim Tour 5N 6D</Link>
                                                                {/* <Link className="dropdown-item" to={"/"}><i className="fa fa-caret-right" aria-hidden="true"></i> Sikkim Darjeeling Tour 4N 5D</Link> */}
                                                                <Link className="dropdown-item" to={"/Sikkim-Gangtok-Darjeeling-Tour-Package"}><i className="fa fa-caret-right" aria-hidden="true"></i> Sikkim Darjeeling Tour 4N 5D</Link>
                                                                {/* <Link className="dropdown-item" to={"/"}><i className="fa fa-caret-right" aria-hidden="true"></i> West Sikkim Tour 5N 6D</Link>
                                                                <Link className="dropdown-item" to={"/"}><i className="fa fa-caret-right" aria-hidden="true"></i> Gangtok Pelling Tour 4N 5D</Link>
                                                                <Link className="dropdown-item" to={"/"}><i className="fa fa-caret-right" aria-hidden="true"></i> Darjeeling Kalimpong 5N 6D</Link>
                                                                <Link className="dropdown-item" to={"/"}><i className="fa fa-caret-right" aria-hidden="true"></i> Sikkim North Bengal Tour 7N 8D</Link> */}
                                                                <Link className="dropdown-item" to={"/silk-route-package-tour-with-gangtok-north-sikkim-from-bagdogra-airport-njp-station"}><i className="fa fa-caret-right" aria-hidden="true"></i> North Sikkim with Gangtok 8N 9D</Link>
                                                                <Link className="dropdown-item" to={"/sikkim-tour-packages-from-bagdogra-njp-siliguri"}><i className="fa fa-caret-right" aria-hidden="true"></i> Sikkim Tour Package Bagdogra Airport</Link>
                                                                <Link className="dropdown-item" to={"/silk-route-package-tour-from-kolkata"}><i className="fa fa-caret-right" aria-hidden="true"></i> Silk Route Package Tour Kolkata</Link>
                                                                <h5>KASHMIR PACKAGES</h5>
                                                                <Link className="dropdown-item" to={"/Kashmir-Tour-Package-from-Srinagar-Airport"}><i className="fa fa-caret-right" aria-hidden="true"></i> Kashmir Tour 6N 7D</Link>
                                                                <Link className="dropdown-item" to={"/Kashmir-Leh-Ladakh-Tour-Package"}><i className="fa fa-caret-right" aria-hidden="true"></i> Kashmir Leh Ladakh Tour</Link>
                                                                <Link className="dropdown-item2" to={"/kashmir-offbeat-package-tour-with-baisaran-valley-doodhpathri-yusmarg"}><i className="fa fa-caret-right" aria-hidden="true"></i> Kashmir doodhpathri Tour</Link>
                                                                <Link className="dropdown-item2" to={"/kashmir-offbeat-package-tour-with-gurez-valley"}><i className="fa fa-caret-right" aria-hidden="true"></i> Kashmir GurezVally Tour</Link>
                                                                <Link className="dropdown-item2" to={"/kashmir-offbeat-package-tour-with-sinthan-top-aru-valley-betab-valley-chandanbari-pahalgam-sonmarg"}><i className="fa fa-caret-right" aria-hidden="true"></i> Kashmir Sinthantop Tour</Link>
                                                                <Link className="dropdown-item2" to={"/kashmir-package-tour-with-vaishno-devi-katra-pahalgam-gulmarg-srinagar"}><i className="fa fa-caret-right" aria-hidden="true"></i> Kashmir Vaishno Devi Tour</Link>
                                                                <Link className="dropdown-item2" to={"/kashmir-tulip-garden-festival-package-tour"}><i className="fa fa-caret-right" aria-hidden="true"></i> Kashmir Tulip Garden Tour</Link>
                                                                <Link className="dropdown-item" to={"/kashmir-package-tour-from-kolkata-with-srinagar-gulmarg-pahalgam-sonmarg"}><i className="fa fa-caret-right" aria-hidden="true"></i> Kashmir Package Tour From Kolkata</Link>
                                                                <Link className="dropdown-item2" to={"/kashmir-package-tour-from-surat"}><i className="fa fa-caret-right" aria-hidden="true"></i> Kashmir Package Tour From Surat</Link>
                                                                <Link className="dropdown-item" to={"/kashmir-package-tour-from-chennai"}><i className="fa fa-caret-right" aria-hidden="true"></i> Kashmir Package Tour From Chennai</Link>
                                                                <Link className="dropdown-item2" to={"/kashmir-package-tour-from-bangalore"}><i className="fa fa-caret-right" aria-hidden="true"></i> Kashmir Package Tour From Bangalore</Link>
                                                                <Link className="dropdown-item2" to={"/kashmir-package-tour-from-pune"}><i className="fa fa-caret-right" aria-hidden="true"></i> Kashmir Package Tour From Pune</Link>
                                                                <Link className="dropdown-item" to={"/kashmir-package-tour-from-mumbai"}><i className="fa fa-caret-right" aria-hidden="true"></i> Kashmir Package Tour From Mumbai</Link>
                                                                <Link className="dropdown-item2" to={"/kashmir-package-tour-from-delhi"}><i className="fa fa-caret-right" aria-hidden="true"></i> Kashmir Package Tour From Delhi</Link>
                                                                <Link className="dropdown-item2" to={"/kashmir-package-tour-from-ahmedabad"}><i className="fa fa-caret-right" aria-hidden="true"></i> Kashmir Package Tour From Ahmedabad</Link>
                                                                <Link className="dropdown-item2" to={"/jammu-kashmir-family-tour-packages"}><i className="fa fa-caret-right" aria-hidden="true"></i> Kashmir Family Tour Package</Link>
                                                                <Link className="dropdown-item2" to={"/kashmir-package-tour-with-amritsar-katra-vaishno-devi-pahalgam-gulmarg-srinagar-sonmarg"}><i className="fa fa-caret-right" aria-hidden="true"></i> Kashmir Vaishno Devi Amritsar Package Tour</Link>
                                                                <h5>INTERNATIONAL PACKAGES</h5>
                                                                {/* <Link className="dropdown-item2" to={"/Thailand-Tour-Package-from-kolkata-Airport"}><i className="fa fa-caret-right" aria-hidden="true"></i> Thailand Tour 8N 9D</Link> */}
                                                                <Link className="dropdown-item" to={"/bangkok-pattaya-thailand-holiday-package-tour-from-india"}><i className="fa fa-caret-right" aria-hidden="true"></i> Bangkok Pattaya Tour Package</Link>
                                                                {/* <Link className="dropdown-item" to={"/Vietnam-Tour-Package"}><i className="fa fa-caret-right" aria-hidden="true"></i> Vietnam Tour 7N 8D</Link> */}
                                                                 <Link className="dropdown-item" to={"/vietnam-package-tour-from-kolkata"}><i className="fa fa-caret-right" aria-hidden="true"></i> Vietnam Tour Package From Kolkata</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>  
                                            <li className="nav-item">
                                                <Link className="nav-link" to={"/visa-consutancy-services"}> VISA Services </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to={"/blog"}> Blog </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to={"/gallery"}> Gallery </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/contact"> Contact </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to={"#"} className="nav-link" onClick={this.onRequestCallback}>
                                                    <span className="btn-custom">Request a Call Back</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                            {this.state.showModal && (
                                <div className="modal d-flex align-items-center justify-content-center" style={{ display: this.state.showModal ? "block" : "none" }}>
                                    <div className="modal-dialog">
                                        <div className="modal-content cust-modContent" ref={this.modalRef}>
                                            <div className="modal-header">
                                                <h4 className="modal-title ms-auto" id="exampleModalLabel">Get a Free Callback &nbsp;&nbsp;<i className="fa fa-phone fa-rotate-90"></i>
                                                </h4>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.onCloseModal}></button>
                                            </div>
                                            <div className="modal-body">
                                                <form className="row g-3">
                                                    <div className="col-md-12">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Name *"
                                                            name="firstName"
                                                            value={this.state.firstName}
                                                            onChange={this.onChangeHandler} />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="10 Digit Mobile No.*"
                                                            name="mobile"
                                                            value={this.state.mobile}
                                                            onChange={this.onChangeHandler} />
                                                    </div>
                                                    <div className="col-12">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Email"
                                                            name="email"
                                                            value={this.state.email}
                                                            onChange={this.onChangeHandler} />
                                                    </div>
                                                    <div className="col-12">
                                                        <textarea
                                                            className="form-control"
                                                            placeholder="Comments"
                                                            rows="2"
                                                            name="comments"
                                                            value={this.state.comments}
                                                            onChange={this.onChangeHandler}></textarea>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="modal-footer">
                                                <div className='container'>
                                                    <div className="row">
                                                        <div className='col-sm-4'>
                                                            {
                                                                (this.state.showLoader) &&
                                                                <div className='pull-left'><Digital size={25} /></div>
                                                            }
                                                        </div>
                                                        <div className='col-sm-8 '>
                                                            <div className='pull-right'>
                                                                {/* <button type="button" className="btn btn-Warning mr-1" onClick={this.onCloseModal}>Close</button> */}
                                                                <button type="button" className="btn btn-Warning btn-lg" onClick={this.onRequestCallbackSubmit}>Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </Fragment >
            
        );
    }
}
export default MainHeader;
