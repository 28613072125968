import React, { Component } from 'react';

class InclusionExclusion_group extends Component {
    render() {
        return (
            <>
                <h4 className='tomato'><u>INCLUSIONS : <strong>CUSTOMIZED ANDAMAN TRIP FROM KOLKATA</strong></u></h4>
                <ul>
                <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Transportation: Airport Transfers and Sightseeing as per the above-itinerary by 01 AC Vehicle at Port Blair & 01 AC vehicle at Havelock and Neil Island, but vehicle not at disposal basis.
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Breakfast + Dinner at hotel
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Accommodation as mentioned</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;All kinds of transport Taxes</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Assistance at all arrival and departure points</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;To and from tickets to Havelock & Neil Island by PVT. CRUISE (Base category Seats)</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Entry permits/Entry tickets/Boat tickets/Ferry tickets wherever required</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Parking Charges</li>
                </ul><br/><br/>
                <h4 className='tomato'><u>EXCLUSIONS : <strong>KOLKATA TO ANDAMAN PACKAGE TOUR</strong></u></h4>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Personal expenses like tips, laundry, phone bills, and any other meals mentioned above</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Any additional sightseeing from the Itinerary/pick up-drop from any other points/Uses of Vehicle – will cost extra</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Anything not mentioned in the Inclusions</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;5% GST</li>
                </ul>
            </>
        );
    }
}

export default InclusionExclusion_group;