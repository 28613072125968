import React, { Component } from 'react';

class InclusionExclusion_group extends Component {
    render() {
        return (
            <>
                <h4 className='tomato'><u>INCLUSIONS : <strong>EGYPT TOUR PACKAGES FROM INDIA-KOLKATA</strong></u></h4>
                <ul>
                <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;02 night accommodation in Cairo
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;01 over night sleeper class train (Cairo to Aswan)
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;03 night accommodation on board of Nile cruise</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;02 night accommodation in Hurghada</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;All Meals as per the program itinerary</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Sightseeing tours as mentioned in the program including transportation by our deluxe coach, entrance fees and qualified English-speaking guide.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;All Tours & Transfers in Cairo, Luxor, Aswan &Hurghada on private basis</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Hot Air Ballon at Luxor</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Guaranteed and fastest tourist e-visa for Egypt at INR 4085 all inclusive per person.</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;A clear scanned passport copy is required (front & back). The processing time is 3-5 working days.</li>
                </ul><br/><br/>
                <h4 className='tomato'><u>EXCLUSIONS : <strong>EGYPT HOLIDAY PACKAGES</strong></u></h4>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Optional Tours – to be settled in US Dollars</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Any personal expenses.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Beverage, drinks and mineral water during meals.</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Remittance charges </li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Tips: $45 per person – to be settled upon arrival by Tour Manger</li>
                </ul>
            </>
        );
    }
}

export default InclusionExclusion_group;