import React, { Component, Fragment } from "react";
import FooterDesktop from "../../../components/common/FooterDesktop";
import MainHeader from "../../../components/common/MainHeader";
import GalleryExplorer from "../../../components/Gallery/GalleryExplorer";

import { Helmet } from "react-helmet";

class GalleryPage extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Gallery</title>
        </Helmet>

        <MainHeader />

        <GalleryExplorer />

        <FooterDesktop />

      </Fragment>
    );
  }
}

export default GalleryPage;
