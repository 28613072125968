import React, { Component, useState } from 'react';
import BookingBox from '../BookingBox';
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Bhutan/InclusionExclusion';

class FIFO_7N_8D_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            btn: "Submit",
            showLoader: false,
            isHidden: true,
            showModal: false,
            showMegamenu: false, // New state to handle megamenu
            firstName: '',
            mobile: '',
            email: '',
            comments: '',
            megamenuTimeout: null // New state for managing hover delay
        }
        this.timeoutId = null; // To store the timeout ID
        this.modalRef = React.createRef(); // Create a ref for the modal
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919883359713&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ showModal: true });
        }, 40000);
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        // Cleanup the event listener
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        // Check if the click is outside the modal
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.onCloseModal();
        }
    };

    toggleHidden = (e) => {
        e.preventDefault();
        this.setState({ isHidden: !this.state.isHidden });
    }

    handleMegamenuEnter = () => {
        // Clear any existing timeout
        if (this.state.timeoutId) {
            clearTimeout(this.state.timeoutId);
        }
        this.setState({ isHidden: false });
    };

    handleMegamenuLeave = () => {
        // Set a timeout to delay hiding the megamenu
        const timeoutId = setTimeout(() => {
            this.setState({ isHidden: true });
        }, 300); // Match this delay with the CSS transition time

        this.setState({ timeoutId });
    };


    onRequestCallback = (e) => {
        e.preventDefault();
        if (!this.state.showModal) {
            this.setState({ showModal: true });
        }
    }

    onCloseModal = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            showModal: false,
            firstName: '',
            mobile: '',
            email: '',
            comments: ''
        });
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    // Function to handle form submission
    onRequestCallbackSubmit = async (e) => {
        e.preventDefault();

        const { firstName, email, mobile, comments } = this.state;

        // Validation
        if (firstName.trim() === '') {
            cogoToast.error("Full Name Required.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
            // } else if (email.trim() === '') {
            //     cogoToast.error("Email Required.", {
            //         position: 'top-center', // Use top-center, then adjust via CSS
            //         hideAfter: 2, // optional: auto-hide after 2 seconds
            //         className: 'custom-toast' // Apply custom class
            //     });
        } else if (mobile.trim() === '' || !/^\d{10}$/.test(mobile.trim())) {
            cogoToast.error("Please Insert 10 digit Mobile No.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else {
            this.setState({ btn: "Processing..", showLoader: true });
            // Proceed with form submission
            try {
                const _ref = 'yes';
                const _url = window.location.href;

                const formData = {
                    FirstName: firstName,
                    Email: email,
                    Mobile: mobile,
                    Comments: comments,
                    Referrer: _ref,
                    URL: _url
                };

                const response = await axios.post(ApiURL.SaveCustomerEnquery, formData);
                console.log('Data submitted successfully:', response.data);
                // Optionally, reset the form state after successful submission
                this.setState({ firstName: '', email: '', mobile: '', comments: '' });

                // Optionally, show a success message to the user
                cogoToast.success("Form submitted successfully.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
                this.setState({ btn: "Submit", showLoader: false, showModal: false });
            } catch (error) {
                console.error('Error submitting data:', error);
                this.setState({ btn: "Submit", showLoader: false });
                // Optionally, show an error message to the user
                cogoToast.error("Please try again after 24 hrs. later.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
            }

        }

    };

    /* RedirectToOTPPage = () => {
        if (this.state.redirectOTPStatus === true) {
            let url = "/verifyotp/" + this.state.mobile;
            return <Redirect to={url} />
        }
    } */

    onCloseModal = () => {
        this.setState({ showModal: false }, () => {
            // Additional code to perform after the modal is closed
            setTimeout(() => {
                this.setState({ showModal: true }, () => {
                    // Additional code to perform after the next modal is displayed
                });
            }, 55000); // 55sec in milliseconds
        });
    };

    render() {
        const sixSenseBhutanTourPackagesImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-with-hotel-six-senses.webp";
        const amanKoraBhutanPackagesImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-with-hotel-aman-kora.webp";
        const sightseen1ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/Bhutan-Airlines-1.webp";
        const sightseen2ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/Bhutan-Airlines-2.webp";
        const sightseen3ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/Bhutan-Airlines-3.webp";
        const sightseen4ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-tour-booking-from-mumbai-with-direct-flights.webp";
        const sightseen5ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-tour-from-mumbai-with-direct-flight-tickets.webp";
        const sightseen6ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-trip-from-mumbai-with-direct-flights.webp";
        const spring1ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-tour-booking-from-mumbai-during-spring-with-adorable-vacation.webp";
        const spring2ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-tour-from-mumbai-during-spring-with-adorable-vacation.webp";
        const spring3ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-tour-package-from-mumbai-during-spring-with-adorable-vacation.webp";
        const winter1ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-package-tour-from-mumbai-with-direct-flight-in-winter.webp";
        const winter2ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-tour-package-from-mumbai-with-direct-flight-in-winter.webp";
        const winter3ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-trip-from-mumbai-with-direct-flight-in-winter.webp";
        const summer1ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-tour-booking-from-mumbai-during-summer-with-adorable-vacation.webp";
        const summer2ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-tour-from-mumbai-during-summer-with-adorable-vacation.webp";
        const summer3ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-tour-package-from-mumbai-with-direct-flight-in-summer.webp";
        const flight1ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/mumbai-to-bhutan-tour-packages-with-direct-flight-with-adorable-vacation-1.webp";
        const flight2ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/mumbai-to-bhutan-tour-packages-with-direct-flight-with-adorable-vacation-2.webp";
        const hotel1ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-hotel-ugyen-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const hotel2ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-hotel-vara-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const hotel3ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-hotel-mandala-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const rows = [
            { date: '24th Sept - 30th Sept', availability: 'Available', price: '84,500' },
            { date: '01st Oct - 07th Oct', availability: 'Available', price: '84,500' },
            { date: '04th Oct - 10th Oct', availability: 'Available', price: '84,500' },
            { date: '08th Oct - 14th Oct', availability: 'Available', price: '84,500' },
            { date: '11th Oct - 17th Oct', availability: 'Available', price: '84,500' },
            { date: '15th Oct - 21st Oct', availability: 'Available', price: '84,500' },
            { date: '18th Oct - 24th Oct', availability: 'Available', price: '84,500' },
            { date: '22nd Oct - 28th Oct', availability: 'Available', price: '84,500' },
            { date: '25th Oct - 31st Oct', availability: 'Available', price: '84,500' },
            { date: '29th Oct - 04th Nov', availability: 'Available', price: '84,500' },
            { date: '1st Nov - 07th Nov', availability: 'Available', price: '84,500' },
            { date: '05th Nov - 11th Nov', availability: 'Available', price: '84,500' },
        ];

        const rowsPerPage = 6;
        const totalPages = Math.ceil(rows.length / rowsPerPage);
        const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
        const { customActiveTab, daywiseActiveTab } = this.state;

        const packageTopBannerImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-package-tour-from-mumbai-airport-with-direct-flights.webp";
        const packageTopBanner2ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-tour-packages-from-mumbai-airport-with-direct-chartered-flights.webp";
        const FlightBannerImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/Bhutan-Group-Tour-Package-from-Mumbai.webp";
        const navDay1ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/mumbai-to-bhutan-tour-packages-with-direct-flights-with-adorable-vacation.webp";
        const navDay2ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/mumbai-to-bhutan-tour-packages-booking-with-direct-flights-with-adorable-vacation.webp";
        const navDay3ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/mumbai-to-bhutan-tour-package-cost-with-direct-flights-with-adorable-vacation.webp";
        const navDay4ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/mumbai-to-bhutan-tour-package-itinerary-with-direct-flights-with-adorable-vacation.webp";
        const navDay5ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/mumbai-to-bhutan-tour-package-plan-with-direct-flights-with-adorable-vacation.webp";
        const navDay6ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/mumbai-to-bhutan-tour-package-price-with-direct-flights-with-adorable-vacation.webp";
        const navDay7ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-3-nights-4-days-package.webp";
        const navDay8ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-tour-3-days.webp";
        const ButtonImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-tour-packages-from-mumbai-with-chartered-flight-tickets-from-adorable-vacation.webp";
        const Button1ImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/bhutan-package-tour-from-mumbai-with-direct-flight-tickets-from-adorable-vacation.webp";

        const hotPackagesDataList = this.props.HotPackagesData;
        const hotPackagesView = hotPackagesDataList.length > 0 ?
            (
                hotPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.FIFO_6N_7D_ImageURL + "/" + dataItem.ImageName;
                    const contactURL = dataItem.Url;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} | {dataItem.EXPARO}<span> &#8377; {dataItem.PricePerNight}</span> </h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue">{dataItem.SDFInclude}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href={contactURL} className="btn btn-Warning btn-sm float-end">Call Now</a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const themePackageSliderList = this.props.ThemePackagesData;
        const themePackagesDataView = themePackageSliderList.map(
            (sliderItem, i) => {
                const sliderImageURL =
                    ApiURL.FIFO_7N_8D_ImageURL +
                    "/" +
                    sliderItem.ImageName;
                return (
                    <div className="theme-cards-wrapper mx-2" key={i}>
                        <div className="theme-card">
                            <div className="image-wrapper">
                                <img src={sliderImageURL} alt={sliderItem.AllternateText} />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">{sliderItem.Title}</h5>
                                <p className="card-text">{sliderItem.Description}</p>
                                <a href="#" className="btn btn-success btn-sm" onClick={this.handleClick1}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                );
            }
        );

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.FIFO_7N_8D_ImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })


        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="bhutan-package-tour-from-mumbai-airport-with-direct-flights.webp" />

                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container">
                                        <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center><strong>Bhutan Package Tour from Mumbai with Chartered Flight</strong></center></h1>
                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Bhutan Package Tour from Mumbai with Chartered Flight</strong></center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="bhutan-tour-packages-from-mumbai-airport-with-direct-chartered-flights.webp" />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Cities:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Thimphu
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Paro
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Punakha
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Bhutan Tour from Mumbai with Direct Flight</strong>, you have come to the right place!
                                        </p>
                                        <hr />
                                        <br />
                                        <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}> FOR BOOKING <strong>BHUTAN TOUR PACKAGES FROM MUMBAI WITH DIRECT FLIGHT</strong> <a href='tel:9883359713' class="reviews"><strong>☎ CALL Mr. SOUMALYA +91-98833-59713</strong></a></h2>
                                        <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}>FOR BOOKING <strong>BHUTAN TOUR PACKAGES FROM MUMBAI WITH DIRECT FLIGHT</strong> <a href='tel:9883359713' class="reviews"><strong>☎ CALL Mr. SOUMALYA +91-98833-59713</strong></a></h2>
                                    </div>
                                    <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >

                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> India's Largest <b>Bhutan DMC, [TCB Approved License No.1053228]</b> Own office at Babesa, Thimphu</h3>
                                            <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> India's Largest <b>Bhutan DMC, [TCB Approved License No.1053228]</b> Own office at Babesa, Thimphu</h3>
                                        </div>
                                        <div className="card-body">
                                            <p>Book <strong>Bhutan Package Tour from Mumbai with Chartered Flight</strong> - Best Offer from Adorable Vacation – Best Bhutan DMC</p>
                                            <p>Escape the hustle and bustle of Mumbai with our exclusive <strong>Bhutan Package Tour from Mumbai with Chartered Flight Tickets</strong>! Immerse yourself in the magical beauty of Bhutan, the land renowned for its happiness and harmony. With direct flights from Mumbai to Paro, your journey to this enchanting kingdom is as seamless as it is luxurious.</p>
                                            <p>Embark on a breath-taking adventure through the majestic Himalayas, serene valleys, and vibrant cultural landscapes. Our <strong>Bhutan Tour Package from Mumbai with Chartered Flight Tickets</strong> offers an unforgettable experience that combines nature's splendour with Bhutan’s rich heritage. From ancient monasteries perched on cliff sides to lively markets filled with local crafts, Bhutan is a treasure trove waiting to be explored.</p>
                                            <p>In Bhutan, every moment is a celebration of life. Meet the warm and welcoming Bhutanese people, and delve into their rich traditions. Enjoy local delicacies, participate in colourful festivals, and engage in unique cultural experiences that showcase the essence of this mystical land. Our <strong>Bhutan Package Tour from Mumbai with Flight</strong> ensures you get an authentic taste of Bhutan’s vibrant culture.</p>
                                            <p>Bhutan is not just about stunning landscapes, it’s a sanctuary for the soul. Participate in rejuvenating yoga and meditation retreats surrounded by breath-taking views. Discover the true meaning of happiness in a country where Gross National Happiness is not just a concept but a way of life. This is your chance to unwind, reflect, and reconnect with your inner self.</p>
                                            <p>Apart from <strong>Mumbai to Bhutan package with Airfare</strong>, Adorable Vacation offers various <strong>Bhutan packages</strong> like <a href="/bhutan-tour-package-from-pune" target="_blank"><b className="tomato">Bhutan Package Tour from Pune</b></a>,<a href="/bhutan-tour-from-delhi" target="_blank"><b className="tomato"> Bhutan Package Tour from Delhi</b></a>, <a href="/bhutan-tour-package-from-mumbai" target="_blank"><b className="tomato">Bhutan Package Tour from Mumbai</b></a>, <a href="/bhutan-tour-package-from-ahemdabad" target="_blank"><b className="tomato">Bhutan Package Tour from Ahmedabad</b></a>, <a href="/bhutan-tour-from-bengaluru" target="_blank"><b className="tomato">Bhutan Package Tour from Bangalore</b></a>, <a href="/bhutan-tour-package-from-jaigaon" target="_blank"><b className="tomato"> Bhutan Package Tour from Jaigaon</b></a>, <a href="/bhutan-tour-package-from-kolkata" target="_blank"><b className="tomato">Bhutan Package Tour from Kolkata</b></a>, <a href="/bhutan-tour-package-from-bagdogra" target="_blank"><b className="tomato">Bhutan Package Tour from Bagdogra</b></a>, <a href="/bhutan-tour-package-from-surat" target="_blank"><b className="tomato">Bhutan Package Tour from Surat</b></a> etc at Best Price.</p><br />
                                            <div class="d-grid gap-2 d-md-block">
                                                <a href="tel://9883359713" className="btn btn-Warning btn-lg">
                                                    Talk To Our Bhutan Specialist Now
                                                </a>
                                            </div>
                                            <br />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div className="card-body">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr className="yellow">
                                                <th rowSpan="2">DAY WISE SUMMARY FOR 6N/7D BHUTAN TOUR FROM MUMBAI AIRPORT</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingOne"
                                                >
                                                    <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                        <span>
                                                            WELCOME TO PARO AND TRANSFER TO THIMPHU
                                                        </span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingTwo"
                                                >
                                                    <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                        <span>THIMPU LOCAL SIGHTSEEING</span>
                                                    </a>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingThree"
                                                >
                                                    <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                        <span>THIMPHU TO PUNAKHA TRANSFER</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingFour"
                                                >
                                                    <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                        <span>EXCURSION TOUR TO GANGTEY/ PHOBJIKHA</span>
                                                    </a>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingFive"
                                                >
                                                    <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                        <span>PUNAKHA / WANGDUE TO PARO</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingSix"
                                                >
                                                    <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                        <span>PARO LOCAL SIGHT-SEEING / TAKTSANG MONASTERY HIKING</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingTen"
                                                >
                                                    <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                        <span>PARO TO AIRPORT DROP FOR MUMBAI TRANSFER</span>
                                                    </a>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={ButtonImageURL} alt="Sightseeing 4" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br /><br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}

                            <br /> <br />
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="image-cell">
                                            <a href='#' onClick={this.onRequestCallback}><img src={Button1ImageURL} alt="bhutan-package-tour-from-mumbai-with-direct-flight-tickets-from-adorable-vacation.webp" className="image-padding" /></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br />
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>Destinations Covered in <span style={{ color: 'tomato' }}>Bhutan Tour Packages From Mumbai With Charterd Flight</span></h3>
                                            <h4>Thimpu 2N | Punakha 2N | Paro 2N</h4>
                                        </div>
                                    </div>
                                </div>
                                <br /><br />
                                <table style={{ width: '100%', textAlign: 'center' }}>
                                    <tbody>
                                        <tr>
                                            <td className="image-cell" style={{ textAlign: 'center' }}>
                                                <img
                                                    src={sightseen1ImageURL}
                                                    alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute"
                                                    style={{ margin: '10px' }}
                                                />
                                                <img
                                                    src={sightseen2ImageURL}
                                                    alt="North-Sikkim-Tour-Package-Cost-with-Silkroute"
                                                    style={{ margin: '10px' }}
                                                />
                                                <img
                                                    src={sightseen3ImageURL}
                                                    alt="North-Sikkim-Tour-Package-from-NJP"
                                                    style={{ margin: '10px' }}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br /><br /><br />

                                <h3 className='site-heading text-center'><b >Wonderful <span className='tomato'>Bhutan Tour Review</span> from our esteemed Guests Mr. & Mrs. Dalal from Mumbai</b><br /></h3>
                                <p className='p-2'><center>We are thrilled to share a heartfelt review for <strong>Bhutan tour from Mumbai</strong> from our valued guests, Mr. & Mrs. Dalal. Their experience highlights the exceptional service and memorable moments they encountered during their <strong>Bhutan tour from Mumbai with Direct Flight</strong>. We take great pride in ensuring that every guest has a delightful and fulfilling journey, and it's always rewarding to hear such positive feedback.</center></p><br /><br />
                                <div class="video-container">
                                    <iframe
                                        src="https://www.youtube.com/embed/19drRVnSMCs?si=Uj5Fm4uSuwPi0SBM"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen>
                                    </iframe>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Best places to explore during <strong>Bhutan Tour Packages from Mumbai with Chartered Flight</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Best places to explore during <strong>Bhutan Tour Packages from Mumbai with Chartered Flight</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf"><tr><th><b>PLACES</b></th><th><b>ATTRACTIONS</b></th></tr></thead>
                                            <tbody><tr><td>Thimphu</td><td>Tashichho Dzong, Simply Bhutan Museum, Buddha Dordenma Statue, National Memorial Chorten, Motithang Takin Preserve, Changangkha Lhakhang, Royal Textile Academy of Bhutan, Centenary Farmers' Market, National Folk Heritage Museum, Simtokha Dzong, Pangri Zampa Monastery</td></tr>
                                                <tr><td>Paro</td><td>Taktsang (Tiger's Nest) Monastery, Paro Dzong (Rinpung Dzong), National Museum of Bhutan, Drukgyel Dzong, Kyichu Lhakhang, Jangsarbu Lhakhang, Dungtse Lhakhang, Chele La Pass, Paro Weekend Market</td></tr>
                                                <tr><td>Punakha</td><td>Punakha Dzong, Chimi Lhakhang (The Fertility Temple), Khamsum Yulley Namgyal Chorten, Sangchhen Dorji Lhuendrup Lhakhang Nunnery, Mo Chhu River Rafting</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-bagdogra" target='_blank' className="btn btn-Warning btn-lg">
                                            <b>Bhutan Package Tour From Bagdogra Airport</b>
                                        </a>
                                    </div>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-pune" target='_blank' className="btn btn-Warning btn-lg w-100">
                                            <b>Bhutan Package Tour From Pune</b>
                                        </a>
                                    </div>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-kolkata" target='_blank' className="btn btn-Warning btn-lg w-100">
                                            <b>Bhutan Package Tour From Kolkata Airport</b>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>How to Reach Bhutan from Mumbai : Your Best Travel Options</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> How to Reach Bhutan from Mumbai : Your Best Travel Options</h3>
                                </div>
                                <div className="card-body">
                                    <h3>OPTION 1: DIRECT NON-STOP FLIGHT FROM MUMBAI TO PARO</h3><br />
                                    <p>Adorable Vacation is excited to offer <strong>direct non-stop flight from Mumbai to Paro</strong> during peak Bhutan tour Season, making your journey to Bhutan quicker and more convenient. In just 3 hours, you can land directly in Paro, eliminating layovers for a seamless travel experience. This hassle-free option allows you to immerse yourself in Bhutan’s breathtaking landscapes and vibrant culture right from the start. Perfect for both quick getaways and extended vacations, this direct flight offers comfort and efficiency.
                                    </p>
                                    <br />
                                    <div className="content-desktop">
                                        <center><img src={flight1ImageURL} alt="..." /></center>
                                    </div>

                                    <div className="content-mobile2">
                                        <center><img src={flight1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                    </div>
                                    <br /><br />
                                    <h3>OPTION 2: CONNECTING FLIGHTS TO PARO VIA MAJOR CITIES</h3><br />
                                    <p>As there is no <strong>Bhutan to Paro direct flight</strong> throughout the year, it is best to travel Bhutan from cities like Kolkata, Delhi and Guwahati from where there are direct flights to Bhutan. <b>Bhutan Airlines</b> and <b>Druk Airways</b> operate flights from Delhi (DEL), Kolkata (CCU), and Guwahati (GAU) to Paro year-round. Start with a domestic flight from Mumbai to one of these cities, then connect to a direct flight to Paro. This option provides flexibility in your travel plans while still allowing you to reach the beautiful landscapes of Bhutan.
                                    </p>
                                    <br /><br /><br />
                                    <h4 style={{ color: "tomato" }}>FLIGHTS TO CHOOSE FOR <strong>KOLKATA TO PARO AIRPORT</strong> <br /><br /></h4>
                                    <div id="no-more-tables">

                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr><th><b>SECTOR</b></th><th><b>AIRLINES</b></th><th><b>FREQUENCY</b></th><th><b>DEP TIME</b></th><th><b>ARR TIME</b></th></tr></thead>
                                            <tbody>
                                                <tr><td>CCU - PBH</td><td>Bhutan Airlines 701</td><td>Daily</td><td>8:15 am</td><td>9:55 am</td></tr>
                                                <tr><td>PBH - CCU</td><td>Bhutan Airlines 700</td><td>Daily</td><td>10:35 am</td><td>11:15 am</td></tr>
                                                <tr><td>CCU - PBH</td><td>Druk Air 211</td><td>Mon - Wed - Sat</td><td>1:20 pm</td><td>3:20 pm</td></tr>
                                                <tr><td>PBH - CCU</td><td>Druk Air 210</td><td>Mon - Wed - Sat</td><td>11:30 am</td><td>12:20 pm</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <div className="content-desktop">
                                        <center><img src={flight2ImageURL} alt="..." /></center>
                                    </div>

                                    <div className="content-mobile2">
                                        <center><img src={flight2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                    </div>
                                    <br /><br /><br />
                                    <h4 style={{ color: "tomato" }}>FLIGHTS TO CHOOSE FOR <strong>DELHI TO PARO AIRPORT</strong><br /><br /></h4>
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr><th><b>SECTOR</b></th><th><b>AIRLINES</b></th><th><b>FREQUENCY</b></th><th><b>DEP TIME</b></th><th><b>ARR TIME</b></th></tr></thead>
                                            <tbody>
                                                <tr><td>DEL - PBH</td><td>Druk Air 201</td><td>Every Sun, Tue, Wed, Fri, Sat</td><td>12:30 pm</td><td>3:20 pm</td></tr>
                                                <tr><td>PBH - DEL</td><td>Druk Air 200</td><td>Every Sun, Tue, Wed, Fri, Sat</td><td>9:40 am</td><td>11:30 am</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br /><br />
                                    <h4 style={{ color: "tomato" }}>FLIGHTS TO CHOOSE FOR <strong>GUWAHATI TO PARO</strong><br /><br /></h4>
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr><th><b>SECTOR</b></th><th><b>AIRLINES</b></th><th><b>FREQUENCY</b></th><th><b>DEP TIME</b></th><th><b>ARR TIME</b></th></tr></thead>
                                            <tbody>
                                                <tr><td>GAU - PBH</td><td>Druk Air 541</td><td>Every Sun and Thu</td><td>3:20 pm</td><td>4:45 pm</td></tr>
                                                <tr><td>PBH - GAU</td><td>Druk Air 540</td><td>Every Wed and Sat</td><td>7:50 am</td><td>8:15 am</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br /><br />
                                    <h4>Tips for Booking <strong>Bhutan tour packages from Mumbai Airport with Flights</strong>:</h4><br />
                                    <p>Book Early: Flights can fill up quickly, especially during peak travel seasons.
                                        Check for Deals: Look for promotions from airlines for the best rates.
                                        Plan Your Itinerary: Consider how much time you want to spend exploring Bhutan’s unique attractions.

                                        So, plan your <strong>Bhutan tour from Mumbai</strong>, have a discussion with our <strong>Bhutan tour Guide</strong> and book your package. Beleive it or not - Bhutan is Amazing, a really wonderfull destination to cherish your holidays.</p>
                                    <br /> <br />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={ButtonImageURL} alt="Sightseeing 4" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Hotels we provide for <strong>Bhutan Package Tour from Mumbai with Flight</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>Hotels we provide for <strong>Bhutan Package Tour from Mumbai with Flight</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr>
                                                    <th>PLACE</th>
                                                    <th>HOTEL NAME</th>
                                                    <th>ROOM CATEGORY</th>
                                                    <th>HOTEL CATEGORY</th>
                                                    <th>NO. OF NIGHTS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Thimphu</td>
                                                    <td>Hotel White Tara/Hotel Ugyen</td>
                                                    <td>Deluxe</td>
                                                    <td>3* TCB</td>
                                                    <td>2</td>
                                                </tr>
                                                <tr>
                                                    <td>Punakha</td>
                                                    <td>Hotel Pema Karpo / Hotel Vara </td>
                                                    <td>Deluxe</td>
                                                    <td>3* TCB</td>
                                                    <td>2</td></tr>
                                                <tr>
                                                    <td>Paro</td>
                                                    <td>Bhutan Mandala/ Resort Thim-Dorji</td>
                                                    <td>Deluxe</td>
                                                    <td>3* TCB</td>
                                                    <td>2</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={hotel1ImageURL}
                                                        alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={hotel2ImageURL}
                                                        alt="North-Sikkim-Tour-Package-Cost-with-Silkroute"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={hotel3ImageURL}
                                                        alt="North-Sikkim-Tour-Package-from-NJP"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Why Choose Adorable Vacation for Your <strong>Mumbai to Bhutan Trip with Direct Flight</strong>?</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>Why Choose Adorable Vacation for Your <strong>Mumbai to Bhutan Trip with Direct Flight</strong>?</h3>
                                </div>
                                <div className="card-body">
                                    <li><b>Direct Non-Stop Flights</b>: Enjoy direct, non-stop flights from Mumbai to Paro during peak season.
                                    </li><br />
                                    <li><b>Trusted Brand</b>: Adorable Vacation has been the most trusted brand for <strong>Bhutan tour from Mumbai</strong> since 2015.</li><br />
                                    <li><b>Passenger Service Agent (PSA)</b>: We are proud to be the PSA for Bhutan Airlines and Druk Airlines.</li><br />
                                    <li><b>Luxury Packages</b>: Experience the best of Bhutan with our packages featuring 5-star luxury hotels, including Six Senses, Amankora, COMO Uma, Le Méridien, Le Méridien Paro, Riverfront, and Pemako.</li><br />
                                    <li><b>Super Peak Season Inventory</b>: We have pre-purchased hotel room inventory for peak season tours, along with chartered flight tickets.</li><br />
                                    <li><b>Authorized Travel House</b>: We are an authorized travel house by the Tourism Council of Bhutan (<span style ={{color:'tomato'}}><b>License No: 1053228</b></span>).</li><br />
                                    <li><b>Direct Employment</b>: Our employees in Bhutan are directly on our payroll, ensuring quality service.</li><br />
                                    <li><b>Customized Itineraries</b>: We offer tailored <strong>Bhutan tour itineraries from Mumbai</strong> with unique and signature programs.</li><br />
                                    <li><b>In-Depth Knowledge</b>: Our team possesses extensive knowledge about Bhutan's territory, hotels, and local laws.</li><br />
                                    <li><b>Assured Services</b>: We ensure premium services to enrich your experience while in Bhutan.</li><br />
                                    <li><b>E-Permit Processing</b>: We handle the e-permit process in advance for all your sightseeing needs.</li><br />
                                    <li><b>Personalized Attention</b>: Enjoy safe and secure personal attention throughout your stay in Bhutan.</li><br />
                                    <li><b>Complete Responsibility</b>: Adorable Vacation takes full responsibility for your entire <strong>Mumbai to Bhutan tour with Chartered Flights</strong>.</li><br />
                                    <li><b>Hassle-Free Experience</b>: We guarantee hassle-free and trustworthy services in Bhutan.</li><br />
                                    <li><b>Best Deals</b>: Access the best deals for top properties and services based on your preferences.</li><br />
                                    <li><b>Flexible Travel Options</b>: Plan a <strong>customized Bhutan private trip</strong> or join a <strong>group tour to Bhutan from Mumbai</strong> with Adorable Vacation.</li><br />
                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen4ImageURL}
                                                        alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen5ImageURL}
                                                        alt="North-Sikkim-Tour-Package-Cost-with-Silkroute"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen6ImageURL}
                                                        alt="North-Sikkim-Tour-Package-from-NJP"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Best Time to Book Your <strong>Bhutan Package Tour from Mumbai with Chartered Flight Tickets</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>Best Time to Book Your <strong>Bhutan Package Tour from Mumbai with Chartered Flight Tickets</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p><b>Summer (June to September): </b>
                                        Summer in Bhutan brings pleasant temperatures ranging from 20 to 25 degrees Celsius. The weather is sunny and slightly humid, with cool nights that may require light woolen garments or jackets. This season transforms the landscape into a lush green paradise, offering stunning views of snow-capped Himalayan peaks. Visiting Bhutan during summer allows you to experience the Haa Festival, celebrating Bhutanese food, culture, and traditions. With fewer crowds, you can explore various sightseeing spots at your leisure. So, Summer is an excellent time to book your <strong>Bhutan holiday packages from Mumbai</strong>. And every year Adorable Vacation offers <strong>Mumbai to Bhutan packages with Direct Flight Tickets</strong> with lots of <strong>customized Bhutan Private trip</strong> and <strong>Bhutan Group Tour</strong>.</p>
                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={summer1ImageURL}
                                                        alt="bhutan-tour-booking-from-mumbai-during-spring-with-adorable-vacation.webp"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={summer2ImageURL}
                                                        alt="bhutan-tour-from-mumbai-during-spring-with-adorable-vacation.webp"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={summer3ImageURL}
                                                        alt="bhutan-tour-package-from-mumbai-during-spring-with-adorable-vacation.webp"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                    <p><b>Autumn (September to November): </b>
                                        Autumn is astonishingly the best season for booking Bhutan tours from Mumbai. The weather is comfortable, with temperatures between 10 to 15 degrees Celsius, making daytime sightseeing enjoyable, although nights can be chilly. This is a festive time in Bhutan, featuring events like the Black-Necked Crane Festival and the Paro and Punakha Tshechus, where vibrant traditions come to life with colorful flags and masked dances. Adorable Vacation recommends to book your Bhutan tour packages in the summer to avoid the peak season rush and ensure availability of hotels and flights.</p>
                                        <br /><br />
                                    <p><b>Winter (December to February) </b>:
                                        Winter in Bhutan can be harsh, with temperatures plummeting, especially in high-altitude areas. Cities like Thimphu, Paro, and Punakha may be blanketed in snow, making travel challenging. Despite the cold, this season offers unique experiences, including festivals like Monggar Tshechu and Trashigang Tshechu. If you enjoy winter landscapes and festive celebrations, consider booking your <strong>Bhutan package tour from Mumbai</strong> during this time. Winter in Bhutan is truly a magical experience!</p>
                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={winter1ImageURL}
                                                        alt="bhutan-tour-booking-from-mumbai-during-spring-with-adorable-vacation.webp"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={winter2ImageURL}
                                                        alt="bhutan-tour-from-mumbai-during-spring-with-adorable-vacation.webp"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={winter3ImageURL}
                                                        alt="bhutan-tour-package-from-mumbai-during-spring-with-adorable-vacation.webp"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                    <p><b>Spring (March to May) :</b>
                                        Spring breathes life back into Bhutan after the winter chill. The blooming Jacaranda and Rhododendron flowers create a stunning landscape of purple petals, transforming the valleys into a picturesque paradise. With clear skies and minimal rain, this is the perfect time for famous treks like Jomolhari and Drukpath. Additionally, several auspicious festivals, including Paro Tshechu and Gasa Tshechu, take place during this season, often attended by the King of Bhutan. Due to the influx of tourists, Adorable Vacation advises booking your Bhutan package from Mumbai early to secure the best rates and avoid peak season surcharges.</p>
                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={spring1ImageURL}
                                                        alt="bhutan-tour-booking-from-mumbai-during-spring-with-adorable-vacation.webp"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={spring2ImageURL}
                                                        alt="bhutan-tour-from-mumbai-during-spring-with-adorable-vacation.webp"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={spring3ImageURL}
                                                        alt="bhutan-tour-package-from-mumbai-during-spring-with-adorable-vacation.webp"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="row py-5">
                                <div className="col-md-12">
                                    <div className="site-heading text-center">
                                        <h2>
                                            DAY-BY-DAY ITINERARY FOR <span><strong>BHUTAN PACKAGE TOUR FROM MUMBAI WITH CHARTERED FLIGHT TICKETS</strong></span>
                                        </h2>
                                        <h4><b>6 Nights 7 Days Bhutan Tour Itinerary From Mumbai</b></h4>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div
                                    className="accordion"
                                    id="accordionPanelsStayOpenExample"
                                >
                                    <div className="accordion-item">
                                        <h2
                                            className="accordion-header"
                                            id="day1"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseOne"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseOne"
                                            >
                                               <h4> DAY 01: ARRIVAL AT PARO AIRPORT AND TRANSFER TO THIMPHU</h4>
                                            </button>
                                        </h2>
                                        <div
                                            id="panelsStayOpen-collapseOne"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingOne"
                                        >
                                            <div className="accordion-body">
                                                Welcome to Bhutan, the Land of the Thunder Dragon! As you arrive at Paro Airport for your <strong>bhutan tour from mumbai</strong>, you’ll be greeted by breathtaking views of the Paro Dzong, a stunning fortress perched on a hillside. A representative will warmly welcome you with a traditional Bhutanese scarf and assist you in your transfer to Thimphu, the capital city. The drive takes you through beautiful landscapes filled with green valleys and charming villages. Once you reach Thimphu, you can relax and enjoy your first night in this vibrant city.
                                                <br /> <br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay1ImageURL} alt="mumbai-to-bhutan-tour-packages-with-direct-flights-with-adorable-vacation.webp" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="mumbai-to-bhutan-tour-packages-with-direct-flights-with-adorable-vacation.webp" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2
                                            className="accordion-header"
                                            id="day2"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseTwo"
                                            >
                                                <h4>DAY 02: FULL DAY THIMPU SIGHTSEEING</h4>
                                            </button>
                                        </h2>
                                        <div
                                            id="panelsStayOpen-collapseTwo"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingTwo"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    Start your day with a delicious breakfast and a cup of hot butter tea, known as Suja. Then, embark on a sightseeing adventure around Thimphu. One of the first stops is the National Memorial Chorten, a peaceful site built in memory of the third King of Bhutan, where locals come to pray and meditate. You’ll also visit the Clock Tower, an architectural highlight of the city, surrounded by shops and cafes, making it a great place to soak in the local atmosphere.
                                                </p><br />
                                                <p>
                                                    Next, head to the towering statue of Sakyamuni Buddha, which offers stunning views of the valley. This impressive 51.5-meter statue is a significant spiritual site and a must-see during your tour. You’ll also explore Changangkha Lhakhang, one of the oldest temples in Thimphu, dedicated to Avalokiteshvara, the symbol of compassion.
                                                </p><br />
                                                <p>After a day of sightseeing, you’ll visit Takin Zoo, home to Bhutan’s national animal, the Takin. This unique creature is both endangered and fascinating. Finally, don’t miss Trashi Chhoe Dzong, a beautiful fortress that serves as the formal coronation site of the King. In the evening, experience Simply Bhutan, an interactive museum that showcases the rich culture of Bhutan. Here, you can try on traditional clothing and even participate in local games, providing a unique glimpse into Bhutanese life.</p>
                                                <br /><br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay2ImageURL} alt="mumbai-to-bhutan-tour-packages-booking-with-direct-flights-with-adorable-vacation.webp" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="mumbai-to-bhutan-tour-packages-booking-with-direct-flights-with-adorable-vacation.webp" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2
                                            className="accordion-header"
                                            id="day3"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseThree"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 03: THIMPHU TO PUNAKHA TRANSFER VIA DOCHULA PASS</h4>
                                            </button>
                                        </h2>
                                        <div
                                            id="panelsStayOpen-collapseThree"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingThree"
                                        >
                                            <div className="accordion-body">
                                                <p>After enjoying a hot cup of Ngaja, a sweet Bhutanese milk tea, you will set out for Punakha, the former capital of Bhutan. The journey takes you through the picturesque Dochula Pass, where you can admire the stunning views of the Himalayas. Once you arrive in Punakha, you’ll explore Punakha Dzong, also known as the “Palace of Great Happiness.” This magnificent fortress is located at the confluence of the Pho and Mo Chhu rivers and is a perfect example of Bhutanese architecture.
                                                </p><br />
                                                <p>
                                                    You’ll also have the chance to walk across a beautiful suspension bridge covered in prayer flags, a great spot for photos and bird watching. Another highlight is Chimi Lhakhang, a fertility temple dedicated to the famous 'Divine Madman,' Lama Drukpa Kuenley. Many couples visit this temple hoping to receive blessings for children. You’ll spend the night in Punakha, surrounded by the stunning natural beauty of the valley.
                                                </p>
                                                <br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay3ImageURL} alt="mumbai-to-bhutan-tour-package-cost-with-direct-flights-with-adorable-vacation.webp" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="mumbai-to-bhutan-tour-package-cost-with-direct-flights-with-adorable-vacation.webp" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2
                                            className="accordion-header"
                                            id="day4"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFour"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 04: - PUNAKHA TO PHOBJIKHA EXCURSION</h4>
                                            </button>
                                        </h2>
                                        <div
                                            id="panelsStayOpen-collapseFour"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFour"
                                        >
                                            <div className="accordion-body">
                                                <p>Today, you’ll take a day trip to Phobjikha Valley, a haven for nature lovers. This beautiful valley is famous for its rich biodiversity, including the rare Black-Necked Cranes that migrate here each winter. You can explore various attractions, such as the Black-Necked Crane Information Centre and Gangtey Monastery, perched on a hilltop offering breathtaking views. The serene environment makes it an ideal spot for hiking and bird watching, allowing you to truly connect with nature.</p>
                                                <p>After a fulfilling day in Phobjikha, you’ll return to Punakha for another overnight stay, soaking in the tranquility of this remarkable region.
                                                </p>
                                                <br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay4ImageURL} alt="mumbai-to-bhutan-tour-package-itinerary-with-direct-flights-with-adorable-vacation.webp" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="mumbai-to-bhutan-tour-package-itinerary-with-direct-flights-with-adorable-vacation.webp" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2
                                            className="accordion-header"
                                            id="day5"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFive"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                               <h4>DAY 05: PUNAKHA TO PARO TRANSFER AND LOCAL SIGHTSEEING</h4>
                                            </button>
                                        </h2>
                                        <div
                                            id="panelsStayOpen-collapseFive"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFive"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    After breakfast, you’ll head to Paro, known for its stunning scenery and rich history. The drive takes about three hours, and as you arrive, you’ll be captivated by the beautiful landscapes, fertile rice fields, and clear rivers. Once in Paro, your sightseeing begins with a visit to the National Museum of Bhutan, housed in the ancient Ta-dzong building. This museum offers a deep dive into Bhutanese art and culture, showcasing everything from beautiful paintings to bronze statues.
                                                </p><br />
                                                <p>Next, you’ll visit Rinpung Dzong, a historic fortress that also serves as a monastery. Its impressive architecture and the traditional covered bridge leading to it are truly a sight to behold. You can also explore the Nya Mey Zam Bridge, known for its unique local beliefs. Finally, enjoy the stunning views at the Paro Airport Viewpoint before settling in for the night in Paro.
                                                </p>

                                                <br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay5ImageURL} alt="mumbai-to-bhutan-tour-package-plan-with-direct-flights-with-adorable-vacation.webp" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="mumbai-to-bhutan-tour-package-plan-with-direct-flights-with-adorable-vacation.webp" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2
                                            className="accordion-header"
                                            id="day6"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSix"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                               <h4>DAY 06: DAY TRIP TO CHELE-LA PASS AND TAKTSANG MONASTERY</h4>
                                            </button>
                                        </h2>
                                        <div
                                            id="panelsStayOpen-collapseSix"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSix"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    On your final full day in Bhutan, you’ll embark on an exciting trip to Chele-La Pass, the highest motorable pass in Bhutan, offering spectacular views of Mount Jomolhari and other snow-covered peaks. After soaking in the views, you’ll hike to the famous Taktsang Monastery, also known as Tiger’s Nest. This iconic site is perched on a cliff and requires a moderate trek, but the breathtaking scenery along the way makes it worthwhile.
                                                </p>
                                                <p>Once you reach the monastery, take a moment to appreciate its beauty and the fresh mountain air. In the evening, indulge in a traditional hot stone bath, a relaxing experience believed to have numerous health benefits. After a long day of exploration, you’ll return to your hotel in Paro for a well-deserved rest.</p>
                                                <br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay6ImageURL} alt="mumbai-to-bhutan-tour-package-price-with-direct-flights-with-adorable-vacation.webp" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="mumbai-to-bhutan-tour-package-price-with-direct-flights-with-adorable-vacation.webp" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2
                                            className="accordion-header"
                                            id="day7"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 07: DEPARTURE</h4>
                                            </button>
                                        </h2>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    On your final morning, enjoy breakfast and reflect on your incredible journey through Bhutan. Afterward, you’ll head to Paro Airport, cherishing the memories of your <strong>Bhutan package tour from Mumbai</strong>. As you depart, you’ll carry with you the beauty and culture of this enchanting kingdom.
                                                </p>

                                                <br />
                                                {/* <div className="content-desktop">
                                                    <center><img src={navDay7ImageURL} alt="bhutan package tour cost from mumbai and explore chele la pass" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="bhutan package tour cost from mumbai and explore chele la pass" /></center>
                                                </div>
                                                <br /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="container-fluid py-5">
                                <div className="row placetoVisit">
                                    <h3>TOP 10 ATTRACTIONS OF <strong>BHUTAN PACKAGE TOUR FROM MUMBAI WITH CHARTERED FLIGHT TICKETS</strong><br /><br /></h3>
                                    <p>Embark on an unforgettable journey with a <strong>Bhutan package tour from Mumbai</strong>, where you can explore the unique cultural and natural treasures of this enchanting Himalayan kingdom. With direct flights included in your tour package, getting to Bhutan is convenient and hassle-free. Here are the top ten attractions that will make your <strong>Bhutan trip</strong> truly special. <br /><br /><br /></p>
                                    <ul>
                                        <p>
                                            <b>TrashiChhoe Dzong, Thimphu</b><br />
                                            Begin your Bhutan adventure with a visit to TrashiChhoe Dzong in Thimphu, a stunning fortress that embodies the essence of Bhutanese architecture. Known as the "Fortress of the Glorious Religion," it serves as the administrative seat of the government and a spiritual center. The dzong’s intricate designs and serene surroundings make it a perfect introduction to Bhutan's rich heritage.
                                            <br /><br /><br />
                                        </p>
                                        <p>
                                            <b>Takin Zoo, Thimphu</b><br />
                                            As part of your <strong>Bhutan tour packages from Mumbai</strong>, don’t miss the opportunity to visit Takin Zoo. This sanctuary is home to the national animal of Bhutan, the takin, a unique creature found nowhere else in the world. Here, you can learn about Bhutan's diverse wildlife and conservation efforts while enjoying the beautiful natural environment.
                                            <br /><br /><br />

                                            <b>Weekend Market, Thimphu</b><br />
                                            Dive into the vibrant culture of Bhutan by visiting the Weekend Market in Thimphu. This lively market offers a wide array of local handicrafts, fresh produce, and traditional Bhutanese delicacies. Exploring the market gives you a taste of everyday life in Bhutan and the opportunity to purchase unique souvenirs.
                                            <br /><br /><br />

                                            <b>Gangtey Monastery</b><br />
                                            Included in your <strong>Bhutan tour packages from Mumbai</strong>, Gangtey Monastery in the Phobjikha Valley is a serene retreat showcasing Bhutan's spiritual heritage. Nestled among pristine landscapes, the monastery is an excellent place for meditation and reflection. The surrounding area is perfect for leisurely walks, allowing you to connect with nature.
                                            <br /><br /><br />

                                            <b>Shopping in Handicrafts Village, Thimphu</b><br />
                                            Enhance your <strong>Bhutan package from Mumbai with Direct</strong> Flight with a shopping excursion to the Handicrafts Village in Thimphu. Here, you can browse exquisite handicrafts, textiles, and souvenirs crafted by local artisans. This is not only a chance to shop but also an opportunity to appreciate the skill and creativity of Bhutanese craftsmanship.
                                            <br /><br /><br />

                                            <b>Buddha Point, Thimphu</b><br />
                                            Marvel at the majestic statue of Buddha Dordenma at Buddha Point in Thimphu. This colossal statue overlooks the Thimphu Valley, offering panoramic views. The peaceful atmosphere around the statue makes it an ideal spot for contemplation and relaxation.
                                            <br /><br /><br />

                                            <b>Tiger Nest Hiking, Paro</b><br />
                                            Experience the thrill of hiking to the legendary Tiger’s Nest Monastery (Taktsang) in Paro, a highlight of your <strong>Bhutan tour from Mumbai with direct flight tickets</strong>. This sacred site, perched on a cliff, requires a moderate trek, but the stunning views and spiritual significance make it worthwhile. The hike allows you to immerse yourself in Bhutan’s rich spiritual traditions and breath-taking landscapes.
                                            <br /><br /><br />

                                            <b>River Rafting in Punakha River, Punakha</b><br />
                                            Add an adventurous twist to your <strong>Bhutan package from Mumbai</strong> with exhilarating river rafting on the Punakha River. Surrounded by lush greenery and majestic mountains, this activity is perfect for thrill-seekers. Experience the rush of navigating the river's rapids while taking in the stunning scenery around you.
                                            <br /><br /><br />

                                            <b>Punakha Dzong, Punakha</b><br />
                                            No visit to Bhutan is complete without exploring Punakha Dzong. Located at the confluence of the Mo Chhu and Pho Chhu rivers, this fortress-monastery is rich in history and cultural significance. The dzong’s magnificent structure and serene gardens offer insight into Bhutanese culture and spirituality.
                                            <br /><br /><br />

                                            <b>Simply Bhutan Museum</b><br />
                                            Immerse yourself in Bhutanese culture at the Simply Bhutan Museum during your <strong>Bhutan package tour from Mumbai</strong>. This living museum offers interactive exhibits, traditional performances, and opportunities to learn about the daily lives of Bhutanese people. It’s a unique experience that brings Bhutan's rich culture to life.
                                            <br /><br /><br />

                                            <b>Phobjikha Valley</b><br />
                                            Discover the unspoiled beauty of Phobjikha Valley, known for its wintering black-necked cranes. This sanctuary for nature lovers and bird watchers offers breathtaking landscapes, making it a perfect day trip during your Bhutan tour.
                                            <br /><br /><br />

                                            <b>Haa Valley, Haa District</b><br />
                                            Journey to the picturesque Haa Valley as part of your <strong>Mumbai to Bhutan Tour with Chartered Flight Tickets</strong>. This hidden gem in western Bhutan offers stunning landscapes, ancient temples, and rich cultural experiences that will leave you enchanted.
                                            <br /><br /><br />

                                            <b>Dochula Pass</b><br />
                                            Capture panoramic views of the Himalayas at Dochula Pass, a scenic spot adorned with 108 memorial chortens. This mountain pass is not only a photographer’s paradise but also a serene place for reflection and appreciation of Bhutan’s natural beauty.
                                            <br /><br /><br />

                                            <b>Punakha Suspension Bridge</b><br />
                                            Dare to cross the Punakha Suspension Bridge, one of the longest in Bhutan. This thrilling experience offers stunning views of the Punakha Valley and is a memorable highlight of your journey.
                                            <br /><br /><br />

                                            <b>Chimi Lhakhang</b><br />
                                            Conclude your <strong>Bhutan Tour Packages from Mumbai with Chartered Flight</strong> with a visit to Chimi Lhakhang, the fertility temple dedicated to the Divine Madman. Known for its unique phallus symbols believed to bring blessings and fertility, this temple is a fascinating glimpse into Bhutan's colorful religious traditions.
                                            <br /><br /><br />

                                            With these top attractions included in your <strong>Mumbai to Bhutan package with chartered flight</strong>, you are sure to experience the beauty, culture, and adventure that Bhutan has to offer. Book your Bhutan tour today with <strong>Adorable vacation – The Best DMC for Bhutan tour from India</strong> and embark on a journey of a lifetime!
                                        </p>
                                    </ul>
                                </div>
                                <br />
                                <div className="container-fluid py-5">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="site-heading text-center">
                                                <h2>
                                                    Branded Bhutan Tour <span>- The SIX SENSE</span>
                                                </h2>
                                                <h4>
                                                    <i>Next Level Luxury Sense</i>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row py-3">
                                        <img src={sixSenseBhutanTourPackagesImageURL} alt="bhutan package tour from mumbai with hotel six senses" />
                                    </div>
                                </div>
                                <div id="no-more-tables">
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Packages</th>
                                                <th>Duration</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Head)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Luxury Trip</td>
                                                <td>5N/6D</td>
                                                <td>
                                                    The SIX SENSE (Ultra Luxury)
                                                    <div className="reviews">
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                </td>
                                                <td>Rs 5,36,789/- </td>
                                                <td><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick1}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        Six Sense Bhutan Packages - An Ultra Luxury Five Star Brand
                                    </div>
                                    <div className="card-body">
                                        <p>
                                            Adorable can adjust your itinerary depending on your needs
                                            & preference. We are happy to arrange your itinerary to
                                            fit your interests, allowing you to explore and enjoy the
                                            Kingdom of Bhutan at your own peace.<br /><br />
                                        </p>
                                        <h3 className="tomato">Unbeatable GEM of Bhutan</h3>
                                        <p>
                                            Travel back in time and take a journey that few people
                                            have had the opportunity to experience. Druk Yul, Bhutan’s
                                            official name, is known as the Land of the Dragon People
                                            with a rich cultural heritage that has largely remained
                                            hidden until its recent emergence onto the world stage.{" "}
                                            <b>Your Six Senses GEM (Guest Experience Maker)</b> will
                                            meet you upon arrival and accompany you on your khamsa
                                            ‘royal stroll’ through the Kingdom, sharing insights to
                                            the culture, history and local life of each valley as you
                                            go. Discover the country’s breathtaking natural beauty,
                                            meet and mingle with our Bhutanese friends as you embrace
                                            the philosophy of a nation that is guided by the
                                            principles of ‘Gross National Happiness’. Our khamsas are
                                            designed with just the right balance of meaningful
                                            encounters and experiences in mind along with a healthy
                                            dose of fun. The following is only a guideline. Your GEM
                                            will help to tailor your 5-day Bhutan itinerary to your
                                            needs and interests each day.
                                        </p>
                                        <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Service available at Paro, Punakha, Thimphu, Bumthang
                                            and Gangtey.<br /><br /></h3>

                                        <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b> Service available at Paro, Punakha, Thimphu, Bumthang
                                            and Gangtey.</b></h3>
                                        <a href="tel://9883359713" className="btn btn-Warning">
                                            Book Now
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                Branded Bhutan Tour <span>- The AMAN KORA</span>
                                            </h2>
                                            <h4>
                                                <i>Stay with Trust</i>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={amanKoraBhutanPackagesImageURL} alt="bhutan package tour from mumbai with hotel aman kora" />
                                </div>
                                <div id="no-more-tables">
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Packages</th>
                                                <th>Duration</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Head)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Premium Trip</td>
                                                <td>5N/6D</td>
                                                <td>
                                                    The AMAN KORA (Premium)
                                                    <div className="reviews">
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                </td>
                                                <td>Rs 4,70,389/- </td>
                                                <td><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick1}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div
                                        className="card-header custHeaer"
                                    >
                                        <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>  AMAN KORA Bhutan Packages - A Super Premium Five Star Brand</h3>

                                        <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>  AMAN KORA Bhutan Packages - A Super Premium Five Star Brand</b></h3>

                                    </div>
                                    <div className="card-body">
                                        <p>
                                            Adorable can adjust your itinerary depending on your needs
                                            & preference. We are happy to arrange your itinerary to
                                            fit your interests, allowing you to explore and enjoy the
                                            Kingdom of Bhutan at your own peace.<br /><br />
                                        </p>
                                        <h3 className="tomato">Unbeatable GEM of Bhutan</h3>
                                        <p>
                                            Travel back in time and take a journey that few people
                                            have had the opportunity to experience. Druk Yul, Bhutan’s
                                            official name, is known as the Land of the Dragon People
                                            with a rich cultural heritage that has largely remained
                                            hidden until its recent emergence onto the world stage.{" "}
                                            <b>Your Six Senses GEM (Guest Experience Maker)</b> will
                                            meet you upon arrival and accompany you on your khamsa
                                            ‘royal stroll’ through the Kingdom, sharing insights to
                                            the culture, history and local life of each valley as you
                                            go. Discover the country’s breathtaking natural beauty,
                                            meet and mingle with our Bhutanese friends as you embrace
                                            the philosophy of a nation that is guided by the
                                            principles of ‘Gross National Happiness’. Our khamsas are
                                            designed with just the right balance of meaningful
                                            encounters and experiences in mind along with a healthy
                                            dose of fun. The following is only a guideline. Your GEM
                                            will help to tailor your 5-day Bhutan itinerary to your
                                            needs and interests each day.
                                        </p>

                                        <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Service available at Paro, Punakha, Thimphu, Bumthang
                                            and Gangtey.</h3>

                                        <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b> Service available at Paro, Punakha, Thimphu, Bumthang
                                            and Gangtey.</b></h3>

                                        <a href="tel://9883359713" className="btn btn-Warning">
                                            Book Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <br />
                            {/* <!--FOQ--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                Frequently Ask <span>Questions</span>
                                            </h2>
                                            <h4>Bhutan Trip Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="accordion accordion-flush faQStyle"
                                        id="accordionFlushExample"
                                    >
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingOne">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseOne"
                                                >
                                                    Is Bhutan visa free for Indian?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseOne"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingOne"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Yes, Indian citizens do not require a visa to enter
                                                    Bhutan.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTwo">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseTwo"
                                                >
                                                    How can I go to Bhutan from India?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseTwo"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingTwo"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    You can take a flight to Bagdogra (IXB). After that
                                                    enter to Bhutan by road via PHUENTSHOLING. This will
                                                    be an economical option. In terms of luxury option,
                                                    you can avail Direct Non Stop flight to PARO (PBH)
                                                    airport. Operated from three major cities DELHI,
                                                    KOLKATA & GUWAHATI.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingThree">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseThree"
                                                >
                                                    Do Bhutanese Speak English?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseThree"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingThree"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Dzongkha is the national language of the Bhutan, which
                                                    is predominantly spoken in the western region of
                                                    Bhutan. However, there are 25 dialects spoken in
                                                    Bhutan. Bhutanese people speak English as it is the
                                                    medium of instruction in the schools. Hence it is easy
                                                    to talk to Bhutanese people if you are fluent in
                                                    English. The official documents, road signs, and even
                                                    the major national newspapers are published in English
                                                    making it easier for tourists to understand.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFour">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFour"
                                                >
                                                    Is Alcohol cheaper in Bhutan?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseFour"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingFour"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Yes, alcohol is cheaper in Bhutan, and the locally
                                                    made alcohol is good in taste, and these beverages act
                                                    as a great souvenir for the boozers. Do try out Ara,
                                                    the traditional hot alcoholic beverage consumed by the
                                                    majority of the population and is made of rice,
                                                    barley, wheat, or millet, and herbs which is either
                                                    fermented or diluted.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFive">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFive"
                                                >
                                                    Can I use Indian money in Bhutan Tour from Mumbai?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseFive"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingFive"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Yes, you can carry Indian Note to Bhutan. Except Rs
                                                    2000/- Note, which is not a legal tender in Bhutan?
                                                    However, many merchants in Bhutan now days accept Rs
                                                    2000/- Note.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSix">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFive"
                                                >
                                                    How are the Bhutan Mobile / Internet Connectivity?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSix"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingSix"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    ndian Mobile Operators provide the Global Roaming
                                                    Connectivity in Bhutan, However, it is expensive.
                                                    Please check with your operator about Global Roaming
                                                    Activation / Incoming and Out Going Charges. Tourists
                                                    can easily get the Bhutan Tourists SIM card (Tashi
                                                    Cell and BMobile ) at Rs 200/- with data option at Rs
                                                    99/- for 400 MB data.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseSeven"
                                                >
                                                    Can I get wine in Bhutan including imported Brands?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSeven"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingSeven"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    In Paro Duty free shop, you can get various brands of
                                                    imported foreign Whisky, Wine, Vodka, Beer etc and
                                                    price is relatively cheaper compared to any Indian
                                                    Duty Free shop. In Thimphu, you can get the imported
                                                    wine brand, however in Paro and Punakha, you may get
                                                    the imported brands only in your hotel, not in any off
                                                    shop. However, in Bhutan, availability of local brand
                                                    wines is abundant and they are really worthy to buy.
                                                    While you are in Bhutan Package Tour, you must buy
                                                    Local Peach Wine and am confident you will love it.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>
                                                <b>
                                                    Bhutan <span className="tomato">Theme Package</span>
                                                </b>
                                            </h3>
                                            <h4>Similar Mountain Trip</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        id="carouselExampleControls"
                                        className="carousel slide"
                                        data-bs-ride="carousel"
                                    >
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <Slider {...settings}>{themePackagesDataView}</Slider>
                                            </div>
                                        </div>
                                    </div>

                                    <br />
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>
                                                <b>
                                                    Bhutan <span className="tomato">Tour Reviews</span>
                                                </b>
                                            </h3>
                                            <h4>
                                                <i>"Your Experience is our Marketing"</i>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1 ">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">
                                            {reviewersDataView}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3">

                            <BookingBox />

                        </div>
                        {this.state.showModal && (
                            <div className="modal d-flex align-items-center justify-content-center" style={{ display: this.state.showModal ? "block" : "none" }}>
                                <div className="modal-dialog">
                                    <div className="modal-content cust-modContent" ref={this.modalRef}>
                                        <div className="modal-header">
                                            <h4 className="modal-title ms-auto" id="exampleModalLabel">Get a Free Callback &nbsp;&nbsp;<i className="fa fa-phone fa-rotate-90"></i>
                                            </h4>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.onCloseModal}></button>
                                        </div>
                                        <div className="modal-body">
                                            <form className="row g-3">
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name *"
                                                        name="firstName"
                                                        value={this.state.firstName}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="10 Digit Mobile No.*"
                                                        name="mobile"
                                                        value={this.state.mobile}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Email "
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Comments"
                                                        rows="2"
                                                        name="comments"
                                                        value={this.state.comments}
                                                        onChange={this.onChangeHandler}></textarea>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <div className='container'>
                                                <div className="row">
                                                    <div className='col-sm-4'>
                                                        {
                                                            (this.state.showLoader) &&
                                                            <div className='pull-left'><Digital size={25} /></div>
                                                        }
                                                    </div>
                                                    <div className='col-sm-8 '>
                                                        <div className='pull-right'>
                                                            {/* <button type="button" className="btn btn-Warning mr-1" onClick={this.onCloseModal}>Close</button> */}
                                                            <button type="button" className="btn btn-Warning btn-lg" onClick={this.onRequestCallbackSubmit}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default FIFO_7N_8D_Package;