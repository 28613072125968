import React, { Component } from 'react';
import ApiURL from "../../../api/ApiURL";

class InclusionExclusion extends Component {
    
    render() {
        const CallButtonSonaliImageURL = ApiURL.BhutanTourPackageFromGuwahatiImageURL + "/call-button-sonali-adorable-vacation.webp";
        return (
            <>
                <table className="table-borderDodont">
                    <thead>
                        <tr>
                            <td className="tomato"><h4>INCLUSIONS :<strong>JAIGAON TO BHUTAN PACKAGE</strong></h4></td>
                            <td className="tomato"><h4>EXCLUSIONS : <strong>BHUTAN TOUR FROM JAIGAON </strong></h4></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Accommodation in TCB listed Hotels (Double/Twin Sharing Room) </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Air Fare </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Transfers & all sightseeing in an exclusive Non-AC Vehicle (Pvt. Basis) </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Snacks, Lunch or any other meal </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>Parking, Toll Tax, e-Permits </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Snacks, Lunch or any other meal </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Thimphu Local Sightseeing </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Monument Entrance Fees</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Paro City Tour </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Laundry, Tips, Phone Call or any such Personal Expense 
                            </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>English / Hindi Speaking Tour Guide </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Any item which is not mentioned in the "Inclusion" Section 
                            </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>All transfer will be on point to point basis </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Additional sightseeing or
                                extra usage of vehicles, other than those mentioned in the itinerary.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>SDF Charges (Sustainable Development Fee) </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Any cost arising due to
                                natural calamities like landslides, road blockage,
                                political disturbances (strikes),etc (to be borne by the client, which is directly payable on the
                                spot).</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>01 Bottle Packages Drinking Water Per Day on Vehicle </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Any increase in taxes or
                                fuel price, leading to an increase in cost on surface
                                transportation & land arrangements, which may come into effect prior to departure.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>Guest Service Support during the trip from Bhutan & India Office 24/7 </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Does not include any
                                Lunch & Evening Tea/snacks.</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                                    {/* <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="bhutan tour packages from mumbai with chartered flight tickets from adorable vacation" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> */}
                
            </>
        );
    }
}

export default InclusionExclusion;