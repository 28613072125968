import React, { Component } from 'react';

class ThingToPack extends Component {

    render() {
        return (
            <>
                <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                        <tbody>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Winter Jacket</td>
                                <td style={{ fontSize: "16px" }}>Thermal Wear</td>
                                <td style={{ fontSize: "16px" }}>ThermoFLUX</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Portable Electric Kettle [optional]</td>
                                <td style={{ fontSize: "16px" }}>Raincoat</td>
                                <td style={{ fontSize: "16px" }}>Dry Fruits and Chocolate</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Biscuit & Dry Snack</td>
                                <td style={{ fontSize: "16px" }}>Woolen Socks</td>
                                <td style={{ fontSize: "16px" }}>Woolen Cap</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Mufflers</td>
                                <td style={{ fontSize: "16px" }}>Woolen Hand Gloves</td>
                                <td style={{ fontSize: "16px" }}>Rucksack</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Undergarments</td>
                                <td style={{ fontSize: "16px" }}>Toothpaste / Brush</td>
                                <td style={{ fontSize: "16px" }}>Moisturizers / Vaseline</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Power Bank</td>
                                <td style={{ fontSize: "16px" }}>Torch / Flashlight</td>
                                <td style={{ fontSize: "16px" }}>Gas Lighter</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>First Aid Kits</td>
                                <td style={{ fontSize: "16px" }}>Inhaler (for Asthma patient)</td>
                                <td style={{ fontSize: "16px" }}>Camphor</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />

            </>
        );
    }
}

export default ThingToPack;