import React, { Component } from 'react';
import MainHeader from '../components/common/MainHeader';
import FooterDesktop from '../components/common/FooterDesktop';
import VisaServices from '../components/home/VisaServices';
import { Helmet } from "react-helmet";
class VisaPage extends Component {
    constructor() {
        super();
        this.state = {
            HotPackagesData: [],
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const hotPackageDataList = [
            {
                Title: "Blissful Bhutan",
                ImageName: "6N-7D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 1N Punakha | 1N Paro...more | Free Sim Card | E-Permit",
                PricePerNight: "29,900/-",
                NoOfTours: 43,
                NoOfDeparture: 38,
                SDFInclude:"SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book:"Book Now",
                Url: 'tel://9883359713',
                EXPARO:"EX-IXB"
            },
            {
                Title: "Untouched Bhutan",
                ImageName: "7N-8D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "3N Thimphu | 2N Paro...more | Free Sim Card | E-Permit",
                PricePerNight: "35,100/-",
                NoOfTours: 47,
                NoOfDeparture: 40,
                SDFInclude:"SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book:"Book Now",
                Url: 'tel://9883359713',
                EXPARO:"EX-IXB"
            },
            {
                Title: "Explore Bhutan",
                ImageName: "8N-9D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "40,200/-",
                NoOfTours: 44,
                NoOfDeparture: 37,
                SDFInclude:"SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book:"Book Now",
                Url: 'tel://9883359713',
                EXPARO:"EX-IXB"
            },
            {
                Title: "Excape Bhutan",
                ImageName: "10N-11D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro...more | Free Sim Card | E-Permit",
                PricePerNight: "48,200/-",
                NoOfTours: 46,
                NoOfDeparture: 39,
                SDFInclude:"SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book:"Book Now",
                Url: 'tel://9883359713',
                EXPARO:"EX-IXB"
            }

        ];

        const reviewersDataList = [
            {
                Name: "Priya Sharma",
                ImageName: "Priya-Sharma.webp",
                AllternateText: "...",
                Location: "@Delhi",
                Comment: "I had a fantastic experience with Adorable Vacation for my Europe visa application! Special thanks to Mr. Akash Dutta, who guided me through the entire Schengen visa process with such precision and professionalism. His knowledgeable advice ensured I had all the required documents for multiple European countries, including France and Italy, and my visa was approved in record time. Highly recommend their services for any international travel!",
            },
            {
                Name: "Ankit Mehta",
                ImageName: "Ankit-Mehta.webp",
                AllternateText: "...",
                Location: "@Pune",
                Comment: "Adorable Vacation helped me secure my Dubai visa within a very limited time frame. Mr. Noorul Ameen was extremely helpful and ensured everything was processed smoothly. I was stressed about the deadline, but his expert guidance and prompt service put me at ease. They’re truly visa specialists, and I couldn’t be happier with the support I received. Thank you!",
            },
            {
                Name: "Neha Kapoor",
                ImageName: "Neha-Kapoor.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "I applied for a student visa for my studies in Europe, and Adorable Vacation made the entire process hassle-free. Thanks to Mr. Akash Dutta, who was incredibly knowledgeable about all the requirements, my visa for Germany was approved much faster than expected. They even offered assistance for my future travel to the UK and Canada. The service was top-notch, and I highly recommend them!",
            },
            {
                Name: "Rohit Verma",
                ImageName: "Rohit-Verma.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "I reached out to Adorable Vacation for my Schengen visa, and they exceeded my expectations. Mr. Noorul Ameen was highly experienced and ensured my application was flawless. I got my visa well within the expected timeline, thanks to his amazing service. Highly recommended for anyone looking for reliable visa assistance!",
            },
            {
                Name: "Meenakshi Singh",
                ImageName: "Meenakshi-Singh.webp",
                AllternateText: "...",
                Location: "@Delhi",
                Comment: "Adorable Vacation provided exceptional service when I needed a Dubai visa urgently. Mr. Akash Dutta and Mr. Noorul Ameen were both extremely helpful, and their knowledge made the process quick and stress-free. Not only did they help me with Dubai, but they also assisted in securing visas for my upcoming trips to Thailand and Malaysia. I appreciated their guidance every step of the way. If you're looking for visa services for any international destination, Adorable Vacation is the best!",
            },
        ]

        this.setState({
            HotPackagesData: hotPackageDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <>
             <Helmet>
                    <title>Expert Visa Consultancy Services for Hassle-Free Travel with Adorable Vacation</title>
                    <meta name="description" content="Adorable Vacation LLP offers expert visa consultancy services, delivering personalized assistance to ensure a smooth and hassle-free process for securing your travel documents. For more details call +91 9147061493" />
                </Helmet>
                <MainHeader />
                <VisaServices 
                    HotPackagesData={this.state.HotPackagesData}
                    ReviewersData={this.state.ReviewersData}
                />
                <FooterDesktop />
            </>
        );
    }
}

export default VisaPage;