import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from "../Sikkim_Darjeeling/InclusionExclusion_SilkroutePackageKolkata";
import TopSearch from '../Sikkim_Darjeeling/TopSearches_SilkroutePackageTourKolkata';
import ThingToPack from "./Thing_To_Pack_SilkroutePackageTourKolkata";
class Silkroute_PackageTourKolkata extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: 'tab1',
      daywiseActiveTab: 'day1',
      currentPage: 1,
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  handleDayWiseTabClick = (tab) => {
    this.setState({
      daywiseActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };
  handlePageChange = (direction) => {
    this.setState((prevState) => {
      const newPage = prevState.currentPage + direction;
      return { currentPage: newPage };
    });
  };
  render() {
    const rows = [
      { date: '24th Sept - 30th Sept', availability: 'Available', price: '84,500' },
      { date: '01st Oct - 07th Oct', availability: 'Available', price: '84,500' },
      { date: '04th Oct - 10th Oct', availability: 'Available', price: '84,500' },
      { date: '08th Oct - 14th Oct', availability: 'Available', price: '84,500' },
      { date: '11th Oct - 17th Oct', availability: 'Available', price: '84,500' },
      { date: '15th Oct - 21st Oct', availability: 'Available', price: '84,500' },
      { date: '18th Oct - 24th Oct', availability: 'Available', price: '84,500' },
      { date: '22nd Oct - 28th Oct', availability: 'Available', price: '84,500' },
      { date: '25th Oct - 31st Oct', availability: 'Available', price: '84,500' },
      { date: '29th Oct - 04th Nov', availability: 'Available', price: '84,500' },
      { date: '1st Nov - 07th Nov', availability: 'Available', price: '84,500' },
      { date: '05th Nov - 11th Nov', availability: 'Available', price: '84,500' },
    ];

    const rowsPerPage = 6;
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
    const { customActiveTab, daywiseActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-tour-packages-from-kolkata-adsr.webp";
    const packageTopBanner2ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-package-tour-from-kolkata-adsr.webp";
    const sightseen1ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/North-Sikkim-gangtok-package-Tour.webp";
    const sightseen2ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/North-Sikkim-package-Tour-Silkroute.webp";
    const sightseen3ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/North-Sikkim-silkroute-Tour-package.webp";
    const sightseen4ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/North-Sikkim-Tour-Package-from-NJP.webp";
    const sightseen5ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/North-Sikkim-Tour-Pakage-from-Siliguri.webp";
    const sightseen6ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/North-Sikkim-Package-Tour-with-Silkroute.webp";
    const sightseen7ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/North-Sikkim-Tour-Itinery-Packages.webp";
    const sightseen8ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/North-Sikkim-Tour-Package-Cost-with-Silkroute.webp";
    const sightseen9ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/North-Sikkim-Tour-Packages-with-Silkroute.webp";
    const navDay1ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-package-tour-from-njp-station-with-adorable-vacation.webp";
    const navDay2ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-package-tour-from-kolkata-with-adorable-vacation.webp";
    const navDay3ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-package-tour-with-zuluk-with-adorable-vacation.webp";
    const navDay4ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-package-tour-with-nathang-valley.webp";
    const navDay5ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-package-tour-from-rongpokhola-with-adorable-vacation.webp";
    const navDay6ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/North-Sikkim-Tour-Package-with-Silkroute.webp";
    const sightseen10ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-package-from-kolkata-adsr-2.webp";
    const sightseen11ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-package-from-kolkata-adsr-5a.webp";
    const sightseen12ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-package-from-kolkata-adsr-3a.webp";
    const sightseen13ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-package-from-kolkata-adsr-2a.webp";
    const sightseen14ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-package-from-kolkata-adsr-9a.webp";
    const sightseen15ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-package-from-kolkata-adsr-4a.webp";
    const sightseen16ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-package-from-kolkata-adsr-8.webp";
    const sightseen17ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-package-from-kolkata-adsr-7.webp";
    const sightseen18ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-package-from-kolkata-adsr-4.webp";
    const sightseen19ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-package-from-kolkata-adsr-1a.webp";
    const sightseen20ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-package-from-kolkata-adsr-5.webp";
    const sightseen21ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-package-from-kolkata-adsr-2a.webp";
    const sightseen22ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-package-from-kolkata-adsr-1.webp";
    const sightseen23ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-package-from-kolkata-adsr-6.webp";
    const train1ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-tour-with-vande-bharat-express-howrah-njp-2.webp";
    const train2ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-tour-with-vande-bharat-express-howrah-njp-1.webp";
    const train3ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-tour-with-vande-bharat-express-howrah-njp-3.webp";
    const train4ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-tour-with-vande-bharat-express-howrah-njp-4.webp";
    const train5ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-tour-with-vande-bharat-express-howrah-njp-5.webp";
    const train6ImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/silk-route-tour-with-vande-bharat-express-howrah-njp-6.webp";
    const CallButtonSoumyaImageURL = ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/talk-to-silk-route-expert.webp";

    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };


    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL =
        ApiURL.SilkRoute_Tour_Package_KolkataImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container">
                    <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center><strong>SILK ROUTE PACKAGE TOUR FROM KOLKATA</strong> - BEST DEAL 2025 SUMMER HOLIDAYS</center></h1>
                    <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>SILK ROUTE PACKAGE TOUR FROM KOLKATA</strong> - BEST DEAL 2025 SUMMER HOLIDAYS</center>
                      <hr />
                    </h3>
                    <div className="row">
                      <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                        5 Destinations:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Sillerygaon
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Aritar
                        </a>{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Zuluk
                        </a>{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Nathang
                        </a>{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Rongpokhola
                        </a>
                      </div>
                      <div className="reviews mx-5">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                    <p className="p-2">
                      If you are looking for a memorable <strong>Silk Route package tour from Kolkata</strong>, you have come to the right place! Adorable Vacation is expert in conducting <a href="https://sites.google.com/view/silk-route-tour-from-kolkata/" target="_blank"><strong>Silk Route package from Kolkata</strong></a> with Best Price & Offer. Adorable offers various <strong>Silk Route Group Tour</strong> during Summer. <strong>Silk Route Summer Special Group Tour Booking</strong> already started. So, connect with us and book your <strong>Silk Route package</strong> before Group close.
                    </p>
                    <hr />
                    <br />
                    <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}>We offer the best <strong>Silk Route Tour Packages from Kolkata</strong><br /><br /> For Booking <strong>Kolkata to Silk Route Package</strong> <a href="tel:+91-9147061467"> ☎ Call +91-91470-61467</a></h2>
                    <h2 className="content-mobile p-4" style={{ fontSize: "4.0vw" }}>We offer the best <strong>Silk Route Tour Packages from Kolkata</strong><br /><br /> For Booking <strong>Kolkata to Silk Route Package</strong> <a href="tel:+91-9147061467"> ☎ Call +91-91470-61467</a></h2>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DAY WISE SUMMARY FOR <strong>SILK ROUTE TOUR PACKAGES FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> DAY WISE SUMMARY FOR <strong>SILK ROUTE TOUR PACKAGES FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingOne"
                          >
                            <b>DAY 1 → </b>
                            <span>
                              PICKUP FROM NJP/BAGDOGRA TRANSFER TO SILLERYGAON
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTwo"
                          >
                            <b>DAY 2 → </b>
                            <span>SILLERYGAON TO ARITAR TRANSFER</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingThree"
                          >
                            <b>DAY 3 → </b>
                            <span>ARITAR TO ZULUK TOUR</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFour"
                          >
                            <b>DAY 4 → </b>
                            <span>ZULUK TO NATHANG TRANSFER</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingFive"
                          >
                            <b>DAY 5 → </b>
                            <span>NATHANG TO RONGPOKHOLA TOUR</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSix"
                          >
                            <b>DAY 6 → </b>
                            <span>RONGPOKHOLA TO NJP / SILIGURI TRANSFER</span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingSeven"
                          >
                            <b>DAY 7 → </b>
                            <span>PARO TO SILIGURI VIA PHUENTSHOLING </span>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="clickable"
                            data-target="panelsStayOpen-headingTen"
                          >
                            <b>DAY 8 → </b>
                            <span>SILIGURI TO BAGDOGRA TRANSFER</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>INCLUSIONS : <strong>SILK ROUTE PACKAGE TOUR</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>INCLUSIONS : <strong>SILK ROUTE PACKAGE TOUR</strong></h4>
                </div>
                <div className="card-body">
                  <ul>
                    <li>Accommodation in the listed Hotels / Homestays </li>
                    <li>All Meals (Breakfast / Lunch / Evening Snacks / Dinner) </li>
                    <li>All transfers in an exclusive non-ac vehicle (NJP-NJP) </li>
                    <li>Driver allowances, fuel, toll tax </li>
                    <li>All permits and applicable taxes </li>
                  </ul>
                  <br /> <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen1ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                          <img src={sightseen2ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                          <img src={sightseen3ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /> <br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DURATION FOR <strong>SILK ROUTE TOUR PACKAGES FROM KOLKATA</strong> : 5 NIGHTS | 6 DAYS</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DURATION FOR <strong>SILK ROUTE TOUR PACKAGES FROM KOLKATA</strong> : 5 NIGHTS | 6 DAYS</h4>
                </div>
                <div className="card-body">
                  <h4>DESTINATIONS COVERED IN <strong>5N/6D SILK ROUTE TOUR PACKAGE FROM KOLKATA</strong> :<br /><br /> <span className="tomato">SILLERYGAON [1N] | ARITAR [1N] | ZULUK [1N] | NATHANG [1N] | RONGPOKHOLA [1N]</span> <br /><br /><br /></h4>
                  <h4>PICKUP & DROP POINT FOR <strong>SILK ROUTE TOUR PACKAGES</strong> : <br /><br /><span className="tomato">NJP STATION / SILIGURI STATION / SILIGURI BUS STOP </span></h4>
                  <br /><br />
                  <div id="no-more-tables" itemtype="https://schema.org/Table">

                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr><td>Duration for <strong>Silk Route Tour Packages</strong></td><td>4N | 5D</td></tr>
                        <tr><td><strong>4N/5D Silk Route Tour Package Cost</strong></td><td>&#8377;&nbsp; 6,850/P</td></tr>
                        <tr><td><strong>5N/6D Silk Route Tour Package Cost</strong></td><td>&#8377;&nbsp; 7,850/P</td></tr>
                        <tr><td><strong>Silk Route Group Tour Date</strong></td><td><b>22 Feb | 8, 14, 22 March</b></td></tr>
                        <tr><td>Pickup & Drop Point for <strong>Silk Route Tour Packages</strong></td><td>NJP Stn / Siliguri Stn</td></tr>
                        <tr><td>Nearest Rail Station from Silk Route</td><td>NJP / Siliguri Station</td></tr>
                        <tr><td>Nearest Bus Stand from Silk Route</td><td>Tenzing Norgay Bus Terminus, Siliguri</td></tr>
                        <tr><td>Nearest Airport from Silk Route</td><td>Bagdogra International Airport [IXB]</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen4ImageURL} alt="North-Sikkim-Tour-Package-with-Silkroute" />
                          <img src={sightseen5ImageURL} alt="North-Sikkim-Package-Tour" />
                          <img src={sightseen6ImageURL} alt="Sightseeing 6" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                  <table style={{ width: '100%', textAlign: 'center' }}>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <a href='tel:9147061470'><img src={CallButtonSoumyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /> <br />
                </div>
              </div>

              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>SILK ROUTE PACKAGE TOUR COST FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>SILK ROUTE PACKAGE TOUR COST FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables" itemtype="https://schema.org/Table">

                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr>
                          <th>SILK ROUTE PACKAGE CATEGORY</th>
                          <th>SILK ROUTE TOUR DURATION</th>
                          <th>SILK ROUTE TOUR COST</th>
                          <th>BOOK NOW</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Standard Package</td>
                          <td>4 Nights 5 Days</td>
                          <td>Rs. 6,850/P (based on 8 pax)</td>
                          <td><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                            <i className="fab fa-whatsapp"></i> BOOK NOW
                          </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Standard Package</td>
                          <td>5 Nights 6 Days</td>
                          <td>Rs. 7,699/P (based on 8 pax)</td>
                          <td><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                            <i className="fab fa-whatsapp"></i> BOOK NOW
                          </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Deluxe Package</td>
                          <td>5 Nights 6 Days</td><td>Rs. 9,999/P (based on 8 pax)</td>
                          <td><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                            <i className="fab fa-whatsapp"></i> BOOK NOW
                          </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>PLACES TO EXPLORE IN <strong>SILK ROUTE TOUR FROM KOLKATA</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>PLACES TO EXPLORE IN <strong>SILK ROUTE TOUR FROM KOLKATA</strong>?</h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Delo Park from Ramdhura</td>
                          <td style={{ fontSize: "16px" }}>Jalsa Bungalow</td>
                          <td style={{ fontSize: "16px" }}>Silent Valley</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Soft trek to Ramiteydara Viewpoint</td>
                          <td style={{ fontSize: "16px" }}>Ariter or Lampokhri Lake</td>
                          <td style={{ fontSize: "16px" }}>Mankhim Viewpoint</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Lingtam Monastery</td>
                          <td style={{ fontSize: "16px" }}>Kuekhola Falls on Old Silk Route</td>
                          <td style={{ fontSize: "16px" }}>Thambi Viewpoint</td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>Old Baba Mandir</td>
                          <td style={{ fontSize: "16px" }}>War Memorial at Tukla Valley</td>
                          <td style={{ fontSize: "16px" }}>Kupup Lake or Elephant Lake</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <br /><br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen22ImageURL} alt="North-Sikkim-Tour-Package-with-Silkroute" />
                          <img src={sightseen16ImageURL} alt="North-Sikkim-Package-Tour" />
                          <img src={sightseen23ImageURL} alt="Sightseeing 6" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WE OFFER BEST <strong>SILK ROUTE PACKAGE TOUR FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WE OFFER BEST <strong>SILK ROUTE PACKAGE TOUR FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <p>The <strong>Silk Route package tour from Kolkata</strong> is an ideal summer getaway for those looking to escape the heat and enjoy the serene beauty of the Eastern Himalayas. Nestled in the lesser-known hills of Sikkim, the <strong>Silk Route tour</strong> offers a perfect blend of tranquility, adventure, and natural beauty. With its cool weather, peaceful environment, and stunning landscapes, it provides a refreshing break from the bustling city life of Kolkata, especially during the summer months when the city can become sweltering. The <strong>Silk Route tour package from Kolkata</strong> offers you a chance to explore charming villages, pristine lakes, and breathtaking views of the mighty Mount Kanchenjunga, all within a few days of travel from Kolkata.<br /><br /></p>

                  <p>One of the best times to visit the Silk Route is between November and December. During these months, the skies are crystal clear, offering unobstructed views of the towering Kanchenjunga and other surrounding snow-capped peaks. The crisp air and bright sunshine during this period make it perfect for sightseeing and photography. The region is less crowded during this time, so you can enjoy the natural beauty and peaceful environment without the usual tourist crowds. The clear skies and the occasional light snowfall add a magical touch to the landscapes, making it an unforgettable experience.<br /><br /></p>

                  <p>Some of the key destinations that you’ll visit on your <strong>Silk Route package tour from Kolkata</strong> include Sillerygaon, Aritar, Zuluk, Nathang, and Rongpokhola. Sillerygaon, known as the "New Darjeeling", offers panoramic views of Kanchenjunga, while Aritar is famous for its beautiful Lampokhari Lake and traditional culture. Zuluk, perched high at 10,000 feet, is known for its winding roads and spectacular views of the Eastern Himalayas. Nathang, a high-altitude meadow, offers a serene environment, while Rongpokhola, with its riverside setting, is perfect for nature walks and photography. Each destination on the Silk Route presents unique experiences, from cultural immersion to breathtaking mountain views.<br /><br />

                    In conclusion, the <strong>Silk Route tour package from Kolkata</strong> is an excellent choice for anyone looking for a peaceful, scenic, and culturally rich holiday. Whether you're looking to enjoy the clear skies and mesmerizing mountain views in November and December or simply seeking a cool escape from the summer heat, the <strong>Silk Route package tour from Kolkata</strong> offers an exceptional travel experience. From hidden gems like Sillerygaon to the stunning landscapes of Zuluk and Nathang, a journey along the Silk Route promises to be a memorable adventure in the heart of Sikkim. <br /><br /></p>

                  <div id="no-more-tables" itemtype="https://schema.org/Table">

                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf"><tr>
                        <th>PLACES</th>
                        <th>ALTITUDE</th>
                        <th>CO-ORDINATES</th>
                      </tr>
                      </thead>
                      <tbody><tr><td>SilleryGaon</td><td>6,000 ft</td><td>27.1396° N, 88.5804° E</td></tr>
                        <tr><td>Aritar</td><td>1,498 m</td><td>27.1879° N, 88.6748° E</td></tr>
                        <tr><td>Zuluk</td><td>2,865 m</td><td>27.2518° N, 88.7775° E</td></tr>
                        <tr><td>Nathang</td><td>13,500 ft</td><td>27.2986° N, 88.8173° E</td></tr>
                        <tr><td>Rongpokhola</td><td>3,000 ft</td><td>27.2215° N, 88.6892° E</td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHY ADORABLE VACATION IS THE BEST CHOICE FOR BOOKING <strong>SILK ROUTE PACKAGE TOUR</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY ADORABLE VACATION IS THE BEST CHOICE FOR BOOKING <strong>SILK ROUTE PACKAGE TOUR</strong>?</h4>
                </div>
                <div className="card-body">

                  <li>We have pre-purchased room inventory in all key Silk Route destinations like Sillerygaon, Zuluk, Nathang, and Rongpokhola. <br /><br /></li>
                  <li>Based on your <strong>Silk Route tour booking from Kolkata</strong>, we ensure you get a hill-facing room with beautiful views, depending on your booking date. <br /><br /></li>
                  <li>We offer both premium and deluxe hotel/home stay options with top-notch facilities and amenities during your <strong>Silk Route tour from Kolkata</strong>. <br /><br /></li>
                  <li>Deluxe homestays in Zuluk, Gnathang, Rongpokhola, and Sillery-Gaon are part of our offerings, with excellent comfort and hospitality. <br /><br /></li>
                  <li>Vehicles are provided from NJP, Bagdogra, Siliguri, Rongpo, or Rongli, depending on your <strong>Silk Route tour package booking from Kolkata</strong>. <br /><br /></li>
                  <li>For a couple or family of three with a standard package, we provide a Wagon-R or Santro.<br /><br /></li>
                  <li>For <strong>Silk Route group tours</strong>, we arrange vehicles like Sumo or Bolero. For small deluxe groups, we offer Xylo or Scorpio. <br /><br /></li>

                  <li>The choice of vehicle depends on your budget and the number of travelers. <br /><br /></li>
                  <li>We have a dedicated team and a "war room" specifically for <strong>Silk Route Tour</strong> to ensure uninterrupted service. <br /><br /></li>
                  <li>Our team offers guidance on peak season rush, weather, and off-season conditions to ensure safety and value for money during your tour. <br /><br /></li>
                  <li>Adorable Vacation specializes in <strong>Silk Route tour from Kolkata</strong>, and we have years of experience in curating and managing tours to this region. <br /><br /></li>
                  <li>We personally visit and certify every hotel and homestay to ensure top-quality accommodation and service for our guests. <br /><br /></li>
                  <li>We organize <strong>Silk Route group tour</strong> during popular times like summer vacations, Durga Puja holidays, Christmas and New Year Holidays ensuring a comfortable and enjoyable journey. <br /><br /></li>
                  <li>Our team provides complete day-to-day assistance, from NJP to NJP, during your <strong>Silk Route tour</strong>. <br /><br /></li>
                  <li>Every trip is managed directly by Adorable Vacation without involving sub-agents, ensuring a seamless and personalized experience. <br /><br /></li>

                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>QUICK FACTS NEED TO KNOW FOR BOOKING <strong>SILK ROUTE TOUR PACKAGES FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>QUICK FACTS NEED TO KNOW FOR BOOKING <strong>SILK ROUTE TOUR PACKAGES FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <p><br />The Silk Route is a remote yet pristine <strong>Sikkim offbeat destination</strong>, offering a unique experience but with limited modern facilities and amenities. Some travelers may have high expectations regarding food, facilities, and hotel rooms in this area. So, it's important to understand a few basic facts before you proceed with your <strong>Silk Route tour package booking from Kolkata</strong>.<br /><br /></p>

                  <li>When you book a <strong>Silk Route package tour from Kolkata</strong>, don't expect modern amenities. Most of the destinations along the route are remote villages, far from urban facilities. Basic facilities like western toilets, running water, hot meals (usually egg curry and local vegetables) are available, but luxuries like TV, internet, geysers, or mineral water are not. <br /><br /></li>

                  <li>Staying in Nathang Valley is not advisable for children or elderly travelers. Nathang, located at an altitude of 13,200 ft, is popular among Silk Route travelers and often referred to as the "<b>Ladakh of East India</b>" due to its similarity to Leh Ladakh. However, due to the extreme altitude and cold, travelers may experience dizziness, headaches, and nausea. Temperatures can drop below freezing at night. We recommend that elderly travelers only visit Nathang during the day and return to a lower altitude after a short visit. Carrying a portable oxygen cylinder for safety is also advised. <br /><br /></li>

                  <li>For your <strong>Silk Route tour packages from Kolkata</strong>, always carry original and photocopies of your ID proofs (such as voter ID, passport, or driver's license), along with at least two passport-sized photos. If you’re traveling with children, remember to carry their birth certificates or school IDs. <br /><br /></li>

                  <li>During your <strong>Silk Route tour from Kolkata</strong>, it's important to carry essential supplies for emergencies. Pack dry fruits, nuts, chocolates, hot coffee, ginger cubes, and biscuits. These come in handy in case of roadblocks, landslides, or sudden weather changes. It's also a good idea to carry a portable oxygen cylinder and oxygen meter. In case of a serious emergency, the Indian Army and Border Roads Organisation (BRO) are always available to assist. Additionally, for high-altitude locations like Gnathang Valley, Elephant Lake, and Kupup, carry items like camphor, chocolates, and ginger cubes to help with breathing. If you’re on prescribed medication, don’t forget to bring it along. <br /><br /></li>

                  <li>Make sure to pack a first aid kit with medicine for fever, cold, cough, headaches, stomach issues, and general pain relief (such as paracetamol, ointment, band-aids, and Vaseline). Woolen clothes, gloves, hats, and wind-resistant gear are essential for high-altitude regions where sub-zero temperatures may occur. Carry enough cash in small denominations, as ATMs are unavailable after Rongli. Withdraw cash from NJP or Bagdogra before heading to the higher altitudes, as ATM links may fail in remote areas. GST will also be applicable to your <strong>Silk Route tour package cost</strong>. <br /><br /></li>

                  <li>Bikers should ensure they have all necessary documents for their vehicles. Failure to provide valid papers can result in difficulties during your <strong>Silk Route tour</strong>. Additionally, we recommend avoiding alcohol at high altitudes, but if you still wish to bring your own, make sure to carry your preferred brand with you, as options may be limited in remote areas. If you visit lower-altitude areas like Sillerygaon, Ramdhura, or Rongpokhola, be cautious of leeches. Lastly, remember that Sikkim is a plastic-free state, so please avoid littering.<br /><br /></li>

                  <li>Above all, enjoy your <strong>Silk Route tour packages</strong> with Adorable vacation. Don’t worry too much about what you have or haven't brought—just relax and soak in the beauty of this incredible journey. <br /><br /></li>

                  <br /> <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen7ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                          <img src={sightseen8ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                          <img src={sightseen9ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /> <br />
                </div>
              </div>
              <br /><br /><br />
              <table style={{ width: '100%', textAlign: 'center' }}>
                <tbody>
                  <tr>
                    <td className="image-cell">
                      <center><a href='tel:9147061470'><img src={CallButtonSoumyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a></center>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHEN IS THE BEST TIME TO EXPERIENCE SNOW DURING YOUR <strong>SILK ROUTE PACKAGE TOUR FROM KOLKATA</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHEN IS THE BEST TIME TO EXPERIENCE SNOW DURING YOUR <strong>SILK ROUTE PACKAGE TOUR FROM KOLKATA</strong>?</h4>
                </div>
                <div className="card-body">
                  <p>The <strong>Silk Route package tour</strong> is a popular choice for travelers who want to experience snow, especially during the winter season. The best time to visit for snowfall is from mid-December to the first week of March. This is when the area is covered in snow, making it a perfect winter getaway. Many Bengali tourists choose this time to plan their trips, as the snowfall season adds a magical touch to the region. Temperatures during this period can range from -5°C to 7°C, creating a beautiful winter landscape with snow-capped mountains and stunning snowy views. The crisp, fresh air and peaceful surroundings make this an ideal destination for those looking to escape the hustle and bustle of everyday life. If you're looking to enjoy the charm of snow and create lasting memories, the <strong>Silk Route tour package from Kolkata</strong> during the snowfall season is the perfect choice. <br /><br /></p>
                  <br /> <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen10ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                          <img src={sightseen11ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                          <img src={sightseen12ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /> <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHEN IS THE BEST TIME TO BOOK <strong>SILK ROUTE TOUR PACKAGES</strong>?</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHEN IS THE BEST TIME TO BOOK <strong>SILK ROUTE TOUR PACKAGES</strong>?</h4>
                </div>
                <div className="card-body">
                  <p>If you're thinking about going on a <strong>Sikkim offbeat trip</strong> with a <strong>Silk Route package tour from Kolkata</strong> booked from Adorable vacation - No1 Silk Route Tour Company, choosing the right time for your journey is important to ensure you have a memorable experience. Let’s look at the best times to book your <strong>Silk Route tour packages</strong>, based on the seasons of summer, monsoon, autumn, and winter. <br /><br /></p>


                  <h4>SUMMER [MARCH TO JUNE]</h4>
                  <p>Summer is a great time to book a <strong>Silk Route package tour from Kolkata</strong>, especially if you're interested in <strong>Sikkim offbeat packages</strong>. The weather is warm and pleasant, which makes it perfect for exploring attractions like Zuluk Zigzag and Aritar Lake. However, be prepared for rising temperatures, so remember to carry sunscreen, hats, and water. Make sure children and elderly travelers stay hydrated and take breaks to adjust to the altitude to avoid sickness. <br /><br /></p>


                  <h4>MONSOON [JULY TO SEPTEMBER]</h4>
                  <p>While the monsoon season brings lush greenery, it also comes with challenges for <strong>Silk Route tour packages</strong> due to the risk of landslides. However, if you enjoy an offbeat and adventurous experience, the monsoon can still be interesting. Be sure to check the road conditions before starting your journey, and pack rain gear and motion sickness medication. Take extra care with altitude issues, particularly for children and elderly travelers. <br /><br /></p>


                  <h4>AUTUMN [OCTOBER TO NOVEMBER]</h4> <p>Autumn is one of the best times to book a <strong>Silk Route tour package</strong>, as it offers clear skies and vibrant landscapes, perfect for exploring places like Nathang Valley, Kupup Lake, and Baba Mandir. This season is popular for <strong>Sikkim offbeat trips</strong>. Be sure to pack warm clothing for the cooler temperatures, and make a gradual ascent to prevent altitude sickness, especially for children and elderly travelers. <br /><br /></p>


                  <h4>WINTER [DECEMBER TO FEBRUARY]</h4>
                  <p>If you're looking for a magical winter experience, booking a <strong>Silk Route package tour from Kolkata</strong> during winter is the perfect choice. You can witness snowfall in Nathang Valley and enjoy the region's stunning beauty. However, winter travel requires extra caution due to icy roads and very cold temperatures. Be sure to pack warm clothing and take precautions against frostbite. For the safety and comfort of children and elderly travelers, keep them warm and consult healthcare professionals about altitude-related concerns. <br /><br /></p>


                  <p>In conclusion, the best time to book a <strong>Silk Route package tour from Kolkata</strong> depends on your preferences and the weather conditions you’re most comfortable with. Whether you're opting for a <strong>Sikkim offbeat package</strong> or a traditional <strong>Silk Route tour</strong>, each season offers something unique. With proper planning and precautions, your Silk Route journey will be an unforgettable adventure. <br /><br /><br /></p>
                  <div id="no-more-tables" itemtype="https://schema.org/Table">

                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr>
                          <th>Travel Months</th>
                          <th>Weather</th>
                          <th>Temperature</th>
                        </tr>
                      </thead>
                      <tbody><tr><td>April to June</td><td>Summer</td><td>9°C to 15°C</td></tr>
                        <tr><td>July to September</td><td>Monsoon</td><td>8°C to 10°C</td></tr>
                        <tr><td>October to March</td><td>Winter</td><td>-5° C to 4° C</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /> <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen14ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                          <img src={sightseen13ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                          <img src={sightseen15ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /> <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DISTANCE TO NOTE FOR <strong>SILK ROUTE TOUR PACKAGES FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DISTANCE TO NOTE FOR <strong>SILK ROUTE TOUR PACKAGES FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables" itemtype="https://schema.org/Table">

                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr>
                          <th><b>From</b></th>
                          <th><b>To</b></th>
                          <th><b>Distance</b></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Kolkata</td>
                          <td>NJP</td>
                          <td>14 hr 30 min (585.0 km) via NH12</td>
                        </tr>
                        <tr>
                          <td>Kolkata</td>
                          <td>Siliguri</td>
                          <td>14 hr 9 min (581.4 km) via NH12</td>
                        </tr>
                        <tr>
                          <td>Kolkata</td>
                          <td>Bagdogra</td>
                          <td>13 hr 58 min (573.7 km) via NH12</td>
                        </tr>
                        <tr>
                          <td>NJP</td>
                          <td>SilleryGaon</td>
                          <td>3 hr 58 min (94.4 km) via NH10 and Rishi Rd</td>
                        </tr>
                        <tr>
                          <td>SilleryGaon</td>
                          <td>Aritar</td>
                          <td>1 hr 53 min (38.8 km) via NH 717A</td>
                        </tr>
                        <tr>
                          <td>Aritar / Mankhim</td>
                          <td>Zuluk</td>
                          <td>1 hr 25 min (45.1 km) via NH 717B</td>
                        </tr>
                        <tr>
                          <td>Zuluk</td>
                          <td>Nathang</td>
                          <td>54 min (23.4 km) via NH 717B</td>
                        </tr>
                        <tr>
                          <td>Nathang</td>
                          <td>Rongpokhola</td>
                          <td>2 hr 47 min (61.0 km) via Rongli - Chujachen Rd</td>
                        </tr>
                        <tr>
                          <td>Rongpokhola</td>
                          <td>NJP</td>
                          <td>122 km via NH10</td></tr>
                        <tr>
                          <td>NJP</td>
                          <td>Ramdhura</td>
                          <td>2 hr 48 min (73.2 km) via NH10</td>
                        </tr>
                        <tr>
                          <td>Ramdhura</td>
                          <td>Reshikhola</td>
                          <td>1 hr 40 min (34.5 km) via NH 717A</td>
                        </tr>
                        <tr>
                          <td>Reshikhola</td>
                          <td>Zuluk</td>
                          <td>2 hr 4 min (48.8 km) via Rhenock - Rongli Hwy</td>
                        </tr>
                        <tr>
                          <td>Lamahata</td>
                          <td>Zuluk</td>
                          <td>3 hr 31 min (93.1 km) via NH 717B</td>
                        </tr>
                        <tr>
                          <td>Phuentsholing</td>
                          <td>Zuluk</td>
                          <td>6 hr 45 min (235.7 km) via NH 17</td>
                        </tr>
                        <tr>
                          <td>Darjeeling</td>
                          <td>Zuluk</td>
                          <td>4 hr 26 min (113.6 km) via Rishi Rd and NH 717B</td>
                        </tr>
                        <tr>
                          <td>Kalimpong</td>
                          <td>Zuluk</td>
                          <td>3 hr 22 min (87.2 km) via NH 717A and NH 717B</td>
                        </tr>
                        <tr>
                          <td>Siliguri</td>
                          <td>Zuluk</td>
                          <td>4 hr 56 min (129.6 km) via NH10 and NH 717B</td>
                        </tr>
                        <tr>
                          <td>Siliguri</td>
                          <td>Zuluk</td>
                          <td>4 hr 56 min (129.6 km) via NH10 and NH 717B</td>
                        </tr>
                        <tr>
                          <td>Nathan</td>
                          <td>Rolep</td>
                          <td>66 km</td>
                        </tr>
                        <tr>
                          <td>Rolep</td>
                          <td>NJP</td>
                          <td>110 km</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /> <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen16ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                          <img src={sightseen17ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                          <img src={sightseen18ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /> <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>POPULAR TRAIN TICKETS TO BOOK FOR <strong>SILK ROUTE TOUR FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>POPULAR TRAIN TICKETS TO BOOK FOR <strong>SILK ROUTE TOUR FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <p>Travelling <strong>Silk Route from Kolkata</strong> is very easy and cost-effective, especially with the option of a train journey. The best way to travel Silk Route is by train, and there are numerous trains departing daily or weekly from Sealdah and Howrah stations. Whether you prefer the comfort of an AC 3 Tier coach or the luxury of AC 1st Class, there are plenty of train options available to suit your budget and preferences. Booking your train tickets for the <strong>Silk Route package tour from Kolkata</strong> ensures a smooth and convenient journey to the breathtaking destinations of East Sikkim along the historic route. Following are the details of some of the trains from Sealdah or Howrah junction for guests' reference.</p><br /><br />
                  <div id="no-more-tables" itemtype="https://schema.org/Table">

                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr>
                          <th>Train No</th>
                          <th>Train Name</th>
                          <th>Dep. Stn</th>
                          <th>Dep. Time</th>
                          <th>Arr. Stn</th>
                          <th>Arr. Time</th>
                          <th>Frequency</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>22301</td>
                          <td>Vande Bharat Express</td>
                          <td>Howrah (HWH)</td>
                          <td>05:55</td>
                          <td>New Jalpaiguri Jn (NJP)</td>
                          <td>13:25</td>
                          <td>Except Wednesday</td>
                        </tr>
                        <tr>
                          <td>22302</td>
                          <td>Vande Bharat Express</td>
                          <td>New Jalpaiguri Jn (NJP)</td>
                          <td>15:05</td>
                          <td>Howrah (HWH)</td>
                          <td>22:35</td>
                          <td>Except Wednesday</td>
                        </tr>
                        <tr>
                          <td>12343</td>
                          <td>Darjeeling Mail</td>
                          <td>Sealdah (SDAH)</td>
                          <td>22:05</td>
                          <td>New Jalpaiguri Jn (NJP)</td>
                          <td>08:15</td>
                          <td>Daily</td>
                        </tr>
                        <tr>
                          <td>12377</td>
                          <td>Padatik Express</td>
                          <td>Sealdah (SDAH)</td>
                          <td>23:00</td>
                          <td>New Jalpaiguri Jn (NJP)</td>
                          <td>09:15</td>
                          <td>Daily</td>
                        </tr>
                        <tr>
                          <td>13149</td>
                          <td>Kanchan Kanya Express</td>
                          <td>Sealdah (SDAH)</td>
                          <td>20:30</td>
                          <td>New Mal Jn(NMZ)</td>
                          <td>09:31</td>
                          <td>Daily</td>
                        </tr>
                        <tr>
                          <td>13147</td>
                          <td>UttarBanga Express</td>
                          <td>Sealdah (SDAH)</td>
                          <td>19:35</td>
                          <td>Falakata (FLK)</td>
                          <td>08:38</td>
                          <td>Daily</td>
                        </tr>
                        <tr>
                          <td>13141</td>
                          <td>Teesta Torsa Express</td>
                          <td>Sealdah (SDAH)</td>
                          <td>13:40</td>
                          <td>New Maynaguri (NMX)</td>
                          <td>04:22</td>
                          <td>Daily</td>
                        </tr>
                        <tr>
                          <td>12345</td>
                          <td>SaraiGhat Express</td>
                          <td>Howrah (HWH)</td>
                          <td>15:50</td>
                          <td>New Jalpaiguri (NJP)</td>
                          <td>01:40</td>
                          <td>Daily</td>
                        </tr>
                        <tr>
                          <td>12517</td>
                          <td>Garib Rath</td>
                          <td>Kolkata (KOOA)</td>
                          <td>21:40</td>
                          <td>New Jalpaiguri (NJP)</td>
                          <td>07:45</td>
                          <td>Sun, Thurs</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /> <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={train2ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                          <img src={train1ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                          <img src={train3ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /> <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>FOR <strong>SILK ROUTE TOUR PACKAGE FROM KOLKATA</strong>, YOU CAN BOOK HOWRAH TO NJP VANDE BHARAT EXPRESS</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>FOR <strong>SILK ROUTE TOUR PACKAGE FROM KOLKATA</strong>, YOU CAN BOOK HOWRAH TO NJP VANDE BHARAT EXPRESS</h4>
                </div>
                <div className="card-body">

                  <div id="no-more-tables" itemtype="https://schema.org/Table">

                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr>
                          <th>Station</th>
                          <th>Arrival <br /> Departure</th>
                          <th>Halt</th>
                          <th>Distance</th>
                        </tr></thead>
                      <tbody><tr><td>Howrah Jn [HWH]</td><td>Starts <br />05:55</td><td>Howrah (HWH)</td><td>0 km</td></tr>
                        <tr><td>Bolpur Shantiniketan [BHP]</td><td>07:43 <br />07:45</td><td>2 min</td><td>145 km</td></tr>
                        <tr><td>Malda Town [MLTD]</td><td>10:32 <br />10:35</td><td>3 min</td><td>329.5 km</td></tr>
                        <tr><td>Barsoi Jn [BOE]</td><td>11:50 <br />11:52</td><td>2 min</td><td>419.7 km</td></tr>
                        <tr><td>New Jalpaiguri Jn [NJP]</td><td>13:25<br />ends</td><td></td><td>564.6</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /> <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={train4ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                          <img src={train5ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                          <img src={train6ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /> <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>NEW JALPAIGURI TO HOWRAH JUNCTION VANDE BHARAT 22302 TRAIN SCHEDULE [EXCEPT WEDNESDAY]</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>NEW JALPAIGURI TO HOWRAH JUNCTION VANDE BHARAT 22302 TRAIN SCHEDULE [EXCEPT WEDNESDAY]</h4>
                </div>
                <div className="card-body">

                  <div id="no-more-tables" itemtype="https://schema.org/Table">

                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr>
                          <th>Station</th>
                          <th>Arrival <br /> Departure</th>
                          <th>Halt</th>
                          <th>Distance</th>
                        </tr>
                      </thead>
                      <tbody><tr><td>New Jalpaiguri Jn [NJP]</td><td>Starts <br />15:05</td><td></td><td>0 km</td></tr>
                        <tr><td>Barsoi Jn [BOE]</td><td>16:44 <br />16:46</td><td>2 min</td><td>145 km</td></tr>
                        <tr><td>Malda Town [MLTD]</td><td>17:50 <br />17:53</td><td>3 min</td><td>235 km</td></tr>
                        <tr><td>Bolpur Shantiniketan [BHP]</td><td>20:22 <br />20:24</td><td>2 min</td><td>420 km</td></tr>
                        <tr><td>Howrah Jn [HWH]</td><td>22:35 <br />ends</td><td>-</td><td>565 km</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br /><br />
                  <table style={{ width: '100%', textAlign: 'center' }}>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <center><a href='tel:9147061470'><img src={CallButtonSoumyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a></center>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="row py-5">
                <div className="col-md-12">
                  <div className="site-heading text-center">
                    <h2>
                      DAY WISE <strong><span>SILK ROUTE TOUR PACKAGE FROM KOLKATA </span></strong>
                    </h2>
                    <h4>5 Nights 6 Days <strong>Silk Route Tour Package Itineraries</strong></h4>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="accordion"
                  id="accordionPanelsStayOpenExample"
                >
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day1"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        <h4> DAY 01: NJP TO SILLERYGAON</h4>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div className="accordion-body">
                        <p>On the first day of your <strong>Silk Route package tour</strong>, you will be greeted upon arrival at NJP/IXB and then head towards Sillery Gaon. Sillery Gaon is a small village, located about 10 km from Delo in Kalimpong, offering a breathtaking view of Mount Kanchenjunga and the Teesta River flowing below. The village sits at an altitude of 6,000 ft and is surrounded by dense pine forests and cinchona plantations, providing a peaceful atmosphere. Though the village is not very developed and lacks luxurious accommodations, its natural beauty will more than make up for that. Enjoy the tranquility of Sillery Gaon during your overnight stay at a local home stay. <br /></p>
                        <br /> <br />
                        <div className="content-desktop">
                          <center><img src={navDay1ImageURL} alt="mumbai-to-bhutan-tour-packages-with-direct-flights-with-adorable-vacation.webp" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="mumbai-to-bhutan-tour-packages-with-direct-flights-with-adorable-vacation.webp" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day2"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        <h4>DAY 02: SILLERYGAON TO ARITAR</h4>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTwo"
                    >
                      <div className="accordion-body">
                        <p>After breakfast, you will head towards Aritar, a beautiful place located near Lampokhri Lake (altitude 1,498 m). This is the highest lake in Sikkim that offers a boating facility, so enjoy a peaceful boating session after lunch. On the way, you will visit several attractions, including Mankhim Temple, Mankhim Viewpoint, Aritar Monastery, and Aritar Dakbunglow. After sightseeing, check into your <strong>HomeStay in Aritar / Mankhim</strong> for the night. <br /></p>
                        <br /><br />
                        <div className="content-desktop">
                          <center><img src={navDay2ImageURL} alt="mumbai-to-bhutan-tour-packages-booking-with-direct-flights-with-adorable-vacation.webp" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="mumbai-to-bhutan-tour-packages-booking-with-direct-flights-with-adorable-vacation.webp" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day3"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 03: ARITAR TO <strong>ZULUK TOUR</strong></h4>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseThree"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingThree"
                    >
                      <div className="accordion-body">
                        <p>This is one of the most important days of your <strong>Silk Route Tour</strong>. After breakfast, head towards Zuluk, passing through Rongli Dam, Lingtam, and Keukhela Falls. <br /><br />

                          <b>Rongli [Altitude: 5200 ft.]</b> - A small town on the Rongli river, known for its Inner Line Permits for Nathula visits. <br /><br />

                          <b>Rongli Dam</b> - A concrete dam on the Rongli River, holding 45,200 cubic meters of water. <br /><br /> <b>Lingtam [Altitude 5082 ft.]</b> - A peaceful village surrounded by hills, known for its pleasant weather. You can also visit Lingtam Monastery. <br /><br />

                          <b>Keukhela Falls</b> - A 100-meter waterfall between Lingtam and Padamchen, surrounded by scenic nature. <br /><br />

                          <b>Zuluk [Altitude: 7000 ft.]</b> - A small village in East Sikkim, popular for its untouched natural beauty and breathtaking views of the Himalayan mountains. Check into your <strong>Zuluk Homestay</strong> for the night. <br /><br /></p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay3ImageURL} alt="mumbai-to-bhutan-tour-package-cost-with-direct-flights-with-adorable-vacation.webp" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="mumbai-to-bhutan-tour-package-cost-with-direct-flights-with-adorable-vacation.webp" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day4"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFour"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 04: - ZULUK TO NATHANG</h4>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseFour"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFour"
                    >
                      <div className="accordion-body">
                        <p>Wake up early to enjoy a sunrise view from Lungthung Viewpoint. After breakfast, drive towards Nathang Valley, passing through various scenic spots like the Zig Zag Road, Thambi Viewpoint, Lungthung, Tukla Valley, Jelep La Pass, and Old Baba Mandir. <br /><br />

                          <b>Zig Zag Road</b> - This winding road with many sharp turns offers beautiful views of Zuluk. It is also one of the top 10 most dangerous roads in the world. <br /><br />

                          <b>Thambi Viewpoint [Altitude: 11,200 ft.]</b> - Offering stunning views of Mount Kanchenjunga and surrounding snow-capped peaks. <br /><br />

                          <b>Lungthung [Altitude: 11,500 ft.]</b> - A small village with panoramic views of Kanchenjunga. <br /><br />

                          <b>Tukla Valley [Altitude: 12,500 ft.]</b> - A valley known for its memorial in honor of British soldiers from the War of Tukla. <br /><br />

                          <b>Jeelpla Pass View [Altitude: 13,999 ft.]</b> - A mountain pass connecting India to Tibet, though now prohibited for civilians. <br /><br />

                          <b>Old Baba Mandir [Altitude: 13,123 ft.]</b> - A temple dedicated to Baba Harbhajan Singh, a martyr of the Indian Army. <br /><br />

                          <b>Nathang Valley [Altitude: 13,200 ft.]</b> - Known as the "Ladakh of East India" Nathang Valley is a stunning destination surrounded by snow-capped peaks. The landscape changes throughout the year, from golden in autumn to snowy in winter. Check into your accommodation in Nathang Valley for the night. <br /><br /></p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay4ImageURL} alt="mumbai-to-bhutan-tour-package-itinerary-with-direct-flights-with-adorable-vacation.webp" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="mumbai-to-bhutan-tour-package-itinerary-with-direct-flights-with-adorable-vacation.webp" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day5"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFive"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 05: NATHANG TO RONGPOKHOLA</h4>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseFive"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFive"
                    >
                      <div className="accordion-body">
                        <p>After breakfast, travel to <strong>Rongpokhola</strong> (altitude 6,000 ft.) from Nathang Valley. Have lunch by the riverside and enjoy the soothing sounds of the Rangpo River. You can also enjoy fishing or bathing in the crystal-clear river. Check into your <strong>Rongpokhola Homestay</strong> for the night. <br /><br /></p>

                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay5ImageURL} alt="mumbai-to-bhutan-tour-package-plan-with-direct-flights-with-adorable-vacation.webp" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="mumbai-to-bhutan-tour-package-plan-with-direct-flights-with-adorable-vacation.webp" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id="day6"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSix"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 06: RONGPOKHOLA TO NJP / IXB</h4>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseSix"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSix"
                    >
                      <div className="accordion-body">
                        <p>On the last day of your <strong>Silk Route Tour</strong>, begin your journey back to NJP / IXB with wonderful memories of your trip. <br /><br /></p>
                        <br />
                        {/* <div className="content-desktop">
                          <center><img src={navDay6ImageURL} alt="mumbai-to-bhutan-tour-package-price-with-direct-flights-with-adorable-vacation.webp" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="mumbai-to-bhutan-tour-package-price-with-direct-flights-with-adorable-vacation.webp" /></center>
                        </div>
                        <br /> */}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">{<InclusionExclusion />}</div>
                </div>
              </div>

              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> TOP SEARCHES FOR <strong>SILK ROUTE TOUR OPERATOR NEAR ME</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> TOP SEARCHES FOR <strong>SILK ROUTE TOUR OPERATOR NEAR ME</strong></h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      {
                        <TopSearch />
                      }
                    </div>
                  </div>
                  <br /> <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen19ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                          <img src={sightseen20ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                          <img src={sightseen21ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /> <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> THINGS TO PACK FOR <strong>SILK ROUTE PACKAGE TOUR FROM KOLKATA</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> THINGS TO PACK FOR <strong>SILK ROUTE PACKAGE TOUR FROM KOLKATA</strong></h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      {
                        <ThingToPack />
                      }
                    </div>
                  </div>
                  <br /><br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen13ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                          <img src={sightseen15ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                          <img src={sightseen19ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /> <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DESTINATIONS TO COVER IN <strong>SILK ROUTE TOUR FROM KOLKATA</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> DESTINATIONS TO COVER IN <strong>SILK ROUTE TOUR FROM KOLKATA</strong></h3>
                </div>
                <div className="card-body">
                  <p>Welcome to an unforgettable <strong>Silk Route tour</strong>. Join us for a unique journey along the historic Silk Route with our carefully planned <strong>Silk Route Tour Package from Kolkata</strong>. We’ve selected a range of beautiful and culturally rich destinations to make your trip truly special. Get ready to explore hidden gems along this ancient trade route, full of natural beauty and rich heritage. <br /><br />

                    Our <strong>Silk Route tour package from Kolkata</strong> includes a variety of incredible tourist spots that highlight the essence of this famous route. From stunning mountain views to peaceful valleys, each stop has been chosen to provide a memorable experience. <br /><br />

                    As you embark on this amazing adventure, you'll visit a range of remarkable places that will leave you with unforgettable memories. Our tour packages make sure you don’t miss any of the must-see spots along the way. <br /><br /> Book your <strong>Silk Route Tour</strong> today and let our expert guides help you discover the hidden treasures of this ancient trade route. Get ready for a journey that will create memories to last a lifetime. <br /><br /></p>


                  <h4><b>Rongli: Discover the Charming Habitat En-Route to Zuluk Tour</b></h4>
                  <p>Rongli is a picturesque small village along the scenic route to Zuluk, offering a unique blend of natural beauty and cultural experiences. Situated next to the Rongli River, this peaceful destination is a great stop for travelers. It also serves as a key point for obtaining permits to visit the famous Nathula Pass. During your <strong>Silk Route Package Tour from Kolkata</strong>, enjoy the stunning views and local attractions of Rongli and its surroundings. <br /><br /><br /></p>


                  <h4><b>Experience the Charm of Zuluk: A Serene Village along the Historic Silk Road</b></h4>
                  <p>Located at 7,000 ft above sea level, Zuluk is a peaceful village that attracts travelers year after year. As part of the <strong>Silk Route Tour Packages from Kolkata</strong>, Zuluk offers visitors a chance to enjoy its natural beauty and cultural charm. Unlike commercial hotels, you can stay in cozy home stays offered by friendly locals, allowing you to experience the authentic life of the village. <br /><br /> Explore the breathtaking mountain views, winding roads, and captivating stories of this hidden gem. Zuluk’s peaceful environment offers a perfect escape into nature, providing both tranquility and adventure. <br /><br /><br /></p>


                  <h4><b>Discover Lingtam: A Charming Village on the Way to Zuluk</b></h4>
                  <p>On your <strong>Silk Route Tour Booking from Kolkata</strong>, you’ll pass through many quaint villages, including Lingtam. Located at 5,082 ft, Lingtam is a peaceful village that comes right after the Rongli permit post. The village is home to the beautiful Lingtam Monastery, which attracts many visitors who stop to admire its serene atmosphere. <br /><br />

                    Lingtam offers a calm and picturesque setting, perfect for relaxing and soaking in the natural beauty that surrounds you. It’s an ideal place for capturing memorable moments and embracing the peaceful vibes. <br /><br /><br /></p>


                  <h4><b>Thambi Viewpoint: Witness the Majestic Splendor of Mt. Kanchenjungha</b></h4>
                  <p>During your <strong>Silk Route Trip from Kolkata</strong>, you’ll reach the Thambi Viewpoint, where you can enjoy an incredible view of Mount Kanchenjungha. At an altitude of 11,500 ft, this viewpoint offers a stunning panoramic vista of the snow-capped Himalayan mountains. <br /><br /> Located along the Zig Zag Road, Thambi Viewpoint offers an amazing bird's eye view of the winding road below. It’s a must-see stop for nature lovers and adventure enthusiasts, as it provides a rare opportunity to admire the majestic Mt. Kanchenjungha in all its glory. <br /><br /><br /></p>


                  <h4><b>Explore Lungthung: A Scenic Stop on the Historic Trading Road</b></h4>
                  <p>As part of your Silk Route Holidays from Kolkata, you’ll visit Lungthung, a beautiful village situated at 11,600 ft on the ancient Silk Road. This picturesque stop offers breathtaking views of the surrounding mountains and valleys, making it a perfect spot for photography and reflection. <br /><br /> Lungthung is a peaceful reminder of the region’s rich history, with its stunning natural beauty and cultural significance. From here, you can enjoy panoramic views of the majestic mountains and experience the timeless charm of this historic trading route. <br /><br /><br /></p>


                  <h4><b>Nathang Valley: Feel the Chill Within</b></h4>
                  <p>One of the highlights of our <strong>Silk Route Package Tour Itinerary</strong> is Nathang Valley. Situated at 13,000 ft, this beautiful valley offers awe-inspiring views of snow-capped mountains and changing landscapes throughout the year. In winter, the valley becomes a white wonderland, while in summer it’s lush and green, and in autumn, it turns a stunning golden color. <br /><br />

                    Nathang Valley is a nature lover’s paradise, and during our <strong>Silk Route Tour Packages</strong>, you’ll have the opportunity to explore this enchanting landscape. Capture the stunning views, enjoy the peaceful atmosphere, and create lasting memories in one of the most scenic places along the Silk Route. <br /><br />

                    Book your <strong>Silk Route Tour</strong> from Kolkata today and discover the magic of Nathang Valley and other hidden treasures along this historic route. <br /><br /></p>

                  <br /><br />

                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen12ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                          <img src={sightseen14ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                          <img src={sightseen16ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /> <br />
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> BEST SIGHTSEEING PLACES TO EXPLORE IN <strong>SILK ROUTE TOUR PACKAGES FROM KOLKATA</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> BEST SIGHTSEEING PLACES TO EXPLORE IN <strong>SILK ROUTE TOUR PACKAGES FROM KOLKATA</strong></h3>
                </div>
                <div className="card-body">
                  <li><b>Zuluk Loop :</b> 32 hair-pin curves <br /><br /></li>
                  <li><b>Thambi View Point :</b> Best for Zuluk Zigzag view and Himalayan Range <br /><br /></li>
                  <li><b>Nathang Valley :</b> Offer best snow all round year <br /><br /></li>
                  <li><b>Kupup Lake :</b> Named as Elephant Lake with picturesque natural beauty <br /><br /></li>
                  <li><b>Menmecho Lake :</b> Lake with good ambience and awesome scenic beauty <br /><br /></li>
                  <li><b>Lungthung :</b> A remote village at 11,500 ft offering great Himalayan view <br /><br /></li>
                  <li><b>Tukla Valley :</b> Offer great view of Mt. Kanchenjungha from 12,500 ft altitude <br /><br /></li>
                  <li><b>Old Baba Mandir :</b> A temple of faith dedicated to sepoy Baba Harbhajan Singh near kupup village <br /><br /></li>
                  <li><b>Tsomgo Lake :</b> A must visit place for every Sikkim Gangtok Traveller <br /><br /></li>
                  <br /><br /><br />
                  <table style={{ width: '100%', textAlign: 'center' }}>
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <center><a href='tel:9147061470'><img src={CallButtonSoumyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a></center>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br /><br />
                </div>
              </div>
              <br /><br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4>SILKROUTE PACKAGE TOUR RELATED QUESTION </h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          Q. What kind of meals can I expect during my Silk Route Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          During your stay at the home stays on the Silk Route, you'll enjoy simple, home-cooked meals. The meals typically include rice, dal (lentils), vegetables, roti (flatbread), egg curry, and some local Sikkimese specialties for lunch and dinner. For evening snacks, you can expect options like pakoras, momos, and Maggi noodles.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          Q. Which vehicle is best for the Silk Route Tour from NJP?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Most of the roads on the Silk Route are made with boulders, and landslides can sometimes make the roads muddy and slippery. For this reason, Adorable Vacation recommends using sturdy vehicles like the Mahindra Bolero or Tata Sumo for standard tours, and the Mahindra Xylo for luxury tours. These vehicles are best suited for the rough terrain along the Silk Route.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          Q. Can I choose an SUV for my Silk Route Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, you can choose an SUV for your Silk Route tour. Adorable Vacation offers a variety of vehicles to match your preferences and budget so you can have a comfortable and enjoyable experience during your trip.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          Q. Is the Silk Route safe for solo women travelers?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Absolutely! The Silk Route is 100% safe for solo women travelers. The area is known for its friendly and welcoming locals, making it a great place for solo travelers.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          Q. How can I avoid altitude sickness during my Silk Route Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          To prevent altitude sickness, it’s important to stay hydrated, eat well, and follow any medical advice from your doctor. Make sure to take any necessary medications to help with high-altitude sickness, especially if you’re traveling to higher elevations.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseSix"
                        >
                          Q. What food should I try during my Silk Route Trip?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          The Silk Route is famous for its hot momos (dumplings) in three varieties: steamed, fried, and spicy. You can also enjoy Thukpa (noodle soup), Gyathuk (another type of noodle soup), Gundruk soup, and Sael Roti (a local snack). For a unique drink, try Chaang, a local alcoholic beverage, which is available along the route.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeven"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeven"
                        >
                          Q. Where can I get the best view of Mt. Kanchenjungha on my Silk Route Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingSeven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          The best views of Mt. Kanchenjungha can be enjoyed from places like Sillery Gaon, Ramdhura, and Thambi Viewpoint. If you’d like to stay at any of these locations, we’d be happy to arrange it for you.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                          Q. Can I bring my pets with me on the Silk Route Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Unfortunately, pets are not allowed on the Silk Route Tour.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingNine">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseNine"
                          aria-expanded="false"
                          aria-controls="flush-collapseNine"
                        >
                          Q. Do I need a permit to visit the Silk Route?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseNine"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingNine"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, permits are required to visit certain restricted areas like Zuluk and Gnathang. These permits are arranged at the Rongli Check Post along the way by your driver.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTen"
                          aria-expanded="false"
                          aria-controls="flush-collapseTen"
                        >
                          Q. Is there any bird-watching spot on the Silk Route?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingTen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, from April to May, Reshikhola becomes a popular spot for birdwatchers. The lush forests here are home to a variety of migrating birds, including the Great Barbet, Fire Tailed Sunbird, Himalayan Bulbul, Green Magpie, and many others. Birdwatchers often spot these species along the Zuluk to Gnathang route.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEleven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEleven"
                          aria-expanded="false"
                          aria-controls="flush-collapseEleven"
                        >
                          Q. Is it advisable to travel to Zuluk with a small baby?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEleven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingEleven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          You can travel to Zuluk with a baby, but it’s important to consult with a doctor beforehand about the potential effects of high altitude. If your baby has any breathing issues, make sure to carry the necessary medications, and pack plenty of warm clothes as it can get quite cold.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwelve">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwelve"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwelve"
                        >
                          Q. Can we find medicine shops and mineral water on the Silk Route while traveling with kids?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwelve"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingTwelve"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          It’s best to carry your own medicines, as getting them along the route can be challenging. You may find some places selling mineral water, but availability is not guaranteed everywhere. The Silk Route is not a luxury destination, and the area is not heavily developed, so stock up on essentials before heading out.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThirteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThirteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseThirteen"
                        >
                          Q. Are there restaurants or bars in Sillery Gaon, Zuluk, or Rongpokhola?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThirteen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingThirteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          There are no formal bars in these areas, but you can find small local restaurants along the roadside serving food like momos, noodles, and soup. Some small shops may have a bar where you can get drinks.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFourteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFourteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseFourteen"
                        >
                          Q. Will I face network or internet issues during my Silk Route Tour?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFourteen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingFourteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, there may be network issues, especially in Zuluk and Nathang where there is no signal. BSNL is the only network that works in some areas, but you’ll need to walk towards a tower to get a signal.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFifteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFifteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseFifteen"
                        >
                          Q. Is hot water available at homestays on the Silk Route?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFifteen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingFifteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, hot water is available at most homestays. Some homestays have geysers, while others provide hot water in a bucket on request.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSixteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSixteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseSixteen"
                        >
                          Q. Is vegetarian food available on the Silk Route?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSixteen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingSixteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, vegetarian meals are available at homestays, and many of them serve organic, locally grown vegetables. However, it’s a good idea to inform Adorable Vacation about your food preferences when booking your tour.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeventeen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeventeen"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeventeen"
                        >
                          Q. Can I find cake shops on the Silk Route?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSeventeen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingSeventeen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          There are no cake shops along the Silk Route. If you want to carry cakes or baked goods, it’s best to buy them from NJP before starting your journey.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEighteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEighteen"
                          aria-expanded="false"
                          aria-controls="flush-collapseEighteen"
                        >
                          Q. Do I need to carry an oxygen cylinder to Nathang?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEighteen"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingEighteen"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          If you have breathing issues, it's advisable to consult a doctor before the trip and carry necessary medications. If you have a serious condition, you may need to carry a small oxygen cylinder, as medical facilities are limited in the area.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Silk Route <span className="tomato">Tour Reviews</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Your Experience is our Marketing"</i>
                      </h4>
                      {/* For google */}
                      <div itemscope='' itemtype='http://schema.org/Product'>
                        <div itemprop='name'><h3 className='dropdown-item2'>Silk Route Reviews</h3></div>
                        <div itemprop='aggregateRating itemscope' itemtype='http://schema.org/AggregateRating'>
                          <span className='dropdown-item2' itemprop='ratingValue'>4.8</span> <span className='dropdown-item2'>Stars Rating, out of</span> <span itemprop='bestRating' className='dropdown-item2'>5</span><br />
                          <span className='dropdown-item2'>based on</span> <span itemprop='ratingCount' className='dropdown-item2'>278</span> <span className='dropdown-item2'>Reviews</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div>
        </div>
        <div class="btn-call-pulse">
          <a style={{ color: 'white' }} href="tel:+91-9147061467">
            <i className="fa fa-phone"></i>
          </a>
        </div>
        <div className="btn-whatsapp-pulse">
          <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>
      </>
    );
  }
}
export default Silkroute_PackageTourKolkata;
