import React, { Component, useState } from 'react';
import BookingBox from '../BookingBox';
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Bhutan/InclusionExclusion_bhutanPackageTourHasimara';
import Activityfecilities from '../Bhutan/Activity_bhutanPackageTourHasimara';
class Bhutan_PackageTourHashimaraNoq extends Component {
    constructor(props) {
        super(props);

        this.state = {
            btn: "Submit",
            showLoader: false,
            isHidden: true,
            showModal: false,
            showMegamenu: false, // New state to handle megamenu
            firstName: '',
            mobile: '',
            email: '',
            comments: '',
            megamenuTimeout: null // New state for managing hover delay
        }
        this.timeoutId = null; // To store the timeout ID
        this.modalRef = React.createRef(); // Create a ref for the modal
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919883359713&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ showModal: true });
        },55000);
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        // Cleanup the event listener
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        // Check if the click is outside the modal
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.onCloseModal();
        }
    };

    toggleHidden = (e) => {
        e.preventDefault();
        this.setState({ isHidden: !this.state.isHidden });
    }

    handleMegamenuEnter = () => {
        // Clear any existing timeout
        if (this.state.timeoutId) {
            clearTimeout(this.state.timeoutId);
        }
        this.setState({ isHidden: false });
    };

    handleMegamenuLeave = () => {
        // Set a timeout to delay hiding the megamenu
        const timeoutId = setTimeout(() => {
            this.setState({ isHidden: true });
        }, 300); // Match this delay with the CSS transition time

        this.setState({ timeoutId });
    };


    onRequestCallback = (e) => {
        e.preventDefault();
        if (!this.state.showModal) {
            this.setState({ showModal: true });
        }
    }

    onCloseModal = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            showModal: false,
            firstName: '',
            mobile: '',
            email: '',
            comments: ''
        });
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    // Function to handle form submission
    onRequestCallbackSubmit = async (e) => {
        e.preventDefault();

        const { firstName, email, mobile, comments } = this.state;

        // Validation
        if (firstName.trim() === '') {
            cogoToast.error("Full Name Required.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
            // } else if (email.trim() === '') {
            //     cogoToast.error("Email Required.", {
            //         position: 'top-center', // Use top-center, then adjust via CSS
            //         hideAfter: 2, // optional: auto-hide after 2 seconds
            //         className: 'custom-toast' // Apply custom class
            //     });
        } else if (mobile.trim() === '' || !/^\d{10}$/.test(mobile.trim())) {
            cogoToast.error("Please Insert 10 digit Mobile No.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else {
            this.setState({ btn: "Processing..", showLoader: true });
            // Proceed with form submission
            try {
                const _ref = 'yes';
                const _url = window.location.href;

                const formData = {
                    FirstName: firstName,
                    Email: email,
                    Mobile: mobile,
                    Comments: comments,
                    Referrer: _ref,
                    URL: _url
                };

                const response = await axios.post(ApiURL.SaveCustomerEnquery, formData);
                console.log('Data submitted successfully:', response.data);
                // Optionally, reset the form state after successful submission
                this.setState({ firstName: '', email: '', mobile: '', comments: '' });

                // Optionally, show a success message to the user
                cogoToast.success("Form submitted successfully.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
                this.setState({ btn: "Submit", showLoader: false, showModal: false });
            } catch (error) {
                console.error('Error submitting data:', error);
                this.setState({ btn: "Submit", showLoader: false });
                // Optionally, show an error message to the user
                cogoToast.error("Please try again after 24 hrs. later.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
            }

        }

    };

    /* RedirectToOTPPage = () => {
        if (this.state.redirectOTPStatus === true) {
            let url = "/verifyotp/" + this.state.mobile;
            return <Redirect to={url} />
        }
    } */
        onCloseModal = () => {
            this.setState({ showModal: false }, () => {
                // Additional code to perform after the modal is closed
                setTimeout(() => {
                    this.setState({ showModal: true }, () => {
                        // Additional code to perform after the next modal is displayed
                    });
                }, 55000); // 55sec in milliseconds
            });
        };
    render() {
        const packageTopBannerImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-guwahati-mobile-long-banner.webp";
        const packageTopBanner2ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-guwahati-mobile-banner.webp";
        const FlightBannerImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/Bhutan-Group-Tour-Package-from-Mumbai.webp";
        const navDay1ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/guwahati-to-bhutan-tour-packages-with-direct-flights-with-adorable-vacation.webp";
        const navDay2ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/guwahati-to-bhutan-tour-packages-booking-with-direct-flights-with-adorable-vacation.webp";
        const navDay3ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/guwahati-to-bhutan-tour-package-cost-with-direct-flights-with-adorable-vacation.webp";
        const navDay4ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/guwahati-to-bhutan-tour-package-itinerary-with-direct-flights-with-adorable-vacation.webp";
        const navDay5ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/guwahati-to-bhutan-tour-package-plan-with-direct-flights-with-adorable-vacation.webp";
        const navDay6ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/guwahati-to-bhutan-tour-package-price-with-direct-flights-with-adorable-vacation.webp";
        const navDay7ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-3-nights-4-days-package.webp";
        const navDay8ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-3-days.webp";
        const CallButtonSoumalyaImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/call-button-bablu-adorable-vacation.webp";
        const CallButtonSonaliImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/call-button-sonali-adorable-vacation.webp";
        const PriceImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-from-guwahati-call-price-button.webp";
        const sightseen1ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-package-from-guwahati-8.webp";
        const sightseen2ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-package-tour-from-guwahati-airport-with-adorable-vacation-llp-9.webp";
        const sightseen3ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-package-tour-from-guwahati-with-adorable-vacation-8.webp";
        const sightseen4ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-package-tours-from-guwahati-airport-with-adorable-vacation-10.webp";
        const sightseen5ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-booking-from-guwahati-7.webp";
        const sightseen6ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-trip-tour-from-guwahati-with-adorable-vacation-9.webp";
        const sightseen7ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/guwahati-to-bhutan-tour-by-direct-flight-11.webp";
        const sightseen8ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/travel-bhutan-from-guwahati-with-adorable-vacation.webp";
        const sightseen9ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-trip-from-guwahati-airport-with-adorable-vacation-4.webp";
        const sightseen10ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-itinerary-from-guwahati-airport-with-adorable-vacation-5.webp";
        const sightseen11ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-from-guwahati-airport-with-adorable-vacation-2.webp";
        const sightseen12ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-cost-from-guwahati-airport-with-adorable-vacation-6.webp";
        const sightseen13ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-package-tour-from-guwahati-airport-with-adorable-vacation-1.webp";
        const sightseen14ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-package-from-guwahati-airport-with-adorable-vacation-3.webp";
        const sightseen15ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-booking-from-guwahati-during-summer-with-adorable-vacation.webp";
        const sightseen16ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-from-guwahati-during-summer-with-adorable-vacation.webp";
        const sightseen17ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-package-tour-from-guwahati-with-direct-flight-in-winter.webp";
        const sightseen18ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-booking-from-guwahati-during-spring-with-adorable-vacation.webp";
        const sightseen19ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-from-guwahati-during-spring-with-adorable-vacation.webp";
        const sightseen20ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-booking-from-guwahati-with-direct-flights.webp";
        const sightseen21ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-from-guwahati-with-direct-flight-tickets.webp";
        const sightseen22ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-trip-from-guwahati-with-direct-flights.webp";
        const flight1ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-package-from-guwahati-with-direct-flight.webp";
        const flight2ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-tour-from-guwahati-with-direct-flight.webp";
        const flight3ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-trip-from-guwahati-with-direct-flight.webp";
        const hotel1ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-hotel-ugyen-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const hotel2ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-hotel-vara-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const hotel3ImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/bhutan-hotel-mandala-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const rows = [
            { date: '24th Sept - 30th Sept', availability: 'Available', price: '84,500' },
            { date: '01st Oct - 07th Oct', availability: 'Available', price: '84,500' },
            { date: '04th Oct - 10th Oct', availability: 'Available', price: '84,500' },
            { date: '08th Oct - 14th Oct', availability: 'Available', price: '84,500' },
            { date: '11th Oct - 17th Oct', availability: 'Available', price: '84,500' },
            { date: '15th Oct - 21st Oct', availability: 'Available', price: '84,500' },
            { date: '18th Oct - 24th Oct', availability: 'Available', price: '84,500' },
            { date: '22nd Oct - 28th Oct', availability: 'Available', price: '84,500' },
            { date: '25th Oct - 31st Oct', availability: 'Available', price: '84,500' },
            { date: '29th Oct - 04th Nov', availability: 'Available', price: '84,500' },
            { date: '1st Nov - 07th Nov', availability: 'Available', price: '84,500' },
            { date: '05th Nov - 11th Nov', availability: 'Available', price: '84,500' },
        ];

        const rowsPerPage = 6;
        const totalPages = Math.ceil(rows.length / rowsPerPage);
        const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
        const { customActiveTab, daywiseActiveTab } = this.state;

        const hotPackagesDataList = this.props.HotPackagesData;
        const hotPackagesView = hotPackagesDataList.length > 0 ?
            (
                hotPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.FIFO_6N_7D_ImageURL + "/" + dataItem.ImageName;
                    const contactURL = dataItem.Url;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} | {dataItem.EXPARO}<span> &#8377; {dataItem.PricePerNight}</span> </h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue">{dataItem.SDFInclude}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href={contactURL} className="btn btn-Warning btn-sm float-end">Call Now</a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const themePackageSliderList = this.props.ThemePackagesData;
        const themePackagesDataView = themePackageSliderList.map(
            (sliderItem, i) => {
                const sliderImageURL =
                    ApiURL.BhutanTourPackageFromHasimaraImageURL +
                    "/" +
                    sliderItem.ImageName;
                return (
                    <div className="theme-cards-wrapper mx-2" key={i}>
                        <div className="theme-card">
                            <div className="image-wrapper">
                                <img src={sliderImageURL} alt={sliderItem.AllternateText} />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">{sliderItem.Title}</h5>
                                <p className="card-text">{sliderItem.Description}</p>
                                <a href="#" className="btn btn-success btn-sm" onClick={this.handleClick1}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                );
            }
        );

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.BhutanTourPackageFromHasimaraImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })


        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="Bhutan Packages From Guwahati tour from adorable vacation the best Bhutan Guwahati dmc" />

                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container">
                                        <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center>Book <strong>Bhutan Package Tour from Hasimara</strong> - BEST DEAL 2025 </center></h1>
                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center>Book <strong>Bhutan Package Tour from Hasimara</strong> - BEST DEAL 2025 </center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="Bhutan Package Tour From Guwahati from adorable vacation the best Bhutan Guwahati dmc in india" />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Cities:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Thimphu
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Paro
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Punakha
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Bhutan tour</strong>, you have come to the Right Place! Adorable vacation is the best DMC for <strong>Bhutan Package Tour From Hasimara</strong>
                                        </p>
                                        <hr />
                                        <br />
                                        <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}> Looking for <strong>Bhutan Tour Package from Hasimara</strong>? <br /><br /> <a href="tel:+91-9883359713">FOR BOOKING ☎ CALL +91-98833-59713</a> <br /><br /></h2>
                                        <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> Looking for <strong>Bhutan Tour Package from Hasimara</strong>? <br /><br /> <a href="tel:+91-9883359713">FOR BOOKING ☎ CALL +91-98833-59713</a></h3>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="card">
                                <div className="card-body">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr className="yellow">
                                                <th rowSpan="2">DAY WISE SUMMERY FOR <strong>BHUTAN PACKAGE TOUR FROM HASIMARA STATION</strong></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingOne"
                                                >
                                                    <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                        <span>
                                                            ARRIVAL AT HASIMARA & THIMPHU TRANSFER
                                                        </span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingTwo"
                                                >
                                                    <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                        <span>THIMPHU LOCAL SIGHTSEEING</span>
                                                    </a>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingThree"
                                                >
                                                    <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                        <span>THIMPHU TO PUNAKHA TRANSFER & SIGHTSEEINGS</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingFour"
                                                >
                                                    <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                        <span>PUNAKHA TO PHOBJIKHA VALLEY DAY TRIP</span>
                                                    </a>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingFive"
                                                >
                                                    <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                        <span>PUNAKHA TO PARO TRANSFER & SIGHTSEEINGS</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingSix"
                                                >
                                                    <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                        <span>PARO TO TAKTSANG MONASTERY HIKING / CHELE-LA-PASS DAY TRIP</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingTen"
                                                >
                                                    <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                        <span>PARO TO HASIMARA TRANSFER</span>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div className="card-body">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr className="yellow">
                                                <th rowSpan="2">INCLUSIONS: <strong>HASIMARA TO BHUTAN PACKAGE TOUR</strong></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingOne"
                                                >
                                                    <a href="#" style={{ color: 'black' }}>
                                                        Accommodation in TCB listed Hotels (Double/Twin Sharing Room)
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                    <a href="#" style={{ color: 'black' }}>
                                                        Meal Plan MAP (Breakfast and Dinner)
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                    <a href="#" style={{ color: 'black' }}>
                                                        Transfers & all sightseeing in an exclusive Non-AC Vehicle (Pvt. Basis)
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                    <a href="#" style={{ color: 'black' }}>
                                                        Parking, Toll Tax, E-Permits
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                    <a href="#" style={{ color: 'black' }}>
                                                        Thimphu Local Sightseeing
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                    <a href="#" style={{ color: 'black' }}>
                                                        Paro City Tour
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                    <a href="#" style={{ color: 'black' }}>
                                                        English / Hindi Speaking Tour Guide
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                    <a href="#" style={{ color: 'black' }}>
                                                        All transfer will be on point-to-point basis
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                    <a href="#" style={{ color: 'black' }}>
                                                        SDF Charges (Sustainable Development Fee)
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                    <a href="#" style={{ color: 'black' }}>
                                                        01 Bottle Packaged Drinking Water Per Day on Vehicle
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="clickable" data-target="panelsStayOpen-headingOne">
                                                    <a href="#" style={{ color: 'black' }}>
                                                        Guest Service Support during the trip from Bhutan & India Office 24/7
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {/* <br />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> */}
                                </div>
                            </div>
                            <br /> <br />
                            {/* <!--end table inclusion exclusion--> */}
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="image-cell">
                                            <a href='#' onClick={this.onRequestCallback}><img src={PriceImageURL} alt="bhutan-tour-from-guwahati-call-price-button.webp" className="image-padding" /></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DURATION FOR <strong>BHUTAN TOUR PACKAGE FROM HASIMARA</strong> : 6N | 7D</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> DURATION FOR <strong>BHUTAN TOUR PACKAGE FROM HASIMARA</strong> : 6N | 7D</h3>
                                </div>
                                <div className="card-body">
                                    <h4>DESTINATIONS COVERED IN 6 NIGHTS 7 DAYS <strong>BHUTAN PACKAGES FROM HASIMARA STATION</strong> : THIMPHU 2N | PUNAKHA 2N | PARO 2N <br /><br /></h4>

                                    <h4>PICKUP & DROP : HASIMARA STATION [HSA] </h4><br />
                                    <p>Apart from <strong>Bhutan Package Tour From Hasimara</strong>, Adorable Vacation offers various <strong>Bhutan tour packages</strong> like <a href="/bhutan-tour-package-from-pune" target="_blank"><b className="tomato">Bhutan Package Tour from Pune</b></a>,<a href="/bhutan-tour-from-delhi" target="_blank"><b className="tomato"> Bhutan Package Tour from Delhi</b></a>, <a href="/bhutan-tour-package-from-mumbai" target="_blank"><b className="tomato">Bhutan package tour from Mumbai</b></a>, <a href="/bhutan-tour-package-from-ahemdabad" target="_blank"><b className="tomato">Bhutan Package Tour from Ahmedabad</b></a>, <a href="/bhutan-tour-from-bengaluru" target="_blank"><b className="tomato">Bhutan Package Tour from Bangalore</b></a>, <a href="/bhutan-tour-package-from-jaigaon" target="_blank"><b className="tomato"> Bhutan Package Tour from Jaigaon</b></a>, <a href="/bhutan-tour-package-from-kolkata" target="_blank"><b className="tomato">Bhutan Package Tour from Kolkata</b></a>, <a href="/bhutan-tour-package-from-bagdogra" target="_blank"><b className="tomato">Bhutan Package Tour from Bagdogra Airport</b></a>, <a href="/bhutan-tour-package-from-surat" target="_blank"><b className="tomato">Bhutan Package Tour from Surat</b></a>, <a href="/mumbai-special-chartered-flight" target="_blank"><b className="tomato">Bhutan Package Tour from Mumbai with Chartered Flight</b></a>,<a href="/bhutan-b2b-package-tour-operator-in-india-best-bhutan-dmc" target="_blank"><b className="tomato">Bhutan B2B Package Tour</b></a>, etc at Best Price.</p>
                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen1ImageURL}
                                                        alt="adorable vacation - bhutan tour operator for b2b"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen2ImageURL}
                                                        alt="b2b travel agen for bhutan tour from india"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen3ImageURL}
                                                        alt="b2b travel agents for bhutan tour package booking"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {/* <br /><br /><br />
                                    <div class="d-grid gap-2 d-md-block">
                                        <a href="tel://9883359713" className="btn btn-Warning btn-lg">
                                            Talk To Our Bhutan Specialist Now
                                        </a>
                                    </div> */}
                                    <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>START YOUR BHUTAN ADVENTURE WITH <strong>BHUTAN TOUR PACKAGES FROM HASIMARA</strong> - THE GATEWAY TO BHUTAN</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>START YOUR BHUTAN ADVENTURE WITH <strong>BHUTAN TOUR PACKAGES FROM HASIMARA</strong> - THE GATEWAY TO BHUTAN</h3>
                                </div>
                                <div className="card-body">
                                    <p>If you're planning a trip to Bhutan, starting your journey from Hasimara Station is the best way to kick off your adventure. Located in West Bengal, India, Hasimara is just 18 km away from the Bhutanese border at Phuentsholing, making it one of the most convenient and accessible entry points for travellers. Whether you're looking for a <strong>Bhutan package tour from Hasimara</strong> or exploring customized <strong>Bhutan tour packages from New Alipurduar</strong>, this route offers an easy and scenic entry to the Land of the Thunder Dragon. <br /><br /></p>
                                </div>
                            </div>
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHY CHOOSE HASIMARA FOR YOUR BHUTAN TOUR</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY CHOOSE HASIMARA FOR YOUR BHUTAN TOUR</h3>
                                </div>
                                <div className="card-body">
                                    <p>The journey to Bhutan begins with a comfortable and hassle-free train ride from Kolkata to Hasimara. The 13149 Kanchan Kanya Express, the only direct train service, departs from Sealdah Station daily at 20:35 PM, and reaches Hasimara at 11:05 AM the following day. This convenient overnight train ride allows you to rest while traveling and ensures that you arrive in Hasimara ready to start your <strong>Bhutan adventure</strong>.<br /><br />

                                        From Hasimara, travel to Bhutan is quick and easy. It takes only about 1 hour by car to reach Phuentsholing, Bhutan's border town, where you can complete the necessary immigration procedures. Afterward, you can continue your journey to Thimphu, the capital of Bhutan, and reach the heart of the country in just a few hours. This ease of access makes it possible for you to reach Bhutan's capital in a single day, maximizing your time exploring Bhutan’s many wonders.<br /><br /></p>

                                </div>
                            </div>
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>BHUTAN TOUR PACKAGE COST FROM HASIMARA</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BHUTAN TOUR PACKAGE COST FROM HASIMARA</h3>
                                </div>
                                <div className="card-body">
                                    <p>With Bhutan trip cost from Hasimara being affordable and transparent, traveling from Hasimara is a budget-friendly way to explore Bhutan. You can find a range of <strong>Bhutan trip packages from Hasimara</strong>, offering flexible itineraries that suit various budgets and preferences. Whether you opt for a <strong>Bhutan tour from Hasimara</strong> or a more customized <strong>Bhutan tour package from New Alipurduar</strong>, there’s something for every traveller. The <strong>Bhutan tour package cost from Hasimara</strong> is competitive and ensures great value for money, covering transport, sightseeing, and accommodations. .<br />.<br /> </p>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <br /><br />
                                <table style={{ width: '100%', textAlign: 'center' }}>
                                    <tbody>
                                        <tr>
                                            <td className="image-cell" style={{ textAlign: 'center' }}>
                                                <img
                                                    src={sightseen4ImageURL}
                                                    alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"
                                                    style={{ margin: '10px' }}
                                                />
                                                <img
                                                    src={sightseen5ImageURL}
                                                    alt="Bhutan Guwahati travel packages"
                                                    style={{ margin: '10px' }}
                                                />
                                                <img
                                                    src={sightseen6ImageURL}
                                                    alt="travel bhutan with best b2b dmc - adorable vacation"
                                                    style={{ margin: '10px' }}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br /><br /><br />

                                <h3 className='site-heading text-center'><b >Wonderful <span className='tomato'>Bhutan Tour Review from Mumbai</span> from our esteemed Guests Mr. & Mrs. Dalal</b><br /></h3>
                                <p className='p-2'><center>We are thrilled to share a heartfelt review for <strong>Bhutan tour from Mumbai</strong> from our valued guests, Mr. & Mrs. Dalal. Their experience highlights the exceptional service and memorable moments they encountered during their <a href='/mumbai-special-chartered-flight' target='_blank'><strong>Bhutan tour from Mumbai with Direct Flight</strong></a>. We take great pride in ensuring that every guest has a delightful and fulfilling journey, and it's always rewarding to hear such positive feedback.</center></p><br /><br />
                                <div class="video-container">
                                    <iframe
                                        src="https://www.youtube.com/embed/19drRVnSMCs?si=Uj5Fm4uSuwPi0SBM"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen>
                                    </iframe>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> EXPLORE BHUTAN'S TOP ATTRACTIONS</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> EXPLORE BHUTAN'S TOP ATTRACTIONS </h3>
                                </div>
                                <div className="card-body">
                                    <p>With Adorable Vacation - The <a href=" https://adorablevacation.com/bhutan-b2b-package-tour-operator-in-india-best-bhutan-dmc/" target="_blank"><strong>Best Bhutan B2B Package Tour Operator</strong></a>, travel Bhutan and visit the iconic Tiger's Nest Monastery perched on a cliff, to the stunning Punakha Dzong and the peaceful Phobjikha Valley. These <strong>Bhutan package tour from Hasimara</strong> allow you to experience the rich culture, pristine landscapes, and unique traditions that make Bhutan one of the most trending travel destinations for 2025.<br /><br />

                                        As Bhutan's tourism continues to rise, it is becoming a top choice for travellers seeking a unique blend of adventure, wellness, and spirituality. Bhutan's commitment to sustainability and its emphasis on high-value, low-impact tourism means that your visit supports the country's sustainable growth. Whether you're looking for an adventurous trek, cultural exploration, or a tranquil retreat, Bhutan offers an unforgettable experience.<br /><br />

                                        Start your <strong>Bhutan package tour from Hasimara</strong> and unlock the magic of Bhutan - a destination like no other. Let <b>Adorable Vacation</b> - The <strong>Best Bhutan tour operator from Hasimara</strong> help you craft the perfect itinerary to experience the best of this Himalayan Kingdom. <br /></p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> FAMOUS PLACES OF BHUTAN TO NOTE  </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>FAMOUS PLACES OF BHUTAN TO NOTE </h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables" itemtype="https://schema.org/Table">

                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr><th>CITIES</th><th>ALTITUDE</th><th>COORDINATES</th></tr>
                                                <tr><td> Thimphu</td><td>2,334 m</td><td>27.4712° N, 89.6339° E</td></tr>
                                                <tr><td>Paro</td><td>2200 m</td><td>27.4287° N, 89.4164° E</td></tr>
                                                <tr><td>Punakha</td><td>1,274 m</td><td>27.5921° N, 89.8797° E</td></tr>
                                                <tr><td>Gasa</td><td>2,850 m</td><td>27.8983° N, 89.7310° E</td></tr>
                                                <tr><td>Dagana</td><td>1,464 m</td><td>27.0970° N, 89.8739° E</td></tr>
                                                <tr><td>Tigers Nest Monastery</td><td>3,120 m</td><td>27.2930° N, 89.2148° E</td></tr>
                                                <tr><td>Chele-La-Pass</td><td>3,988 m</td><td>27.3698° N, 89.3466° E</td></tr>
                                                <tr><td>Haa Valley</td><td>2,670 m</td><td>27.3333° N, 89.1833° E</td></tr>
                                                <tr><td>Dochula Pass</td><td>3,100 m</td><td>27.2924° N, 89.4501° E</td></tr>
                                                <tr><td>Phobjikha Valley</td><td>3,000 m</td><td>27.4594° N, 90.1802° E</td></tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen7ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen8ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen9ImageURL}
                                                        alt="travel bhutan with best b2b dmc - adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br /><br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHY ADORABLE VACATION IS THE BEST CHOICE FOR BOOKING <strong>BHUTAN TOUR PACKAGE FROM HASIMARA STATION</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> WHY ADORABLE VACATION IS THE BEST CHOICE FOR BOOKING <strong>BHUTAN TOUR PACKAGE FROM HASIMARA STATION</strong></h3>
                                </div>
                                <div className="card-body">
                                    <ul>
                                        <li><b>Trusted Brand : </b> Adorable Vacation has been the most trusted brand for <strong>Bhutan tour from Hasimara</strong>. <br /><br /></li>
                                        <li><b>Passenger Service Agent (PSA) : </b> We are proud to be the PSA for Bhutan Airlines and Druk Airlines. <br /><br /></li>
                                        <li><b>Luxury Packages : </b> Experience the best <a href=" https://adorablevacation.com/bhutan-b2b-package-tour-operator-in-india-best-bhutan-dmc/ " target="_blank"><strong>Bhutan Luxury Package Tour with Hotel Six Senses</strong></a>, Amankora, COMO Uma, Le Méridien, and Pemako. <br /><br /></li>
                                        <li><b>Super Peak Season Inventory : </b> We have pre-purchased hotel room inventory for peak season for <a href=" https://adorablevacation.com/mumbai-special-chartered-flight/ " target="_blank"><strong>Bhutan package tour from Mumbai with chartered flight</strong></a>. <br /><br /></li>
                                        <li><b>Authorized Travel House : </b> We are an authorized travel house by the Tourism Council of Bhutan [License No: 1053228]. <br /><br /></li>
                                        <li><b>Direct Employment : </b> Our employees in Bhutan are directly on our payroll, ensuring quality service.<br /><br /></li>
                                        <li><b>Customized Itineraries : </b> We offer tailored <strong>Bhutan tour itineraries from Hasimara</strong> with unique and signature programs. <br /><br /></li>
                                        <li><b>In-Depth Knowledge : </b> Our team possesses extensive knowledge about Bhutan's territory, hotels, and local laws. <br /><br /></li>
                                        <li><b>Assured Services : </b> We ensure premium services to enrich your experience while in Bhutan. <br /><br /></li>
                                        <li><b>E-Permit Processing : </b> We handle the e-permit process in advance for all your sightseeing needs. <br /><br /></li>
                                        <li><b>Personalized Attention : </b> Enjoy safe and secure personal attention throughout your stay in Bhutan. <br /><br /></li>
                                        <li><b>Complete Responsibility : </b> Adorable Vacation takes full responsibility for your entire <strong>Bhutan tour from Hasimara</strong>. <br /><br /></li>
                                        <li><b>Hassle-Free Experience : </b> We guarantee hassle-free and trustworthy services in Bhutan. <br /><br /></li>
                                        <li><b>Best Deals : </b> Access the best deals for top properties and services based on your preferences. <br /><br /></li>
                                        <li><b>Flexible Travel Options : </b>Plan a customized Bhutan private trip or join a Bhutan group tour with Adorable Vacation. <br /><br /></li>
                                    </ul>

                                </div>
                            </div>
                            <br />
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> QUICK FACTS NEED TO KNOW BEFORE PLANNING <strong>BHUTAN PACKAGE TOUR FROM HASIMARA</strong> </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>QUICK FACTS NEED TO KNOW BEFORE PLANNING <strong>BHUTAN PACKAGE TOUR FROM HASIMARA</strong> </h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables" itemtype="https://schema.org/Table">

                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody><tr><th>Fact</th><th>Details</th></tr>
                                                <tr><td>Capital</td><td>Thimphu</td></tr>
                                                <tr><td>Largest Cities</td><td>Thimphu, Punakha, Paro, Phuentsholing, Samdrup Jongkhar, Wangdue Phodrang, Samtse, Jakar </td></tr>
                                                <tr><td>Official Language</td><td>Dzongkha</td></tr>
                                                <tr><td>Population</td><td>7.87 lakhs (2023)</td></tr>
                                                <tr><td>Area</td><td>38,394 km²</td></tr>
                                                <tr><td>Currency</td><td>The ngultrum (BTN)</td></tr>

                                                <tr><td>Independence Day</td><td>National Day is a public holiday in the Kingdom of Bhutan on December 17th each year</td></tr>
                                                <tr><td>National Flower</td><td>Himalayan blue poppy</td></tr>
                                                <tr><td>National Animal</td><td>the Takin</td></tr>
                                                <tr><td>Major Religion</td><td>Buddhism</td></tr>
                                                <tr><td>UNESCO World Heritage Sites</td>
                                                    <td>Ancient Ruin of Drukgyel Dzong <br /><br />
                                                        Dzongs: Punakha Dzong, Wangdue Phodrang Dzong, Paro Dzong, Trongsa Dzong and Dagana Dzong<br /><br />
                                                        Sacred Sites associated with Phajo Drugom Zhigpo and his descendants<br />
                                                        Tamzhing Monastery<br />
                                                        Royal Manas National Park (RMNP)<br />
                                                        Jigme Dorji National Park (JDNP)<br />
                                                        Bumdeling Wildlife Sanctuary<br />
                                                        Sakteng Wildlife Sanctuary (SWS)</td></tr>

                                                <tr><td>Famous Cuisine</td><td>Ema datshi, Jasha Maroo, Phaksha paa, Kewa Datshi, Shakam datshi, Shamu Datshi</td></tr>

                                                <tr><td>Notable Attractions in Bhutan</td><td>
                                                    Paro Taktsang, Dochula Pass, Punakha Dzong, National Memorial Chhorten, Tashichho Dzong, National Museum of Bhutan, Gangtey Monastery, Punakha Suspension Bridge, Royal Textile Academy, Trashigang Dzong, Ura valley, Dechen Phodrang Monastery, National Library & Archives Of Bhutan</td></tr>

                                                <tr><td>Notable River of Bhutan</td><td>Manas River, Mo Chhu, Paro Chu, Jaldhaka, Pho Chhu, Tang Chhu River, Shil Torsa River, Raidak River</td></tr>

                                                <tr><td>Highest Mountain</td><td>Kangkar Pünzum [7,570 metres]</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={flight1ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={flight2ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={flight3ImageURL}
                                                        alt="travel bhutan with best b2b dmc - adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> HOW TO REACH BHUTAN FROM HASIMARA?  </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> HOW TO REACH BHUTAN FROM HASIMARA?  </h3>
                                </div>
                                <div className="card-body">
                                    <p>Hasimara is a small town in the Alipurduar district of West Bengal. Hasimara is approximately 17 kilometres (about 10 miles) away from the border town of Phuentsholing, Bhutan. Phuentsholing is the main entry point to Bhutan from India, and it is where you will cross the border into Bhutan. The drive from Hasimara to Phuentsholing usually takes around 30 to 45 minutes, depending on traffic and road conditions. Hasimara is a convenient starting point for your Bhutan adventure. Well-connected by the Kanchankanya Express from Kolkata, you can also reach Hasimara via New Alipurduar or New Jalpaiguri (NJP) Railway stations. Alternatively, the nearest airport is Bagdogra Airport (IXB), approximately 165 km (4 hours) from Phuentsholing, Bhutan border town.
                                        <br /><br /></p>


                                    <h4>REACH BHUTAN FROM HASIMARA BY ROAD VIA PHUENTSHOLING</h4>

                                    <p>The most common way to travel from Hasimara to Phuentsholing is by hiring a taxi or a private vehicle. There are several local taxi services available in Hasimara, and you can easily book a ride to Phuentsholing. Taxis are usually available at Hasimara railway station or other popular locations in the town. You can also arrange for a private car if you are traveling with a <strong>Bhutan group tour</strong> or have a lot of luggage. The road from Hasimara to Phuentsholing is well-maintained, and the journey is quite scenic, with beautiful views of the surrounding hills and forests. <br /><br />

                                        But guests who are travelling with Adorable Vacation and booked the <strong>Bhutan package tour from Hasimara Station</strong>, no need to worry. Our driver will be at Hasimara at proper time to welcome you and take you to the border town of Phuentsholing. <br /><br /></p>


                                    <p>After about 30 to 45 minutes of driving, you will reach Phuentsholing, the border town of Bhutan. Phuentsholing is a busy town that serves as the main gateway for people traveling from India to Bhutan. It is well connected by road and is the most common point for travellers crossing the India-Bhutan border. As you enter Phuentsholing, you will come across the Bhutan Gate, which marks the official entry point into Bhutan. <br /><br /></p>


                                    <p>Before you can enter Bhutan, you will need to go through immigration and customs procedures at the border. The border crossing process is straight forward but requires some paperwork. You will need to produce following documents :<br /><br />

                                        <b>Passport:</b> A valid passport is required to enter Bhutan. Indian nationals do not need a visa to visit Bhutan, but you must carry your passport with at keast 6monts+ validity or a voter ID card as proof of identity. <br /><br />

                                        <b>Visa (for Non-Indian Nationals):</b> If you are not an Indian national, you will need a valid Bhutanese visa. You must apply for the visa in advance through a Bhutan tour operator like Adorable Vacation, as tourists from most countries must arrange their visas before arrival. <br /><br />

                                        <b>Tourist Permit:</b> In addition to your passport or visa, Indian nationals need to obtain a tourist permit to enter Bhutan. This permit is usually issued at the immigration office in Phuentsholing. <br /><br />

                                        At the immigration office in Phuentsholing, you will need to fill out a simple form and show your documents. After completing the necessary formalities, you will be allowed to enter Bhutan. <br /><br /></p>
                                    <p>Once the border formalities are completed, you are free to enter Bhutan. In Phuentsholing, there are lots of places to explore like Bhutan Gate, Amo Chhu Crocodile Breeding Centre, Zangto Pelri Lhakhang, Karbandi Monastery, Torsa River Side etc. So exploring these places drive straight to Thimphu - The Capital of Bhutan. Phuentsholing to Thimphu is almost 150km and takes more than 4hrs enjoying the scenic journey to the Land of Happiness. <br /><br /></p>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-bagdogra" target='_blank' className="btn btn-Warning btn-lg">
                                            <b>Bhutan Package Tour From Bagdogra Airport</b>
                                        </a>
                                    </div>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-pune" target='_blank' className="btn btn-Warning btn-lg w-100">
                                            <b>Bhutan Package Tour Booking From Pune</b>
                                        </a>
                                    </div>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-kolkata" target='_blank' className="btn btn-Warning btn-lg w-100">
                                            <b>Bhutan Package Tour From Kolkata Airport</b>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> REACH BHUTAN FROM HASIMARA BY TRAIN</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> REACH BHUTAN FROM HASIMARA BY TRAIN</h3>
                                </div>
                                <div className="card-body">
                                    <p>There is only one direct train from Sealdah to Hasimara Station. To travel from Sealdah to Hasimara by train, the Kanchan Kanya Express (Train No. 13149) is a convenient and popular option. This train departs from Sealdah station at 20:35 and reaches Hasimara the following day at 11:05, covering the 720km journey in approximately 14 hours and 30 minutes. The Kanchan Kanya Express operates daily, ensuring flexibility for travelers planning their trip. <br /><br />

                                        The train offers various classes of travel, including sleeper and air-conditioned coaches, making it suitable for different preferences and budgets. Whether you're traveling for business, leisure, or a <strong>Bhutan family trip</strong>, the Kanchan Kanya Express offers a reliable and comfortable way to reach Hasimara from Sealdah. <br /><br /><br /></p>
                                    <div id="no-more-tables" itemtype="https://schema.org/Table">

                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr>
                                                    <th>Train Name</th>
                                                    <th>TRAIN NO</th>
                                                    <th>ORIGIN STN / TIME</th>
                                                    <th>ARRV STN / TIME</th>
                                                    <th>TRAIN FREQUENCY</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>KANCHAN KANYA</td>
                                                    <td>13149</td>
                                                    <td>Sealdah | 20:35</td>
                                                    <td>Hasimara | 11:05</td>
                                                    <td>Everyday</td>
                                                </tr>
                                                <tr>
                                                    <td>SARAIGHAT EXPRESS</td>
                                                    <td>12345</td>
                                                    <td>Howrah | 16:05</td>
                                                    <td>New Alipurduar | 03:40</td>
                                                    <td>Everyday</td>
                                                </tr>
                                                <tr>
                                                    <td>GHY GARIB RATH</td>
                                                    <td>12517</td>
                                                    <td>Kolkata | 21:40</td>
                                                    <td>New Alipurduar | 10:18</td>
                                                    <td>Thursday</td>
                                                </tr>
                                                <tr>
                                                    <td>PADATIK EXPRESS</td>
                                                    <td>12377</td>
                                                    <td>Sealdah | 23:20</td>
                                                    <td>New Alipurduar | 12:25</td>
                                                    <td>Everyday</td>
                                                </tr>
                                                <tr>
                                                    <td>UTTAR BANGA EXPRESS</td>
                                                    <td>13147</td>
                                                    <td>Sealdah | 19:40</td>
                                                    <td>New Cooch Behar | 08:55</td>
                                                    <td>Everyday</td>
                                                </tr>
                                                <tr>
                                                    <td>KANCHANJUNGA</td>
                                                    <td>13173</td>
                                                    <td>Sealdah | 06:50</td>
                                                    <td>New Alipurduar | 20:50</td>
                                                    <td>Tuesday, Thursday, Friday, Sunday</td>
                                                </tr>
                                                <tr>
                                                    <td>KAMRUP EXPRESS</td>
                                                    <td>15959</td>
                                                    <td>Howrah | 18:30</td>
                                                    <td>New Alipurduar | 08:42</td>
                                                    <td>Tuesday, Wednesday, Thursday, Saturday, Sunday</td>
                                                </tr>
                                                <tr>
                                                    <td>TEESTA TORSHA</td>
                                                    <td>13141</td>
                                                    <td>Sealdah | 15:00</td>
                                                    <td>New Alipurduar | 05:50</td>
                                                    <td>Everyday</td>
                                                </tr>
                                                <tr>
                                                    <td>KANCHAN KANYA</td>
                                                    <td>13149</td>
                                                    <td>Sealdah | 20:35</td>
                                                    <td>Alipur Duar JN | 12:35</td>
                                                    <td>Everyday</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9674738480'><img src={CallButtonSonaliImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {/* <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen10ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen11ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen12ImageURL}
                                                        alt="travel bhutan with best b2b dmc - adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> */}
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> SIGHTSEEING PLACES & ENTRY FEES TO NOTE FOR <strong>BHUTAN TOUR FROM HASIMARA</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> SIGHTSEEING PLACES & ENTRY FEES TO NOTE FOR <strong>BHUTAN TOUR FROM HASIMARA</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables" itemtype="https://schema.org/Table">

                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr>
                                                    <th>PLACES</th>
                                                    <th>CITY</th>
                                                    <th>ENTRY FEES</th>
                                                    <th>REMARKS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr><td colspan="4">THIMPHU SIGHTSEEING PLACES AND THE ENTRY FEES (IN NGULTRUM)</td></tr>
                                                <tr><td>Tashichhoe Dzong (Fort)</td><td>Thimphu</td><td>NU 500</td><td>Mon-Fri from 5pm & Sat-Sun from 9am to 6pm</td></tr>
                                                <tr><td>Buddha Dordenma Statue</td><td>Thimphu</td><td>No Entry Fee</td><td>Time-9am to 5pm</td></tr>
                                                <tr><td>Thimphu Memorial Chorten</td><td>Thimphu</td><td>NU 500</td><td>Anytime</td></tr>
                                                <tr><td>Changangkha Lhakhang (Temple)</td><td>Thimphu</td><td>NU 500</td><td>Anytime between 8am to 5pm</td></tr>
                                                <tr><td>Takin Preservation Centre</td><td>Thimphu</td><td>NU 500</td><td>9am to 5pm</td></tr>
                                                <tr><td>Simtokha Dzong (Fort)</td><td>Thimphu</td><td>NU 500</td><td>9am to 5pm</td></tr>
                                                <tr><td>National Library</td><td>Thimphu</td><td>NU 100</td><td>9am to 5pm on Mon to Fri</td></tr>
                                                <tr><td>The Royal Textile Museum</td><td>Thimphu</td><td>NU 250</td><td>Mon to Sat from 9am to 4pm</td></tr>
                                                <tr><td>National Institute for Zorig Chusum (Painting School)</td><td>Thimphu</td><td>NU 200</td><td>Mon to Fri 10am to 3:30pm</td></tr>
                                                <tr><td>Folk Heritage Museum</td><td>Thimphu</td><td>NU 200</td><td>Open from 9am to 4pm on Mon to Sun</td></tr>
                                                <tr><td>Simply Bhutan Museum</td><td>Thimphu</td><td>NU 1000</td><td>Open from 9am to 4pm on Mon to Sun</td></tr>

                                                <tr><td colspan="4">PUNAKHA SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                                                <tr><td>Punakha Dzong (Fort)</td><td>Punakha</td><td>NU 500</td><td>11am to 1pm & 3pm to 5pm (Nov to May) and other time anytime bwtn 9am to 5pm</td></tr>
                                                <tr><td>Chimmi Lhakhang (Temple)</td><td>Punakha</td><td>NU 500</td><td>9am to 1pm and 2pm to 5pm </td></tr>
                                                <tr><td>Khamsum Yueely Namgyal Chorten</td><td>Punakha</td><td>NU 100</td><td>9am to 1pm and 2pm to 5pm </td></tr>

                                                <tr><td colspan="4">PARO SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                                                <tr><td>Kyichu Lhakhang (temple)</td><td>Paro</td><td>NU 500</td><td>9am to 12pm & 1pm to 4pm </td></tr>
                                                <tr><td>Rinpung Dzong (Fort)</td><td>Paro</td><td>No Entry Fee</td><td>9am to 5pm (Summer) & 9am to 4pm (Winter) </td></tr>
                                                <tr><td>Dobji Dzong</td><td>Paro</td><td>No Entry Fee</td><td>9am to 5pm </td></tr>
                                                <tr><td>National Museum of Bhutan</td><td>Paro</td><td>NU 300</td><td>Opens from 9am to 4pm throughout the week except on govt & local holidays </td></tr>
                                                <tr><td>Taktshang (Tiger Nest Monastery)</td><td>Paro</td><td>NU 1000</td><td>9am to 5pm </td></tr>

                                                <tr><td colspan="4">TRONGSA SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                                                <tr><td>Trongsa Dzong</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>
                                                <tr><td>Trongsa Ta Dzong (Watch tower)</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>

                                                <tr><td colspan="4">BUMTHANG SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                                                <tr><td>Jampa Lhakhang</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>
                                                <tr><td>Kurjey Lhakhang</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>
                                                <tr><td>Jakar Dzong</td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm (Summer ) & 9am to 4pm (Winter) </td></tr>

                                                <tr><td colspan="4">PHUENTSHOLING SIGHTSEEING PLACES AND THE ENTRY FEES</td></tr>
                                                <tr><td>Rinchending Goenpa (temple) </td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm </td></tr>
                                                <tr><td>Palden Tashi Choling Shedra </td><td>Trongsa</td><td>No Entry Fee</td><td>9am to 5pm </td></tr>
                                                <tr><td colspan="4">NOTE : 50% monument fees exemption on entry fees for children of 6 years to 18 years and no fee for children of 5 years and below. </td></tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br /><br />
                                    {/* <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br /> */}
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen13ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen14ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen15ImageURL}
                                                        alt="travel bhutan with best b2b dmc adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>BEST TIME TO BOOK <strong>BHUTAN PACKAGE TOUR FROM HASIMARA STATION</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST TIME TO BOOK <strong>BHUTAN PACKAGE TOUR FROM HASIMARA STATION</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p><b>Summer</b> in Bhutan spans from June to September, with temperatures in central Bhutan typically ranging between 20 to 25 degrees Celsius. The weather is generally sunny and mildly humid, while the nights can get quite chilly, so light woolen clothes or jackets are advisable. The landscapes around cities and tourist attractions are lush and green, and the clear skies offer a breathtaking view of the snow-capped Himalayan peaks. If you visit during this period, you can also experience the Haa Festival, which showcases Bhutanese cuisine, culture, traditions, and lifestyle. With fewer crowds, you can peacefully explore numerous sightseeing spots. Therefore, summer is one of the best seasons to opt for <strong>Bhutan Holiday Package from Hasimara</strong>. <br /><br /><br /></p>


                                    <h4>AUTUMN [SEPTEMBER - NOVEMBER]</h4>
                                    <p>Autumn is widely regarded as the best season for booking <strong>Bhutan tour from Hasimara</strong>. The weather during this time is quite pleasant, ranging from 10 to 15 degrees, making sightseeing and travel enjoyable throughout the day, while the nights are comfortably chilly. This is also the festive season in Bhutan, offering travelers a glimpse into the rich cultural heritage and vibrant festivals of the country. Festivals such as the Black-Necked Crane Festival, Paro Tshechu, and Punakha Tshechu are celebrated with great enthusiasm, with dzongs and monasteries coming alive with colorful flags, large Thonkas, and masked dances. For tourists, it’s the perfect time to visit. However, Adorable Vacation recommends booking your in advance during the summer months to avoid the peak season rush and the unavailability of flights and hotel rooms. <br /><br /><br /></p>


                                    <h4>WINTER [DECEMBER - FEBRUARY]</h4>
                                    <p><b>Winter</b> in Bhutan can be quite harsh. Due to the high Himalayan altitude, temperatures drop rapidly, and cities like Thimphu, Paro, and Punakha are often blanketed in snow, creating a serene and still atmosphere. In the northern, high-altitude regions, temperatures can even fall to sub-zero levels. As the weather becomes severe, the government of Bhutan typically declares holidays for schools, colleges, and government offices. Travel between different regions may become difficult due to heavy snowfalls. However, if you're keen to experience the snow and cold and want to enjoy the festivals that occur during winter, you can certainly plan your <strong>Bhutan package tour in December</strong>. Some of the notable festivals, like Monggar Tshechu, Trashigang Tshechu, Lhuntse Tshechu, and Trongsa Tshechu, take place between November and December. So, if you’re excited about experiencing Bhutan's winter charm, don't hesitate to book your <strong>Bhutan tour from Hasimara</strong> this season. Winter in Bhutan is truly magical! <br /><br /><br /></p>


                                    <h4>SPRING [MARCH - MAY]</h4>
                                    <p>After the cold and harsh winter, Bhutan comes to life during spring. The blooming of Jacaranda and Rhododendron flowers marks the arrival of spring, carpeting the roads, valleys, and surroundings in purple petals, creating a heavenly atmosphere. From March to May, the weather is typically dry with minimal rainfall, and the skies become crystal clear, signaling the perfect time for the world-famous Jomolhari and Drukpath treks. Additionally, this period hosts some of the most significant and auspicious festivals, including Paro Tshechu, Gasa Tshechu, Rhododendron festivals, and Talo Tshechu, where the King of Bhutan also actively participates. Since Bhutan is usually bustling with tourists during these months, Adorable Vacation advises booking your <strong>Bhutan package in May</strong> well in advance, along with your flights, to avoid the peak season surcharges, extra hotel costs, and increased flight prices. <br /><br /></p>
                                    <div id="no-more-tables" itemtype="https://schema.org/Table">

                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr><th>SEASON</th><th>MONTH</th><th>WEATHER</th><th>TEMPERATURE</th></tr>
                                                <tr><td><b>Winter</b></td><td>December</td><td>Cool and Dry</td><td>15°C - 24°C</td></tr>
                                                <tr><td></td><td>January</td><td>Cool and Dry</td><td>14°C - 24°C</td></tr>
                                                <tr><td></td><td>February</td><td>Cool and Dry</td><td>15°C - 25°C</td></tr>
                                                <tr><td><b>Spring</b></td><td>March</td><td>Warm and sunny</td><td>17°C - 29°C</td></tr>
                                                <tr><td></td><td>April</td><td>Warm and sunny</td><td>21°C - 32°C</td></tr>
                                                <tr><td><b>Summer</b></td><td>May</td><td>Hot and Humid</td><td>24°C - 34°C</td></tr>
                                                <tr><td></td><td>June</td><td>Hot and Humid</td><td>25°C - 35°C</td></tr>
                                                <tr><td></td><td>July</td><td>Hot and Humid</td><td>26°C - 35°C</td></tr>
                                                <tr><td></td><td>August</td><td>Hot and Humid</td><td>25°C - 34°C</td></tr>
                                                <tr><td><b>Autumn</b></td><td>September</td><td>Warm and rainy</td><td>24°C - 32°C</td></tr>
                                                <tr><td></td><td>October</td><td>Cool and Dry</td><td>22°C - 30°C</td></tr>
                                                <tr><td></td><td>November</td><td>Cool and Dry</td><td>18°C - 28°C</td></tr>
                                            </tbody>

                                        </table>
                                    </div>
                                    <br /><br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen16ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen17ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen18ImageURL}
                                                        alt="travel bhutan with best b2b dmc adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="row py-5">
                                <div className="col-md-12">
                                    <div className="site-heading text-center">
                                        <h3>
                                            <span>DETAIL ITINERARY FOR 6 NIGHTS 7 DAYS <strong>BHUTAN TOUR FROM HASIMARA</strong></span>
                                        </h3>
                                        <h4>6N/7D <strong>Bhutan Tour Package Itinerary From Hashimara</strong> from Adorable Vacation</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div
                                    className="accordion"
                                    id="accordionPanelsStayOpenExample"
                                >
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day1"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseOne"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseOne"
                                            >
                                                <h4> DAY 01: WELCOME TO HASIMARA AND TRANSFER TO THIMPHU </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseOne"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingOne"
                                        >
                                            <div className="accordion-body">
                                                <p>Today marks the start of your long-awaited <strong>Bhutan tour from Hasimara</strong>. Once you arrive in Phuentsholing, our representative will assist you with the permit process. You'll need to complete various formalities, including biometric data collection. The offline permit process typically takes between 2 to 6 hours, depending on the time of year and the crowd at the immigration office in Phuentsholing. During this waiting time, you can have lunch. Once your permit is ready, you'll begin your journey to Thimphu, the capital of Bhutan, which is about 4.5 hours away by car.
                                                    <br /><br />

                                                    If you have already processed your permits online, you can head directly to the Bhutan Gate to complete the biometric process. This usually takes about 1 hour, and once done, you can continue your journey to Thimphu. <br /><br />

                                                    Upon reaching Thimphu, check in at your hotel in the afternoon or evening. After freshening up, take a stroll around the city. You can explore the local nightlife, enjoy a local beer, try karaoke, or relax with a traditional hot stone bath at a spa. End your day with a delicious dinner and rest for the night in Thimphu. <br /><br /></p>
                                                <h4>BARS & NIGHT CLUBS TO HANGOUT NIGHTLIFE IN THIMPHU</h4><br /><br />
                                                <div id="no-more-tables" itemtype="https://schema.org/Table">

                                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                        <thead className="cf">
                                                            <tr><th>NAME</th><th>ADDRESS</th><th>TIMINGS</th></tr>
                                                        </thead>
                                                        <tbody><tr><td>Mojo Park</td><td>Chang Lam, Thimphu, Bhutan</td><td>5 PM - 12 AM</td></tr>
                                                            <tr><td>Space 34</td><td>Chang Lam, Main Town, Thimphu</td><td>9 PM - 1 AM</td></tr>
                                                            <tr><td>Om Bar</td><td>Thimphu, Bhutan</td><td>5 PM - 1 AM</td></tr>
                                                            <tr><td>Vivacity</td><td>Sheten Lam, Thimphu, Bhutan</td><td>7 PM - 12 AM</td></tr>
                                                            <tr><td>Club Ace</td><td>Thimphu, Bhutan</td><td>9 PM - 1 AM</td></tr>
                                                            <tr><td>Barz Brothers</td><td>Thimphu, Bhutan</td><td>Open 24 hours</td></tr>
                                                            <tr><td>Benez Bar</td><td>Jangchhub Lam, Thimphu, Bhutan</td><td>10 AM - 11 PM</td></tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br /> <br /><br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay1ImageURL} alt="Bhutan Package Tour From Guwahati with direct flights" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Bhutan Package Tour From Guwahati with direct flights" /></center>
                                                </div>
                                                <br /> <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day2"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseTwo"
                                            >
                                                <h4>DAY 02: FULL DAY THIMPHU SIGHTSEEING TOUR</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseTwo"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingTwo"
                                        >
                                            <div className="accordion-body">
                                                <p>Start your day with a hot cup of <b>Suja (Butter Tea)</b> as the sun shines over the beautiful Thimphu valley. After enjoying breakfast, get ready for a tour of Thimphu's local attractions. <br /><br /></p>

                                                <p><b>The National Memorial Chorten (Thimphu Chorten)</b> : This place is where many locals come for evening prayers. It was built in memory of Bhutan’s third king, Jigme Dorji Wangchuk, and was completed in 1974 after his untimely death. People walk around the chorten, spinning prayer wheels and chanting mantras. It stands as a symbol of peace and prosperity. <br /><br />

                                                    <b>The Clock Tower</b> : Located in the center of Thimphu, the Clock Tower Square is a beautiful example of Bhutanese architecture. The tower has four clock faces and is decorated with intricate carvings of flowers, paintings, and dragons. The dragons are facing each clock and represent the country’s independence. This area is popular for both locals and tourists, and it’s surrounded by shops, restaurants, and hotels. The square often hosts live music events, making it a lively spot. You can also buy gifts here or enjoy a picnic in the nearby playground. <br /><br />

                                                    <b>Sakyamuni Buddha</b> : On top of Kuensel Phodrang hill stands the giant statue of Buddha Dordenma. This 51.5-meter statue is made of gilded bronze, and it offers a stunning view of the valley. It’s a peaceful and breathtaking site, also known as Buddha Viewpoint. <br /><br />

                                                    <b>Changangkha Lhakhang</b> : One of the oldest and most important monasteries in Thimphu, dating back to the 15th century. It’s dedicated to Avalokiteshvara, the symbol of compassion. From here, you can enjoy a panoramic view of the Thimphu valley. <br /><br />

                                                    <b>Takin Zoo</b> : This zoo is home to Bhutan's national animal, the Takin, an endangered species found only in Bhutan. The animals live in an open habitat, allowing you to see them in a more natural environment. <br /><br />

                                                    <b>Trashi Chhoe Dzong</b> : Known as the Fortress of the Glorious Region, this site is a must-visit. It sits on the west bank of the Wang Chhu River and is the coronation site of Bhutan’s fifth king. It also hosts the annual <strong>Tsechu Festival</strong>. Built in 1641, it was later rebuilt in the 1960s using traditional Bhutanese methods, without nails or architectural plans. You’ll spend the night at a hotel in Thimphu.
                                                    <br /><br /></p>
                                                <div className="content-desktop">
                                                    <center><img src={navDay2ImageURL} alt="mumbai to Bhutan Package Tour From Guwahati booking with direct flights" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="mumbai to Bhutan Package Tour From Guwahati booking with direct flights" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day3"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseThree"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 03: THIMPHU TO PUNAKHA TRANSFER & SIGHTSEEING</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseThree"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingThree"
                                        >
                                            <div className="accordion-body">
                                                <p>Start your 3rd day <strong>Bhutan Trip from Hasimara</strong> in Thimphu with a cup of Hot Ngaja (sweet Bhutanese milk tea) and enjoy the view of the beautiful valley from your room. After breakfast, head to Punakha, the former capital of Bhutan. Punakha is a peaceful place, much quieter than the busy cities, sitting at an altitude of 1200 meters. It’s warm in winter and cool in summer. The valley is famous for growing red and white rice along the Pho and Mo Chu rivers. On the way, you’ll visit: <br /><br />

                                                    <b>Punakha Dzong</b>: Also called "<b>The Palace of Great Happiness</b>", this stunning fortress sits at the meeting point of the Pho Chhu (father) and Mo Chhu (mother) rivers. It’s one of the most beautiful dzongs in Bhutan. The dzong is connected to the mainland by an arched wooden bridge and is home to many valuable relics from the time when the kings ruled Bhutan from this valley. <br /><br />

                                                    <b>Suspension Bridge</b>: This hidden gem in the Punakha Valley connects the valley to the old administrative center of Punakha Dzongkhag. The bridge is covered with prayer flags and offers a great spot for birdwatching and taking photos. <br /><br />

                                                    <b>Chimi Lhakhang (Fertility Temple)</b>: Located on a hill, this temple is dedicated to Lama Drukpa Kuenley, also known as the 'Divine Madman.' He used humor and songs to teach his lessons. Chimi Lhakhang is considered a fertility temple, and it is believed that childless couples who visit and pray here will be blessed by Lama Drukpa Kuenley. The temple is reached by walking along a beautiful rural path through paddy fields. After crossing a small stream, a short climb will take you to the temple. You will stay overnight at a hotel in Punakha. <br /><br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay3ImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day4"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFour"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 04: - PUNAKHA TO PHOBJIKHA VALLEY DAY EXCURSION & RETURN </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseFour"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFour"
                                        >
                                            <div className="accordion-body">
                                                <p>As the Sun shines up high in the Land of Happiness, open your eyes to a beautiful morning in the lap of serene nature, and sip into your cup of hot tea while admiring the green valley from your bed before moving towards Phobjikha Valley post breakfast. <br /><br />

                                                    <b>Phobjikha Valley</b> : A treat for all nature lovers, and an idle place for spotting various birds, including the famous Black Necked Cranes that migrate to this valley. Being one of the most important wildlife preserves in Bhutan, this serene and untouched beauty of nature away from the hustle-bustle of the city life is a pleasure to enjoy with the happiest people surrounding the entire region that makes this place worth the visit. The Phobjikha Valley is the largest and the widest in Bhutan and is known for the panoramic vistas with tranquil nature that not only call the nature lovers to witness its beauty but also traps the adventure buffs who wish to follow the trekking and hiking routes while exploring the untouched beauty of nature. Overnight at hotel in Punakha. <br /><br /><br /></p>
                                                <br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay4ImageURL} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day5"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFive"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 05: PUNAKHA TO PARO TRANSFER & LOCAL SIGHTSEEING </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseFive"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFive"
                                        >
                                            <div className="accordion-body">
                                                <p>After breakfast, start your journey to Paro and visit the following places on the way: <br /><br />

                                                    <b>National Museum of Bhutan</b> : Located above Rinpung Dzong in Paro, the National Museum of Bhutan showcases Bhutan’s culture. The museum was built in 1968 inside the old Ta Dzong building. It displays some of the best examples of Bhutanese art and culture, including beautiful paintings and bronze statues. The museum has large galleries where you can learn about Bhutan’s history and culture. <br /><br />

                                                    <b>Drukgyal Dzong</b> : Drukgyal Dzong was a fortress and Buddhist monastery, now in ruins, located in the upper Paro Valley. It was built in 1649 to celebrate Bhutan’s victory over a Tibetan invasion. The site is currently closed. <br /><br />

                                                    <b>Ta Dzong</b> : Ta Dzong was originally a watchtower built to protect Rinpung Dzong during the 17th-century wars. It became the National Museum of Bhutan in 1968. <br /><br />

                                                    <b>Rinpung Dzong</b> : Built in 1646 by Shabdrung Ngawang Namgyal, the first ruler of Bhutan, Rinpung Dzong houses the monks of Paro, the district head (Dzongda), and the judge (Thrimpon). To reach the dzong, you cross a traditional covered bridge called Nemi Zam. <br /><br />

                                                    <b>Nya Mey Zam Bridge</b> : Known as "The Bridge with No Fish," this bridge connects Paro Town to the Dzong. Locals believe that seeing a fish in the river is a bad omen. The original bridge, which could be folded during times of war, was destroyed in a flood in 1969. <br /><br />

                                                    <b>Paro Airport View Point</b> : This viewpoint offers the best view of Paro Valley and Paro Airport. You can see the Paro River, the dzongs, and monasteries from here. Overnight stay at a hotel in Paro. <br /><br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay5ImageURL} alt="Bhutan Packages From Guwahati Tour Operator" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="Bhutan Packages From Guwahati Tour Operator" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day6"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSix"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 06: PARO, TAKTSANG MONASTERY HIKING / CHELE-LA PASS EXCURSION</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSix"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSix"
                                        >
                                            <div className="accordion-body">
                                                <p>Wake up in the beautiful Paro Valley as the sun rises, surrounded by nature. Enjoy the amazing view of the river and mountains. Have a hearty breakfast with plenty of protein, because the hike to Taktsang Monastery will require some energy. <br /><br />

                                                    <b>Taktsang Monastery</b>: The hike to Taktsang Monastery, also known as the Tiger's Nest, takes you up a steep cliff for 1,200 meters. Once you reach the top, take a deep breath and enjoy the fresh air and the incredible view of the valley below. The monastery was built in 1694 and is a sacred site founded by Guru Rinpoche, who brought Buddhism to Bhutan in 747 CE. After a 3-hour trek, you’ll reach the monastery. If you prefer, you can take a horse ride from the parking lot to the top. During your hike, you’ll be treated to beautiful views of Paro town, with its green valleys, clear blue sky, and sparkling river. It’s a perfect place to enjoy nature. Overnight in Paro. <br /><br /><br /><br /></p>

                                                <h4>SOME FACTS TO NOTE ABOUT - THE TIGER'S NEST MONASTERY</h4>
                                                <br /><br />
                                                <div id="no-more-tables">
                                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                        <tbody><tr><td>Location</td><td>Approx 11.7 km from Paro town, Bhutan</td></tr>
                                                            <tr><td>Elevation</td><td>3,120 meters (10,236ft) above sea level</td></tr>
                                                            <tr><td>Duration of the Tiger's Nest hike</td><td>5-6 hours (Back and forth)</td></tr>
                                                            <tr><td>Tiger's Nest Trekking Distance</td><td>4 Miles (6.43 Kilometers) - Round Trip</td></tr>
                                                            <tr><td>Tiger's Nest Trek Difficulty</td><td>Moderate - Steep Ascend</td></tr>
                                                            <tr><td>Best Months to Visit Tiger's Nest Monastery</td><td>March, April, May and September, October, November, December</td></tr>
                                                            <tr><td>Gears needed to hike Tiger's Nest</td><td>Normal hiking shoes, Water bottle, Some snacks, energy bars, Camera, Hat, Sunglasses, Sunscreen, Walking Pole/Stick</td></tr>
                                                            <tr><td>Opening time of Tiger's Nest Monastery</td><td>(October-March) 8 am-1 pm and 2 pm-5 pm <br /> (April-September) 8 am-1 pm and 2 pm-6 pm daily</td></tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br /><br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay6ImageURL} alt="B2B Tour Operator for Bhutan" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="B2B Tour Operator for Bhutan" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day7"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 07: PARO TO HASIMARA DROP</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>After breakfast, say goodbye to the Last Buddhist Kingdom from Paro and head to Phuentsholing, taking with you unforgettable memories of your Bhutan holiday. <br /><br /><br /></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="container-fluid py-5">
                                <table className="table-borderDodont">
                                    <thead>
                                        <tr>
                                            <td className="tomato" style={{ fontSize: "1.5vw" }}>DO'S : <strong>BHUTAN TRIP FROM HASIMARA</strong></td>
                                            <td className="tomato" style={{ fontSize: "1.5vw" }}>DONT'S : <strong>BHUTAN TRIP FROM HASIMARA</strong></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Listen to your tour guide and follow their instructions. </td>
                                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Do not take photographs of local people, monks, events, or objects without their permission.  </td>
                                        </tr>
                                        <tr>
                                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Bring enough cash, as many places don’t take credit cards. </td>
                                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Do not take photographs inside dzongs, temple, monasteries, sacred places, and religious sites.  </td>
                                        </tr>
                                        <tr>
                                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Be polite and respectful to older people.</td>
                                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Do not climb on to the top of any of the religious sites or sacred places. </td>
                                        </tr>
                                        <tr>
                                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Only enter temples and monasteries if you have permission.</td>
                                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Do not touch holy objects inside the monastery or any sacred sites. </td>
                                        </tr>
                                        <tr>
                                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Dress modestly; avoid wearing sleeveless tops and short skirts.</td>
                                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Do not point your finger or feet at anyone while talking.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Take off your shoes before going into a temple.</td>
                                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Do not touch the forehead of any person as it is considered a sign of disrespect.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> If photography isn’t allowed, keep your camera safe.</td>
                                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Do not wear revealing or skimpy clothes when entering any place of religious significance. </td>
                                        </tr>
                                        <tr>
                                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Don’t wear hats or sunglasses inside religious sites.</td>
                                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Do not speak loudly inside the religious premises</td>
                                        </tr>
                                        <tr>
                                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Keep your valuables, like wallets and passports, in a safe place.</td>
                                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Use Dustbin when needed. Do not through rubbish inside temples or any scared sites. </td>
                                        </tr>
                                        <tr>
                                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Always follow traffic rules when on the road.</td>
                                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Do not say anything negative about the country, its Royal couple, or discuss political issues with locals.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br /><br />
                            <table style={{ width: '100%', textAlign: 'center' }}>
                                <tbody>
                                    <tr>
                                        <td className="image-cell">
                                            <img
                                                src={sightseen19ImageURL}
                                                alt="Bhutan Guwahati group tour from bagdogra with adorable"
                                                style={{ margin: '10px' }}
                                            />
                                            <img
                                                src={sightseen20ImageURL}
                                                alt="Bhutan Guwahati travel packages"
                                                style={{ margin: '10px' }}
                                            />
                                            <img
                                                src={sightseen21ImageURL}
                                                alt="travel bhutan with best b2b dmc adorable vacation"
                                                style={{ margin: '10px' }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>IMPORTANT FACTS TO KNOW BEFORE <strong>HASIMARA TO BHUTAN TRIP</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>IMPORTANT FACTS TO KNOW BEFORE <strong>HASIMARA TO BHUTAN TRIP</strong></h3>
                                </div>
                                <div className="card-body">
                                    <li>Locals call their country "Druk Yul," which means "the Land of the Dragons." <br /><br /></li>
                                    <li>Bhutan has never been conquered or ruled by outside forces. <br /><br /></li>
                                    <li>Bhutan is the only carbon-negative country in the world. <br /><br /></li>
                                    <li>Bhutan was isolated from the rest of the world until the 1970s. <br /><br /></li>
                                    <li>You won’t find any traffic lights in Bhutan. <br /><br /></li>
                                    <li>Bhutan is a constitutional monarchy. <br /><br /></li>
                                    <li>Bhutan does not use GDP as a measure of economic growth. <br /><br /></li>
                                    <li><b>GNH</b> (Gross National Happiness) is the measure of growth and development in Bhutan. <br /><br /></li>
                                    <li>Bhutan is a plastic-free country. <br /><br /></li>
                                    <li>There are fewer than 20 qualified pilots who can fly into Bhutan's only airport. <br /><br /></li>
                                    <li>Smoking is not allowed in Bhutan and is considered a punishable offense. <br /><br /></li>
                                    <li>Bhutan has some of the world’s highest unclimbed mountain peaks, including Gangkhar Puensum at 7,570m (24,981ft). <br /><br /></li>
                                    <li>The national animal of Bhutan is the Takin, a goat-antelope. <br /><br /></li>
                                    <li>Bhutanese food is very spicy, and you will find chilies in almost every dish. <br /><br /></li>
                                    <li>Education and healthcare are free for all citizens. <br /><br /></li>
                                    <li>Archery is the national sport of Bhutan. <br /><br /></li>
                                    <li>Bhutanese people love their king, and you’ll find his photo in every home. <br /><br /></li>
                                    <li>Bhutan has a very low crime rate. <br /><br /></li>
                                    <li>Wearing the national dress (Gho for men and Kira for women) is mandatory in schools, government offices, and during festivals. <br /><br /></li>
                                    <li>Cleanliness is very important in Bhutan, and the environment is highly prioritized. <br /><br /></li>
                                    <li>Bhutanese people often chew betel leaf and areca nut. <br /><br /></li>
                                    <li>Ema Datshi is the national dish of Bhutan, made with spicy chilies and local cheese. <br /><br /></li>


                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen13ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen16ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen18ImageURL}
                                                        alt="travel bhutan with best b2b dmc adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> ACTIVITIES TO ENJOY DURING YOUR <strong>BHUTAN TOUR FROM GUWAHATI</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>ACTIVITIES TO ENJOY DURING YOUR <strong>BHUTAN TOUR FROM GUWAHATI</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                <Activityfecilities />
                                            }
                                        </div>
                                    </div>
                                    <br /><br />

                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen14ImageURL}
                                                        alt="Bhutan Guwahati group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen17ImageURL}
                                                        alt="Bhutan Guwahati travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen20ImageURL}
                                                        alt="travel bhutan with best b2b dmc adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            {/* <!--FOQ--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                Frequently Ask <span>Questions</span>
                                            </h2>
                                            <h4>Related Questions for <strong>BHUTAN TOUR PACKAGES FROM HASIMARA</strong></h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="accordion accordion-flush faQStyle"
                                        id="accordionFlushExample"
                                    >
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingIdealTime">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseIdealTime"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseIdealTime"
                                                >
                                                    When is the ideal time to Bhutan tour from Hasimara?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseIdealTime"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingIdealTime"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    October to December is the best time to enjoy a pleasant holiday experience in the country. During summer, March to June is also considered the best time for a Hasimara to Bhutan tour.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSafety">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSafety"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseSafety"
                                                >
                                                    Is it safe to visit Bhutan from Hasimara?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSafety"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingSafety"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Bhutan is typically safe and has an extremely low crime rate. However, one should use caution and abstain from going alone at night into remote locations.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingVisa">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseVisa"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseVisa"
                                                >
                                                    Do I need a visa to visit Bhutan from Hasimara?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseVisa"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingVisa"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Yes, foreign nationals (except Indian, Bangladeshi, and Maldivian nationals) require a visa to visit Bhutan. Bhutanese visas are issued only through a licensed tour operator. You can arrange your visa through Adorable Vacation, handling your <strong>Bhutan package tour from Hasimara</strong>.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingDocuments">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseDocuments"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseDocuments"
                                                >
                                                    What documents do I need to travel to Bhutan from Hasimara?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseDocuments"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingDocuments"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    For Indian nationals, a valid passport or Voter ID card is required to enter Bhutan. For other foreign nationals, you will need a visa and a passport with at least six months of validity. Additionally, you will need to carry a photograph for the visa process.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTravelTime">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseTravelTime"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseTravelTime"
                                                >
                                                    How long does it take to reach Bhutan from Hasimara?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseTravelTime"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingTravelTime"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    The drive from Hasimara to the Phuentsholing border takes around 45 minutes to 1 hour. After crossing the border, you will need to undergo immigration and customs checks, which can take additional time. From Phuentsholing, it typically takes about 5-6 hours to reach Thimphu, the capital of Bhutan.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingCurrency">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseCurrency"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseCurrency"
                                                >
                                                    What is the currency used in Bhutan? Can I exchange money at Phuentsholing?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseCurrency"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingCurrency"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Bhutan's currency is the Bhutanese Ngultrum (BTN), which is pegged to the Indian Rupee (INR). Indian Rupees (INR) are also widely accepted in Bhutan. You can exchange currency at banks or exchange centers in Phuentsholing or Thimphu.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingRestrictions">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseRestrictions"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseRestrictions"
                                                >
                                                    Are there any travel restrictions for Bhutan from Hasimara?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseRestrictions"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingRestrictions"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Travel to Bhutan is relatively straightforward for Indian nationals. However, foreign nationals are required to arrange their visa through a licensed Bhutanese tour operator. There may also be some restrictions on entry during the Bhutanese New Year (Tshechu) or national festivals, so it’s a good idea to check in advance.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingCustomize">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseCustomize"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseCustomize"
                                                >
                                                    Can I customize my Bhutan package tour from Hasimara?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseCustomize"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingCustomize"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Yes, with Adorable Vacation – the best Bhutan tour operator in India, you can have customized Bhutan packages with itineraries based on your interests, budget, and time constraints. You can choose from various options like trekking, cultural tours, or exploring offbeat destinations.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFood">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFood"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFood"
                                                >
                                                    Is food available in Bhutan for vegetarians and non-vegetarians?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseFood"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingFood"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Bhutan offers a wide variety of food options, including both vegetarian and non-vegetarian dishes. Bhutanese cuisine includes rice, meat (mostly pork, chicken, and beef), and vegetables, with dishes like Ema Datshi (chili and cheese), Phaksha Paa (pork with red chili), and Red Rice. International cuisines are also available in most tourist areas. With Adorable Vacation, you don’t need to worry about food. The company offers various food options based on your requirement.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>
                                                <b>
                                                    Bhutan <span className="tomato">Theme Package</span>
                                                </b>
                                            </h3>
                                            <h4>Similar Mountain Trip</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        id="carouselExampleControls"
                                        className="carousel slide"
                                        data-bs-ride="carousel"
                                    >
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <Slider {...settings}>{themePackagesDataView}</Slider>
                                            </div>
                                        </div>
                                    </div>

                                    <br />
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>
                                                <b>
                                                    Bhutan <span className="tomato">Tour Reviews</span>
                                                </b>
                                            </h3>
                                            <h4>
                                                <i>"Travel without Trouble"</i>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1 ">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">
                                            {reviewersDataView}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3">

                            <BookingBox />

                        </div>
                        {this.state.showModal && (
                            <div className="modal d-flex align-items-center justify-content-center" style={{ display: this.state.showModal ? "block" : "none" }}>
                                <div className="modal-dialog">
                                    <div className="modal-content cust-modContent" ref={this.modalRef}>
                                        <div className="modal-header">
                                            <h4 className="modal-title ms-auto" id="exampleModalLabel">Get a Free Callback &nbsp;&nbsp;<i className="fa fa-phone fa-rotate-90"></i>
                                            </h4>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.onCloseModal}></button>
                                        </div>
                                        <div className="modal-body">
                                            <form className="row g-3">
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name *"
                                                        name="firstName"
                                                        value={this.state.firstName}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="10 Digit Mobile No.*"
                                                        name="mobile"
                                                        value={this.state.mobile}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Email "
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Comments"
                                                        rows="2"
                                                        name="comments"
                                                        value={this.state.comments}
                                                        onChange={this.onChangeHandler}></textarea>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <div className='container'>
                                                <div className="row">
                                                    <div className='col-sm-4'>
                                                        {
                                                            (this.state.showLoader) &&
                                                            <div className='pull-left'><Digital size={25} /></div>
                                                        }
                                                    </div>
                                                    <div className='col-sm-8 '>
                                                        <div className='pull-right'>
                                                            {/* <button type="button" className="btn btn-Warning mr-1" onClick={this.onCloseModal}>Close</button> */}
                                                            <button type="button" className="btn btn-Warning btn-lg" onClick={this.onRequestCallbackSubmit}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default Bhutan_PackageTourHashimaraNoq;