import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Kashmir/InclusionExclusion_kashmir_vaishno_devi_amritsar';

// import FloatingWhatsAppButton from 'react-floating-whatsapp';

class Kashmir_9N_10D_Vaishno_Devi_Amritsar_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-Vaishno-Devi-Amritsar-Package-Tour.webp";
        const packageTopBanner2ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-Vaishno-Devi-Amritsar-Tour-Package.webp";
        const sightseen1ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Delhi-to-Kashmir-Tour-Packages.webp";
        const sightseen2ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Magical-Trip-to-kashmir-from-Delhi.webp";
        const sightseen3ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-Package-Tour-Cost.webp";
        const sightseen4ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-Package-Tour-Price-ex-Srinagar.webp";
        const sightseen5ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-Tour-Itinerary-from-Delhi.webp";
        const sightseen6ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-Tour-Package-from-Delhi.webp";
        const sightseen7ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-Tour-Plan-from-Delhi.webp";
        const sightseen8ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-Trip-from-Delhi.webp";
        const sightseen9ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Customized-Kashmir-Tour-Itinary.webp";
        const sightseen10ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-from-Delhi.webp";
        const sightseen11ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-Honeymoon-Trip.webp";
        const sightseen12ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-Package-Tour.webp";
        //day wise itenaryy
        const navDay1ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-Vaishno-Devi-Amritsar-Package-Tour-itinary.webp";
        const navDay2ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-Vaishno-Devi-Amritsar-Tour-Packages.webp";
        const navDay3ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-package-tour-with-Amritsar-Katra-Vaishno-Devi.webp";
        const navDay4ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-Vaishno-Devi-Amritsar-tour-operator.webp";
        const navDay5ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-Vaishno-Devi-Amritsar-Tour-Packages-Cost.webp";
        const navDay6ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-Trip-Booking.webp";
        const navDay7ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-trip.webp";
        const navDay8ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Katra-to-Vaishno-Devi.webp";
        const navDay9ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Amritsar-Package-Tour.webp";
        const navDay10ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-Tour-Package-cost.webp";
        const Topbanner0ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-Houseboat-Packages.webp";
        const Topbanner1ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-Adventure-Tour-Plan.webp";
        const Topbanner2ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-Gondola-Package-Tour.webp";
        const Topbanner3ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-Trekking-Packages.webp";
        const Topbanner4ImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/Kashmir-White-River-Rafting-Plan.webp";
        const brandedTourackageImage_1_URL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/The-Khyber-Himalayan-Resort-&-Spa.webp";
        const brandedTourackageImage_2_URL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/The-LaLiT-Grand-Palace-Srinagar-Hotel.webp";
        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Kashmir_Vaishno_devi_Amritsar_Tour_Package + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>

                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">

                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>Kashmir Vaishno Devi Amritsar Package Tour  </strong></center></h1>

                                        <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Kashmir Vaishno Devi Amritsar Package Tour  </strong></center>
                                            <hr />
                                        </h1>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Destination:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Amritsar (1N)
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Katra (2N)
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Srinagar (4N)
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Pahalgam (2N)
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Kashmir Vaishno Devi Amritsar Package Tour</strong>, you have come to the right place!
                                        </p>
                                        <hr />
                                        <br />
                                    </div>
                                    <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}> Searching for <strong> Kashmir Vaishno Devi Amritsar Tour Packages ?</strong> To book a Vaishno Devi Amritsar Tour Package <a href='tel:9147061467' class="tomato">☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> Searching for <strong> Kashmir Vaishno Devi Amritsar Tour Packages ?</strong> To book a Vaishno Devi Amritsar Tour Package <a href='tel:9147061467' class="tomato">☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Experience the enchantment of Kashmir Vaishno Devi Amritsar Tour Packages</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Experience the enchantment of Kashmir Vaishno Devi Amritsar Tour Packages</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Welcome to an Inspiring Journey of Spirituality and Nature! Join us on a special trip that mixes spirituality, beautiful nature, and rich culture. Our <strong>Amritsar Vaishno Devi Kashmir Tour Package</strong>, including Amritsar and Katra Vaishno Devi, promises an unforgettable experience.</p>
                                            <br />
                                            <h5><b>Amritsar: A Place of Spirit and Life</b></h5>
                                            <br />
                                            <p>Your adventure starts in Amritsar, a city full of spirit and energy. The famous Golden Temple, a stunning and peaceful place, invites you to enjoy its calm surroundings. This is a perfect spot to relax and reflect.</p>
                                            <p>You’ll also witness the exciting Wagah Border ceremony, where Indian and Pakistani soldiers show their patriotic spirit.</p>
                                            <p>Explore the busy streets of Amritsar’s old market, filled with colorful sights and delicious smells. Don’t miss tasting the famous Amritsari Kulcha and enjoying a meal at the Golden Temple, a heartwarming experience.</p>
                                            <br />
                                            <h5><b>Vaishno Devi: A Journey of Faith</b></h5>
                                            <br />
                                            <p>Next, we’ll go to Katra, the starting point for the sacred Vaishno Devi temple. The trek to the temple is a spiritual journey that many people take each year. The beautiful mountains and peaceful atmosphere make this pilgrimage special.</p>
                                            <p>Our <strong>Amritsar to Kashmir Tour Packages</strong> ensures a smooth and comfortable trip so you can focus on the spiritual significance of your visit. Seek blessings from Mata Vaishno Devi and feel the divine energy around you.</p>
                                            <p>After the spirituality of Amritsar and Vaishno Devi, get ready to be amazed by the stunning beauty of Kashmir. Our tour opens the door to a land of snow-capped mountains, green valleys, and peaceful lakes.</p>
                                            <br />
                                            <ul>
                                                <li><b>• Dal Lake</b>: Enjoy a ride on a Shikara (a traditional boat) on this beautiful lake, known for its floating gardens and houseboats.</li><br />
                                                <li><b>• Gulmarg</b>: Known as the 'Meadow of Flowers,' it’s perfect for nature lovers. In winter, it’s great for skiing, and in summer, it’s filled with blooming flowers.</li><br />
                                                <li><b>• Pahalgam</b>: A lovely town by the Lidder River, surrounded by forests and mountains, ideal for relaxing walks</li><br />
                                                <li><b>• Betaab Valley</b>: Famous for its stunning scenery, this valley is great for breathtaking views.</li><br />
                                                <li><b>• Wular Lake</b>: One of the largest freshwater lakes in Asia, it’s perfect for birdwatching, especially in winter.</li><br />
                                                <li><b>• Manasbal Lake</b>: Known for its lovely lotus flowers, it’s a peaceful spot for a boat ride.</li><br />
                                                <li><b>• Gangbal Lake</b>: A high-altitude lake surrounded by mountains, great for trekkers.</li>
                                            </ul><br />
                                            <p>So, book your Amritsar, Vaishno Devi, and Kashmir tour package today for a trip full of spirituality, adventure, and peace!</p>
                                            <p><b>Apart from that, we also provide,</b></p>
                                            <a href='/kashmir-offbeat-package-tour-with-baisaran-valley-doodhpathri-yusmarg' target='_blank'><b className="tomato">Kashmir Package Tour with Doodhpathri Yusmarg</b></a>, <a href='/kashmir-offbeat-package-tour-with-gurez-valley' target='_blank'><b className="tomato">Kashmir Package Tour with Gurez Valley</b></a>,
                                            <a href='/kashmir-offbeat-package-tour-with-sinthan-top-aru-valley-betab-valley-chandanbari-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Offbeat Package Tour with Sinthan Top</b></a>,
                                            <a href='/kashmir-package-tour-with-vaishno-devi-katra-pahalgam-gulmarg-srinagar' target='_blank'><b className="tomato">Kashmir Package Tour with Vaishno Devi</b></a>,
                                            <a href='/kashmir-tulip-garden-festival-package-tour' target='_blank'><b className="tomato">Kashmir Package Tour with Tulip Package</b></a>,
                                            <a href='/kashmir-package-tour-from-kolkata-with-srinagar-gulmarg-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Package Tour from Kolkata </b></a>,
                                            <a href='/kashmir-package-tour-from-surat' target='_blank'><b className="tomato">Kashmir Package Tour from Surat </b></a>,
                                            <a href='/kashmir-package-tour-from-chennai' target='_blank'><b className="tomato">Kashmir Package Tour from Chennai </b></a>,
                                            <a href='/kashmir-package-tour-from-bangalore' target='_blank'><b className="tomato">Kashmir Package Tour from Bangalore </b></a>,
                                            <a href='/kashmir-package-tour-from-pune' target='_blank'><b className="tomato">Kashmir Package Tour from Pune </b></a>,
                                            <a href='/kashmir-package-tour-from-mumbai' target='_blank'><b className="tomato">Kashmir Package Tour from Mumbai </b></a>,
                                            <a href='/kashmir-package-tour-from-delhi' target='_blank'><b className="tomato">Kashmir Package Tour from Delhi </b></a>,
                                            <a href='/kashmir-package-tour-from-chennai' target='_blank'><b className="tomato">Kashmir Package Tour from Chennai </b></a>,
                                            <a href='/kashmir-package-tour-from-ahmedabad' target='_blank'><b className="tomato">Kashmir Package Tour from Ahemdabad </b></a>
                                            <br /><br />
                                            <p>Book your dream destination with Adorable Vacation and for further information do not hesitate to contact us at +91 9147061467</p>
                                            <br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen1ImageURL} alt="kashmir tour packages" />
                                                            <img src={sightseen2ImageURL} alt="kashmir trip from srinagar airport" />
                                                            <img src={sightseen3ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /> <br /> <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>Duration of <strong>Kashmir Package Tour with Amritsar Katra Vaishno Devi : 9N | 10D</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>Kashmir Package Tour with Amritsar Katra Vaishno Devi : 9N | 10D</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Places to cover in <strong>Vaishno Devi Kashmir Amritsar Package Tour </strong> : Amritsar 1N | Katra 2N | Srinagar 4N | Pahalgam 2N </p>
                                            <p>Pickup <b>Amritsar Katra Vaishno Devi Kashmir Tour</b> : Jammu Tawi Railway Station (JAT) / Jammu Airport (IXJ)
                                            </p>
                                            <p><b>Drop :</b> Sheikh Ul Alam International Airport, Srinagar (SXR)</p>
                                            <br /><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td>Amritsar to Kashmir Tour Pickup Location</td>
                                                            <td>Amritsar Central Railway Station (ASR)</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Amritsar Package Tour Duration</strong></td>
                                                            <td>09 Nights | 10 Days</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Kashmir Vaishno Devi Amritsar Tour Packages Cost </strong></td>
                                                            <td>Rs. 34,500/Based on 8 Pax</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Amritsar</strong></td>
                                                            <td>234 m | Coordinates - 31.6340° N, 74.8723° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Vaishno Devi</strong></td>
                                                            <td>1,585 m | Coordinates - 33.0308° N, 74.9490° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Srinagar</strong></td>
                                                            <td>1,585 m | Coordinates - 34.0837° N, 74.7973° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Gulmarg</strong></td>
                                                            <td>2,650 m | Coordinates - 34.0484° N, 74.3805° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Sonmarg</strong></td>
                                                            <td>2,730 m | Coordinates - 34.3032° N, 75.2931° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Pahalgam</strong></td>
                                                            <td>2,740 m | Coordinates - 34.0161° N, 75.3150° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Doodhpathri</strong></td>
                                                            <td>2,730 m | Coordinates - 33.5423° N, 74.3615° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Betaab Valley</strong></td>
                                                            <td>2,393 m | Coordinates - 34.0541° N, 75.3639° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Aru Valley</strong></td>
                                                            <td>2,414 m | Coordinates - 34.0922° N, 75.2632° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Chandanwadi</strong></td>
                                                            <td>3,288 m | Coordinates - 34.2122° N, 73.9295° E</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br /><br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen4ImageURL} alt="kashmir package tour from srinagar" />
                                                            <img src={sightseen5ImageURL} alt="kashmir package price from srinagar airport" />
                                                            <img src={sightseen6ImageURL} alt="kashmir packages from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />

                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    {/* start short itinerary */}

                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr className="table-danger">
                                                        <th>DAY WISE PLAN <strong>KASHMIR PACKAGE TOUR WITH AMRITSAR KATRA VAISHNO DEVI</strong></th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingOne"
                                                        >
                                                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                                <span>
                                                                    Arrival in Amritsar and Visit to Wagah Border
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingTwo"
                                                        >
                                                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                                <span>Golden Temple Excursion and Transfer to Katra</span>
                                                            </a>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingThree"
                                                        >
                                                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                                <span>Vaishno Devi Darshan</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFour"
                                                        >
                                                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                                <span>Transfer from Katra to Srinagar</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFive"
                                                        >
                                                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                                <span>Srinagar to Gulmarg Excursion</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSix"
                                                        >
                                                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                                <span>Srinagar to Sonmarg Excursion</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSeven"
                                                        >
                                                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                                <span>Srinagar Local Sightseeing </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingEight"
                                                        >
                                                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                                                                <span>Srinagar to-Pahalgam </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingEight"
                                                        >
                                                            <a href="#day9" style={{ color: 'black' }}><b>DAY 9 → </b>
                                                                <span>Pahalgam sightseeing </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingEight"
                                                        >
                                                            <a href="#day10" style={{ color: 'black' }}><b>DAY 10 → </b>
                                                                <span>Departure to Jammu Tawi Station </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <!--end Short description--> */}
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT IS THE IMPORTANCE OF THE <strong>GOLDEN TEMPLE IN THE AMRITSAR TOUR</strong>?</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT IS THE IMPORTANCE OF THE <strong>GOLDEN TEMPLE IN THE AMRITSAR TOUR</strong>?</h4>
                                        </div>
                                        <div className="card-body">

                                            <p>The Golden Temple, also called Sri Harmandir Sahib, is very special to millions of people. Located in Amritsar, it is more than just a religious site; it symbolizes unity, spirituality, and inclusivity. Here’s why the Golden Temple is important and how it fits into the Amritsar Tour Packages.</p>
                                            <br />
                                            <p><b>Spiritual Center</b>: The Golden Temple is the holiest site in Sikhism, attracting visitors from all backgrounds. The peaceful surroundings and the Amrit Sarovar (Pool of Nectar) create a calm space for meditation and prayer.</p>
                                            <p><b>Architectural Beauty</b>: With its stunning gold-plated exterior, the Golden Temple is a beautiful example of Sikh architecture. Its four entrances represent the inclusivity of Sikhism, welcoming everyone. The reflection of the temple in the Amrit Sarovar is truly breathtaking.</p>

                                            <p><b>Historical Importance</b>: The temple has a rich history, including being founded by Guru Arjan Dev Ji. Nearby, the Akal Takht is the seat of Sikh religious authority, showing the leadership of the Sikh community.</p>

                                            <p><b>Langar - Community Kitchen</b>: A unique feature of the Golden Temple is the Langar, a free kitchen that serves meals to all visitors, regardless of their background. This tradition represents the Sikh values of equality, selfless service, and community support.</p>
                                            <p>The Golden Temple in Amritsar is not just a place of worship; it represents unity, spirituality, and acceptance. Its importance attracts people from all walks of life. As part of the Kashmir Vaishno Devi Amritsar Package Tour, visiting the Golden Temple offers a rich cultural and spiritual experience, making it a perfect starting point for your memorable journey.</p>
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen10ImageURL} alt="kashmir package tour booking from srinagar" />
                                                            <img src={sightseen11ImageURL} alt="kashmir package tour booking from srinagar airport" />
                                                            <img src={sightseen12ImageURL} alt="kashmir package tour cost from srinagar" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>KASHMIR VAISHNO DEVI AMRITSAR TOUR PACKAGES COST</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>KASHMIR VAISHNO DEVI AMRITSAR TOUR PACKAGES COST</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>TOUR DURATION</th>
                                                            <th>2 PAX</th>
                                                            <th>4 PAX</th>
                                                            <th>6 PAX</th>
                                                            <th>8 PAX</th>
                                                            <th>10 PAX</th>
                                                            <th>12 PAX</th>
                                                            <th>BOOK NOW</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">9N/10D</td>
                                                            <td data-title="2 ADULTS">₹ 47,300/-</td>
                                                            <td data-title="4 ADULTS">₹ 39,000/-</td>
                                                            <td data-title="6 ADULTS">₹ 35,200/-</td>
                                                            <td data-title="8 ADULTS">₹ 34,500/-</td>
                                                            <td data-title="10 ADULTS">₹ 33,200/-</td>
                                                            <td data-title="12 ADULTS">₹ 32,000/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>EXPLORE THE BEAUTIFUL SRINAGAR WITH OUR <strong>AMRITSAR VAISHNO DEVI KASHMIR TOUR PACKAGE</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>EXPLORE THE BEAUTIFUL SRINAGAR WITH OUR <strong>AMRITSAR VAISHNO DEVI KASHMIR TOUR PACKAGE</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Nestled in the stunning Kashmir Valley, Srinagar is a beautiful city known for its clear lakes, lush gardens, and classic Mughal architecture. It’s the perfect place for anyone looking for a peaceful getaway. Join us on the <strong>Amritsar Katra Vaishno Devi Kashmir Tour</strong> to discover the most beautiful spots in Srinagar and create unforgettable memories.</p>
                                            <p><b>Dal Lake - The Gem of Srinagar</b>:
                                                Dal Lake is the most famous attraction in Srinagar. Surrounded by snow-capped mountains, it offers unique experiences like Shikara rides and houseboats. Don’t miss the floating market, where you can find colorful flowers and local crafts sold from Shikaras. Visiting Dal Lake is more than just sightseeing; it’s an experience you won’t forget.
                                            </p>
                                            <p><b>Shalimar Bagh - Mughal Beauty</b>:
                                                Visit Shalimar Bagh, a stunning Mughal garden built by Emperor Jehangir for his wife. With terraced lawns, colorful flowers, and flowing water, this garden feels grand and romantic. Walking through its beautiful pathways will transport you to a serene world.</p>

                                            <p><b>Nishat Bagh - The Garden of Joy</b>:
                                                Next to Dal Lake, Nishat Bagh, also known as the Garden of Bliss, offers amazing views of the lake and mountains. Its fountains, green plants, and colorful flowerbeds create a peaceful atmosphere, perfect for a leisurely walk or a quiet retreat.</p>

                                            <p><b>Jamia Masjid - A Beautiful Mosque</b>:
                                                Jamia Masjid is a grand mosque that showcases the architectural beauty of Srinagar. Built in the 14th century, it features intricate wooden carvings and Persian calligraphy. Its calm atmosphere makes it a great spot for visitors interested in culture and spirituality.</p>

                                            <p><b>Hazratbal Shrine - A Sacred Place</b>:
                                                Located on the northern shore of Dal Lake, Hazratbal Shrine is an important religious site. The white marble building reflects beautifully in the lake, creating a stunning view. It’s a place of worship that attracts many visitors, offering a peaceful environment for reflection.</p>

                                            <p><b>Pari Mahal - The Fairy’s Abode</b>:
                                                Pari Mahal is a historical garden palace that offers breathtaking views of Dal Lake and the city. Built by Dara Shikoh, the Mughal prince, this seven-terraced garden is an architectural marvel, blending Islamic and Persian styles. It's a must-see for history lovers and those seeking scenic beauty.</p>

                                            <p><b>Shankaracharya Temple - A Place of Peace</b>:
                                                The Shankaracharya Temple, located on a hill, offers a tranquil escape and stunning views of Srinagar. Dedicated to Lord Shiva, this ancient temple requires climbing about 250 steps but rewards visitors with beautiful sights of the city and Dal Lake.</p>
                                            <p>Make sure to include these attractions in your <strong>Amritsar Kashmir Tour Packages</strong>. Each place has its own story, creating a magical experience that you will always remember. Explore Srinagar’s captivating sites, and let the beauty of Kashmir leave a lasting impression on your heart.</p>
                                            <br /> <br />
                                            <h5><b>ENTRY FEES FOR SIGHTSEEING DURING KASHMIR TOUR</b></h5>
                                            <br />

                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td>Places</td>
                                                            <td>Entry Fees</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dal Lake</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Nishant Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pari Mahal</td>
                                                            <td>Rs. 40/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shalimar Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shankaracharya Temple</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Chashme Shahi</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Verinag Garden</td>
                                                            <td>Rs. 10/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SPS Museum</td>
                                                            <td>Rs. 10 for Indian, Rs. 50 for Foreigners</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tulip Garden</td>
                                                            <td>Rs. 75 per adult, Rs. 30 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Aru Valley</td>
                                                            <td>Rs. 25/Person</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES WITH VAISHNO DEVI</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES WITH VAISHNO DEVI</strong></h4>

                                        </div>
                                        <div className="card-body">
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Shikara Rides:</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Shikara Rides:</b> Experience the tranquility of Dal Lake with a leisurely Shikara ride During your <strong> Kashmir Tour Packages with Vaishno Devi</strong>. Glide across the serene waters, passing by floating gardens, houseboats, and vibrant markets. Sunset and early morning rides offer particularly magical views.</p>
                                            <center> <img src={Topbanner0ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Skiing and Snowboarding:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Skiing and Snowboarding:</b> During the winter months, places like Gulmarg transform into ski resorts, attracting enthusiasts from around the world. Gulmarg offers excellent slopes for skiing, snowboarding, and heli-skiing, with breathtaking views of the surrounding Himalayan peaks.</p>
                                            <center> <img src={Topbanner1ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Gondola Ride: </b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gondola Ride: </b>
                                                Take a ride on the Gulmarg Gondola, one of the highest cable cars in the world. Enjoy panoramic views of the snow-capped peaks as you ascend to Kongdori or Apharwat Peak, where you can indulge in activities like hiking and mountain biking.Experience this Awesome Ride During your <strong>Kashmir Tour Package with Vaishno Devi</strong> with Adorable Vacation and make this as a life long memories.</p>
                                            <center> <img src={Topbanner2ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Trekking:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Trekking:</b> Kashmir is a paradise for trekkers, with numerous trails that cater to all levels of expertise. Popular trekking destinations include the Kashmir Great Lakes Trek, Tarsar Marsar Trek, and the trek to Thajiwas Glacier in Sonamarg.</p>
                                            <center> <img src={Topbanner3ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>River Rafting:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>River Rafting:</b> The Lidder River in Pahalgam offers exciting opportunities for white-water rafting. Experience the thrill of navigating through rapids while enjoying the scenic beauty of the surrounding pine forests and mountains.</p>
                                            <center> <img src={Topbanner4ImageURL} className="img-fluid" alt="..." /></center>
                                            <br /><br /><br />
                                            <a href="tel://9147061467" className="btn btn-Warning" style={{ padding: '15px 30px', fontSize: '20px' }}>CALL NOW</a>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="container-fluid navtabCustom">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-highlights" aria-selected={customActiveTab === 'tab1'}>Highlights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-flights" aria-selected={customActiveTab === 'tab2'}>Flights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-hotel" aria-selected={customActiveTab === 'tab3'}>Hotel</button>
                                                <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-sightseeing" aria-selected={customActiveTab === 'tab4'}>Sightseeing</button>
                                                <button onClick={() => this.handleCustomTabClick('tab5')} className={`tab button ${customActiveTab === 'tab5' ? 'active' : ''}`} role="tab" aria-controls="nav-meals" aria-selected={customActiveTab === 'tab5'}>Meals</button>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent" >
                                            <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                                <p><strong>Vaishno Devi and Kashmir Tour Packages</strong> is renowned for its breathtaking landscapes, which include iconic features like the Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. These areas attract tourists from around the world who come to admire the region's picturesque scenery and engage in various outdoor activities.</p>
                                                <p>Planning for a <strong>Vaishno Devi and Kashmir Tour Package</strong> remains a captivating destination for travellers seeking natural beauty, cultural experiences, and adventure in a unique and picturesque setting.</p>
                                                <p><b>Potential Geography</b> - Kashmir is nestled in the Himalayan Mountain range and is bordered by India, Pakistan, and China. It is divided into three regions: Jammu, the Kashmir Valley, and Ladakh. The region is characterized by its snow-capped peaks, lush valleys, meandering rivers, and serene lakes.</p>
                                                <p><strong>Vaishno Devi Tour Packages</strong> is renowned for its breathtaking landscapes, which include iconic features like the Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. These areas attract tourists from around the world who come to admire the region's picturesque scenery and engage in various outdoor activities. Kashmir has long been a popular tourist destination, attracting visitors with its natural beauty, adventure sports, religious sites, and historical landmarks. Tourists can enjoy activities such as trekking, skiing, houseboat stays, shikara rides, and visits to Mughal gardens and ancient temples.</p>
                                            </div>
                                            <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                                <div className="overflow-table">
                                                    <span>Best Srinagar Non-Stop Flight connection from your city<b> <i>(SXR)</i></b></span><br /><br />
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th>FLIGHT</th>
                                                            <th>AIRLINES</th>
                                                            <th>AIRPORT</th>
                                                            <th>TIME</th>
                                                            <th>DAY</th>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-509</td>
                                                            <td>SpiceJet</td>
                                                            <td>Mumbai</td>
                                                            <td>14:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>QP-1637</td>
                                                            <td>Akasa Air</td>
                                                            <td>Mumbai</td>
                                                            <td>5:35</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>63-266</td>
                                                            <td>IndiGo</td>
                                                            <td>Mumbai</td>
                                                            <td>06:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-8913</td>
                                                            <td>SpiceJet</td>
                                                            <td>Delhi</td>
                                                            <td>08:00</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SG-8373</td>
                                                            <td>SpiceJet</td>
                                                            <td>Delhi</td>
                                                            <td>09:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E-6947</td>
                                                            <td>IndiGo</td>
                                                            <td>Bengaluru</td>
                                                            <td>13:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6253</td>
                                                            <td>IndiGo</td>
                                                            <td>Hyderabad</td>
                                                            <td>14:50</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6945</td>
                                                            <td>IndiGo</td>
                                                            <td>Ahmedabad</td>
                                                            <td>06:55</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6E6961</td>
                                                            <td>IndiGo</td>
                                                            <td>Kolkata</td>
                                                            <td>14:45</td>
                                                            <td>M,T,W,T,F,S,S</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">

                                                <div className="overflow-table">
                                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }} ><center><b>4 STAR - CERTIFIED BY 'ADORABLE' </b></center></h4>
                                                    <div id="no-more-tables">
                                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                            <thead class="cf">
                                                                <tr className="text-center">
                                                                    {/* <!-- Add the "text-center" className to center content --> */}
                                                                    <th colspan="6" style={{ fontSize: "1.3vw" }}><h6><b>3 STAR CATEGORY HOTEL CERTIFIED BY 'ADORABLE VACATION' </b></h6></th>
                                                                </tr>
                                                                <tr className="table-danger">
                                                                    <th className="text-center">Destination</th>
                                                                    <th className="text-center">Hotel Names</th>
                                                                    <th className="text-center">Hotel Type</th>
                                                                    <th className="text-center">No of Nights</th>
                                                                    <th className="text-center">Meal Plan</th>
                                                                </tr>
                                                            </thead>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Grand Fortune / Hotel Shefaf / Hotel Siddique Palace / Similar</div></td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>5N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Gulmarg</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Affarwat / Hotel Mama Palace /Gulmarg House / Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>1N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Pahalgam</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Pine Palace Resort / Hotel Fifth Season / Hotel Moonview Resort /Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>2N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                                <div className="container-fluid px-0 py-1">
                                                    <h3>Sightseeing for <strong>Amritsar Kashmir Tour Packages</strong></h3>
                                                    <p>Kashmir, often referred to as "Paradise on Earth," is renowned for its breathtaking natural beauty, serene landscapes, and rich cultural heritage. Here are some of the most popular sightseeing destinations in Kashmir:</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }} className='content-desktop'>Dal Lake:</b>
                                                        <b style={{ fontSize: "3.5vw" }} className='content-mobile2'>Dal Lake:</b> This iconic lake in Srinagar is famous for its picturesque surroundings, houseboats, and Shikara rides. Visitors can explore the floating gardens, old Mughal gardens along the shores, and enjoy the stunning views of the surrounding mountains.</p><br />
                                                    <p><b className='tomato content-desktop' style={{ fontSize: "1.3vw" }}>Gulmarg:</b>
                                                        <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gulmarg:</b> Known for its lush green meadows, Gulmarg is a popular hill station and skiing destination. Visitors can take the Gulmarg Gondola, one of the highest cable cars in the world, to reach Kongdori and enjoy panoramic views of the Himalayas.</p><br />
                                                    <p><b className='content-desktop' style={{ fontSize: "1.3vw" }}>Sonamarg:</b>
                                                        <b className='content-mobile2' style={{ fontSize: "3.5vw" }}>Sonamarg:</b>  Translating to "Meadow of Gold," Sonamarg is a scenic valley situated at an altitude of 2800 meters. It offers breathtaking views of snow-capped peaks, glaciers, and meadows. It's also the base for various trekking routes.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Pahalgam:</b> This quaint town is renowned for its pristine beauty, lush greenery, and the Lidder River. Pahalgam serves as a starting point for the annual Amarnath Yatra pilgrimage and offers opportunities for trekking, horse riding, and fishing.</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }}>Srinagar:</b> The summer capital of Jammu and Kashmir, Srinagar is famous for its Mughal gardens, including Shalimar Bagh, Nishat Bagh, and Chashme Shahi. Visitors can also explore the old city, visit the Jama Masjid, and indulge in shopping for traditional Kashmiri handicrafts in the local markets.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Pari Mahal:</b> Also known as the "Palace of Fairies," Pari Mahal is a historic monument located above the Chashme Shahi Gardens in Srinagar. It offers panoramic views of the city and Dal Lake and is surrounded by beautiful terraced gardens.</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }}>Betaab Valley:</b> Named after the Bollywood movie "Betaab," this picturesque valley is located near Pahalgam and offers stunning views of snow-capped mountains, lush greenery, and the Lidder River. It's a popular picnic spot and filming location.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Aru Valley:</b> Situated near Pahalgam, Aru Valley is known for its scenic beauty, alpine meadows, and trekking trails. It's a great place for nature lovers and adventure enthusiasts.</p><br />
                                                    <p><b style={{ fontSize: "1.3vw" }}>Mughal Road:</b> This historic road connects the Kashmir Valley with the regions of Poonch and Rajouri. It passes through picturesque landscapes, including dense forests, meadows, and small villages, offering a scenic drive.</p><br />
                                                    <p><b className='tomato' style={{ fontSize: "1.3vw" }}>Dachigam National Park:</b> Located near Srinagar, this national park is home to endangered species like the Kashmir stag (Hangul) and the Himalayan black bear. Visitors can enjoy wildlife safari tours and birdwatching in the park.</p><br />
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                                <div className="overflow-table">
                                                    <h5 class="tomato">10 times Meals and 10 times Breakfast has been Provided</h5>
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th colspan="6" className="text-center">Category</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">JAIN</td>
                                                            <td class="text-center">PURE-VEG</td>
                                                            <td class="text-center">VEGAN</td>
                                                            <td class="text-center">PURE-NON-VEG</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /> <br /> <br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                DAY WISE <strong><span>KASHMIR TOUR WITH VAISHNO DEVI</span></strong>
                                            </h2>
                                            <h4>9 Nights 10 Days <strong>Kashmir Tour Itinerary</strong></h4>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--end Short description--> */}
                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    DAY 1  – Arrival in Amritsar and Visit to Wagah Border
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Arrive in Amritsar, the heart of Punjab and a city known for its rich history and vibrant culture. Check-in at your hotel and freshen up. Head out to explore some local attractions like the Jallianwala Bagh, a historical garden that commemorates the tragic massacre of 1919, and the Partition Museum, which tells the story of India’s partition. After lunch, proceed to the Wagah Border, located about 30 km from Amritsar, to witness the famous Wagah Border Ceremony, a daily military practice that symbolizes the rivalry, cooperation, and respect between India and Pakistan. Return to Amritsar city. You can explore local markets for some shopping or enjoy a delicious Punjabi dinner at one of the famous dhabas.<b>Overnight: Stay in Amritsar</b>.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day2"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    DAY 02: Golden Temple Excursion and Transfer to Katra
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Start your day early with a visit to the Golden Temple (Harmandir Sahib), the holiest shrine of Sikhism. Experience the serene atmosphere, listen to the sacred hymns, and take part in the community meal (Langar). After the visit, you can explore the surrounding areas like the Guru Ka Langar Hall, Akal Takht, and the Central Sikh Museum. Enjoy lunch in Amritsar and then check out from your hotel. Begin your road journey to Katra, the base camp for pilgrims visiting Vaishno Devi. The distance from Amritsar to Katra is approximately 260 km, and it will take around 5-6 hours. Arrive in Katra, check in to your hotel, and relax after a long journey.<b>Overnight: Stay in Katra.</b>

                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day3"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 03: Vaishno Devi Darshan
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Begin your trek to Vaishno Devi Shrine, one of the holiest Hindu temples dedicated to Mata Vaishno Devi. The trek is approximately 12-13 km from Katra. You can choose to walk, hire a pony, or take a helicopter ride (depending on availability and preference). Reach the Bhawan (the main shrine) and perform the darshan (prayer). The atmosphere is filled with devotion and the chants of "Jai Mata Di." After darshan, you can visit Bhairon Temple, another significant temple located 1.5 km from Vaishno Devi Bhawan. Begin your descent back to Katra. Arrive in Katra and relax. Enjoy a peaceful evening at the hotel or stroll through the local market to pick up souvenirs.
                                                        <b>Overnight: Stay in Katra</b>.
                                                    </p>
                                                    <br />

                                                    <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="Kashmir Tour Packages" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Packages" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day4"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 04: - Transfer from Katra to Srinagar
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <p>Morning: Check out from your hotel in Katra and start your road journey to Srinagar, the summer capital of Jammu and Kashmir. The distance is around 250 km, and the journey takes approximately 7-8 hours. En route, enjoy the scenic beauty of the Himalayas, lush valleys, and charming towns. Srinagar experience.<b>Overnight: Stay in Srinagar.</b></p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay4ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day5"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 05: Srinagar to Gulmarg Excursion
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast in the morning, we will begin our countryside day trip towards Gulmarg. Gulmarg (2440 Meters above Sea level). It is one of the most beautiful hill stations and also called “Meadows of Flower”. Gulmarg has one of the best ski slopes in the world and the highest golf course with 18 holes.
                                                    </p>
                                                    <p>In Gulmarg, we will board the Gondola cable car system (on direct payment Basis), and ascend towards the Apharwat Mountain range. The 08 minutes of ropeway will take you past colorful shepherd huntsmen to the lower ridge of the Afarwat peak. From here you can click lots of photos of the nearby mountain ranges that stand tall like sentinels guarding the rich natural beauty of Kashmir valley. Dinner and Overnight at Hotel in Srinagar.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day6"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 06: Srinagar to Sonmarg Excursion
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast we will drive to the majestic mountain resort of Sonamarg. The route towards Sonamarg takes us on a picturesque countryside drive where we will come across rolling hills, green forests, clear water streams and blooming rice fields. We will zoom past the town of Kangan which handles all the local needs of this region. From Kangan, we will drive towards Mammar, famous for its trout farm before we head towards Sonamarg. We will once again alight from our vehicles at the tourist spot of Gagangir. The spot is built in the middle of the river Sindh and looks like an island with snow glaciers covering one or the other spot. In Sonamarg we will take a 3 hour walk or horseback trip/local Union cab to the Thajiwas glacier (on direct payment Basis) overnight stay in a Houseboat at Srinagar.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay6ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day7"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 07: Srinagar Local Sightseeing
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast ,we will leave for full day srinagar sightseeing,  visit local sightseeing that includes a visit to World famous Tulip garden  , Mughal gardens , Cheshma Shahi(Royal Spring), Nishat(Garden of Pleasure), Shalimar ( Abode of Love) Mughal laid Out in the 16th century & these fall along the bank of Dal-Lake in the heart of Srinagar City  & Shankaracharya Temple located on a hillock, thus providing panoramic view of entire City, along with famous Dal-Lake & River Jhelum, Enroute visiting handloom centre famous for  production of Pashmina/Shahtoosh shawls , world over reckoned Carpets & Char Chinari and <b>Overnight stay  at Srinagar</b>.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay7ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day8"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 08:Srinagar to-Pahalgam
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTen"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast & proceeding to Pahalgam,  we drive to Pahalgam via Pampore where you can visit famous saffron fields, Awantipora, and the village of Bijbehara which remains famous as the breadbasket of Kashmir. We switch from the national highway 1A at Khanabal and drive through the second-largest city of Anantnag. Pahalgam is a picturesque valley around 97 kilometers South of Kashmir. Known as Pahalgam (which means The Shepherds Village), the valley is renowned the world over for its exotic forests, pretty water streams, and imposing snow peaks. On the way to Pahalgam, we will take Awantipora's famous temple, famous Saffron field & Apple Valley Garden, Later we will move to the Pahalgam route beautiful view of Lidder river,After check-in hotel & <b>overnight stay in Pahalgam</b>.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay8ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day9"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 09:Pahalgam sightseeing
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTen"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast Leave for Pahalgam sightseeing( Betab Valley,Auro valley ,Chandanwadi)  by local union cab, After covering all these points we will come back to Hotel for overnight stay  at Pahalgam.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay9ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay9ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day9"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 10: Departure
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTen"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After morning breakfast you will be transferred to Jammu Tawi Station with sweet memories.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay10ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay10ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>KEY FEATURES OF THE DARBAR SAHIB (MAIN HALL) AT THE GOLDEN TEMPLE IN AMRITSAR</b></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>KEY FEATURES OF THE DARBAR SAHIB (MAIN HALL) AT THE GOLDEN TEMPLE IN AMRITSAR</b></h4>
                                </div>
                                <div className="card-body">
                                    <p>The Darbar Sahib, or main hall of the Golden Temple in Amritsar, is a beautiful and spiritual place. Here are the key features that make it a must-see for visitors on <strong>Amritsar to Kashmir Tour Package</strong>.</p>
                                    <p><b>Gilded Beauty</b>: The Darbar Sahib is stunning, with its gold-plated exterior. The entire building, including its domes and walls, is covered in gold, representing the richness and divine nature of Sikh faith. This beautiful design showcases the grandeur of Sikh architecture.</p>

                                    <p><b>Sikh Architectural Principles</b>: The main hall follows Sikh architectural principles, featuring four entrances that symbolize openness to everyone. The structure is balanced and symmetrical, creating a sense of harmony that reflects Sikh values.</p>

                                    <p><b>Akal Takht</b>: Next to the Darbar Sahib is the Akal Takht, the highest seat of Sikh authority. Many pilgrims on the <strong>Amritsar Kashmir Tour Package</strong> visit the Darbar Sahib to honor the Akal Takht, deepening their spiritual journey and connection to Sikh history.</p>

                                    <p><b>Guru Granth Sahib</b>: The central focus of the Darbar Sahib is the Guru Granth Sahib, the holy book of Sikhism. It is placed on a raised platform to show its importance. Devotees gather to listen to the sacred hymns and teachings, creating a sense of community.</p>

                                    <p><b>Continuous Reading</b>: The Darbar Sahib features continuous reading of the Guru Granth Sahib, known as Akhand Path. This uninterrupted reading symbolizes the ongoing flow of divine wisdom and guidance.</p>

                                    <p><b>Kirtan: Devotional Music</b>: The atmosphere in the Darbar Sahib is often filled with the uplifting sounds of kirtan, which is devotional music that enhances the spiritual experience. Visitors on <strong>Amritsar Kashmir Tour Packages</strong> can join in these musical rituals, feeling a deep connection to the divine.</p>
                                    <p>Overall, the Darbar Sahib at the Golden Temple is a wonderful mix of beautiful architecture, spiritual importance, and community spirit. For those on the Vaishno Devi Kashmir Amritsar Tour Packages, it offers a chance to experience the rich culture and spirituality of Sikhism.</p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>WHAT IS LANGAR AND HOW DOES IT WORK AT THE GOLDEN TEMPLE?</b></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>WHAT IS LANGAR AND HOW DOES IT WORK AT THE GOLDEN TEMPLE?</b></h4>
                                </div>
                                <div className="card-body">
                                    <p>The Golden Temple in Amritsar is not just a place of spiritual importance; it also focuses on community service through something called Langar. Langar is a communal kitchen that provides free meals to everyone, no matter their background, caste, or religion. This practice reflects key Sikh values like equality, selfless service, and community support.</p>
                                    <p>At the Golden Temple, Langar runs with the help of volunteers who dedicate their time to prepare, serve, and clean up after meals. This system highlights the spirit of seva, or selfless service. Langar operates continuously, 24 hours a day, 7 days a week, allowing visitors to enjoy meals at any time. This ongoing service shows a strong commitment to helping the community.</p>
                                    <p>The meals served in Langar are simple but nutritious, usually including dal (lentils), roti (flatbread), sabzi (vegetables), and kheer (sweet rice pudding). The focus is on providing healthy food for everyone, promoting well-being.</p>
                                    <p>For those on the Vaishno Devi Kashmir Amritsar Tour, experiencing Langar adds a meaningful aspect to their journey, helping them appreciate the spirit of service that is central to the Golden Temple.</p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>THE GOLDEN TEMPLE: A SACRED CONNECTION TO SIKH HISTORY</b></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>THE GOLDEN TEMPLE: A SACRED CONNECTION TO SIKH HISTORY</b></h4>
                                </div>
                                <div className="card-body">
                                    <p>The Golden Temple, also known as Sri Harmandir Sahib, is a shining symbol in the heart of Amritsar, Punjab. More than just its beautiful architecture, the temple is closely linked to Sikh history and the teachings of its founders. Here, we explore the importance of the Golden Temple and its connection to the leaders who shaped Sikhism.</p>
                                    <p>The Golden Temple was founded by Guru Arjan Dev Ji, the fifth Sikh Guru. He wanted to create a central place of worship that would bring the Sikh community together. His goal was to build not just a physical structure but also a spiritual refuge that embraced the core beliefs of Sikhism.</p>
                                    <p>Construction of the Golden Temple began in the early 17th century. The foundation stone was laid in 1588, and the temple was finished in 1604. Its design includes important symbols, like four entrances that represent inclusivity, and its gold-plated exterior, which symbolizes purity and divinity.</p>
                                    <p>Next to the Golden Temple is the Akal Takht, established by Guru Hargobind Sahib Ji, the sixth Sikh Guru. The Akal Takht is the highest seat of Sikh authority and highlights the importance of both spiritual and worldly matters in Sikhism.</p>
                                    <p>The Akal Takht has played a key role in Sikh history, addressing social and political issues and upholding justice. It reminds Sikhs of their commitment to truth, righteousness, and defending the oppressed.</p>
                                    <p>For those on the Kashmir Vaishno Devi Amritsar Package Tour, visiting the Golden Temple offers a rich cultural and spiritual experience. Visitors can connect with the historical significance of the temple and appreciate the vision of Guru Arjan Dev Ji and the lasting impact of Sikhism’s founders.</p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>SIGNIFICANCE OF VAISHNO DEVI AND HOW TO REACH KATRA TO VAISHNO DEVI</b></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>SIGNIFICANCE OF VAISHNO DEVI AND HOW TO REACH KATRA TO VAISHNO DEVI</b></h4>
                                </div>
                                <div className="card-body">

                                    <p>Vaishno Devi, located in the beautiful hills of Kashmir, is a very important pilgrimage site for those seeking spiritual peace and blessings. Dedicated to Goddess Vaishno, it attracts millions of pilgrims each year. If you’re planning a spiritual journey, Kashmir trip Booking is a great option that ensures a smooth and enriching experience.</p>
                                    <h5><b>Importance of Vaishno Devi Darshan</b></h5><br />
                                    <p>Vaishno Devi, also known as Mata Vaishnavi, is believed to represent the goddesses Mahalakshmi, Mahakali, and Maha Saraswati. The shrine is about 5,200 feet high in the Trikuta Mountains. Pilgrims trek to seek the goddess's blessings and fulfill their spiritual wishes.</p>
                                    <p>The pilgrimage is not just about the destination; it’s a journey of faith, devotion, and self-discovery. The holy cave houses three natural rock formations called Pindies, which symbolize the three goddesses. The atmosphere is mystical, making it a special place for those looking to connect with the divine.</p>
                                    <br />
                                    <h5><b>How to Reach Vaishno Devi from Katra</b></h5><br />
                                    <p>Katra is the starting point for the Vaishno Devi pilgrimage and is located in the Trikuta Mountains. The trek to the sacred cave is about 13.5 kilometers. Here’s how to get there:</p>
                                    <p>You can easily reach Katra by road or rail. The nearest railway station is Jammu Tawi, and the closest airport is Jammu Airport. From these locations, you can take a taxi or bus to Katra.</p>
                                    <p><b>Registration and Yatra Parchi</b>: Before starting the trek, you need to register at the Katra base camp and get a Yatra Parchi, which is a necessary slip to help keep track of pilgrims. It’s best to get this in advance to avoid long waits.</p>
                                    <p>The trek from <strong>Katra to Vaishno Devi</strong> takes about 6 to 8 hours, depending on your pace. The path is well-marked and offers beautiful views, making the journey both spiritual and enjoyable.</p>
                                    <p>On the way, you will pass Ardhkuwari, a halfway point, before reaching the holy cave where the shrine is. The Bhawan is the main destination, where devotees pray and feel the presence of Goddess Vaishno.</p>
                                    <p>After receiving blessings from Vaishno Devi, pilgrims return to Katra. For those who prefer not to trek back, options like shared cabs and helicopters are available.</p>
                                    <p>For a complete spiritual and cultural experience, the <strong>Kashmir Vaishno Devi Amritsar Package Tour</strong> is an excellent choice. This package includes visits to the Golden Temple in Amritsar, the shrine of Vaishno Devi, and the stunning landscapes of Kashmir.</p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>UNVEILING ADVENTURE: 7 EXCITING SPORTS TO TRY ON THE</b> <strong>KASHMIR VAISHNO DEVI AMRITSAR TOUR PACKAGES</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>UNVEILING ADVENTURE: 7 EXCITING SPORTS TO TRY ON THE</b> <strong>KASHMIR VAISHNO DEVI AMRITSAR TOUR PACKAGES</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>Get ready for an exciting adventure with the <strong>Vaishno Devi Kashmir Amritsar Tour Packages</strong> in the stunning land of Kashmir. With its majestic mountains and beautiful landscapes, Kashmir offers a variety of thrilling activities that will create lasting memories. Whether you love golf, skiing, or exploring nature, there's something special for everyone.</p>
                                    <p><b>Golfing in Gulmarg</b> :
                                        Golfing in Gulmarg is a delightful experience. The golf course, located at 3,730 meters, is the highest in the world. Surrounded by snow-capped mountains, it provides a beautiful setting for golfers. Enjoy sunny mornings on the course, and you can rent golf balls and clubs if needed.
                                    </p>
                                    <p><b>Skiing in Gulmarg and Pahalgam</b>:
                                        Feel the excitement of skiing in the snowy landscapes of Gulmarg and Pahalgam. Gulmarg is famous for winter sports, and skiing can be enjoyed year-round. You can rent skis, boots, goggles, and gloves from local shops, making it perfect for both beginners and experts.
                                    </p>
                                    <p><b>Trekking in Kashmir</b>:
                                        If you love adventure, trekking in Kashmir is a must. The Shankaracharya hill offers great trails through rugged areas, snowy peaks, and lush valleys. From July to September, you can enjoy the rich plants and animals along the way. Beginners can join trekking clubs for a safe and fun experience.
                                    </p>
                                    <p><b>River Rafting on the Lidder River</b>: Experience the thrill of river rafting on the Lidder River in Pahalgam. Navigate through exciting rapids in a beautiful green setting. The moderate difficulty makes it suitable for both beginners and experienced rafters.</p>

                                    <p><b>Mountain Biking from Gulmarg to Srinagar</b>: For mountain biking fans, Kashmir has many trails, especially along the Srinagar Leh highway. Ride through the majestic Himalayas on ten different trails, equipped with all-terrain bikes and gear. Explore the breathtaking views of Kashmir on two wheels.</p>

                                    <p><b>Shikara Ride on Dal Lake</b>: Relax with a Shikara ride on Dal Lake in Srinagar. These charming wooden boats offer a unique experience, allowing you to enjoy the calm beauty of the lake. You can visit nearby gardens, the Hazrat Bal mosque, or simply enjoy birdwatching. Shikara boats also serve as floating markets, selling flowers and handicrafts.</p>

                                    <p><b>Wildlife Safaris in Dachigam National Park</b>: Discover the unique plants and animals of Kashmir with wildlife safaris in Dachigam National Park. You might see rare species like the snow leopard and the endangered Kashmir stag. Kashmir is a paradise for wildlife lovers, with opportunities to spot red foxes, Himalayan black bears, and a variety of birds.</p>
                                    <p>In conclusion, Kashmir offers a wide range of adventure sports, from relaxing activities to thrilling challenges, ensuring you have an unforgettable experience in this beautiful region.</p>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div className="card-header custHeaer">
                                    <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>DISTANCES TO NOTE FOR KASHMIR TRIP BOOKING</b></h4>
                                    <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>DISTANCES TO NOTE FOR KASHMIR TRIP BOOKING</b></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>From</th>
                                                    <th>To</th>
                                                    <th>Distance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="From">Ahmedabad</td>
                                                    <td data-title="To">Srinagar</td>
                                                    <td data-title="Distance">30 hr (1,671.9 km) via NH 48</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Surat</td>
                                                    <td data-title="To">Srinagar</td>
                                                    <td data-title="Distance">36 hr (1,926.2 km) via NH 48</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Chennai</td>
                                                    <td data-title="To">Srinagar</td>
                                                    <td data-title="Distance">54 hr (3,017.4 km) via NH 44</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Pune</td>
                                                    <td data-title="To">Srinagar</td>
                                                    <td data-title="Distance">41 hr (2,245.6 km) via NH 44</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Bangalore</td>
                                                    <td data-title="To">Srinagar</td>
                                                    <td data-title="Distance">53 hr (2,985.2 km) via NH 44</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Kolkata</td>
                                                    <td data-title="To">Srinagar</td>
                                                    <td data-title="Distance">46 hr (2,343.4 km) via NH 44</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Delhi</td>
                                                    <td data-title="To">Srinagar</td>
                                                    <td data-title="Distance">18 hr 22 min (812.5 km) via NH 44</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Mumbai</td>
                                                    <td data-title="To">Srinagar</td>
                                                    <td data-title="Distance">42 hr (2,169.8 km) via NH 48</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Jammu</td>
                                                    <td data-title="To">Srinagar</td>
                                                    <td data-title="Distance">8 hr 14 min (266.5 km) via NH 44</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Srinagar</td>
                                                    <td data-title="To">Gulmarg</td>
                                                    <td data-title="Distance">1 hr 30 min (49.5 km) via Srinagar - Tangmarg Rd</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Srinagar</td>
                                                    <td data-title="To">Sonmarg</td>
                                                    <td data-title="Distance">2 hr 11 min (79.4 km) via NH1</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Srinagar</td>
                                                    <td data-title="To">Pahalgam</td>
                                                    <td data-title="Distance">2 hr 11 min (89.6 km) via Srinagar - Kanyakumari Hwy</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Srinagar</td>
                                                    <td data-title="To">Dras</td>
                                                    <td data-title="Distance">4 hr 2 min (141.2 km) via NH1</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Srinagar</td>
                                                    <td data-title="To">Kargil</td>
                                                    <td data-title="Distance">5 hr 19 min (202.1 km) via NH1</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Srinagar</td>
                                                    <td data-title="To">Leh</td>
                                                    <td data-title="Distance">9 hr 41 min (418.5 km) via NH1</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h2>The Khyber Himalayan Resort & Spa <span>- Gulmarg</span></h2>
                                            <h4><i>Next Level Luxury Resort </i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_1_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Platinum Accommodation</td>
                                                <td data-title="Size(Room)">386+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Khyber Hotels (Heritage)<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 53,119/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>HEAVEN IS CLOSER THAN YOU CAN IMAGINE</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>HEAVEN IS CLOSER THAN YOU CAN IMAGINE</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>8,825 feet</b> above sea level in the Pir Panjal range of the Himalayas lies a higher order of luxury. <b>The Khyber Himalayan Resort & Spa</b> is spread over seven unspoilt, fir-laden acres, with majestic views of the Affarwat Peaks. This 85-room resort is framed at every vantage point by nature at its most spectacular. <b>The world’s highest ski lift</b>, the Gulmarg Gondola – which takes you to Kongdoori Mountain, is only a short walk away. Meadows and forests that have enchanted travellers for centuries wait to be explored by trekking enthusiasts and undying romantics. Clad in locally sourced pine and slate, the resort is built in a style true to the architectural heritage of Kashmir. In every way the indoors mirror the beauty of the outdoors. Specialty restaurants, a cigar lounge are amongst the necessary luxuries in place. Add to these other thoughtful additions like our children’s club, a mini theatre and a dedicated Activities Concierge and you will find The Khyber is a resort at par with the best in the world.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >The LaLiT Grand Place <span>- Srinagar</span></h2>
                                            <h4 ><i>Fill the Luxury</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_2_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Premium Accommodation</td>
                                                <td data-title="Size(Room)">290+ SQ.FT</td>
                                                <td data-title="Hotel Brand">The LaLiT Hotels<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 28,332/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>The LaLit Grand Palace</b> was originally built in 1901 for the royal family. It now provides luxurious rooms with views of Dal Lake and the beautiful gardens. An indoor pool, tennis courts and pampering spa treatments are also available.The tastefully designed rooms are modern with a touch of traditional charm. All have a satellite TV, minibar and free tea/coffee making facilities. It is 18 km from Srinagar Airport and 4 km from Lal Chowk city centre. Jammu Railway Station is 300 km away. Guests can enjoy a relaxing massage at Rejuve Spa Health Club, practice yoga, or play golf. The hotel also has a gym and 24-hour business center. The Chinar Garden serves North Indian cuisine, while international buffet spreads are available at The Chinar – The All Day Coffee Shop. Other dining options include Dal Bar and 24-hour room service.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>

                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2><span>Kashmir Vaishno Devi Amritsar Tour Packages</span></h2>
                                            <h4>Related Kashmir Tour plan</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >Frequently Ask <span>Questions</span></h2>
                                            <h4 >Amritsar Katra Vaishno Devi Kashmir Tour Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" >
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion" id="accordionFlushExample">
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                        What is Jallianwala Bagh, and how is it related to the Golden Temple?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Jallianwala Bagh:</b> Jallianwala Bagh is a historical site near the Golden Temple in Amritsar. It is known for the tragic Jallianwala Bagh massacre that happened on April 13, 1919. During this event, British troops, led by Brigadier General Reginald Dyer, opened fire on a peaceful gathering of people protesting against unfair laws. Many people were killed or injured, and the site now includes a well known as the Martyr's Well, where some people jumped to escape the firing. This event is linked to the Golden Temple because many victims were pilgrims visiting for a Sikh festival.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                        What does "Amrit Sarovar" mean, and how did Amritsar get its name?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Amrit Sarovar:</b> "Amrit Sarovar" means "Pool of Nectar." This name refers to the sacred water tank surrounding the Golden Temple. The name "Amritsar" comes from two Punjabi words: "Amrit," meaning nectar, and "Sar," meaning pool. The city was founded by Guru Ram Das, the fourth Sikh Guru, in the 16th century, and the Golden Temple was completed by his successor, Guru Arjan Dev, in 1604. The tank is important in Sikh practices and attracts many visitors.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFour">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                        Are there any dress code requirements to visit the Golden Temple?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <ul>
                                                            <li><b>Head Covering:</b> Everyone must cover their heads. You can bring your own scarf or use one provided at the entrance.</li>
                                                            <li><b>No Shoes:</b> Shoes must be removed before entering. There are places to store them, and you should wash your feet at foot baths before entering.</li>
                                                            <li><b>Modest Clothing:</b> Wear respectful clothing that covers shoulders and knees. Avoid revealing or very casual clothes.</li>
                                                            <li><b>No Leather:</b> Leather items, like belts and bags, are not allowed. Leave them in storage before entering.</li>
                                                            <li><b>Respectful Behavior:</b> Keep noise to a minimum and behave quietly inside the temple.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFive">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                        What is a Shikara ride on Dal Lake?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Shikara Ride:</b> A Shikara ride on Dal Lake is a peaceful experience in Kashmir. Shikaras are beautifully decorated wooden boats that glide over the calm water. During the ride, you can see floating gardens and enjoy views of the beautiful Mughal gardens and snow-capped mountains. This cultural experience is a great way to enjoy the stunning landscapes of Kashmir and is a must-do during your tour.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSix">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                                        Can I visit the Tulip Garden in Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Tulip Garden:</b> Yes, you can visit the Tulip Garden in Srinagar! The garden blooms with colorful tulips in spring, usually from late March to early April. Plan your trip during this time to see the flowers at their best. Visiting the Tulip Garden will add to the beauty of your Kashmir tour, allowing you to enjoy the serene atmosphere and capture lovely photos among the vibrant tulip fields.</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h3><b>Kashmir <span class="tomato">Holiday Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container" >

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div >
                </div >
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91470-61467">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                    </a>
                </div>

            </>
        );
    }
}

export default Kashmir_9N_10D_Vaishno_Devi_Amritsar_Package;