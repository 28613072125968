import React, { Component } from 'react';

class TopSearch extends Component {

    render() {
        return (
            <>
                <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                        <tbody>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Hike to Taktsang Monastery (Tiger's Nest) - Paro</td>
                                <td style={{ fontSize: "16px" }}>Take the Dagala Thousand Lakes Trek - Thimphu</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Walk across the Punakha Suspension Bridge - Punakha</td>
                                <td style={{ fontSize: "16px" }}>Explore the National Memorial Chorten - Thimphu</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Walk across the Punakha Suspension Bridge - Punakha</td>
                                <td style={{ fontSize: "16px" }}>Explore the National Memorial Chorten - Thimphu</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Explore Simtokha Dzong - Thimphu</td>
                                <td style={{ fontSize: "16px" }}>Explore the Chele La Pass</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Visit the Buddha Cave & Buddha Falls</td>
                                <td style={{ fontSize: "16px" }}>Visit Trongsa Dzong - Trongsa</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Visit Rinpung Dzong - Paro</td>
                                <td style={{ fontSize: "16px" }}>Visit Trashigang Dzong - Trashigang District</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Take a Bike Trip around Bhutan</td>
                                <td style={{ fontSize: "16px" }}>Go Camping in Thimphu</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Try Archery - Bhutan’s National Sport</td>
                                <td style={{ fontSize: "16px" }}>Go Mountain Biking in Bhutan</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Visit the Tashichho Dzong - Thimphu</td>
                                <td style={{ fontSize: "16px" }}>Visit the Buddha Dordenma - Thimphu</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Hike in the beautiful Haa Valley - Haa</td>
                                <td style={{ fontSize: "16px" }}>Relax with a Hot Spring Therapy</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Experience the Nimalung Tsechu Festival</td>
                                <td style={{ fontSize: "16px" }}>Take a scenic drive at Dochula Pass</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Attend the Annual Black-Necked Crane Festival</td>
                                <td style={{ fontSize: "16px" }}>Try Kayaking on the Wang Chhu River - Paro</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Go Rafting in the Mo Chhu River - Punakha</td>
                                <td style={{ fontSize: "16px" }}>Explore Punakha Dzong - Punakha</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Get a Blessing at Gangtey Monastery - Wangdue Phodrang</td>
                                <td style={{ fontSize: "16px" }}>Pray at Chimi Lhakhang Temple - Punakha</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px" }}>Spot Snow Leopards at Jigme Dorji National Park</td>
                                <td style={{ fontSize: "16px" }}>Try Bhutanese Cuisine - especially Ema Datshi</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />

            </>
        );
    }
}

export default TopSearch;