import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from './InclusionExclusion_kashmir_family';

class Kashmir_7N_8D_Family_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1',
            btn: "Submit",
            showLoader: false,
            isHidden: false,
            showModal: false,
            firstName: '',
            mobile: '',
            email: '',
            comments: ''
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    onRequestCallback = (e) => {
        e.preventDefault();
        // Prevent opening a new modal if another one is already open
        if (!this.state.showModal) {
            this.setState({ showModal: true });
        }
    }


    onCloseModal = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            showModal: false,
            firstName: '',
            mobile: '',
            email: '',
            comments: ''
        });
    }

    toggleHidden = (e) => {
        e.preventDefault();
        this.setState({ isHidden: !this.state.isHidden });
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    // Function to handle form submission
    onRequestCallbackSubmit = async (e) => {
        e.preventDefault();

        const { firstName, email, mobile, comments } = this.state;

        // Validation
        if (firstName.trim() === '') {
            cogoToast.error("Full Name Required.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else if (email.trim() === '') {
            cogoToast.error("Email Required.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else if (mobile.trim() === '' || !/^\d{10}$/.test(mobile.trim())) {
            cogoToast.error("Please Insert 10 digit Mobile No.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else {
            this.setState({ btn: "Processing..", showLoader: true });
            // Proceed with form submission
            try {
                const _ref = 'yes';
                const _url = window.location.href;

                const formData = {
                    FirstName: firstName,
                    //LastName: lastName,
                    Email: email,
                    Mobile: mobile,
                    // City: city,
                    // TravelDate: travelDate,
                    // TicketBooked: ticketBookedStatus,
                    // NoOfGuests: noOfGuests,
                    // HotelCategory: hotelCategory,
                    Comments: comments,
                    Referrer: _ref,
                    URL: _url
                };

                const response = await axios.post(ApiURL.SaveCustomerEnquery, formData);
                console.log('Data submitted successfully:', response.data);
                // Optionally, reset the form state after successful submission
                this.setState({ firstName: '', email: '', mobile: '', comments: '' });

                // Optionally, show a success message to the user
                cogoToast.success("Form submitted successfully.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
                this.setState({ btn: "Submit", showLoader: false, showModal: false });
            } catch (error) {
                console.error('Error submitting data:', error);
                this.setState({ btn: "Submit", showLoader: false });
                // Optionally, show an error message to the user
                cogoToast.error("Please try again after 24 hrs. later.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
            }

        }

    };
    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Kashmir-Family-Tour-Package.webp";
        const packageTopBanner2ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Kashmir-Family-Tour-Packages.webp";
        const sightseen1ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Magical-Trip-to-kashmir-from-Delhi.webp";
        const sightseen2ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Delhi-to-Kashmir-Tour-Packages.webp";
        const sightseen3ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Kashmir-Package-Tour-Price-ex- Srinagar.webp";
        const sightseen4ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Kashmir-Tour-Itinerary-from-Delhi.webp";
        const sightseen5ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Kashmir-Tour-Package-from-Delhi.webp";
        const sightseen6ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Kashmir-Tour-Plan-from-Delhi.webp";
        const sightseen7ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Kashmir-Trip-from-Delhi.webp";
        const sightseen8ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Kashmir-from-Delhi.webp";
        const sightseen9ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Kashmir-Honeymoon-Trip.webp";
        const sightseen10ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Kashmir-Package-Tour.webp";
        const sightseen11ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Kashmir-Package-Tour-Cost.webp";
        const sightseen12ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Customized-Kashmir-Tour-Itinary.webp";
        //day wise itenaryy
        const navDay1ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Jammu-Kashmir-family-package-Tour.webp";
        const navDay2ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Jammu-Kashmir-Family-Package-Tour-Price.webp";
        const navDay3ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Jammu-Kashmir-Family-Tour-Packages.webp";
        const navDay4ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Jammu-and-Kashmir-Holiday-Packages.webp";
        const navDay5ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Book-Kashmir-Family-Holiday-Packages.webp";
        const navDay6ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Kashmir-Family-Tour.webp";
        const navDay7ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Kashmir-Family-Tour-Package-Cost.webp";
        const navDay8ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Kashmir-Family-Tour-Packages-cost.webp";
        const navDay9ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Kashmir-Tour-Package-cost.webp";
        const Topbanner0ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Kashmir-Houseboat-Packages.webp";
        const Topbanner1ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Kashmir-Adventure-Tour-Plan.webp";
        const Topbanner2ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Kashmir-Gondola-Package-Tour.webp";
        const Topbanner3ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Kashmir-Trekking-Packages.webp";
        const Topbanner4ImageURL = ApiURL.Kashmir_Family_Package_Tour + "/Kashmir-White-River-Rafting-Plan.webp";
        const brandedTourackageImage_1_URL = ApiURL.Kashmir_Family_Package_Tour + "/The-Khyber-Himalayan-Resort-&-Spa.webp";
        const brandedTourackageImage_2_URL = ApiURL.Kashmir_Family_Package_Tour + "/The-LaLiT-Grand-Palace-Srinagar-Hotel.webp";
        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Kashmir_Family_Package_Tour + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Kashmir_Family_Package_Tour + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>

                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">

                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>JAMMU KASHMIR FAMILY PACKAGE TOUR </strong></center></h1>

                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>JAMMU KASHMIR FAMILY PACKAGE TOUR </strong></center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                4 Destination:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Katra (2N)
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Pahalgam (2N)
                                                </a>
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Gulmarg (1N)
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Srinagar (3N)
                                                </a>
                                                {" "}
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Kashmir Family Package Tour</strong>, you have come to the right place!
                                        </p>
                                        <hr />
                                        <br />
                                    </div>
                                    <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}><b>Looking for Jammu Kashmir Family Tour Packages ?</b><a href='tel:9147061467' class="tomato"> To book a <strong>Jammu Kashmir Family Tour Packages</strong> ☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}><b>Looking for Jammu Kashmir Family Tour Packages?</b><a href='tel:9147061467' class="tomato"> To book a <strong>Jammu Kashmir Family Tour Packages</strong> ☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Explore the Enchanting Beauty of a <strong>Kashmir Family Package Tour</strong> with Adorable Vacation</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Explore the Enchanting Beauty of a <strong>Kashmir Family Package Tour</strong> with Adorable Vacation</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Welcome to the picturesque region of Jammu and Kashmir, where every corner unfolds a fascinating story of natural beauty, rich history, and spiritual wonders. At Adorable Vacation, we present to you the perfect <strong>Kashmir Tour Packages for Family</strong> that promises an unforgettable experience for you and your loved ones. Let's dive into the attraction of Jammu & Kashmir, explore popular tourist destinations, and discover the spiritual sanctuary of Katra and the revered Vaishno Devi Darshan.
                                            </p>
                                            <p>Starting on a <strong>Family Tour Packages for Jammu & Kashmir</strong> is like stepping into a magical realm where snow-capped mountains, pristine valleys, and lush meadows create a mesmerizing tapestry. Our thoughtfully curated packages ensure that your family's travel experience is seamless, filled with joy, and leaves you with cherished memories.
                                            </p>
                                            <p>Before you delve into the beauty of the region, let's take a moment to explore the historical tapestry of Jammu & Kashmir. With a history dating back centuries, this region has witnessed the rise and fall of various dynasties, leaving behind a legacy of architectural marvels and cultural richness.
                                            </p>
                                            <p>From the ancient times ruled by the Mauryas and Kushans to the later periods of the Mughals and Sikhs, Jammu & Kashmir has evolved into a melting pot of traditions. The intertwining of Hindu, Muslim, and Sikh influences has given the region a unique identity that reflects in its art, music, and lifestyle.</p>
                                            <p>As you set off on your <strong>Jammu and Kashmir Holiday Packages</strong>, prepare to be captivated by the wide range of tourist attractions that cater to the diverse tastes of visitors. The Dal Lake in Srinagar, with its shikara rides and houseboats, offers a tranquil escape amidst breathtaking scenery. The Mughal Gardens, such as Shalimar Bagh and Nishat Bagh, showcase the grandeur of Mughal architecture against the backdrop of the Himalayas.</p>
                                            <p>For those seeking adventure, Gulmarg is a paradise for skiing and winter sports enthusiasts. The meadows of Pahalgam, with the Lidder River flowing gently, provide a serene setting for nature lovers. The ancient Shankaracharya Temple atop a hill in Srinagar offers panoramic views of the city and the Dal Lake.</p>
                                            <p>As part of your <strong>Jammu & Kashmir Family Tour</strong>, a visit to Katra is a must. Nestled in the <strong>Trikuta Mountains</strong>, Katra serves as the base camp for the sacred <strong>Vaishno Devi</strong> pilgrimage. A town of profound spirituality and natural beauty, Katra welcomes pilgrims and tourists alike. The journey to the <strong>Vaishno Devi shrine</strong> is not just a trek but a spiritual odyssey, where the faith of millions converges on the path leading to the sacred cave.</p>
                                            <p>The Vaishno Devi shrine, perched at an altitude of 5,200 feet, is a sacred abode of Goddess Vaishno Devi. Pilgrims undertake a trek to reach the cave shrine, where the divine energy of the goddess is believed to fulfill wishes and bestow blessings. The spiritual energy and the breathtaking views in route make the Vaishno Devi Darshan a soul-stirring experience, drawing devotees from all walks of life. The chants of <b>'Jai Mata Di'</b> resonate in the air, creating an atmosphere of positivity and unity.</p>
                                            <p>Adorable Vacation is your trusted partner in crafting memorable family vacations. With a commitment to excellence and customer satisfaction, we offer seamless travel experiences. Our <strong>Kashmir Family Tour</strong> are designed to provide the perfect blend of comfort, adventure, and spirituality.</p>
                                            <p><strong>Book Kashmir Family Holiday Packages</strong> with Adorable Vacation and let the mountains, lakes, and spirituality weave a tapestry of joy for your family. <strong>Adorable Vacation – Your Gateway to Blissful Journeys</strong>!</p>
                                            <p><b>Apart from that, we also provide,</b></p>
                                            <a href='/kashmir-offbeat-package-tour-with-baisaran-valley-doodhpathri-yusmarg' target='_blank'><b className="tomato">Kashmir Package Tour with Doodhpathri Yusmarg</b></a>, <a href='/kashmir-offbeat-package-tour-with-gurez-valley' target='_blank'><b className="tomato">Kashmir Package Tour with Gurez Valley</b></a>,
                                            <a href='/kashmir-offbeat-package-tour-with-sinthan-top-aru-valley-betab-valley-chandanbari-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Offbeat Package Tour with Sinthan Top</b></a>,
                                            <a href='/kashmir-package-tour-with-vaishno-devi-katra-pahalgam-gulmarg-srinagar' target='_blank'><b className="tomato">Kashmir Package Tour with Vaishno Devi</b></a>,
                                            <a href='/kashmir-tulip-garden-festival-package-tour' target='_blank'><b className="tomato">Kashmir Package Tour with Tulip Package</b></a>,
                                            <a href='/kashmir-package-tour-from-kolkata-with-srinagar-gulmarg-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Package Tour from Kolkata </b></a>,
                                            <a href='/kashmir-package-tour-from-surat' target='_blank'><b className="tomato">Kashmir Package Tour from Surat </b></a>,
                                            <a href='/kashmir-package-tour-from-chennai' target='_blank'><b className="tomato">Kashmir Package Tour from Chennai </b></a>,
                                            <a href='/kashmir-package-tour-from-bangalore' target='_blank'><b className="tomato">Kashmir Package Tour from Bangalore </b></a>,
                                            <a href='/kashmir-package-tour-from-pune' target='_blank'><b className="tomato">Kashmir Package Tour from Pune </b></a>,
                                            <a href='/kashmir-package-tour-from-mumbai' target='_blank'><b className="tomato">Kashmir Package Tour from Mumbai </b></a>,
                                            <a href='/kashmir-package-tour-from-delhi' target='_blank'><b className="tomato">Kashmir Package Tour from Delhi </b></a>,
                                            <a href='/kashmir-package-tour-from-chennai' target='_blank'><b className="tomato">Kashmir Package Tour from Chennai </b></a>,
                                            <a href='/kashmir-package-tour-from-ahmedabad' target='_blank'><b className="tomato">Kashmir Package Tour from Ahemdabad </b></a>
                                            <br /><br />
                                            <p>Book your dream destination with Adorable Vacation and for further information do not hesitate to contact us at +91 9147061467</p>
                                            <br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen1ImageURL} alt="kashmir tour packages" />
                                                            <img src={sightseen2ImageURL} alt="kashmir trip from srinagar airport" />
                                                            <img src={sightseen3ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /> <br /> <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>Duration for Jammu Kashmir Family Package Tour : 8N | 9D</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>Duration for Jammu Kashmir Family Package Tour : 8N | 9D</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Places Covered<strong> Jammu and Kashmir Tour Packages </strong> : Katra 2N | Pahalgam 2N | Gulmarg 1N | Srinagar Houseboat 1N | Srinagar 2N </p>
                                            <p> Pickup & Drop point for <strong> Kashmir Family Tour Package</strong> : Jammu & Srinagar Airport</p>
                                            <p><b>Address</b>: Indra Gandhi Road, Humhama, Badgam, Srinagar, Jammu and Kashmir 190007</p>
                                            <br /><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>Kashmir Family Tour Package Pickup</strong> <b>Pickup </b></td>
                                                            <td>Jammu Tawi Railway Station [JAT] / Jammu Airport [IXJ]</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Kashmir Family Tour Duration</strong></td>
                                                            <td>08 Nights</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Ahmedabad to Kashmir Package Price </strong></td>
                                                            <td>Rs. 30,600/Based on 8 Pax</td>
                                                        </tr>

                                                        <tr>
                                                            <td><strong>Altitude of Srinagar</strong></td>
                                                            <td>1,585 m | Coordinates - 34.0837° N, 74.7973° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Gulmarg</strong></td>
                                                            <td>2,650 m | Coordinates - 34.0484° N, 74.3805° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Sonmarg</strong></td>
                                                            <td>2,730 m | Coordinates - 34.3032° N, 75.2931° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Pahalgam</strong></td>
                                                            <td>2,740 m | Coordinates - 34.0161° N, 75.3150° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Betaab Valley</strong></td>
                                                            <td>2,393 m | Coordinates - 34.0541° N, 75.3639° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Aru Valley</strong></td>
                                                            <td>2,414 m | Coordinates - 34.0922° N, 75.2632° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Chandanwadi</strong></td>
                                                            <td>3,288 m | Coordinates - 34.2122° N, 73.9295° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Doodhpathri</strong></td>
                                                            <td>2,730 m | Coordinates - 33.8509° N, 74.5630° E</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br /><br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen4ImageURL} alt="kashmir package tour from srinagar" />
                                                            <img src={sightseen5ImageURL} alt="kashmir package price from srinagar airport" />
                                                            <img src={sightseen6ImageURL} alt="kashmir packages from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />

                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    {/* start short itinerary */}

                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr className="table-danger">
                                                        <th>JAMMU KASHMIR FAMILY PACKAGE TOUR ITINERARIES</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingOne"
                                                        >
                                                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                                <span>
                                                                    Arrive Jammu Station and Transfer to Katra
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingTwo"
                                                        >
                                                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                                <span>Vaishnodevi Darshan</span>
                                                            </a>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingThree"
                                                        >
                                                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                                <span>Katra to Pahalgam </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFour"
                                                        >
                                                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                                <span>Pahalgam Local Sightseeing ( Include )</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFive"
                                                        >
                                                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                                <span>Pahalgam – Gulmarg: 3 hrs</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSix"
                                                        >
                                                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                                <span>Gulmarg Gondola Ride and transfer to Srinagar</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSeven"
                                                        >
                                                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                                <span>Srinagar Local Sightseeing </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingEight"
                                                        >
                                                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                                                                <span>SRINAGAR (DAY EXCURSION TO SONMARG) -(87 Kms – 2 to 3 hrs One Way) N/Stay at House Boat </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingNine"
                                                        >
                                                            <a href="#day9" style={{ color: 'black' }}><b>DAY 9 → </b>
                                                                <span>DEPART FOR SRINAGAR Airport ( FLY OUT ) </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <!--end Short description--> */}
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>KASHMIR FAMILY TOUR PACKAGE COST</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>KASHMIR FAMILY TOUR PACKAGE COST</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>TOUR DURATION</th>
                                                            <th>2 PAX</th>
                                                            <th>4 PAX</th>
                                                            <th>6 PAX</th>
                                                            <th>8 PAX</th>
                                                            <th>10 PAX</th>
                                                            <th>12 PAX</th>
                                                            <th>BOOK NOW</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">8N/9D</td>
                                                            <td data-title="2 ADULTS">₹ 42,000/-</td>
                                                            <td data-title="4 ADULTS">₹ 34,600/-</td>
                                                            <td data-title="6 ADULTS">₹ 31,200/-</td>
                                                            <td data-title="8 ADULTS">₹ 30,600/-</td>
                                                            <td data-title="10 ADULTS">₹ 29,500/-</td>
                                                            <td data-title="12 ADULTS">₹ 28,400/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>BEST WAYS TO REACH <strong>JAMMU KASHMIR FAMILY TOUR PACKAGE</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST WAYS TO REACH <strong>JAMMU KASHMIR FAMILY TOUR PACKAGE</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <h4>TRAIN TICKETS FOR <strong>JAMMU KASHMIR FAMILY TOUR PACKAGE BY TRAIN</strong></h4><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Train No</th>
                                                            <th>Train Name</th>
                                                            <th>Operational Day</th>
                                                            <th>Classes Available</th>
                                                            <th>Dep. Station</th>
                                                            <th>Dep. Time</th>
                                                            <th>Arr. Station</th>
                                                            <th>Arr. Time</th>
                                                            <th>Travel Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Train No">13151</td>
                                                            <td data-title="Train Name">KOAA JAT EXPRESS</td>
                                                            <td data-title="Days of Operation">Mon, Tue, Wed, Thurs, Fri, Sat, Sun</td>
                                                            <td data-title="Classes Available">SL, 3E, 3A, 2A</td>
                                                            <td data-title="Dep. Station">KOLKATA</td>
                                                            <td data-title="Dep. Time">11:45</td>
                                                            <td data-title="Arr. Station">JAMMU TAWI</td>
                                                            <td data-title="Arr. Time">08:50</td>
                                                            <td data-title="Travel Time">45hr 05mins</td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Train No">12331</td>
                                                            <td data-title="Train Name">HIMGIRI EXPRESS</td>
                                                            <td data-title="Days of Operation">Tue, Fri, Sat</td>
                                                            <td data-title="Classes Available">SL, 3E, 3A, 2A, 1A</td>
                                                            <td data-title="Dep. Station">HOWRAH JN</td>
                                                            <td data-title="Dep. Time">23:55</td>
                                                            <td data-title="Arr. Station">JAMMU TAWI</td>
                                                            <td data-title="Arr. Time">13:00</td>
                                                            <td data-title="Travel Time">37hr 05mins</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>BEST KASHMIR OFFBEAT FAMILY TOUR PACKAGE</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BEST KASHMIR OFFBEAT FAMILY TOUR PACKAGE</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Kashmir Tour Packages</th>
                                                            <th>Price (Per Person)</th>
                                                            <th>Days/Nights</th>
                                                            <th>Inclusions</th>
                                                            <th>Places to Visit</th>
                                                            <th>Book Now</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-tulip-garden-festival-package-tour' target='_blank'><b className="tomato">Kashmir Package Tour with Tulip Package</b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Tulip Garden Tour</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-offbeat-package-tour-with-gurez-valley' target='_blank'><b className="tomato">Kashmir Package Tour with Gurez Valley</b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Gurez Valley</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"> <a href='/kashmir-offbeat-package-tour-with-baisaran-valley-doodhpathri-yusmarg' target='_blank'><b className="tomato">Kashmir Package Tour with Doodhpathri Yusmarg</b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Doodh Pathri, Sonmarg, Yusmarg</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-offbeat-package-tour-with-sinthan-top-aru-valley-betab-valley-chandanbari-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Offbeat Package Tour with Sinthan Top</b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Sinthan Top, Sonmarg</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/jammu-kashmir-family-tour-packages' target='_blank'><b className="tomato">Jammu Kashmir Family Tour Packages</b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Katra, Patnitop, Pahalgam, Gulmarg, Gurez Valley, Srinagar</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-with-amritsar-katra-vaishno-devi-pahalgam-gulmarg-srinagar-sonmarg' target='_blank'><b className="tomato">Kashmir Tour Packages with Amritsar Katra Vaishno Devi </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Amritsar, Vaishno Devi Darshan, Katra, Pahalgam, Gulmarg, Sonmarg, Srinagar</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-with-vaishno-devi-katra-pahalgam-gulmarg-srinagar' target='_blank'><b className="tomato">Kashmir Tour Packages with Vaishno Devi </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Vaishno Devi Darshan, Katra, Pahalgam, Gulmarg, Srinagar</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>BEST TIME TO VISIT VAISHNO DEVI <strong>POPULAR KASHMIR FAMILY TOUR PACKAGES</strong>?</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST TIME TO VISIT VAISHNO DEVI <strong>POPULAR KASHMIR FAMILY TOUR PACKAGES</strong>?</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Planning a family trip to Vaishno Devi? Picking the right time to go is important for a comfortable and enjoyable experience. Here's a simple guide to help you choose the best time to visit Vaishno Devi and explore the beautiful landscapes of Jammu & Kashmir.</p>
                                            <p><b>Spring (March to May)</b> :</p>
                                            <ul>
                                                <li>•	Mild weather between 10°C to 25°C, perfect for all ages.</li>
                                                <li>•	Melting snow and blooming flowers create a peaceful and vibrant atmosphere.</li>
                                                <li>•	Great time to explore Jammu & Kashmir Family Tour.</li>
                                            </ul><br />
                                            <p><b>Summer (June to August):</b></p>
                                            <ul>
                                                <li>•	Peak pilgrimage season with temperatures between 15°C to 30°C.</li>
                                                <li>•	Comfortable for families with children and elderly due to the manageable trek.</li>
                                                <li>•	Ideal for long daylight hours and clear skies.</li>
                                            </ul><br />
                                            <p><b>Autumn (September to November):</b></p>
                                            <ul><li>•	Cool weather with temperatures ranging from 7°C to 20°C.</li>
                                                <li>•	The region is covered in golden autumn colors, making the scenery beautiful.</li>
                                                <li> •	Perfect for enjoying both spirituality and nature on your Jammu & Kashmir holiday.</li>
                                            </ul><br />
                                            <p>By choosing the right season, you can make the most of your family trip to Vaishno Devi. Whether you prefer the cool spring, vibrant autumn, or summer's sunny days, there's always a good time to visit. Don’t forget to check out <strong>Jammu and Kashmir Tour Packages</strong> to make your trip smooth and memorable!</p>
                                            <br />
                                            <h4><b>BEST SEASONS TO VISIT VAISHNO DEVI DARSHAN</b></h4><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Months</th>
                                                            <th>Temp. (Min - Max)</th>
                                                            <th>Season</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Months">March - May</td>
                                                            <td data-title="Temp. (Min - Max)">10°C - 20°C</td>
                                                            <td data-title="Season">Spring</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Months">June - August</td>
                                                            <td data-title="Temp. (Min - Max)">15°C - 30°C</td>
                                                            <td data-title="Season">Summer</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Months">September - November</td>
                                                            <td data-title="Temp. (Min - Max)">7°C - 20°C</td>
                                                            <td data-title="Season">Autumn</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>POPULAR ATTRACTIONS OF PATNITOP WITH  <strong>KASHMIR TOUR PACKAGES FOR FAMILY</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>POPULAR ATTRACTIONS OF PATNITOP WITH  <strong>KASHMIR TOUR PACKAGES FOR FAMILY</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Patnitop is a beautiful hill station in the Jammu and Kashmir Himalayas, perfect for families looking for a peaceful getaway. Our <strong>Customized Kashmir Tour</strong> cover all the highlights of this scenic spot.</p>
                                            <p><b>•	Saffron Fields:</b> Visit the colorful saffron fields in Patnitop, especially during the blooming season, for a vibrant and fragrant experience.</p>
                                            <p><b>• Nathatop:</b> A perfect place for a picnic, Nathatop offers stunning views of snow-capped mountains and lush forests, making it ideal for family walks.</p>
                                            <p><b>• Shiva Garh:</b> Visit this temple dedicated to Lord Shiva, perched on a hill with incredible valley views, offering spiritual peace and breathtaking sights.</p>
                                            <p><b>• Mantalai Lake:</b> This hidden lake, surrounded by greenery, is a peaceful spot for families to enjoy boating or relax by the water.</p>
                                            <p><b>• Avantipur Ruins:</b> Explore these 9th-century ruins, featuring temples dedicated to Lord Vishnu and Lord Shiva, rich in cultural history and beautiful carvings.</p>
                                            <p><b>• Sanasar Lake:</b> Enjoy boating or other activities at this calm lake, surrounded by serene beauty, perfect for a relaxing family day out.</p>
                                            <p><b>• Patnitop Circular Road:</b> Take a scenic drive through forests with amazing views of the Himalayas, ideal for a memorable family road trip.</p>
                                            <p><b>• Paragliding Point:</b> For adventure lovers, try paragliding at Patnitop for thrilling views of the valley from the sky.</p>
                                            <p><b>• Naag Temple:</b> Visit this ancient temple dedicated to the Snake God, known for its intricate carvings and historical significance.</p>
                                            <p><b>• Anantnag Sulphur Spring:</b> Known for its healing properties, this natural spring offers warm, sulphur-infused water that helps with relaxation and wellness.</p>
                                            <p>Patnitop offers a mix of natural beauty, adventure, and culture. <strong>Book Kashmir Family Holiday Packages</strong> to explore all that this hidden gem has to offer.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>EXCITING ADVENTURES IN PAHALGAM: FAMILY-FRIENDLY ACTIVITIES</b></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>EXCITING ADVENTURES IN PAHALGAM: FAMILY-FRIENDLY ACTIVITIES</b></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Pahalgam, in the beautiful region of Jammu and Kashmir, is not just known for its stunning views but also for thrilling adventures. It’s the perfect spot for families looking for a fun-filled vacation. Here are some activities to enjoy on your <strong>Jammu Kashmir family package Tour</strong>:</p>
                                            <p><b>White Water Rafting on Lidder River:</b> For a real adventure, try white water rafting on the fast-flowing Lidder River. The rapids are great for both beginners and experienced rafters, with amazing mountain views along the way.</p>

                                            <p><b>Pony Riding in Aru Valley:</b> Take a pony ride through the peaceful Aru Valley, with its green meadows and tall pine trees. It’s a fun way for families to explore nature and enjoy the outdoors together.</p>

                                            <p><b>Golfing at Pahalgam Golf Course:</b> For a relaxed adventure, try golfing at the scenic Pahalgam Golf Course. With snow-capped mountains in the background, it’s a beautiful spot for golf lovers to enjoy a round.</p>

                                            <p><b>Trekking to Baisaran Valley:</b> Families can also enjoy trekking to Baisaran Valley, which is an easy hike offering amazing views of mountains and meadows. It’s a great way to take in the beauty of Pahalgam at a slower pace.</p>
                                            <p>These activities in Pahalgam add excitement to your <strong>Kashmir Family Tour</strong>. Whether you’re rafting, riding ponies, golfing, or trekking, there’s something for everyone to enjoy in the breathtaking landscapes of Pahalgam.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>SINTHAN TOP: A PERFECT ESCAPE FOR YOUR</b> <strong>KASHMIR TRIP</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>SINTHAN TOP: A PERFECT ESCAPE FOR YOUR</b> <strong>KASHMIR TRIP</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Sinthan Top is a beautiful mountain pass, ideal for families looking for adventure and natural beauty on their <strong>Kashmir Family Tour</strong>. Located between the Breng Valley in South Kashmir and the Chenab Valley in Jammu and Kashmir, it offers a peaceful and scenic escape.</p>
                                            <p>Sinthan Top, in Kishtwar district, is known for its stunning views and peaceful atmosphere. It's a great base for exploring the nearby alpine lakes and enjoying the area's natural beauty.</p>

                                            <p>At 12,500 feet above sea level, Sinthan Top is an important road connecting Anantnag and Kishtwar, offering breathtaking views of snow-capped peaks. The pass is 80 km from Kishtwar town, 130 km from Srinagar, and 48 km from Kokernag, making it a popular destination for travelers.</p>

                                            <p>Sinthan Top doesn't have any local residents, but it attracts shepherds and tourists alike. The 37-kilometer drive from Daksum is a scenic adventure, perfect for families who want to explore the beauty of <strong>Jammu Kashmir family package Tour</strong>.</p>

                                            <p>In short, Sinthan Top is a hidden gem, offering peaceful landscapes and thrilling adventures for families looking to enjoy the best of Jammu and Kashmir.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DIFFERENT DISTANCES TO NOTE FOR JAMMU & KASHMIR FAMILY TOUR</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DIFFERENT DISTANCES TO NOTE FOR JAMMU & KASHMIR FAMILY TOUR</h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead class="cf">
                                                        <tr className="table-danger">
                                                            <th className="text-center">From</th>
                                                            <th className="text-center">To</th>
                                                            <th className="text-center">Distance</th>
                                                            <th className="text-center">Duration</th>
                                                            <th className="text-center">Route</th>
                                                        </tr>
                                                    </thead>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Ahmedabad</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="Distance">1,671.9 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>30 hr</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 48</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Surat</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="Distance">1,926.2 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>36 hr</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 48</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Chennai</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="Distance">3,017.4 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>54 hr</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 44</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Pune</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="Distance">2,245.6 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>41 hr</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 44</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Bangalore</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="Distance">2,985.2 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>53 hr</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 44</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Kolkata</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="Distance">2,343.4 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>46 hr</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 44</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Delhi</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="Distance">812.5 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>18 hr 22 min</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 44</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Mumbai</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="Distance">2,169.8 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>42 hr</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 48</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Jammu</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="Distance">266.5 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>8 hr 14 min</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH 44</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Gulmarg</div></td>
                                                        <td data-title="Distance">49.5 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>1 hr 30 min</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via Srinagar - Tangmarg Rd</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Sonmarg</div></td>
                                                        <td data-title="Distance">79.4 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>2 hr 11 min</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH1</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Pahalgam</div></td>
                                                        <td data-title="Distance">89.6 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>2 hr 11 min</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via Srinagar - Kanyakumari Hwy</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Dras</div></td>
                                                        <td data-title="Distance">141.2 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>4 hr 2 min</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH1</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Kargil</div></td>
                                                        <td data-title="Distance">202.1 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>5 hr 19 min</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH1</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td data-title="From"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                        <td data-title="To"><div style={{ display: 'inline-block' }}>Leh</div></td>
                                                        <td data-title="Distance">418.5 km</td>
                                                        <td data-title="Duration"><div style={{ display: 'inline-block' }}>9 hr 41 min</div></td>
                                                        <td data-title="Route"><div style={{ display: 'inline-block' }}>Via NH1</div></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>SIGHTSEEING ENTRY FEES DURING <strong>BOOK KASHMIR TOUR PACKAGES FOR FAMILY</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>SIGHTSEEING ENTRY FEES DURING <strong>BOOK KASHMIR TOUR PACKAGES FOR FAMILY</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td>Places</td>
                                                            <td>Entry Fees</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dal Lake</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Nishant Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pari Mahal</td>
                                                            <td>Rs. 40/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shalimar Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shankaracharya Temple</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Chashme Shahi</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Verinag Garden</td>
                                                            <td>Rs. 10/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SPS Museum</td>
                                                            <td>Rs. 10 for Indian, Rs. 50 for Foreigners</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tulip Garden</td>
                                                            <td>Rs. 75 per adult, Rs. 30 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Aru Valley</td>
                                                            <td>Rs. 25/Person</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>GARDENS AND PEACE: A <strong>JAMMU KASHMIR FAMILY PACKAGE</strong> TOUR THROUGH FAMOUS GARDENS</h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>GARDENS AND PEACE: A <strong>JAMMU KASHMIR FAMILY PACKAGE</strong> TOUR THROUGH FAMOUS GARDENS</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Kashmir, known as "Paradise on Earth," is home to beautiful gardens filled with colorful flowers and peaceful surroundings. These famous gardens are must-visit spots for families and can be included in any <strong>Jammu and Kashmir holiday package</strong> for an unforgettable <strong>Kashmir family tour</strong>.</p>
                                            <p>Shalimar Bagh: Located by Dal Lake, Shalimar Bagh was built by Mughal Emperor Jehangir for his wife Noor Jahan. The garden is famous for its neat layout with fountains, pavilions, and beautiful flower beds. The view of the Zabarwan Mountains in the background adds to its charm. It’s a popular stop in <strong>Family Tour Packages for Jammu & Kashmir</strong>.</p>
                                            <p><b>Nishat Bagh:</b> Right next to Shalimar Bagh is Nishat Bagh, also called the "Garden of Joy." It was built by Asif Khan, Noor Jahan’s brother, and is the largest Mughal garden in Kashmir. The garden has twelve terraces, each representing a zodiac sign, making it a unique and interesting place to visit.</p>

                                            <p><b>Chashme Shahi:</b> Chashme Shahi, meaning "Royal Spring," is known for its fresh spring water believed to have healing powers. Built by Emperor Shah Jahan, this garden is a peaceful spot with green lawns and lovely terraces. It’s a great place for families to relax and enjoy the soothing atmosphere.</p>

                                            <p><b>Achabal Bagh:</b> Located in the Anantnag district, Achabal Bagh is a quiet and hidden gem. It was created by Empress Nur Jahan and has a beautiful waterfall and a three-level design. It’s perfect for families looking for a peaceful escape, and it’s a great addition to any family tour of Kashmir.</p>
                                            <p>These beautiful gardens in Kashmir offer a mix of nature, history, and peace, making a <strong>Jammu & Kashmir Family Tour</strong> truly special and memorable</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR FAMILY TOUR PACKAGE</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR FAMILY TOUR PACKAGE</strong></h4>

                                        </div>
                                        <div className="card-body">
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Shikara Rides:</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Shikara Rides:</b> Experience the tranquility of Dal Lake with a leisurely Shikara ride During your <strong> Kashmir Tulip Festival Package Tour</strong>. Glide across the serene waters, passing by floating gardens, houseboats, and vibrant markets. Sunset and early morning rides offer particularly magical views.</p>
                                            <center> <img src={Topbanner0ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Skiing and Snowboarding:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Skiing and Snowboarding:</b> During the winter months, places like Gulmarg transform into ski resorts, attracting enthusiasts from around the world. Gulmarg offers excellent slopes for skiing, snowboarding, and heli-skiing, with breathtaking views of the surrounding Himalayan peaks.</p>
                                            <center> <img src={Topbanner1ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Gondola Ride: </b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gondola Ride: </b>
                                                Take a ride on the Gulmarg Gondola, one of the highest cable cars in the world. Enjoy panoramic views of the snow-capped peaks as you ascend to Kongdori or Apharwat Peak, where you can indulge in activities like hiking and mountain biking.Experience this Awesome Ride During your <strong>Kashmir Tulip Garden Tour</strong> with Adorable Vacation and make this as a life long memories.</p>
                                            <center> <img src={Topbanner2ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Trekking:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Trekking:</b> Kashmir is a paradise for trekkers, with numerous trails that cater to all levels of expertise. Popular trekking destinations include the Kashmir Great Lakes Trek, Tarsar Marsar Trek, and the trek to Thajiwas Glacier in Sonamarg.</p>
                                            <center> <img src={Topbanner3ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>River Rafting:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>River Rafting:</b> The Lidder River in Pahalgam offers exciting opportunities for white-water rafting. Experience the thrill of navigating through rapids while enjoying the scenic beauty of the surrounding pine forests and mountains.</p>
                                            <center> <img src={Topbanner4ImageURL} className="img-fluid" alt="..." /></center>
                                            <br /><br /><br />
                                            <a href="tel://9147061467" className="btn btn-Warning" style={{ padding: '15px 30px', fontSize: '20px' }}>CALL NOW</a>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="container-fluid navtabCustom">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-highlights" aria-selected={customActiveTab === 'tab1'}>Highlights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-flights" aria-selected={customActiveTab === 'tab2'}>Flights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-hotel" aria-selected={customActiveTab === 'tab3'}>Hotel</button>
                                                <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-sightseeing" aria-selected={customActiveTab === 'tab4'}>Sightseeing</button>
                                                <button onClick={() => this.handleCustomTabClick('tab5')} className={`tab button ${customActiveTab === 'tab5' ? 'active' : ''}`} role="tab" aria-controls="nav-meals" aria-selected={customActiveTab === 'tab5'}>Meals</button>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent" >
                                            <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                                <p>The <strong>Kashmir Family Tour Package</strong> is famous for its stunning landscapes, including well-known spots like Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. These places draw tourists from all over the world who come to enjoy the beautiful scenery and take part in outdoor activities.</p>
                                                <p>Planning a <strong>Kashmir Family Tour Package</strong> is a great choice for travelers looking for beautiful scenery, cultural experiences, and adventure in a unique and stunning location.</p>
                                                <p>Kashmir is located in the Himalayan Mountains and is surrounded by India, Pakistan, and China. It has three main regions: Jammu, the Kashmir Valley, and Ladakh. The area is known for its snowy mountains, green valleys, winding rivers, and peaceful lakes.</p>
                                                <p>The <strong>Kashmir Family Tour Package</strong> are famous for their stunning landscapes, including places like Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. Tourists from around the world visit to enjoy the beautiful scenery and take part in outdoor activities. Kashmir has always been a popular destination, known for its natural beauty, adventure sports, religious sites, and historical landmarks. Visitors can enjoy activities like trekking, skiing, staying in houseboats, taking shikara rides, and exploring Mughal gardens and ancient temples.</p>
                                            </div>
                                            <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                                <div className="overflow-table">
                                                    <span>POPULAR FLIGHTS FOR THE KASHMIR TULIP GARDEN TRIP 2025<b> <i>(SXR)</i></b></span><br /><br />
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th>Flight Operator</th>
                                                            <th>Departure From</th>
                                                            <th>Departure Time</th>
                                                            <th>Arrival at Srinagar</th>
                                                            <th>Stopover</th>
                                                            <th>Travel Time</th>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Kolkata [CCU]</td>
                                                            <td>08:15</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>7h 00m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Mumbai [BOM]</td>
                                                            <td>09:30</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>5h 45m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SpiceJet</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>08:00</td>
                                                            <td>09:25</td>
                                                            <td>(None)</td>
                                                            <td>1h 25m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Bangalore [BLR]</td>
                                                            <td>09:30</td>
                                                            <td>14:10</td>
                                                            <td>Sri Guru Ram Dass Jee [ATQ]</td>
                                                            <td>4h 40m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Goa [GOX]</td>
                                                            <td>10:30</td>
                                                            <td>16:45</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>6h 15m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Hyderabad [HYD]</td>
                                                            <td>08:55</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>6h 20m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Chennai [MAA]</td>
                                                            <td>08:30</td>
                                                            <td>14:20</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>5h 50m</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">

                                                <div className="overflow-table">
                                                    <div id="no-more-tables">
                                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                            <thead class="cf">
                                                                <tr className="text-center">
                                                                    {/* <!-- Add the "text-center" className to center content --> */}
                                                                    <th colspan="6" style={{ fontSize: "1.3vw" }}><h6><b>3 STAR CATEGORY HOTEL CERTIFIED BY 'ADORABLE VACATION' </b></h6></th>
                                                                </tr>
                                                                <tr className="table-danger">
                                                                    <th className="text-center">Destination</th>
                                                                    <th className="text-center">Hotel Names</th>
                                                                    <th className="text-center">Hotel Type</th>
                                                                    <th className="text-center">No of Nights</th>
                                                                    <th className="text-center">Meal Plan</th>
                                                                </tr>
                                                            </thead>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Grand Fortune / Hotel Shefaf / Hotel Siddique Palace / Similar</div></td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>5N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Gulmarg</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Affarwat / Hotel Mama Palace /Gulmarg House / Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>1N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Pahalgam</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Pine Palace Resort / Hotel Fifth Season / Hotel Moonview Resort /Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>2N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                                <div className="container-fluid px-0 py-1">
                                                    <h5>WHERE TO STAY DURING YOUR <strong>KASHMIR FAMILY TOUR PACKAGE</strong></h5><br />
                                                    <p>Your choice of accommodation can make a big difference in your <strong>Kashmir Family Tour Package</strong> Adventure Tours experience. Here are some great options for where you can stay: </p><br />
                                                    <p><b className='tomato'>Houseboats on Dal Lake </b> Stay in a traditional houseboat on Dal Lake in Srinagar for a truly unique experience. These beautifully decorated houseboats offer a peaceful and relaxing stay, surrounded by the lake’s calm waters and stunning scenery. You’ll also get to enjoy the warm hospitality of the locals.
                                                    </p>
                                                    <p><b className='tomato'>Luxury Hotels and Resorts: </b>Kashmir has plenty of luxury hotels and resorts in popular places like Srinagar, Gulmarg, and Pahalgam. These hotels provide top-quality service, modern amenities, and amazing views of the landscape, making your stay comfortable and unforgettable.</p>

                                                    <p><b className='tomato'>Boutique Guesthouses: </b>If you want a more personal experience, boutique guesthouses and homestays are a great choice. These cozy accommodations are often located in quiet areas or beautiful countryside settings, allowing you to connect with local culture while enjoying a comfortable stay.</p>

                                                    <p><b className='tomato'>Tented Camps: </b>For adventure lovers and nature enthusiasts, staying in a tented camp is a unique way to experience Kashmir’s natural beauty. Set in lush valleys, near mountains, or beside streams, these camps provide a simple yet comfortable stay close to nature.</p>

                                                    <p><b className='tomato'>Heritage Properties: </b>For a taste of Kashmir’s history and culture, you can stay in heritage properties like old mansions or palaces. These historic places offer a glimpse into Kashmir’s past while providing modern comforts, making your stay both elegant and memorable.</p>

                                                    <p><b className='tomato'>Eco-Resorts and Retreats: </b>If you care about the environment, eco-resorts and retreats are great options. These sustainable accommodations are designed to be eco-friendly without sacrificing comfort. Set in serene forests, meadows, or mountains, they offer a peaceful and green place to stay.</p>
                                                    <p>No matter which option you choose, each type of accommodation in Kashmir has its own special charm. Think about your preferences, budget, and travel plans to find the best fit for your <strong>Luxury Kashmir Family Tour Package</strong>.</p>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                                <div className="overflow-table">
                                                    <h5 class="tomato">8 times Meals and 8 times Breakfast has been Provided</h5>
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th colspan="6" className="text-center">Category</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">JAIN</td>
                                                            <td class="text-center">PURE-VEG</td>
                                                            <td class="text-center">VEGAN</td>
                                                            <td class="text-center">PURE-NON-VEG</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br /><br /><br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                DAY WISE <strong><span>JAMMU & KASHMIR FAMILY TOUR ITINERARIES</span></strong>
                                            </h2>
                                            <h4>8 Nights 9 Days <strong>Kashmir Tour Itinerary</strong></h4>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--end Short description--> */}
                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    DAY 1  – Arrive Jammu Station and Transfer to Katra
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Meet & Greet at Jammu station and transfer to Katra.
                                                        Overnight at Katra
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day2"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    DAY 02: Vaishnodevi Darshan
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast and check in to the Hotel later on proceed for Vaishno Devi Darshan One way Helicopter to Reach Mata Vaishnodevi (On additional cost basis).
                                                        One Way Helicopter charge Rs.1800/- per person.Overnight at Katra.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day3"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 03: Katra to Pahalgam
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast leave for Pahalgam 2440 Mtrs (vale of Kashmir). It is a 4 hours journey  on the way.On the way visit Saffron fields, Avantipur ruins and Anantnag sulpher spring. Bat village and apple orchids. Pahalgam is the most famous place for the Indian Film Industry. Enjoy the natural charm of the valley. In the evening, enjoy the music of the running water of river lidder and spend a few hours in the evening on the back of the river.Overnight at Pahalgam.</p>
                                                    <br />

                                                    <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="Kashmir Tour Packages" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Packages" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day4"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 04: - Pahalgam Local Sightseeing ( Include )
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <p>After a leisurely breakfast, a full day free to enjoy and explore the Beauty of “Pahalgam”. One can go to
                                                        Chandanwari ( 16 Kms from Pahalgam ) which is the starting point of Amarnath Yatra, Aru (10 kms from Pahalgam) which is a fine meadow of picturesque scenery and starting point of trekking of (Liddarwat, Kolahoi Glacier & Trsar Lake) & Betaab Valley. Direct Payment Basis.
                                                    </p>
                                                    <p><b>ARU VALLEY </b>
                                                        It is located around 12 km from Pahalgam, 11 km upstream from the Lidder River. Noted  for its scenic meadows, lakes and mountains, it is a base camp for trekking to the Kolahoi Glacier and Tarsar Lake. The village lies on the left bank of the Aru river, which is a tributary of the Lidder river The Aru valley is noted for its scenic meadows. It is popular among the tourists for its peaceful environment and scenic beauty. Other popular tourist activities include fishing for trout in the Lidder river, trekking, hiking, horse riding, sightseeing and photography.
                                                    </p>
                                                    <p><b>BETAAB VALLEY </b>
                                                        It is situated at a distance of 15 kilometers from Pahalgam. The valley got its name from the Sunny Deol– Amrita Singh hit debut film Betaab. The valley is surrounded by lush green meadows, snow-clad mountains and covered with dense vegetation. Betaab Valley is a very popular tourist destination. Betaab Valley is also a favorite campsite of travellers as it also serves as a base camp for trekking and further exploration of the mountains. The valley is a walk-able distance from Pahalgam. The crystal clear & chilly water of the  stream bubbling down from the snowy hills is a delight; locals here drink this water too.
                                                    </p>
                                                    <p><b>CHANDANWARI</b>
                                                        Chandanwari - Situated 16 km from Pahalgam, is the starting point of the Amarnath  Yatra, which takes place every year in the month of Sawan (Rain). It is famous for snow sledding on a snow bridge. Taxi cabs can be hired from Pahalgam for a roundtrip. Snow fights" is what people generally indulge in here before they sledge back down. Many Prefer to sip a cup of tea or snack at the nearby dhabas. Hajan, on the way to Chandanwari is an idyllic spot for a picnic. Filmgoers will recognize it instantly as it has been the location of several movie scenes.
                                                    </p>
                                                    <p>Or<br />
                                                        You can explore the Beauty of “Pahalgam”. You can hire ponies (Directly payment basis ) for a visit to Baisaran Valley / Mini Switzerland.

                                                        Overnight at Pahalgam
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay4ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day5"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 05: Pahalgam – Gulmarg: 3 hrs
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After a leisurely breakfast, drive to Gulmarg via Bye-Pass.  Gulmarg, called the “Meadow of  Flowers”. Gulmarg is at an altitude of 8700 – ft. Gulmarg also referred to as Meadow of Flowers is a famous Ski Resort & the world’s highest 18 hole Golf Course. The place was prominent during British time.  Gulmarg's legendary beauty, prime location and proximity to Srinagar naturally make it one of the premier hill resorts in the country. Originally called ‘Gaurimarg’ by shepherds, its present name was given in the 16th century by Sultan Yusuf Shah, who was inspired by the sight of its grassy slopes emblazoned with  wild flowers. Gulmarg was a favorite haunt of Emperor Jehangir who once collected 21 different varieties of flowers from here. Today Gulmarg is not merely a mountain resort of   exceptional beauty- it also has the highest green golf course in the world, at an altitude of 2,650 m, and is the country's premier ski resort in the winter. On Arrival in Gulmarg, check in to the pre-arranged Hotel. Rest of the day free at leisure.One Can go for theDinner & Overnight stay at Gulmarg.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day6"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 06: Gulmarg Gondola Ride and transfer to Srinagar
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After an early breakfast, check out from the Hotel & and Enjoy the world famous Gondola Ride up to 1st or 2nd Phase. The Gulmarg Gondola is a tourist attraction in itself. It is one of the highest cable cars in the world, reaching 3,979 metres. The two-stage ropeway ferries about 600 people per hour to and from the gondola main station in Gulmarg to the shoulder of nearby Mt.Affarwat Summit (4,200 m  (13,780 ft)). There are also countless off-piste possibilities through the beautiful Khilanmarg meadows on the foot of Mount Affarwat, which offer a scenic view of Nanga Parbat. The Affarwat ridge at 3850+ metres offers an avalanche controlled ski area that offers a wide field of snow to descend 800 m in approximately 3 km of skiing, and is for advanced skiers only. Due to Gulmarg's steep terrain, the region is popular amongst advanced and extreme skiers from around the world and has been visited by a number of ski professionals and featured in a number of ski films also.  Later on transfer to Srinagar.Overnight stay at Srinagar
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay6ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day7"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 07: IN SRINAGAR Local Sightseeing
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After Breakfast take a visit to Shankaracharya Temple, It is believed that it was the place where the great philosopher and saint Shankaracharya stayed when he visited Kashmir ten centuries ago to revive Sanathan Dharma. The original ceiling was dome- shaped and the brick roof is not more   than a century old. In the afternoon, we took a visit to Mughal Gardens, Nishat Garden known as  “Garden of pleasure’ built in 16th century (1633) by Mughal King Asif Khan. Then visit Shalimar  Garden known as ‘Garden of love’ built in 16th century (1619) by Mughal King Jahangir for his beloved wife Noor Jahan or (Mehr-un-Nissa & Chashmashahi (The Royal Spring). The Chashmashahi Mughal garden in Srinagar had been loved by emperors and picnickers alike. Evening Enjoy the world famous sightseeing at Dal lake Overnight stay at Srinagar

                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay7ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day8"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 08: IN SRINAGAR (DAY EXCURSION TO SONMARG) -87 Kms – 2 to 3 hrs One Way) N/Stay at Houese Boat
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTen"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast we depart on an excursion to Sonmarg. Sonmarg, which means Meadow of Gold, has its backdrop of snowy mountains against the blue sky. The drive offers incredible scenic beauty, dense pine forests and gigantic mountains. The place provides an ideal base for various trekking routes, high mountain lakes, glaciers, and Sri Amarnath Holy cave. The river meanders along here & abounds with Trout & Mahseer. Sonamarg’s importance lies in the fact that it is the last halt on the Kashmir side for the drive from Srinagar to Leh. Later we drive back and reach Srinagar by evening for dinner & Overnight stay in Srinagar House Boat.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay8ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day9"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 09: IN SRINAGAR (DAY EXCURSION TO SONMARG) -87 Kms – (2 to 3 hrs One Way) N/Stay at Houese Boat
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTen"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Tour concludes. Adorable Vacation thanks you for your patronage and ensures a punctual transfer to Domestic Airport to catch the flight for your onward Destination.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay9ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay9ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h2>The Khyber Himalayan Resort & Spa <span>- Gulmarg</span></h2>
                                            <h4><i>Next Level Luxury Resort </i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_1_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Platinum Accommodation</td>
                                                <td data-title="Size(Room)">386+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Khyber Hotels (Heritage)<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 53,119/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >The LaLiT Grand Place <span>- Srinagar</span></h2>
                                            <h4 ><i>Fill the Luxury</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_2_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Premium Accommodation</td>
                                                <td data-title="Size(Room)">290+ SQ.FT</td>
                                                <td data-title="Hotel Brand">The LaLiT Hotels<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 28,332/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>The LaLit Grand Palace</b> was originally built in 1901 for the royal family. It now provides luxurious rooms with views of Dal Lake and the beautiful gardens. An indoor pool, tennis courts and pampering spa treatments are also available.The tastefully designed rooms are modern with a touch of traditional charm. All have a satellite TV, minibar and free tea/coffee making facilities. It is 18 km from Srinagar Airport and 4 km from Lal Chowk city centre. Jammu Railway Station is 300 km away. Guests can enjoy a relaxing massage at Rejuve Spa Health Club, practice yoga, or play golf. The hotel also has a gym and 24-hour business center. The Chinar Garden serves North Indian cuisine, while international buffet spreads are available at The Chinar – The All Day Coffee Shop. Other dining options include Dal Bar and 24-hour room service.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>

                            <br /><br />
                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Kashmir<span> Package Tour From Ahmedabad </span></h2>
                                            <h4>Related Kashmir Tour plan</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >Frequently Ask <span>Questions</span></h2>
                                            <h4 >Kashmir Family Package Tour Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" >
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion" id="accordionFlushExample">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="flush-headingNineteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNineteen" aria-expanded="false" aria-controls="flush-collapseNineteen">
                                                        Q. How far is Vaishno Devi Temple from Katra?
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseNineteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingNineteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> The distance from Katra to Vaishno Devi Temple is about 14 kilometers (8.7 miles). Katra is the starting point for pilgrims heading to the temple. Many people choose to trek, but there are other options like horses, palanquins, or helicopters to reach the temple.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="flush-headingTwenty">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwenty" aria-expanded="false" aria-controls="flush-collapseTwenty">
                                                        Q. What is Katra known for?
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseTwenty" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwenty" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Katra is best known as the base for the pilgrimage to Vaishno Devi Temple, one of the most important Hindu shrines in India. Apart from religious significance, Katra has many hotels, restaurants, and shops for visitors. The town is surrounded by the scenic Trikuta Mountains and has a lively market where you can buy souvenirs and religious items.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="flush-headingTwentyOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyOne" aria-expanded="false" aria-controls="flush-collapseTwentyOne">
                                                        Q. What is the trekking route from Katra to Vaishno Devi?
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseTwentyOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentyOne" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> The trek from Katra to Vaishno Devi is about 14 kilometers long and passes through beautiful scenery. Here's a brief overview:
                                                            <ul>
                                                                <li><b>Katra to Banganga:</b> 1.5 km – a rest point with facilities.</li>
                                                                <li><b>Banganga to Charan Paduka:</b> 3 km – where pilgrims believe the Goddess left her footprints.</li>
                                                                <li><b>Charan Paduka to Adhkuwari:</b> 6 km – site of a holy cave where the Goddess meditated.</li>
                                                                <li><b>Adhkuwari to Sanjichhat:</b> 9.5 km – a spot to rest with views of the temple.</li>
                                                                <li><b>Sanjichhat to Bhawan (Vaishno Devi Temple):</b> 2.5 km – the final part of the journey to the temple.</li>
                                                            </ul>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="flush-headingTwentyTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyTwo" aria-expanded="false" aria-controls="flush-collapseTwentyTwo">
                                                        Q. Are there luxury hotels in Katra and Patnitop?
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseTwentyTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentyTwo" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Yes, both Katra and Patnitop offer luxury hotels.
                                                            <ul>
                                                                <li><b>In Katra:</b>
                                                                    <ul>
                                                                        <li>The White Hotels: Offers modern amenities and comfortable rooms.</li>
                                                                        <li>Vaishnodevi IRCTC Guest House: Run by Indian Railways, known for comfort and convenience.</li>
                                                                        <li>The Atrium on the Greens: Combines comfort with elegance, offering a range of services.</li>
                                                                    </ul>
                                                                </li>
                                                                <li><b>In Patnitop:</b>
                                                                    <ul>
                                                                        <li>Hotel Jai Skahan: Offers modern facilities with scenic views.</li>
                                                                        <li>Vardaan Resorts Patnitop: A luxurious resort with beautiful views and well-appointed rooms.</li>
                                                                        <li>Hotel Samson: Provides a comfortable stay with various amenities.</li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="flush-headingTwentyThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyThree" aria-expanded="false" aria-controls="flush-collapseTwentyThree">
                                                        Q. Where is Gurez Valley?
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseTwentyThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentyThree" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Gurez Valley is in the northern part of Jammu and Kashmir, near the Line of Control (LoC) between India and Pakistan. It’s a remote valley known for its beautiful landscapes, alpine meadows, and wooden houses. The Kishanganga River flows through the valley, adding to its scenic charm. Be aware that Gurez Valley is close to a sensitive border area, and visitors might need special permits to visit.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h3><b>Kashmir <span class="tomato">Holiday Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container" >

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                        {this.state.showModal && (
                            <div className="modal" style={{ display: this.state.showModal ? "block" : "none" }}>
                                <div className="modal-dialog">
                                    <div className="modal-content cust-modContent">
                                        <div className="modal-header">
                                            <h4 className="modal-title ms-auto" id="exampleModalLabel">Get a Free Callback &nbsp;&nbsp;<i className="fa fa-phone fa-rotate-90"></i>
                                            </h4>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.onCloseModal}></button>
                                        </div>
                                        <div className="modal-body">
                                            <form className="row g-3">
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name *"
                                                        name="firstName"
                                                        value={this.state.firstName}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                {/* <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Last Name *"
                                                        name="lastName"
                                                        value={this.state.lastName}
                                                        onChange={this.onChangeHandler} />
                                                </div> */}
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="10 Digit Mobile No.*"
                                                        name="mobile"
                                                        value={this.state.mobile}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Email *"
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Comments"
                                                        rows="2"
                                                        name="comments"
                                                        value={this.state.comments}
                                                        onChange={this.onChangeHandler}></textarea>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <div className='container'>
                                                <div className="row">
                                                    <div className='col-sm-4'>
                                                        {
                                                            (this.state.showLoader) &&
                                                            <div className='pull-left'><Digital size={25} /></div>
                                                        }
                                                    </div>
                                                    <div className='col-sm-8 '>
                                                        <div className='pull-right'>
                                                            <button type="button" className="btn btn-Warning" onClick={this.onRequestCallbackSubmit} >Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div >
                </div >
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91470-61467">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                    </a>
                </div>

            </>
        );
    }
}

export default Kashmir_7N_8D_Family_Package;