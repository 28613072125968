import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop_bhutan';
import SuratToBhutanPackage from '../../../components/package/Bhutan/SuratToBhutanPackage';
import { Helmet } from "react-helmet";

class SuratToBhutanPage extends Component {
    constructor() {
        super();
        this.state = {
            HotPackagesData: [],
            ThemePackagesData: [],
            HotelsData: [],
            TrendingBhutanDataList: [],
            ReviewersData: [],

        }
    }


    componentDidMount() {
        window.scroll(0, 0);
        const hotPackageDataList = [
            {
                Title: "Blissful Bhutan",
                ImageName: "4N-5D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 1N Punakha | 1N Paro | Free Sim Card | E-Permit",
                PricePerNight: "22,599",
                NoOfTours: 42,
                NoOfDeparture: 35,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/4N-5D-FIFO-package'
            },
            {
                Title: "Untouched Bhutan",
                ImageName: "5N-6D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 1N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "27,899",
                NoOfTours: 45,
                NoOfDeparture: 39,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/5N-6D-FIFO-package'

            },
            {
                Title: "Explore Bhutan",
                ImageName: "6N-7D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "32,999",
                NoOfTours: 43,
                NoOfDeparture: 38,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/6N-7D-FIFO-package'
            },

            {
                Title: "Excape Bhutan",
                ImageName: "7N-8D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro | Free Sim Card | E-Permit",
                PricePerNight: "37,999",
                NoOfTours: 47,
                NoOfDeparture: 40,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/7N-8D-FIFO-package'
            },
            {
                Title: "Pleasing Bhutan",
                ImageName: "8N-9D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "3N Thimphu | 2N Punakha | 3N Paro | Free Sim Card | E-Permit",
                PricePerNight: "43,499",
                NoOfTours: 44,
                NoOfDeparture: 37,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/8N-9D-FIFO-package'
            },
            {
                Title: "Fascinating Bhutan",
                ImageName: "10N-11D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Bumthang...more | Free Sim Card | E-Permit",
                PricePerNight: "53,899",
                NoOfTours: 46,
                NoOfDeparture: 39,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/10N-11D-FIFO-package'
            }

        ];

        const themePackageDataList = [
            {
                Title: "Bhutan Honeymoon",
                ImageName: "Bhutan-Honeymoon-Package.webp",
                AllternateText: "...",
                Description: "Bhutan Package is your ultimate destination. 7N/8D is the decent duration to explore the relation between Nature & Better Half.",
            },
            {
                Title: "Wedding at Bhutan",
                ImageName: "Destination-Wedding-at-Bhutan.webp",
                AllternateText: "...",
                Description: "Make your iconic marriage day very very special at Bhutan. Marriage like VIP, execute a super destination wedding 'LIKE NEVER BEFORE'.available.",
            },
            {
                Title: "Bhutan Solo Trip (Woman)",
                ImageName: "Bhutan-Solo-Trip-Woman-Special.webp",
                AllternateText: "...",
                Description: "Love to explore everything by yourself ? Then Bhutan is the god gifted gem for you. Numerous things are present to explore,experience & recreate.",
            },
            {
                Title: "Bhutan Adventure Trip",
                ImageName: "Bhutan-Adventure-Packages.webp",
                AllternateText: "...",
                Description: "After NEPAL, Bhutan is an amazing counter destiation for Adventure lover. Explore with thrilling activities - River Rafting, Paragliding & Kayaking etc.",
            },
            {
                Title: "Bhutan Bike Trip",
                ImageName: "Bhutan-Bike-Package-Tour.webp",
                AllternateText: "...",
                Description: "Throughout the Bhutan bike trip, you will pass through curvy roads with most picturesque surroundings with high eastern Himalayan snow-capped peaks.",
            },
            {
                Title: "Pilgrimage of Bhutan",
                ImageName: "Bhutan-Monastery-Packages.webp",
                AllternateText: "...",
                Description: "Bhutan is the only country in the world that officially practices Mahayana Buddhism or Vajrayana Buddhism. Buddhism is a way of life in Bhutan.",
            },
            {
                Title: "Bhutan Snowman Trek",
                ImageName: "Bhutan-Snowman-Trek.webp",
                AllternateText: "...",
                Description: "The Snowman Trek is the longest hiking trail of Bhutan that extends from Laya to the high Bhutanese Himalayas, covering up the northern Kingdom.",
            },
            {
                Title: "Bhutan Foreigners Package",
                ImageName: "Bhutan-Forigners-Package.webp",
                AllternateText: "...",
                Description: "As a foreigner, living in Bhutan can be a rewarding and fascinating experience,a small landlocked country in the eastern Himalayas, is known for its unique culture.",
            },

        ]

        const reviewersDataList = [
            {
                Name: "Mahendra Khandpekar",
                ImageName: "Mahendra-Khandpekar.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "I am extremely happy to choose adorable travels for my family trip from Mumbai to Bhutan during 26 May - June 2, 2024 via direct chartered flight. Sonali and then Sneheta made the reservation process very simple and transparent. They responded for every query of mine. The trip and hotels were very comfortable & cosy. Our trip guide Karma Jigme and driver Tshering Dorji were a perfect combination. Karma patiently answered all our questions with his excellent English accent. Tshering was jolly throughout. As it was a customised tour, Karma took every effort to show us the places like more monasteries & places we wished for. Karma motivated my family to trek the challenging Paro Taktsang valley till the top. I was astonished to see the love of Bhutanese people for India who understood and spoke hindi making things more Indian with simple Indian- Bhutanese cusine. The SDF fee per person per day, compulsory guide & high entry fees to the monuments were taxing at times unlike India. Overall we got the real feel of Bhutan with so much of hidden and mysterious history and happy going people.",
            },
            {
                Name: "SAHIL GARUD",
                ImageName: "SAHIL-GARUD.webp",
                AllternateText: "...",
                Location: "@Chennai",
                Comment: "We traveled to bhutan this summer in may 2024 , looking at great reviews we booked our family trip by adorable vacation based in Kolkata.We had a wonderful trip to Bhutan, right from arranging direct flights to paro from Mumbai was very convenient for us, we had a customized tour with a private car for my family, right from pick from airport to all the sightseeing till the early morning drop to the airport was perfectly managed.The adorable vacation team guided us really well on all the hotels and all the sightseeing since we were traveling with our 3 yr old daughter and parents .The guide was also good, my wife and me also managed to visit the tigers nest in paro which was a steep hike .Overall will definitely recommend adorable vacation for their brilliant and transparent way of managing and making holidays more memorable.Thanks to sonali, sneheta and akash who were really helpful in the process.Great memories thanks 🙏🏻.",
            },
            {
                Name: "Samarendra Roy",
                ImageName: "Samarendra-Roy.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Bhutan tour will be remembered as one of the best tour organised by Adorable.Bhutan is found to be beautiful country.People wellbehaved.Very disciplined and humble.Thank you Soumalya Ghosh And Sneheta madam For arranging an excellent tour of Bhutan.We have enjoyed every moment of it. We also highly appreciate the Guide Deepak , he was very very good.His cooperation was of the highest order. Also We appreciate the driver Sonam , for his excellent driving with such calmness.We thank Adorable for letting us see such a great place like Bhutan.The land of calmness, cooperative, well disciplined,Honesty, beautiful.",
            },
            {
                Name: "Sangeeta B",
                ImageName: "Sangeeta-B.webp",
                AllternateText: "...",
                Location: "@Chennai",
                Comment: "This was our second trip coordinated by Adorable. We had been to Bhutan for a 6N 7D package. Had a very professional experience and the journey was well coordinated. Would recommend Adorable.",
            },
        ];

        const hotelDataList = [
            {
                HotelName: 'Hotel Ugyen, Thimpu',
                ImageName: "hotel-ugyen.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.9,
                Reviews: '50 Reviews'
            },
            {
                HotelName: 'Amodhara, Thimphu',
                ImageName: "amodhara-2.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '47 Reviews'
            },
            {
                HotelName: 'White Dragon, Punakha',
                ImageName: "white-dragon.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.7,
                Reviews: '46 Reviews'
            },
            {
                HotelName: 'Pema karpo, Punakha',
                ImageName: "pema-karpo.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.6,
                Reviews: '48 Reviews'
            },
            {
                HotelName: 'Layhuel, Paro',
                ImageName: "lehul.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '49 Reviews'
            },
            {
                HotelName: 'Tshringma, Paro',
                ImageName: "Tsherim-resort.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.7,
                Reviews: '43 Reviews'
            },
            {
                HotelName: 'Green Touch, Lataguri',
                ImageName: "green-touch.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.6,
                Reviews: '44 Reviews'
            },
            {
                HotelName: 'Suncity, Lataguri',
                ImageName: "suncity.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '40 Reviews'
            }
        ];

        const trendingBhutanDataList = [
            {
                Title: "Blissful Bhutan",
                ImageName: "6N-7D-Bhutan-Tour1.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "31,899",
                NoOfTours: 43,
                NoOfDeparture: 38
            },
            {
                Title: "Untouched Bhutan",
                ImageName: "7N-8D-Bhutan-Tour1.webp ",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro | Free Sim Card | E-Permit",
                PricePerNight: "37,599",
                NoOfTours: 47,
                NoOfDeparture: 40
            },
            {
                Title: "Explore Bhutan",
                ImageName: "9N-10D-Bhutan-Tour1.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "48,899",
                NoOfTours: 44,
                NoOfDeparture: 37
            },
            {
                Title: "Excape Bhutan",
                ImageName: "12N-13D-Bhutan-Tour1.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro | Free Sim Card | E-Permit",
                PricePerNight: "76,500",
                NoOfTours: 46,
                NoOfDeparture: 39
            }


        ];


        this.setState({
            HotPackagesData: hotPackageDataList,
            ThemePackagesData: themePackageDataList,
            HotelsData: hotelDataList,
            TrendingBhutanDataList: trendingBhutanDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Bhutan Package Tour from Surat, Bhutan Tour Package from Surat, Bhutan Package Tour from Surat via Mumbai Airport</title>
                    <meta name="description" content="Book amazing Bhutan Package Tour from Surat via Mumbai Airport at the most Affordable Price. FOR BOOKING ☎ CALL +91-98833-59713" />
                </Helmet>

                <MainHeader />

                <SuratToBhutanPackage
                    HotPackagesData={this.state.HotPackagesData}
                    ThemePackagesData={this.state.ThemePackagesData}
                    HotelsData={this.state.HotelsData}
                    TrendingBhutanDataList={this.state.TrendingBhutanDataList}
                    ReviewersData={this.state.ReviewersData}
                />

                <FooterDesktop />
            </>
        )
    }
}

export default SuratToBhutanPage;