import React, { Component, useState } from 'react';
import BookingBox from '../BookingBox';
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Bhutan/InclusionExclusion_bhutan_b2b';

class Bhutan_B2B_7N_8D_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            btn: "Submit",
            showLoader: false,
            isHidden: true,
            showModal: false,
            showMegamenu: false, // New state to handle megamenu
            firstName: '',
            mobile: '',
            email: '',
            comments: '',
            megamenuTimeout: null // New state for managing hover delay
        }
        this.timeoutId = null; // To store the timeout ID
        this.modalRef = React.createRef(); // Create a ref for the modal
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919883359713&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ showModal: true });
        }, 40000);
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        // Cleanup the event listener
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        // Check if the click is outside the modal
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.onCloseModal();
        }
    };

    toggleHidden = (e) => {
        e.preventDefault();
        this.setState({ isHidden: !this.state.isHidden });
    }

    handleMegamenuEnter = () => {
        // Clear any existing timeout
        if (this.state.timeoutId) {
            clearTimeout(this.state.timeoutId);
        }
        this.setState({ isHidden: false });
    };

    handleMegamenuLeave = () => {
        // Set a timeout to delay hiding the megamenu
        const timeoutId = setTimeout(() => {
            this.setState({ isHidden: true });
        }, 300); // Match this delay with the CSS transition time

        this.setState({ timeoutId });
    };


    onRequestCallback = (e) => {
        e.preventDefault();
        if (!this.state.showModal) {
            this.setState({ showModal: true });
        }
    }

    onCloseModal = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            showModal: false,
            firstName: '',
            mobile: '',
            email: '',
            comments: ''
        });
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    // Function to handle form submission
    onRequestCallbackSubmit = async (e) => {
        e.preventDefault();

        const { firstName, email, mobile, comments } = this.state;

        // Validation
        if (firstName.trim() === '') {
            cogoToast.error("Full Name Required.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
            // } else if (email.trim() === '') {
            //     cogoToast.error("Email Required.", {
            //         position: 'top-center', // Use top-center, then adjust via CSS
            //         hideAfter: 2, // optional: auto-hide after 2 seconds
            //         className: 'custom-toast' // Apply custom class
            //     });
        } else if (mobile.trim() === '' || !/^\d{10}$/.test(mobile.trim())) {
            cogoToast.error("Please Insert 10 digit Mobile No.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else {
            this.setState({ btn: "Processing..", showLoader: true });
            // Proceed with form submission
            try {
                const _ref = 'yes';
                const _url = window.location.href;

                const formData = {
                    FirstName: firstName,
                    Email: email,
                    Mobile: mobile,
                    Comments: comments,
                    Referrer: _ref,
                    URL: _url
                };

                const response = await axios.post(ApiURL.SaveCustomerEnquery, formData);
                console.log('Data submitted successfully:', response.data);
                // Optionally, reset the form state after successful submission
                this.setState({ firstName: '', email: '', mobile: '', comments: '' });

                // Optionally, show a success message to the user
                cogoToast.success("Form submitted successfully.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
                this.setState({ btn: "Submit", showLoader: false, showModal: false });
            } catch (error) {
                console.error('Error submitting data:', error);
                this.setState({ btn: "Submit", showLoader: false });
                // Optionally, show an error message to the user
                cogoToast.error("Please try again after 24 hrs. later.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
            }

        }

    };

    /* RedirectToOTPPage = () => {
        if (this.state.redirectOTPStatus === true) {
            let url = "/verifyotp/" + this.state.mobile;
            return <Redirect to={url} />
        }
    } */

    onCloseModal = () => {
        this.setState({ showModal: false }, () => {
            // Additional code to perform after the modal is closed
            setTimeout(() => {
                this.setState({ showModal: true }, () => {
                    // Additional code to perform after the next modal is displayed
                });
            }, 55000); // 55sec in milliseconds
        });
    };

    render() {
        const sixSenseBhutanTourPackagesImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-with-hotel-six-senses.webp";
        const amanKoraBhutanPackagesImageURL = ApiURL.BhutanTourPackageFromMumbaiImageURL + "/bhutan-package-tour-from-mumbai-with-hotel-aman-kora.webp";
        const sightseen1ImageURL = ApiURL.BhutanB2BPackageImageURL + "/adorable-vacation-bhutan-tour-operator-for-b2b.webp";
        const sightseen2ImageURL = ApiURL.BhutanB2BPackageImageURL + "/b2b-travel-agen-for-bhutan-tour-from-india.webp";
        const sightseen3ImageURL = ApiURL.BhutanB2BPackageImageURL + "/b2b-travel-agents-for-bhutan-tour-from-bagdogra.webp";
        const sightseen4ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-b2b-group-tour-from-bagdogra-with-adorable.webp";
        const sightseen5ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-b2b-travel-packages.webp";
        const sightseen6ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-trip-from-mumbai-with-direct-flights.webp";
        const spring1ImageURL = ApiURL.BhutanB2BPackageImageURL + "/travel-bhutan-with-best-b2b-dmc-adorable-vacation.webp";
        const spring2ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-tour-from-mumbai-during-spring-with-adorable-vacation.webp";
        const spring3ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-tour-package-from-mumbai-during-spring-with-adorable-vacation.webp";
        const winter1ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-package-tour-from-mumbai-with-direct-flight-in-winter.webp";
        const winter2ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-tour-package-from-mumbai-with-direct-flight-in-winter.webp";
        const winter3ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-trip-from-mumbai-with-direct-flight-in-winter.webp";
        const summer1ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-tour-booking-from-mumbai-during-summer-with-adorable-vacation.webp";
        const summer2ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-tour-from-mumbai-during-summer-with-adorable-vacation.webp";
        const summer3ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-tour-package-from-mumbai-with-direct-flight-in-summer.webp";
        const flight1ImageURL = ApiURL.BhutanB2BPackageImageURL + "/mumbai-to-bhutan-tour-packages-with-direct-flight-with-adorable-vacation-1.webp";
        const flight2ImageURL = ApiURL.BhutanB2BPackageImageURL + "/mumbai-to-bhutan-tour-packages-with-direct-flight-with-adorable-vacation-2.webp";
        const hotel1ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-hotel-ugyen-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const hotel2ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-hotel-vara-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const hotel3ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-hotel-mandala-during-bhutan-package-tour-from-mumbai-with-direct-flight.webp";
        const CallButtonSoumalyaImageURL = ApiURL.BhutanTourPackageFromJaigaonImageURL + "/call-button-bablu-adorable-vacation.webp";
        const rows = [
            { date: '24th Sept - 30th Sept', availability: 'Available', price: '84,500' },
            { date: '01st Oct - 07th Oct', availability: 'Available', price: '84,500' },
            { date: '04th Oct - 10th Oct', availability: 'Available', price: '84,500' },
            { date: '08th Oct - 14th Oct', availability: 'Available', price: '84,500' },
            { date: '11th Oct - 17th Oct', availability: 'Available', price: '84,500' },
            { date: '15th Oct - 21st Oct', availability: 'Available', price: '84,500' },
            { date: '18th Oct - 24th Oct', availability: 'Available', price: '84,500' },
            { date: '22nd Oct - 28th Oct', availability: 'Available', price: '84,500' },
            { date: '25th Oct - 31st Oct', availability: 'Available', price: '84,500' },
            { date: '29th Oct - 04th Nov', availability: 'Available', price: '84,500' },
            { date: '1st Nov - 07th Nov', availability: 'Available', price: '84,500' },
            { date: '05th Nov - 11th Nov', availability: 'Available', price: '84,500' },
        ];

        const rowsPerPage = 6;
        const totalPages = Math.ceil(rows.length / rowsPerPage);
        const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
        const { customActiveTab, daywiseActiveTab } = this.state;

        const packageTopBannerImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-b2b-package-tour-from-adorable-vacation-the-best-bhutan-b2b-dmc.webp";
        const packageTopBanner2ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-b2b-tour-packages-from-adorable-vacation-the-best-bhutan-b2b-dmc-in-india.webp";
        const FlightBannerImageURL = ApiURL.BhutanB2BPackageImageURL + "/Bhutan-Group-Tour-Package-from-Mumbai.webp";
        const navDay1ImageURL = ApiURL.BhutanB2BPackageImageURL + "/mumbai-to-bhutan-tour-packages-with-direct-flights-with-adorable-vacation.webp";
        const navDay2ImageURL = ApiURL.BhutanB2BPackageImageURL + "/mumbai-to-bhutan-tour-packages-booking-with-direct-flights-with-adorable-vacation.webp";
        const navDay3ImageURL = ApiURL.BhutanB2BPackageImageURL + "/mumbai-to-bhutan-tour-package-cost-with-direct-flights-with-adorable-vacation.webp";
        const navDay4ImageURL = ApiURL.BhutanB2BPackageImageURL + "/mumbai-to-bhutan-tour-package-itinerary-with-direct-flights-with-adorable-vacation.webp";
        const navDay5ImageURL = ApiURL.BhutanB2BPackageImageURL + "/mumbai-to-bhutan-tour-package-plan-with-direct-flights-with-adorable-vacation.webp";
        const navDay6ImageURL = ApiURL.BhutanB2BPackageImageURL + "/mumbai-to-bhutan-tour-package-price-with-direct-flights-with-adorable-vacation.webp";
        const navDay7ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-3-nights-4-days-package.webp";
        const navDay8ImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-tour-3-days.webp";
        const ButtonImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-tour-packages-from-mumbai-with-chartered-flight-tickets-from-adorable-vacation.webp";
        const Button1ImageURL = ApiURL.BhutanB2BPackageImageURL + "/adorable-vacation-the-best-bhutan-b2b-dmc-in-india.webp";

        const hotPackagesDataList = this.props.HotPackagesData;
        const hotPackagesView = hotPackagesDataList.length > 0 ?
            (
                hotPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.FIFO_6N_7D_ImageURL + "/" + dataItem.ImageName;
                    const contactURL = dataItem.Url;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} | {dataItem.EXPARO}<span> &#8377; {dataItem.PricePerNight}</span> </h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue">{dataItem.SDFInclude}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href={contactURL} className="btn btn-Warning btn-sm float-end">Call Now</a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const themePackageSliderList = this.props.ThemePackagesData;
        const themePackagesDataView = themePackageSliderList.map(
            (sliderItem, i) => {
                const sliderImageURL =
                    ApiURL.BhutanB2BPackageImageURL +
                    "/" +
                    sliderItem.ImageName;
                return (
                    <div className="theme-cards-wrapper mx-2" key={i}>
                        <div className="theme-card">
                            <div className="image-wrapper">
                                <img src={sliderImageURL} alt={sliderItem.AllternateText} />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">{sliderItem.Title}</h5>
                                <p className="card-text">{sliderItem.Description}</p>
                                <a href="#" className="btn btn-success btn-sm" onClick={this.handleClick1}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                );
            }
        );

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.BhutanB2BPackageImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <div dangerouslySetInnerHTML={{ __html: dataItem.Comment }}></div>
                    </div>
                </div>
            )
        })


        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="bhutan b2b package tour from adorable vacation the best bhutan b2b dmc" />

                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container">
                                        <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center>We Offer <strong> Bhutan B2B Package Tour</strong> at Best Price </center></h1>
                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center>We Offer <strong> Bhutan B2B Package Tour</strong> at Best Price </center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="bhutan b2b tour packages from adorable vacation the best bhutan b2b dmc in india" />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Cities:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Thimphu
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Paro
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Punakha
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Bhutan tour from India</strong>, you have come to the Right Place! Adorable vacation is the best DMC for <strong>Bhutan B2B Package Tour</strong> from India.
                                        </p>
                                        <hr />
                                        <br />
                                        <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}> LOOKING FOR <strong>BHUTAN B2B TOUR PACKAGES</strong>?<br /><br /> FOR BEST DEAL |<a href='tel:9883359713' class="reviews"><strong> ☎ CALL MR. SOUMALYA +91-98833-59713</strong></a></h2>
                                        <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> LOOKING FOR <strong>BHUTAN B2B TOUR PACKAGES</strong>?<br /><br /> FOR BEST DEAL |<a href='tel:9883359713' class="reviews"><strong> ☎ CALL MR. SOUMALYA +91-98833-59713</strong></a></h3>
                                    </div>
                                </div>
                            </div>
                            <br /> <br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}> ADORABLE VACATION - THE <strong>BEST BHUTAN DMC IN INDIA</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "4.5vw" }}> ADORABLE VACATION - THE <strong>BEST BHUTAN DMC IN INDIA</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p>Do you dream of a <strong>Bhutan Tour from India</strong>? If so, you’ve come to the right place! If you’re feeling unsure about where to stay and want to avoid the stress of planning your travels, look no further. At Adorable Vacation - the <strong>Best DMC of Bhutan Tour</strong>, you’re about to discover one of the most meticulously curated travel experiences designed by a team of skilled <strong>Bhutan B2B Package Tour</strong> specialists.</p>
                                    <p>To ensure your <strong>Bhutan trip</strong> is truly unforgettable, Adorable Vacation is always here for you. We offer personalized and customized <strong>Bhutan B2B Packages</strong> tailored to your needs. Our mission is to enhance your life with exceptional travel experiences. Our dedicated team is committed to making your <strong>Bhutan Tour from India</strong> a resounding success.</p>
                                    <p>Adorable Vacation is renowned for <a href='' target='_blank'><strong>Bhutan B2B package Tour From Bagdogra Airport</strong></a>. We operate our <strong>Bhutan B2B Business</strong> through diverse business models, offering a range of innovative products and services both in India and globally. Our goal is to turn travel dreams into reality through <b>HIGH QUALITY SERVICES</b>, <b>RELIABILITY</b>, and <b>FLEXIBILITY</b>, all while ensuring the best rates and support.</p>
                                    <p>At Adorable Vacation, we prioritize <b>COMMITMENT</b>, <b>DEDICATION</b>, and <b>SATISFACTION</b>. We aim to enhance our reliability by accommodating your individual needs with innovative service options. Our <strong>Bhutan B2B packages</strong> are thoughtfully designed to provide you with easy, secure, and cost-effective <b>Bhutan B2B Tours</b> for businesses. We also offer a wide range of packages for both international and corporate clients.</p>
                                    <p>Whether you are a business professional heading on a <strong>Bhutan Business Trip</strong> or a traveller seeking new adventures, your goals will be met through our research-based consultancy. The concept of travel is constantly evolving, along with the needs and expectations of travellers.</p>
                                    <p><b>Adorable Vacation</b> - <strong>India's No 1 DMC for Bhutan Tour</strong> takes pride in its commitment and integrity. We provide clear and transparent information about our <strong>Bhutan B2B Services</strong> to prevent any misunderstandings.</p>
                                    <p>Furthermore, to ensure your needs are fully addressed, we collaborate with a network of well-managed suppliers and vendors dedicated to delivering results-driven services with precision. Our dynamic, experienced team of <strong>Bhutan B2B</strong> experts, equipped with first-hand knowledge of your desired destination, will guarantee that all your travel plans are flawlessly executed. We promise a well-organized journey that is completely hassle-free.</p>
                                    <p>Apart from <strong>Bhutan B2B package with Airfare</strong>, Adorable Vacation offers various <strong>Bhutan tour packages</strong> like <a href="/bhutan-tour-package-from-pune" target="_blank"><b className="tomato">Bhutan Package Tour from Pune</b></a>,<a href="/bhutan-tour-from-delhi" target="_blank"><b className="tomato"> Bhutan Package Tour from Delhi</b></a>, <a href="/bhutan-tour-package-from-mumbai" target="_blank"><b className="tomato">Bhutan package tour from Mumbai</b></a>, <a href="/bhutan-tour-package-from-ahemdabad" target="_blank"><b className="tomato">Bhutan Package Tour from Ahmedabad</b></a>, <a href="/bhutan-tour-from-bengaluru" target="_blank"><b className="tomato">Bhutan Package Tour from Bangalore</b></a>, <a href="/bhutan-tour-package-from-jaigaon" target="_blank"><b className="tomato"> Bhutan Package Tour from Jaigaon</b></a>, <a href="/bhutan-tour-package-from-kolkata" target="_blank"><b className="tomato">Bhutan Package Tour from Kolkata</b></a>, <a href="/bhutan-tour-package-from-bagdogra" target="_blank"><b className="tomato">Bhutan Package Tour from Bagdogra Airport</b></a>, <a href="/bhutan-tour-package-from-surat" target="_blank"><b className="tomato">Bhutan Package Tour from Surat</b></a>, <a href="/mumbai-special-chartered-flight" target="_blank"><b className="tomato">Bhutan Package Tour from Mumbai with Chartered Flight</b></a>, etc at Best Price.</p>
                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell" style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={sightseen1ImageURL}
                                                        alt="adorable vacation - bhutan tour operator for b2b"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen2ImageURL}
                                                        alt="b2b travel agen for bhutan tour from india"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen3ImageURL}
                                                        alt="b2b travel agents for bhutan tour package booking"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /> <br />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={CallButtonSoumalyaImageURL} alt="Perfect Bhutan Tour Package From Guwahati for Tour Operators" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /> <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> ADORABLE VACATION - INDIA'S NO 1 TRAVEL DMC FOR BHUTAN TOUR </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> ADORABLE VACATION - INDIA'S NO 1 TRAVEL DMC FOR BHUTAN TOUR </h3>
                                </div>
                                <div className="card-body">
                                    <h3>DURATION FOR <strong>BHUTAN B2B PACKAGE TOUR</strong> : 7N | 8D <br /><br /></h3>
                                    <p>Destinations Covered in <strong>Bhutan B2B Tour Package from Bagdogra Airport</strong> : Phuentsholing 1N | Thimphu 2N | Punakha 1N | Paro 2N | Lataguri 1N <br /></p>
                                    <p>Pickup & Drop Point for <strong>Bhutan B2B Package</strong> : Bagdogra International Airport [IXB] <br /><br /></p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WE ARE THE BEST DMC OF <strong>BHUTAN B2B PACKAGE</strong> </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> WE ARE THE BEST DMC OF <strong>BHUTAN B2B PACKAGE</strong></h3>
                                </div>
                                <div className="card-body">
                                    <p>Adorable vacation offers the best <strong>Bhutan B2B Tour Package</strong> as a pioneer of <strong>Bhutan DMC</strong>. We have good hotel rates, very good control over car vendors, TCB approved <b>[Licence No : 1053228]</b> English speaking well behaved tour guides and drivers to cater the best service to all of our <strong>B2B Partners</strong>. Our <strong>B2B Travel Agents</strong> can go through a fixed itinerary or a <strong>Customized Bhutan Tour Plan</strong> according their choice. With own office in Babesa, Thimphu, Bhutan and direct payroll employees, our best Bhutan Operation Team will help you to understand more about our services and connect with us for more best <strong>Bhutan B2B Deals</strong> easily. <br /><br /></p>
                                    <h3>OUR MISSION</h3>
                                    <p>Adorable Vacation always dedicates to provide all kinds of <strong>Bhutan B2B Travel Package</strong> needs to our guests. We also believe in providing of quality services. We aim to offer a value added service at affordable prices. Trust is the most important thing that we want to build across the world along with our visitors. <br /><br /></p>
                                    <h3>OUR VISION</h3>
                                    <p>We would like to establish our self as the best <strong>Bhutan B2B Tour Operator</strong> among the travellers in India and around the world. We believe to achieve our goals and reach on the next level in every prospect. Your support will help us to become a genuine <strong>Bhutan Tour Operator</strong> in India and a trustable travel partner across our service sector. <br /><br /></p>

                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DIFFERENT TYPES OF <strong>BHUTAN B2B PACKAGES</strong> WE OFFER </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> DIFFERENT TYPES OF <strong>BHUTAN B2B PACKAGES</strong> WE OFFER </h3>
                                </div>
                                <div className="card-body">
                                    <p>The B2B Packages mentioned here are for Indian Travel Agents looking for <strong>Best Bhutan B2B Deals</ strong >. Adorable Vacation is best <strong>DMC of Bhutan</ strong> and deals with Indian Tourists as well as foreign travellers from USA, UK, Australia, Dubai, Singapore, Malayasia, Thailand, Korea, China, Japan and Sri Lanka. <br /><br /></p>
                                    <p>Adorable Vacation conducts two types of tours in this <strong>Bhutan B2B Business Model</strong>. One is <b>FIFO [Fly-in fly-out] option</b>, where guests will arrive and depart from the <b>Paro Airport [PBH] </b> - the sole International Airport in Bhutan. There are two airlines in Bhutan, <b>Drukair</b> and <b>Bhutan Airlines</b> which operates direct flights from Paro Airport to Delhi, Kolkata, Guwahati and Bagdogra. <br /><br /></p>
                                    <p>The other option is <b>DIDO [Drive-in-Drive-Out]</b> mode. In this model <strong>Bhutan Tour</strong> happens through the Indo-Bhutan boarder town at Phuentsholing. Basically Phuentsholing is a bustling and modern city at the Himalayan foothill. It is a sprawling town with modern architecture, shops, shopping malls, medical facilities, in short it is a vast commercial hub for business between two countries - India & Bhutan. Apparently Phuentsholing is more a commercial town and one of the prime gateway of Bhutan other than <b>Samtse, Gelephu and Samdrupjonkhar</b>. <br /><br /></p>
                                    <p>Now with new Tourism Policy of Bhutan, travelling this beautiful country is now more comfortable from Phuentsholing. Paro Airport has limited number of flights connection to a few Indian cities and a few days in a week. More over during peak season for <a href="/bhutan-tour-package-from-mumbai" target="_blank"><strong>Bhutan package tour from Mumbai</strong></a> often flight tickets get sold out months is advance. This is the root cause that <a href="/bhutan-tour-package-from-bagdogra" target="_blank"><strong>Bhutan package tour from Bagdogra Airport</strong></a> is more lucrative and it is cost effective too. Adorable Vacation conducts <strong>Bhutan B2B Tour Packages via Bagdogra Airport</strong> with 100% guests satisfaction. <br /><br /></p>

                                    <p>Apart from <b>FIFO</b> and <b>DIDO</b>, there are <b>DIFO [Drive-In-Fly-Out]</b> - entering Bhutan from Phuentsholing and depart from Paro Airport and <b>FIDO [Fly-In-Drive-Out]</b> - entering Bhutan from Paro Airport and depart journey by road via Phuentsholing. This option also <strong>Bhutan B2B Tour Operator</strong> can avail from Adorable Vacation Ltd - The <strong>Best Bhutan B2B Travel Agency in India</strong>. <br /><br /></p>


                                    <p>In Bhutan the most beautiful and attractive destinations are Thimphu and Paro. Being the capital city, Thimphu is the main hub for tourism. Lots of deluxe and luxury hotels surrounds this capital city. There are many attractions in Thimphu [clock tower, Trashi Chho Dzong, Changangkha Lhakhang, National Memorial Chorten, National Library, Clock Tower Square, Simtokha Dzong, Buddha Dordenma Statue etc] that you can cover during <strong>Bhutan B2B tour Package</strong>. <br /><br /></p>

                                    <p>Paro - the land of beautiful valleys and world famous Taktsang Monasteries are the must visit place during any <strong>Bhutan B2B Travel Packages</strong>. Like this Punakha is also one of the place of attractions. Most <strong>Bhutan Tour Packages in the B2B Travel market</strong>, circled round on these three destinations as these destinations have very good accommodation options in different categories. You can opt from 3 Star Hotel categories to <b>Premium 5 Star Hotels in Bhutan</b> like <b>Six Senses</b>, <b>COMO Uma</b>, <b>Aman Kora</b>, <b>Taj Tashi</b>, <b>Le Meredian</b> etc. Adorable Vacation has all sort of solutions as <strong>Bhutan B2B Tour Operator</strong>.<br /><br /></p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DAY WISE SUMMERY FOR 7N|8D <strong>BHUTAN B2B TOUR PACKAGE</strong> FROM BAGDOGRA AIRPORT</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DAY WISE SUMMERY FOR 7N|8D <strong>BHUTAN B2B TOUR PACKAGE</strong> FROM BAGDOGRA AIRPORT</h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingOne"
                                                    >
                                                        <b>DAY 1 → </b>
                                                        <span>
                                                            BAGDOGRA ARRIVAL, TRANSFER TO PHUENTSHOLING
                                                        </span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingTwo"
                                                    >
                                                        <b>DAY 2 → </b>
                                                        <span>PHUENTSHOLING TO THIMPHU</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingThree"
                                                    >
                                                        <b>DAY 3 → </b>
                                                        <span>THIMPHU LOCAL SIGHTSEEING</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingFour"
                                                    >
                                                        <b>DAY 4 → </b>
                                                        <span>THIMPHU TO PUNAKHA TRANSFER VIA DOCHULA PASS</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingFive"
                                                    >
                                                        <b>DAY 5 → </b>
                                                        <span>PUNAKHA / WANGDUE TO PARO</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingSix"
                                                    >
                                                        <b>DAY 6 → </b>
                                                        <span>PARO LOCAL SIGHTSEEING & TAKTSHANG MONASTERY HIKING</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingSeven"
                                                    >
                                                        <b>DAY 7 → </b>
                                                        <span>PARO TO SILIGURI VIA PHUENTSHOLING </span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className="clickable"
                                                        data-target="panelsStayOpen-headingTen"
                                                    >
                                                        <b>DAY 8 → </b>
                                                        <span>SILIGURI TO BAGDOGRA TRANSFER</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br /><br />
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="image-cell">
                                                        <a href='tel:9883359713'><img src={ButtonImageURL} alt="Perfect Bhutan B2B packages for Tour Operators" /></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br /><br />
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="image-cell">
                                            <a href='#' onClick={this.onRequestCallback}><img src={Button1ImageURL} alt="adorable vacation - the best bhutan b2b dmc in india" className="image-padding" /></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br />
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>Destinations Covered in <span style={{ color: 'tomato' }}>Bhutan B2B Package Tour</span></h3>
                                            <h4>Phuentsholing 1N | Thimpu 2N | Punakha 1N | Paro 2N | Siliguri 1N</h4>
                                        </div>
                                    </div>
                                </div>
                                <br /><br />
                                <table style={{ width: '100%', textAlign: 'center' }}>
                                    <tbody>
                                        <tr>
                                            <td className="image-cell" style={{ textAlign: 'center' }}>
                                                <img
                                                    src={sightseen4ImageURL}
                                                    alt="bhutan b2b group tour from bagdogra with adorable vacation"
                                                    style={{ margin: '10px' }}
                                                />
                                                <img
                                                    src={sightseen5ImageURL}
                                                    alt="bhutan b2b travel packages"
                                                    style={{ margin: '10px' }}
                                                />
                                                <img
                                                    src={sightseen6ImageURL}
                                                    alt="travel bhutan with best b2b dmc - adorable vacation"
                                                    style={{ margin: '10px' }}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br /><br /><br />

                                <h3 className='site-heading text-center'><b >Wonderful <span className='tomato'>Bhutan Tour Review from Mumbai</span> from our esteemed Guests Mr. & Mrs. Dalal</b><br /></h3>
                                <p className='p-2'><center>We are thrilled to share a heartfelt review for <strong>Bhutan tour from Mumbai</strong> from our valued guests, Mr. & Mrs. Dalal. Their experience highlights the exceptional service and memorable moments they encountered during their <a href='/mumbai-special-chartered-flight' target='_blank'><strong>Bhutan tour from Mumbai with Direct Flight</strong></a>. We take great pride in ensuring that every guest has a delightful and fulfilling journey, and it's always rewarding to hear such positive feedback.</center></p><br /><br />
                                <div class="video-container">
                                    <iframe
                                        src="https://www.youtube.com/embed/19drRVnSMCs?si=Uj5Fm4uSuwPi0SBM"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen>
                                    </iframe>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >

                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> BEST PLACES TO EXPLORE DURING <strong>BHUTAN B2B PACKAGE TOUR</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> BEST PLACES TO EXPLORE DURING <strong>BHUTAN B2B PACKAGE TOUR</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf"><tr><th>PLACES</th><th>ATTRACTIONS</th></tr></thead>
                                            <tbody><tr><td>Thimphu</td><td>Tashichho Dzong, Simply Bhutan Museum, Buddha Dordenma Statue, National Memorial Chorten, Motithang Takin Preserve, Changangkha Lhakhang, Royal Textile Academy of Bhutan, Centenary Farmers' Market, National Folk Heritage Museum, Simtokha Dzong, Pangri Zampa Monastery</td></tr>
                                                <tr><td>Paro</td><td>Taktsang (Tiger's Nest) Monastery, Paro Dzong (Rinpung Dzong), National Museum of Bhutan, Drukgyel Dzong, Kyichu Lhakhang, Jangsarbu Lhakhang, Dungtse Lhakhang, Chele La Pass, Paro Weekend Market</td></tr>
                                                <tr><td>Punakha</td><td>Punakha Dzong, Chimi Lhakhang (The Fertility Temple), Khamsum Yulley Namgyal Chorten, Sangchhen Dorji Lhuendrup Lhakhang Nunnery, Mo Chhu River Rafting</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-bagdogra" target='_blank' className="btn btn-Warning btn-lg">
                                            <b>Bhutan Package Tour From Bagdogra Airport</b>
                                        </a>
                                    </div>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-pune" target='_blank' className="btn btn-Warning btn-lg w-100">
                                            <b>Bhutan Package Tour Booking From Pune</b>
                                        </a>
                                    </div>
                                    <div className='col-md-4 col-12 mb-3'>
                                        <a href="/bhutan-tour-package-from-kolkata" target='_blank' className="btn btn-Warning btn-lg w-100">
                                            <b>Bhutan Package Tour From Kolkata Airport</b>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> HOW TO REACH BHUTAN FROM INDIA?</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> HOW TO REACH BHUTAN FROM INDIA?</h3>
                                </div>
                                <div className="card-body">
                                    <p><b>Bhutan</b> is a land locked country. From north to south and from east to west it is surrounded by China, and India. Tucked in the lap of Himalayas High peaked mountain ranges, it was untouched from rest of the world till 1974. Bhutan opened its door for tourism in 1974 with an aim to increase foreign currency and to showcase its age old preserved culture and traditions to rest of the world. From then on people are travelling this beautiful country. On 1974 tourism in Bhutan was started with only 287 tourists, whereas in 2019 the no. of tourists increased to 3, 16,000.<br /><br />

                                        Now, the best way to do a <strong>Bhutan Tour</strong> is by Air. Paro in the sole international airport in Bhutan. Bhutan has two major airlines - <b>Druk Air, IATA Code 'KB'</b> and <b>Bhutan Airlines, IATA Code 'B3'</b>. Both airlines operate flights from different major cities like Delhi, Kolkata, uwahati, Bangkok, Singapore, Kahmandu and Dhaka.<br /><br />

                                        Bhutan Airlines and Druk Air operate flights from Kolkata Airport [CCU], Delhi Airport [DEL] and Guwahati Airport [GAU] to the Paro Airport [PBH] on regular basis. Following is the flight schedule as published by them. <br /><br /><br /></p>

                                    <h4 className="tomato1">OPTION 1 : BHUTAN PACKAGE TOUR FROM KOLKATA WITH DIRECT FLIGHT <br /></h4>
                                    <p><b>Bhutan Airlines</b> operates direct flights from Kolkata Airport [CCU] to the Paro Airport [PBH] on regular basis. Following is the flight schedule as published by them.
                                    </p>
                                    <br /><br />
                                    <h4>FLIGHTS TO CHOOSE FROM KOLKATA TO PARO AIRPORT <br /><br /></h4>
                                    <div id="no-more-tables">

                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr><th>SECTOR</th><th>AIRLINES</th><th>FREQUENCY</th><th>DEP TIME</th><th>ARR TIME</th></tr></thead>
                                            <tbody>
                                                <tr><td>CCU - PBH</td><td>Bhutan Airlines 701</td><td>Daily</td><td>8:15 am</td><td>9:55 am</td></tr>
                                                <tr><td>PBH - CCU</td><td>Bhutan Airlines 700</td><td>Daily</td><td>10:35 am</td><td>11:15 am</td></tr>
                                                <tr><td>CCU - PBH</td><td>Druk Air 211</td><td>Mon - Wed - Sat</td><td>1:20 pm</td><td>3:20 pm</td></tr>
                                                <tr><td>PBH - CCU</td><td>Druk Air 210</td><td>Mon - Wed - Sat</td><td>11:30 am</td><td>12:20 pm</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                    <div className="content-desktop">
                                        <center><img src={flight1ImageURL} alt="Bhutan B2B Package from Bhutan DMC" /></center>
                                    </div>

                                    <div className="content-mobile2">
                                        <center><img src={flight1ImageURL} style={{ width: '320px' }} alt="B2B Travel Agents" /></center>
                                    </div>
                                    <br /><br /><br />
                                    <h4 className="tomato1">OPTION 2: BHUTAN PACKAGE TOUR FROM DELHI WITH DIRECT FLIGHT <br /></h4>
                                    <p><b>Bhutan Airlines</b> also operates direct flights from Delhi Airport [DEL] to the Paro Airport [PBH] of Bhutan on regular basis. Following is the flight schedule as published by them. <br /><br /><br /></p>

                                    <div className="content-desktop">
                                        <center><img src={flight2ImageURL} alt="Bhutan B2B Group Tour" /></center>
                                    </div>

                                    <div className="content-mobile2">
                                        <center><img src={flight2ImageURL} style={{ width: '320px' }} alt="Bhutan B2B Packages from India" /></center>
                                    </div>
                                    <br /><br /><br />
                                    <h4 style={{ color: "tomato" }}>FLIGHTS TO CHOOSE FOR <strong>DELHI TO PARO AIRPORT</strong><br /><br /></h4>
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr><th>SECTOR</th><th>AIRLINES</th><th>FREQUENCY</th><th>DEP TIME</th><th>ARR TIME</th></tr></thead>
                                            <tbody>
                                                <tr><td>DEL - PBH</td><td>Druk Air 201</td><td>Every Sun, Tue, Wed, Fri, Sat</td><td>12:30 pm</td><td>3:20 pm</td></tr>
                                                <tr><td>PBH - DEL</td><td>Druk Air 200</td><td>Every Sun, Tue, Wed, Fri, Sat</td><td>9:40 am</td><td>11:30 am</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br /><br />
                                    <h4 className="tomato1">OPTION 3 : DIRECT FLIGHT TO PARO [PBH] FROM GUWAHATI AIRPORT [GAU] <br /></h4>
                                    <p><strong>Druk Air</strong> operates direct flights from Guwahati Airport [GAU] of Assam to the Paro Airport [PBH] of Bhutan on regular basis. Following is the flight schedule as published by them. <br /><br /><br /></p>
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr><th>SECTOR</th><th>AIRLINES</th><th>FREQUENCY</th><th>DEP TIME</th><th>ARR TIME</th></tr></thead>
                                            <tbody>
                                                <tr><td>GAU - PBH</td><td>Druk Air 541</td><td>Every Sun and Thu</td><td>3:20 pm</td><td>4:45 pm</td></tr>
                                                <tr><td>PBH - GAU</td><td>Druk Air 540</td><td>Every Wed and Sat</td><td>7:50 am</td><td>8:15 am</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br /><br />
                                    <h4 className="tomato1">OPTION 4 : REACH BHUTAN BY ROAD FROM BAGDOGRA AIRPORT [IXB] </h4>
                                    <p>Bagdogra is located in about 16 km (9.9 mi) west of the city of Siliguri in the Darjeeling district in northern West Bengal. Bagdogra Airport is well connected with all the cities of India including Mumbai, Delhi, Bangalore, Chennai, Kolkata. You can enter the last Buddhist Kingdom foothill, the Phuentsholing town which is 170 KM (4 hrs approx) only from Bagdogra Airport. Guests from Mumbai / Delhi / Bangalore can easily fly to Bagdogra Airport from where Adorable Vacation conduct <a href="/bhutan-tour-package-from-bagdogra" target='_blank'><strong>Bhutan package tour from Bagdogra Airport</strong></a>.<br /><br /><br /></p>

                                    <h4>FLIGHTS TO CHOOSE FROM DIFFERENT CITIES FOR BOOKING <strong>BHUTAN PACKAGE TOUR FROM BAGDOGRA AIRPORT VIA PHUENTSHOLING</strong> <br /><br /></h4>
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf"><tr><td><b>Airlines</b></td><td><b>Origin</b></td><td><b>Departure</b></td><td><b>Destination</b></td><td><b>Arrival</b></td><td><b>Duration</b></td></tr></thead>
                                            <tbody><tr><td>IndiGo</td><td>Mumbai [BOM]</td><td>08:10</td><td>Bagdogra [IXB]</td><td>10:55</td><td>02 h 45 m (non-stop)</td></tr>
                                                <tr><td>SpiceJet</td><td>Mumbai [BOM]</td><td>08:25</td><td>Bagdogra [IXB]</td><td>11:10</td><td>02 h 45 m (non-stop)</td></tr>
                                                <tr><td>IndiGo</td><td>Hyderabad [HYD]</td><td>10:15</td><td>Bagdogra [IXB]</td><td>12:40</td><td>02 h 25 m (non-stop)</td></tr>
                                                <tr><td>IndiGo</td><td>Chennai [MAA]</td><td>11:45</td><td>Bagdogra [IXB]</td><td>14:30</td><td>02 h 45 m (non-stop)</td></tr>
                                                <tr><td>AirAsia</td><td>Bangalore [BLR]</td><td>05:20</td><td>Bagdogra [IXB]</td><td>08:20</td><td>03 h 00 m (non-stop)</td></tr>
                                                <tr><td>SpiceJet</td><td>Bangalore [BLR]</td><td>06:05</td><td>Bagdogra [IXB]</td><td>09:05</td><td>03 h 00 m (non-stop)</td></tr>
                                                <tr><td>IndiGo</td><td>Bangalore [BLR]</td><td>06:25</td><td>Bagdogra [IXB]</td><td>09:15</td><td>02 h 50 m (non-stop)</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br /><br />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={ButtonImageURL} alt="Travel with the Best DMC Bhutan" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHY CHOOSE ADORABLE VACATION FOR <strong>BHUTAN B2B TOUR PACKAGE</strong>?</h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY CHOOSE ADORABLE VACATION FOR <strong>BHUTAN B2B TOUR PACKAGE</strong>?</h3>
                                </div>
                                <div className="card-body">
                                    <li><b>Direct Non-Stop Flights</b>: Enjoy direct, non-stop flights from Mumbai to Paro during Bhutan peak season.</li><br />
                                    <li><b>Trusted Brand</b>: Adorable Vacation has been the most trusted brand for <strong>Bhutan B2B Tour Package</strong> since 2015.</li><br />
                                    <li><b>Passenger Service Agent (PSA)</b>: We are proud to be the PSA for Bhutan Airlines and Druk Airlines.</li><br />
                                    <li><b>Luxury Packages</b>: Experience the best of Bhutan with our packages featuring 5-star luxury hotels, including Six Senses, Amankora, COMO Uma, Le Méridien, Le Méridien Paro, Riverfront, and Pemako.</li><br />
                                    <li><b>Super Peak Season Inventory</b>: We have pre-purchased hotel room inventory for peak season tours.</li><br />
                                    <li><b>Authorized Travel House</b>: We are an authorized travel house by the Tourism Council of Bhutan [<span style={{ color: 'tomato' }}><b>Licence No: 1053228</b></span>].</li><br />
                                    <li><b>Direct Employment</b>: Our employees in Bhutan are directly on our payroll, ensuring quality service.</li><br />
                                    <li><b>Customized Itineraries</b>: We offer tailored <strong>Bhutan B2B Travel Package</strong> with unique and signature programs.</li><br />
                                    <li><b>In-Depth Knowledge</b>: Our team possesses extensive knowledge about Bhutan's territory, hotels, and local laws.</li><br />
                                    <li><b>Assured Services</b>: We ensure premium services to enrich your experience while in Bhutan.</li><br />
                                    <li><b>E-Permit Processing</b>: We handle the e-permit process in advance for all your sightseeing needs.</li><br />
                                    <li><b>Personalized Attention</b>: Enjoy safe and secure personal attention throughout your stay in Bhutan.</li><br />
                                    <li><b>Complete Responsibility</b>: We takes full responsibility for your entire <strong>Bhutan B2B Package</strong>.</li><br />
                                    <li><b>Hassle-Free Experience</b>: We guarantee hassle-free and trustworthy services in Bhutan.</li><br />
                                    <li><b>Best Deals</b>: Access the best deals for top properties and services based on your preferences.</li><br />
                                    <li><b>Flexible Travel Options</b>: We offer <strong>Bhutan private trip</strong> or <strong>Bhutan group tour</strong> with Best Price.</li><br />
                                    <br /><br />
                                    <table style={{ width: '100%', textAlign: 'center' }}>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img
                                                        src={sightseen1ImageURL}
                                                        alt="bhutan b2b group tour from bagdogra with adorable"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen3ImageURL}
                                                        alt="bhutan b2b travel packages"
                                                        style={{ margin: '10px' }}
                                                    />
                                                    <img
                                                        src={sightseen5ImageURL}
                                                        alt="travel bhutan with best b2b dmc adorable vacation"
                                                        style={{ margin: '10px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /><br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT ARE THE ENTRY FORMALITIES IN BHUTAN? </h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT ARE THE ENTRY FORMALITIES IN BHUTAN? </h3>
                                </div>
                                <div className="card-body">
                                    <p>To enter Bhutan from Phuentsholing, guests must have to acquire Bhutan Entry Permit. Now, permit can be obtained in two ways - Online Permit & Offline Permit. <br /><br /></p>

                                    <h4 className='tomato1'>FOR FIFO [FLY IN FLY OUT] TOURIST [PARO AIRPORT - PBH] <br /></h4>
                                    <p>Bhutan entry permit are done online and manual permit on arrival at Paro Airport. For this travelers need to produce following documents. <br /></p>

                                    <ul><li>A valid passport (at least 6 months validity or more), or Voter ID card issued by the Election Commission of India <br /><br /></li>
                                        <li>Two recent Passport size photograph of the individual traveller with white background <br /><br /></li>
                                        <li>Travel Insurance with sign & seal of the insurance company <br /><br /></li>
                                        <li>SDF (Sustainable Development Fee) amount in INR for regional tourist <br /><br /></li>
                                        <li>A licensed <strong>Bhutan Tour Guide</strong> is mandatory <br /><br /></li></ul>

                                    <p>Guests for <b>Bhutan Tour</b> with Adorable Vacation need not to worry about permit as the company has expert to attain Bhutan Permit online. <br /><br /><br /></p>

                                    <h4 className='tomato1'>DIDO [DRIVE IN DRIVE OUT] TRAVELERS - BY ROAD VIA PHUENTSHOLING <br /></h4>
                                    <p>As per current rule of Bhutan, tourists cannot enter Bhutan without permit. At Phuentsholing immigration terminal, travelers need to complete offline permit formalities on arrival at the immigration counter to enter Bhutan. However, tourists with desires of doing some trekking or going beyond some places need to acquire a 'special area permit' from the Immigration Office, Royal Govt. of Bhutan located at Thimphu on any working days (Monday to Friday - 9am to 4pm). <br /><br />
                                        Following are the documents require to process for Offline Permit:</p>

                                    <ul><li>Passport having validity of more than 6 months / Voter Card, issued by the Election Commission of India <br /><br /></li>
                                        <li>Hotel Confirmation voucher <br /><br /></li>
                                        <li>Guide is mandatory to process the offline permit <br /><br /></li>
                                        <li>SDF amount in cash (INR) <br /><br /></li></ul>

                                    <p>The Phuentsholing border gate and immigration office are now open 7 days a week from 9 am to 7.30 pm. <br /><br /><br /></p>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="row py-5">
                                <div className="col-md-12">
                                    <div className="site-heading text-center">
                                        <h3>
                                            DAY WISE ITINERARY FOR <span><strong>BHUTAN B2B TOUR PACKAGE</strong></span>
                                        </h3>
                                        <h4>7N/8D <strong>Bhutan B2B Package Tour Itinerary</strong> from Adorable Vacation</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div
                                    className="accordion"
                                    id="accordionPanelsStayOpenExample"
                                >
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day1"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseOne"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseOne"
                                            >
                                                <h4> DAY 01: ARRIVAL AT BAGDOGRA, TRANSFER TO PHUENTSHOLING </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseOne"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingOne"
                                        >
                                            <div className="accordion-body">
                                                <p>Upon arrival at Bagdogra Airport (IXB) for your <strong>Bhutan B2B Package Tour</strong>, you will be warmly greeted and then drive towards the Indo-Bhutanese border at Jaigaon / Phuentsholing. Enjoy the beautiful scenery of the North Bengal Tea Estates along the way. If your permit is ready, you can enter the border town of Phuentsholing for an overnight stay. If your permit is not yet complete, you will stay overnight at a hotel in Jaigaon. Adorable Vacation offers direct <a href="https://adorablevacation.com/bhutan-package-tour-from-phuentsholing/" target="_blank"><strong>Bhutan package tour from Phuentsholing</strong></a> & <a href="https://adorablevacation.com/bhutan-tour-package-from-jaigaon/" target="_blank"><strong>Bhutan package tour from Jaigaon</strong></a> also. The evening is yours to relax and enjoy at your leisure. <br /><br /></p>
                                                <div className="content-desktop">
                                                    <center><img src={navDay1ImageURL} alt="bhutan B2B tour packages with direct flights" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="bhutan B2B tour packages with direct flights" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day2"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseTwo"
                                            >
                                                <h4>DAY 02: PROCESS THE PERMIT AND TRANSFER TO THIMPU</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseTwo"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingTwo"
                                        >
                                            <div className="accordion-body">
                                                <p>After an early morning breakfast, begin the process of obtaining your offline permit. Our team will assist you in navigating the necessary permit procedures, including biometric registration. Typically, the offline permit processing can take anywhere from 2 to 6 hours, depending on the season and the volume of visitors at the immigration office in Phuentsholing. While waiting, you can enjoy your lunch. Once your permit is ready, you will set off for Thimphu, the capital city of Bhutan. The drive from Phuentsholing to Thimphu takes about 4.5 hours by car. <br /></p>

                                                <p>*** If you have already completed your online permits, head to the Bhutan Gate to finalize the biometric process. This step should take no more than 1 hour. After securing your online permit, you can easily proceed to Thimphu City.<br /></p>
                                                <p>Upon arriving in Thimphu, check in at your hotel in the afternoon or evening. Freshen up and take a leisurely stroll around Thimphu. Discover the vibrant nightlife, enjoy local beer, try karaoke, or relax with a traditional hot stone bath at a spa. After a fulfilling day, have dinner and settle in for the night in Thimphu. <br /></p>
                                                <div className="content-desktop">
                                                    <center><img src={navDay2ImageURL} alt="mumbai to bhutan B2B tour packages booking with direct flights" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="mumbai to bhutan B2B tour packages booking with direct flights" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day3"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseThree"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 03: FULL DAY THIMPHU SIGHTSEEING TOUR</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseThree"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingThree"
                                        >
                                            <div className="accordion-body">
                                                <p>As the sun casts its warm rays across the stunning Thimphu valley, your day begins with a hot cup of Suja (Butter Tea). After enjoying this delightful beverage, head to the breakfast table to savor a delicious spread before setting out for local sightseeing in Thimphu. <br /><br /></p>

                                                <p><b>The National Memorial Chorten</b> - Also known simply as Thimphu Chorten, this site serves as a gathering place for evening prayers. Built in memory of the third King, it is continuously circled by people murmuring mantras and spinning their prayer wheels. The idea for this landmark was proposed by His Majesty Jigme Dorji Wangchuk, the third King of Bhutan, who envisioned a monument symbolizing peace and prosperity for the world. Completed in 1974 after the King's untimely death, it stands as both a memorial to the late King and a beacon of peace.<br /><br /></p>


                                                <p><b>The Clock Tower</b> - Situated in the heart of Thimphu Town, the Clock Tower Square is an architectural marvel you won’t want to miss during your <strong>Bhutan B2B Packages from India</strong>. This tower is distinct from other structures in Bhutan, featuring four clock faces pointing in different directions. The design reflects traditional Bhutanese architecture, with intricate carvings and paintings of floral patterns and dragons. These dragons, symbolizing independence in Bhutanese culture, face each clock, making the tower particularly captivating.<br /><br />

                                                    This location is a popular spot for both locals and travelers, especially in the evening when visitors come to admire the exquisite carvings and vibrant atmosphere. The area around the Clock Tower is bustling with restaurants, gift shops, and hotels, often hosting trade fairs, concerts, and live music events. It's a lively area, perfect for shopping for unique gifts and enjoying a fun picnic at the nearby playground.<br /><br /></p>


                                                <p><b>Sakyamuni Buddha</b> - Atop Kuensel Phodrang hill stands the Great Buddha Dordenma, a majestic 51.5-meter gilded bronze statue that is sure to captivate you. The site, known as Buddha Viewpoint, offers breathtaking views of the valley below.<br /><br /></p>


                                                <p><b>Changangkha Lhakhang</b> - In Thimphu, you will find many monasteries and temples, but Changangkha Lhakhang is the most significant religious structure. Dedicated to Avalokiteshvara, the embodiment of compassion, this 15th-century temple is the oldest in the region and provides stunning views of the Thimphu valley.<br /><br /></p>


                                                <p><b>Takin Zoo</b> - Here, you can observe the national animal of Bhutan, the Takin. This unique species is one of the most endangered animals in the world and is found exclusively in Bhutan, preserved in an open habitat.<br /><br /></p>


                                                <p><b>Trashi Chhoe Dzong</b> - Known as the Fortress of the Glorious Region, this is another must-visit site on your <strong>Bhutan B2B Group Tour</strong>. Located on the west bank of the Wang Chhu River, Trashi Chhoe Dzong is the official coronation site of the fifth King and hosts the annual <strong>Tsechu festival</strong>. Built in 1641 by the revered Shabdrung Ngawang Namgyal, this Dzong was reconstructed in the 1960s using traditional Bhutanese techniques, without nails or architectural blueprints. Overnight at a hotel in Thimphu. <br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay3ImageURL} alt="Perfect Bhutan B2B packages for Tour Operators" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Perfect Bhutan B2B packages for Tour Operators" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day4"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFour"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 04: - THIMPHU TO PUNAKHA TRANSFER </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseFour"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFour"
                                        >
                                            <div className="accordion-body">
                                                <p>As your Hot Ngaja (a sweet Bhutanese milk tea) arrives, take a sip while enjoying the stunning view of the valley from your room. After breakfast, head towards Punakha, the former capital of Bhutan. Punakha is a peaceful escape from the chaos of city life, situated at an elevation of 1,200 meters, which gives it a warm climate in winter and a cool breeze in summer. The valley is renowned for its Red and White Rice farming along the banks of the Pho and Mo Chu rivers. En route, you will visit several attractions. <br /><br /></p>

                                                <p><b>Punakha Dzong :</b> Also known as "<b>The Palace of Great Happiness</b>," Punakha Dzong is beautifully located at the confluence of the Pho Chhu (father) and Mo Chhu (mother) rivers in the Punakha–Wangdue valley. This Dzong is considered the most picturesque of all Bhutan's ancient fortresses and is connected to the mainland by an arched wooden bridge. It houses many precious relics from the time when the successive kings ruled the kingdom from this tranquil valley. <br /><br /></p>

                                                <p><b>Suspension Bridge :</b> This hidden gem in the Punakha Valley connects the rest of the valley to the old administrative center of Punakha Dzongkhag. The entire deck is adorned with colorful prayer flags, making it a perfect spot for birdwatching and photography. <br /><br /></p>

                                                <p><b>Chimi Lhakhang (Fertility Temple) :</b> Perched atop a hillock, Chimi Lhakhang is dedicated to Lama Drukpa Kuenley, known as the 'Divine Madman.' He is famous for using songs, humor, and outrageous behavior to convey his teachings. This temple is believed to bring fertility, and many childless couples visit with the hope of being blessed by Lama Drukpa Kuenley if they pray for a child. The path to the temple follows a picturesque rural trail through lush paddy fields, leading visitors to a small stream, from where it's just a short climb to Chimi Lhakhang. Overnight stay at a hotel in Punakha. <br /><br /><br /></p>
                                                <br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay4ImageURL} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day5"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFive"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 05: PUNAKHA TO PARO TRANSFER & LOCAL SIGHTSEEING </h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseFive"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingFive"
                                        >
                                            <div className="accordion-body">
                                                <p>In the morning, after breakfast, begin your <strong>Paro Trip</strong> and en route visit: <br /></p>

                                                <p><b>National Museum of Bhutan:</b> Located just above Rinpung Dzong in Paro, the National Museum of Bhutan is a treasure trove that showcases the rich culture of Bhutan. Established in 1968, the museum is housed within the ancient Ta-dzong building. It features an impressive collection of Bhutanese culture and art, with highlights including exquisite paintings and bronze statues that are true masterpieces. The items are displayed in spacious galleries, allowing you to learn extensively about Bhutan's art and culture through these remarkable objects. <br /><br /></p>

                                                <p><b>Drukgyal Dzong:</b> Once a fortress and Buddhist monastery, Drukgyal Dzong is now in ruins, located in the upper part of Paro Valley in Bhutan. The Dzong was likely constructed by Tenzin Drukdra in 1649 at the request of Shabdrung Ngawang Namgyal to commemorate a victory over an invasion from Tibet. Please note that it is currently closed to the public. <br /><br /></p>

                                                <p><b>Ta Dzong:</b> Initially built as a watchtower to protect Rinpung Dzong during the inter-valley wars of the 17th century, Ta Dzong was inaugurated as Bhutan's National Museum in 1968. <br /><br /></p>

                                                <p><b>Rinpung Dzong:</b> Constructed in 1646 by Shabdrung Ngawang Namgyal, the first spiritual and temporal ruler of Bhutan, this Dzong houses the monastic body of Paro as well as the offices of the Dzongda (district administrative head) and Thrimpon (judge) of Paro district. The approach to the Dzong is via a traditional covered bridge known as Nemi Zam. <br /><br /></p>

                                                <p><b>Nya Mey Zam Bridge:</b> Known as "The Bridge with No Fish," this bridge connects Paro Town to the Dzong. It is one of the finest specimens of Bhutanese architecture, with a local belief that seeing a fish in the river is an ill omen. The previous bridge, which could be folded during wartime, was washed away by floods in 1969. <br /><br /></p>

                                                <p><b>Paro Airport View Point:</b> This is one of the best vantage points to enjoy breathtaking views of Paro Valley and Paro Airport. Overlooking the Paro River, it also offers scenic vistas of the Dzongs and monasteries in the area. Overnight at a hotel in Paro. <br /><br /></p>

                                                <div className="content-desktop">
                                                    <center><img src={navDay5ImageURL} alt="Bhutan B2B Package Tour Operator" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="Bhutan B2B Package Tour Operator" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day6"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSix"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 06: PARO, TAKTSANG MONASTERY HIKING</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSix"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSix"
                                        >
                                            <div className="accordion-body">
                                                <p>As the sun rays illuminate the stunning Paro valley, awaken amidst the beauty of Mother Nature and savor the breathtaking views of the river and mountains all in one frame. Enjoy a lavish breakfast spread, ensuring you include some protein, as the climb to the top of the Taksang Monastery will require plenty of energy. <br /><br /></p>

                                                <p><b>Taktsang Monastery:</b> After ascending 1,200 meters on a steep cliff, you will arrive at this sacred site known as the Tiger Nest. Once you reach the summit, take a deep breath of the fresh air and feast your eyes on the spectacular views of the surrounding landscape and the valley below. The Taktsang Monastery was established by Guru Rinpoche, who arrived in Bhutan in 747 CE, and its construction was completed in 1694. Following an adventurous trek lasting about 3 hours, you will discover the Tiger Nest monastery, and if you prefer, you can opt for a horse ride from the parking lot to the top. During your trek, enjoy the glorious sights of Paro town, with its lush green valleys, sparkling river, clear blue sky, and drifting clouds—truly a celebration of nature at its finest. Overnight in Paro. <br /><br /><br /></p>

                                                <h4>SOME FACTS TO NOTE ABOUT - THE TIGER'S NEST MONASTERY</h4>
                                                <br /><br />
                                                <div id="no-more-tables">
                                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">

                                                        <tbody><tr><td>Location</td><td>Approx 11.7 km from Paro town, Bhutan</td></tr>
                                                            <tr><td>Elevation</td><td>3,120 meters (10,236ft) above sea level</td></tr>
                                                            <tr><td>Duration of the Tiger's Nest hike</td><td>5-6 hours (Back and forth)</td></tr>
                                                            <tr><td>Tiger's Nest Trekking Distance</td><td>4 Miles (6.43 Kilometers) - Round Trip</td></tr>
                                                            <tr><td>Tiger's Nest Trek Difficulty</td><td>Moderate - Steep Ascend</td></tr>
                                                            <tr><td>Best Months to Visit Tiger's Nest Monastery</td><td>March, April, May and September, October, November, December</td></tr>
                                                            <tr><td>Gears needed to hike Tiger's Nest</td><td>Normal hiking shoes, Water bottle, Some snacks, energy bars, Camera, Hat, Sunglasses, Sunscreen, Walking Pole/Stick</td></tr>
                                                            <tr><td>Opening time of Tiger's Nest Monastery</td><td>(October-March) 8 am-1 pm and 2 pm-5 pm <br /> (April-September) 8 am-1 pm and 2 pm-6 pm daily</td></tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br /><br />
                                                <div className="content-desktop">
                                                    <center><img src={navDay6ImageURL} alt="B2B Tour Operator for Bhutan" /></center>
                                                </div>

                                                <div className="content-mobile2">
                                                    <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="B2B Tour Operator for Bhutan" /></center>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day7"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 07: PARO TO LATAGURI VIA PHUENTSHOLING</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>After enjoying breakfast at your hotel in Paro, embark on your journey to Lataguri, marking the beginning of your Dooars Package Tour. Lataguri serves as the gateway to Gorumara National Park in West Bengal. This mid-sized national park, nestled in the Dooars region, is characterized by its lush grasslands and dense forests. It is renowned for its diverse wildlife, including the One-Horned Rhino, Wild Elephants, Bison, and numerous Peacocks. The evening is yours to relax and explore at your leisure. Overnight stay in Lataguri.<br /><br /></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div
                                            className="accordion-header"
                                            id="day8"
                                        >
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                <h4>DAY 08: LATAGURI TO BAGDOGRA - <strong>BHUTAN B2B TOUR </strong> ENDS</h4>
                                            </button>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-headingSeven"
                                        >
                                            <div className="accordion-body">
                                                <p>In the early morning, enjoy the <strong>Gorumara Jungle Safari</strong> before heading to Bagdogra Airport. After an early breakfast, carry with you sweet memories of your Bhutan holiday. Cherish your experiences from the <strong>Bhutan B2B Tour Package ex Bagdogra</strong> forever.
                                                    <br /><br /></p>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <br /><br />
                            <div className="container-fluid py-5">
                                <div className="row placetoVisit">
                                    <h3>TOP 10 ATTRACTIONS OF <strong>BHUTAN B2B PACKAGE TOUR</strong><br /><br /></h3>
                                    <p>Embark on an unforgettable journey with a <strong>Bhutan B2B package tour</strong>, where you can explore the unique cultural and natural treasures of this enchanting Himalayan kingdom. With direct flights included in your tour package, getting to Bhutan is convenient and hassle-free. Here are the top ten attractions that will make your <strong>Bhutan trip</strong> truly special. <br /><br /></p>
                                    <ul>
                                        <p>
                                            <b>TrashiChhoe Dzong, Thimphu</b><br />
                                            Begin your Bhutan adventure with a visit to TrashiChhoe Dzong in Thimphu, a stunning fortress that embodies the essence of Bhutanese architecture. Known as the "Fortress of the Glorious Religion," it serves as the administrative seat of the government and a spiritual center. The dzong’s intricate designs and serene surroundings make it a perfect introduction to Bhutan's rich heritage.
                                            <br /><br /><br />
                                        </p>

                                        <p>
                                            <b>Takin Zoo, Thimphu</b><br />
                                            As part of your <strong>Bhutan B2B package tour</strong>, don’t miss the opportunity to visit Takin Zoo. This sanctuary is home to the national animal of Bhutan, the takin, a unique creature found nowhere else in the world. Here, you can learn about Bhutan's diverse wildlife and conservation efforts while enjoying the beautiful natural environment.
                                            <br /><br /><br />

                                            <b>Weekend Market, Thimphu</b><br />
                                            Dive into the vibrant culture of Bhutan by visiting the Weekend Market in Thimphu. This lively market offers a wide array of local handicrafts, fresh produce, and traditional Bhutanese delicacies. Exploring the market gives you a taste of everyday life in Bhutan and the opportunity to purchase unique souvenirs.
                                            <br /><br /><br />

                                            <b>Gangtey Monastery</b><br />
                                            Included in your <strong>Bhutan B2B package from Bagdogra</strong>, Gangtey Monastery in the Phobjikha Valley is a serene retreat showcasing Bhutan's spiritual heritage. Nestled among pristine landscapes, the monastery is an excellent place for meditation and reflection. The surrounding area is perfect for leisurely walks, allowing you to connect with nature.
                                            <br /><br /><br />

                                            <b>Shopping in Handicrafts Village, Thimphu</b><br />
                                            Enhance your <strong>Bhutan B2B package from Bagdogra</strong> with a shopping excursion to the Handicrafts Village in Thimphu. Here, you can browse exquisite handicrafts, textiles, and souvenirs crafted by local artisans. This is not only a chance to shop but also an opportunity to appreciate the skill and creativity of Bhutanese craftsmanship.
                                            <br /><br /><br />

                                            <b>Buddha Point, Thimphu</b><br />
                                            Marvel at the majestic statue of Buddha Dordenma at Buddha Point in Thimphu. This colossal statue overlooks the Thimphu Valley, offering panoramic views. The peaceful atmosphere around the statue makes it an ideal spot for contemplation and relaxation.
                                            <br /><br /><br />

                                            <b>Tiger Nest Hiking, Paro</b><br />
                                            Experience the thrill of hiking to the legendary Tiger’s Nest Monastery (Taktsang) in Paro, a highlight of your <strong>Bhutan tour from Bagdogra </strong>. This sacred site, perched on a cliff, requires a moderate trek, but the stunning views and spiritual significance make it worthwhile. The hike allows you to immerse yourself in Bhutan’s rich spiritual traditions and breath-taking landscapes.
                                            <br /><br /><br />

                                            <b>River Rafting in Punakha River, Punakha</b><br />
                                            Add an adventurous twist to your <strong>Bhutan B2B Packages from India</strong> with exhilarating river rafting on the Punakha River. Surrounded by lush greenery and majestic mountains, this activity is perfect for thrill-seekers. Experience the rush of navigating the river's rapids while taking in the stunning scenery around you.
                                            <br /><br /><br />

                                            <b>Punakha Dzong, Punakha</b><br />
                                            No visit to Bhutan is complete without exploring Punakha Dzong. Located at the confluence of the Mo Chhu and Pho Chhu rivers, this fortress-monastery is rich in history and cultural significance. The dzong’s magnificent structure and serene gardens offer insight into Bhutanese culture and spirituality.
                                            <br /><br /><br />

                                            <b>Simply Bhutan Museum</b><br />
                                            Immerse yourself in Bhutanese culture at the Simply Bhutan Museum during your <strong>Bhutan package tour from Bagdogra</strong>. This living museum offers interactive exhibits, traditional performances, and opportunities to learn about the daily lives of Bhutanese people. It’s a unique experience that brings Bhutan's rich culture to life.
                                            <br /><br /><br />

                                            <b>Phobjikha Valley</b><br />
                                            Discover the unspoiled beauty of Phobjikha Valley, known for its wintering black-necked cranes. This sanctuary for nature lovers and bird watchers offers breathtaking landscapes, making it a perfect day trip during your Bhutan tour.
                                            <br /><br /><br />

                                            <b>Haa Valley, Haa District</b><br />
                                            Journey to the picturesque Haa Valley as part of your <strong>Bhutan B2B Travel Package</strong>. This hidden gem in western Bhutan offers stunning landscapes, ancient temples, and rich cultural experiences that will leave you enchanted.
                                            <br /><br /><br />

                                            <b>Dochula Pass</b><br />
                                            Capture panoramic views of the Himalayas at Dochula Pass, a scenic spot adorned with 108 memorial chortens. This mountain pass is not only a photographer’s paradise but also a serene place for reflection and appreciation of Bhutan’s natural beauty.
                                            <br /><br /><br />

                                            <b>Punakha Suspension Bridge</b><br />
                                            Dare to cross the Punakha Suspension Bridge, one of the longest in Bhutan. This thrilling experience offers stunning views of the Punakha Valley and is a memorable highlight of your journey.
                                            <br /><br /><br />

                                            <b>Chimi Lhakhang</b><br />
                                            Conclude your <strong>Bhutan Corporate Tour</strong> with a visit to Chimi Lhakhang, the fertility temple dedicated to the Divine Madman. Known for its unique phallus symbols believed to bring blessings and fertility, this temple is a fascinating glimpse into Bhutan's colorful religious traditions.
                                            <br /><br /><br />

                                            With these top attractions included in your <strong>Bhutan Tour Package</strong>, you are sure to experience the beauty, culture, and adventure that Bhutan has to offer. Book your Bhutan tour today with Adorable vacation – <strong> India's No 1 Travel DMC for Bhutan Tour</strong> and embark on a journey of a lifetime!
                                        </p>
                                    </ul>
                                </div>
                                <br />   <br />   <br />
                                <div className="row placetoVisit">
                                    <h3>PLACES YOU MUST VISIT WITH <strong>BHUTAN B2B TRAVEL PACKAGE</strong></h3><br />
                                    <ul>
                                        <li>Thimphu - Buddha Dordenma <br /></li>
                                        <li>Paro - Taktsang Monastery <br /></li>
                                        <li>Thimphu - Dagala Thousand Lakes <br /></li>
                                        <li>Punakha - Punakha Suspension Bridge <br /></li>
                                        <li>Thimphu - National Memorial Chorten <br /></li>
                                        <li>Trashigang Dzong - Trashigang District <br /></li>
                                        <li>Thimphu - Tashichho Dzong <br /></li>
                                        <li>Thimphu - Simtokha Dzong <br /></li>
                                        <li>Trongsa - Trongsa Dzong <br /></li>
                                        <li>Paro - Rinpung Dzong <br /></li>
                                        <li>Punakha - Punakha Dzong <br /></li>
                                        <li>Gangtey Monastery - Wangdue Phodrang <br /></li>
                                        <li>Punakha - Chimi Lhakhang Temple <br /></li>
                                        <li>Dochula Pass - On the way from Thimphu to Punakha <br /></li>
                                        <br /><br /><br />
                                    </ul>
                                    <h3>RELATED SEARCHES FOR <strong>BHUTAN B2B PACKAGES FROM INDIA</strong><br /></h3>
                                    <ul><li>Bhutan B2B Travel Packages</li>
                                        <li>Bhutan B2B Tours for Businesses</li>
                                        <li>Corporate Travel Packages to Bhutan</li>
                                        <li>Bhutan B2B Group Tours</li>
                                        <li>Bhutan B2B Adventure Travel</li>
                                        <li>Bhutan B2B Cultural Tours</li>
                                        <li>Bhutan B2B Wellness Retreats</li>
                                        <li>Bhutan B2B Incentive Travel</li>
                                        <li>Bhutan B2B Conference Packages</li>
                                        <li>Bhutan B2B Customized Tours</li>
                                        <li>Bhutan B2B Travel Agents</li>
                                        <li>India's No 1 Travel DMC for Bhutan</li>
                                        <li>Bhutan B2B Agents</li>
                                        <li>Best B2B Travel Agency of Bhutan</li>
                                        <li>Best Bhutan B2B Travel Agency in India</li>
                                        <li>Bhutan B2B Tour Operator in India</li>
                                        <li>Bhutan B2B Tour Packages</li>
                                        <li>Bhutan B2B DMC Travel Company</li>
                                    </ul>
                                </div>
                                <br /><br />
                                <div className="container-fluid py-5">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="site-heading text-center">
                                                <h2>
                                                    Branded Bhutan Tour <span>- The SIX SENSE</span>
                                                </h2>
                                                <h4>
                                                    <i>Next Level Luxury Sense</i>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row py-3">
                                        <img src={sixSenseBhutanTourPackagesImageURL} alt="Bhutan package tour with hotel six senses" />
                                    </div>
                                </div>
                                <div id="no-more-tables">
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Packages</th>
                                                <th>Duration</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Head)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Luxury Trip</td>
                                                <td>5N/6D</td>
                                                <td>
                                                    The SIX SENSE (Ultra Luxury)
                                                    <div className="reviews">
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                </td>
                                                <td>Rs 5,36,789/- </td>
                                                <td><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick1}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Six Sense Bhutan Packages - An Ultra Luxury Five Star Brand</h3>
                                        <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>  Six Sense Bhutan Packages - An Ultra Luxury Five Star Brand</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p>
                                            Adorable Vacation can adjust your itinerary depending on your needs
                                            & preference. We are happy to arrange your <a href='/bhutan-luxury-package-tour-with-six-senses-hotel' target='_blank'><strong>Bhutan Package tour With Hotel Six Senses</strong></a> With Best  itinerary that fit your Budget and interests, allowing you to explore and enjoy the
                                            Kingdom of Bhutan at your own peace.<br /><br />
                                        </p>
                                        <h3 className="tomato">Unbeatable GEM of Bhutan</h3>
                                        <p>
                                            Travel back in time and take a journey that few people
                                            have had the opportunity to experience. Druk Yul, Bhutan’s
                                            official name, is known as the Land of the Dragon People
                                            with a rich cultural heritage that has largely remained
                                            hidden until its recent emergence onto the world stage.{" "}
                                            <b>Your Six Senses GEM (Guest Experience Maker)</b> will
                                            meet you upon arrival and accompany you on your khamsa
                                            ‘royal stroll’ through the Kingdom, sharing insights to
                                            the culture, history and local life of each valley as you
                                            go. Discover the country’s breathtaking natural beauty,
                                            meet and mingle with our Bhutanese friends as you embrace
                                            the philosophy of a nation that is guided by the
                                            principles of ‘Gross National Happiness’. Our khamsas are
                                            designed with just the right balance of meaningful
                                            encounters and experiences in mind along with a healthy
                                            dose of fun. The following is only a guideline. Your GEM
                                            will help to tailor your 5-day Bhutan itinerary to your
                                            needs and interests each day.
                                        </p>
                                        <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Service available at Paro, Punakha, Thimphu, Bumthang
                                            and Gangtey.<br /><br /></h3>

                                        <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b> Service available at Paro, Punakha, Thimphu, Bumthang
                                            and Gangtey.</b></h3>
                                        <a href="tel://9883359713" className="btn btn-Warning">
                                            Book Now
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                Branded Bhutan Tour <span>- The AMAN KORA</span>
                                            </h2>
                                            <h4>
                                                <i>Stay with Trust</i>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={amanKoraBhutanPackagesImageURL} alt="Bhutan package tour from Bagdogra with hotel aman kora" />
                                </div>
                                <div id="no-more-tables">
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Packages</th>
                                                <th>Duration</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Head)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Premium Trip</td>
                                                <td>5N/6D</td>
                                                <td>
                                                    The AMAN KORA (Premium)
                                                    <div className="reviews">
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                </td>
                                                <td>Rs 4,70,389/- </td>
                                                <td><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick1}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div
                                        className="card-header custHeaer"
                                    >
                                        <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>  AMAN KORA Bhutan Packages - A Super Premium Five Star Brand</h3>

                                        <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>  AMAN KORA Bhutan Packages - A Super Premium Five Star Brand</b></h3>

                                    </div>
                                    <div className="card-body">
                                        <p>
                                            Adorable Vacation can adjust your <strong>Bhutan Tour Itinerary</strong> depending on your needs
                                            & preference. We are happy to arrange your itinerary to
                                            fit your interests, allowing you to explore and enjoy the
                                            Kingdom of Bhutan at your own peace.<br /><br />
                                        </p>
                                        <h3 className="tomato">Unbeatable GEM of Bhutan</h3>
                                        <p>
                                            Travel back in time and take a journey that few people
                                            have had the opportunity to experience. Druk Yul, Bhutan’s
                                            official name, is known as the Land of the Dragon People
                                            with a rich cultural heritage that has largely remained
                                            hidden until its recent emergence onto the world stage.{" "}
                                            <b>Your Six Senses GEM (Guest Experience Maker)</b> will
                                            meet you upon arrival and accompany you on your khamsa
                                            ‘royal stroll’ through the Kingdom, sharing insights to
                                            the culture, history and local life of each valley as you
                                            go. Discover the country’s breathtaking natural beauty,
                                            meet and mingle with our Bhutanese friends as you embrace
                                            the philosophy of a nation that is guided by the
                                            principles of ‘Gross National Happiness’. Our khamsas are
                                            designed with just the right balance of meaningful
                                            encounters and experiences in mind along with a healthy
                                            dose of fun. The following is only a guideline. Your GEM
                                            will help to tailor your 5-day Bhutan itinerary to your
                                            needs and interests each day.
                                        </p>

                                        <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Service available at Paro, Punakha, Thimphu, Bumthang
                                            and Gangtey.</h3>

                                        <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b> Service available at Paro, Punakha, Thimphu, Bumthang
                                            and Gangtey.</b></h3>

                                        <a href="tel://9883359713" className="btn btn-Warning">
                                            Book Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <br />
                            {/* <!--FOQ--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                Frequently Ask <span>Questions</span>
                                            </h2>
                                            <h4>Related Questions for <strong>Bhutan B2B Tour Packages</strong></h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="accordion accordion-flush faQStyle"
                                        id="accordionFlushExample"
                                    >
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingPackages">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapsePackages"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapsePackages"
                                                >
                                                    What is included in your Bhutan B2B packages?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapsePackages"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingPackages"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Our <strong>Bhutan B2B packages</strong> typically include accommodation, transportation, guided tours, meals, and necessary permits. We customize these packages based on client needs to ensure a seamless experience. <br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingPermits">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapsePermits"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapsePermits"
                                                >
                                                    How can Adorable Vacation assist with Bhutan travel permits?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapsePermits"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingPermits"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    As a specialist <strong>Bhutan DMC</strong> and TCB License Holder [License No: 1053228], we handle the entire permit application process online/offline for your clients, ensuring they receive the necessary documentation for a hassle-free experience. Our dedicated staff in Bhutan ensures 100% confirmation of permits.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingBusiness">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseBusiness"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseBusiness"
                                                >
                                                    What types of businesses can benefit from Bhutan B2B packages?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseBusiness"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingBusiness"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Our <strong>Bhutan B2B packages</strong> cater to travel agencies, corporate clients, MICE, and tour operators. We offer tailored solutions for group tours, incentive trips, and special events.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingGroups">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseGroups"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseGroups"
                                                >
                                                    Are your Bhutan tours suitable for large groups?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseGroups"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingGroups"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Yes, we specialize in managing large group tours. We recently completed an 80-person Rotary Club tour with almost zero complaints. All guests shared positive feedback and enjoyed their experience with Adorable Vacation.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingDestinations">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseDestinations"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseDestinations"
                                                >
                                                    What destinations are included in your Bhutan B2B packages?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseDestinations"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingDestinations"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Our <strong>Bhutan B2B packages</strong> cover popular destinations like Thimphu, Paro, Punakha, and Phobjikha Valley. We also offer customized trips to more remote areas such as Gasa, Throngsa, Trashigang, Dagana, Bumthang, Zhemgang, and Trashiyangtse.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingGuides">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseGuides"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseGuides"
                                                >
                                                    Do you provide local guides for Bhutan B2B Tour Package?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseGuides"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingGuides"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Absolutely! We provide experienced, humble, and knowledgeable local guides who are well-versed in Bhutanese culture, history, and geography, ensuring a rich experience for your clients.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingQuality">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseQuality"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseQuality"
                                                >
                                                    How do you ensure the quality of your services?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseQuality"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingQuality"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    We prioritize high-quality services through partnerships with local vendors, staff training, and a commitment to customer satisfaction.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingCustomize">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseCustomize"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseCustomize"
                                                >
                                                    Can we customize our Bhutan B2B package?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseCustomize"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingCustomize"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Yes, we offer fully customizable packages to meet client needs, including itinerary adjustments, special accommodations, and activities.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingClimate">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseClimate"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseClimate"
                                                >
                                                    What should clients expect regarding the climate in Bhutan?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseClimate"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingClimate"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Bhutan’s climate varies by region and season. We provide detailed information on the best times to visit and what to expect to help clients prepare.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingContact">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseContact"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseContact"
                                                >
                                                    How can I get in touch with Adorable Vacation for booking Bhutan B2B Package?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseContact"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingContact"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    You can contact us through our website or directly via phone or email. Our Bhutan Specialists, Mr. Soumalya [+91-98833-59713] and Ms. Sonali [+91-96747-38480], are ready to assist you with any inquiries.<br /><br />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>
                                                <b>
                                                    Bhutan <span className="tomato">Theme Package</span>
                                                </b>
                                            </h3>
                                            <h4>Similar Mountain Trip</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        id="carouselExampleControls"
                                        className="carousel slide"
                                        data-bs-ride="carousel"
                                    >
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <Slider {...settings}>{themePackagesDataView}</Slider>
                                            </div>
                                        </div>
                                    </div>

                                    <br />
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>
                                                <b>
                                                    Bhutan <span className="tomato">Tour Reviews</span>
                                                </b>
                                            </h3>
                                            <h4>
                                                <i>"Travel without Trouble"</i>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1 ">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">
                                            {reviewersDataView}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3">

                            <BookingBox />

                        </div>
                        {this.state.showModal && (
                            <div className="modal d-flex align-items-center justify-content-center" style={{ display: this.state.showModal ? "block" : "none" }}>
                                <div className="modal-dialog">
                                    <div className="modal-content cust-modContent" ref={this.modalRef}>
                                        <div className="modal-header">
                                            <h4 className="modal-title ms-auto" id="exampleModalLabel">Get a Free Callback &nbsp;&nbsp;<i className="fa fa-phone fa-rotate-90"></i>
                                            </h4>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.onCloseModal}></button>
                                        </div>
                                        <div className="modal-body">
                                            <form className="row g-3">
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name *"
                                                        name="firstName"
                                                        value={this.state.firstName}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="10 Digit Mobile No.*"
                                                        name="mobile"
                                                        value={this.state.mobile}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Email "
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Comments"
                                                        rows="2"
                                                        name="comments"
                                                        value={this.state.comments}
                                                        onChange={this.onChangeHandler}></textarea>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <div className='container'>
                                                <div className="row">
                                                    <div className='col-sm-4'>
                                                        {
                                                            (this.state.showLoader) &&
                                                            <div className='pull-left'><Digital size={25} /></div>
                                                        }
                                                    </div>
                                                    <div className='col-sm-8 '>
                                                        <div className='pull-right'>
                                                            {/* <button type="button" className="btn btn-Warning mr-1" onClick={this.onCloseModal}>Close</button> */}
                                                            <button type="button" className="btn btn-Warning btn-lg" onClick={this.onRequestCallbackSubmit}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default Bhutan_B2B_7N_8D_Package;