import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop';
import Egypt_8N_9D_Tour_Package from '../../../components/package/Egypt/Egypt_8N_9D_Tour_Package';
import { Helmet } from 'react-helmet';
class Egypt_8N_9D_Page extends Component {
    constructor() {
        super();
        this.state = {
            HotPackagesData: [],
            ThemePackagesData: [],
            HotelsData: [],
            TrendingBhutanDataList: [],
            ReviewersData: [],

        }
    }


    componentDidMount() {

        window.scroll(0, 0);
        const hotPackageDataList = [
            {
                Title: "Blissful Bhutan",
                ImageName: "4N-5D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 1N Punakha | 1N Paro | Free Sim Card | E-Permit",
                PricePerNight: "22,599",
                NoOfTours: 42,
                NoOfDeparture: 35,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/4N-5D-FIFO-package',
                EXPARO: "EX-PARO"
            },
            {
                Title: "Untouched Bhutan",
                ImageName: "5N-6D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 1N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "27,899",
                NoOfTours: 45,
                NoOfDeparture: 39,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/5N-6D-FIFO-package',
                EXPARO: "EX-PARO"

            },
            {
                Title: "Explore Bhutan",
                ImageName: "6N-7D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "32,999",
                NoOfTours: 43,
                NoOfDeparture: 38,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/6N-7D-FIFO-package',
                EXPARO: "EX-PARO"
            },

            {
                Title: "Excape Bhutan",
                ImageName: "7N-8D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro | Free Sim Card | E-Permit",
                PricePerNight: "37,999",
                NoOfTours: 47,
                NoOfDeparture: 40,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/mumbai-special-chartered-flight',
                EXPARO: "EX-PARO"
            },
            {
                Title: "Pleasing Bhutan",
                ImageName: "8N-9D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "3N Thimphu | 2N Punakha | 3N Paro | Free Sim Card | E-Permit",
                PricePerNight: "43,499",
                NoOfTours: 44,
                NoOfDeparture: 37,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/8N-9D-FIFO-package',
                EXPARO: "EX-PARO"
            },
            {
                Title: "Fascinating Bhutan",
                ImageName: "10N-11D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Bumthang...more | Free Sim Card | E-Permit",
                PricePerNight: "53,899",
                NoOfTours: 46,
                NoOfDeparture: 39,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head / Min 6 Pax",
                book: "Book Now",
                Url: '/10N-11D-FIFO-package',
                EXPARO: "EX-PARO"
            }

        ];

        const themePackageDataList = [
            {
                Title: "Bhutan Honeymoon",
                ImageName: "Bhutan-Honeymoon-Package.webp",
                AllternateText: "...",
                Description: "Bhutan Package is your ultimate destination. 7N/8D is the decent duration to explore the relation between Nature & Better Half.",
            },
            {
                Title: "Wedding at Bhutan",
                ImageName: "Destination-Wedding-at-Bhutan.webp",
                AllternateText: "...",
                Description: "Make your iconic marriage day very very special at Bhutan. Marriage like VIP, execute a super destination wedding 'LIKE NEVER BEFORE'.available.",
            },
            {
                Title: "Bhutan Solo Trip (Woman)",
                ImageName: "Bhutan-Solo-Trip-Woman-Special.webp",
                AllternateText: "...",
                Description: "Love to explore everything by yourself ? Then Bhutan is the god gifted gem for you. Numerous things are present to explore,experience & recreate.",
            },
            {
                Title: "Bhutan Adventure Trip",
                ImageName: "Bhutan-Adventure-Packages.webp",
                AllternateText: "...",
                Description: "After NEPAL, Bhutan is an amazing counter destiation for Adventure lover. Explore with thrilling activities - River Rafting, Paragliding & Kayaking etc.",
            },
            {
                Title: "Bhutan Bike Trip",
                ImageName: "Bhutan-Bike-Package-Tour.webp",
                AllternateText: "...",
                Description: "Throughout the Bhutan bike trip, you will pass through curvy roads with most picturesque surroundings with high eastern Himalayan snow-capped peaks.",
            },
            {
                Title: "Pilgrimage of Bhutan",
                ImageName: "Bhutan-Monastery-Packages.webp",
                AllternateText: "...",
                Description: "Bhutan is the only country in the world that officially practices Mahayana Buddhism or Vajrayana Buddhism. Buddhism is a way of life in Bhutan.",
            },
            {
                Title: "Bhutan Snowman Trek",
                ImageName: "Bhutan-Snowman-Trek.webp",
                AllternateText: "...",
                Description: "The Snowman Trek is the longest hiking trail of Bhutan that extends from Laya to the high Bhutanese Himalayas, covering up the northern Kingdom.",
            },
            {
                Title: "Bhutan Foreigners Package",
                ImageName: "Bhutan-Forigners-Package.webp",
                AllternateText: "...",
                Description: "As a foreigner, living in Bhutan can be a rewarding and fascinating experience,a small landlocked country in the eastern Himalayas, is known for its unique culture.",
            },

        ]

        const reviewersDataList = [
            {
                Name: "Dr.Anirban Roy and Family",
                ImageName: "Anirban-Roy.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Our family’s trip to Egypt with Team Adorable Vacation was nothing short of spectacular! Mr. Soumalya Ghosh made sure every aspect of our tour, from the majestic pyramids to the cruise on the Nile, was seamless and memorable. The kids were thrilled with the camel rides, and we were in awe of the ancient temples. His attention to detail and constant support made our experience truly special. Highly recommend Team Adorable for an unforgettable family vacation!",
            },
            {
                Name: "Mrs. Sumita Sen and Family",
                ImageName: "Sumita-Sen.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "We had the most amazing time in Egypt, thanks to the incredible planning and care by Mr. Akash Dutta from Adorable Vacation. From exploring the Sphinx to sailing the Nile, everything was perfectly arranged for our family. Mr. Akash’s professionalism and warm hospitality made us feel comfortable and well taken care of throughout the tour. Our Egypt adventure was magical, and we can’t wait to plan our next trip with them!",
            },
            {
                Name: "Mr. Rajesh Gupta and Family",
                ImageName: "Rajesh-Gupta.webp",
                AllternateText: "...",
                Location: "@Ahmedabad",
                Comment: "Exploring the wonders of Egypt with my family through Adorable Vacation was a dream come true. Mr. Soumalya Ghosh ensured that every moment was well-coordinated, from the breathtaking pyramids to the vibrant local markets. His expertise made our journey smooth and enjoyable, and the memories we’ve created are ones we will cherish forever. Thank you, Soumalya, for making our family trip so perfect!",
            },
            {
                Name: "Mrs. Pooja Mehta and Family",
                ImageName: "Pooja-Mehta.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "A big thank you to Mr. Akash Dutta and the entire team at Adorable Vacation for organizing an unforgettable family trip to Egypt! We were able to see all the iconic sites, including the Pyramids of Giza and the beautiful temples along the Nile. Akash made sure we were comfortable at every step, and his guidance throughout the tour was exceptional. It was a fantastic experience that we’ll talk about for years to come.",
            },
            {
                Name: "Mr. Shantanu Das and Family",
                ImageName: "Shantanu-Das.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Our family had a truly enriching and adventurous trip to Egypt with Adorable Vacation. Mr. Soumalya Ghosh was an outstanding tour coordinator, always ensuring our needs were met and providing insightful information about the historical sites. The tour was a perfect blend of excitement and relaxation, with everything taken care of so efficiently. We couldn’t have asked for a better experience. Thank you, Soumalya, for making this trip unforgettable!",
            },
        ];

        const hotelDataList = [
            {
                HotelName: 'Hotel Ugyen, Thimpu',
                ImageName: "hotel-ugyen.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.9,
                Reviews: '50 Reviews'
            },
            {
                HotelName: 'Amodhara, Thimphu',
                ImageName: "amodhara-2.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '47 Reviews'
            },
            {
                HotelName: 'White Dragon, Punakha',
                ImageName: "white-dragon.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.7,
                Reviews: '46 Reviews'
            },
            {
                HotelName: 'Pema karpo, Punakha',
                ImageName: "pema-karpo.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.6,
                Reviews: '48 Reviews'
            },
            {
                HotelName: 'Layhuel, Paro',
                ImageName: "lehul.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '49 Reviews'
            },
            {
                HotelName: 'Tshringma, Paro',
                ImageName: "Tsherim-resort.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.7,
                Reviews: '43 Reviews'
            },
            {
                HotelName: 'Green Touch, Lataguri',
                ImageName: "green-touch.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.6,
                Reviews: '44 Reviews'
            },
            {
                HotelName: 'Suncity, Lataguri',
                ImageName: "suncity.webp",
                AllternateText: "...",
                HotelStar: '★★★',
                RatingBoxStar: '★',
                RatingValue: 4.8,
                Reviews: '40 Reviews'
            }
        ];

        const trendingBhutanDataList = [
            {
                Title: "Blissful Bhutan",
                ImageName: "6N-7D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "31,899",
                NoOfTours: 43,
                NoOfDeparture: 38,
                Url: 'tel://9883359713',
            },
            {
                Title: "Untouched Bhutan",
                ImageName: "7N-8D-Bhutan-Tour.webp ",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro | Free Sim Card | E-Permit",
                PricePerNight: "37,599",
                NoOfTours: 47,
                NoOfDeparture: 40,
                Url: 'tel://9883359713',
            },
            {
                Title: "Explore Bhutan",
                ImageName: "9N-10D-Bhutan-Tour1.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "48,899",
                NoOfTours: 44,
                NoOfDeparture: 37,
                Url: 'tel://9883359713',
            },
            {
                Title: "Excape Bhutan",
                ImageName: "12N-13D-Bhutan-Tour1.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro | Free Sim Card | E-Permit",
                PricePerNight: "76,500",
                NoOfTours: 46,
                NoOfDeparture: 39,
                Url: 'tel://9883359713',
            }

        ];


        this.setState({
            HotPackagesData: hotPackageDataList,
            ThemePackagesData: themePackageDataList,
            HotelsData: hotelDataList,
            TrendingBhutanDataList: trendingBhutanDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Egypt Package Tour from Kolkata – Best Deal from Adorable Vacation LLP</title>
                    <meta name="description" content=" We Offer the Best Egypt Package Tour from Kolkata for 8N 9D. For Booking ☎ CALL Mr. SOUMALYA +91-98833-59713" />
                </Helmet>

                <MainHeader />

                <Egypt_8N_9D_Tour_Package
                    HotPackagesData={this.state.HotPackagesData}
                    ThemePackagesData={this.state.ThemePackagesData}
                    HotelsData={this.state.HotelsData}
                    TrendingBhutanDataList={this.state.TrendingBhutanDataList}
                    ReviewersData={this.state.ReviewersData}
                />

                <FooterDesktop />
            </>
        )
    }
}

export default Egypt_8N_9D_Page;