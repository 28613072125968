import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop';
import Kashmir_7N_8D_Tulip_Package from '../../../components/package/Kashmir/Kashmir_7N_8D_Tulip_Package';
import { Helmet } from "react-helmet";

class Kashmir_7N_8D_Tulip_Page extends Component {
    constructor() {
        super();
        this.state = {
            TrendingPackagesData: [],
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const TrendingPackageDataList = [
            {
                Title: "Jannat-E-Kashmir",
                ImageName: "Kashmir-Package-Cost.webp",
                AllternateText: "...",
                Description: "3N Srinagar   |   Pahalgam |   Gulmarg |   Private Transfer   |   3 Star Hotel  |   Stay in House Boat  |   Shikara Ride",
                PricePerNight: "11,699",
                NoOfTours: 47,
                NoOfDeparture: 40,
                Include: "EX-SXR",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9147061467'
            },
            {
                Title: "Peaceful Kashmir",
                ImageName: "Kashmir-Family-Tour-Plans.webp",
                AllternateText: "...",
                Description: "3N Srinagar | Sonmarg | Gulmarg | 1N Pahalgam | Private Transfer | 3 Star Hotel | Stay in House Boat | Shikara Ride",
                PricePerNight: "15,499",
                NoOfTours: 43,
                NoOfDeparture: 38,
                Include: "EX-SXR",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9147061467'
            },
            {
                Title: "Charming Kashmir",
                ImageName: "Kashmir-Tour-Package-kolkata.webp",
                AllternateText: "...",
                Description: "4N Srinagar | Sonmarg | Gulmarg | 1N Pahalgam | Private Transfer | 3 Star Hotel | Stay in House Boat | Shikara Ride",
                PricePerNight: "18,499",
                NoOfTours: 44,
                NoOfDeparture: 37,
                Include: "EX-SXR",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9147061467'
            },
            {
                Title: "Sparkling Kashmir",
                ImageName: "Kashmir-Honeymoon-Package.webp",
                AllternateText: "...",
                Description: "4N Srinagar | Sonmarg | 1N Gulmarg | 2N Pahalgam | Private Transfer | 3 Star Hotel | Stay in House Boat | Shikara Ride",
                PricePerNight: "26,499",
                NoOfTours: 46,
                NoOfDeparture: 39,
                Include: "EX-SXR",
                MutedText: "*Per head / Min 6 Pax",
                Url: 'tel://9147061467'
            }
        ];

        const reviewersDataList = [
            {
                Name: "Arup Bhattacharya",
                ImageName: "Arup-Bhattacharya.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "We recently had a Kashmir tour with Adorable Vacation. Everything went well! The team is very supportive and always approachable. During the trip, associates are available 24*7 to help you out in case of any challenges.Thanks and best wishes to the whole team ❤️",
            },
            {
                Name: "Sharmi Mandal",
                ImageName: "Sharmi-Mandal.webp",
                AllternateText: "...",
                Location: "@Kolkata",
                Comment: "Thank you so much, Soumalya da, Soumya da, and Anushka, for organizing such a wonderful Kashmir trip. It was truly a memorable journey with Adorable Vacation. We deeply appreciate all your efforts. Thanks a lot!",
            },
            {
                Name: "Tanisha Chanda",
                ImageName: "Tanisha-Chanda.webp",
                AllternateText: "...",
                Location: "@Delhi",
                Comment: "Unforgettable Kashmir trip with my family, thanks to Team Adorable Vacation! Breathtaking scenery, cozy houseboats, and warm hospitality.Delicious local cuisine and memorable experiences, every thing was perfectly organised.",
            },
            {
                Name: "Shreya Das",
                ImageName: "Shreya-Das.webp",
                AllternateText: "...",
                Location: "@Bangalore",
                Comment: "This was a long awaited trip to Kashmir with our little one. A special thanks to the team of Adorable Vacation LLP for making it more special for us. The arrangements of accomodation and travel were perfect. Thank you for suggesting offbeat places full of peace and tranquility!! Hope to travel with you soon on a different destination!",
            },
        ]

        this.setState({
            TrendingPackagesData: TrendingPackageDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        return (
            <>
                
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Kashmir Tulip Festival Package Tour, Kashmir Tulip Festival Tour Packages</title>
                    <meta name="description" content="Book Kashmir Tulip Festival Package Tour from Kolkata with Adorable Vacation. FOR BOOKING ☎ CALL +91-91470-61467" />
                </Helmet>
                <MainHeader />
                <Kashmir_7N_8D_Tulip_Package
                    TrendingPackagesData={this.state.TrendingPackagesData}
                    ReviewersData={this.state.ReviewersData}
                    
                />
                <FooterDesktop />
            </>
        );
    }
}

export default Kashmir_7N_8D_Tulip_Page;