import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./InclusionExclusion";
import InclusionExclusion from "./InclusionExclusion_kolkata";

class KolkataToBhutanPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: "tab1",
      currentPage: 1,
      btn: "Submit",
      showLoader: false,
      isHidden: true,
      showModal: false,
      firstName: '',
      mobile: '',
      email: '',
      comments: ''
    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };

  handleDayWiseTabClick = (tab) => {
    this.setState({
      daywiseActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919883359713&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };

  handlePageChange = (direction) => {
    this.setState((prevState) => {
      const newPage = prevState.currentPage + direction;
      return { currentPage: newPage };
    });
  };

  onRequestCallback = (e) => {
    e.preventDefault();
    this.setState({ showModal: true });
  }



  onCloseModal = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      showModal: false,
      firstName: '',
      mobile: '',
      email: '',
      comments: ''
    });
  }

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  // Function to handle form submission
  onRequestCallbackSubmit = async (e) => {
    e.preventDefault();

    const { firstName, email, mobile, comments } = this.state;

    // Validation
    if (firstName.trim() === '') {
        cogoToast.error("Full Name Required.", { position: 'bottom-center' });
    } else if (email.trim() === '') {
        cogoToast.error("Email Required.", { position: 'bottom-center' });
    } else if (mobile.trim() === '' || !/^\d{10}$/.test(mobile.trim())) {
        cogoToast.error("Invalid Mobile No.", { position: 'bottom-center' });
    } else {
        this.setState({ btn: "Processing..", showLoader: true });
        // Proceed with form submission
        try {
            const _ref = 'yes';
            const _url = window.location.href;

            const formData = {
                FirstName: firstName,
                //LastName: lastName,
                Email: email,
                Mobile: mobile,
                // City: city,
                // TravelDate: travelDate,
                // TicketBooked: ticketBookedStatus,
                // NoOfGuests: noOfGuests,
                // HotelCategory: hotelCategory,
                Comments: comments,
                Referrer: _ref,
                URL: _url
            };

            const response = await axios.post(ApiURL.SaveCustomerEnquery, formData);
            console.log('Data submitted successfully:', response.data);
            // Optionally, reset the form state after successful submission
            this.setState({ firstName: '', email: '', mobile: '', comments: '' });

            // Optionally, show a success message to the user
            cogoToast.success("Form submitted successfully.", { position: 'bottom-center' });
            this.setState({ btn: "Submit", showLoader: false, showModal: false });
        } catch (error) {
            console.error('Error submitting data:', error);
            this.setState({ btn: "Submit", showLoader: false });
            // Optionally, show an error message to the user
            cogoToast.error("Failed to submit form. Please try again later.", { position: 'bottom-center' });
        }

    }

};


  render() {
    const rows = [
      { Train_No: '12345', Train_Name: 'Saraighat Exp', Boarding_From: 'Howrah', Boarding_time: '16:05', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '12377', Train_Name: 'Padatik Exp', Boarding_From: 'Sealdah', Boarding_time: '23:20', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13147', Train_Name: 'Uttar Banga Exp', Boarding_From: 'Sealdah', Boarding_time: '19:40', Arrival: 'New Cooch Behar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13175', Train_Name: 'Kanchanjunga', Boarding_From: 'Sealdah', Boarding_time: '06:50', Arrival: 'NJP', Day: 'M,W,SAT' },
      { Train_No: '15959', Train_Name: 'Kamrup Exp', Boarding_From: 'Howrah', Boarding_time: '18:30', Arrival: 'New Alipurduar', Day: 'T,W,T,S,S' },
      { Train_No: '13141', Train_Name: 'Teesta Torsha', Boarding_From: 'Sealdah', Boarding_time: '15:00', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13149', Train_Name: 'Kanchan Kanya', Boarding_From: 'Sealdah', Boarding_time: '20:35', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
    ];

    const rowsPerPage = 4;
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
    const { customActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Bhutan-Tour-Package-from-Bagdogra.webp";
    const packageTopBanner2ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Bhutan-Package-Tour-From-Kolkata.webp";
    const flightBhutanPackagesImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Bhutan-Flight-from-Kolkata.webp";
    const bhutanTravelAgentImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Bhutan-Travel-Agents.webp";
    const sixSenseBhutanTourPackagesImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Six-Sense-Bhutan-Tour-Packages.webp";
    const amanKoraBhutanPackagesImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Aman-Kora-Bhutan-Packages.webp";
    const flightBhutanPackagesmobile0ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Bhutan-to-Kolkata-Flight-Everyday.webp";
    const flightBhutanPackagesmobile1ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Kolkata-to-Bhutan-Flight-Everyday.webp";
    const sightseen1ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/ADVENTURE-ACTIVITIES-TO-ENJOY-DURING-A-BHUTAN-TOUR-FROM-KOLKATA (2).webp";
    const sightseen2ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/ADVENTURE-ACTIVITIES-TO-ENJOY-DURING-A-BHUTAN-TOUR-FROM-KOLKATA (3).webp";
    const sightseen3ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/ADVENTURE-ACTIVITIES-TO-ENJOY-DURING-A-BHUTAN-TOUR-FROM-KOLKATA.webp";
    const sightseen4ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/FAMOUS-PLACES-IN-BHUTAN-SIGHTSEEING (2).webp";
    const sightseen5ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/FAMOUS-PLACES-IN-BHUTAN-SIGHTSEEING (3).webp";
    const sightseen6ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/FAMOUS-PLACES-IN-BHUTAN-SIGHTSEEING.webp";
    const sightseen7ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/WHY-SHOULD-BHUTAN-BE-ON-YOUR-TRAVEL-WISH-LIST (2).webp";
    const sightseen8ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/WHY-SHOULD-BHUTAN-BE-ON-YOUR-TRAVEL-WISH-LIST (3).webp";
    const sightseen9ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/WHY-SHOULD-BHUTAN-BE-ON-YOUR-TRAVEL-WISH-LIST.webp";
    const sightseen10ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/BHUTAN-CULTURE (2).webp";
    const sightseen11ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/BHUTAN-CULTURE (3).webp";
    const sightseen12ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/BHUTAN-CULTURE.webp";
    const navDay1ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/The-Gateway-of-Bhutan-by-Road-from-India.webp";
    const navDay2ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Punakha-Wangdue-and-drive-to-Paro.webp";
    const navDay3ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Tourist-sites-in-Thimphu.webp";
    const navDay4ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Excursion-tour-to-Taktshang-Monastery.webp";
    const navDay5ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/bhutan-package-tour-cost-from-mumbai-and-explore-chele-la-pass.webp";
    // const navDay6ImageURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/Excursion-tour-to-Taktshang-Monastery.webp";
    const hotPackagesDataList = this.props.HotPackagesData;
    const hotPackagesView = hotPackagesDataList.length > 0 ? (
      hotPackagesDataList.map((dataItem, i) => {
        const hotelImgURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/" + dataItem.ImageName;
        const packageURL = dataItem.Url;
        return (
          <div className="col-md-4" key={i}>
            <div className="card h-100">
              <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
              <div className="card-body">
                <h5 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}/-</span></h5>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text">{dataItem.Description}</p>
                <p>
                  <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> |
                  <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> |
                  <span><b className="sdf-blue">SDF Inc.</b></span>
                </p>
              </div>
              <div className="card-footer">
                <small className="text-muted">{dataItem.MutedText}</small>
                <Link
                  to={packageURL}
                  target="_blank"
                  className="btn btn-Warning btn-sm float-end"
                >
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div></div>
    );

    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const hotelDataList = this.props.HotelsData;
    const hotelDataView = hotelDataList.map((hotelItem, i) => {
      const hotelImageURL =
        ApiURL.BhutanTourPackageFromKolkataImageURL + "/" + hotelItem.ImageName;
      return (
        <div className="col-md-3" key={i}>
          <img
            src={hotelImageURL}
            className="rounded mx-auto d-block"
            alt={hotelItem.AllternateText}
          />
          <br />
          <span>
            <b>{hotelItem.HotelName}</b> |
            <span className="tomato">{hotelItem.HotelStar}</span>
          </span>
          <br />
          <span className="rating-box">
            <span className="star">{hotelItem.RatingBoxStar}</span>
            <b className="rating-value">{hotelItem.RatingValue}</b>
          </span>{" "}
          &nbsp;<b>{hotelItem.Reviews}</b>
        </div>
      );
    });

    const themePackageSliderList = this.props.ThemePackagesData;
    const themePackagesDataView = themePackageSliderList.map(
      (sliderItem, i) => {
        const sliderImageURL =
          ApiURL.BhutanTourPackageFromKolkataImageURL +
          "/" +
          sliderItem.ImageName;
        return (
          <div className="theme-cards-wrapper mx-2" key={i}>
            <div className="theme-card">
              <div className="image-wrapper">
                <img src={sliderImageURL} alt={sliderItem.AllternateText} />
              </div>
              <div className="card-body">
                <h5 className="card-title">{sliderItem.Title}</h5>
                <p className="card-text">{sliderItem.Description}</p>
                <Link to={"#"} className="btn btn-primary">
                  Book Now
                </Link>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        );
      }
    );

    const trendingBhutanDataList = this.props.TrendingBhutanDataList;
    const trendingBhutanDataView = trendingBhutanDataList.map(
      (sliderItem, i) => {
        const trendingBhutanImgURL = ApiURL.BhutanTourPackageFromKolkataImageURL + "/" + sliderItem.ImageName;
        return (
          <div className="theme-cards-wrapper mx-2" key={i}>
            <div className="theme-card">
              <div className="image-wrapper">
                <img src={trendingBhutanImgURL} alt="..." />
              </div>
              <div className="card-body">
                <h6 className="card-title">{sliderItem.Title}<span> &#8377; {sliderItem.PricePerNight}/-</span></h6>
                <p className="card-text">
                  <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                  <i className="fa fa-camera tomato"></i>
                </p>
                <p className="card-text"><span>{sliderItem.Description}</span></p>
                <p>
                  <span><b className="tomato">{sliderItem.NoOfTours} </b>Tours</span> |
                  <span><b className="tomato">{sliderItem.NoOfDeparture} </b>Departures</span> |
                  <span><b className="sdf-blue">SDF Inc.</b></span>
                </p>
                <div className="card-footer">
                  <small className="text-muted"><strong className="tomato">EX-IXB</strong></small>
                  <Link to={sliderItem.BookNowURL} className="btn btn-Warning btn-sm float-end">
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      }
    );

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL =
        ApiURL.BhutanTourPackageFromKolkataImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container">
                    <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center><strong>Bhutan Package Tour from Kolkata </strong> <b>Starting from NJP / Badogra</b></center></h1>
                    <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Bhutan Package Tour from Kolkata </strong> <b>Starting from NJP / Badogra</b></center>
                      <hr />
                    </h3>
                    <div className="row">
                      <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                        3 Cities:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Thimphu (2N)
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Paro (2N)
                        </a>{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Phuentsholing (2N)
                        </a>
                      </div>
                      <div className="reviews mx-2">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                    <p className="p-2">
                      A Bhutan Trip to your favourite chillout spot or a new
                      adventure, you've come to the right place!
                    </p>
                    <hr />
                    <br />
                    <h2 className="content-desktop p-2" style={{ fontSize: "1.4vw" }}> Are You Looking For <strong>Bhutan Tour Package from Kolkata</strong> in 2024 Holidays? For the best deal for <strong>Bhutan Tour Packages from Kolkata</strong><a href='tel:9883359713' class="tomato"> ☎ CALL Mr.SOUMALYA +91- 98833-59713</a></h2>
                    <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> Are You Looking For <b>Bhutan Tour Package from Kolkata</b> <a href='tel:9883359713' class="tomato">☎ CALL Mr.SOUMALYA +91- 98833-59713</a></h3>
                    <br />
                    <div className="card">
                      <div
                        className="card-header custHeaer"
                      >
                        <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> India's Largest <b>Bhutan Tour Operator</b> (Own office at
                          PARO)</h3>
                        <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> India's Largest <b>Bhutan Tour Operator</b> (Own office at
                          PARO)</h4>
                      </div>
                      <div className="card-body">
                        <p>
                          Bhutan, a beautiful country nestled in the Himalayas, is gaining popularity as a top travel destination for the 2024/25 holidays. Known for its stunning landscapes, including snow-capped mountains, lush valleys, and serene monasteries, Bhutan offers a peaceful retreat from the hustle and bustle of city life. Booking a <strong>Bhutan package tour from Kolkata</strong> allows travelers to easily explore this enchanting destination. The country's rich and well-preserved culture is celebrated through vibrant festivals like <strong>Paro Tsechu and Thimphu Tshechu</strong>, providing travelers with a unique opportunity to experience Bhutanese traditions.
                        </p>
                        <p>Adventure enthusiasts can enjoy trekking, mountain biking, and river rafting on popular routes such as the <strong>Druk Path</strong> and <strong>Jomolhari Trek</strong>. Bhutan's commitment to sustainable tourism, with its <strong>"high value, low impact"</strong> policy, ensures that the environment and cultural heritage are preserved, making it an ideal destination for eco-conscious travelers. </p>
                        <p>Additionally, Bhutan is perfect for wellness retreats, offering a tranquil environment and traditional healing practices for those looking to rejuvenate. With several <strong>Bhutan tour packages from Kolkata</strong> available, planning your trip is easy and convenient. These packages often include flights [direct Kolkata to Paro flight by Bhutan Airlines], trains [Howrah/Sealdah to Hasimara or NewALipurduar], accommodation, guided tours, and other amenities, ensuring a hassle-free travel experience.</p>
                        <p>Our <strong>Kolkata to Bhutan Trip</strong> includes premium hotels, english-speaking guides, and experienced drivers. Capture breathtaking landscapes, explore hiking trails, and indulge in deep cultural experiences.</p>
                        <p>Book your <strong>Bhutan Tour Package from Kolkata</strong> today with Adorable Vacation LLP and create lasting memories in the magical land of Bhutan!</p>
                        <p>Apart from <strong>Kolkata to Bhutan tour</strong>, <strong>Adorable Vacation LLP</strong> also offer <a href="/mumbai-special-chartered-flight" target="_blank"><b className="tomato">Special Chertered Mumbai to Bhutan </b></a>,<a href="/bhutan-tour-package-from-pune" target="_blank"><b className="tomato">Bhutan Package Tour from Pune</b></a>,<a href="/bhutan-tour-from-delhi" target="_blank"><b className="tomato"> Bhutan Package Tour from Delhi</b></a>, <a href="/bhutan-tour-package-from-mumbai" target="_blank"><b className="tomato">Bhutan Package Tour from Mumbai</b></a>, <a href="/bhutan-tour-package-from-ahemdabad" target="_blank"><b className="tomato">Bhutan Package Tour from Ahmedabad</b></a>, <a href="/bhutan-tour-from-bengaluru" target="_blank"><b className="tomato">Bhutan Package Tour from Bangalore</b></a>,<a href="/bhutan-tour-package-from-jaigaon" target="_blank"><b className="tomato"> Bhutan Package Tour from Jaigaon</b></a>, <a href="/bhutan-tour-package-from-chennai" target="_blank"><b className="tomato">Bhutan Package Tour from Chennai</b></a>,<a href="/bhutan-tour-package-from-bagdogra" target="_blank"><b className="tomato">Bhutan Package Tour from Bagdogra</b></a>,<a href="/bhutan-tour-package-from-surat" target="_blank"><b className="tomato">Bhutan Package Tour from Surat</b></a> etc at Best Price.</p>
                        <br />
                        <a href="tel://9883359713" className="btn btn-Warning">
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> DURATION FOR <strong>BHUTAN PACKAGE TOUR FROM KOLKATA</strong> : 5N | 6D</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DURATION FOR <strong>BHUTAN PACKAGE TOUR FROM KOLKATA</strong> : 5N | 6D</h4>
                </div>
                <div className="card-body">
                  <p>Places to cover in 5N | 6D <strong>Kolkata to Bhutan trip</strong> : 1N Phuentsholing | 2N Thimphu | 2N Paro</p>
                  <p>Pickup & Drop off point : Hasimara Rail Station
                  </p>
                  <p><strong>Bhutan tour package cost</strong>: Rs. 24,500/Person [Based on 6 Persons travelling together]</p>
                  <p><strong>Bhutan tour date from Kolkata</strong> : 11th October 2024</p>
                  <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen1ImageURL} alt="Sightseeing 7" />
                          <img src={sightseen2ImageURL} alt="Sightseeing 9" />
                          <img src={sightseen3ImageURL} alt="Sightseeing 8" />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <tbody>
                        <tr>
                          <td><strong>Best Bhutan Tour Packages from Kolkata</strong> Pickup & Drop</td>
                          <td>Hasimara Rail Station</td>
                        </tr>
                        <tr>
                          <td><strong>Kolkata to Bhutan Package Tour</strong> Duration</td>
                          <td>5 Nights | 6 Days</td>
                        </tr>
                        <tr>
                          <td><strong>Bhutan Tour Cost from Kolkata</strong></td>
                          <td>Rs. 24, 500 [Based on 6 Pax]</td>
                        </tr>
                        <tr>
                          <td>Kolkata to Hasimara Distance</td>
                          <td>713 km</td>
                        </tr>
                        <tr>
                          <td><strong>Kolkata to Bhutan Flight</strong> Time</td>
                          <td>01 Hours & 00 Minutes</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>CITY</th>
                          <th>ALTITUDE</th>
                          <th>CO-ORDINATES</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Thimphu</td>
                          <td>2,334 m</td>
                          <td>27.4712° N, 89.6339° E</td>
                        </tr>
                        <tr>
                          <td>Punakha</td>
                          <td>1,274 m</td>
                          <td>27.5921° N, 89.8797° E</td>
                        </tr>
                        <tr>
                          <td>Paro</td>
                          <td>2,200 m</td>
                          <td>27.4287° N, 89.4164° E</td>
                        </tr>

                        <tr>
                          <td>Chele La Pass</td>
                          <td>3,988 m</td>
                          <td>27.3698° N, 89.3466° E</td>
                        </tr>
                        <tr>
                          <td>Dochula Pass</td>
                          <td>3,100 m</td>
                          <td>27.2924° N, 89.4501° E</td>
                        </tr>
                        <tr>
                          <td>Tigers Nest Monastery</td>
                          <td>3,120 m</td>
                          <td>27.2930° N, 89.2148° E</td>
                        </tr>
                        <tr>
                          <td>Haa Valley</td>
                          <td>2,670 m</td>
                          <td>27.3333° N, 89.1833° E</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>BHUTAN TOUR COST FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BHUTAN TOUR COST FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>CATEGORY</th>
                          <th>DURATION</th>
                          <th>COST</th>
                          <th>BOOK NOW</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><strong>Bhutan Tour Cost</strong> 
                            [Land Cost ex Alipurduar]
                          </td>
                          <td>5N/6D [without Airfare]</td>
                          <td>Rs 26,599/-</td>
                          <td><a href="#" className="btn btn-success" onClick={this.onRequestCallback}>
                            Book Now
                          </a></td>
                        </tr>
                        <tr>
                          <td>
                          <strong>Bhutan Tour Cost</strong> [Land Cost ex Paro Airport]
                          </td>
                          <td>
                            5N/6D [without Airfare]
                          </td>
                          <td>
                          Rs 25,699/-
                          </td>
                          <td>
                            <a href="#" class="btn btn-success" onClick={this.onRequestCallback}>
                              Book Now
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                          <strong>Bhutan Tour Cost</strong> [ex Paro Airport]
                          </td>
                          <td>
                            7N/8D [with Airfare]
                          </td>
                          <td>
                          Rs 60,899/-
                          </td>
                          <td>
                            <a href="#" class="btn btn-success" onClick={this.onRequestCallback}>
                              Book Now
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                          <strong>Bhutan Group Tour Cost</strong> [Land Cost ex Bagdogra]
                          </td>
                          <td>
                            7N/8D [without Airfare]
                          </td>
                          <td>
                          Rs 33,399/-
                          </td>
                          <td>
                            <a href="#" class="btn btn-success" onClick={this.onRequestCallback}>
                              Book Now
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>HOW TO REACH <strong>BHUTAN FROM KOLKATA?</strong></h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HOW TO REACH <strong>BHUTAN FROM KOLKATA?</strong></h3>
                </div>
                <div className="card-body">
                  <h5><strong>OPTION 1: BY DIRECT FLIGHT FROM KOLKATA TO PARO</strong></h5>
                  <p>Bhutan Airlines offers direct flights from Kolkata Airport (CCU) to Paro International Airport (PBH) on regular basis. Here is the flight schedule available up to December 2024.</p>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>SECTOR</th>
                          <th>FLIGHT NO</th>
                          <th>DEP DAY</th>
                          <th>DEP TIME</th>
                          <th>ARR TIME</th>
                          <th>DURATION</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>CCU - PBH</td>
                          <td>B3 701</td>
                          <td>EVERYDAY</td>
                          <td>08 : 15</td>
                          <td>09 : 55</td>
                          <td>1 Hrs 10 Mins</td>
                        </tr>
                        <tr>
                          <td>PBH - CCU</td>
                          <td>B3 700</td>
                          <td>EVERYDAY</td>
                          <td>10 : 35</td>
                          <td>11 : 15</td>
                          <td>1 Hrs 10 Mins</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <h5><strong>OPTION 2: BY ROAD FROM BAGDOGRA AIRPORT (IXB)</strong></h5>
                  <p>Bagdogra Airport (IXB) is about 16 km west of Siliguri in the Darjeeling district of northern West Bengal. It is well-connected to Kolkata Airport. From Bagdogra Airport, you can reach Phuentsholing, the gateway to Bhutan, which is about 170 km away (approximately a 4-hour drive). </p>
                  <p>Guests from Kolkata can easily fly to Bagdogra Airport, where <strong>Adorable Vacation</strong> offers customized <a href="/bhutan-tour-package-from-bagdogra" target="_blank"><strong>Bagdogra to Bhutan package</strong></a>. This is one of the best ways to travel to <strong>Bhutan from Kolkata by road via Bagdogra Airport</strong>.</p>
                  <br />
                  <h5><b>FLIGHTS FROM KOLKATA AIRPORT [CCU] TO BAGDOGRA AIRPORT [IXB]</b></h5>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>AIRLINES</th>
                          <th>ORIGIN</th>
                          <th>DEPARTURE</th>
                          <th>DESTINATION</th>
                          <th>ARRIVAL</th>
                          <th>DURATION</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>AIR INDIA EXPRESS</td>
                          <td>KOLKATA [CCU]</td>
                          <td>09 : 55</td>
                          <td>BAGDOGRA [IXB]</td>
                          <td>11 : 50</td>
                          <td>1 Hrs 20 Mins</td>
                        </tr>
                        <tr>
                          <td>SPICE JET</td>
                          <td>KOLKATA [CCU]</td>
                          <td>06 : 50</td>
                          <td>BAGDOGRA [IXB]</td>
                          <td>08 : 10</td>
                          <td>1 Hrs 20 Mins</td>
                        </tr>
                        <tr>
                          <td>INDIGO</td>
                          <td>KOLKATA [CCU]</td>
                          <td>07 : 50</td>
                          <td>BAGDOGRA [IXB]</td>
                          <td>09 : 00</td>
                          <td>1 Hrs 10 Mins</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <h5><b>OPTION 3: BY TRAIN FROM HASIMARA / NEW ALIPURDUAR JUNCTION</b></h5>
                  <br />
                  <p>To travel to <strong>Bhutan from Kolkata by train</strong>, start from either Sealdah or Howrah Station. Many trains run daily between from Howrah / Sealdah to NJP / Siliguri / Hasimara / New Alipurduar Junction. The journey takes about 8-10 hours, passing through scenic paddy and corn fields, tea gardens, and pineapple orchards.</p>
                  <p>For <strong>Kolkata to Bhutan trip by road</strong>, Hasimara Station is the best option. From Sealdah you can book <b>13149 Kanchankanya Express</b>. The train leaves Sealdah Station at 20.35 hrs and reach Hasimara around 11:05 hrs. It takes approx. 14.30 hrs to cover almost 714km distance. </p>
                  <p>From Hasimara, you can easily reach nearest border town of Bhutan – Phuentsholing within 1 hrs. It is merely 20km distance. </p>
                  <p>So, book Puja special <strong>Bhutan tour package from Kolkata by train</strong> and explore the awesome beauty of this amazing small country which you have not seen before.</p>
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>TRAIN NAME</th>
                          <th>TRAIN NO</th>
                          <th>ORIGIN STN / TIME</th>
                          <th>ARRV STN / TIME</th>
                          <th>TRAIN FREQUENCY</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>KANCHANKANYA</td>
                          <td>13149</td>
                          <td>SEALDAH / 20 : 35</td>
                          <td>HASIMARA / 11 : 05</td>
                          <td>EVERYDAY</td>
                        </tr>
                        <tr>
                          <td>PADATIK EXPRESS</td>
                          <td>12377</td>
                          <td>SEALDAH / 23 : 20</td>
                          <td>NEW ALIPURDUAR / 12 : 25</td>
                          <td>EVERYDAY</td>
                        </tr>
                        <tr>
                          <td>KAMRUP EXPRESS</td>
                          <td>15959</td>
                          <td>HOWRAH JN / 18 : 30</td>
                          <td>NEW ALIPURDUAR / 08 : 42</td>
                          <td>TUESDAY, WEDNESDAY, THURSDAY, SATURDAY, SUNDAY</td>
                        </tr>
                        <tr>
                          <td>TEESTA TORSHA</td>
                          <td>13141</td>
                          <td>SEALDAH / 15 : 00</td>
                          <td>NEW ALIPURDUAR / 05 : 50</td>
                          <td>EVERYDAY</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br/>
                  <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen4ImageURL} alt="Sightseeing 7" />
                          <img src={sightseen5ImageURL} alt="Sightseeing 9" />
                          <img src={sightseen6ImageURL} alt="Sightseeing 8" />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHY ADORABLE VACATION IS BEST FOR BOOKING <strong>BHUTAN PACKAGE TOURS FROM KOLKATA</strong>
                  </h3>
                  <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY ADORABLE VACATION IS BEST FOR BOOKING <strong>BHUTAN PACKAGE TOURS FROM KOLKATA</strong></h3>
                </div>
                <div className="card-body">
                  <li><strong>Trusted Brand: Adorable Vacation LLP</strong> has been a trusted name for <strong>Bhutan tours from Kolkata</strong> since 2011.</li><br />
                  <li><strong>Authorized Agents:</strong> They are authorized selling agents for Bhutan Airlines and Druk Airlines.</li><br />
                  <li><strong>Special Airfare:</strong> As a PSA (Passenger Service Agent) of Bhutan Airlines, they offer special airfare to guests.</li><br />
                  <li><strong>Hotel Availability:</strong> They have pre-purchased hotel room inventory for the super peak season.</li><br />
                  <li><strong>Official Recognition:</strong> Adorable Vacation LLP is authorized by the <strong>Tourism Council of Bhutan (TCB)</strong>.</li><br />
                  <li><strong>Local Presence:</strong> They have their own office and employees in Thimphu, Bhutan.</li><br />
                  <li><strong>Customized Itineraries:</strong> They offer personalized tour itineraries with unique and signature programs.</li><br />
                  <li><strong>Expert Knowledge:</strong> The Company has in-depth knowledge of Bhutan's territory, hotels, local laws, and regulations.</li><br />
                  <li><strong>Assured Service:</strong> They ensure high-quality service to enhance your experience in Bhutan.</li><br />
                  <li><strong>E-Permit Processing:</strong> They process e-permits in advance for visiting different places and sightseeing.</li><br />
                  <li><strong>Personal Attention:</strong> Safe and secure personal attention during your stay in Bhutan.</li><br />
                  <li><strong>Full Responsibility:</strong> They take responsibility for the entire duration of your <strong>Bhutan tour from Kolkata.</strong></li><br />
                  <li><strong>Hassle-Free Service:</strong> Ensuring hassle-free and trustworthy services in Bhutan.</li><br />
                  <li><strong>Best Deals:</strong> Get the best deals for properties and services as per your preferences.</li><br />
                  <li><strong>Flexible Planning:</strong> You can plan a <strong>Bhutan trip from Kolkata</strong> even during weekends and holidays.</li><br />
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DISTANCE MUST KNOW FOR <strong>BHUTAN PACKAGE TOUR BOOKING FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DISTANCE MUST KNOW FOR <strong>BHUTAN PACKAGE TOUR BOOKING FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>FROM</th>
                          <th>TO</th>
                          <th>DISTANCES</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Thimphu</td>
                          <td>Punakha</td>
                          <td>74.6 KM</td>
                        </tr>
                        <tr>
                          <td>Thimphu</td>
                          <td>Phobjikha Valley</td>
                          <td>134.3 KM</td>
                        </tr>
                        <tr>
                          <td>Thimphu</td>
                          <td>Dochula Pass</td>
                          <td>23 KM</td>
                        </tr>
                        <tr>
                          <td>Paro</td>
                          <td>Thimphu</td>
                          <td>50.4 KM</td>
                        </tr>
                        <tr>
                          <td>Paro</td>
                          <td>Thimphu</td>
                          <td>116.3 KM</td>
                        </tr>
                        <tr>
                          <td>Punakha</td>
                          <td>Phobjikha Valley</td>
                          <td>81.1 KM</td>
                        </tr>
                        <tr>
                          <td>Punakha</td>
                          <td>Chime Lhakhang</td>
                          <td>9.9 KM</td>
                        </tr>
                        <tr>
                          <td>Paro City</td>
                          <td>Taksang Monastery</td>
                          <td>11.8 KM</td>
                        </tr>
                        <tr>
                          <td>Paro City</td>
                          <td>Chelela Pass</td>
                          <td>37.1 KM</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>ENTRY FORMALITIES IN BHUTAN AS PER NEW TOURISM POLICY</b></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>ENTRY FORMALITIES IN BHUTAN AS PER NEW TOURISM POLICY</b></h4>
                </div>
                <div className="card-body">

                  <li><b>No Visa Needed:</b> Indian citizens do not need a visa to visit Bhutan. However, an entry permit is required. A local tour agency like Adorable Vacation Bhutan can process these permits for your <strong>Bhutan tour from Kolkata</strong>.</li><br />

                  <li><b>Required Documents:</b> You need a valid passport (with at least 6 months of validity) or a Voter ID card for your <strong>Bhutan trip from Kolkata</strong>.</li><br />

                  <li><b>Travel Freedom:</b> With a single permit, tourists can travel beyond Thimphu and Paro.</li><br />

                  <li><b>Sustainable Development Fee (SDF):</b> Under the new Bhutan Tourism Policy 2024, all regional tourists must pay an SDF of Rs. 1200 per person per night, in addition to the package price (flights, hotels, meals, guides, transportation, and monument fees).</li><br />

                  <li><b>Online Permit Processing:</b> All permits for the <strong>Bhutan tour</strong> will be processed online.</li><br />

                  <li><b>Mandatory Hotel and Guide:</b> Booking a hotel approved by the Tourism Council of Bhutan (TCB) and having a tour guide are mandatory.</li><br />

                  <li><b>Permit Processing:</b> Online permits can be processed through a local registered tour operator or any international partners.</li><br /><br />
                  <h5>SIGHTSEEING PLACES & ENTRY FEES FOR <strong>KOLKATA TO BHUTAN PACKAGE TOUR</strong></h5>
                  <br /><br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>NAME</th>
                          <th>TOWN</th>
                          <th>START DATE</th>
                          <th>END DATE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th colspan="4"><center>PUNAKHA FESTIVALS 2024</center></th>
                        </tr>
                        <tr>
                          <td>Punakha Drubchen</td>
                          <td>Punakha</td>
                          <td>Feb 16, 2024</td>
                          <td>Feb 18, 2024</td>
                        </tr>
                        <tr>
                          <td>Punakha Tshechu</td>
                          <td>Punakha</td>
                          <td>Feb 19, 2024</td>
                          <td>Feb 21, 2024</td>
                        </tr>
                        <tr>
                          <td>Talo Tshechu</td>
                          <td>Punakha</td>
                          <td>Mar 17, 2024</td>
                          <td>Mar 19, 2024</td>
                        </tr>
                        <tr>
                          <th colspan="4"><center>THIMPHU FESTIVALS 2024</center></th>
                        </tr>
                        <tr>
                          <td>Rhododendron Festival</td>
                          <td>Thimphu</td>
                          <td>Apr 13, 2024</td>
                          <td>Apr 14, 2024</td>
                        </tr>
                        <tr>
                          <td>Tour Of the Dragon (Mountain Bike Race)</td>
                          <td>Thimphu</td>
                          <td>Sep 02, 2024</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Thimphu Drubchen</td>
                          <td>Thimphu</td>
                          <td>Sep 09, 2024</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Thimphu Tshechu</td>
                          <td>Thimphu</td>
                          <td>Sep 13, 2024</td>
                          <td>Sep 15, 2024</td>
                        </tr>
                        <tr>
                          <td>Dechenphu Tshechu</td>
                          <td>Thimphu</td>
                          <td>Oct 12, 2024</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Jhomolhari Mountain Festival</td>
                          <td>Thimphu</td>
                          <td>Oct 14, 2024</td>
                          <td>Oct 15, 2024</td>
                        </tr>
                        <tr>
                          <td>Druk Wangyel Tshechu</td>
                          <td>Thimphu</td>
                          <td>Dec 13, 2024</td>
                          <td></td>
                        </tr>
                        <tr>
                          <th colspan="4"><center>BUMTHANG FESTIVALS 2024</center></th>
                        </tr>
                        <tr>
                          <td>Tharpaling Thongdrol</td>
                          <td>Bumthang</td>
                          <td>Feb 24, 2024</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Domkhar Tshechu</td>
                          <td>Bumthang</td>
                          <td>Apr 18, 2024</td>
                          <td>Apr 20, 2024</td>
                        </tr>
                        <tr>
                          <td>Ura Yakchoe</td>
                          <td>Bumthang</td>
                          <td>Apr 20, 2024</td>
                          <td>Apr 24, 2024</td>
                        </tr>
                        <tr>
                          <td>Nimalung Tshechu</td>
                          <td>Bumthang</td>
                          <td>Jun 14, 2024</td>
                          <td>Jun 16, 2024</td>
                        </tr>
                        <tr>
                          <td>Kurjey Tshechu</td>
                          <td>Bumthang</td>
                          <td>Jun 16, 2024</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Tamshing Phala Chhoepa</td>
                          <td>Bumthang</td>
                          <td>Sep 13, 2024</td>
                          <td>Sep 15, 2024</td>
                        </tr>
                        <tr>
                          <td>Thangbi Mani</td>
                          <td>Bumthang</td>
                          <td>Sep 17, 2024</td>
                          <td>Sep 19, 2024</td>
                        </tr>
                        <tr>
                          <td>Jakar Tshechu</td>
                          <td>Bumthang</td>
                          <td>Oct 10, 2024</td>
                          <td>Oct 12, 2024</td>
                        </tr>
                        <tr>
                          <td>Prakhar Duchhoed</td>
                          <td>Bumthang</td>
                          <td>Oct 18, 2024</td>
                          <td>Oct 20, 2024</td>
                        </tr>
                        <tr>
                          <td>Nalakhar Tshechu</td>
                          <td>Bumthang</td>
                          <td>Nov 15, 2024</td>
                          <td>Nov 17, 2024</td>
                        </tr>
                        <tr>
                          <td>Jambay Lhakhang Drup</td>
                          <td>Bumthang</td>
                          <td>Nov 15, 2024</td>
                          <td>Nov 18, 2024</td>
                        </tr>
                        <tr>
                          <td>Jambay Lhakhang Singye Cham</td>
                          <td>Bumthang</td>
                          <td>Nov 15, 2024</td>
                          <td></td>
                        </tr>
                        <tr>
                          <th colspan="4"><center>TRASHIGANG FESTIVALS 2024</center></th>
                        </tr>
                        <tr>
                          <td>Trashigang Tshechu</td>
                          <td>Trashigang</td>
                          <td>Nov 09, 2024</td>
                          <td>Nov 11, 2024</td>
                        </tr>
                        <tr>
                          <td>Chhorten Kora</td>
                          <td>Trashigang</td>
                          <td>Mar 25, 2024</td>
                          <td>Apr 08, 2024</td>
                        </tr>
                        <tr>
                          <td>Gomphukora</td>
                          <td>Trashigang</td>
                          <td>Mar 17, 2024</td>
                          <td>Mar 19, 2024</td>
                        </tr>
                        <tr>
                          <th colspan="4"><center>WANGDUE PHODRANG FESTIVALS 2024</center></th>
                        </tr>
                        <tr>
                          <td>Wangdue Tshechu</td>
                          <td>Wangdue Phodrang</td>
                          <td>Sep 11, 2024</td>
                          <td>Sep 13, 2024</td>
                        </tr>
                        <tr>
                          <td>Gangtey Tshechu</td>
                          <td>Wangdue Phodrang</td>
                          <td>Sep 16, 2024</td>
                          <td>Sep 18, 2024</td>
                        </tr>
                        <tr>
                          <td>Black Necked Crane Festival</td>
                          <td>Wangdue Phodrang</td>
                          <td>Nov 11, 2024</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th colspan="4"><center>TRONGSA FESTIVALS 2024</center></th>
                        </tr>
                        <tr>
                          <td>Trongsa Tshechu</td>
                          <td>Trongsa</td>
                          <td>Dec 08, 2024</td>
                          <td>Dec 10, 2024</td>
                        </tr>
                        <tr>
                          <td>Nabji Lhakhang Drup</td>
                          <td>Trongsa</td>
                          <td>Dec 15, 2024</td>
                          <td>Dec 16, 2024</td>
                        </tr>
                        <tr>
                          <th colspan="4"><center>OTHER FESTIVALS 2024</center></th>
                        </tr>
                        <tr>
                          <td>Zhemgang Tshechu</td>
                          <td>Zhemgang</td>
                          <td>Mar 17, 2024</td>
                          <td>Mar 19, 2024</td>
                        </tr>
                        <tr>
                          <td>Gasa Tshechu</td>
                          <td>Gasa</td>
                          <td>Mar 17, 2024</td>
                          <td>Mar 19, 2024</td>
                        </tr>
                        <tr>
                          <td>Paro</td>
                          <td>Paro</td>
                          <td>Mar 21, 2024</td>
                          <td>Mar 25, 2024</td>
                        </tr>
                        <tr>
                          <td>Chukha Tshechu</td>
                          <td>Chukha</td>
                          <td>Oct 10, 2024</td>
                          <td>Oct 12, 2024</td>
                        </tr>
                        <tr>
                          <td>Royal Highland Festival (Laya)</td>
                          <td>Laya</td>
                          <td>Oct 23, 2024</td>
                          <td>Oct 24, 2024</td>
                        </tr>
                        <tr>
                          <td>Pemagatshel Tshechu</td>
                          <td>Pemagatshel</td>
                          <td>Nov 09, 2024</td>
                          <td>Nov 11, 2024</td>
                        </tr>
                        <tr>
                          <td>Mongar Tshechu</td>
                          <td>Mongar</td>
                          <td>Nov 09, 2024</td>
                          <td>Nov 11, 2024</td>
                        </tr>
                        <tr>
                          <td>Lhuentse Tshechu</td>
                          <td>Lhuentse</td>
                          <td>Dec 08, 2024</td>
                          <td>Dec 10, 2024</td>
                        </tr>
                        <tr>
                          <td>Samdrup Jongkhar Tshechu</td>
                          <td>Samdrup Jongkhar</td>
                          <td>Dec 13, 2024</td>
                          <td>Dec 15, 2024</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>REQUIRED DOCUMENTS FOR INDIAN TOURISTS TO ENTER</b> <strong> BHUTAN FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>REQUIRED DOCUMENTS FOR INDIAN TOURISTS TO ENTER</b> <strong> BHUTAN FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <p>
                    Indian tourists traveling to <strong>Bhutan from Kolkata</strong> need one of the following valid documents:
                  </p>
                  <p>1.	A valid Indian passport with at least 6 months of validity from the intended date of departure from Bhutan.</p>
                  <p>2.	A valid Voter Identity Card (Election Card) issued by the Election Commission of India.</p>
                  <a href="tel://9883359713" className="btn btn-Warning">
                    Call Now
                  </a>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>BEST TIME TO <strong>TRAVEL BHUTAN FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST TIME TO <strong>TRAVEL BHUTAN FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <p>Bhutan, nestled in the Himalayas, experiences distinct seasons: Summer, Winter, Spring, and Autumn. Each season offers unique beauty, making Bhutan a year-round destination. Here’s a guide to help you decide the best time to book your <strong>Bhutan Package Tour from Kolkata :</strong></p><br />
                  <p><b>MONSOON (JUNE TO AUGUST) :</b> Monsoon season brings heavy rains and frequent landslides, causing roadblocks and traffic issues. Travel becomes difficult, so it's best to avoid planning a <strong>Bhutan tour from Kolkata</strong> during these months.</p>
                  <p><b>AUTUMN (SEPTEMBER TO NOVEMBER) :</b> With little to no rain, the weather is pleasant and ideal for travel and sightseeing. Many important festivals like the <strong>Black-Necked Crane Festival</strong>, <strong>Paro Tshechu</strong>, and <strong>Punakha Tshechu</strong> take place during this time. Colorful festivals with dancers in vibrant masks make it a great time to visit Bhutan.</p>

                  <p><b>WINTER (DECEMBER TO FEBRUARY) :</b>  Winter offers stunning scenic beauty but can be very cold, especially in the northern regions where temperatures drop below zero. During winter cities like Thimphu, Paro, and Punakha often experience heavy snowfall. Despite the cold, festivals like <strong>Monggar Tshechu, Trashigang Tshechu, Lhuntse Tshechu, and Trongsa Tshechu</strong> are held. If you enjoy winter landscapes and festive activities, winter can also be a good time to visit.</p>
                  <p><b>SPRING (MARCH TO MAY) : </b> According to <strong>Adorable Vacation</strong> – The <strong>best DMC for Bhutan tour from Kolkata</strong>, spring is the best time for <strong>Bhutan tour plan from Kolkata</strong>. The weather is pleasant, with blooming jacaranda and rhododendron flowers decorating the valleys and streets. Clear, fog-free skies make it perfect for trekking, including the famous Jomolhari and Drukpath treks. Tourists from around the world, especially from Europe, the USA, Canada, Brazil, China, Thailand, Cambodia, and Australia, visit Bhutan during this season.</p>
                  <p>No matter the season, Bhutan offers a unique and beautiful experience. Choose the time that best fits your preferences and enjoy the wonders of the <strong>"Land of the Thunder Dragon."</strong></p>
                  <br />
                  <table className="image-table">
                    <tbody>
                      <tr>
                        <td className="image-cell">
                          <img src={sightseen7ImageURL} alt="Sightseeing 7" />
                          <img src={sightseen9ImageURL} alt="Sightseeing 9" />
                          <img src={sightseen8ImageURL} alt="Sightseeing 8" />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />
                  <br />
                  <h5><b>TEMPARATUTRE TO NOTE FOR</b> <strong>BHUTAN TOUR PLAN FROM KOLKATA</strong></h5><br />
                  <br />
                  <div id="no-more-tables">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr className="table-danger">
                          <th>MONTH</th>
                          <th>MAX TEMP</th>
                          <th>MIN TEMP</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>JANUARY</td>
                          <td>10°C</td>
                          <td>-2°C</td>
                        </tr>
                        <tr>
                          <td>FEBRUARY</td>
                          <td>14°C</td>
                          <td>0°C</td>
                        </tr>
                        <tr>
                          <td>MARCH</td>
                          <td>16°C</td>
                          <td>4°C</td>
                        </tr>
                        <tr>
                          <td>APRIL</td>
                          <td>20°C</td>
                          <td>7°C</td>
                        </tr>
                        <tr>
                          <td>MAY</td>
                          <td>22°C</td>
                          <td>13°C</td>
                        </tr>
                        <tr>
                          <td>JUNE</td>
                          <td>25°C</td>
                          <td>15°C</td>
                        </tr>
                        <tr>
                          <td>JULY</td>
                          <td>26°C</td>
                          <td>16°C</td>
                        </tr>
                        <tr>
                          <td>AUGUST</td>
                          <td>28°C</td>
                          <td>17°C</td>
                        </tr>
                        <tr>
                          <td>SEPTEMBER</td>
                          <td>23°C</td>
                          <td>15°C</td>
                        </tr>
                        <tr>
                          <td>OCTOBER</td>
                          <td>22°C</td>
                          <td>12°C</td>
                        </tr>
                        <tr>
                          <td>NOVEMBER</td>
                          <td>20°C</td>
                          <td>9°C</td>
                        </tr>
                        <tr>
                          <td>DECEMBER</td>
                          <td>18°C</td>
                          <td>7°C</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>

              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        DETAILS <span><strong>BHUTAN TOUR ITINERARY FROM KOLKATA</strong></span>
                      </h2>
                      <h4>5 Nights 6 Days <strong>Bhutan Tour Package from Hashimara</strong></h4>
                    </div>
                  </div>
                </div>


                {/* <!--end Short description--> */}
                <br />
                <div className="row">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpenExample"
                  >
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day1"
                      >
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          DAY 01: PICK UP FROM PHUNTSHOLING – TRANSFER TO THIMPHU
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <p>
                            This is the first day of this exciting <strong>Bhutan package tour from Kolkata</strong>. On arrival at Hasimara Railway Station met & greet with representative who will transfer you to Phuntsholing - The Gateway of Bhutan by Road from India. It is a thriving commercial center on the northern edge of the Indian Plains. On arrival, our Representative guide and driver from Adorable Vacation will greet you and proceed with Immigration. Please note it will take a few minutes to complete the immigration work and our guide will help you complete the work, and later on start towards Thimphu via the wonderful mystic town Gedu, which is about 8500ft above the sea and also you will come across Chukha Dam. On the way, halt at Chozum to take photographs of the confluence of two rivers of Bhutan, (Thimphu Chu and Paro Chu). Most probably you will reach Thimphu in the evening. On arrival check in at the hotel. In the evening, take a good rest and you have to adapt to the weather (Acclimatization). Overnight stay at Thimphu.
                          </p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay1ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day2"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          DAY 02: THIMPHU TO PUNAKHA DAY EXCURSION
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <p>
                            This is the 2nd day of this exciting Kolkata to Bhutan trip. After a heavy breakfast we will set out to explore Punakha – the previous summer capital of Bhutan. Thimphu to Punakha one way is almost 77 km and takes approx. 2 hrs to travel. On the way to Punakha and within city we will explore following attractions:
                          </p>
                          <p><b>Dochula View Point:</b> The first stop on the journey from Thimphu is Dochula Pass, which is about 3100 meters high and often above the clouds. It’s only an hour's drive from Thimphu (20 km). Here, you’ll find 108 memorial stupas called "Druk Wangyal Chortens." The pass offers a stunning view of the Eastern Himalayan Range. Take some time to enjoy the view, take photos, and make videos. In winter, temperatures can drop to -2°C, and the area is covered in snow, creating a beautiful, snowy landscape that many visitors find exciting.</p>
                          <p><b>Punakha Dzong:</b> Also known as the Palace of Great Happiness, Punakha Dzong is located at the meeting point of the Pho Chhu (male) and Mo Chhu (female) rivers in the Punakha-Wangdue valley. It is one of the most scenic of Bhutan’s ancient fortresses. The Dzong is connected to the mainland by a wooden bridge and holds many important relics from when Bhutan's kings ruled from this valley. Dzong means "fort," and these structures were originally built for defence during invasions. Today, they serve as administrative centers and monastic hubs. In winter, Punakha Dzong becomes the monastic capital, hosting all administrative officials and monks for six months. Although Thimphu is now the capital, Punakha Dzong still hosts significant royal events like coronations and royal weddings, making it one of the most important Dzongs in Bhutan.</p>
                          <p><b>Suspension Bridge:</b> This is a major attraction in Punakha, known for being the longest suspension bridge in Bhutan, stretching about 180 meters over the Mochu (male) river. The view from the bridge, with its clear river and surrounding mountains, is breathtaking. The bridge is decorated with prayer flags on both sides and is an excellent spot for bird watching and photography. Walking on the bridge among and viewing the scenic beauty will be a life time experience during your <strong>Bhutan tour from Kolkata</strong>.</p>
                          <p>After exploring all these wonderful places, in the evening return back to Thimphu. Evening is free for leisure. Overnight at the hotel in Thimphu.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay2ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day3"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 03: THIMPU SIGHTSEEING AND TRANSFER TO PARO
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <p>Wake up early morning and see the serene beauty of sun kissed valley of Thimphu while sipping hot tea. This is the third day of your <strong>Bhutan tour started from Kolkata</strong>.  After a heavy breakfast proceed for following tourist attractions:
                          </p>
                          <p>
                            <b>The National Memorial Chorten :</b> It is a site for evening prayer. The chorten or stupa was built in the memory of the third king, continuously circumambulated by people, murmuring mantras and spinning their prayer wheels. Construction of this landmark was the idea of Bhutan's third king, His Majesty Jigme Dorji Wangchuk ("the father of modern Bhutan") who has wished to erect monument to world peace and prosperity. Completed in 1974 after his untimely death, it serves both as a memorial to the Late King and as a monument to peace.
                          </p>
                          <br />
                          <p><b>Sakyamuni Buddha :</b> On the top of Kuensel Phodrang hill sits the statue of Sakyamuni Buddha. The 51.5 meter long bronze statue would surely attract you and the uninterrupted view of the valley would leave you spellbound. The site is also known as Buddha View Point.</p><br />
                          <p><b>Changangkha Lhakhang :</b> At Thimphu you will go across several monasteries and temples. Changangkha Lhakhang is among the most important religious structures in Thimphu. The structure dates back to the 15th century and is one of the oldest in the region. The structure is dedicated to Avalokiteshvara, the sign of compassion. You will also enjoy the grand view of Thimphu valley from here.</p><br />
                          <p><b>Takin Zoo :</b> You will find the National animal of Bhutan “Takin” in this open zoo. Takin is one of the most endangered species of the World and it is available only in Bhutan.</p>
                          <p><b>Zorig Chusum  :</b> Institute for Zorig Chusum (commonly known as the Painting School) where students undergo a 6-year training course in Bhutan's 13 traditional arts and crafts.</p>
                          <p><b>The Royal Textile Museum :</b> Thimphu also introduces visitors with the culture and heritage of Bhutan. The Royal Textile Museum at Norzin Lam is a site where you will get introduced to Bhutanese textiles, some of which date back to the 1600s.</p>
                          <p><b>Trashi Chhoe Dzong :</b> or the Fortress of the Glorious Region is another ‘must visit’ site. Situated on the west bank of Wang Chhu River, Trashi Chhoe Dzong is the formal coronation site of the fifth King. The Dzong also hosts the annual Tsechu festival. Built in 1641 by the political and religious unifier of Bhutan, Shabdrung Ngawang Namgyal, it was reconstructed in 1960s in traditional Bhutanese manner, without nails or architectural plans.</p>
                          <p>After completing all these sightseeing, proceed towards Paro. Thimphu to Paro is just 50km and takes only 1hrs drive. After reaching check-in hotel and night stay.</p>
                          <div className="content-desktop">
                            <center><img src={navDay3ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day4"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          Day 04: - PARO TIGERS NEST MONASTERY HIKING
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFour"
                      >
                        <div className="accordion-body">
                          <p>Today is the most exciting day of this <strong>Kolkata to Bhutan trip</strong>. After breakfast, start for Taktsang Hiking.</p>
                          <br />
                          <p><b>Taktsang Monastery :</b>  If you can brave the risky cliffs on a high mountain, Paro will astonish you in the form of Taktsang Monastery. After covering 1200 meters on a fierce cliff you will reach this sacred site, also known as the Tiger’s Nest. Take a deep breath after reaching the spot and then comfort your eyes and soul with the stunning view of the scenic surroundings and the valley lying underneath. The Taktsang Monastery was established by Guru Rinpoche who came to Bhutan in 747 CE. Construction of the complex was completed in 1694. Discovering the Tiger’s Nest after 3 hours of trekking is real adventure but you may also opt for a horse ride from the parking lot. </p>
                          <p>Kyichu Lhakhang : Kyichu Lhakhang, (also known as Kyerchu Temple or Lho Kyerchu) is an important Himalayan Buddhist temple situated in Lamgong Gewog of Paro District in Bhutan. The Jowo Temple of Kyichu is one of the oldest temples in Bhutan, originally built in the 7th century by the Tibetan Emperor Songsten Gampo.</p>
                          <p>Return back hotel. Evening is free for leisure. Overnight in Paro.</p>
                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay4ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day5"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 05: CHELE-LA PASS EXCURSION & TRANSFER TO PHUENTSHOLING
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingFive"
                      >
                        <div className="accordion-body">
                          <p>
                            Today morning after breakfast start for Chele-La Pass. Located at an elevation of about 3,988 meters (13,083 feet), it is one of the highest motor able pass in Bhutan. Situated about 35 kilometers from Paro, it is a popular destination for breathtaking views and a serene experience. The drive to Chele-La Pass from Paro takes around 1.5 to 2 hours, offering travelers stunning landscapes along the way.
                          </p>
                          <p>
                            At the pass, visitors are greeted with panoramic views of the surrounding valleys and the majestic Himalayas. On clear days, you can see the snow-capped peaks of Mount Jomolhari and other high mountains in Bhutan. The area around the pass is often covered with colorful prayer flags that flutter in the wind, adding a spiritual touch to the landscape.
                          </p>
                          <p>Chele-La Pass is also known for its scenic beauty, with forests of pine and rhododendron trees that bloom in vibrant colors, especially in the spring. The area is a popular spot for photography and nature walks. The high altitude means that the weather can be quite cold, even in summer, so it's advisable to dress warmly.</p>
                          <p>After a delightful excursion of this wonderful place, start descending towards Phuentsholing. Chele-La Pass to Phuentsholing is almost 170km and can takes approx. 5 hrs depending upon weather and road condition. On reaching Phuentsholing, get fresh, and enjoy the leisurely hours. Overnight here at hotel in Phuentsholing.</p>

                          <br />
                          <div className="content-desktop">
                            <center><img src={navDay5ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="day6"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          DAY 06: DEPARTURE DAY– PHUNTSHOLING TO HASIMARA RLY STN DROP FOR THE EXIT
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingSix"
                      >
                        <div className="accordion-body">
                          <p>
                            After breakfast check out from the hotel and move towards Hasimara Rly Stn. Proceed to your final destination with sweet memories of <strong>Bhutan tour</strong>.
                          </p>
                          {/* <br />
                          <div className="content-desktop">
                            <center><img src={navDay6ImageURL} alt="..." /></center>
                          </div>

                          <div className="content-mobile2">
                            <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                          </div>
                          <br /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">{<InclusionExclusion />}</div>
                </div>
              </div>

              <br />  <br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TOP 15 PLACES TO EXPLORE DURING A <strong>BHUTAN TOUR FROM KOLKATA</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>TOP 15 PLACES TO EXPLORE DURING A <strong>BHUTAN TOUR FROM KOLKATA</strong></h4>
                </div>
                <div className="card-body">
                  <li><b>1. Paro Taktsang (Tiger's Nest) :</b> Famous monastery perched on a cliff.</li><br />
                  <li><b>2. Thimphu :</b> Bhutan's capital with sights like Tashichho Dzong.</li><br />
                  <li><b>3. Punakha Valley :</b> Beautiful valley with Punakha Dzong and Chimi Lhakhang.</li><br />
                  <li><b>4. Dochula Pass :</b> Offers stunning views of the Himalayas.</li><br />
                  <li><b>5. Rinpung Dzong :</b> A fortress-monastery in Paro.</li><br />
                  <li><b>6. Chele La Pass :</b> One of the highest motorable passes in Bhutan.</li><br />
                  <li><b>7. Haa Valley :</b> Peaceful valley with traditional Bhutanese villages.</li><br />
                  <li><b>8. Buddha Dordenma Statue :</b> Huge Buddha statue in Thimphu.</li><br />
                  <li><b>9. Phobjikha Valley :</b> Known for Gangtey Monastery and Black-necked cranes.</li><br />
                  <li><b>10. National Museum of Bhutan :</b> Located in Paro, showcasing Bhutanese art and history.</li><br />
                  <li><b>11. Jigme Dorji National Park :</b> Home to diverse plants and animals.</li><br />
                  <li><b>12. Kyichu Lhakhang :</b> One of Bhutan's oldest temples.</li><br />
                  <li><b>13. Drukgyel Dzong :</b> Historical site in the Paro Valley.</li><br />
                  <li><b>14. Tamzhing Lhündrup Monastery :</b> A hidden gem in Bumthang.</li><br />
                  <li><b>15. Kurjey Lhakhang :</b> Sacred monastery complex in Bumthang.</li><br />
                </div>
              </div>
              <br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4>Bhutan Trip Related Questions</h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          Q. How is the weather in Bhutan?
                        </button>
                      </h4>
                      <div id="flush-collapseThree" className="accordion-collapse collapseShow" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Thimphu: Temperature ranges from -2.8°C in January to 22°C in summer, with 110 mm of rain.<br />
                          • High Mountains: Temperature varies from 0°C in winter to 10°C in summer, with 350 mm of rain.<br />
                          • Central Bhutan: Cool climate with warm summers and cool, dry winters.<br />
                          • Northern Bhutan: Cold winters with snow-covered peaks and cool summers due to high altitude.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                          Q. Can I use a Credit or Debit Card during my Bhutan tour?
                        </button>
                      </h4>
                      <div id="flush-collapseFour" className="accordion-collapse collapseShow" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Credit/debit card use is limited in Bhutan.<br />
                          • You can withdraw cash from ATMs using your debit card, but check with your bank if your card is valid in Bhutan.<br />
                          • Service charges may apply for ATM withdrawals.<br />
                          • It's advisable to carry Indian cash, and you can bring up to ₹25,000 per person.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                          Q. Can we use Indian currency for Bhutan trip from Kolkata?
                        </button>
                      </h4>
                      <div id="flush-collapseFive" className="accordion-collapse collapseShow" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Yes, Indian currency in small denominations (₹50, ₹100, ₹200) is accepted in Bhutan.<br />
                          • Note: ₹2000 notes are not accepted.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                          Q. Can we get vegetarian/Jain food in hotels/restaurants in Bhutan?
                        </button>
                      </h4>
                      <div id="flush-collapseSix" className="accordion-collapse collapseShow" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Yes, pure vegetarian food is available in Thimphu and Paro.<br />
                          • Nivana Restaurant in Thimphu and Paro offers Gujarati, Rajasthani, and Jain food.<br />
                          • Hotel New Grand in Thimphu serves vegetarian dishes and is a good option for vegetarians.<br />
                          • Outside Thimphu and Paro, pure vegetarian restaurants are rare, but vegetarian food is available in hotels.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                          Q. Can a woman solo traveler explore Bhutan safely?
                        </button>
                      </h4>
                      <div id="flush-collapseSeven" className="accordion-collapse collapseShow" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Bhutan is considered very safe for women travelers.<br />
                          • It's best to travel via Paro Airport rather than through Phuentsholing.<br />
                          • A tour guide and driver are required for regional tourists (Indian, Maldivian, Bangladeshi).<br />
                          • You can request a female guide if preferred.<br />
                          • Avoid traveling at night and be cautious of unfamiliar situations.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEight">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                          Q. What is the Sustainable Development Fee (SDF) and how does it affect Indian tourists?
                        </button>
                      </h4>
                      <div id="flush-collapseEight" className="accordion-collapse collapseShow" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Bhutan's tourism policy is "Low Volume, High Value."<br />
                          • Indian tourists must pay an SDF of ₹1200 per person per night, excluding tour package costs (flights, hotels, meals, guides, transportation, monument fees).<br />
                          • This increases the cost but ensures quality tourism.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingNine">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                          Q. How are the medical facilities in Bhutan?
                        </button>
                      </h4>
                      <div id="flush-collapseNine" className="accordion-collapse collapseShow" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Limited medical facilities, with specialist doctors mainly available at Thimphu General Hospital.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTen">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                          Q. What is the baggage weight limit on Druk Air?
                        </button>
                      </h4>
                      <div id="flush-collapseTen" className="accordion-collapse collapseShow" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Economy class: 20 kg<br />
                          • Business class: 30 kg<br />
                          • Additional 5 kg for carry-on luggage.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEleven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                          Q. What is the currency of Bhutan?
                        </button>
                      </h4>
                      <div id="flush-collapseEleven" className="accordion-collapse collapseShow" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Ngultrum (Nu, BTN) is the official currency.<br />
                          • 1 Ngultrum is equal to 1 Indian Rupee.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwelve">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                          Q. What is the national game of Bhutan?
                        </button>
                      </h4>
                      <div id="flush-collapseTwelve" className="accordion-collapse collapseShow" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Archery is the national game.<br />
                          • You can try archery during your Bhutan tour with bamboo, recurve, or compound bows.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThirteen">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                          Q. What is the national bird of Bhutan?
                        </button>
                      </h4>
                      <div id="flush-collapseThirteen" className="accordion-collapse collapseShow" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • The Raven is the national bird and is featured in the Royal Crown, representing the deity Gonpo Jarodongchen.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFourteen">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourteen" aria-expanded="false" aria-controls="flush-collapseFourteen">
                          Q. What is the national flower of Bhutan?
                        </button>
                      </h4>
                      <div id="flush-collapseFourteen" className="accordion-collapse collapseShow" aria-labelledby="flush-headingFourteen" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • The Blue Poppy (Himalayan Blue Poppy) is the national flower, found in high-altitude regions.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFifteen">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFifteen" aria-expanded="false" aria-controls="flush-collapseFifteen">
                          Q. What is the language of Bhutan?
                        </button>
                      </h4>
                      <div id="flush-collapseFifteen" className="accordion-collapse collapseShow" aria-labelledby="flush-headingFifteen" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                          • Dzongkha is the national language.<br />
                          • Over two dozen languages are spoken, with Nepali being one of the major ones.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Bhutan <span className="tomato">Tour Reviews</span>
                        </b>
                      </h3>
                      <h4>
                        <i>"Your Experience is our Marketing"</i>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <BookingBox />
            </div>
            <div className="modal" style={{ display: this.state.showModal ? "block" : "none" }}>
                                <div className="modal-dialog">
                                    <div className="modal-content cust-modContent">
                                        <div className="modal-header">
                                            <h4 className="modal-title ms-auto" id="exampleModalLabel">Get a Free Callback &nbsp;&nbsp;<i className="fa fa-phone fa-rotate-90"></i>
                                            </h4>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.onCloseModal}></button>
                                        </div>
                                        <div className="modal-body">
                                            <form className="row g-3">
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name *"
                                                        name="firstName"
                                                        value={this.state.firstName}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                {/* <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Last Name *"
                                                        name="lastName"
                                                        value={this.state.lastName}
                                                        onChange={this.onChangeHandler} />
                                                </div> */}
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="10 Digit Mobile No.*"
                                                        name="mobile"
                                                        value={this.state.mobile}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Email *"
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Comments"
                                                        rows="2"
                                                        name="comments"
                                                        value={this.state.comments}
                                                        onChange={this.onChangeHandler}></textarea>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <div className='container'>
                                                <div className="row">
                                                    <div className='col-sm-4'>
                                                        {
                                                            (this.state.showLoader) &&
                                                            <div className='pull-left'><Digital size={25} /></div>
                                                        }
                                                    </div>
                                                    <div className='col-sm-8 '>
                                                        <div className='pull-right'>
                                                            <button type="button" className="btn btn-Warning mr-1" onClick={this.onCloseModal}>Close</button>
                                                            <button type="button" className="btn btn-Warning" onClick={this.onRequestCallbackSubmit} >Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
          </div>
        </div>
      </>
    );
  }
}
export default KolkataToBhutanPackage;
