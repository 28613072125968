import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from './InclusionExclusion_kashmir_kolkata';

class Kashmir_7N_8D_Package_Tour_From_Kolkata extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Kashmir-Tour-Package-from-Kolkata.webp";
        const packageTopBanner2ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Kashmir-Package-Tour-from-Kolkata.webp";
        const sightseen1ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Kashmir-by-Train-from-Kolkata.webp";
        const sightseen2ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Kolkata-to-Kashmir-Package-Tour -Cost.webp";
        const sightseen3ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Kashmir-Package-Tour-from-Kolkata-Cost.webp";
        const sightseen4ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Kolkata-to-Kashmir-Tour-Packages.webp";
        const sightseen5ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Kashmir-Tour-Package-Booking-From-Kolkata.webp";
        const sightseen6ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Kashmir-Tour-Packages-itinery-from-Kolkata.webp";
        const sightseen7ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Kashmir-Trip.webp";
        const sightseen8ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Kolkata-to-Kashmir-Package-Booking.webp";
        const sightseen9ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/kashmir-package-tour-itinerary-from-srinagar-airport.webp";
        const sightseen10ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/kashmir-tour.webp";
        const sightseen11ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/kashmir-packages-from-srinagar-airport.webp";
        const sightseen12ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/kashmir-package-tour-cost-from-srinagar.webp";
        //day wise itenaryy
        const navDay1ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/7N-8D-Kashmir-Tulip-Festival-Package-Tour.webp";
        const navDay2ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/2024-Tulip-Garden-Kashmir-Package.webp";
        const navDay3ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Customized-Kashmir-Tour.webp";
        const navDay4ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Tulip-Festival-in-Kashmir.webp";
        const navDay5ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Srinagar-Tulip-Garden-Tour.webp";
        const navDay6ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Tulip-Garden-Tour-Package.webp";
        const navDay7ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Book-Kashmir-Tulip-Trip.webp";
        const navDay8ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Tulip-Festival-Special-2024.webp";
        const Topbanner0ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Kashmir-Houseboat-Packages.webp";
        const Topbanner1ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Kashmir-Adventure-Tour-Plan.webp";
        const Topbanner2ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Kashmir-Gondola-Package-Tour.webp";
        const Topbanner3ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Kashmir-Trekking-Packages.webp";
        const Topbanner4ImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/Kashmir-White-River-Rafting-Plan.webp";
        const brandedTourackageImage_1_URL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/The-Khyber-Himalayan-Resort-&-Spa.webp";
        const brandedTourackageImage_2_URL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/The-LaLiT-Grand-Palace-Srinagar-Hotel.webp";
        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Kashmir_Tour_Package_from_Kolkata + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>

                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">

                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>BOOK YOUR KASHMIR PACKAGE TOUR FROM KOLKATA </strong></center></h1>

                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>BOOK YOUR KASHMIR PACKAGE TOUR FROM KOLKATA </strong></center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Destination:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Srinagar (3N)
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Gulmarg (1N)
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Pahalgam (3N)
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Kashmir Package Tour From Kolkata</strong>, you have come to the right place!
                                        </p>
                                        <hr />
                                        <br />
                                    </div>
                                    <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}><b>SEARCHING FOR KASHMIR TOUR PACKAGES FROM KOLKATA?</b><a href='tel:9147061467' class="tomato"> FOR BOOKING KASHMIR TOUR PACKAGE FROM KOLKATA ☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}><b>SEARCHING FOR KASHMIR TOUR PACKAGES FROM KOLKATA?</b><a href='tel:9147061467' class="tomato"> FOR BOOKING KASHMIR TOUR PACKAGE FROM KOLKATA ☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Experience the enchantment of Kashmir with our exclusive tour package from kolkata</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Experience the enchantment of Kashmir with our exclusive tour package from kolkata</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Discover the magic of Kashmir with our carefully crafted <strong>Kashmir Tour from Kolkata</strong>, brought to you by Adorable Vacation, your trusted travel specialist. We offer a comprehensive experience designed to immerse you in the unparalleled beauty and rich culture of this stunning destination.</p>
                                            <p>Begin your journey in Srinagar, the heart of Kashmir, where you’ll be greeted by lush gardens and serene waterways. Explore the famous Dal Lake on a traditional houseboat, and take in the picturesque views of the surrounding mountains. Our <strong>Kashmir Package Tour from Kolkata</strong> ensures a comfortable stay and includes guided tours of the city's key attractions.</p>
                                            <p>Venture to Gulmarg, renowned for its breath-taking landscapes and adventure opportunities. Whether you're interested in skiing, golf, or simply enjoying the panoramic views, Gulmarg offers something for everyone. Our <strong>Kashmir Trip from Kolkata</strong> provides ample time to explore and enjoy this picturesque locale.</p>
                                            <p>Next,<strong>Kashmir Houseboat Tour</strong> experience the natural beauty of Pahalgam, a charming town known for its lush meadows and serene environment. Enjoy leisurely walks along the banks of the Lidder River and explore the surrounding scenic beauty.<strong>Kashmir Tour from Kolkata during Summer holidays</strong> We include visits to the region's top spots, ensuring you make the most of your time here.</p>
                                            <p>Our <strong>Kashmir tour packages</strong> are designed to offer a balance of relaxation and exploration. We provide comfortable accommodations, reliable transportation, and expert guidance throughout your journey. Our goal is to make your experience as enjoyable and stress-free as possible.</p>
                                            <p>Adorable Vacation takes pride in offering personalized service and attention to detail. We tailor our <strong>7N 8D Kashmir Tour Itinerary from Kolkata</strong> to meet your needs and preferences, ensuring that every aspect of your trip is handled with care and expertise.</p>
                                            <p>In addition to sightseeing, our <strong>Kolkata to Kashmir Package</strong> include opportunities to experience local culture and cuisine. Enjoy traditional Kashmiri dishes and immerse yourself in the region’s vibrant traditions and customs.</p>
                                            <p>Whether you're traveling with family, friends, or on your own, our <strong>Kashmir tour packages from Kolkata</strong> are designed to offer a memorable and enriching experience. With Adorable Vacation, you can trust that every detail of your trip will be expertly managed.</p>
                                            <p>Book your Kashmir adventure with us today and embark on a journey to one of India’s most breath-taking destinations. Let Adorable Vacation make your dream trip a reality with our exceptional service and dedication.</p>
                                            <p><b>Apart from that, we also provide </b></p>
                                            <a href='/kashmir-offbeat-package-tour-with-baisaran-valley-doodhpathri-yusmarg' target='_blank'><b className="tomato">Kashmir Package Tour with Doodhpathri Yusmarg</b></a>, <a href='/kashmir-offbeat-package-tour-with-gurez-valley' target='_blank'><b className="tomato">Kashmir Package Tour with Gurez Valley</b></a>,
                                            <a href='/kashmir-offbeat-package-tour-with-sinthan-top-aru-valley-betab-valley-chandanbari-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Offbeat Package Tour with Sinthan Top</b></a>,
                                            <a href='/kashmir-package-tour-with-vaishno-devi-katra-pahalgam-gulmarg-srinagar' target='_blank'><b className="tomato">Kashmir Package Tour with Vaishno Devi</b></a>,
                                            <a href='/kashmir-tulip-garden-festival-package-tour' target='_blank'><b className="tomato">Kashmir Package Tour with Tulip Package</b></a>,
                                            <a href='/kashmir-package-tour-from-surat' target='_blank'><b className="tomato">Kashmir Package Tour from Surat</b></a>
                                            <br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen1ImageURL} alt="kashmir tour packages" />
                                                            <img src={sightseen2ImageURL} alt="kashmir trip from srinagar airport" />
                                                            <img src={sightseen3ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /> <br /> <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>DURATION FOR KASHMIR PACKAGE FROM KOLKATA : 7N | 8D</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>DURATION FOR KASHMIR PACKAGE FROM KOLKATA : 7N | 8D</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p><strong>Itinerary for Kashmir Package Tour From Kolkata</strong> : Srinagar Houseboat 1N | Gulmarg 1N | Pahalgam 2N | Srinagar 3N </p>
                                            <p>Pickup <b>Kashmir Tour Packages</b> : Jammu Tawi Railway Station (JAT) / Jammu Airport (IXJ)
                                            </p>
                                            <p><b>Pickup & Drop Location:</b> Sheikh Ul Alam International Airport, Srinagar [SXR]</p>
                                            <br /><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>Pickup & Drop Location</strong></td>
                                                            <td>Sheikh Ul Alam International Airport, Srinagar [SXR]</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Kolkata to Kashmir Package Duration</strong></td>
                                                            <td>07 Nights | 08 Days</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Kolkata to Kashmir Package Tour Cost </strong></td>
                                                            <td>Rs. 28,100/Based on 8 Pax</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Srinagar</strong></td>
                                                            <td>1,585 m | Coordinates - 34.0837° N, 74.7973° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Gulmarg</strong></td>
                                                            <td>2,650 m | Coordinates - 34.0484° N, 74.3805° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Sonmarg</strong></td>
                                                            <td>2,730 m | Coordinates - 34.3032° N, 75.2931° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Pahalgam</strong></td>
                                                            <td>2,740 m | Coordinates - 34.0161° N, 75.3150° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Betaab Valley</strong></td>
                                                            <td>2,393 m | Coordinates - 34.0541° N, 75.3639° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Aru Valley</strong></td>
                                                            <td>2,414 m | Coordinates - 34.0922° N, 75.2632° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Chandanwadi</strong></td>
                                                            <td>3,288 m | Coordinates - 34.2122° N, 73.9295° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Doodhpathri</strong></td>
                                                            <td>2,730 m | Coordinates - 33.8509° N, 74.5630° E</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br /><br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen4ImageURL} alt="kashmir package tour from srinagar" />
                                                            <img src={sightseen5ImageURL} alt="kashmir package price from srinagar airport" />
                                                            <img src={sightseen6ImageURL} alt="kashmir packages from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />

                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    {/* start short itinerary */}

                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr className="table-danger">
                                                        <th>DAY-WISE SUMMARY FOR KASHMIR PACKAGE TOUR FROM KOLKATA</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingOne"
                                                        >
                                                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                                <span>
                                                                    ARRIVE IN SRINAGAR
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingTwo"
                                                        >
                                                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                                <span>TAKE A FULL-DAY EXCURSION FROM SRINAGAR TO GULMARG </span>
                                                            </a>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingThree"
                                                        >
                                                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                                <span>TRAVEL FROM GULMARG TO PAHALGAM </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFour"
                                                        >
                                                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                                <span>EXPLORE PAHALGAM WITH VISITS TO BETAB VALLEY AND MORE</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFive"
                                                        >
                                                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                                <span>RETURN TO SRINAGAR FOR SIGHTSEEING AND AN OVERNIGHT STAY</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSix"
                                                        >
                                                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                                <span>ENJOY A DAY TRIP TO DOODHPATHRI AND SPEND THE NIGHT IN SRINAGAR</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSeven"
                                                        >
                                                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                                <span>TAKE A DAY TRIP TO SONMARG </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingEight"
                                                        >
                                                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                                                                <span>DEPART FROM SRINAGAR AIRPORT </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <!--end Short description--> */}
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>WHAT ARE THE MAIN ATTRACTIONS ON A KASHMIR TOUR PLAN FROM KOLKATA?</b></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>WHAT ARE THE MAIN ATTRACTIONS ON A KASHMIR TOUR PLAN FROM KOLKATA?</b></h4>
                                        </div>
                                        <div className="card-body">

                                            <p>During the <strong>Kashmir tour from Kolkata</strong>, booked with Adorable Vacation, the <strong>Kashmir Package Tour Operator in Kolkata</strong>, India, you will explore the top attractions of this beautiful region. Some of the must-visit places include Srinagar, Gulmarg, Sonmarg, Pahalgam, and the famous Mughal Gardens. You will also visit Dal Lake, Manasbal Lake, Shalimar Gardens, Nishat Gardens, Chashme Shahi, Badamwari Garden, Pari Mahal, Shankaracharya Temple, Vaishno Devi Temple, Hazratbal Shrine, Hari Parbat, and Kheer Bhawani Temple. Other scenic spots include Betaab Valley, Yusmarg, Aru Valley, Amarnath Temple, Gurez, Doodhpathri, Bangus Valley, Patnitop, Poonch, Bhimgarh Fort, and Ramnagar Fort.</p>
                                            <p>A special highlight of the <strong>Kolkata to Kashmir Package Booking</strong> during the summer holidays is the <a href='/kashmir-tulip-garden-festival-package-tour' target='_blank'></a>Kashmir Tulip festival Package Tour. This vibrant festival takes place in the spring, from March 19 to April 15, 2024, when tulips are in full bloom. The festival is held at the Indira Gandhi Memorial Tulip Garden (Siraj Bagh) in Srinagar, the largest tulip garden in Asia. Visitors can walk through the beautifully landscaped garden and enjoy a stunning display of various tulip varieties.</p>
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen10ImageURL} alt="kashmir package tour booking from srinagar" />
                                                            <img src={sightseen11ImageURL} alt="kashmir package tour booking from srinagar airport" />
                                                            <img src={sightseen12ImageURL} alt="kashmir package tour cost from srinagar" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>KASHMIR TULIP FESTIVAL</strong> <b>2025: DATES, TIMINGS, AND TICKET INFORMATION</b></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>KASHMIR TULIP FESTIVAL</strong> <b>2025: DATES, TIMINGS, AND TICKET INFORMATION</b></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Highlights</th>
                                                            <th>Tulip Festival Details</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Highlights">Festival Dates</td>
                                                            <td data-title="Festival Details">March 19 - April 20, 2025</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Highlights">Festival Location</td>
                                                            <td data-title="Festival Details">Indira Gandhi Memorial Tulip Garden, Srinagar, J&K</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Highlights">Festival Timings</td>
                                                            <td data-title="Festival Details">10:00 AM - 7:30 PM Daily</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Highlights">Ticket Fees</td>
                                                            <td data-title="Festival Details">INR 50 per adult, INR 25 per child</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>KASHMIR BY TRAIN FROM KOLKATA</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>KASHMIR BY TRAIN FROM KOLKATA</h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Train No</th>
                                                            <th>Train Name</th>
                                                            <th>Dep. Stn</th>
                                                            <th>Dep. Time</th>
                                                            <th>Arr. Stn</th>
                                                            <th>Arr. Time</th>
                                                            <th>Travel Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Train No">22317</td>
                                                            <td data-title="Train Name">Humsafar Express</td>
                                                            <td data-title="Dep. Stn">SDAH (Sealdah)</td>
                                                            <td data-title="Dep. Time">13:10</td>
                                                            <td data-title="Arr. Stn">Jammu Tawi (JAT)</td>
                                                            <td data-title="Arr. Time">23:30</td>
                                                            <td data-title="Travel Time">34h 20m</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Train No">12318</td>
                                                            <td data-title="Train Name">Himgiri Express</td>
                                                            <td data-title="Dep. Stn">HWH (Howrah)</td>
                                                            <td data-title="Dep. Time">23:55</td>
                                                            <td data-title="Arr. Stn">Jammu Tawi (JAT)</td>
                                                            <td data-title="Arr. Time">12:30</td>
                                                            <td data-title="Travel Time">36h 35m</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Train No">13151</td>
                                                            <td data-title="Train Name">Jammu Tawi Express</td>
                                                            <td data-title="Dep. Stn">KOAA (Kolkata)</td>
                                                            <td data-title="Dep. Time">11:45</td>
                                                            <td data-title="Arr. Stn">Jammu Tawi (JAT)</td>
                                                            <td data-title="Arr. Time">09:55</td>
                                                            <td data-title="Travel Time">46h 10m</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>DISTANCES TO NOTE FOR KOLKATA TO KASHMIR TOUR PACKAGES</b></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>DISTANCES TO NOTE FOR KOLKATA TO KASHMIR TOUR PACKAGES</b></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>From</th>
                                                            <th>To</th>
                                                            <th>Distance</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="From">Kolkata</td>
                                                            <td data-title="To">Srinagar</td>
                                                            <td data-title="Distance">46 hr (2,343.4 km) via NH 44</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Delhi</td>
                                                            <td data-title="To">Srinagar</td>
                                                            <td data-title="Distance">18 hr 22 min (812.5 km) via NH 44</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Mumbai</td>
                                                            <td data-title="To">Srinagar</td>
                                                            <td data-title="Distance">42 hr (2,169.8 km) via NH 48</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Jammu</td>
                                                            <td data-title="To">Srinagar</td>
                                                            <td data-title="Distance">8 hr 14 min (266.5 km) via NH 44</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Srinagar</td>
                                                            <td data-title="To">Gulmarg</td>
                                                            <td data-title="Distance">1 hr 30 min (49.5 km) via Srinagar-Tangmarg Rd</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Srinagar</td>
                                                            <td data-title="To">Sonmarg</td>
                                                            <td data-title="Distance">2 hr 11 min (79.4 km) via NH1</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Srinagar</td>
                                                            <td data-title="To">Pahalgam</td>
                                                            <td data-title="Distance">2 hr 11 min (89.6 km) via Srinagar-Kanyakumari Hwy</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Srinagar</td>
                                                            <td data-title="To">Dras</td>
                                                            <td data-title="Distance">4 hr 2 min (141.2 km) via NH1</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Srinagar</td>
                                                            <td data-title="To">Kargil</td>
                                                            <td data-title="Distance">5 hr 19 min (202.1 km) via NH1</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="From">Srinagar</td>
                                                            <td data-title="To">Leh</td>
                                                            <td data-title="Distance">9 hr 41 min (418.5 km) via NH1</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>COST OF <strong>KASHMIR PACKAGE TOUR FROM KOLKATA</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>COST OF <strong>KASHMIR PACKAGE TOUR FROM KOLKATA</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>TOUR DURATION</th>
                                                            <th>2 PAX</th>
                                                            <th>4 PAX</th>
                                                            <th>6 PAX</th>
                                                            <th>8 PAX</th>
                                                            <th>BOOK NOW</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">4N/5D</td>
                                                            <td data-title="2 ADULTS">₹ 20,500/-</td>
                                                            <td data-title="4 ADULTS">₹ 18,300/-</td>
                                                            <td data-title="6 ADULTS">₹ 16,600/-</td>
                                                            <td data-title="8 ADULTS">₹ 16,400/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">5N/6D</td>
                                                            <td data-title="2 ADULTS">₹ 24,700/-</td>
                                                            <td data-title="4 ADULTS">₹ 22,000/-</td>
                                                            <td data-title="6 ADULTS">₹ 19,800/-</td>
                                                            <td data-title="8 ADULTS">₹ 21,800/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">6N/7D</td>
                                                            <td data-title="2 ADULTS">₹ 30,900/-</td>
                                                            <td data-title="4 ADULTS">₹ 27,400/-</td>
                                                            <td data-title="6 ADULTS">₹ 24,700/-</td>
                                                            <td data-title="8 ADULTS">₹ 24,500/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">7N/8D</td>
                                                            <td data-title="2 ADULTS">₹ 35,600/-</td>
                                                            <td data-title="4 ADULTS">₹ 30,800/-</td>
                                                            <td data-title="6 ADULTS">₹ 28,300/-</td>
                                                            <td data-title="8 ADULTS">₹ 28,100/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>DISCOVER THE KASHMIR PACKAGE TOUR FROM KOLKATA</b></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>DISCOVER THE KASHMIR PACKAGE TOUR FROM KOLKATA</b></h4>
                                        </div>
                                        <div className="card-body">
                                            <h5><b>ENTRY FEES FOR GULMARG GONDOLA RIDE</b></h5><br />
                                            <p>Any <strong>Kashmir Package Tour</strong> isn't complete without a Gondola Ride in Gulmarg. You can enjoy stunning views of the Himalayas during this ride, making it an unforgettable experience. Be sure to buy the Phase 2 ticket, as the view from there is amazing.</p>
                                            <p>During the peak season, it can be hard to get tickets because of long lines and many tourists. You can book Phase 1 tickets online, but Phase 2 tickets are not available online. It's better to do Phase 2 first, then come back to Phase 1.</p>
                                            <p><b>Ticket Prices:</b></p>
                                            <li>Phase 1 (Gulmarg to Kungdoor): ₹600 per adult or child (ages 3 to 10)</li><br />
                                            <li>Phase 2 (Kungdoor to Aparwath): ₹800 per adult or child (ages 3 to 10)</li><br />
                                            <li>Children below 3 years: Free for both phases</li><br />
                                            <li>Chair Lift (Kungdoor to Marry Shoulder): ₹300 per adult</li><br />
                                            <p>Make sure to plan ahead and enjoy the best views that the Gulmarg Gondola ride has to offer !</p>
                                            <br /> <br />
                                            <h5><b>ENTRY FEES FOR SIGHTSEEING DURING KASHMIR TOUR</b></h5>
                                            <br />

                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td>Places</td>
                                                            <td>Entry Fees</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dal Lake</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Nishant Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pari Mahal</td>
                                                            <td>Rs. 40/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shalimar Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shankaracharya Temple</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Chashme Shahi</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Verinag Garden</td>
                                                            <td>Rs. 10/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SPS Museum</td>
                                                            <td>Rs. 10 for Indian, Rs. 50 for Foreigners</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tulip Garden</td>
                                                            <td>Rs. 75 per adult, Rs. 50 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Aru Valley</td>
                                                            <td>Rs. 25/Person</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>FAMOUS LAKES TO VISIT DURING A KOLKATA TO KASHMIR TOUR PACKAGE</b></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>FAMOUS LAKES TO VISIT DURING A KOLKATA TO KASHMIR TOUR PACKAGE</b></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>When traveling to <strong>Kolkata to Kashmir Tour Plan</strong>, visiting the beautiful lakes is a must. Kashmir is home to some of the world's most famous lakes, surrounded by snow-capped mountains, chinar and pine forests, and stunning gardens filled with blooming tulips and roses. Here are the lakes you should include in your <strong>Kashmir tour package from Kolkata</strong>:</p>
                                            <p><b>1. Dal Lake</b> Dal Lake is the most popular destination in any <strong>Kashmir tour</strong>. Covering an area of approximately 22 sq km, this picturesque lake is adorned with colourful shikaras, houseboats, and a floating market. A visit to Kashmir isn't complete without a shikara ride on Dal Lake, which also includes a stop at Char Chinar, a beautiful island in the middle of the lake with four chinar trees. Depending on the season, Char Chinar offers stunning views of the lake and its surroundings. Dal Lake has also been a favorite location for Bollywood movies such as "Kashmir Ki Kali," "Kabhi Kabhie," "Noorie," "Silsila," "Dil Se," "Roja," "Fiza," "Jab Tak Hai Jaan," "Highway," "Haider," "BajrangiBhaijaan," and "Raazi." Enjoying a shikara ride while sipping on hot noon chai (also known as shir chai or gulabi chai) and tasting authentic Kashmiri food is an unforgettable experience. Dal Lake is also perfect for a romantic photoshoot and is considered one of the most romantic spots in Kashmir, especially for honeymooners. Staying in a houseboat on the calm waters of Dal Lake offers a unique and peaceful experience.
                                            </p>
                                            <p><b>2. Wular Lake</b> Located in the Bandipora district, Wular Lake is the largest lake in Jammu & Kashmir and the second-largest freshwater lake in Asia, covering an area of about 190 sq km. The lake, known locally as Wullar, is fed by the Jhelum River and is home to a wide variety of aquatic vegetation and fish. It is a lifeline for the locals and a major attraction for visitors on a <strong>Kashmir Houseboat Tour</strong>.
                                            </p>
                                            <p><b>3. Nagin Lake</b> Surrounded by majestic hills, Nagin Lake offers a pristine and serene environment that every traveller seeks. A romantic shikara ride in the lake's quiet, pollution-free waters is especially enjoyable and ideal for <strong>Kashmir Honeymoon Tour</strong>.
                                            </p>
                                            <p><b>4. Manasbal Lake</b> Located at an elevation of 1,583 meters and about 28 km from Srinagar in the Ganderbal district, Manasbal Lake is smaller than Dal Lake and Nagin Lake but is no less beautiful. It is filled with blooming lilies and lotus flowers, making it a perfect spot to spend quality time with loved ones. The lake is surrounded by beautiful gardens with seasonal flowers, tulips, and roses, making it one of the most sought-after destinations in Kashmir.
                                            </p>
                                            <p>These lakes offer unique experiences and should be part of any <strong>Kashmir tour from Kolkata</strong>.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES FROM KOLKATA</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES FROM KOLKATA</strong></h4>

                                        </div>
                                        <div className="card-body">
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Shikara Rides:</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Shikara Rides:</b> Experience the tranquility of Dal Lake with a leisurely Shikara ride During your <strong> Kashmir Tulip Festival Package Tour</strong>. Glide across the serene waters, passing by floating gardens, houseboats, and vibrant markets. Sunset and early morning rides offer particularly magical views.</p>
                                            <center> <img src={Topbanner0ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Skiing and Snowboarding:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Skiing and Snowboarding:</b> During the winter months, places like Gulmarg transform into ski resorts, attracting enthusiasts from around the world. Gulmarg offers excellent slopes for skiing, snowboarding, and heli-skiing, with breathtaking views of the surrounding Himalayan peaks.</p>
                                            <center> <img src={Topbanner1ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Gondola Ride: </b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gondola Ride: </b>
                                                Take a ride on the Gulmarg Gondola, one of the highest cable cars in the world. Enjoy panoramic views of the snow-capped peaks as you ascend to Kongdori or Apharwat Peak, where you can indulge in activities like hiking and mountain biking.Experience this Awesome Ride During your <strong>Kashmir Tulip Garden Tour</strong> with Adorable Vacation and make this as a life long memories.</p>
                                            <center> <img src={Topbanner2ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Trekking:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Trekking:</b> Kashmir is a paradise for trekkers, with numerous trails that cater to all levels of expertise. Popular trekking destinations include the Kashmir Great Lakes Trek, Tarsar Marsar Trek, and the trek to Thajiwas Glacier in Sonamarg.</p>
                                            <center> <img src={Topbanner3ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>River Rafting:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>River Rafting:</b> The Lidder River in Pahalgam offers exciting opportunities for white-water rafting. Experience the thrill of navigating through rapids while enjoying the scenic beauty of the surrounding pine forests and mountains.</p>
                                            <center> <img src={Topbanner4ImageURL} className="img-fluid" alt="..." /></center>
                                            <br /><br /><br />
                                            <a href="tel://9147061467" className="btn btn-Warning" style={{ padding: '15px 30px', fontSize: '20px' }}>CALL NOW</a>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="container-fluid navtabCustom">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-highlights" aria-selected={customActiveTab === 'tab1'}>Highlights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-flights" aria-selected={customActiveTab === 'tab2'}>Flights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-hotel" aria-selected={customActiveTab === 'tab3'}>Hotel</button>
                                                <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-sightseeing" aria-selected={customActiveTab === 'tab4'}>Sightseeing</button>
                                                <button onClick={() => this.handleCustomTabClick('tab5')} className={`tab button ${customActiveTab === 'tab5' ? 'active' : ''}`} role="tab" aria-controls="nav-meals" aria-selected={customActiveTab === 'tab5'}>Meals</button>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent" >
                                            <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                                <p>The <strong>Kashmir Package Tour</strong> is famous for its stunning landscapes, including well-known spots like Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. These places draw tourists from all over the world who come to enjoy the beautiful scenery and take part in outdoor activities.</p>
                                                <p>Planning a <strong>Kashmir Tour Pacakages From Kolkata</strong> is a great choice for travelers looking for beautiful scenery, cultural experiences, and adventure in a unique and stunning location.</p>
                                                <p>Kashmir is located in the Himalayan Mountains and is surrounded by India, Pakistan, and China. It has three main regions: Jammu, the Kashmir Valley, and Ladakh. The area is known for its snowy mountains, green valleys, winding rivers, and peaceful lakes.</p>
                                                <p>The <strong>Kashmir Package Tour From Kolkata</strong> are famous for their stunning landscapes, including places like Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. Tourists from around the world visit to enjoy the beautiful scenery and take part in outdoor activities. Kashmir has always been a popular destination, known for its natural beauty, adventure sports, religious sites, and historical landmarks. Visitors can enjoy activities like trekking, skiing, staying in houseboats, taking shikara rides, and exploring Mughal gardens and ancient temples.</p>
                                            </div>
                                            <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                                <div className="overflow-table">
                                                    <span>POPULAR FLIGHTS FOR THE KASHMIR TULIP GARDEN TRIP 2025<b> <i>(SXR)</i></b></span><br /><br />
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th>Flight Operator</th>
                                                            <th>Departure From</th>
                                                            <th>Departure Time</th>
                                                            <th>Arrival at Srinagar</th>
                                                            <th>Stopover</th>
                                                            <th>Travel Time</th>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Kolkata [CCU]</td>
                                                            <td>08:15</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>7h 00m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Mumbai [BOM]</td>
                                                            <td>09:30</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>5h 45m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SpiceJet</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>08:00</td>
                                                            <td>09:25</td>
                                                            <td>(None)</td>
                                                            <td>1h 25m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Bangalore [BLR]</td>
                                                            <td>09:30</td>
                                                            <td>14:10</td>
                                                            <td>Sri Guru Ram Dass Jee [ATQ]</td>
                                                            <td>4h 40m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Goa [GOX]</td>
                                                            <td>10:30</td>
                                                            <td>16:45</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>6h 15m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Hyderabad [HYD]</td>
                                                            <td>08:55</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>6h 20m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Chennai [MAA]</td>
                                                            <td>08:30</td>
                                                            <td>14:20</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>5h 50m</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">

                                                <div className="overflow-table">
                                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }} ><center><b>4 STAR - CERTIFIED BY 'ADORABLE' </b></center></h4>
                                                    <div id="no-more-tables">
                                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                            <thead class="cf">
                                                                <tr className="text-center">
                                                                    {/* <!-- Add the "text-center" className to center content --> */}
                                                                    <th colspan="6" style={{ fontSize: "1.3vw" }}><h6><b>3 STAR CATEGORY HOTEL CERTIFIED BY 'ADORABLE VACATION' </b></h6></th>
                                                                </tr>
                                                                <tr className="table-danger">
                                                                    <th className="text-center">Destination</th>
                                                                    <th className="text-center">Hotel Names</th>
                                                                    <th className="text-center">Hotel Type</th>
                                                                    <th className="text-center">No of Nights</th>
                                                                    <th className="text-center">Meal Plan</th>
                                                                </tr>
                                                            </thead>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Grand Fortune / Hotel Shefaf / Hotel Siddique Palace / Similar</div></td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>5N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Gulmarg</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Affarwat / Hotel Mama Palace /Gulmarg House / Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>1N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Pahalgam</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Pine Palace Resort / Hotel Fifth Season / Hotel Moonview Resort /Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>2N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                                <div className="container-fluid px-0 py-1">
                                                    <h5>TOP ATTRACTIONS TO EXPLORE DURING THE <strong>KASHMIR PACKAGE TOUR FROM KOLKATA</strong></h5><br />
                                                    <p>During the Kashmir Tulip Holiday Package from Adorable Vacation, the region offers a wealth of captivating attractions that complement the vibrant display of tulips. Here are the must-see highlights:</p><br />
                                                    <p><b className='tomato'>1.	Indira Gandhi Memorial Tulip Garden:</b> Dive into the festival's heart, where sprawling tulip beds create a riot of colours against the backdrop of the majestic Himalayas.</p>
                                                    <p><b className='tomato'>2. Srinagar's Dal Lake:</b> Take a serene shikara ride on this iconic lake, known for its houseboats and picturesque views, offering a tranquil escape from the festival's bustle.</p>
                                                    <p><b className='tomato'>3. Mughal Gardens:</b> Explore the timeless beauty of Shalimar Bagh, Nishat Bagh, and Chashme Shahi, where Mughal architecture blends with lush landscapes.</p>
                                                    <p><b className='tomato'>4. Gulmarg:</b> Discover the lush meadows and pristine surroundings of Gulmarg, perfect for a day trip with opportunities for cable car rides and panoramic views.</p>
                                                    <p><b className='tomato'>5. Pahalgam:</b> Experience the natural splendor of Pahalgam, from its scenic meadows to the picturesque Betab Valley and Aru Valley.</p>
                                                    <p><b className='tomato'>6. Sonmarg:</b> Venture to Sonmarg's breathtaking landscapes, where stunning views and serene environments make for a memorable excursion.</p>
                                                    <p><b className='tomato'>7. Doodhpathri:</b> Explore this lesser-known gem, renowned for its lush green meadows and gentle streams, offering a peaceful retreat.</p>
                                                    <p><b className='tomato'>8. Yusmarg:</b> Visit this idyllic meadow surrounded by pine forests, providing a serene spot for relaxation and picnicking.</p>
                                                    <p><b className='tomato'>9. Srinagar's Local Markets:</b> Immerse yourself in the vibrant local culture by visiting bustling markets like Lal Chowk and Polo View Market, where you can shop for traditional Kashmiri crafts and souvenirs.</p>
                                                    <p><b className='tomato'>10. Vaishno Devi Temple:</b> Take a pilgrimage to this revered shrine, nestled in the Trikuta Mountains, offering spiritual solace and panoramic views.</p>
                                                    <p><b className='tomato'>11. Shankaracharya Temple:</b> Visit this ancient temple perched on a hilltop for a blend of spiritual significance and stunning panoramic views of Srinagar.</p>
                                                    <p>The <strong>Tulip Garden Tour Package</strong> promises not only a visual feast of flowers but also a rich array of cultural and natural wonders to explore.</p>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                                <div className="overflow-table">
                                                    <h5 class="tomato">7 times Meals and 7 times Breakfast has been Provided</h5>
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th colspan="6" className="text-center">Category</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">JAIN</td>
                                                            <td class="text-center">PURE-VEG</td>
                                                            <td class="text-center">VEGAN</td>
                                                            <td class="text-center">PURE-NON-VEG</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div className="card-header custHeaer">
                                    <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>MUST-TRY KASHMIRI DISHES ON YOUR KASHMIR HOLIDAY PACKAGES FROM KOLKATA</b></h4>
                                    <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>MUST-TRY KASHMIRI DISHES ON YOUR KASHMIR HOLIDAY PACKAGES FROM KOLKATA</b></h4>
                                </div>
                                <div className="card-body">
                                    <p>When you visit Kashmir, be sure to try these popular local dishes:</p>
                                    <ul>
                                    <li>•	Rogan Josh: A flavorful lamb curry with a rich, spicy sauce.</li><br />
                                    <li>•	Gushtaba: Tender meatballs cooked in a creamy, spiced yogurt sauce.</li><br />
                                    <li>•	Kahwa: A traditional saffron tea made with green tea leaves, almonds, and spices.</li><br />
                                    <li>•	Yakhni: A yogurt-based lamb or chicken curry with aromatic spices.</li><br />
                                    <li>•	Dum Aloo: Spicy, flavorful potatoes cooked in a rich gravy.</li><br />
                                    <li>•	Tabak Maaz: Crispy fried lamb ribs with aromatic spices.</li><br />
                                    <li>•	Kashmiri Pulao: A fragrant rice dish cooked with dry fruits and saffron.</li><br />
                                    <li>•	NadruMonje: Crispy fritters made from lotus stem.</li><br />
                                    <li>•	Kahwa: A traditional saffron tea flavored with cardamom and nuts.</li><br />
                                    </ul>
                                    <p>Enjoy these delicious dishes as part of your Kashmir tour experience!</p>
                                </div>
                            </div>
                            <br /><br /><br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                DAY WISE <strong><span>KASHMIR TOUR ITINERARY FROM KOLKATA</span></strong>
                                            </h2>
                                            <h4>7 Nights 8 Days <strong>Kashmir Tour Itinerary</strong></h4>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--end Short description--> */}
                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    DAY 1  – ARRIVAL IN SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Welcome to your <strong>Kashmir package Tour From Kolkata</strong>! Upon arrival at Srinagar Sheikh Ul Alam International Airport, you will be greeted by our representative. After checking into your hotel, enjoy a welcome drink. In the evening, embark on a Shikara ride on the famous Dal Lake. Explore the floating vegetable gardens and the lake’s serene waters. As you float along, you may spot various bird species such as Kingfishers and Common Pariah Kites. Overnight stay at a houseboat.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day2"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    DAY 02: SRINAGAR TO GULMARG
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After a leisurely breakfast, check out from the houseboat and drive to Gulmarg, also known as the "Meadow of Flowers," which is about 51 kilometres away. Gulmarg sits at an altitude of 8,700 feet and is renowned for its breath-taking scenery and its status as a premier ski resort. Upon arrival, check into your hotel. Enjoy the famous Gondola Ride, one of the highest cable cars in the world. The ride takes you up to 3,979 meters, offering stunning views. You can also explore off-piste areas and enjoy skiing. Return to your hotel in the evening for an overnight stay.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day3"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 03: GULMARG TO PAHALGAM
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast, check out from the hotel and travel to Pahalgam, a 148-kilometer journey that takes around 4 to 5 hours. Known as the "Valley of Shepherds," Pahalgam is situated where the streams from Sheshnag Lake meet the Lidder River. Once a quiet shepherd’s village, it now offers breath-taking views and is a base camp for the Amarnath Pilgrimage. After checking into your hotel, spend the rest of the day relaxing or exploring the area.</p>
                                                    <br />

                                                    <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="Kashmir Tour Packages" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Packages" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day4"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 04: - PAHALGAM LOCAL SIGHTSEEING
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <p>Enjoy a full day to explore Pahalgam. You can visit Chandanwari, the starting point of the Amarnath Yatra, or Aru Valley, which is known for its picturesque meadows and trekking routes. Alternatively, visit Betaab Valley, famous for its lush green meadows and clear streams, or Baisaran Valley, which resembles European resorts with its dense pine forests and scenic views. Return to your hotel in the evening.</p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay4ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day5"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 05: PAHALGAM TO SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After an early breakfast, check out from your hotel and drive back to Srinagar. Visit the Hazratbal Shrine and the Shankaracharya Temple. In the afternoon, explore the Mughal Gardens: Nishat Garden, Shalimar Garden, and Chashmashahi. These gardens, built by Mughal emperors, are known for their beauty and historical significance. Return to your Srinagar hotel in the evening for an overnight stay.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day6"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 06: SRINAGAR TO DOODHPATHRI AND BACK
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, take a day trip to Doodhpathri, located 42 kilometers from Srinagar. Known as the "Valley of Milk," Doodhpathri is famous for its lush meadows and cold, milky streams. The area is covered with wildflowers in spring and summer. Return to Srinagar in the evening for an overnight stay.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay6ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day7"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 07: SRINAGAR TO SONMARG AND BACK
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, check out from your hotel and head to Sonmarg, meaning "Meadow of Gold." The 87-kilometer drive offers stunning views of forests and mountains. Sonmarg is a gateway to trekking routes and glaciers. You can hire ponies or vehicles to visit Thajiwas Glacier, a major attraction. Return to Srinagar in the evening for your last overnight stay.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay7ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day8"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 08:DEPARTURE FROM SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTen"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Your <strong>Kashmir Tour From Kolkata</strong> concludes today. After breakfast, you will be transferred to the Domestic Airport for your flight to your next destination. Thank you for traveling with us, and we hope you had a memorable experience.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay8ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h2>The Khyber Himalayan Resort & Spa <span>- Gulmarg</span></h2>
                                            <h4><i>Next Level Luxury Resort </i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_1_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Platinum Accommodation</td>
                                                <td data-title="Size(Room)">386+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Khyber Hotels (Heritage)<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 53,119/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>HEAVEN IS CLOSER THAN YOU CAN IMAGINE</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>HEAVEN IS CLOSER THAN YOU CAN IMAGINE</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>8,825 feet</b> above sea level in the Pir Panjal range of the Himalayas lies a higher order of luxury. <b>The Khyber Himalayan Resort & Spa</b> is spread over seven unspoilt, fir-laden acres, with majestic views of the Affarwat Peaks. This 85-room resort is framed at every vantage point by nature at its most spectacular. <b>The world’s highest ski lift</b>, the Gulmarg Gondola – which takes you to Kongdoori Mountain, is only a short walk away. Meadows and forests that have enchanted travellers for centuries wait to be explored by trekking enthusiasts and undying romantics. Clad in locally sourced pine and slate, the resort is built in a style true to the architectural heritage of Kashmir. In every way the indoors mirror the beauty of the outdoors. Specialty restaurants, a cigar lounge are amongst the necessary luxuries in place. Add to these other thoughtful additions like our children’s club, a mini theatre and a dedicated Activities Concierge and you will find The Khyber is a resort at par with the best in the world.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >The LaLiT Grand Place <span>- Srinagar</span></h2>
                                            <h4 ><i>Fill the Luxury</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_2_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Premium Accommodation</td>
                                                <td data-title="Size(Room)">290+ SQ.FT</td>
                                                <td data-title="Hotel Brand">The LaLiT Hotels<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 28,332/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>The LaLit Grand Palace</b> was originally built in 1901 for the royal family. It now provides luxurious rooms with views of Dal Lake and the beautiful gardens. An indoor pool, tennis courts and pampering spa treatments are also available.The tastefully designed rooms are modern with a touch of traditional charm. All have a satellite TV, minibar and free tea/coffee making facilities. It is 18 km from Srinagar Airport and 4 km from Lal Chowk city centre. Jammu Railway Station is 300 km away. Guests can enjoy a relaxing massage at Rejuve Spa Health Club, practice yoga, or play golf. The hotel also has a gym and 24-hour business center. The Chinar Garden serves North Indian cuisine, while international buffet spreads are available at The Chinar – The All Day Coffee Shop. Other dining options include Dal Bar and 24-hour room service.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>BEST TIME TO BOOK A</b><strong> KASHMIR  TOUR PACKAGES FROM KOLKATA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>BEST TIME TO BOOK A</b><strong> KASHMIR  TOUR PACKAGES FROM KOLKATA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p><b>1. Spring (March to May):</b> This is a fantastic time to visit Kashmir as the region blooms with vibrant tulips and other flowers. The weather is pleasant, and it's an excellent time for sightseeing and outdoor activities.</p>
                                    <p><b>2. Summer (June to August):</b> Ideal for those looking to escape the heat of Kolkata. The temperatures in Kashmir are mild and comfortable, making it perfect for exploring the natural beauty, gardens, and lakes. Summer is also a good time for adventure activities like trekking and paragliding.</p>
                                    <p><b>3. Autumn (September to November):</b> Kashmir is known for its stunning autumn colors, with the landscape turning into shades of red, orange, and yellow. The weather is crisp and clear, offering beautiful views and a serene atmosphere.</p>
                                    <p><b>4. Winter (December to February):</b> This is the best time for snow lovers. Kashmir transforms into a winter wonderland, and it's the perfect season for skiing, snowboarding, and experiencing the snow-capped landscapes. However, the weather can be very cold, so be prepared for snow and chilly conditions.</p>
                                    <p>For the best experience, plan and book your <strong>Kashmir Package Tour</strong> in advance based on your preferred season and activities.</p>
                                    <br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen7ImageURL} alt="Sightseeing 7" />
                                                    <img src={sightseen9ImageURL} alt="Sightseeing 9" />
                                                    <img src={sightseen8ImageURL} alt="Sightseeing 8" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>

                            <br /><br />
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>ESSENTIAL ITEMS TO CARRY FOR YOUR KASHMIR TOUR PLAN</b></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>ESSENTIAL ITEMS TO CARRY FOR YOUR KASHMIR TOUR PLAN</b></h4>
                                </div>
                                <div className="card-body">
                                    <p>As a traveller, being prepared for various situations is crucial. Here’s a list of essential items to bring for your <strong>Kashmir Trip Itinerary</strong>:</p>
                                    <p><b>1. Identity Proof:</b> Always carry your original ID, such as a Voter Card, Aadhaar Card, or Driving License. This is important as Kashmir is a sensitive region, and your ID will prove your nationality in case of any issues.</p>
                                    <p><b>2. Local Contacts:</b> If you have contacts in the region or nearby areas like Delhi or Haryana, inform them about your journey. Keep their addresses and phone numbers handy in case you need assistance.</p>
                                    <p><b>3. Medicines:</b> Pack basic medications for common issues like fever, headache, vomiting, and stomach upset. Include prescribed medicines, antiseptics, antibiotics, antacids, painkillers, inhalers, pain relief sprays, band-aids, bandages, and ointments.</p>
                                    <p><b>4. Warm Clothes and Jackets:</b> Depending on your travel dates, bring light woolen clothes for April to October and heavier woolens for November to March. In cold areas like Gulmarg, Sonmarg, and Pahalgam, waterproof jackets or windcheaters are also recommended.</p>
                                    <p><b>5. Trekking Shoes/Boots:</b> If traveling to icy locations during the cold season, wear high-thigh boots or gumboots to protect your legs. Alternatively, you can rent them locally.</p>
                                    <p><b>6. Dry Fruits:</b> Carry dry fruits as a backup food source. In case of unexpected delays due to landslides or other issues, dry fruits will help keep your hunger in check.</p>
                                    <p>In addition to these essentials, consider packing moisturizers, cold cream, extra bags, an umbrella, adequate money, debit/credit cards, a torch, candles, matches, a gas lighter, a small rope, a small knife, and a camera. This will ensure you are well-prepared for your journey.</p>
                                    

                                </div>
                            </div>

                            <br /><br />
                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Kashmir<span> Package Tour From Kolkata </span></h2>
                                            <h4>Related Kashmir Tour plan</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >Frequently Ask <span>Questions</span></h2>
                                            <h4 >Kashmir Package Tour From Kolkata Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" >
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion" id="accordionFlushExample">
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSix">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                                        What is included in the Kashmir tour package from Kolkata?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Our <strong>Kashmir tour package</strong> typically includes transportation, accommodation, daily meals, and guided tours to major attractions. Specific inclusions can vary based on the package.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSeven">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                        What is the best time to visit Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>The best time to visit Kashmir depends on your preferences. For snow and winter activities, December to February is ideal. For sightseeing and trekking, April to October is recommended, while the Tulip Festival takes place from March to April.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingEight">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                                        What is the duration of the Kashmir tour package?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Our <strong>Kashmir tour packages</strong> generally range from 7 to 10 days, covering popular destinations such as Srinagar, Gulmarg, Pahalgam, and Sonmarg.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingNine">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                                        What should I pack for the Kashmir tour itinerary?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Pack warm clothing, comfortable footwear, sunglasses, sunscreen, personal medications, and any necessary travel documents. Be sure to bring layers of clothing, as temperatures can vary.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                        Are there any special permits required for the Kashmir tour?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>For most tourist areas, no special permits are needed. However, some restricted or border areas may require additional permits, which will be arranged by our team if necessary.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingEleven">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                                                        Is travel insurance included in the package?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseEleven" className="accordion-collapse collapse show" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Travel insurance is not typically included in the package. We recommend purchasing travel insurance separately to cover any unforeseen circumstances.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwelve">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                                        What are the entry fees for popular attractions?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwelve" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Entry fees for attractions like Mughal Gardens and Shankaracharya Temple vary. Most attractions have nominal fees, and some are free of charge. Specific fees will be provided in your itinerary.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingThirteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                                                        How is the accommodation arranged in Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseThirteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Accommodation is arranged in quality hotels or houseboats based on the package you choose. Options include standard, deluxe, and luxury stays.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFourteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourteen" aria-expanded="false" aria-controls="flush-collapseFourteen">
                                                        Can I customize my Kashmir tour package?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFourteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingFourteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Yes, we offer <strong>customizable tour packages</strong>. You can tailor your itinerary based on your preferences and interests.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingFifteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFifteen" aria-expanded="false" aria-controls="flush-collapseFifteen">
                                                        What is the cancellation policy for the Kashmir tour package?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseFifteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingFifteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Cancellation policies vary based on the package and time of cancellation. Please review the terms and conditions provided at the time of booking or contact us for detailed information.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSixteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSixteen" aria-expanded="false" aria-controls="flush-collapseSixteen">
                                                        Are meals included in the Kashmir tour package?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSixteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingSixteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Most packages include daily breakfast and dinner. Lunch may be included in some packages or can be arranged separately.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingSeventeen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeventeen" aria-expanded="false" aria-controls="flush-collapseSeventeen">
                                                        Is transportation provided during the tour?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseSeventeen" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeventeen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>Yes, transportation is provided for sightseeing and travel between destinations as per the itinerary.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingEighteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEighteen" aria-expanded="false" aria-controls="flush-collapseEighteen">
                                                        Are there any health precautions or vaccinations required?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseEighteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingEighteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>No specific vaccinations are required for Kashmir. However, it is advisable to carry a basic first-aid kit and consult with your doctor before traveling.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingNineteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNineteen" aria-expanded="false" aria-controls="flush-collapseNineteen">
                                                        How do I book a Kashmir tour package?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseNineteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingNineteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>You can book your <strong>Kashmir tour package</strong> by contacting us directly through our website or customer service. We will assist you in selecting the best package and completing the booking process.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwenty">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwenty" aria-expanded="false" aria-controls="flush-collapseTwenty">
                                                        What safety measures are in place during the tour?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwenty" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwenty" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p>We prioritize your safety and ensure that all travel and accommodation arrangements meet high standards. Our guides are experienced and knowledgeable about local conditions.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h3><b>Kashmir <span class="tomato">Holiday Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container" >

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                    </div >
                </div >
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91470-61467">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                    </a>
                </div>

            </>
        );
    }
}

export default Kashmir_7N_8D_Package_Tour_From_Kolkata;