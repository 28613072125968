import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from './InclusionExclusion_kashmir_mumbai';

class Kashmir_7N_8D_Package_Tour_From_Mumbai extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1',
            btn: "Submit",
            showLoader: false,
            isHidden: false,
            showModal: false,
            firstName: '',
            mobile: '',
            email: '',
            comments: ''
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    onRequestCallback = (e) => {
        e.preventDefault();
        // Prevent opening a new modal if another one is already open
        if (!this.state.showModal) {
            this.setState({ showModal: true });
        }
    }


    onCloseModal = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            showModal: false,
            firstName: '',
            mobile: '',
            email: '',
            comments: ''
        });
    }

    toggleHidden = (e) => {
        e.preventDefault();
        this.setState({ isHidden: !this.state.isHidden });
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    // Function to handle form submission
    onRequestCallbackSubmit = async (e) => {
        e.preventDefault();

        const { firstName, email, mobile, comments } = this.state;

        // Validation
        if (firstName.trim() === '') {
            cogoToast.error("Full Name Required.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else if (email.trim() === '') {
            cogoToast.error("Email Required.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else if (mobile.trim() === '' || !/^\d{10}$/.test(mobile.trim())) {
            cogoToast.error("Please Insert 10 digit Mobile No.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else {
            this.setState({ btn: "Processing..", showLoader: true });
            // Proceed with form submission
            try {
                const _ref = 'yes';
                const _url = window.location.href;

                const formData = {
                    FirstName: firstName,
                    //LastName: lastName,
                    Email: email,
                    Mobile: mobile,
                    // City: city,
                    // TravelDate: travelDate,
                    // TicketBooked: ticketBookedStatus,
                    // NoOfGuests: noOfGuests,
                    // HotelCategory: hotelCategory,
                    Comments: comments,
                    Referrer: _ref,
                    URL: _url
                };

                const response = await axios.post(ApiURL.SaveCustomerEnquery, formData);
                console.log('Data submitted successfully:', response.data);
                // Optionally, reset the form state after successful submission
                this.setState({ firstName: '', email: '', mobile: '', comments: '' });

                // Optionally, show a success message to the user
                cogoToast.success("Form submitted successfully.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
                this.setState({ btn: "Submit", showLoader: false, showModal: false });
            } catch (error) {
                console.error('Error submitting data:', error);
                this.setState({ btn: "Submit", showLoader: false });
                // Optionally, show an error message to the user
                cogoToast.error("Please try again after 24 hrs. later.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
            }

        }

    };
    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Kashmir-Package-Tour-from-Mumbai.webp";
        const packageTopBanner2ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Kashmir-Tour-Packages-from-Mumbai.webp";
        const sightseen1ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Magical-Trip-to-kashmir-from-Mumbai.webp";
        const sightseen2ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Mumbai-to-Kashmir-Tour-Packages.webp";
        const sightseen3ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Kashmir-Package-Tour-Price-ex- Srinagar.webp";
        const sightseen4ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Kashmir-Tour-Itinerary-from-Mumbai.webp";
        const sightseen5ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Kashmir-Tour-Package-from-Mumbai.webp";
        const sightseen6ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Kashmir-Tour-Plan-from-Mumbai.webp";
        const sightseen7ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Kashmir-Trip-from-Mumbai.webp";
        const sightseen8ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Kashmir-from-Mumbai.webp";
        const sightseen9ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Kashmir-Honeymoon-Trip.webp";
        const sightseen10ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Kashmir-Package-Tour.webp";
        const sightseen11ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Kashmir-Package-Tour-Cost.webp";
        const sightseen12ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Customized-Kashmir-Tour-Itinary.webp";
        //day wise itenaryy
        const navDay1ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/7N-8D-Kashmir-Tulip-Festival-Package-Tour.webp";
        const navDay2ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/2024-Tulip-Garden-Kashmir-Package.webp";
        const navDay3ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Customized-Kashmir-Tour.webp";
        const navDay4ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Tulip-Festival-in-Kashmir.webp";
        const navDay5ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Srinagar-Tulip-Garden-Tour.webp";
        const navDay6ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Tulip-Garden-Tour-Package.webp";
        const navDay7ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Book-Kashmir-Tulip-Trip.webp";
        const navDay8ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Tulip-Festival-Special-2024.webp";
        const Topbanner0ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Kashmir-Houseboat-Packages.webp";
        const Topbanner1ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Kashmir-Adventure-Tour-Plan.webp";
        const Topbanner2ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Kashmir-Gondola-Package-Tour.webp";
        const Topbanner3ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Kashmir-Trekking-Packages.webp";
        const Topbanner4ImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/Kashmir-White-River-Rafting-Plan.webp";
        const brandedTourackageImage_1_URL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/The-Khyber-Himalayan-Resort-&-Spa.webp";
        const brandedTourackageImage_2_URL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/The-LaLiT-Grand-Palace-Srinagar-Hotel.webp";
        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Kashmir_Tour_Package_from_Mumbai + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>

                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">

                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>KASHMIR PACKAGE TOUR FROM MUMBAI  </strong></center></h1>

                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>KASHMIR PACKAGE TOUR FROM MUMBAI </strong></center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Destination:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Srinagar (3N)
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Gulmarg (1N)
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Pahalgam (3N)
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Kashmir Package Tour From Mumbai</strong>, you have come to the right place!
                                        </p>
                                        <hr />
                                        <br />
                                    </div>
                                    <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}><b>Looking for Kashmir Tour Packages from Mumbai ?</b><a href='tel:9147061467' class="tomato"> To book a <strong>Kashmir Package from Mumbai</strong> ☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}><b>Looking for Kashmir Tour Packages from Mumbai?</b><a href='tel:9147061467' class="tomato"> To book a <strong>Kashmir Package from Mumbai</strong> ☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Explore the Enchanting Beauty of Kashmir from Mumbai with Adorable Vacation</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Explore the Enchanting Beauty of Kashmir from Mumbai with Adorable Vacation</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Have you ever imagined a place where snow-covered mountains surround sparkling lakes, wildflowers fill the meadows, and peaceful houseboats float on calm waters? Welcome to Kashmir, known as the "Paradise on Earth," where natural beauty awaits at every corner. Our <strong>Kashmir Package Tour Price ex Srinagar</strong> will take you on a delightful journey from the bustling streets of Mumbai to the serene beauty of Kashmir.
                                            </p>
                                            <p>Kashmir’s charm comes from its diverse landscapes and rich traditions. From a quiet Shikara ride on Dal Lake in Srinagar to the snow-covered peaks of Gulmarg, perfect for skiing, every moment in Kashmir is magical. With our <strong>Kashmir adventure Tour Packages from Mumbai</strong>, you’ll visit the famous Mughal Gardens, shop in the lively markets of Srinagar, and enjoy the warm hospitality of the Kashmiri people.
                                            </p>
                                            <p>Our <strong>7 Nights 8 Days Kashmir with Gulmarg & Pahalgam excursion Package from Mumbai</strong> are designed for your convenience and comfort. Prices range from ₹15,599 to ₹35,800, so you can find an option that fits your budget. Our 7-night, 8-day <strong>Kashmir tour with Gulmarg and Pahalgam</strong> offers a perfect mix of nature and culture.
                                            </p>
                                            <p>Your journey starts in Srinagar, the summer capital of Jammu and Kashmir. Srinagar will welcome you with its rich culture and stunning scenery. The famous Dal Lake, with its clear waters and towering Chinar trees, is a must-see. Your holiday becomes even more special with a stay on a houseboat, floating peacefully on Dal Lake, surrounded by snowy mountains.</p>
                                            <p>One of the best parts of your <strong>Kashmir Package Tour</strong> is a relaxing Shikara ride on Dal Lake. Hop on a traditional wooden boat and glide through the peaceful waters, past floating gardens and lively markets. You’ll soon see why Dal Lake is called the "Jewel in the crown of Kashmir."</p>
                                            <p>Kashmir is incomplete without visiting Gulmarg, a beautiful town sitting 2,650 meters above sea level. Nature lovers and adventure seekers will love Gulmarg. A highlight of your trip is the thrilling Gondola ride, offering panoramic views of the surrounding valleys and snow-covered peaks. Whether you're skiing or simply enjoying the view, Gulmarg will leave you amazed.</p>
                                            <p>From Gulmarg, you’ll travel to the lovely village of Pahalgam, along the banks of the Lidder River. Pahalgam’s green meadows, thick forests, and snow-capped mountains offer a peaceful escape. Walk along the river, go horse riding in the meadows, or just relax in the calm surroundings.</p>
                                            <p>Another hidden gem in Kashmir is Doodhpathri, known for its unspoiled meadows, flowing streams, and stunning views. It’s the perfect place for a peaceful day, whether you’re having a picnic, trekking through forests, or simply enjoying the untouched beauty.</p>
                                            <p>For a quieter experience, visit Nagin Lake, a peaceful spot away from the crowds. Often called the "Jewel in the ring of fire," Nagin Lake is a place where you can relax by the water, take a gentle boat ride, or just enjoy the peace and quiet.</p>
                                            <p>Join us on an unforgettable trip to <strong>Kashmir Package from Mumbai</strong>, where each day brings new sights and memories. Book your <strong>Customized Kashmir Package Tour from Mumbai with Adorable Vacation</strong> today and get ready to explore one of the most beautiful places on Earth!</p>
                                            <p><b>Apart from that, we also provide,</b></p>
                                            <a href='/kashmir-offbeat-package-tour-with-baisaran-valley-doodhpathri-yusmarg' target='_blank'><b className="tomato">Kashmir Package Tour with Doodhpathri Yusmarg</b></a>, <a href='/kashmir-offbeat-package-tour-with-gurez-valley' target='_blank'><b className="tomato">Kashmir Package Tour with Gurez Valley</b></a>,
                                            <a href='/kashmir-offbeat-package-tour-with-sinthan-top-aru-valley-betab-valley-chandanbari-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Offbeat Package Tour with Sinthan Top</b></a>,
                                            <a href='/kashmir-package-tour-with-vaishno-devi-katra-pahalgam-gulmarg-srinagar' target='_blank'><b className="tomato">Kashmir Package Tour with Vaishno Devi</b></a>,
                                            <a href='/kashmir-tulip-garden-festival-package-tour' target='_blank'><b className="tomato">Kashmir Package Tour with Tulip Package</b></a>,
                                            <a href='/kashmir-package-tour-from-kolkata-with-srinagar-gulmarg-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Package Tour from Kolkata </b></a>,
                                            <a href='/kashmir-package-tour-from-surat' target='_blank'><b className="tomato">Kashmir Package Tour from Surat </b></a>,
                                            <a href='/kashmir-package-tour-from-chennai' target='_blank'><b className="tomato">Kashmir Package Tour from Chennai </b></a>,
                                            <a href='/kashmir-package-tour-from-bangalore' target='_blank'><b className="tomato">Kashmir Package Tour from Bangalore </b></a>,
                                            <a href='/kashmir-package-tour-from-pune' target='_blank'><b className="tomato">Kashmir Package Tour from Pune </b></a>
                                            <br /><br />
                                            <p>Book your dream destination with Adorable Vacation and for further information do not hesitate to contact us at +91 9147061467</p>
                                            <br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen1ImageURL} alt="kashmir tour packages" />
                                                            <img src={sightseen2ImageURL} alt="kashmir trip from srinagar airport" />
                                                            <img src={sightseen3ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /> <br /> <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>Duration of Kashmir Tour package Booking from Mumbai : 7N | 8D</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>Duration of Kashmir Tour package Booking from Mumbai : 7N | 8D</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p><strong>Places Covered in the Mumbai to Kashmir Tour Package</strong> : Srinagar Houseboat 1N | Gulmarg 1N | Pahalgam 2N | Srinagar 3N </p>
                                            <p>Pickup & Drop for <strong>Mumbai to Srinagar Sightseeing Tour Package</strong> Sheikh Ul Alam International Airport, Srinagar [SXR]</p>
                                            <p><b>Address</b>: Indra Gandhi Road, Humhama, Badgam, Srinagar, Jammu and Kashmir 190007</p>
                                            <br /><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>Mumbai to Kashmir Tour Packages </strong>Pickup & Drop</td>
                                                            <td>Sheikh Ul Alam International Airport, Srinagar [SXR]</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Mumbai to kashmir Trip Duration</strong></td>
                                                            <td>07 Nights | 08 Days</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Kashmir Tour Packages Cost </strong></td>
                                                            <td>Rs. 28,100/Based on 8 Pax</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Srinagar</strong></td>
                                                            <td>1,585 m | Coordinates - 34.0837° N, 74.7973° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Gulmarg</strong></td>
                                                            <td>2,650 m | Coordinates - 34.0484° N, 74.3805° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Sonmarg</strong></td>
                                                            <td>2,730 m | Coordinates - 34.3032° N, 75.2931° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Pahalgam</strong></td>
                                                            <td>2,740 m | Coordinates - 34.0161° N, 75.3150° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Betaab Valley</strong></td>
                                                            <td>2,393 m | Coordinates - 34.0541° N, 75.3639° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Aru Valley</strong></td>
                                                            <td>2,414 m | Coordinates - 34.0922° N, 75.2632° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Chandanwadi</strong></td>
                                                            <td>3,288 m | Coordinates - 34.2122° N, 73.9295° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Doodhpathri</strong></td>
                                                            <td>2,730 m | Coordinates - 33.8509° N, 74.5630° E</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br /><br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen4ImageURL} alt="kashmir package tour from srinagar" />
                                                            <img src={sightseen5ImageURL} alt="kashmir package price from srinagar airport" />
                                                            <img src={sightseen6ImageURL} alt="kashmir packages from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />

                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    {/* start short itinerary */}

                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr className="table-danger">
                                                        <th>DAYWISE KASHMIR TOUR ITINERARY FROM FROM MUMBAI</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingOne"
                                                        >
                                                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                                <span>
                                                                    ARRIVE IN SRINAGAR, ENJOY LOCAL SIGHTSEEING
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingTwo"
                                                        >
                                                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                                <span>TAKE A FULL-DAY TRIP TO GULMARG </span>
                                                            </a>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingThree"
                                                        >
                                                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                                <span>TRAVEL FROM GULMARG TO PAHALGAM AND STAY </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFour"
                                                        >
                                                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                                <span>EXPLORE PAHALGAM, VISITING BETAAB VALLEY AND MORE</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFive"
                                                        >
                                                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                                <span>TRANSFER BACK TO SRINAGAR</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSix"
                                                        >
                                                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                                <span>ENJOY A DAY TRIP TO DOODHPATHRI</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSeven"
                                                        >
                                                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                                <span>TAKE A DAY TRIP TO SONMARG </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingEight"
                                                        >
                                                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                                                                <span>DEPART FROM SRINAGAR AIRPORT </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <!--end Short description--> */}
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>POPULER</b> <strong>MUMBAI TO KASHMIR PACKAGE PRICE</strong> 2024</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>POPULER</b> <strong>MUMBAI TO KASHMIR PACKAGE PRICE</strong> 2024</h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Kashmir Tour Packages</th>
                                                            <th>Price (Per Person)</th>
                                                            <th>Days/Nights</th>
                                                            <th>Inclusions</th>
                                                            <th>Places to Visit</th>
                                                            <th>Book Now</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-from-bangalore' target='_blank'><b className="tomato">Kashmir Package Tour from Bangalore </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Houseboat Nightstay</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-from-pune' target='_blank'><b className="tomato">Kashmir Package Tour from Pune </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Doodh Pathri, Sonmarg</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-from-chennai' target='_blank'><b className="tomato">Kashmir Package Tour from Chennai </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Aru Valley, Betab Valley, Doodh Pathri, Sonmarg</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-from-ahmedabad' target='_blank'><b className="tomato">Kashmir Package Tour from Ahmedabad </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Doodh Pathri, Sonmarg</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-from-surat' target='_blank'><b className="tomato">Kashmir Package Tour from Surat </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Houseboat Nightstay</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-from-delhi' target='_blank'><b className="tomato">Kashmir Package Tour from Delhi </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Houseboat Nightstay</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-from-kolkata-with-srinagar-gulmarg-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Package Tour from Kolkata </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Aru Valley, Betab Valley, Doodh Pathri, Sonmarg</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>ATTRACTIONS OF MUMBAI TO JAMMU & KASHMIR TRIP</b> </h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>ATTRACTIONS OF MUMBAI TO JAMMU & KASHMIR TRIP</b> </h4>
                                        </div>
                                        <div className="card-body">
                                            <p>During your <strong>Magical Trip to kashmir from Mumbai</strong> with Adorable Vacation, you will get to visit some of the most famous and beautiful places in Kashmir. These include Srinagar, with its scenic beauty, and the popular spots like Gulmarg, Sonmarg, and Pahalgam. You will also explore the Mughal Gardens, Dal Lake, and Manasbal Lake. The tour will take you to beautiful gardens such as Shalimar Gardens, Nishat Gardens, Chashme Shahi, and Badamwari Garden.</p>
                                            <p>You will also visit historic and religious sites like Pari Mahal, Shankaracharya Temple, Vaishno Devi Temple, Hazratbal Shrine, Hari Parbat, and Kheer Bhawani Temple. For nature lovers, places like Betaab Valley, Yusmarg, Aru, Gurez, Dudhpathri, and Bangus Valley offer stunning views. The tour also includes visits to historical forts like Bhimgarh Fort and Ramnagar Fort, as well as the scenic hill station Patnitop. Each destination offers a unique experience of Kashmir's natural beauty, history, and culture.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>HOW FAR IS JAMMU & KASHMIR TOUR PACKAGES FROM MUMBAI?</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>HOW FAR IS JAMMU & KASHMIR TOUR PACKAGES FROM MUMBAI?</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Kashmir is about 2,000 kilometers away from Mumbai. If you travel by road, it can take around 2 days to reach. By flight, it takes a little over 4 hours to fly from <strong>Mumbai to Srinagar</strong>.</p>
                                            <br />
                                            <h4>TRAIN TICKETS FOR <strong>KASHMIR PACKAGES FROM MUMBAI BY TRAIN</strong></h4><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Train Name</th>
                                                            <th>Service Days</th>
                                                            <th>Classes</th>
                                                            <th>Departs From</th>
                                                            <th>Arrive Station</th>
                                                            <th>Total Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Train Name">SWARAJ EXPRESS (12471)</td>
                                                            <td data-title="Days">Sun/Mon/Thurs/Fri</td>
                                                            <td data-title="Classes">2A, 3A, SL,3E,1A</td>
                                                            <td data-title="Departs From">BANDRA TERMINUS <br />(BDTS – 11:00)</td>
                                                            <td data-title="Arrive Station">Jammu Tawi <br />(SVDK – 14:55)</td>
                                                            <td data-title="Total Time">27hr 55mins</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Train Name">VIVEK EXPRESS (19027)</td>
                                                            <td data-title="Days">Saturday</td>
                                                            <td data-title="Classes">2A, 3A, SL,3E,1A</td>
                                                            <td data-title="Departs From">BANDRA TERMINUS <br />(BDTS – 12:00)</td>
                                                            <td data-title="Arrive Station">Jammu Tawi <br />(JAT – 23:25)</td>
                                                            <td data-title="Total Time">35hr 25mins</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>FLIGHT TICKETS TO BOOK FOR</b> <strong>KASHMIR PACKAGES FROM MUMBAI BY FLIGHT</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>FLIGHT TICKETS TO BOOK FOR</b> <strong>KASHMIR PACKAGES FROM MUMBAI BY FLIGHT</strong></h4>
                                        </div>
                                        <div className="card-body">

                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Flight Name</th>
                                                            <th>Dep. Time</th>
                                                            <th>Arr. Time</th>
                                                            <th>Travel Time</th>
                                                            <th>Stop Over</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Flight Name">IndiGo 6E - 214</td>
                                                            <td data-title="Dep. Time">BOM 14:05</td>
                                                            <td data-title="Arr. Time">SXR 16:40</td>
                                                            <td data-title="Travel Time">2h 35m</td>
                                                            <td data-title="Stop Over">Non stop</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Flight Name">Akasa Air QP - 1891</td>
                                                            <td data-title="Dep. Time">BOM 13:00</td>
                                                            <td data-title="Arr. Time">SXR 15:50</td>
                                                            <td data-title="Travel Time">2h 50m</td>
                                                            <td data-title="Stop Over">Non stop</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Flight Name">SpiceJet SG - 944</td>
                                                            <td data-title="Dep. Time">BOM 05:50</td>
                                                            <td data-title="Arr. Time">SXR 08:15</td>
                                                            <td data-title="Travel Time">2h 25m</td>
                                                            <td data-title="Stop Over">Non stop</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Flight Name">SpiceJet SG - 8385</td>
                                                            <td data-title="Dep. Time">BOM 12:25</td>
                                                            <td data-title="Arr. Time">SXR 15:10</td>
                                                            <td data-title="Travel Time">2h 45m</td>
                                                            <td data-title="Stop Over">Non stop</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>BEST KASHMIR OFFBEAT HOLIDAY PACKAGES FROM MUMBAI</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BEST KASHMIR OFFBEAT HOLIDAY PACKAGES FROM MUMBAI</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Kashmir Tour Packages</th>
                                                            <th>Price (Per Person)</th>
                                                            <th>Days/Nights</th>
                                                            <th>Inclusions</th>
                                                            <th>Places to Visit</th>
                                                            <th>Book Now</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-tulip-garden-festival-package-tour' target='_blank'><b className="tomato">Kashmir Package Tour with Tulip Package</b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Tulip Garden Tour</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-offbeat-package-tour-with-gurez-valley' target='_blank'><b className="tomato">Kashmir Package Tour with Gurez Valley</b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Gurez Valley</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"> <a href='/kashmir-offbeat-package-tour-with-baisaran-valley-doodhpathri-yusmarg' target='_blank'><b className="tomato">Kashmir Package Tour with Doodhpathri Yusmarg</b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Doodh Pathri, Sonmarg, Yusmarg</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-offbeat-package-tour-with-sinthan-top-aru-valley-betab-valley-chandanbari-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Offbeat Package Tour with Sinthan Top</b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Sinthan Top, Sonmarg</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/jammu-kashmir-family-tour-packages' target='_blank'><b className="tomato">Jammu Kashmir Family Tour Packages</b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Katra, Patnitop, Pahalgam, Gulmarg, Gurez Valley, Srinagar</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-with-amritsar-katra-vaishno-devi-pahalgam-gulmarg-srinagar-sonmarg' target='_blank'><b className="tomato">Kashmir Tour Packages with Amritsar Katra Vaishno Devi </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Amritsar, Vaishno Devi Darshan, Katra, Pahalgam, Gulmarg, Sonmarg, Srinagar</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-with-vaishno-devi-katra-pahalgam-gulmarg-srinagar' target='_blank'><b className="tomato">Kashmir Tour Packages with Vaishno Devi </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Vaishno Devi Darshan, Katra, Pahalgam, Gulmarg, Srinagar</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT ARE THE FAMOUS LAKES TO EXPLORE ON YOUR <strong>KASHMIR TOUR FROM MUMBAI</strong> ?</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT ARE THE FAMOUS LAKES TO EXPLORE ON YOUR <strong>KASHMIR TOUR FROM MUMBAI</strong> ?</h4>
                                        </div>
                                        <div className="card-body">
                                            <p><b>Dal Lake </b>:
                                                Start your <strong>Kashmir tour with Dal Lake</strong>, often called the "Jewel of Kashmir." Enjoy a peaceful shikara ride among the floating gardens and houseboats, creating unforgettable memories. Our <strong>Kashmir Package Tour Operator</strong> ensure you fully experience the calm beauty of Dal Lake.
                                            </p>
                                            <p><b>Wular Lake </b> :
                                                Visit Wular Lake, the largest freshwater lake in India, surrounded by snowy mountains. Take a boat ride to enjoy the stunning views and spot a variety of birds.
                                            </p>
                                            <p><b>Nagin Lake </b>:
                                                Nagin Lake, next to Dal Lake, provides a quieter, more intimate experience. Explore its calm waters, surrounded by willow and poplar trees, with our specially crafted <strong>Kashmir from Mumbai</strong>.
                                            </p>
                                            <p><b>Surinsar Lake </b>:
                                                Located about 42 kilometers from Jammu, Surinsar Lake is one of the most scenic lakes near Jammu and a perfect addition to your tour.
                                            </p>
                                            <br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen10ImageURL} alt="kashmir tour packages" />
                                                            <img src={sightseen11ImageURL} alt="kashmir trip from srinagar airport" />
                                                            <img src={sightseen12ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT IS THE BEST TIME TO <strong>KASHMIR HOLIDAY WITH HOUSEBOAT STAY PACKAGE FROM MUMBAI</strong>?</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT IS THE BEST TIME TO <strong>KASHMIR HOLIDAY WITH HOUSEBOAT STAY PACKAGE FROM MUMBAI</strong>?</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>The best time to book a <strong>Kashmir Tour Plan from Mumbai</strong> is between March and November, from spring to autumn. During this time, Kashmir is at its most beautiful, with colorful flowers blooming, green meadows, and clear skies. The snow-capped mountains provide a peaceful and romantic backdrop, perfect for enjoying a Shikara ride on Dal Lake.</p>
                                            <p><b>SPRING [MARCH-EARLY MAY] </b>
                                                In spring, Kashmir comes to life with blooming flowers and green meadows as the snow melts. The temperature is pleasant, making it a great time for nature lovers to explore the valley as it refreshes after winter.
                                            </p>
                                            <p><b>SUMMER [EARLY MAY-LATE AUGUST] </b>
                                                During summer, the weather is mild and skies are clear, perfect for outdoor activities like trekking, golfing, and shikara rides on Dal Lake. It's an excellent time for family vacations, with a cool breeze and beautiful mountain views.
                                            </p>
                                            <p><b>AUTUMN [SEPTEMBER-NOVEMBER] </b>
                                                Autumn is magical in Kashmir, with the landscape turning red and gold as Chinar trees shed their leaves. This season offers a romantic, peaceful atmosphere, and you can also visit the famous Saffron fields of Pampore, known for their beauty and rare spices.
                                            </p>
                                            <p><b>WINTER [DECEMBER-EARLY MARCH] </b>
                                                If you love snow, visit Kashmir in winter for a snowy wonderland. The valley is covered in snow, offering activities like skiing and snowboarding. The frozen Dal Lake provides a special shikara ride experience amidst the snowy landscape.
                                            </p>
                                            <p>So, plan your <strong>Kashmir Trip From Mumbai</strong> and experience the natural beauty of this incredible destination.</p>
                                            <br /><br />
                                            <h4><b>MONTH-WISE TEMPERATURE CHART FOR KASHMIR PACKAGES FROM MUMBAI</b></h4><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Months</th>
                                                            <th>Temp. (Min - Max)</th>
                                                            <th>Season</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Months">March - May</td>
                                                            <td data-title="Temp. (Min - Max)">10°C - 20°C</td>
                                                            <td data-title="Season">Spring</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Months">June - August</td>
                                                            <td data-title="Temp. (Min - Max)">15°C - 30°C</td>
                                                            <td data-title="Season">Summer</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Months">September - November</td>
                                                            <td data-title="Temp. (Min - Max)">10°C - 25°C</td>
                                                            <td data-title="Season">Autumn</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Months">December - February</td>
                                                            <td data-title="Temp. (Min - Max)">-2°C - 10°C</td>
                                                            <td data-title="Season">Winter</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>MUST-VISIT TOURIST ATTRACTIONS IN THE MUMBAI TO <strong>KASHMIR TOUR PACKAGE</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>MUST-VISIT TOURIST ATTRACTIONS IN THE MUMBAI TO <strong>KASHMIR TOUR PACKAGE</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Starting your journey from <strong>Mumbai to Kashmir Trip</strong> is a dream come true for any traveler. A well-planned <strong>Customized Kashmir Tour</strong> offers a mix of stunning scenery, rich culture, and memorable experiences. Here are the must-visit attractions that will make your trip unforgettable:</p>
                                            <p><b>Srinagar</b>:
                                                The heart of Kashmir, Srinagar, is a beautiful city with its peaceful Dal Lake and Mughal Gardens. Enjoy the charm of life by the Jhelum River, feeling like you're in paradise.
                                            </p>
                                            <p><b>Gulmarg</b>:
                                                Known as the "Meadow of Flowers," Gulmarg is a breathtaking destination featuring a renowned ski resort. With spectacular views of Nanga Parbat and Mt. Apharwat, it’s a must-visit for nature lovers and adventure seekers.
                                            </p>

                                            <p><b>Betaab Valley</b>:
                                                Made famous by the Bollywood movie "Betaab," this picturesque valley is surrounded by lush forests and meadows. It's perfect for a peaceful picnic or a tranquil weekend retreat.
                                            </p>

                                            <p><b>Pahalgam</b>:
                                                Also known as the "Valley of the Shepherds," Pahalgam is famous for its scenic Sheshnag Lake and dense pine forests. It’s a prime spot for trekking, zorbing, and other thrilling adventure activities.
                                            </p>

                                            <p><b>Pulwama</b>:
                                                often referred to as the "Rice Bowl of Kashmir," is celebrated for its vibrant saffron fields and apple orchards. It's a fantastic location for trekking, snowboarding, skiing, and admiring the scenic beauty.
                                            </p>

                                            <p><b>Sonamarg</b>:
                                                Nestled in the Himalayas, Sonamarg is known for its striking natural beauty. A pony ride through its meadows, snow-capped mountains, and flowing rivers makes for an unforgettable experience.
                                            </p>

                                            <p><b>Pari Mahal</b>:
                                                Also called the "Palace of Fairies," Pari Mahal is a stunning seven-terraced garden offering mesmerizing views of Dal Lake and the surrounding mountains. Its rich history and unique architecture make it a must-see.
                                            </p>

                                            <p><b>Shalimar Bagh</b>:
                                                 a grand Mughal Garden in Srinagar, is famous for its magnificent terraces, vibrant flowerbeds, and elegant fountains. It’s an essential stop to experience the grandeur of Mughal architecture during your <strong>Kashmir tour</strong>.
                                            </p>

                                            <p><b>Shankaracharya Temple</b>:
                                                Perched on a hilltop, this temple dedicated to Lord Shiva offers awe-inspiring views of Srinagar. The 243-step climb is well worth it to take in the breathtaking scenery and the temple’s serene atmosphere.
                                            </p>
                                            <p>Book your <strong>Kashmir Tulip Festival Package Tour</strong> with Adorable Vacation LLP and let the beauty of Kashmir captivate your heart!</p>
                                            <br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen7ImageURL} alt="kashmir tour packages" />
                                                            <img src={sightseen8ImageURL} alt="kashmir trip from srinagar airport" />
                                                            <img src={sightseen9ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHEN DOES IT SNOW IN <strong>KASHMIR HONEYMOON TRIP?</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHEN DOES IT SNOW IN <strong>KASHMIR HONEYMOON TRIP?</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>In Kashmir, it usually starts snowing in early December and continues until the beginning of March. During this time, the valleys, roads, hills, and meadows get covered in thick snow, and rivers and lakes freeze because of the cold temperatures. Winter in Kashmir is perfect for snow activities like snowboarding and skiing, attracting snow lovers from all over India and the world.</p>
                                            <br />
                                            <h4><b>What Are the Fees for Gulmarg Gondola Ride (Phase 1 + Phase 2)?</b></h4>
                                            <br />
                                            <p><strong>Jammu & Kashmir Tour</strong> is incomplete without experiencing the Gulmarg Gondola ride. It's a must-do if you want to see the breathtaking views of the Himalayas, offering an unforgettable experience. Be sure to buy tickets for both Phase 1 and Phase 2, as the views at the second stage are truly amazing.</p>
                                            <p>During the peak season (from the end of March to the end of June, and from the end of September to early December), ticket bookings can be challenging due to long lines and the high number of tourists. You can book Phase 1 tickets online, but Phase 2 tickets are only available on-site. It's a good idea to do Phase 2 first and then return to Phase 1.</p>
                                            <ul>
                                                <li><b>Phase 1 (Gulmarg to Kungdoor)</b>: ₹600 per adult and child (3 to 10 years)</li>
                                                <li><b>Phase 2 (Kungdoor to Aparwath)</b>: ₹800 per adult and child (3 to 10 years)</li>
                                                <li><b>Both Phases for children below 3 years</b>: Free</li>
                                                <li><b>Chair Lift (Kungdori to Marry Shoulder)</b>: ₹300 per adult</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>SIGHTSEEING ENTRY FEES DURING <strong>BOOK KASHMIR PACKAGES FROM MUMBAI</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>SIGHTSEEING ENTRY FEES DURING <strong>BOOK KASHMIR PACKAGES FROM MUMBAI</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td>Places</td>
                                                            <td>Entry Fees</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dal Lake</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Nishant Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pari Mahal</td>
                                                            <td>Rs. 40/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shalimar Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shankaracharya Temple</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Chashme Shahi</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Verinag Garden</td>
                                                            <td>Rs. 10/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SPS Museum</td>
                                                            <td>Rs. 10 for Indian, Rs. 50 for Foreigners</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tulip Garden</td>
                                                            <td>Rs. 75 per adult, Rs. 30 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Aru Valley</td>
                                                            <td>Rs. 25/Person</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>FAMOUS NATIONAL PARKS TO EXPLORE DURING YOUR CUSTOMIZED <strong>KASHMIR TOUR</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>FAMOUS NATIONAL PARKS TO EXPLORE DURING YOUR CUSTOMIZED <strong>KASHMIR TOUR</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p><b>•	Dachigam National Park</b>: Just 22 km from Srinagar, this park is home to animals like leopards, Himalayan black bears, Himalayan weasels, long-tailed marmots, and various beautiful birds.
                                            </p>
                                            <p><b>• Kazinag National Park</b>: About 70 km from Srinagar in Baramula district, this park features wildlife such as snow leopards, Kashmir flying squirrels, musk deer, bears, and Himalayan marmots.</p>

                                            <p><b>• Hemis National Park</b>: Located in Ladakh, this is the largest national park in India and is famous for its snow leopards.</p>

                                            <p><b>• Salim Ali National Park</b>: Situated in Srinagar, this park is named after the renowned ornithologist Salim Ali and is known for its diverse bird species, with over 100 species of birds and animals.</p>

                                            <p><b>• Kishtwar National Park</b>: This high-altitude park in Jammu and Kashmir covers 2,190 square kilometers and is known for its snow leopards and various other wildlife.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>THINGS TO CARRY FOR YOUR <strong>KASHMIR HOLIDAY PACKAGE FROM MUMBAI</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>THINGS TO CARRY FOR YOUR <strong>KASHMIR HOLIDAY PACKAGE FROM MUMBAI</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>When traveling, it’s important to be prepared for any situation. Here’s a list of essentials you should bring:</p>
                                            <p><b>•	Identity Proof</b>: Always carry your original ID (Voter Card, Aadhaar Card, or Driving License) as a precaution, since Kashmir is a sensitive area.
                                            </p>
                                            <p><b>• Emergency Contacts</b>: Inform someone you know in nearby regions (like Delhi or Haryana) about your journey. Keep their address and contact number handy in case you need help.</p>

                                            <p><b>• Medicines</b>: Bring basic medicines for common issues like fever, headache, or stomach upset. Include antiseptics, antibiotics, antacids, painkillers, inhalers, pain relief sprays, band-aids, bandages, and ointments.</p>

                                            <p><b>• Warm Clothes and Jackets</b>: Pack woollen clothes suitable for the season (light for April to October, heavy for November to March). Places like Gulmarg, Sonmarg, and Pahalgam are cold year-round, so include waterproof jackets or windcheaters for unexpected rain.</p>

                                            <p><b>• Trekking Shoes/Boots</b>: If traveling to icy areas like Gulmarg or Sonmarg in winter, wear high-thigh boots or gumboots to protect your legs. You can also rent these locally.</p>

                                            <p><b>• Dry Fruits</b>: Keep dry fruits in your bag, as they can be a lifesaver if you get stuck due to landslides or delays.</p>
                                            <p>In addition to these, consider packing moisturizers, cold cream, extra bags, an umbrella, money, debit and credit cards, a torch, candles, matches or a lighter, a small rope, a knife, and a camera.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES FROM MUMBAI</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES FROM MUMBAI</strong></h4>

                                        </div>
                                        <div className="card-body">
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Shikara Rides:</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Shikara Rides:</b> Experience the tranquility of Dal Lake with a leisurely Shikara ride During your <strong> Kashmir Tulip Festival Package Tour</strong>. Glide across the serene waters, passing by floating gardens, houseboats, and vibrant markets. Sunset and early morning rides offer particularly magical views.</p>
                                            <center> <img src={Topbanner0ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Skiing and Snowboarding:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Skiing and Snowboarding:</b> During the winter months, places like Gulmarg transform into ski resorts, attracting enthusiasts from around the world. Gulmarg offers excellent slopes for skiing, snowboarding, and heli-skiing, with breathtaking views of the surrounding Himalayan peaks.</p>
                                            <center> <img src={Topbanner1ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Gondola Ride: </b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gondola Ride: </b>
                                                Take a ride on the Gulmarg Gondola, one of the highest cable cars in the world. Enjoy panoramic views of the snow-capped peaks as you ascend to Kongdori or Apharwat Peak, where you can indulge in activities like hiking and mountain biking.Experience this Awesome Ride During your <strong>Kashmir Tulip Garden Tour</strong> with Adorable Vacation and make this as a life long memories.</p>
                                            <center> <img src={Topbanner2ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Trekking:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Trekking:</b> Kashmir is a paradise for trekkers, with numerous trails that cater to all levels of expertise. Popular trekking destinations include the Kashmir Great Lakes Trek, Tarsar Marsar Trek, and the trek to Thajiwas Glacier in Sonamarg.</p>
                                            <center> <img src={Topbanner3ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>River Rafting:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>River Rafting:</b> The Lidder River in Pahalgam offers exciting opportunities for white-water rafting. Experience the thrill of navigating through rapids while enjoying the scenic beauty of the surrounding pine forests and mountains.</p>
                                            <center> <img src={Topbanner4ImageURL} className="img-fluid" alt="..." /></center>
                                            <br /><br /><br />
                                            <a href="tel://9147061467" className="btn btn-Warning" style={{ padding: '15px 30px', fontSize: '20px' }}>CALL NOW</a>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="container-fluid navtabCustom">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-highlights" aria-selected={customActiveTab === 'tab1'}>Highlights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-flights" aria-selected={customActiveTab === 'tab2'}>Flights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-hotel" aria-selected={customActiveTab === 'tab3'}>Hotel</button>
                                                <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-sightseeing" aria-selected={customActiveTab === 'tab4'}>Sightseeing</button>
                                                <button onClick={() => this.handleCustomTabClick('tab5')} className={`tab button ${customActiveTab === 'tab5' ? 'active' : ''}`} role="tab" aria-controls="nav-meals" aria-selected={customActiveTab === 'tab5'}>Meals</button>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent" >
                                            <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                                <p>The <strong>Kashmir Package Tour from Mumbai</strong> is famous for its stunning landscapes, including well-known spots like Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. These places draw tourists from all over the world who come to enjoy the beautiful scenery and take part in outdoor activities.</p>
                                                <p>Planning a <strong>Kashmir Tour Packages from Mumbai</strong> is a great choice for travelers looking for beautiful scenery, cultural experiences, and adventure in a unique and stunning location.</p>
                                                <p>Kashmir is located in the Himalayan Mountains and is surrounded by India, Pakistan, and China. It has three main regions: Jammu, the Kashmir Valley, and Ladakh. The area is known for its snowy mountains, green valleys, winding rivers, and peaceful lakes.</p>
                                                <p>The <strong>Kashmir Tour Pakcage from Mumbai</strong> are famous for their stunning landscapes, including places like Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. Tourists from around the world visit to enjoy the beautiful scenery and take part in outdoor activities. Kashmir has always been a popular destination, known for its natural beauty, adventure sports, religious sites, and historical landmarks. Visitors can enjoy activities like trekking, skiing, staying in houseboats, taking shikara rides, and exploring Mughal gardens and ancient temples.</p>
                                            </div>
                                            <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                                <div className="overflow-table">
                                                    <span>POPULAR FLIGHTS FOR THE KASHMIR TULIP GARDEN TRIP 2025<b> <i>(SXR)</i></b></span><br /><br />
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th>Flight Operator</th>
                                                            <th>Departure From</th>
                                                            <th>Departure Time</th>
                                                            <th>Arrival at Srinagar</th>
                                                            <th>Stopover</th>
                                                            <th>Travel Time</th>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Kolkata [CCU]</td>
                                                            <td>08:15</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>7h 00m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Mumbai [BOM]</td>
                                                            <td>09:30</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>5h 45m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SpiceJet</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>08:00</td>
                                                            <td>09:25</td>
                                                            <td>(None)</td>
                                                            <td>1h 25m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Bangalore [BLR]</td>
                                                            <td>09:30</td>
                                                            <td>14:10</td>
                                                            <td>Sri Guru Ram Dass Jee [ATQ]</td>
                                                            <td>4h 40m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Goa [GOX]</td>
                                                            <td>10:30</td>
                                                            <td>16:45</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>6h 15m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Hyderabad [HYD]</td>
                                                            <td>08:55</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>6h 20m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Chennai [MAA]</td>
                                                            <td>08:30</td>
                                                            <td>14:20</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>5h 50m</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">

                                                <div className="overflow-table">
                                                    <div id="no-more-tables">
                                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                            <thead class="cf">
                                                                <tr className="text-center">
                                                                    {/* <!-- Add the "text-center" className to center content --> */}
                                                                    <th colspan="6" style={{ fontSize: "1.3vw" }}><h6><b>3 STAR CATEGORY HOTEL CERTIFIED BY 'ADORABLE VACATION' </b></h6></th>
                                                                </tr>
                                                                <tr className="table-danger">
                                                                    <th className="text-center">Destination</th>
                                                                    <th className="text-center">Hotel Names</th>
                                                                    <th className="text-center">Hotel Type</th>
                                                                    <th className="text-center">No of Nights</th>
                                                                    <th className="text-center">Meal Plan</th>
                                                                </tr>
                                                            </thead>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Grand Fortune / Hotel Shefaf / Hotel Siddique Palace / Similar</div></td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>5N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Gulmarg</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Affarwat / Hotel Mama Palace /Gulmarg House / Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>1N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Pahalgam</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Pine Palace Resort / Hotel Fifth Season / Hotel Moonview Resort /Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>2N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                                <div className="container-fluid px-0 py-1">
                                                    <h5>TOP ATTRACTIONS TO EXPLORE DURING THE <strong>KASHMIR PACKAGE TOUR FROM MUMBAI</strong></h5><br />
                                                    <p>During the Kashmir Tulip Holiday Package from Adorable Vacation, the region offers a wealth of captivating attractions that complement the vibrant display of tulips. Here are the must-see highlights:</p><br />
                                                    <p><b className='tomato'>1.	Indira Gandhi Memorial Tulip Garden:</b> Dive into the festival's heart, where sprawling tulip beds create a riot of colours against the backdrop of the majestic Himalayas.</p>
                                                    <p><b className='tomato'>2. Srinagar's Dal Lake:</b> Take a serene shikara ride on this iconic lake, known for its houseboats and picturesque views, offering a tranquil escape from the festival's bustle.</p>
                                                    <p><b className='tomato'>3. Mughal Gardens:</b> Explore the timeless beauty of Shalimar Bagh, Nishat Bagh, and Chashme Shahi, where Mughal architecture blends with lush landscapes.</p>
                                                    <p><b className='tomato'>4. Gulmarg:</b> Discover the lush meadows and pristine surroundings of Gulmarg, perfect for a day trip with opportunities for cable car rides and panoramic views.</p>
                                                    <p><b className='tomato'>5. Pahalgam:</b> Experience the natural splendor of Pahalgam, from its scenic meadows to the picturesque Betab Valley and Aru Valley.</p>
                                                    <p><b className='tomato'>6. Sonmarg:</b> Venture to Sonmarg's breathtaking landscapes, where stunning views and serene environments make for a memorable excursion.</p>
                                                    <p><b className='tomato'>7. Doodhpathri:</b> Explore this lesser-known gem, renowned for its lush green meadows and gentle streams, offering a peaceful retreat.</p>
                                                    <p><b className='tomato'>8. Yusmarg:</b> Visit this idyllic meadow surrounded by pine forests, providing a serene spot for relaxation and picnicking.</p>
                                                    <p><b className='tomato'>9. Srinagar's Local Markets:</b> Immerse yourself in the vibrant local culture by visiting bustling markets like Lal Chowk and Polo View Market, where you can shop for traditional Kashmiri crafts and souvenirs.</p>
                                                    <p><b className='tomato'>10. Vaishno Devi Temple:</b> Take a pilgrimage to this revered shrine, nestled in the Trikuta Mountains, offering spiritual solace and panoramic views.</p>
                                                    <p><b className='tomato'>11. Shankaracharya Temple:</b> Visit this ancient temple perched on a hilltop for a blend of spiritual significance and stunning panoramic views of Srinagar.</p>
                                                    <p>The <strong>Tulip Garden Tour Package</strong> promises not only a visual feast of flowers but also a rich array of cultural and natural wonders to explore.</p>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                                <div className="overflow-table">
                                                    <h5 class="tomato">7 times Meals and 7 times Breakfast has been Provided</h5>
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th colspan="6" className="text-center">Category</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">JAIN</td>
                                                            <td class="text-center">PURE-VEG</td>
                                                            <td class="text-center">VEGAN</td>
                                                            <td class="text-center">PURE-NON-VEG</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br /><br /><br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                DAY WISE <strong><span>KASHMIR TOUR ITINERARY FROM MUMBAI</span></strong>
                                            </h2>
                                            <h4>7 Nights 8 Days <strong>Kashmir Tour Itinerary</strong></h4>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--end Short description--> */}
                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    DAY 1  – ARRIVAL IN SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Arrive at Srinagar’s Sheikh Ul Alam International Airport. You’ll be greeted by our representative and taken to your hotel for check-in. Enjoy a welcome drink and then head out for a shikara ride on the famous Dal Lake. Explore the floating gardens and enjoy bird watching. Spend the night on a houseboat.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day2"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    DAY 02: SRINAGAR TO GULMARG
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, check out from the houseboat and drive to Gulmarg, known as the “Meadow of Flowers.” Check in at your hotel in Gulmarg. You can take a gondola ride up to the first or second phase of the cable car, which offers stunning views and is one of the highest cable cars in the world. Gulmarg is also famous for skiing and its beautiful meadows. Return to your hotel for the night.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day3"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 03: GULMARG TO PAHALGAM
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Enjoy breakfast before checking out and driving to Pahalgam, known as the “Valley of Shepherds.” This charming town sits where the streams from Sheshnag Lake meet the Lidder River. Check in at your hotel and enjoy the rest of the day at leisure.</p>
                                                    <br />

                                                    <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="Kashmir Tour Packages" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Packages" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day4"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 04: - PAHALGAM LOCAL SIGHTSEEING
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast, spend the day exploring Pahalgam. Visit Chandanwari, the starting point for the Amarnath Yatra, and enjoy snow sledding. Explore Aru Valley and Betaab Valley for their stunning scenery. You can also visit Baisaran Valley, known as “Mini Switzerland,” for beautiful meadows and dense pine forests. Return to your hotel in Pahalgam for the night.</p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay4ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day5"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 05: PAHALGAM TO SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Check out from your hotel and drive back to Srinagar. Visit the Hazratbal Shrine and Shankaracharya Temple. In the afternoon, explore the Mughal Gardens: Nishat Bagh, Shalimar Bagh, and Chashme Shahi. Return to your Srinagar hotel for the night.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day6"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 06: SRINAGAR TO DOODHPATHRI AND BACK
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, take a day trip to Doodhpathri, located 42 kilometers from Srinagar. Known as the "Valley of Milk," Doodhpathri is famous for its lush meadows and cold, milky streams. The area is covered with wildflowers in spring and summer. Return to Srinagar in the evening for an overnight stay.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay6ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day7"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 07: SRINAGAR TO SONMARG AND BACK
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, check out from your hotel and head to Sonmarg, meaning "Meadow of Gold." The 87-kilometer drive offers stunning views of forests and mountains. Sonmarg is a gateway to trekking routes and glaciers. You can hire ponies or vehicles to visit Thajiwas Glacier, a major attraction. Return to Srinagar in the evening for your last overnight stay.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay7ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day8"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 08:DEPARTURE FROM SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTen"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Your <strong>Kashmir Tour From Mumbai</strong> concludes today. After breakfast, you will be transferred to the Domestic Airport for your flight to your next destination. Thank you for traveling with us, and we hope you had a memorable experience.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay8ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h2>The Khyber Himalayan Resort & Spa <span>- Gulmarg</span></h2>
                                            <h4><i>Next Level Luxury Resort </i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_1_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Platinum Accommodation</td>
                                                <td data-title="Size(Room)">386+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Khyber Hotels (Heritage)<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 53,119/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >The LaLiT Grand Place <span>- Srinagar</span></h2>
                                            <h4 ><i>Fill the Luxury</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_2_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Premium Accommodation</td>
                                                <td data-title="Size(Room)">290+ SQ.FT</td>
                                                <td data-title="Hotel Brand">The LaLiT Hotels<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 28,332/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>The LaLit Grand Palace</b> was originally built in 1901 for the royal family. It now provides luxurious rooms with views of Dal Lake and the beautiful gardens. An indoor pool, tennis courts and pampering spa treatments are also available.The tastefully designed rooms are modern with a touch of traditional charm. All have a satellite TV, minibar and free tea/coffee making facilities. It is 18 km from Srinagar Airport and 4 km from Lal Chowk city centre. Jammu Railway Station is 300 km away. Guests can enjoy a relaxing massage at Rejuve Spa Health Club, practice yoga, or play golf. The hotel also has a gym and 24-hour business center. The Chinar Garden serves North Indian cuisine, while international buffet spreads are available at The Chinar – The All Day Coffee Shop. Other dining options include Dal Bar and 24-hour room service.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>

                            <br /><br />
                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Kashmir<span> Package Tour From Mumbai </span></h2>
                                            <h4>Related Kashmir Tour plan</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >Frequently Ask <span>Questions</span></h2>
                                            <h4 >Kashmir Package Tour From Mumbai Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" >
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion" id="accordionFlushExample">
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingEighteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEighteen" aria-expanded="false" aria-controls="flush-collapseEighteen">
                                                        Q. What are the KASHMIR TOUR PACKAGE FROM MUMBAI WITH AIRFARE?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseEighteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingEighteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> The <strong>Kashmir trip</strong> starts around ₹15,599. These packages usually include accommodation, meals, and transportation for 4 nights and 5 days. Prices can vary based on the season and what’s included, but these options are affordable for exploring Kashmir’s beauty and culture.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingNineteen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNineteen" aria-expanded="false" aria-controls="flush-collapseNineteen">
                                                        Q. How are ATMs in Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseNineteen" className="accordion-collapse collapse show" aria-labelledby="flush-headingNineteen" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> ATMs are widely available in Srinagar and other parts of Kashmir, but it's a good idea to carry extra cash. Sometimes ATMs might not work or run out of cash, and internet issues can affect ATM access. Bring cash in small denominations like ₹10, ₹50, ₹100, and ₹500 for convenience.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwenty">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwenty" aria-expanded="false" aria-controls="flush-collapseTwenty">
                                                        Q. What is the best time to visit Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwenty" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwenty" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Kashmir is beautiful year-round. The best time to visit is between March and October when the weather is pleasant, gardens are in bloom, and lakes are clear for Shikara rides. If you want to experience snow, visit from December to March when Kashmir resembles a winter wonderland.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentyOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyOne" aria-expanded="false" aria-controls="flush-collapseTwentyOne">
                                                        Q. Which travel agencies offer the lowest-priced packages?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentyOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentyOne" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Adorable Vacation often provides some of the most affordable <strong>Kashmir package tours from Mumbai</strong>. They are known for their competitive prices and good service, making them a popular choice for budget-conscious travelers.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentyTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyTwo" aria-expanded="false" aria-controls="flush-collapseTwentyTwo">
                                                        Q. What should I pack for a Kashmir trip from Mumbai?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentyTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentyTwo" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b>
                                                            • Cotton shirts (for summer) or heavy jackets (for winter)<br />
                                                            • Woollen caps, mufflers, and warm gloves<br />
                                                            • Snow boots (available for rent in Gulmarg)<br />
                                                            • ID proof (Aadhar Card, Voter ID)<br />
                                                            • Cash<br />
                                                            • Camera<br />
                                                            • Universal adapter and charger<br />
                                                            • Torch and power bank<br />
                                                            • First aid kit<br />
                                                            • Winter lotion, toothbrush and paste, sanitizer, cold cream, and moisturizer<br />
                                                            • Dry fruits and biscuits
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentyThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyThree" aria-expanded="false" aria-controls="flush-collapseTwentyThree">
                                                        Q. Are there direct flights from Mumbai to Srinagar?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentyThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentyThree" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Yes, there are direct flights from Mumbai to Srinagar. Several airlines operate daily flights from Mumbai's Chhatrapati Shivaji Maharaj International Airport (BOM) to Srinagar's Sheikh ul-Alam International Airport (SXR). The flight takes about 2.5 to 3 hours. Check with airlines for current schedules and availability.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentyFour">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyFour" aria-expanded="false" aria-controls="flush-collapseTwentyFour">
                                                        Q. When is the best time to visit Kashmir to escape Mumbai’s heat?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentyFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentyFour" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> The best time to visit Kashmir to avoid Mumbai’s heat is between April and October. During these months, Kashmir has pleasant weather, with colorful flowers blooming and temperatures warm but comfortable.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentyFive">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyFive" aria-expanded="false" aria-controls="flush-collapseTwentyFive">
                                                        Q. What street food can we try in Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentyFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentyFive" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Kashmir offers delicious street food such as Dum Aloo, Nadir Monji, Masala Tchot, Kalari Kulcha, Lala Shangram, Monji Gaade Halwa-Paratha, and Kachalu Chaat.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentySix">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentySix" aria-expanded="false" aria-controls="flush-collapseTwentySix">
                                                        Q. What can we shop for in Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentySix" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentySix" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Kashmir is great for shopping, with items like Kashmiri jewelry, walnut wood products, carpets, Papier Mâché artifacts, Pashmina shawls, copperware, Kashmiri tea, and dry fruits (walnuts, apricots, dates, cashews, resins, pistachios), as well as spices and Kashmiri apples.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentySeven">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentySeven" aria-expanded="false" aria-controls="flush-collapseTwentySeven">
                                                        Q. Should we bring liquor from Mumbai?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentySeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentySeven" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Yes, it's a good idea to bring liquor from Mumbai as there are only a few liquor shops in Srinagar. It’s better to carry your own drinks and consume them in your hotel room.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h3><b>Kashmir <span class="tomato">Holiday Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container" >

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                        {this.state.showModal && (
                            <div className="modal" style={{ display: this.state.showModal ? "block" : "none" }}>
                                <div className="modal-dialog">
                                    <div className="modal-content cust-modContent">
                                        <div className="modal-header">
                                            <h4 className="modal-title ms-auto" id="exampleModalLabel">Get a Free Callback &nbsp;&nbsp;<i className="fa fa-phone fa-rotate-90"></i>
                                            </h4>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.onCloseModal}></button>
                                        </div>
                                        <div className="modal-body">
                                            <form className="row g-3">
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name *"
                                                        name="firstName"
                                                        value={this.state.firstName}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                {/* <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Last Name *"
                                                        name="lastName"
                                                        value={this.state.lastName}
                                                        onChange={this.onChangeHandler} />
                                                </div> */}
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="10 Digit Mobile No.*"
                                                        name="mobile"
                                                        value={this.state.mobile}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Email *"
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Comments"
                                                        rows="2"
                                                        name="comments"
                                                        value={this.state.comments}
                                                        onChange={this.onChangeHandler}></textarea>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <div className='container'>
                                                <div className="row">
                                                    <div className='col-sm-4'>
                                                        {
                                                            (this.state.showLoader) &&
                                                            <div className='pull-left'><Digital size={25} /></div>
                                                        }
                                                    </div>
                                                    <div className='col-sm-8 '>
                                                        <div className='pull-right'>
                                                            <button type="button" className="btn btn-Warning" onClick={this.onRequestCallbackSubmit} >Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div >
                </div >
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91470-61467">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                    </a>
                </div>

            </>
        );
    }
}

export default Kashmir_7N_8D_Package_Tour_From_Mumbai;