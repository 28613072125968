import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ApiURL from "../../../api/ApiURL";

class IndividualIntervalsExample extends Component {
    
  render() {
    const navDay1ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/IXB-Airport–Phuentsholing.webp";
    const navDay2ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/Phuentsholing-to-Thimphu.webp";
    const navDay3ImageURL = ApiURL.BhutanTourPackageFromBangaloreImageURL + "/Thimphu-Local-Sightseeing.webp";
    return (
      <Carousel fade>
        <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src={navDay1ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
            <h3>Bhutan tour Package</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={navDay2ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block w-100"
          src={navDay3ImageURL}
          alt="First slide"
        />
          <Carousel.Caption>
            <h3>Third slide label</h3>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default IndividualIntervalsExample;
