import React, { Component } from 'react';

class InclusionExclusion_group extends Component {
    render() {
        return (
            <>
                <h4><u>INCLUSION: <strong className='tomato'>NORTH EAST GROUP TOUR PACKAGES FROM GUWAHATI</strong></u></h4>
                <ul>
                <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;All Accommodation on Double sharing basis
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Return Airfare (BOM-GAU-BOM)
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;All transfers &amp; sightseeing's by TEMPO TRAVELLER</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Driver's allowance, fuel, toll, parking</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Mineral Water during Sightseeing</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Meal Plan AP (Breakfast &amp; Dinner in Hotel &amp; Lunch for 9 Days at outside Local Restaurant)</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;01 No. of Professional Licensed Tour Guide (Guwahati - Guwahati)</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;24x7 Tour Assistance Services</li>
                </ul><br/><br/><br/>
                <h4><u>EXCLUSIONS: <strong className='tomato'>NORTH EAST GROUP PACKAGE TOUR FROM GUWAHATI</strong></u></h4>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Entry fees, camera permit, Ferry rides</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Laundry, Tips or any such personal expenses</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Any item which is not mentioned in the 'Inclusion' section</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Any cost arising due to any unavoidable circumstances or changes in the program by guests</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Applicable 5% GST</li>
                </ul>
            </>
        );
    }
}

export default InclusionExclusion_group;