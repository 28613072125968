import React, { Component, useState } from 'react';
import BookingBox from '../package/BookingBox';
import Slider from "react-slick";
import ApiURL from "../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


class VisaServices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1',
            currentPage: 1,
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919883359713&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    handlePageChange = (direction) => {
        this.setState((prevState) => {
            const newPage = prevState.currentPage + direction;
            return { currentPage: newPage };
        });
    };
    render() {
        const rows = [
            { date: '24th Sept - 30th Sept', availability: 'Available', price: '84,500' },
            { date: '01st Oct - 07th Oct', availability: 'Available', price: '84,500' },
            { date: '04th Oct - 10th Oct', availability: 'Available', price: '84,500' },
            { date: '08th Oct - 14th Oct', availability: 'Available', price: '84,500' },
            { date: '11th Oct - 17th Oct', availability: 'Available', price: '84,500' },
            { date: '15th Oct - 21st Oct', availability: 'Available', price: '84,500' },
            { date: '18th Oct - 24th Oct', availability: 'Available', price: '84,500' },
            { date: '22nd Oct - 28th Oct', availability: 'Available', price: '84,500' },
            { date: '25th Oct - 31st Oct', availability: 'Available', price: '84,500' },
            { date: '29th Oct - 04th Nov', availability: 'Available', price: '84,500' },
            { date: '1st Nov - 07th Nov', availability: 'Available', price: '84,500' },
            { date: '05th Nov - 11th Nov', availability: 'Available', price: '84,500' },
        ];

        const rowsPerPage = 6;
        const totalPages = Math.ceil(rows.length / rowsPerPage);
        const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
        const { customActiveTab, daywiseActiveTab } = this.state;

        const packageTopBannerImageURL = ApiURL.VisaServicesImageURL + "/Visa-Consultancy-Services.webp";
        const packageTopBanner2ImageURL = ApiURL.VisaServicesImageURL + "/Visa-Consultancy-Service-in-Kolkata.webp";
        const sightseen1ImageURL = ApiURL.VisaServicesImageURL + "/best-immigration-consultants.webp";
        const sightseen2ImageURL = ApiURL.VisaServicesImageURL + "/best-visa-agent-in-kolkata.webp";
        const sightseen3ImageURL = ApiURL.VisaServicesImageURL + "/Kolkata-visa-agent.webp";
        const sightseen4ImageURL = ApiURL.VisaServicesImageURL + "/online-visa-application.webp";
        const sightseen5ImageURL = ApiURL.VisaServicesImageURL + "/online-visa-service-agency.webp";
        const sightseen6ImageURL = ApiURL.VisaServicesImageURL + "/online-visa-service-agency-in-Kolkata.webp";
        const sightseen7ImageURL = ApiURL.VisaServicesImageURL + "/Student-visa-consultancy-in-Kolkata.webp";
        const sightseen8ImageURL = ApiURL.VisaServicesImageURL + "/tourists-visa-services.webp";
        const sightseen9ImageURL = ApiURL.VisaServicesImageURL + "/travel-visa-services.webp";
        const sightseen10ImageURL = ApiURL.VisaServicesImageURL + "/Visa-consultancy-services-in-Kolkata.webp";
        const sightseen11ImageURL = ApiURL.VisaServicesImageURL + "/Visa-service-in-Kolkata.webp";
        const sightseen12ImageURL = ApiURL.VisaServicesImageURL + "/Visa-services-in-Kolkata.webp";

        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.VisaServicesImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })


        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />

                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">
                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>EXPERT VISA CONSULTANCY SERVICES FOR HASSLE-FREE TRAVEL WITH <span className='tomato'>ADORABLE VACATION</span></strong></center></h1>
                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>EXPERT VISA CONSULTANCY SERVICES FOR HASSLE-FREE TRAVEL WITH <span className='tomato'>ADORABLE VACATION</span></strong></center>
                                            <hr />
                                        </h3>

                                        <div className="container-fluid">
                                            <div className="row">
                                                <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                    <h2 className="p-2"><b>
                                        Adorable Vacation LLP offers travel visa services, <a href='tel:9163676037' className="tomato">☎ CALL Mr. Akash +91-9163676037</a></b></h2>

                                    <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >

                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Best VISA Operator in Kolkata</h3>
                                            <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Best VISA Operator in Kolkata</h3>
                                        </div>
                                        <div className="card-body">
                                            <p>Enjoy hassle-free <strong>visa services in kolkata</strong> with Adorable Vacation LLP. Our experienced team will guide you through every step of your <strong>visa application</strong>, making the process easy and stress-free.</p>
                                            <p>Please note, the final decision to approve or deny your visa is up to the visa authorities of the country you're applying to. Adorable Vacation LLP has no control over that decision.</p>
                                            <p>Whether you're a tourist, have a work permit, been accepted to a foreign school, or traveling for business, we’re here to help with your <strong>visa application</strong>.</p>
                                            <p>Our expert team provides personalized assistance for your <strong>online visa application</strong>, making it simpler and more convenient. Our trusted advice is tailored to your needs, and we offer reliable service to ensure your paperwork is in order.</p>
                                            <p>This service covers visas for the USA, UK, Europe (Schengen countries), Australia, Canada, and other destinations. There are different types of visas, each giving you specific rights in the country you're visiting.</p>
                                            <br /> <br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen1ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                                                    <img src={sightseen2ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                                                    <img src={sightseen3ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /> <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>DISCOVER THE BEST KOLKATA VISA AGENT FOR HASSLE-FREE TRAVEL</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>DISCOVER THE BEST KOLKATA VISA AGENT FOR HASSLE-FREE TRAVEL</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>When it comes to planning an international trip, having a reliable visa agent can make all the difference. If you're based in Kolkata and looking for the <strong>best immigration consultants</strong>, you've come to the right place. With a wealth of experience, a deep understanding of <strong>visa passport</strong>, and a commitment to customer satisfaction, we’re recognized as one of Kolkata's leading <strong>visa consultant</strong>.</p>
                                    <p>Why We’re Considered the Best <strong>visa travel agency</strong></p>
                                    <p><b>1. Expert Visa Consultation </b>
                                        Our team is highly knowledgeable in processing visas for various countries, including Schengen countries, the USA, Canada, the UK, and more. We offer expert guidance to ensure your <strong>visa services</strong> is smooth, efficient, and stress-free.
                                    </p>
                                    <p><b>2. Personalized Visa Services </b>
                                        We understand that every traveler has unique visa needs. Whether you're applying for a tourist, student, work, or business visa, we tailor our services to meet your specific requirements, ensuring all paperwork is correct and submitted on time.</p>

                                    <p><b>3. Quick and Easy Visa Processing </b>
                                        Time is crucial when it comes to travel planning. We provide quick and efficient visa processing, making sure you receive your visa well before your travel date. Our streamlined process minimizes delays and maximizes your chances of approval.
                                    </p>
                                    <p><b>4. Comprehensive Documentation Assistance </b>
                                        From completing forms to gathering the necessary documents, we assist you every step of the way. We ensure your <strong>immigration visa</strong> is complete and free from errors, significantly reducing the chances of rejection.
                                    </p>
                                    <p><b>5. Transparent and Affordable Pricing </b>
                                        We believe in offering top-notch services at competitive rates. Our pricing structure is transparent, with no hidden fees, ensuring you receive the best value for your money.
                                    </p>
                                    <br /> <br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen4ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                                                    <img src={sightseen5ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                                                    <img src={sightseen6ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /> <br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>OUR SERVICES</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>OUR SERVICES</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p><b>Tourist Visa</b> : Whether you're planning a vacation in Europe, Asia, or the Americas, we provide hassle-free tourist visa services.</p>
                                    <p><b>Business Visa</b>: Need to attend a business meeting or conference abroad? We’ll help you secure a business visa promptly.</p>
                                    <p><b>Student Visa</b>: If you're heading to study abroad, we offer specialized student visa services for countries like the USA, UK, Canada, and Australia.</p>
                                    <p><b>Work Visa</b>: For professionals looking to work overseas, we assist in processing work visas with complete documentation support.</p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>COUNTRIES WE SPECIALIZE IN</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>COUNTRIES WE SPECIALIZE IN</strong></h4>
                                </div>
                                <div className="card-body">
                                    <h2 style={{ fontSize: "1.5vw" }}><b>WE OFFER VISA SERVICES FOR A WIDE RANGE OF COUNTRIES, INCLUDING BUT NOT LIMITED TO:</b></h2><br />
                                    <ul>
                                        <li>•	Schengen Countries (Europe)</li><br />
                                        <li>• United States and Canada</li><br />
                                        <li>• United Kingdom</li> <br />
                                        <li>• Australia and New Zealand</li><br />
                                        <li>• Middle Eastern countries (UAE, Saudi Arabia, Qatar)</li><br />
                                        <li>• Asian countries (Japan, South Korea, Thailand, Singapore)</li>
                                    </ul>
                                    <br /> <br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen7ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                                                    <img src={sightseen8ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                                                    <img src={sightseen9ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /> <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>WHY TRUST US WITH YOUR VISA?</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>WHY TRUST US WITH YOUR VISA?</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>We’ve built our reputation as the <strong>best visa agent in Kolkata</strong> by consistently delivering high-quality services, ensuring customer satisfaction at every step. From visa consultation to final approval, we handle everything, giving you peace of mind. Our deep expertise, personalized approach, and quick turnaround make us the go-to agency for travelers across Kolkata.</p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>CONTACT US TODAY</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>CONTACT US TODAY</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>Ready to make your travel plans stress-free? Reach out to <strong>Kolkata's most trusted visa agent</strong> today and let us take care of the rest</p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>STUDENT VISA CONSULTANCY SERVICES</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>STUDENT VISA CONSULTANCY SERVICES</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>A <strong>student visa</strong> is a type of visa that lets you study at a higher education institution in another country.</p>
                                    <p>Students can apply for visas to countries that welcome international students and exchange visitors.<br />
                                        Before applying, students or exchange visitors need to be accepted by their school or program sponsor. Once accepted, our visa consultancy team will handle all the formalities and guide you through each step to make sure the process goes smoothly.
                                    </p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>TOURIST VISA OR FAMILY VISIT VISA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>TOURIST VISA OR FAMILY VISIT VISA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>This visa lets you travel to other countries for tourism, leisure, or visiting family and friends, allowing you to stay for a set period of time.<br />
                                        If you have a dream destination in mind, anywhere in the world, simply use our visitor visa services, and we’ll handle the rest.
                                    </p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>BUSINESS VISA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BUSINESS VISA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>A <strong>business visa</strong> lets you enter another country to do business activities without working there.<br />
                                        For example, you may need a business visa if you're meeting with a company or attending a business conference abroad.<br />
                                        We make the visa application process easy and hassle-free, so you can focus on your business while we handle the travel formalities.
                                    </p>
                                    <br /> <br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen10ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                                                    <img src={sightseen11ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                                                    <img src={sightseen12ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /> <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>WORK VISA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>WORK VISA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>Work visas are needed for people who want to work in another country. If you have a job offer from a company abroad and need to apply for a work visa, we can help you as long as your employer provides all the necessary documents.<br />
                                        There are different types of work visas based on the type of work and how long you’ll be staying. For example, there are temporary work visas for short jobs and freelance visas that allow you to work while traveling. There are also long-term work visas that are connected to residency.<br />
                                        If you already have a job in a foreign country, make sure you have all the required paperwork ready so we can process your work visa.<br />
                                        Please note, we do not help with job placements in foreign countries; we only assist with the visa process if you already have a job.
                                    </p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>MARITIME CREW VISA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>MARITIME CREW VISA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>The Maritime Crew visa is a temporary visa for crew members working on non-military ships that travel internationally.<br />
                                        Crew members are those who have contracts to work on a ship while it's at sea. This also includes people doing scientific research on ships owned by foreign governments. Our <strong>visa assistance services</strong> help make the process easier for Maritime Crew members.<br />
                                        This visa allows individuals to enter a foreign country by sea as part of a ship’s crew and do their regular work while they are in that country.

                                    </p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>SHORT TERM VISA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>SHORT TERM VISA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>This type of visa allows a foreign national to stay in a country temporarily without becoming a full citizen. It can be for study, business, or other reasons, and it allows you to stay for a specific amount of time as stated on your visa.<br />
                                        You can travel freely within the country while your visa is valid. If you want to travel for tourism, business, or family visits, we can help. Our experienced team will guide you through the application process with expert visa consultations based on the purpose and length of your visit. We’ll take care of everything for you.
                                    </p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>LONG TERM VISA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>LONG TERM VISA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>If you plan to stay in a country longer than what your short-term visa allows, you need to apply in advance for a long-stay visa. In some countries, this is called an authorization for temporary stay.<br />
                                        When you apply for this visa, your planned stay and other requirements will be considered. Our team will ensure that the process goes smoothly and without any issues.
                                    </p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>SUPER VISA (CANADA)</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>SUPER VISA (CANADA)</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>The Super Visa is a type of multi-entry visa that allows you to enter a country multiple times over a period of up to 10 years. Unlike a regular tourist visa for Canada, the Super Visa lets you stay for up to two years at a time. In contrast, a 10-year multiple-entry visa only allows you to stay for six months each time you enter the country.
                                    </p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>DEPENDENT VISA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>DEPENDENT VISA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>The dependent visa allows professionals, students, permanent residents, and others to bring their family members from another country to the host country.<br />
                                        There are two types of dependent visas: Temporary Visas for those with Work, Intra-company Transfer, Student, or Fiancé visas, and Permanent Visas for spouses or children under the age of 18 to 21.<br />
                                        Our trusted immigration consultants will help you understand the details of the dependent visa process so you can reunite with your family in a foreign country.
                                    </p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>MEDICAL VISA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>MEDICAL VISA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>Medical visas are given to people who are traveling to another country just to receive medical treatment. Our complete <strong>visa consultancy service</strong> will take into account all your needs for the visa application and guide you through the entire process of applying for a medical visa.
                                    </p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>USE OUR COMPLETE VISA CONSULTANCY SERVICE FOR AN EASY VISA APPLICATION</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>USE OUR COMPLETE VISA CONSULTANCY SERVICE FOR AN EASY VISA APPLICATION</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>The type of visa you need depends on the country you want to visit, the purpose of your trip, and how long you'll stay. If your home country has a visa agreement with the country you plan to visit, you probably won't need to apply for a visa in advance.<br />
                                        However, if there is no visa agreement, you must apply for a visa before you travel.<br />
                                        No matter your needs or reasons for traveling, you can trust us to make the visa process smooth and stress-free.
                                    </p>
                                </div>
                            </div>
                            <br /><br />
                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Frequently Ask <span>Questions</span></h2>
                                            <h4>VISA Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                    What Documents Do You Need for a Visa Interview?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    The documents required for a Visa Interview depend on the type of visa you need and the visa agreement between your country and the country you're visiting. Generally, you'll need:
                                                    <ul>
                                                        <li>Your original passport, which should be valid for at least six months beyond your expected arrival date in the host country.</li>
                                                        <li>Any old passports.</li>
                                                        <li>A photo that meets the specifications.</li>
                                                        <li>The visa application form confirmation page, which is stamped at the Visa Application Centre.</li>
                                                        <li>Proof of fee payment.</li>
                                                        <li>Your interview appointment letter.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                    Which Countries Commonly Offer Dependent or Spouse Visas?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    Most countries that allow immigration offer dependent or spouse visas. Countries like Canada, the UK, Australia, Denmark, the Netherlands, Finland, and New Zealand allow spouses and partners to work. However, countries like Hong Kong and the USA only allow married dependent visa holders to work.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSix">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                                    What Are Popular Countries for Studying Abroad?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    Students often look for the best countries to study abroad. The USA ranks first for international students, followed by the UK, China, and Canada. Many other countries also offer great opportunities, including Australia, New Zealand, the Netherlands, France, Germany, and Spain.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                                    How Long Can You Stay?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    Most countries allow you to stay for about 90 days for tourism. Some may allow longer stays, while others might allow fewer days. The duration of your stay depends on the type of visa you have.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEight">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                                    How Can Adorable Vacation LLP Help?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    Adorable Vacation LLP provides expert visa consultancy services to help you find the best visa for your needs and guide you through the visa application process.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingNine">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                                    Do Visa Agents Help Prepare for the Visa Interview?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    Yes, our complete visa service will guide you through all the steps of the visa process, including helping you prepare your documents and providing information for the interview.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                                    What Are the Charges for Visa Assistance?
                                                </button>
                                            </h4>
                                            <div id="flush-collapseTen" className="accordion-collapse collapse show" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    The cost of visa assistance varies based on the type of visa and the country you’re applying to. For example:
                                                    <ul>
                                                        <li>UK visa assistance starts from INR 13,000.</li>
                                                        <li>USA visa assistance starts from INR 15,000.</li>
                                                        <li>Canada visa assistance starts from INR 14,000.</li>
                                                        <li>Australia visa assistance starts from INR 11,000.</li>
                                                        <li>Schengen visa assistance starts from INR 10,500.</li>
                                                    </ul>
                                                    For more details, contact us at <a href="tel:9163676037">+91 91636-76037</a> or <a href="tel:9147061493">+91 91470-61493</a>.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b>VISA <span className="tomato"> Reviews</span></b></h3>
                                            <h4><i>"Your Valuable Guest "</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3">

                            <BookingBox />

                        </div>

                    </div>
                </div>
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91636-76037">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                    </a>
                </div>

            </>
        );
    }
}

export default VisaServices;