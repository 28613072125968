import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ApiURL from "../../../api/ApiURL";
import InclusionExclusion from '../Sikkim_Darjeeling/InclusionExclusion_sikkim_bagdogra_package';
import BookingBox from '../BookingBox';

class Sikkim_Tour_9N_10D_bagdogra_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };
    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Sikkim-Package-Tour-Booking-from-Bagdogra.webp";
        const packageTopBanner2ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Sikkim-Package-Tour-Plan-from-Bagdogra-Airport.webp";
        const sightseen1ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Gangtok-to-Lachen-Tour-packages.webp";
        const sightseen2ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Gangtok-to-Pelling-Tour-cost.webp";
        const sightseen3ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Sikkim-Tour-Packages-from-Bagdogra-Airport.webp";
        const sightseen4ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Bagdogra-Airport-to-Sikkim-Package.webp";
        const sightseen5ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Bagdogra-airport-to-Sikkim-Packages.webp";
        const sightseen6ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Bagdogra-to-Sikkim-Package.webp";
        const sightseen7ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Bagdogra-to-Sikkim-Tour.webp";
        const sightseen8ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Sikkim-Tour-Package-Cost-from-Bagdogra.webp";
        const sightseen9ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Sikkim-Tour-Plan-from-Bagdogra.webp";
        const sightseen10ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Sikkim-Packages-from-Bagdogra.webp";
        const sightseen11ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Sikkim-Package-Tour-Itinerary-from-Bagdogra.webp";
        const sightseen12ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Sikkim-Tour-Package-from-Bagdogra.webp";
        const packageDestinationBannerImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Sikkim-Travel-Agents.webp";
        const brandedTourackageImage_1_URL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Cilantro-Hotels.webp";
        const brandedTourackageImage_2_URL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Ramada-Hotels.webp";
        const Topbanner0ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Sikkim-Tour-Package-with-Helicopter-Ride.webp";
        const Topbanner1ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/River-Rafting-at-Teesta-River.webp";
        const TopbannerImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Sikkim-Holidays-Package-from-Bagdogra-Airport.webp";
        const navDay1ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Sikkim-Package-Tour-from-Bagdogra-Airport.webp";
        const navDay2ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Sikkim-Package-Tour-Cost-from-Bagdogra.webp";
        const navDay3ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Sikkim-Tour-from-Bagdogra.webp";
        const navDay4ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Gangtok-to-Lachen-Tour.webp";
        const navDay5ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Bagdogra airport-to-Sikkim-Packages.webp";
        const navDay6ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/North-Sikkim-Package-Tour.webp";
        const navDay7ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/North-Sikkim-from-Gangtok.webp";
        const navDay8ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Sikkim-Trip.webp";
        const navDay9ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Gangtok-to-Pelling-Tour.webp";
        const navDay10ImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/Best-Sikkim-Tour-Plan.webp";
        const hotPackagesDataList = this.props.HotPackagesData;
        const hotPackagesView = hotPackagesDataList.length > 0 ?
            (
                hotPackagesDataList.map((dataItem, i) => {
                    const hotImgURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/" + dataItem.ImageName;
                    // const contactURL = dataItem.Url;
                    return (<div className="col-md-4" key={i}>
                        <div className="card h-100">
                            <img src={hotImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}/-</span></h6>
                                <p className="card-text">{dataItem.Description}</p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">{dataItem.MutedText}</small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) :
            (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const hotelDataList = this.props.HotelsData;
        const hotelDataView = hotelDataList.map((hotelItem, i) => {
            const hotelImageURL =
                ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/" + hotelItem.ImageName;
            return (
                <div className="col-md-3" key={i}>
                    <img
                        src={hotelImageURL}
                        className="rounded mx-auto d-block"
                        alt={hotelItem.AllternateText}
                    />
                    <br />
                    <span>
                        <b>{hotelItem.HotelName}</b> |{" "}
                        <span className="tomato">{hotelItem.HotelStar}</span>
                    </span>
                    <br />
                    <span className="rating-box">
                        <span className="star">{hotelItem.RatingBoxStar}</span>
                        <b className="rating-value">{hotelItem.RatingValue}</b>
                    </span>{" "}
                    &nbsp;<b>{hotelItem.Reviews}</b>
                </div>
            );
        });

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Sikkim_Bagdogra_Package_9N_10DImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })


        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container">
                                        <h1 className="content-desktop" style={{ fontSize: "2.0vw" }}><center><strong>SIKKIM TOUR FROM BAGDOGRA AIRPORT</strong></center></h1>
                                        <h1 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>SIKKIM TOUR FROM BAGDOGRA AIRPORT</strong></center>
                                            <hr />
                                        </h1>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                5 Destinations:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Gangtok
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Lachen
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Lachung
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Pelling
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Ravangla
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Sikkim Tour Package From Bagdogra Airport</strong>, you have come to the right place!
                                        </p>
                                        <hr />
                                        <br />
                                        <br />
                                        <h2 className="content-desktop p-2" style={{ fontSize: "1.4vw" }}> Looking for <strong>Sikkim tour Package from Bagdogra ?</strong> <a href='tel:9147061467' class="tomato">To Book a Sikkim Package tour from Bagdogra  ☎ Call Mr.soumya+91-91470-61467</a></h2>
                                        <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> Looking for <strong>Sikkim tour Package from Bagdogra ?</strong> <a href='tel:9147061467' class="tomato">To Book a Sikkim Package tour from Bagdogra  ☎ Call Mr.soumya+91-91470-61467</a></h2>
                                    </div>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>BEST SIKKIM PACKAGE TOUR OPERATOR FROM BAGDOGRA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BEST SIKKIM PACKAGE TOUR OPERATOR FROM BAGDOGRA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>Sikkim, a state in northeastern India, is known for its beautiful landscapes and peaceful surroundings. A <strong>Sikkim Tour Package From Bagdogra Airport</strong> with Adorable Vacation LLP offers a chance to escape the busy city life and enjoy the calm of nature. On this trip, you can wake up to the stunning sunrise over Mt. Kanchenjunga, one of the tallest mountains in the world, while listening to birds sing and streams flow. The fresh morning air, mixed with the smell of tea and the warm rays of sunlight breaking through the clouds, creates the perfect start to a day of exploring Sikkim.</p>
                                    <p>Sikkim is a place of amazing views, rich Buddhist culture, and friendly locals. Its landscapes include green forests, rolling hills, and snow-covered mountains, making it a paradise for nature lovers. The <strong>Sikkim Packages from Bagdogra</strong> lets visitors enjoy scenic trekking paths, breathtaking views, and local villages filled with smiles.</p>
                                    <p>If you're looking to break away from your daily routine and experience the beauty of nature, this 9-night, 10-day <strong>Sikkim tour from Bagdogra</strong> is an ideal choice. With stunning views, a peaceful environment, and a unique culture, this trip promises to be unforgettable. Don't wait— <strong>Sikkim Tour Package booking from Bagdogra</strong> today!</p>
                                    <p>Adorable Vacation LLP is the best <strong>Sikkim Package Tour Operator from Bagdogra</strong>, offering a wide range of <strong>Customized Sikkim Packages from Bagdogra</strong> to suit all types of travelers. Whether you're looking for a relaxing vacation, an adventure-packed trip, or a cultural experience, Adorable Vacation has you covered. Book your <strong>Sikkim package from Bagdogra Airport</strong> today and make your dream trip come true!</p>
                                    <br /> <br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen1ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                                                    <img src={sightseen2ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                                                    <img src={sightseen3ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /> <br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>BAGDOGRA AIRPORT (IXB)</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BAGDOGRA AIRPORT (IXB)</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>Bagdogra Airport, also known as Civil Aerodrome Bagdogra, is a busy airport for both domestic and international travelers. Located in the scenic town of Bagdogra, just 16 km from Siliguri in Darjeeling, this airport has changed a lot over the years. It started as an Indian Air Force base but is now a fully operating airport with flights to many cities in India and abroad.</p>
                                    <p>The addition of international flights has made Bagdogra Airport even more popular. With daily flights to major cities like Kolkata, Mumbai, Bangalore, Chennai, and Delhi, it is a top choice for people traveling to Sikkim, Darjeeling, Bhutan, and Northeast India. Its excellent connections make it a great starting point for tours to these beautiful regions.</p>
                                    <p>Bagdogra Airport also offers regular helicopter services to Gangtok, making it easy for visitors to travel quickly and comfortably.</p>
                                    <p>If you're planning a <strong>Sikkim Trip</strong>, Bagdogra Airport is a great option. With a wide flight network, including international routes, and easy access to top tourist spots, it’s the perfect place to begin your journey through the stunning landscapes of Sikkim, Darjeeling, Bhutan, and Northeast India.</p>
                                    <br />
                                    <h4><b>SOME INFORMATION ABOUT BAGDOGRA AIRPORT</b></h4>
                                    <br /> <br />
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <tbody>
                                                <tr>
                                                    <td><strong>Airport Name</strong></td>
                                                    <td>Customs Airport, Bagdogra (Civil Enclave)</td>
                                                </tr>
                                                <tr>
                                                    <td>Address</td>
                                                    <td>Civil Aerodrome, Bagdogra, Siliguri - 734421 (WB)</td>
                                                </tr>
                                                <tr>
                                                    <td>IATA Code</td>
                                                    <td>IXB</td>
                                                </tr>
                                                <tr>
                                                    <td>Elevation</td>
                                                    <td>126 m</td>
                                                </tr>
                                                <tr>
                                                    <td>Hub For</td>
                                                    <td>IndiGo, SpiceJet, Go First</td>
                                                </tr>
                                                <tr>
                                                    <td>Country</td>
                                                    <td>India</td>
                                                </tr>
                                                <tr>
                                                    <td>Coordinates</td>
                                                    <td>Latitude: 26.6811°N</td>
                                                </tr>
                                                <tr>
                                                    <td>Phone</td>
                                                    <td>0353 269 8431</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>FLIGHTS FOR</b> <strong>SIKKIM TOUR PACKAGES FROM BAGDOGRA AIRPORT</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>FLIGHTS FOR</b> <strong>SIKKIM TOUR PACKAGES FROM BAGDOGRA AIRPORT</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>AIRLINES</th>
                                                    <th>ORIGIN CITY</th>
                                                    <th>DEPARTURE</th>
                                                    <th>DESTINATION CITY</th>
                                                    <th>ARRIVAL TIME</th>
                                                    <th>FLIGHT DURATION</th>
                                                    <th>STOPS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="AIRLINES">Akasa Air (QP 1850)</td>
                                                    <td data-title="ORIGIN CITY">Bangalore [BLR]</td>
                                                    <td data-title="DEP. TIME">09:10</td>
                                                    <td data-title="DEST. CITY">Bagdogra [IXB]</td>
                                                    <td data-title="ARR.TIME">12:10</td>
                                                    <td data-title="DURATION">03 h 00 m</td>
                                                    <td data-title="STOPS">Non Stop</td>
                                                </tr>

                                                <tr>
                                                    <td data-title="AIRLINES">IndiGo (6E 443)</td>
                                                    <td data-title="ORIGIN CITY">Bangalore [BLR]</td>
                                                    <td data-title="DEP.TIME">08:15</td>
                                                    <td data-title="DEST.CITY">Bagdogra [IXB]</td>
                                                    <td data-title="ARR. TIME">11:05</td>
                                                    <td data-title="DURATION">02 h 50 m</td>
                                                    <td data-title="STOPS">Non Stop</td>
                                                </tr>

                                                <tr>
                                                    <td data-title="AIRLINES">SpiceJet (SG 8895)</td>
                                                    <td data-title="ORIGIN CITY">Bangalore [BLR]</td>
                                                    <td data-title="DEP.TIME">10:20</td>
                                                    <td data-title="DEST.CITY">Bagdogra [IXB]</td>
                                                    <td data-title="ARR. TIME">13:05</td>
                                                    <td data-title="DURATION">02 h 45 m</td>
                                                    <td data-title="STOPS">Non Stop</td>
                                                </tr>

                                                <tr>
                                                    <td data-title="AIRLINES">Air India (AI 6559)</td>
                                                    <td data-title="ORIGIN CITY">New Delhi [DEL]</td>
                                                    <td data-title="DEP.TIME">08:00</td>
                                                    <td data-title="DEST.CITY">Bagdogra [IXB]</td>
                                                    <td data-title="ARR.TIME">10:00</td>
                                                    <td data-title="DURATION">02 h 00 m</td>
                                                    <td data-title="STOPS">Non Stop</td>
                                                </tr>

                                                <tr>
                                                    <td data-title="AIRLINES">SpiceJet (SG 903)</td>
                                                    <td data-title="ORIGIN CITY">New Delhi [DEL]</td>
                                                    <td data-title="DEP.TIME">05:40</td>
                                                    <td data-title="DEST.CITY">Bagdogra [IXB]</td>
                                                    <td data-title="ARR.TIME">07:35</td>
                                                    <td data-title="DURATION">01 h 55 m</td>
                                                    <td data-title="STOPS">Non Stop</td>
                                                </tr>

                                                <tr>
                                                    <td data-title="AIRLINES">IndiGo (6E 5317)</td>
                                                    <td data-title="ORIGIN CITY">New Delhi [DEL]</td>
                                                    <td data-title="DEP.TIME">07:30</td>
                                                    <td data-title="DEST.CITY">Bagdogra [IXB]</td>
                                                    <td data-title="ARR.TIME">09:35</td>
                                                    <td data-title="DURATION">02 h 05 m</td>
                                                    <td data-title="STOPS">Non Stop</td>
                                                </tr>

                                                <tr>
                                                    <td data-title="AIRLINES">IndiGo (6E 6134)</td>
                                                    <td data-title="ORIGIN CITY">Kolkata [CCU]</td>
                                                    <td data-title="DEP.TIME">16:45</td>
                                                    <td data-title="DEST. CITY">Bagdogra [IXB]</td>
                                                    <td data-title="ARR.TIME">17:50</td>
                                                    <td data-title="DURATION">01 h 05 m</td>
                                                    <td data-title="STOPS">Non Stop</td>
                                                </tr>

                                                <tr>
                                                    <td data-title="AIRLINES">SpiceJet (SG 3594)</td>
                                                    <td data-title="ORIGIN CITY">Kolkata [CCU]</td>
                                                    <td data-title="DEP.TIME">11:05</td>
                                                    <td data-title="DEST.CITY">Bagdogra [IXB]</td>
                                                    <td data-title="ARR.TIME">12:35</td>
                                                    <td data-title="DURATION">01 h 30 m</td>
                                                    <td data-title="STOPS">Non Stop</td>
                                                </tr>

                                                <tr>
                                                    <td data-title="AIRLINES">Air India Express (IX 634)</td>
                                                    <td data-title="ORIGIN CITY">Kolkata [CCU]</td>
                                                    <td data-title="DEP.TIME">08:05</td>
                                                    <td data-title="DEST.CITY">Bagdogra [IXB]</td>
                                                    <td data-title="ARR.TIME">09:25</td>
                                                    <td data-title="DURATION">01 h 20 m</td>
                                                    <td data-title="STOPS">Non Stop</td>
                                                </tr>

                                                <tr>
                                                    <td data-title="AIRLINES">SpiceJet (SG 163)</td>
                                                    <td data-title="ORIGIN CITY">Mumbai [BOM]</td>
                                                    <td data-title="DEP.TIME">14:20</td>
                                                    <td data-title="DEST.CITY">Bagdogra [IXB]</td>
                                                    <td data-title="ARR.TIME">17:00</td>
                                                    <td data-title="DURATION">02 h 40 m</td>
                                                    <td data-title="STOPS">Non Stop</td>
                                                </tr>

                                                <tr>
                                                    <td data-title="AIRLINES">IndiGo (6E 5305)</td>
                                                    <td data-title="ORIGIN CITY">Mumbai [BOM]</td>
                                                    <td data-title="DEP.TIME">08:05</td>
                                                    <td data-title="DEST.CITY">Bagdogra [IXB]</td>
                                                    <td data-title="ARR.TIME">10:50</td>
                                                    <td data-title="DURATION">02 h 45 m</td>
                                                    <td data-title="STOPS">Non Stop</td>
                                                </tr>

                                                <tr>
                                                    <td data-title="AIRLINES">IndiGo (6E 149)</td>
                                                    <td data-title="ORIGIN CITY">Hyderabad [HYD]</td>
                                                    <td data-title="DEP.TIME">10:25</td>
                                                    <td data-title="DEST.CITY">Bagdogra [IXB]</td>
                                                    <td data-title="ARR.TIME">12:35</td>
                                                    <td data-title="DURATION">02 h 10 m</td>
                                                    <td data-title="STOPS">Non Stop</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="AIRLINES">IndiGo (6E 6518)</td>
                                                    <td data-title="ORIGIN CITY">Chennai [MAA]</td>
                                                    <td data-title="DEP.TIME">12:10</td>
                                                    <td data-title="DEST.CITY">Bagdogra [IXB]</td>
                                                    <td data-title="ARR.TIME">14:40</td>
                                                    <td data-title="DURATION">02 h 30 m</td>
                                                    <td data-title="STOPS">Non Stop</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>DURATION FOR SIKKIM TOUR PACKAGES FROM BAGDOGRA : 9N | 10D</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>DURATION FOR SIKKIM TOUR PACKAGES FROM BAGDOGRA : 9N | 10D</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>Places to Cover in <strong>Sikkim Tour Booking from Bagdogra</strong>: Gangtok 4N | Lachen 1N | Lachung 1N | Pelling 2N | Ravangla 1N </p>
                                    <p> Pickup & Drop point for <strong> Sikkim Trip</strong> :Bagdogra Airport [IXB]</p>
                                    <p><strong>Sikkim Package Tour Cost from Bagdogra</strong> : </p>
                                    <br /><br />
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>PLACES</th>
                                                    <th>ALTITUDE</th>
                                                    <th>CO-ORDINATES</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="PLACES">Gangtok</td>
                                                    <td data-title="ALTITUDE">1,650 m</td>
                                                    <td data-title="coord.">27.3314° N, 88.6138° E</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="PLACES">Lachen</td>
                                                    <td data-title="ALTITUDE">2,600 m</td>
                                                    <td data-title="coord">27.7167° N, 88.5577° E</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="PLACES">Lachung</td>
                                                    <td data-title="ALTITUDE">2,700 m</td>
                                                    <td data-title="coord">27.6891° N, 88.7430° E</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="PLACES">Tsomgo Lake</td>
                                                    <td data-title="ALTITUDE">3,753 m</td>
                                                    <td data-title="coord">27.3742° N, 88.7619° E</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="PLACES">Gurudongmar Lake</td>
                                                    <td data-title="ALTITUDE">5,154 m</td>
                                                    <td data-title="coord">28.0258° N, 88.7097° E</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="PLACES">Yumthang Valley</td>
                                                    <td data-title="ALTITUDE">3,700 m</td>
                                                    <td data-title="coord">27.8268° N, 88.6958° E</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="PLACES">Pelling</td>
                                                    <td data-title="ALTITUDE">1,800 m</td>
                                                    <td data-title="coord">27.3198° N, 88.2400° E</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="PLACES">Ravangla</td>
                                                    <td data-title="ALTITUDE">8,000 ft</td>
                                                    <td data-title="coord">27.3066° N, 88.3640° E</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="PLACES">Zero Point</td>
                                                    <td data-title="ALTITUDE">4,358 m</td>
                                                    <td data-title="coord">27.9305° N, 88.7346° E</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br /><br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen4ImageURL} alt="kashmir package tour from srinagar" />
                                                    <img src={sightseen5ImageURL} alt="kashmir package price from srinagar airport" />
                                                    <img src={sightseen6ImageURL} alt="kashmir packages from srinagar airport" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />

                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div className="card-body">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>SIKKIM PACKAGE TOUR ITINERARY FROM BAGDOGRA</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingOne"
                                                >
                                                    <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                        <span>
                                                            BAGDOGRA AIRPORT TO GANGTOK
                                                        </span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingTwo"
                                                >
                                                    <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                        <span> EX GANGTOK - TSOMGO LAKE, BABA MANDIR, NATHULA PASS</span>
                                                    </a>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingThree"
                                                >
                                                    <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                        <span>GANGTOK LOCAL SIGHTSEEING</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingFour"
                                                >
                                                    <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                        <span>GANGTOK TO LACHEN TRANSFER</span>
                                                    </a>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingFive"
                                                >
                                                    <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                        <span>GURUDONGMAR TOUR - LACHUNG TRANSFER</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingSix"
                                                >
                                                    <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                        <span>YUMTHANG VALLEY SIGHTSEEING, GANGTOK TRANSFER</span>
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingSeven"
                                                >
                                                    <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                        <span>GANGTOK TO PELLING TOUR</span>
                                                    </a>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingTen"
                                                >
                                                    <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                                                        <span>PELLING LOCAL SIGHTSEEING</span>
                                                    </a>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingTen"
                                                >
                                                    <a href="#day9" style={{ color: 'black' }}><b>DAY 9 → </b>
                                                        <span>TRANSFER TO RAVANGLA VIA BUDDHA PARK, TEMI TEA GARDEN</span>
                                                    </a>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    className="clickable"
                                                    data-target="panelsStayOpen-headingTen"
                                                >
                                                    <a href="#day10" style={{ color: 'black' }}><b>DAY 10 → </b>
                                                        <span>DROP TO BAGDOGRA AIRPORT</span>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>SIKKIM TOUR PACKAGE COST FROM BAGDOGRA AIRPORT</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>SIKKIM TOUR PACKAGE COST FROM BAGDOGRA AIRPORT</strong></h4>
                                </div>
                                <div className="card-body">
                                    <p>The cost of a <strong>Sikkim Tour Package Cost from Bagdogra</strong> depends on several factors, such as the length of the trip, the number of travelers, choice of car, and meal plan. Below are some estimated prices to help you plan your <strong>Sikkim Package Tour Booking from Bagdogra</strong>.</p>
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>List of Sikkim Packages</th>
                                                    <th>Duration</th>
                                                    <th>Price</th>
                                                    <th>Book Now</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="List of Sikkim">North Sikkim package Tour with Silk Route</td>
                                                    <td data-title="Duration">8N 9D</td>
                                                    <td data-title="Price">Rs. 21,699/-</td>
                                                    <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                        <i className="fab fa-whatsapp"></i> WhatsApp
                                                    </a></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <br /> <br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen10ImageURL} alt="North-Sikkim-Package-Tour-Itinerary-with-Silkroute" />
                                                    <img src={sightseen11ImageURL} alt="North-Sikkim-Tour-Package-Cost-with-Silkroute" />
                                                    <img src={sightseen12ImageURL} alt="North-Sikkim-Tour-Package-from-NJP" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br /> <br />
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>9N 10D SIKKIM TOUR PACKAGE COST EX BAGDOGRA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>9N 10D SIKKIM TOUR PACKAGE COST EX BAGDOGRA</strong></h4>
                                </div>
                                <div className="card-body">

                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">

                                                    <th>HOTEL CATEGORY</th>
                                                    <th>2 PAX</th>
                                                    <th>4 PAX</th>
                                                    <th>6 PAX</th>
                                                    <th>BOOK NOW</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="Category"><b>3 STAR</b></td>
                                                    <td data-title="2 Pax">₹ 54,300</td>
                                                    <td data-title="4 Pax">₹ 39,900</td>
                                                    <td data-title="6 Pax">₹ 33,700</td>
                                                    <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                        <i className="fab fa-whatsapp"></i> WhatsApp
                                                    </a></td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Category"><b>3 STAR DELUXE</b></td>
                                                    <td data-title="2 Pax">₹ 62,000</td>
                                                    <td data-title="4 Pax">₹ 47,600</td>
                                                    <td data-title="6 Pax">₹ 41,300</td>
                                                    <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                        <i className="fab fa-whatsapp"></i> WhatsApp
                                                    </a></td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Category"><b>4 STAR</b></td>
                                                    <td data-title="2 Pax">₹ 72,200</td>
                                                    <td data-title="4 Pax">₹ 57,800</td>
                                                    <td data-title="6 Pax">₹ 51,600</td>
                                                    <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                        <i className="fab fa-whatsapp"></i> WhatsApp
                                                    </a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT IS THE BEST TIME TO BOOK A <strong>SIKKIM PACKAGE TOUR BOOKING FROM BAGDOGRA AIRPORT</strong> ?</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT IS THE BEST TIME TO BOOK A <strong>SIKKIM PACKAGE TOUR BOOKING FROM BAGDOGRA AIRPORT</strong> ?</h4>
                                </div>
                                <div className="card-body">
                                    <p>Adorable Vacation suggests that the best times to book a Sikkim Packages from Bagdogra Airport are from March to the end of June and from October to mid-December. Spring, especially in May and June, is the best time to see the beautiful flowers like Rhododendrons, Primulas, and Poppies in Yumthang Valley, North Sikkim. If you want to see the stunning frozen Gurudongmar Lake surrounded by snow-capped mountains, plan your North Sikkim tour between October and mid-December.</p>
                                    <br /><br />

                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>Months</th>
                                                    <th>Temp. (Min - Max)</th>
                                                    <th>Season</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="Months">March to June</td>
                                                    <td data-title="Temp.">10°C - 15°C</td>
                                                    <td data-title="Season">Summer</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Months">June - August</td>
                                                    <td data-title="Temp.">15°C - 30°C</td>
                                                    <td data-title="Season">Summer</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Months">July to October</td>
                                                    <td data-title="Temp.">8° - 12°C</td>
                                                    <td data-title="Season">Autumn</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Months">October to February</td>
                                                    <td data-title="Temp.">8° - 4°C</td>
                                                    <td data-title="Season">Winter</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT ARE THE BEST ATTRACTIONS ON A <strong>CUSTOMIZED SIKKIM PACKAGES FROM BAGDOGRA</strong> ?</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT ARE THE BEST ATTRACTIONS ON A <strong>CUSTOMIZED SIKKIM PACKAGES FROM BAGDOGRA</strong> ?</h4>
                                </div>
                                <div className="card-body">
                                    <p>On your <strong>Bagdogra to Sikkim Package</strong>, you will visit many beautiful places. These include Gangtok, Tashi Viewpoint, Mahatma Gandhi Marg, Ganesh Tok, and the Himalayan Zoological Park. You can also explore Changu Lake, Baba Temple, Lachen, and Gurudongmar Lake. Other highlights are Lachung, Yumthang Valley, Thangu Valley, and Mount Katao. You’ll see Chungthang, Crow's Lake, and the stunning Seven Sisters Waterfalls.</p>
                                    <p>There are also Mangan, Chopta Valley, Cholamu Lake, and trekking spots like Green Lake and Tosar Lake. Don't miss Singhik, Phensang Monastery, Phodong Monastery, and Kabi Lungchok. You can visit Rimbi Waterfalls, Kanchenjunga Waterfalls, and Khecheopalri Lake. Pemyangse Monastery, Pelling Skywalk, Sangacholing Monastery, and Rabdentse Ruins are also worth seeing. Additionally, you can explore Singshore Bridge, British Dak Bunglow, Buddha Park, Namchi/Char Dham, Temi Tea Garden, and Samdruptse. Each of these places offers unique experiences and stunning views!</p>
                                    <br /><br />
                                    <h4><b>SIKKIM TOUR SIGHTSEEING PLACES AND ENTRY FEES</b></h4>
                                    <br /><br />
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>Places</th>
                                                    <th>Timings</th>
                                                    <th>Entry Fees</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="Places">Rumtek Monastery</td>
                                                    <td data-title="Timings">6:00 AM – 6:00 PM</td>
                                                    <td data-title="Entry Fees">Rs 10/-</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Namgyal Institute of Tibetology</td>
                                                    <td data-title="Timings">10:00 AM – 4:00 PM</td>
                                                    <td data-title="Entry Fees">Rs 10/-</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Ralang Monastery</td>
                                                    <td data-title="Timings">8:00 AM – 4:00 PM</td>
                                                    <td data-title="Entry Fees">Free Entry</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Phodong Monastery</td>
                                                    <td data-title="Timings">8:00 AM – 5:00 PM</td>
                                                    <td data-title="Entry Fees">Free Entry</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Sanga Choeling Monastery</td>
                                                    <td data-title="Timings">7:00 AM – 6:00 PM</td>
                                                    <td data-title="Entry Fees">Free Entry</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Ranka Monastery</td>
                                                    <td data-title="Timings">6:00 AM – 5:00 PM</td>
                                                    <td data-title="Entry Fees">Free Entry</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Nathula Pass</td>
                                                    <td data-title="Timings">7:00 AM – 2:00 PM</td>
                                                    <td data-title="Entry Fees">Rs 200/-</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Baba Harbhajan Singh Temple</td>
                                                    <td data-title="Timings">8:00 AM – 5:00 PM</td>
                                                    <td data-title="Entry Fees">Free Entry</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Tashi Viewpoint</td>
                                                    <td data-title="Timings">5:00 AM / 5:00 PM</td>
                                                    <td data-title="Entry Fees">Free Entry</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Himalayan Zoological Park</td>
                                                    <td data-title="Timings">9:00 AM – 5:00 PM</td>
                                                    <td data-title="Entry Fees">Rs 25/-</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Seven Sisters Waterfalls</td>
                                                    <td data-title="Timings">7:00 AM – 5:00 PM</td>
                                                    <td data-title="Entry Fees">Free Entry</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Banjhakri Falls</td>
                                                    <td data-title="Timings">7:00 AM – 5:30 PM</td>
                                                    <td data-title="Entry Fees">Rs 50/-</td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>HOW TO REACH GANGTOK</h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HOW TO REACH GANGTOK</h4>
                                </div>
                                <div className="card-body">
                                    <p>Gangtok is easy to get to from Bagdogra Airport. After arriving at Bagdogra, travelers can book private taxis, cars, or shared jeeps to reach Gangtok. The prices can vary based on the number of passengers and the travel season. For a shared car, <strong>Gangtok North Sikkim Package Tour from Bagdogra Airport</strong> is usually between Rs. 350 and Rs. 500 per person.</p>
                                    <p>Another option is to fly into Pakyong Airport (PYG). From there, you can hire a cab to reach Gangtok, which is about 34 km away and takes around 1.5 hours. The drive offers beautiful views of hills, mountains, forests, lakes, and streams. The cost for a dedicated car from Pakyong Airport to Gangtok ranges from Rs. 2500 to Rs. 3500, depending on the season and number of tourists.</p>
                                    <p>For a more adventurous trip, you can take a helicopter ride from Bagdogra to Burtuk, which is 4.2 km from Gangtok city. This daily helicopter service can carry up to five passengers with limited luggage. The 35-minute ride costs about Rs. 2500 and offers stunning views of the mountains.</p>
                                    <p>Travelers should keep in mind that if they arrive at Bagdogra Airport during peak season without a pre-booked car, they may face difficulties. Without prior booking, prices can be much higher, and availability is not guaranteed. It’s best to book a car in advance, which can be arranged through a <strong>Gangtok to Lachen Tour</strong>.</p>
                                    <br /><br />
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>From</th>
                                                    <th>To</th>
                                                    <th>Distance/Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="From">NJP</td>
                                                    <td data-title="To">Gangtok</td>
                                                    <td data-title="Distance/Time">4 hr 20 min (117.9 km) via NH10</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Bagdogra Airport</td>
                                                    <td data-title="To">Gangtok</td>
                                                    <td data-title="Distance/Time">4 hr 31 min (124.1 km) via NH10</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Pakyong Airport</td>
                                                    <td data-title="To">Gangtok</td>
                                                    <td data-title="Distance/Time">1 hr 25 min (26.7 km) via NH 717A and NH10</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="From">Siliguri Junction</td>
                                                    <td data-title="To">Gangtok</td>
                                                    <td data-title="Distance/Time">4 hr 11 min (112.3 km) via NH10</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DISTANCES TO NOTE FOR <strong>SIKKIM PACKAGE TOUR FROM BAGDOGRA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DISTANCES TO NOTE FOR <strong>SIKKIM PACKAGE TOUR FROM BAGDOGRA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>Places</th>
                                                    <th>Distance/Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="Places">NJP Rly Station to Gangtok</td>
                                                    <td data-title="Distance/Time">4 hr 5 min (118.1 km) via NH10</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Bagdogra Airport to Gangtok</td>
                                                    <td data-title="Distance/Time">4 hr 21 min (122.9 km) via NH10</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Gangtok to Tsomgo [Chhanggu] Lake</td>
                                                    <td data-title="Distance/Time">58 min (35.7 km) via Jawaharlal Nehru Rd</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Tsomgo Lake to Baba Mandir</td>
                                                    <td data-title="Distance/Time">28 min (16.8 km) via NH 310</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Tsomgo Lake to Nathula Pass</td>
                                                    <td data-title="Distance/Time">24 min (17.0 km) via NH 310</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Gangtok to Chungthang</td>
                                                    <td data-title="Distance/Time">3 hr 35 min (95.4 km) via Singtam - Chungthang Rd</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Gangtok to Lachen</td>
                                                    <td data-title="Distance/Time">4 hr 32 min (114.0 km) via Gangtok-Chungthang Rd</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Lachen to Gurudongmar Lake</td>
                                                    <td data-title="Distance/Time">2 hr 27 min (65.9 km) via Gurudongmar Road</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Gangtok to Lachung</td>
                                                    <td data-title="Distance/Time">4 hr 19 min (109.2 km) via Gangtok-Chungthang Rd</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Lachen to Lachung</td>
                                                    <td data-title="Distance/Time">2 hr 43 min (81.8 km) via NH206</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Lachung to Yumthang Valley</td>
                                                    <td data-title="Distance/Time">1 hr 20 min (25 km)</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Lachung to Zero Point</td>
                                                    <td data-title="Distance/Time">2 hr 20 min (50 km)</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Gangtok to Pelling</td>
                                                    <td data-title="Distance/Time">4 hr 45 min (128.9 km) via NH10</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Pelling to Khecheopalri Lake</td>
                                                    <td data-title="Distance/Time">1 hr 20 min (31.6 km) via Pelling-Rimbi Rd</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Gangtok to Rumtek Monastery</td>
                                                    <td data-title="Distance/Time">59 min (21.8 km) via NH10 and Ranipool-Rumtek-Sang Rd</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Gangtok to Mangan</td>
                                                    <td data-title="Distance/Time">2 hr 30 min (58.9 km) via Gangtok-Chungthang Rd</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Places">Gangtok to Zuluk</td>
                                                    <td data-title="Distance/Time">3 hr 36 min (95.7 km) via Jawaharlal Nehru Rd</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>POPULAR TRAINS YOU CAN BOOK FOR → <strong>SIKKIM PACKAGE TOUR FROM SEALDAH / HOWRAH STATION</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>POPULAR TRAINS YOU CAN BOOK FOR → <strong>SIKKIM PACKAGE TOUR FROM SEALDAH / HOWRAH STATION</strong></h4>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>Train No</th>
                                                    <th>Train Name</th>
                                                    <th>Dep. Station</th>
                                                    <th>Dep. Time</th>
                                                    <th>Arr. Station</th>
                                                    <th>Arr. Time</th>
                                                    <th>Frequency</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="Train No">22301</td>
                                                    <td data-title="Train Name">Vande Bharat Express</td>
                                                    <td data-title="Dep. Station">Howrah (HWH)</td>
                                                    <td data-title="Dep. Time">05:55</td>
                                                    <td data-title="Arr. Station">New Jalpaiguri Jn (NJP)</td>
                                                    <td data-title="Arr. Time">13:25</td>
                                                    <td data-title="Frequency">Except Wednesday</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Train No">22302</td>
                                                    <td data-title="Train Name">Vande Bharat Express</td>
                                                    <td data-title="Dep. Station">New Jalpaiguri Jn (NJP)</td>
                                                    <td data-title="Dep. Time">15:05</td>
                                                    <td data-title="Arr. Station">Howrah (HWH)</td>
                                                    <td data-title="Arr. Time">22:35</td>
                                                    <td data-title="Frequency">Except Wednesday</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Train No">12343</td>
                                                    <td data-title="Train Name">Darjeeling Mail</td>
                                                    <td data-title="Dep. Station">Sealdah (SDAH)</td>
                                                    <td data-title="Dep. Time">22:05</td>
                                                    <td data-title="Arr. Station">New Jalpaiguri Jn (NJP)</td>
                                                    <td data-title="Arr. Time">08:15</td>
                                                    <td data-title="Frequency">Daily</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Train No">12377</td>
                                                    <td data-title="Train Name">Padatik Express</td>
                                                    <td data-title="Dep. Station">Sealdah (SDAH)</td>
                                                    <td data-title="Dep. Time">23:00</td>
                                                    <td data-title="Arr. Station">New Jalpaiguri Jn (NJP)</td>
                                                    <td data-title="Arr. Time">09:15</td>
                                                    <td data-title="Frequency">Daily</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Train No">13149</td>
                                                    <td data-title="Train Name">Kanchan Kanya Express</td>
                                                    <td data-title="Dep. Station">Sealdah (SDAH)</td>
                                                    <td data-title="Dep. Time">20:30</td>
                                                    <td data-title="Arr. Station">New Mal Jn (NMZ)</td>
                                                    <td data-title="Arr. Time">09:31</td>
                                                    <td data-title="Frequency">Daily</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Train No">13147</td>
                                                    <td data-title="Train Name">UttarBanga Express</td>
                                                    <td data-title="Dep. Station">Sealdah (SDAH)</td>
                                                    <td data-title="Dep. Time">19:35</td>
                                                    <td data-title="Arr. Station">Falakata (FLK)</td>
                                                    <td data-title="Arr. Time">08:38</td>
                                                    <td data-title="Frequency">Daily</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Train No">13141</td>
                                                    <td data-title="Train Name">Teesta Torsa Express</td>
                                                    <td data-title="Dep. Station">Sealdah (SDAH)</td>
                                                    <td data-title="Dep. Time">13:40</td>
                                                    <td data-title="Arr. Station">New Maynaguri (NMX)</td>
                                                    <td data-title="Arr. Time">04:22</td>
                                                    <td data-title="Frequency">Daily</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Train No">12345</td>
                                                    <td data-title="Train Name">SaraiGhat Express</td>
                                                    <td data-title="Dep. Station">Howrah (HWH)</td>
                                                    <td data-title="Dep. Time">15:50</td>
                                                    <td data-title="Arr. Station">New Jalpaiguri (NJP)</td>
                                                    <td data-title="Arr. Time">01:40</td>
                                                    <td data-title="Frequency">Daily</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Train No">12517</td>
                                                    <td data-title="Train Name">Garib Rath</td>
                                                    <td data-title="Dep. Station">Kolkata (KOOA)</td>
                                                    <td data-title="Dep. Time">21:40</td>
                                                    <td data-title="Arr. Station">New Jalpaiguri (NJP)</td>
                                                    <td data-title="Arr. Time">07:45</td>
                                                    <td data-title="Frequency">Sunday, Thursday</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <h4><b>HOWRAH STATION TO NJP, VANDE BHARAT 22301 TRAIN SCHEDULE [EXCEPT WEDNESDAY]</b></h4>
                                    <br /><br />
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>Train No</th>
                                                    <th>Train Name</th>
                                                    <th>Dep. Station</th>
                                                    <th>Dep. Time</th>
                                                    <th>Arr. Station</th>
                                                    <th>Arr. Time</th>
                                                    <th>Frequency</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="Train No">22301</td>
                                                    <td data-title="Train Name">Vande Bharat Express</td>
                                                    <td data-title="Dep. Station">Howrah (HWH)</td>
                                                    <td data-title="Dep. Time">05:55</td>
                                                    <td data-title="Arr. Station">New Jalpaiguri Jn (NJP)</td>
                                                    <td data-title="Arr. Time">13:25</td>
                                                    <td data-title="Frequency">Except Wednesday</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Train No">22302</td>
                                                    <td data-title="Train Name">Vande Bharat Express</td>
                                                    <td data-title="Dep. Station">New Jalpaiguri Jn (NJP)</td>
                                                    <td data-title="Dep. Time">15:05</td>
                                                    <td data-title="Arr. Station">Howrah (HWH)</td>
                                                    <td data-title="Arr. Time">22:35</td>
                                                    <td data-title="Frequency">Except Wednesday</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Train No">12343</td>
                                                    <td data-title="Train Name">Darjeeling Mail</td>
                                                    <td data-title="Dep. Station">Sealdah (SDAH)</td>
                                                    <td data-title="Dep. Time">22:05</td>
                                                    <td data-title="Arr. Station">New Jalpaiguri Jn (NJP)</td>
                                                    <td data-title="Arr. Time">08:15</td>
                                                    <td data-title="Frequency">Daily</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Train No">12377</td>
                                                    <td data-title="Train Name">Padatik Express</td>
                                                    <td data-title="Dep. Station">Sealdah (SDAH)</td>
                                                    <td data-title="Dep. Time">23:00</td>
                                                    <td data-title="Arr. Station">New Jalpaiguri Jn (NJP)</td>
                                                    <td data-title="Arr. Time">09:15</td>
                                                    <td data-title="Frequency">Daily</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Train No">13149</td>
                                                    <td data-title="Train Name">Kanchan Kanya Express</td>
                                                    <td data-title="Dep. Station">Sealdah (SDAH)</td>
                                                    <td data-title="Dep. Time">20:30</td>
                                                    <td data-title="Arr. Station">New Mal Jn (NMZ)</td>
                                                    <td data-title="Arr. Time">09:31</td>
                                                    <td data-title="Frequency">Daily</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Train No">13147</td>
                                                    <td data-title="Train Name">UttarBanga Express</td>
                                                    <td data-title="Dep. Station">Sealdah (SDAH)</td>
                                                    <td data-title="Dep. Time">19:35</td>
                                                    <td data-title="Arr. Station">Falakata (FLK)</td>
                                                    <td data-title="Arr. Time">08:38</td>
                                                    <td data-title="Frequency">Daily</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Train No">13141</td>
                                                    <td data-title="Train Name">Teesta Torsa Express</td>
                                                    <td data-title="Dep. Station">Sealdah (SDAH)</td>
                                                    <td data-title="Dep. Time">13:40</td>
                                                    <td data-title="Arr. Station">New Maynaguri (NMX)</td>
                                                    <td data-title="Arr. Time">04:22</td>
                                                    <td data-title="Frequency">Daily</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Train No">12345</td>
                                                    <td data-title="Train Name">SaraiGhat Express</td>
                                                    <td data-title="Dep. Station">Howrah (HWH)</td>
                                                    <td data-title="Dep. Time">15:50</td>
                                                    <td data-title="Arr. Station">New Jalpaiguri (NJP)</td>
                                                    <td data-title="Arr. Time">01:40</td>
                                                    <td data-title="Frequency">Daily</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Train No">12517</td>
                                                    <td data-title="Train Name">Garib Rath</td>
                                                    <td data-title="Dep. Station">Kolkata (KOOA)</td>
                                                    <td data-title="Dep. Time">21:40</td>
                                                    <td data-title="Arr. Station">New Jalpaiguri (NJP)</td>
                                                    <td data-title="Arr. Time">07:45</td>
                                                    <td data-title="Frequency">Sunday, Thursday</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <h4><b>HOWRAH STATION TO NJP, VANDE BHARAT 22301 TRAIN SCHEDULE [EXCEPT WEDNESDAY]</b></h4>
                                    <br />
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>Station</th>
                                                    <th>Arrival</th>
                                                    <th>Departure</th>
                                                    <th>Halt</th>
                                                    <th>Distance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="Station">Howrah Jn [HWH]</td>
                                                    <td data-title="Arrival">Starts</td>
                                                    <td data-title="Departure">05:55</td>
                                                    <td data-title="Halt">-</td>
                                                    <td data-title="Distance">0 km</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Station">Bolpur Shantiniketan [BHP]</td>
                                                    <td data-title="Arrival">07:43</td>
                                                    <td data-title="Departure">07:45</td>
                                                    <td data-title="Halt">2 min</td>
                                                    <td data-title="Distance">145 km</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Station">Malda Town [MLTD]</td>
                                                    <td data-title="Arrival">10:32</td>
                                                    <td data-title="Departure">10:35</td>
                                                    <td data-title="Halt">3 min</td>
                                                    <td data-title="Distance">329.5 km</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Station">Barsoi Jn [BOE]</td>
                                                    <td data-title="Arrival">11:50</td>
                                                    <td data-title="Departure">11:52</td>
                                                    <td data-title="Halt">2 min</td>
                                                    <td data-title="Distance">419.7 km</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Station">New Jalpaiguri Jn [NJP]</td>
                                                    <td data-title="Arrival">13:25</td>
                                                    <td data-title="Departure">Ends</td>
                                                    <td data-title="Halt">-</td>
                                                    <td data-title="Distance">564.6 km</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br /><br />
                                    <h4><b>NJP TO HOWRAH STATION, VANDE BHARAT 22302 TRAIN SCHEDULE [EXCEPT WEDNESDAY]</b></h4>
                                    <br />
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>Station</th>
                                                    <th>Arrival</th>
                                                    <th>Departure</th>
                                                    <th>Halt</th>
                                                    <th>Distance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="Station">New Jalpaiguri Jn [NJP]</td>
                                                    <td data-title="Arrival">Starts</td>
                                                    <td data-title="Departure">15:05</td>
                                                    <td data-title="Halt">-</td>
                                                    <td data-title="Distance">0 km</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Station">Barsoi Jn [BOE]</td>
                                                    <td data-title="Arrival">16:44</td>
                                                    <td data-title="Departure">16:46</td>
                                                    <td data-title="Halt">2 min</td>
                                                    <td data-title="Distance">145 km</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Station">Malda Town [MLTD]</td>
                                                    <td data-title="Arrival">17:50</td>
                                                    <td data-title="Departure">17:53</td>
                                                    <td data-title="Halt">3 min</td>
                                                    <td data-title="Distance">235 km</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Station">Bolpur Shantiniketan [BHP]</td>
                                                    <td data-title="Arrival">20:22</td>
                                                    <td data-title="Departure">20:24</td>
                                                    <td data-title="Halt">2 min</td>
                                                    <td data-title="Distance">420 km</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="Station">Howrah Jn [HWH]</td>
                                                    <td data-title="Arrival">22:35</td>
                                                    <td data-title="Departure">Ends</td>
                                                    <td data-title="Halt">-</td>
                                                    <td data-title="Distance">565 km</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            <br /><br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>DO'S AND DON'TS FOR YOUR</b> <strong>SIKKIM TOUR PLAN FROM BAGDOGRA</strong></h3>
                                    <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>DO'S AND DON'TS FOR YOUR</b> <strong>SIKKIM TOUR PLAN FROM BAGDOGRA</strong></h4>
                                </div>
                                <div className="card-body">
                                    <ul>
                                        <li>•	Make sure to get the proper permits before visiting restricted areas like Tsomgo Lake and Nathula Pass.</li><br />
                                        <li>• Always carry your ID and a photocopy with you.</li><br />
                                        <li>• Ask for permission before taking photos of local people.</li><br />
                                        <li>• Dress modestly when visiting religious sites and monasteries.</li><br />
                                        <li>• Respect the customs and culture of the people in Sikkim.</li><br />
                                        <li>• It's a good idea to travel with a local guide or experienced driver.</li><br />
                                        <li>• Do not disturb plants or animals while you’re traveling or trekking.</li><br />
                                        <li>• Don’t litter during your tour from Bagdogra to Sikkim.</li><br />
                                        <li>• Take all your waste with you, like empty plastic bottles and bags.</li><br />
                                        <li>• Dispose of waste only in dustbins.</li><br />
                                        <li>• Don’t pick flowers from trees or plants along the roads.</li><br />
                                        <li>• Accept food offered by local people; it’s a sign of hospitality.</li><br />
                                        <li>• Don’t swim in lakes or rivers in protected areas.</li><br />
                                        <li>• Drink plenty of water to stay hydrated while traveling and trekking.</li><br />
                                        <li>• Remember, Sikkim is a smoke-free state, so be cautious if you smoke.</li><br />
                                        <li>• Always remove your shoes before entering sacred places and monasteries.</li><br />
                                        <li>• Avoid buying products made from endangered species or animals.</li><br />
                                        <li>• Be careful near waterfalls, as it can be easy to slip and get hurt.</li><br />

                                    </ul>
                                    <br /><br />
                                    <table className="image-table">
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <img src={sightseen7ImageURL} alt="kashmir tour packages" />
                                                    <img src={sightseen8ImageURL} alt="kashmir trip from srinagar airport" />
                                                    <img src={sightseen9ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            </div>
                            <br /><br /><br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                DAY WISE <strong><span>SIKKIM PACKAGE TOUR ITINERARY FROM BAGDOGRA</span></strong>
                                            </h2>
                                            <h4>9 Nights 10 Days <strong>Sikkim Tour Packages</strong></h4>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--end Short description--> */}
                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    DAY 1  – ARRIVAL AT IXB / NJP / SILIGURI AND TRANSFER TO GANGTOK [125 KMS/5 HR]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Welcome to the Cleanest State in India - Sikkim, and transfer to the capital city. As the aircraft lands at the nearest airport, our representative waits outside to meet & greet on arrival at Bagdogra and drives towards Gangtok (Alt.5550Ft/1675M). The drive is about 4 hours following the most scenic way through the beautiful lanes of Sikkim along with the picturesque Teesta River.
                                                    </p>
                                                    <p>On arrival, check into the beautiful view rooms of the hotel well equipped with modern amenities and sink into the soft bed to get rid of the exhaustion. In the evening, take a stroll in the neighbourhood or enjoy some hot coffee sitting at the balcony while admiring the beauty of the place.
                                                        Overnight at Gangtok.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day2"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    DAY 02: DAY EXCURSION TO CHANGU LAKE, BABA MANDIR AND NATHULA PASS
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        As the morning sores in, the warm rays of the sun knock through the window to give a start-up call for the second day. Enjoy breakfast at the hotel in its peaceful surroundings, and load the bags with dry foods before leaving for a full day trip to Tsomgo Lake with Baba Mandir / Nathula Pass Excursion.
                                                    </p>
                                                    <p>Tsomgo Lake (12400ft) - Also known as Changu Lake is an oval shaped glacial lake which is only 40 km from Gangtok City, and almost about 1Km in length with 15 meters depth. The name Tsomgo is made of two words in Bhutia Language 'Tso' means 'lake' and 'Mgo' means 'head' in a short 'source of the lake'. This high-altitude lake is surrounded by snow-capped steep mountains and remains frozen during wintertime. As summer beings to step-in, the glacier melts and the lake gets its origin. The locals consider the lake to be sacred, and it reflects different colors with the change in seasons.</p>
                                                    <p>New Baba Harbhajan Singh Mandir - The Baba Mandir was built in the Memorial of Baba Harbhajan Singh or the 'Hero of Nathula', a soldier in the Indian Army. It is situated at a distance of 12Km from Tsomgolake, and 52Km from Gangtok city. Baba Harbhajan served as a part of the border patrol force, and in the year 1968 while escorting a mule column he fell on a fast-flowing stream and got carried away by the powerful water current. Soldiers found his remains after 3 days and cremated with full military honour along with a shrine on his Samadhi built as per Baba’s wish, which he expressed through his friend post-death. The original bunker of Baba Harbhajan Singh is located further up near the Gnathang valley which is the Old Baba Mandir but because of its difficult terrain tourist can’t reach there so they build a New Mandir for easy access. This shrine believed to have wish-fulfilling powers along with that Baba is still guarding the international border between India and China. On Sundays, a 'Langar' runs at the temple, which provides free meals to the visitors.</p>
                                                    <p>Nathula Pass - A major mountain pass in the Himalayas in East Sikkim district, which is a corridor of passage between India and Tibet before it was closed in 1962 but reopened in 2006. Located around 56 km from Gangtok at an altitude of 14450 ft, the road to Nathula is the highest motorable road, passes through the Tsomgo lake, and requires an entry permit to visit. The view from this international border is breath-taking and allows the tourist to get a glimpse of China on the other side along with their Chinese militants. On a clear day, the road winding down the Chumbi valley can be seen.
                                                        (Nathula Pass is closed on every Tuesday and Permit depends on Weather condition) and  ALWAYS ON EXTRA PAYMENT BASIS
                                                        Overnight at Gangtok.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day3"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 03: GANGTOK TO LACHEN (130 KMS/ 6 HRS)
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>After the blackness of night, Earth's star rises on the horizon, spreading her gold in every direction, to wake-up the dreamy city of Gangtok, to kick start the day. Get the tummy refilled with heavy breakfast before leaving for a 6 hours long journey towards Lachen 130 Km. Post breakfast, around 10 a.m. leave for Lachen via NH 31A. En-route visit Sightseeing:</p>
                                                    <p>KabiLungstok - It's a historic place, located on North Sikkim Highway. This is the where a friendship treaty between Lepcha Chief Te-Kung-Tek and the Bhutia Chief Khey-Bum-Sar was signed. A small stone pillar was built to mark the location where the treaty was signed.</p>
                                                    <p>Seven Sister Waterfalls - The name is given to this waterfall as it makes its descent in seven stages, before gushing down through the dense forest and hitting the rock. This magnificent fall on the way to Lachen is sure to allure the tourist with its beauty in the background of lush-green Mountain. To facilitate the tourists to savor the pristine beauty and to be with nature, the Tourism Department has set up a waiting shed and cafeteria.</p>
                                                    <p>Naga Waterfalls - Another picturesque waterfall in North Sikkim near the confluence of Lachen-Chu and Lachung-Chu at Chungthang. This is the most scenic waterfall with the longest route and ice-cold water surrounded by forest in a peaceful domain. Lunch will be served at the Hotel after reaching Lachen in the evening.</p>
                                                    <p>Lachen - The name Lachen means "Big Pass" nestled at an altitude of 8838 ft in North Sikkim, this peaceful place is secluded far from the noisy city between Chopta Valley and Gurudongmar Lake. The small town is beautifully decorated with little hamlets in the background of the Lower Himalayas with their snowy cliffs and tall pine trees.</p>
                                                    <p>After check-in to the hotel, head straight towards the cozy room equipped with basic amenities and facing the beautiful green mountain. This remote place doesn't have much to offer the tourist except for its natural beauty, so one can spend the evening tasting some local Sikkim flavors while admiring nature.
                                                        Overnight at Lachen.
                                                    </p>
                                                    <br />

                                                    <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="Kashmir Tour Packages" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Packages" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day4"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 04: - GURUDONGMAR LAKE EXCURSION AND TRANSFER TO LACHUNG
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <p>After shedding off the exhaustion with a good night's sleep in a peaceful environment, day 04 calls to get up a bit early to get moving towards the sightseeing of the day after feasting on fresh and hot breakfast while appreciating the beautiful morning. Post breakfast visits Gurudongmar Lake (5450 M/70 Km/03 hours).
                                                    </p>
                                                    <p>Gurudongmar Lake - Located at an altitude of 17800 ft, this high altitude natural lake is considered the most sacred lake as believed by Buddhists and Hindus alike. This high altitude beautiful and awe-inspiring lake remains milky in color throughout the year. This beautiful lake used to remain frozen most of the year, hence it was not possible to use its water for drinking purposes. When the Guru Padmasambhava passed by, while returning from Tibet, the residents are reported to have approached him for providing a source of water. The Guru obliged, and a portion of the lake touched by him does not freeze in extreme winter. Since the water of the lake is considered sacred, visitors carry the 'blessed' water in bottles.
                                                        After lunch, check out from the hotel and transfer to Lachung.
                                                    </p>
                                                    <p>
                                                        Lachung - The name Lachung stands for a Small Pass is a mesmerizing hilly area near the border of Tibet in North Sikkim at an elevation of 9600 ft. This small dreamy destination is embraced with snowy mountains, tall coniferous trees, the sparkling stream which is a perfect treat for a vacationer, nature, and photography lover.

                                                        On arrival, Check-in to the hotel in the middle of pristine nature with rooms having breathtaking views of the rugged mountains apart from basic amenities. The evening is free to enjoy the beauty of the place, or binge on Hot Thukpas, Momo, or local sprees while praising the mountains as the calm moonbeam hits the surface.
                                                        Overnight at Lachung.

                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay4ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day5"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 05: YUMTHANG VALLEY EXCURSION AND TRANSFER TO GANGTOK
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        DAY 5: YUMTHANG VALLEY EXCURSION AND TRANSFER TO GANGTOK
                                                        For others, the arrival of a new day brings new hope and for explorers, a new day brings a new destination. As the golden hues of the sun illuminate the lazy town of Sikkim, with the birds joining for the get-up call fills the morning with their sweet melody to remind everyone that morning has arrived. Enjoy the fresh morning from the balcony, with the jaw-dropping view of the mountains while sipping some tea. Post breakfast, leave for Yumthang Valley Excursion to Gangtok.

                                                    </p>
                                                    <p>Yumthang Valley - Also known as the Valley of Flowers when the 24 species of Rhododendron blooms during May and the Valley full of Snow during winter. Shingba Rhododendron Sanctuary Spread over 43 sq. km through which flows the Yumthang River, it adjoins LachungandYumthang. The Sanctuary which is known for its abundance of Rhododendron shrubs and trees is rich in ground flora and other vegetation as well. The road leading to the valley follows a scenic way with cascading waterfalls, and streams. This exotic location with hot springs, lazy yaks, and lush green meadows remains cold throughout the year due to the snow-capped mountains surrounding it.</p>
                                                    <p>Zero Point - The formal name of this Zero point is Yumesamdong, Zero points Sikkim stands at an altitude of 15,300 feet (23 kms from Yumthang Valley) is the last outpost of civilization where three rivers meet amid a panoramic view of the snow-clad mountain and picturesque surrounding. Tourists with asthma are advised to avoid this place due to high altitude sickness because of the low oxygen level.
                                                        VISITING ZERO POINT AT AN ADDITIONAL COST IS SUBJECT TO PERMIT AND ROAD CONDITIONS.
                                                        Overnight in Gangtok.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day6"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 06: GANGTOK LOCAL SIGHTSEEING
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        The morning in hilly areas is always beautiful, with the glittering rays of the sun spreading its golden hues on the snowy mountains, the chirping of the birds, the murmuring river, and the floating clouds making promise every day of a new beginning. After feasting on a sumptuous breakfast, it is time for Gangtok Local Sightseeing. Post breakfast, start for a tour in and around Gangtok. Visit -
                                                    </p>
                                                    <p>Tashi View Point - Located in Tashi about 8 Km from Gangtok town built by Tashi Namgyal, which offers a panoramic view of Mt Kanchenjunga on a clear day. Binoculars and Telescopes are mounted at the viewpoint which can get an unobstructed view of the mountains and forest on the opposite side.</p>
                                                    <p>
                                                        Gonjang Monastery - The monastery is located 6 Km away from Gangtok near Tashi View Point. This monastery was established in the year 1981 and the founder is H.E. Tingkye Gonjang Rimpoche. He got recognition as an incarnation of Yolmo Terton Ngakchang Shakya Zangpo, a 15th-century Nyingmapa Terton. The monastery follows the Jangter tradition of the Nyingma school of Tibetan Buddhism.
                                                    </p>
                                                    <p>Do-drul Chorten - The huge Stupa or monument built by the Venerable Trullshi Rimpoche, head of the Nyingma order of Tibetan Buddhism, in 1945. Inside the stupa, there is a complete set of Dorjee Phurba (Vajra Kilaya), a set of Kang Gyur relics (Holy Book), complete Zunga (mantras) and other religious objects. Around the Chorten, there are 108 Mani Lhakor (Prayer Wheels) all of which have mantras inscribed on them in Tibetan, and they consider this place as one of the important Chorten of Sikkim.</p>
                                                    <p>Namgyal Institute of Tibetology - It is considered a prestigious and renowned research institute of its kind in the world, NIT is a treasure of a vast collection of rare Lepcha, Tibetan and Sanskrit manuscripts, statues and rare Thangkas (colorful tapestries used in Buddhist liturgy). Apart from an attractive museum, it has over 200 Buddhist icons and other prized objects of art along with a majestic image of Manjushri (Bodhisattva of knowledge and wisdom) brought from Tibet.</p>
                                                    <p>Tsukla Khang Monastery or Palace Monastery - This monastery is located inside the complex of Tsuklakhang royal palace of the Chogyal, the former rulers of Sikkim, and is the most accessible monastery within the city. Built-in typical Sikkimese style, there are exciting murals and images of the Buddhist pantheon.</p>
                                                    <p>White Memorial Hall - A two-storied structure with typical British architecture, the hall built in the year 1932. The hall gets its name not because they paint it white but as they construct it in the memory of Sir John Claude White, the first political officer of Sikkim. The hall has historical values and is on the Ridge.</p>
                                                    <p>Flower Show Hall - A popular permanent exhibition of Sikkim diverse flowering fauna, near the White Hall, which holds the annual Orchid Show between March/June. This place is perfect to take photographs of the various colourful flowers and shrubs.</p>
                                                    <p>Gangtok Ropeway - One of the major attractions of Gangtok is the cable car ride at Deorali. The ropeway has 03 entry points or stops with the lowest point being the Deorali market, Nam Nang as mid-point, and the highest point is at Tashiling. This is a standing ride for everyone that offers a panoramic bird-eye view of the mountains, the valleys, the flowing river along with the mesmerizing view of the Gangtok city and snow-covered Mt. Kanchenjunga.</p>
                                                    <p>As the sightseeing ends for the day back at the Hotel, an evening is free to roam around the MG Marg where one can taste the Sikkim delicacies at local restaurants or spend some bucks shopping for handicrafts and other items.

                                                        Overnight at Gangtok.
                                                    </p>                       <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay6ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day7"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 07: GANGTOK TO PELLING TRANSFER
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Pelling the most popular small hill town in the district of West Sikkim, nestled at an altitude of 2150 m. This scenic place is a paradise for the nature lover with the mesmerizing view of mighty Kanchenjunga, and the neighboring peaks. Covered with thick forest this place is home to many wild animals, birds, and insects.
                                                    </p>
                                                    <p>After a long drive of 3 hours, Check-in at Hotel with a room facing the breathtaking Kanchenjunga surrounded by pristine nature. The evening is free for leisure to relish the tranquility of the place with a cup of hot tea in a peaceful surrounding.Overnight at Pelling.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay7ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day8"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 08: PELLING LOCAL SIGHTSEEING
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTen"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Post breakfast, start for Pelling Sightseeing Tour .
                                                        Pelling Skywalk and Sangacholing Monastery - Pelling Skywalk is a new attraction and an engineering marvel and located just 3 km from Pelling town. The breathtaking view of Mt. Kanchanjhungha makes visitors spellbound from here, and you can reach the Sangacholing Buddha Statue from here.
                                                    </p>
                                                    <p>Rimbi Waterfalls - About 12 kms away from Pelling and about 5 kms further down from Darap village is Rimbi River Bank, which falls on the way to Khecheopalri, and Yuksam where the main attractions are the river waterfalls. A large volume of water is falling on massive rocks from a great height. It demonstrates both timelessness and power and is quite hypnotic.</p>
                                                    <p>Kanchenjunga Water Falls - One of the large waterfalls in West Sikkim situated at a distance of 24 km from Pelling. It is a major tourist attraction located on a sharply curved roadside. The water is so cold, and the intensity of the fall is also high, this water is directly from Kanchanjanga parvath, Holy water. The area is a cluster of many falls, and to see the main waterfalls, one needs to climb up around 50 staircases. To visit the main fall, you need to purchased tickets at a nominal price. While Kanchenjunga Falls astonish visitors with the sight of a broad stream of water jumping deep down from a great height.</p>
                                                    <p>Khecheopalri Lake - Before leaving, make an immortal wish on it. It is a very holy and sacred lake, and birds prohibit any leaves to drop on the lake. This lake is a small paradise for Photographer & Bird Explorer. Khechipelri Lake makes people spellbound with its serenity and holiness. Try to attend the "Maghe Purne Festival" celebrated in April by floating Diyas & offering food.
                                                        Back to Hotel for Lunch and post Lunch, visit the following attractions.
                                                    </p>
                                                    <p>Pemyangse Monastery - The Pemyangse Monastery is a major attraction, not only because of its religious importance but also its history. The monastery that stands at an altitude of over 6,800 feet, is among the oldest in Sikkim. The monastery dates back to the 17th century and is the creation of Lama Lhatsun Chempo.</p>
                                                    <p>The decoration within the monastery is full of impressive paintings on Buddhist themes and statues of Buddhist deities. In former times, the head lama of the monastery held the ceremony of anointing the kingdom of Chogyalov, the rulers of Sikkim. One of the main attractions of the monastery, it is on a seven-tier wooden sculpture, representing the heavenly palace of Padmasambhava and its inhabitants.</p>
                                                    <p>Rabdentse Ruins - Located near the Pemayangtse Monastery, this was the second capital of the Sikkim Royalty after Yuksom. The ruins of the city command a panoramic view of Khangchendzonga and the valley. You will require to walk at least 2
                                                        - 2.5 km on a small road midst of dense trees to reach there from the main road. This ruins complex currently maintained by the Archeological Society of India.

                                                        Overnight Stay at Pelling.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay8ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day9"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 09: TRANSFER TO RAVANGLA VIA TEMI TEA GARDEN & BUDDHA PARK
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTen"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Buddha Park - Situated very close to the town of Ravenel, the Buddha Park, or Tathagata Tsal is the newest attraction in this area. The beautifully designed park features a 130 feet tall sitting statue of Lord Buddha, which is visible from quite a long distance. The park is a part of the Rabong Gompa complex. The view of the snow-clad mountain peaks from this place is simply out of the world. Battery operated car helps to see the complete area in around 25-30 minutes for senior aged people.
                                                    </p>
                                                    <p>Char Dham - This Pilgrim Centre has a large statue of 108 feet of Lord Shiva is the main attraction of this place. This complex is around 5 km from Namchi Bazar in an uphill. There are 12 Jyotirlingams are encircled around Lord Shiva.</p>
                                                    <p>There are four iconic temples from four corners of the country, Puri Jagganath Temple from the East, Dwarka Temple from the West, Badrinath Temple from the North, and Rameswaram temple from the South.</p>
                                                    <p>Namchi - The visit to Solophak (Namchi) does not end with visiting only the pilgrimage center, there is a lot more to see. There is a Temi Tea Garden - the only tea estate existing in Sikkim, just a few kilometers away from Solophak (Namchi). You can also take a walk to Tendong Biodiversity Park to see the myriads of medical plants conserved delightfully by the Sikkimâ€™s Forest Department. At just 40 kilometers away from the Solophak (Namchi) is a waterfall called Tirikhola, which is also a great tourist attraction, and you love the view of this waterfall for sure.</p>
                                                    <p>Temi Tea Garden - Sprawling on a natural hill slope originating from the Tendong Hill is the only tea garden in Sikkim.</p>
                                                    <p>Enroute, you will cross Ravangla, a small township at the base of Maenam Hills and a popular tourist destination. After a rough road journey, It was mesmerizing to see the beautiful Temi tea garden filled with greens all side. Take a leisurely walk inside the tea garden & lost your sole inside. In the lap of mountains, among the beautiful tea leaves will melt your heart. The ladies carrying the tea carriers were looking so heavenly like angels walking through the garden. White flags were making the place look quite enthralled with unwinking beauty.</p>
                                                    <p>Samdruptse - Painted in shimmering copper, pink and bronze, the 45m-high statue of Guru Padmasambhava lords over the forested Samdruptse ridge and is visible for miles around. Completed in 2004 on a foundation stone laid by the Dalai Lama, the statue of the hallowed Buddhist leader sits atop a giant lotus plinth and makes for a striking photo-op. It is the largest Buddha statue of Sikkim. This beautiful statue of Buddha is at around 8000 ft above the sea level and is well maintained. The chanting sounding of mantra refreshes your souls with peace.
                                                        Overnight stay in Ravangla.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay9ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay9ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day10"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 10: PELLING TO NJP/IXB TRANSFER
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTen"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After Breakfast Proceed for NJP from Darjeeling with sweet memories.
                                                    </p>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Cilantro Unwind Delisso Abode <span>- Gangtok</span></h2>
                                            <h4><i>4 Star Cottage Hotel</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_1_URL} alt="..." />
                                </div>

                                <div id="no-more-tables">
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Luxury Accommodation</td>
                                                <td data-title="Size(Room)">290+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Cilantro<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 7,080/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>UNLOCK THE SERENITY OF SIKKIM</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>UNLOCK THE SERENITY OF SIKKIM</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p><b>Cilantro</b> Unwind is a combination of luxury and magnificent hospitality. We are always looking forward to providing you with all the care, love, and adhering support. At Cilantro, we cater to your needs to make you feel comfortable and more at home. Our restaurant and bar is the best place to replenish your hunger and mood!</p>
                                        <p>We are also located very close to MG Marg. Just a 2 minutes walk away!</p>
                                        <p>
                                            <b>Distance From
                                                Railway:</b> New Jalpaiguri Railway Station (148 Km)<br />
                                            <b>Airport:</b> Bagdogra Airport (124 Km)<br />
                                            <b>Bus Stand:</b> SNT Bus Station (1 Km)<br />
                                            <b>Airport:</b> Pakyong Airport (27 Km)<br /></p>

                                        <a href="tel://9147061467" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Ramada <span>- Gangtok</span></h2>
                                            <h4><i>Stay with Trust</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_2_URL} alt="..." />
                                </div>

                                <div id="no-more-tables">
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Platinum Accommodation</td>
                                                <td data-title="Size(Room)">320+ Sq Ft</td>
                                                <td data-title="Hotel Brand">Wyndham <div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 13,570/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>ENJOY 5 STAR CASINO HOTEL IN THE LAP OF HIMALAYA</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>ENJOY 5 STAR CASINO HOTEL IN THE LAP OF HIMALAYA</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p><b>Ramada</b> By Wyndham Gangtok Hotel & Casino Golden now becomes the first Ramada hotel in Sikkim. At a mere distance of just 10 minutes from the main mall area at M.G. Marg and 45 minutes from the closest operational airport Greenfield Pakyong, this is the perfect place to enjoy 5-star luxury services along with serene views of the mighty Kanchenjunga range. Additionally, we also have a live Casino that boasts of the biggest live gaming area in the entire country.</p>

                                        <a href="tel://9147061467" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3>FREQUENTLY ASKED QUESTIONS [FAQ] FOR <b><span class="tomato">FOR SIKKIM TOUR PACKAGE FROM BAGDOGRA</span></b></h3>
                                            <h4>SIKKIM TOUR PACKAGE FROM BAGDOGRA</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="accordion accordion-flush faQStyle"
                                        id="accordionFlushExample"
                                    >
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingThree">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseThree"
                                                >
                                                    3. Do we need a permit to visit all locations in Sikkim?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseThree"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingThree"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>Yes, there are two types of permits needed:</p>
                                                    <ul>
                                                        <li>• RAP (Restricted Area Permit): This is for foreigners and can be obtained from places like immigration offices, the Ministry of Home Affairs, Foreigners Registration Offices, Indian embassies, or the Deputy Director at the SNT Complex in Siliguri.</li>
                                                        <li>• PAP (Protected Area Permit): This is for Indians and locals, required for visiting areas like Tsomgo Lake, Baba Mandir, Nathula Pass, Gurudongmar, Yumthang, Dzongri Trek, Singalila Trek, and Yumesamdong.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFour">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFour"
                                                >
                                                    4. When is the best time to visit North Sikkim Package Tour?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseFour"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingFour"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>The best time to visit North Sikkim is March and April when you can enjoy snow, and the roads are open.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFive">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFive"
                                                >
                                                    5. When can we expect snow in Sikkim?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseFive"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingFive"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>Snowfall usually occurs from January to March, especially in places like Changu Lake, Baba Mandir, and North Sikkim.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSix">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseSix"
                                                >
                                                    6. Can we expect snow in Sikkim in May?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSix"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingSix"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>Yes, in May you can find snow at Zero Point in North Sikkim. You can also see snow at Zero Point in October and November.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseSeven"
                                                >
                                                    7. When is the best time to visit Pelling?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSeven"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingSeven"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>For the best views of Mt. Kanchenjunga, visit Pelling between the last week of November and the first week of December. If you visit during a full moon, it will be even more memorable.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingEight">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseEight"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseEight"
                                                >
                                                    8. Can we get a shared package for North Sikkim from Gangtok?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseEight"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingEight"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>Yes, shared packages for North Sikkim are available from Gangtok. These are usually basic and budget-friendly, and can be booked only from Gangtok to Pelling Tour.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingNine">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseNine"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseNine"
                                                >
                                                    9. Can we get a shared taxi from Bagdogra airport to Sikkim Packages?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseNine"
                                                className="accordion-collapse collapseShow"
                                                aria-labelledby="flush-headingNine"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <p>It’s rare to find a shared taxi from Bagdogra Airport to Gangtok, but you can find a shared jeep service from NJP railway station to Gangtok.</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b>Sikkim Tour</b> Reviews</h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                        <div className="col-md-3">
                            <BookingBox />
                        </div>

                    </div >
                </div >
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91470-61467">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                    </a>
                </div>

            </>
        );
    }
}

export default Sikkim_Tour_9N_10D_bagdogra_Package;