import React, { Component } from 'react';
import ApiURL from "../../../api/ApiURL";

class InclusionExclusion extends Component {
    
    render() {
        const ButtonImageURL = ApiURL.BhutanB2BPackageImageURL + "/bhutan-tour-packages-from-mumbai-with-chartered-flight-tickets-from-adorable-vacation.webp";
        return (
            <>
                <table className="table-borderDodont">
                    <thead>
                        <tr>
                            <td className="tomato" style={{ fontSize: "1.5vw" }}>INCLUSIONS :<strong> BHUTAN B2B PACKAGE</strong></td>
                            <td className="tomato" style={{ fontSize: "1.5vw" }}>EXCLUSIONS : <strong> BHUTAN B2B TOUR </strong></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Accommodation in TCB listed Hotels (Double/Twin Sharing Room) </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Air Fare </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Transfers & all sightseeing in an exclusive Non-AC Vehicle (Pvt. Basis) </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Snacks, Lunch or any other meal </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>Parking, Toll Tax, e-Permits </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Snacks, Lunch or any other meal </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Thimphu Local Sightseeing </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Monument Entrance Fees</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Paro City Tour </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Laundry, Tips, Phone Call or any such Personal Expense 
                            </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>English / Hindi Speaking Tour Guide </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Any item which is not mentioned in the "Inclusion" Section 
                            </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>All transfer will be on point to point basis </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Additional sightseeing or
                                extra usage of vehicles, other than those mentioned in the itinerary.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>SDF Charges (Sustainable Development Fee) </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Any cost arising due to
                                natural calamities like landslides, road blockage,
                                political disturbances (strikes),etc (to be borne by the client, which is directly payable on the
                                spot).</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>01 Bottle Packages Drinking Water Per Day on Vehicle </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Any increase in taxes or
                                fuel price, leading to an increase in cost on surface
                                transportation & land arrangements, which may come into effect prior to departure.</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>Guest Service Support during the trip from Bhutan & India Office 24/7 </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Does not include any
                                Lunch & Evening Tea/snacks.</td>
                        </tr>
                    </tbody>
                </table>
                <br /><br />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="image-cell">
                                                    <a href='tel:9883359713'><img src={ButtonImageURL} alt="bhutan tour packages from mumbai with chartered flight tickets from adorable vacation" /></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                <br/><br/>
                <table className="table-borderDodont">
                    <thead>
                        <tr>
                            <td className="tomato" style={{ fontSize: "1.5vw" }}>DO'S : <strong>BHUTAN B2B TRIP</strong></td>
                            <td className="tomato" style={{ fontSize: "1.5vw" }}>DONT'S : <strong>BHUTAN B2B TRAVEL</strong></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Listen to your tour guide and follow their instructions. </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Do not take photographs of local people, monks, events, or objects without their permission.  </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Bring enough cash, as many places don’t take credit cards. </td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Do not take photographs inside dzongs, temple, monasteries, sacred places, and religious sites.  </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Be polite and respectful to older people.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Do not climb on to the top of any of the religious sites or sacred places. </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Only enter temples and monasteries if you have permission.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Do not touch holy objects inside the monastery or any sacred sites. </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Dress modestly; avoid wearing sleeveless tops and short skirts.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Do not point your finger or feet at anyone while talking. 
                            </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Take off your shoes before going into a temple.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Do not touch the forehead of any person as it is considered a sign of disrespect.  
                            </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> If photography isn’t allowed, keep your camera safe.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Do not wear revealing or skimpy clothes when entering any place of religious significance. </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Don’t wear hats or sunglasses inside religious sites.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Do not speak loudly inside the religious premises</td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Keep your valuables, like wallets and passports, in a safe place.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span> Use Dustbin when needed. Do not through rubbish inside temples or any scared sites. </td>
                        </tr>
                        <tr>
                            <td><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span> Always follow traffic rules when on the road.</td>
                            <td><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>Do not say anything negative about the country, its Royal couple, or discuss political issues with locals.</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    }
}

export default InclusionExclusion;