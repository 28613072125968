
import ApiURL from "../../api/ApiURL";
import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useState, useEffect } from "react";
import ImagePopup from "./ImagePopup";

const destinationData = [
    {
        ImageName: "sandakphu1.jpeg",
        AlternateText: "Image of Bhutan",
        Title: "Bhutan",
        Description: "Explore Bhutan's beauty.",
        BookNowURL: "/",
    },
    {
        ImageName: "Bhutan6.webp",
        AlternateText: "Image of Bhutan",
        Title: "Bhutan",
        Description: "Explore Bhutan's beauty.",
        BookNowURL: "/",
    },
    {
        ImageName: "Bhutan10.png",
        AlternateText: "Image of Bhutan",
        Title: "Bhutan",
        Description: "Explore Bhutan's beauty.",
        BookNowURL: "/",
    },
    {
        ImageName: "Bhutan9.png",
        AlternateText: "Image of Bhutan",
        Title: "Bhutan",
        Description: "Explore Bhutan's beauty.",
        BookNowURL: "/",
    },
];

function GalleryExplorerInside() {
    const imageUrls = destinationData.map(
        (item) => `${ApiURL.BlogExplorerImageURL}/${item.ImageName}`
    );
    return (
        <div className="container-fluid custom-container2">
            <div className="row">
                <div className="col-md-12">
                    <div className="site-heading text-center">
                        <h2>
                            Bhutan <span>Explorer</span>
                        </h2>
                        <h4>Our Valuable Tour</h4>
                    </div>
                </div>
                <div className="col-md-12">
                    <h3><b>Bhutan Group Tour From Mumbai</b></h3>
                    <p>Rotary Group</p>
                </div>
            </div>

            <div className="row gallery-grid custom-containergallery">
            {destinationData.map((item, index) => {
                    const sliderImageURL = `${ApiURL.BlogExplorerImageURL}/${item.ImageName}`;
                    return (
                        <div className="cards-wrapper2" key={index}>
                            <div className="card">
                                <ImagePopup
                                    index={index} // Pass images as an array for the modal
                                    images={imageUrls} // Pass all images for navigation
                                    sliderImageURL={sliderImageURL} // Pass the main image URL for the card thumbnail
                                    alternateText={item.AlternateText}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default GalleryExplorerInside;

