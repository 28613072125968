import React, { Component } from 'react';

class TopSearch extends Component {

    render() {
        return (
            <>
            <div id="no-more-tables">
                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                    <tbody>
                        <tr>
                            <td style={{ fontSize: "16px" }}> Six Senses Bhutan </td>
                            <td style={{ fontSize: "16px" }}> Six Senses Thimphu </td>
                            <td style={{ fontSize: "16px" }}> Six Senses Luxury Tours in Bhutan</td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: "16px" }}>Six Senses Bhutan Location</td>
                            <td style={{ fontSize: "16px" }}>Bhutan Tour with Six Senses Hotel</td>
                            <td style={{ fontSize: "16px" }}>Six Senses Punakha</td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: "16px" }}>Six Senses Paro</td>
                            <td style={{ fontSize: "16px" }}>Six Senses Gangtey</td>
                            <td style={{ fontSize: "16px" }}>Six Senses Bumthang</td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: "16px" }}>Six Senses Bhutan Price</td>
                            <td style={{ fontSize: "16px" }}>Six Senses Bhutan Review</td>
                            <td style={{ fontSize: "16px" }}>Six Senses Bhutan Booking</td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: "16px" }}>Map of Six Senses Thimphu</td>
                            <td style={{ fontSize: "16px" }}>Six Senses Bhutan Packages</td>
                            <td style={{ fontSize: "16px" }}>Six Senses Bhutan Rates</td>
                        </tr>
                        <tr> 
                            <td style={{ fontSize: "16px" }}>Six Senses Bhutan Tripadvisor</td>
                            <td style={{ fontSize: "16px" }}>Six Senses Bhutan Spa</td>
                            <td style={{ fontSize: "16px" }}>Six Senses Bhutan Restaurant</td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <br />

            </>
        );
    }
}

export default TopSearch;