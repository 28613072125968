import React, { Component } from "react";
import BookingBox from "../BookingBox";
import Slider from "react-slick";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import ApiURL from "../../../api/ApiURL";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./InclusionExclusion";
import InclusionExclusion from "../Meghalaya/InclusionExclusion_KazirangaPackageTourWithElephantSafari_7N_8D";

class KazirangaPackageTourWithElephantSafari_7N_8D extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customActiveTab: "tab1",
      currentPage: 1,

    };
  }

  handleCustomTabClick = (tab) => {
    this.setState({
      customActiveTab: tab,
    });
  };
  handleClick = () => {
    const currentUrl = window.location.href;
    const pageTitle = document.title;
    const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+919674738480&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
    window.open(whatsappLink, '_blank');
  };


  render() {
    const rows = [
      { Train_No: '12345', Train_Name: 'Saraighat Exp', Boarding_From: 'Howrah', Boarding_time: '16:05', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '12377', Train_Name: 'Padatik Exp', Boarding_From: 'Sealdah', Boarding_time: '23:20', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13147', Train_Name: 'Uttar Banga Exp', Boarding_From: 'Sealdah', Boarding_time: '19:40', Arrival: 'New Cooch Behar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13175', Train_Name: 'Kanchanjunga', Boarding_From: 'Sealdah', Boarding_time: '06:50', Arrival: 'NJP', Day: 'M,W,SAT' },
      { Train_No: '15959', Train_Name: 'Kamrup Exp', Boarding_From: 'Howrah', Boarding_time: '18:30', Arrival: 'New Alipurduar', Day: 'T,W,T,S,S' },
      { Train_No: '13141', Train_Name: 'Teesta Torsha', Boarding_From: 'Sealdah', Boarding_time: '15:00', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
      { Train_No: '13149', Train_Name: 'Kanchan Kanya', Boarding_From: 'Sealdah', Boarding_time: '20:35', Arrival: 'New Alipurduar', Day: 'M,T,W,T,F,S,S' },
    ];

    const rowsPerPage = 4;
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const currentRows = rows.slice((this.state.currentPage - 1) * rowsPerPage, this.state.currentPage * rowsPerPage);
    const { customActiveTab } = this.state;
    const packageTopBannerImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Kaziranga-Shillong-Cherrapunjee-Package-from-Guwahati.webp";
    const packageTopBanner2ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Kaziranga-Shillong-Cherrapunjee-Tour-Package-from-Guwahati.webp";
    const sightseen1ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/KAZIRANGA-Cherrapunjee-JUNGLE-SAFARI.webp";
    const sightseen2ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Kaziranga-Shillong-Cherrapunji-package-tour.webp";
    const sightseen3ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/ASSAM-CULTURE.webp";
    const sightseen4ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/CULTURE.webp";

    const navDay1ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Indian-one-horned-rhinoceroses-KAZIRANGA.webp";
    const navDay2ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Western-Zone-of Kaziranga-National-Park-Jungle-Safari.webp";
    const navDay3ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Sholling-Barapani-Lake-Umiam-Lake.webp";
    const navDay4ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/JOWAI-SHILLONG-Krang-Suri-Waterfalls.webp";
    const navDay5ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/MAWLYNNONG-DAWKI–CHERRAPUNJEE.webp";
    const navDay6ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/CHERRAPUNJEE–NONGRIAT-TREK-DOUBLE-DECKER-BRIDGE.webp";
    const navDay7ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/SEVEN-SISTER-WATERFALLS-CHERAPUNJEE.webp";
    const navDay8ImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/Kamakhya-Temple-Guwahati.webp";



    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const reviewersDataList = this.props.ReviewersData;
    const reviewersDataView = reviewersDataList.map((dataItem, i) => {
      const reviewerImageURL = ApiURL.Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL + "/" + dataItem.ImageName;
      return (
        <div className="testimonial-box" key={i}>
          <div className="box-top">
            <div className="profile">
              <div className="profile-img">
                <img src={reviewerImageURL} alt="..." />
              </div>
              <div className="name-user">
                <strong>{dataItem.Name}</strong>
                <span>{dataItem.Location}</span>
              </div>
            </div>
            <div className="reviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
          <div className="client-comment">
            <p>{dataItem.Comment}</p>
          </div>
        </div>
      )
    })


    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <img
              src={packageTopBannerImageURL}
              className="mobile-image2"
              alt="..."
            />
          </div>
        </div>

        <div className="container-fluid custom-container py-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="container-fluid custom-container py-1">


                    <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>Kaziranga Package Tour From Guwahati</strong> with Elephant + Jeep Safari</center></h1>

                    <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>Kaziranga Package Tour From Guwahati</strong> with Elephant + Jeep Safari</center>
                      <hr />
                    </h3>

                    <div className="row">
                      <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                    </div>
                    <hr />
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                      <div className="font-lg">
                        Destinations:{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Guwahati
                        </a>
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          kaziranga
                        </a>
                        &nbsp;{" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Shillong
                        </a>
                        {" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Dawki
                        </a>
                        {" "}
                        <a href="#">
                          <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                          Cherrapunjee
                        </a>
                      </div>
                      <div className="reviews mx-5">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                    <p className="p-2">
                      If you are looking for a memorable <strong>Kaziranga Shillong Cherrapunjee Group Package</strong>, you have come to the right place!
                    </p>
                    <hr />
                  </div>
                  <h2 className="content-desktop p-4" style={{ fontSize: "1.6vw" }}>Looking For <strong>Kaziranga Elephant Safari Package Tour</strong> <a href='tel:9674738480' class="tomato">☎ CALL +91- 96747-38480</a></h2>
                  <h3 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}>Looking For <strong>Kaziranga Elephant Safari Package Tour</strong> <a href='tel:9674738480' class="tomato">☎ CALL +91- 96747-38480</a></h3>
                  <br /><br />
                  <div className="card">
                    <div
                      className="card-header custHeaer"
                    >
                      <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> INDIA'S LARGEST <b>NORTH EAST TOUR OPERATOR</b></h3>
                      <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> INDIA'S LARGEST <b>NORTH EAST TOUR OPERATOR</b></h4>
                    </div>
                    <div className="card-body">
                      <p>Jungles are something that has always attracted a lot of tourists and adventure lovers every year because of the serenity, abundant natural beauty, exotic wildlife and adventure activities like jungle safari, <strong>Kaziranga Elephant Safari Package Tour</strong> & Kaziranga Jeep Safari. Kaziranga, one of the best and well reserved national parks in India is located in the state of Assam, at the banks of the mighty river of Brahmaputra. This park is just about 250km away from Guwahati and the landscape here is picture perfect as well as peaceful to experience. Apart from hustle & bustle of madly city life, this Evergreen Park gives the tourists a wonderful background with the river Brahmaputra which makes the <strong>Kaziranga Package Tour</strong> worth it. This popular National Park is best known for Asiatic One-horned Rhino, an endangered species in the world where about 2/3rd of the remaining found in this Park. <br /><br />
                        This UNESCO recognised World Heritage site is one of the best place to explore during winter holidays that will make one feel so much closer to nature and makes the whole <strong>Kaziranga Tour Package</strong> memorable. With absolute peace and tranquillity except the chirping of birds which breaks the silence here, please the heart and soul of every adventure lovers with absolute joy. With almost 480 species of birds, wild elephants, tigers, gaurs, wild boars, deers and reptiles, the visitors also can experience many tea estates, handicraft souvenir shop, Orchid & Biodiversity Park which is also a delight to look at. In the evening, a traditional dance performance with different instruments and folk song depicts the asamese culture which is the one of the prime attractions during <strong>Kaziranga Tour</strong>. So, don't think twice. If you are planning for <strong>Kaziranga Package Tour Booking</strong>, let's do it from Adorable.<br /><br /></p>
                      <br />
                      <div className="content-desktop">
                        <center><img src={sightseen1ImageURL} alt="..." /></center>
                      </div>

                      <div className="content-mobile2">
                        <center><img src={sightseen1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>Destinations Covered in <strong>Kaziranga Tour Package from Guwahati</strong> : Kaziranga 2N | Nameri 2N | Manas 2N | Pobitora 1N</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>Destinations Covered in <strong>Kaziranga Tour Package from Guwahati</strong> : Kaziranga 2N | Nameri 2N | Manas 2N | Pobitora 1N</h4>
                </div>
                <div className="card-body">
                  <p><b>Location of Kaziranga National Park</b> : Kanchanjuri, Assam 784177 [Golaghat and Nagaon District]</p>
                  <p><b>Area of Kaziranga National Park :</b> 430 km2 (170 sq mi)</p>
                  <p><b>Nearest City :</b> Golaghat, Nagaon, Forkating</p>
                  <p><b>Established :</b> 1904, 117 years ago <br /><br /></p>

                  <p><b>Location of Manas National Park :</b> Manas Road, Barangabari Gyati Village, Dist Baksa, Gobardhana, Assam 781315</p>
                  <p><b>Area of Manas National Park :</b> 950 sq km</p>
                  <p><b>Established :</b> 1990 <br /><br /></p>

                  <p><b>Location of Nameri National Park :</b> Sonitpur, Guwahati, Assam 784001</p>
                  <p><b>Area of Nameri National Park :</b> 200 sq km </p>
                  <p><b>Established :</b> 15 November 1998 <br /></p>

                  <p><b>Pickup & Drop</b> : Guwahati Airport [GAU] / Guwahati Railway Station [GHY] <br /></p>
                  <p><b>Guwahati to Kaziranga Tour Duration</b> : 7 Nights / 8 Days <br /><br /></p>

                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>Animals that can be seen during <strong>Kaziranga Manas Nameri Tour Package from Guwahati</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>Animals that can be seen during <strong>Kaziranga Manas Nameri Tour Package from Guwahati</strong></h4>
                </div>
                <div className="card-body">
                  <p>During your <strong>Kaziranga Manas Nameri Tour</strong>, you can witness wild animals in the open air like - One Horned Rhinoceros, Asiatic water buffalo, Eastern swamp deer, Indian elephants, gaur, sambar, wild boar, hog deer, Bengal tigers, leopards, jungle cat, fishing cat, leopard cat, Indian gray mongoose, small Indian mongooses, large Indian civet, small Indian civets, Bengal fox, golden jackal, sloth bear, Chinese pangolin, Indian pangolins, hog badger, Chinese ferret badgers, flying squirrel etc.<br /><br /></p>

                  <h3>Birds can be seen in <strong>Kaziranga Trip</strong></h3>
                  <p>Kaziranga is like a heaven for birders. Different species of birds that can be seen here are Great Indian hornbill, wreathed hornbill, lesser white-fronted goose, ferruginous duck, Baer's pochard duck, lesser adjutant, greater adjutant, black-necked stork, Asian openbill stork, Blyth's kingfisher, white-bellied heron, Dalmatian pelican, spot-billed pelican, Nordmann's greenshank, black-bellied tern, marsh babblers, common baya weaver, threatened Finn's weavers, Hodgson's bushchat, bristled grassbird, black-breasted parrotbill, rufous-vented grass babbler etc.<br /><br />
                    Apart This below Packages :<br /><br />
                    <ul><li><a href="/arunachal-pradesh-package-tour-from-kolkata" target="_blank"><b>Arunachal Package Tour From Kolkata - 9N 10D</b></a></li>
                      <li><a href="/shillong-meghalaya-package-tour-from-guwahati" target="_blank"><b>Shillong Package Tour From Guwahati - 6N 7D</b></a></li>
                      <li><a href="/kaziranga-shillong-cherrapunjee-package-tour-from-guwahati" target="_blank"><b> Kaziranga Shillong Cherrapunji Package Tour - 6N 7D</b></a></li>
                      <li><a href="/north-east-india-package-tour-from-guwahati-airport" target="_blank"><b>North East Package Tour From Guwahati Airport - 9N 10D</b></a></li>
                      <li><a href="/meghalaya-arunachal-pradesh-package-tour-from-guwahati" target="_blank"><b>Meghalaya Arunachal Package Tour From Guwahati - 9N 10D</b></a></li>
                      <li><a href="/arunachal-pradesh-package-tour-from-guwahati" target="_blank"><b>Arunachal Package Tour From Guwahati - 15N 16D</b></a></li>
                      <li><a href="/meghalaya-shillong-cherrapunji-package-tour-from-guwahati-with-garo-hills-tura-wari-chora" target="_blank"><b>Meghalaya Shillong Cherrapunjee Package Tour With Garo Hills - 9N 10D</b></a></li>
                    </ul>
                    <br /></p>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div className="card-body">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="yellow">
                        <th rowSpan="2">DAY WISE SUMMERY OF <strong>KAZIRANGA MANAS NAMERI TOUR PACKAGE</strong></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingOne"
                        >
                          <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                            <span>
                              GUWAHATI TO KAZIRANGA TRANSFER
                            </span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingTwo"
                        >
                          <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                            <span>KAZIRANGA JUNGLE SAFARI [ELEPHANT + JEEP SAFARI]</span>
                          </a>

                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingThree"
                        >
                          <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                            <span> TRANSFER FROM KAZIRANGA TO NAMERI</span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingFour"
                        >
                          <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                            <span> DAY SIGHTSEEING / ACTIVITIES AT NAMERI</span>
                          </a>

                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingFive"
                        >
                          <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                            <span>NAMERI TO MANAS NATIONAL PARK</span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingSix"
                        >
                          <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                            <span>DAY SIGHTSEEING / ACTIVITIES AT MANAS</span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingTen"
                        >
                          <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                            <span> MANAS NATIONAL PARK TO POBITORA</span>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="clickable"
                          data-target="panelsStayOpen-headingTen"
                        >
                          <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                            <span> POBITORA TO GUWAHATI AIRPORT / RAILWAY STN</span>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}>Animals that can be found at <strong>Kaziranga National Park</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}>Animals that can be found at <strong>Kaziranga National Park</strong></h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables" itemtype="https://schema.org/Table">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr>
                          <th>Name</th>
                          <th>No. (Approx)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Greater One-Horned Rhinoceros</td>
                          <td>2413</td>
                        </tr>
                        <tr><td>Indian Elephant</td><td>1940</td></tr>
                        <tr><td>Tiger</td><td>118</td></tr>
                        <tr><td>Gaur</td><td>1300</td></tr>
                        <tr><td>Wild Asian water Buffalo</td><td>1666</td></tr>
                        <tr><td>Sambar</td><td>58</td></tr>
                        <tr><td>Eastern Swamp Deer</td><td>468</td></tr>
                        <tr><td>Indian Muntjac / Barking Deer</td><td>120</td></tr>
                        <tr><td>Wild Boar</td><td>431</td></tr>
                        <tr><td>Hog Deer</td><td>5045</td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}><strong>Kaziranga Jeep Safari Price</strong> - [Kohora / Bagori / Agaratoli Range]</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}><strong>Kaziranga Jeep Safari Price</strong> - [Kohora / Bagori / Agaratoli Range]</h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables" itemtype="https://schema.org/Table">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr>
                          <th><b>Range</b></th>
                          <th><b>Vehicle</b></th>
                          <th><b>Entry Fee/Indian</b></th>
                          <th><b>Entry Fee/Foreigner</b></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Central Range (Kohora)</td>
                          <td>INR 2700 (Incl Road Toll)</td>
                          <td>INR 100</td>
                          <td>INR 650</td></tr>
                        <tr>
                          <td>Western Range (Bagori)</td>
                          <td>INR 2800 (Incl Road Toll)</td>
                          <td>INR 100</td>
                          <td>INR 650</td>
                        </tr>
                        <tr>
                          <td>Eastern Range (Agaratoli)</td>
                          <td>INR 3200 (Incl Road Toll)</td>
                          <td>INR 100</td>
                          <td>INR 650</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /><br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.6vw" }}><strong>Kaziranga Elephant Safari Price</strong> - [Kohora Range / Bagori Range]</h3>
                  <h4 className="content-mobile2" style={{ fontSize: "4.5vw" }}><strong>Kaziranga Jeep Safari Price</strong> - [Kohora / Bagori / Agaratoli Range]</h4>
                </div>
                <div className="card-body">
                  <div id="no-more-tables" itemtype="https://schema.org/Table">
                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                      <thead className="cf">
                        <tr>
                          <th><b>Citizen</b></th>
                          <th><b>Ranges</b></th>
                          <th><b>Rate/hour</b></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Indian</td>
                          <td>Western Range (Bagori)</td>
                          <td>INR 1000</td>
                        </tr>
                        <tr>
                          <td>Foreigner</td>
                          <td>Central Range (Kohora)</td>
                          <td>INR 2100</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br /><br />
                  <p><b>NOTE</b> : <strong>Elephant Safari at the Kohora Range (Central) in Kaziranga National Park</strong> is reserved only for Foreign nationals and VIPs from India while the Bagori Safari Range (Western) offers Elephant Rides for Indian Nationals.</p>
                </div>
              </div>
              <br /><br /><br />
              {/* <!--By Itinerary--> */}
              <div className="row py-5">
                <div className="col-md-12">
                  <div className="site-heading text-center">
                    <h3>
                      DAY WISE ITINERARY FOR <span><strong>KAZIRANGA PACKAGE TOUR FROM GUWAHATI</strong></span>
                    </h3>
                    <h4>8 NIGHTS / 9 DAYS <strong>Kaziranga Package Tour </strong> from Adorable Vacation</h4>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="accordion"
                  id="accordionPanelsStayOpenExample"
                >
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day1"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        <h4> DAY 01: Guwahati to Kaziranga National Park [3 hr 54 min (192.8 km) via NH 27] </h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div className="accordion-body">
                        <p>Meet and Greet at Guwahati Airport [GAU] / Guwahati Railway Station [GHY] and start your journey for <strong>Kaziranga Package Tour</strong>. Kaziranga located 193 KM from Guwahati, covering an area of 430 sq-kms, is inhabited by the world's largest population of one-horned rhinoceroses, as well as many mammals, including tigers, elephants, panthers and bears, and thousands of birds. In 1985, it was declared a World Heritage Site by <abbr title="The United Nations Educational, Scientific and Cultural Organization">UNESCO</abbr> for its unique natural environment. Go to bed early for next day early morning <strong>Kaziranga Jungle Safari</strong>. Overnight Stay at Hotel in Kaziranga.</p>
                        <div className="content-desktop">
                          <center><img src={navDay1ImageURL} alt="bhutan B2B tour packages with direct flights" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="bhutan B2B tour packages with direct flights" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day2"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        <h4>DAY 02: <strong>Kaziranga Safari</strong> (Included)</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTwo"
                    >
                      <div className="accordion-body">
                        <p>After an early wake up, you will be taken to the riding point for <strong>Kaziranga Elephant Safari</strong> in the Western Range. Watch the famous One-Horn Rhino within the jungle from stone throwing distance. After the elephant safari, return back to the Resort. After lunch, drive to Kaziranga Central Range for the <strong>Kaziranga Jeep Safari</strong>. The Central zone of the Kaziranga National Park is the prime zone for the ecotourism in the Kaziranga forest. The entry gate to the central zone is situated just about two km from the National Highway passing through the village of Kohora. The <strong>Kaziranga Jungle Safari</strong> offers you some spectacular sighting of the Rhinos grazing or wallowing in the large grassland, a herd of elephants with having the adorable baby elephants to big old elephants in the herd, and many other species of the wild flora and fauna of this tourism zone. Overnight Stay at Kaziranga National Park.<br /><br /></p>
                        <div className="content-desktop">
                          <center><img src={navDay2ImageURL} alt="mumbai to bhutan B2B tour packages booking with direct flights" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="mumbai to bhutan B2B tour packages booking with direct flights" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day3"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 03: Transfer from Kaziranga to Nameri [2 hr 5 min (87.1 km) via NH715]</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseThree"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingThree"
                    >
                      <div className="accordion-body">
                        <p>After breakfast check out and proceed towards <strong>Nameri National Park Tour</strong>, Assam famous for Tiger Reserve. Nameri National Park is located in the foothills of Himalyas in the Sonitpur District of Assam. Nameri is one of famous National Park of India for bird lovers and Wild Life Photographer. Overnight Stay at Nameri National Park.<br /><br /></p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay3ImageURL} alt="Perfect Bhutan B2B packages for Tour Operators" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Perfect Bhutan B2B packages for Tour Operators" /></center>
                        </div>
                        <br /><br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day4"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFour"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 04: - Day Sightseeing / Activities at Nameri </h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseFour"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFour"
                    >
                      <div className="accordion-body">
                        <p>Nameri National Park covers an area of 200 sqkms and is located in the Sonitpur dist, bodering Arunachal Pradesh. It is also the core area of <strong>Nameri Tiger Reserve</strong>. It is beautiful deciduous forests and the adjacent river Jia Bhorali, fringe the border of Assam and Arunachal Pradesh. This is therefore a popular stop for fishing / river raffting. Animals seen here Leopard, Clouded Leopard, Elephant, Gaur, Wild Pigs, Sambar, Capped Languretcs. Overnight Stay at Nameri.<br /><br /></p>
                        <br />
                        <div className="content-desktop">
                          <center><img src={navDay4ImageURL} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="India's No 1 Travel DMC for Bhutan Tour" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day5"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFive"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 05: Nameri to Manas National Park [5 hr 54 min (267.8 km) via NH 15] </h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseFive"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFive"
                    >
                      <div className="accordion-body">
                        <p>After early breakfast, start for Manas Sanctuary or <strong>Manas National Park Tour</strong>. It is located two district of Bongaigaon and Barpeta of Assam. This park is famous for its rare and endangered wildlife like hispid hare, Assam roofed turtle,  golden langur, pygmy hog and wild water buffalo also. In 1985, it was also declared as UNESCO Natural World Heritage Site. Overnight Stay at Manas Park.<br /><br /></p>

                        <div className="content-desktop">
                          <center><img src={navDay5ImageURL} alt="Bhutan B2B Package Tour Operator" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="Bhutan B2B Package Tour Operator" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day6"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSix"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 06: Day Sightseeing / Activities at Manas</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSix"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSix"
                    >
                      <div className="accordion-body">
                        <p>Wake up early and enjoy a wonderful <strong>Elephant Safari in Manas National Park</strong>. The elephant will walk through sometimes through dense forest and sometime through tall grass land. You can see some of the rare species of birds and animals through Jungle Safari and if you are lucky, you can see the tiger also. Later in the afternoon, go for <strong>Manas National Park Jeep Safari</strong> (included). Overnight Stay at Manas.</p>
                        <br /><br />
                        <div className="content-desktop">
                          <center><img src={navDay6ImageURL} alt="B2B Tour Operator for Bhutan" /></center>
                        </div>

                        <div className="content-mobile2">
                          <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="B2B Tour Operator for Bhutan" /></center>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day7"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 07: Transfer from Manas National Park To Pobitora [170 Kms / 4.5 Hrs]</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>Pobitora Wildlife Sanctuary is located in the Morigaon district of Assam, in the southern bank of River Brahmaputra. The sanctuary is covering around 40 Sq KM and mainly with grassland and wetland and home to many Indian One horn rhino. You can also do Jeep safari in Pobitora National Park, if time permits. Overnight Stay at Pobitora.<br /><br /></p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div
                      className="accordion-header"
                      id="day8"
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        <h4>DAY 08: Transfer from Pobitora to Guwahati Airport / Railway Stn</h4>
                      </button>
                    </div>
                    <div
                      id="panelsStayOpen-collapseSeven"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingSeven"
                    >
                      <div className="accordion-body">
                        <p>Start from Pobitora to Guwahati for your return journey and <strong>Kaziranga Trip</strong> ends with sweet memory.<br /><br /></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /><br />
              <div className="card">
                <div
                  className="card-header custHeaer"
                >
                  <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> HOTELS ADORABLE PROVIDE FOR <strong>KAZIRANGA PACKAGE TOUR WITH ELEPHANT SAFARI</strong></h3>
                  <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> HOTELS ADORABLE PROVIDE FOR <strong>KAZIRANGA PACKAGE TOUR WITH ELEPHANT SAFARI</strong></h4>
                </div>
                <div className="card-body">
                  <h4>4 Star Hotel in Kaziranga :</h4><br />
                  <p><b>Guwahati</b> - Gateway Grandeur</p>
                  <p><b>Shillong </b> - Hotel Polo Tower</p>
                  <p><b>Cherrpunjee</b> - Polo Orchid Resort<br /><br /></p>

                  <h4>3 Star Hotels :</h4><br />
                  <p>
                    <b>Guwahati</b> - Lilawati Grand</p>
                  <p><b>Shillong </b> - Centre Point, Blue Berry Inn, Eee Cee Hotel</p>
                  <p><b>Cherrpunjee</b> - Cherrapunjee Holiday Resort, Kutmadam Resort<br /><br /></p>

                  <h4>2 Star Hotels :</h4><br />
                  <p><b>Guwahati</b> - Hotel Siroy Liya, Hotel Siroy Classic</p>
                  <p><b>Shillong </b> - Hotel Barbareek,  Season Rate, J K Interanational</p>
                  <p><b>Kaziranga</b> - Wild Grass Lodge, Dhanshree Resort</p>
                </div>
              </div>
              {/* <!--end of package details--> */}
              <br /><br />

              {/* inclusion start  */}

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    {<InclusionExclusion />}
                  </div>
                </div>
              </div>

              {/* inclusion End  */}
              <br />  <br />  <br />
              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h2>
                        Frequently Ask <span>Questions</span>
                      </h2>
                      <h4><strong><strong>KAZIRANGA PACKAGE TOUR FROM GUWAHATI</strong> WITH MANAS & NAMERI</strong></h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="accordion accordion-flush faQStyle"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          What is the famous local cuisine to taste during Assam Kaziranga Tour ?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p><b>"Assamese khar"</b> is one of the top dishes of Assam. It is prepared from raw papayas, chillies banana peel as well as the trunk of the banana plants. There are few other types also to prepare khar with fish. It is an indigenous product, an alkaline-based delicacy of Assam. The Assamese people cannot do without it and hence the epithet '<b>KHAR KHUWA ASOMIYA</b>'. The other famous food of Assam to experience during <strong>Assam Kaziranga Package Tour</strong> includes Masor Tenga, Kumurat diya Duck Manxho, Xaak aru bhaji, Paro Manxho, Baanhgajor Lagot Kukura etc are widely consumed and one can easily be ordered in the restaurants with the food descriptions against the food items on the menu also Authentic Restaurants of the states.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          Which is the most famous festival in Assam ?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>It is one of the famous and renowned all over the festival in Assam which is held every year 14th April. It is celebrated to welcome the Assamese New year called Bohag Bihu. In this festival the small villages also enjoy with sound, light, and music and nice local pithas or local beer and meats in wide served and entire Assam and other neighbouring states like Meghalaya and Arunachal Pradesh celebrates in the big grounds or parks or in the banquet. Girls / Women’s and Boys / Men all takes participate in this festival and perform their traditional Bihu dance. It is one of the rejoicing festivals of Assam, North East. Although the festival is observed for 7 days, the celebration extends for about a month. Stages are set in different parts of the state, where Bihu dance is performed regularly for almost a month. If you want to be a part of this festival, contact NatureWings. We will design your tour itinerary with <strong>Kaziranga Package Tour</strong> in such a way that you will also enjoy this awesome festival during your <strong>Assam Tour</strong>.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          What is the best time to book Kaziranga Package Tour ?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>The best time to visit Kaziranga, rather booking a <strong>Kaziranga Package Tour from Guwahati</strong> is anywhere from November to April. Since the whole of Assam receives a major rainfall in the monsoon and the pre-monsoon time of the year, the Kaziranga National Park floods in the months of June, July and August. No wonder the rhinos love bathing and staying in water during these months. The water starts receding in September and October and the place is open to tourism from November again. <br /><br />
                            Tickets are available for sale one day in advance at the central booking office (near Kohora range) between 7 pm and 8pm. It is best to get the tickets through the agent because they can get you confirmed seat. <br /><br />
                            So, according to the tourists, the best time to book <strong>Kaziranga Tour Package</strong> is November, February and April who want to avoid the bitter cold weather. But, who travelers want to enjoy the chillness can go with <strong>Kaziranga Tour</strong> in December and January as well. Basically seasonal wise, November to April is the best time to visit, rest of the time the forest remain closed. During the period of November to April, the forest remains purely dry and easy accessible to walk around without any wetness though the wetness on the small grasses will give you a freshness on your above feet and make you feel comfortable to walk around the forest. It will be a good time for trekking, good season for the bird lovers. A wake up natural alarm by the birds in the morning will make you fresh and alive.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          How to reach for Kaziranga Jungle Safari ?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p><b>By Air</b> : To visit Kaziranga by Air, there are two nearest airports which are Guwahati International Airport and Jorhat Airport, located at a distance of 217 km and 97 km respectively from the park. The Lokpriya Gopinath Bordoloi International Airport, located in Guwahati, is the best option to arrive by air from any corner of India or abroad for the <strong>Kaziranga Safari Tour</strong>. From the Airports, you can further use the roadways to reach the park. So the booking the <strong>Kaziranga Package Tour from Guwahati</strong> is the best option. <br /><br />
                            <b>By Train</b> : If travelers choose to reach Kaziranga, the nearest railway station will be Furkating which is located at a distance of 75 km from the park. The station can be reached from major cities like Guwahati, Kolkata, New Delhi, etc. However, travelers can also use the other nearest railway stations like Guwahati railway station and Jorhat railway station located at a distance of 240 km and 90 km respectively. Both these railway stations are the major railway stations of the Northeast railways. <br /><br />
                            <b>By Road</b> : The main gate of the Kaziranga Park is situated in a small Kohora town which lies on the National Highway NH 37 due to which Kohora is connected to all the major cities of Assam such a Guwahati, Tezpur, Jorhat, Nagaon, Golaghat, Dimapur, Tinsukia, Sivasagar, etc. by the state transport buses or the privately run buses and minibusses. So to reach the park, first, you can reach Guwahati & Jorhat and then use the roadways to reach the park conveniently. <br /><br /></p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          Can I visit Kaziranga during Puja vacations i.e. in September to October ?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>In the month of September, the forest remains closed. The region experiences rainfall of 2,220 mm (87 inch) between June, and September, which leads to the flood. In 2019, Kaziranga was opened in October 1st despite of heavy rain fall. In 2020, you can expect the Park may open from 1st of October but due to the after affect of flood it is better to avoid the Park before 15th October. This year, Durga Puja is from 20th October, so there will be a fair chance to enjoy the <strong>Kaziranga Jeep Safari</strong>, <strong>Kaziranga Elephant Safari</strong> and <strong>Kaziranga Jungle Safari</strong> during the <b>Durga Puja / Diwali Holidays 2024</b>.<br /><br /></p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseSix"
                        >
                          Which is better to choose in Kaziranga - Jeep Safari or Elephant Safari ?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>There are two ways to see these animals in this park, jeep safari is the simple and affordable, while elephant safari is much more exciting option. It is mostly depends on the choice of the tourists. They can explore both or one as per their interest.
                            <br /><br />
                            <strong>Elephant Safari in Kaziranga</strong> takes you through the Kohora, Mihimukh, Arimarah, Hole Path and Bagori or western zone. Western zone is highly recommended safari zone in the Kaziranga where there is strong probability of spotting tiger and most famous one-horned rhinos. <br /><br />
                            Elephant can traverse into the deep jungles to reach very close to the place where mainly Rhino resides, and Jeep Safari requires muddy forest roads to traverse inside the Jungles, so to watch the Rhinos more closely, Obviously <strong>Kaziranga Elephant Safari</strong> is a better choice. <br /><br />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeven"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeven"
                        >
                         How can I book a guaranteed Elephant Safari in Kaziranga ?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseSeven"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingSeven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>You can book <em>Elephant Safari in Kaziranga</em> through online or you can ask your <strong>Kaziranga Travel Agent</strong> / <strong>Kaziranga Tour Operator</strong> like NatureWings for guaranteed Elephant Safari. Elephant Safari is famous in the Western Range and first priority goes to Indian national cost Rs. 1450/- approx per head and for Foreign Nationals Central range is famous and cost Rs. 3200/- approx per head.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                         How much does a trip cost only in Kaziranga ?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>You can't visit only Kaziranga. For this <em>Kaziranga Trip</em> you need to spend minimum 3N/4D and with cost generally depends on the group size, mode of transportation/hotel category, etc. For a rough idea you need to spend Rs.3,200 to 3,500/- per day basis (Safari cost extra).</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                         Can I see tiger in Kaziranga National Park ?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>Yes. Kaziranga is one of those rare national parks in India, where you can see the big five mammals - Indian Rhinos, Asiatic Elephants, Royal Bengal Tigers, Swamp Deer and Water Buffalo. There are approximately 86 tigers in the area but due to the tall elephant grass, it's hard to spot them.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                         How to Book Jeep Safari in Kaziranga ?
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p>One can enjoy the main attractions of Kaziranga by Jeep, which is usually accompanied by a guide or escort. <strong>Kaziranga Jeep Safari</strong> is a wonderful ride through which one can explore tigers, one horned rhinoceros, wild buffaloes, elephants, herd of deer, Pythons, and many other mammals & birds as well. <br /><br />
                          You can book safari permit by directly visiting the Kaziranga zone offices but unavailability of permit is a usual scenario, the best way to consult NatureWings to book the safari for you in advance so that you can enjoy the safari conveniently without any hassles when you will reach there on your scheduled date.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h4 className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                          How to Book Elephant Safari in Kaziranga
                        </button>
                      </h4>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <p><strong>Kaziranga Elephant Safari</strong> can be booked at the two centres at the two safari zones which are the Central zone, also known as Kohora zone, at Kohora and the Western zone at the Bagori. At both the centres, the permits for the elephant safari can be obtained depending on the availability. The elephant safari is organized only in the morning time in the three shifts for one hour from 05:00 am to 06:00 am, 06:00 am to 07:00 am and 07:00 am to 08:00 am. The third shift (07-08 am) is organized only if there are more tourists who cannot be accommodated in the earlier two shifts. So, the third shift is optional and depends on the park authority to organize or not. For the <strong>Elephant Safari in Kaziranga</strong> western zone, the permits can be obtained from the range-office at Bagori and for the elephant safari in the central zone, the safari permits can be obtained from the centenary convention center at Kohora. The western zone is considered as the best zone for the elephant safari and most of the tourists choose this zone for the maximum enjoyment of the elephant safari in the dense forest of Kaziranga.<br /><br />
                          You can book elephant safari permit by directly visiting the Kaziranga zone offices but but unavailability of permit is a usual scenario, the best way you can contact NatureWings to book the safari for you in advance so that you can enjoy the safari conveniently without any hassles when you will reach there on your scheduled date.<br /><br /></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid py-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="site-heading text-center">
                      <h3>
                        <b>
                          Kaziranga Shillong Cherrapunjee Package Tour <span className="tomato"> Reviews</span>
                        </b>
                      </h3>
                      <p>
                        <i>"Your Happiness is our Priority"</i>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 ">
                  <div id="testimonials">
                    <div className="testimonial-box-container">
                      {reviewersDataView}
                    </div>
                  </div>
                </div>
              </div>
            </div >
            <div className="col-md-3">
              <BookingBox />
            </div>
          </div >
          <div className="btn-whatsapp-pulse">
            <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
          <div class="btn-call-pulse">
            <a style={{ color: 'white' }} href="tel:+91-96747-38480">
              <i className="fa fa-phone"></i>
            </a>
          </div>
        </div >
      </>
    );
  }
}
export default KazirangaPackageTourWithElephantSafari_7N_8D;
