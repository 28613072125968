import React, {Component} from 'react';
import Contact from '../components/common/Contact';
import FooterDesktop from '../components/common/FooterDesktop';
import MainHeader from '../components/common/MainHeader';
import { Helmet } from 'react-helmet';
class ContactPage extends Component {
    render() {
        return (
            <>
              <Helmet>
                    <title>Contact Us – Best Deal from Adorable Vacation LLP</title>
                    <meta name="description" content="We Offer the Best Travel Experiences. For Booking ☎ CALL Mr. SOUMALYA +91-98833-59713" />
                </Helmet>
                <MainHeader/>
                
                <Contact />

                <FooterDesktop />
            </>
        );
    }
}

export default ContactPage;
