import React, { Component } from 'react';
import BookingBox from '../BookingBox';
import axios from "axios";
import cogoToast from "cogo-toast";
import { Digital } from "react-activity";
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from './InclusionExclusion_kashmir_ahmedabad';

class Kashmir_7N_8D_Package_Tour_From_Ahmedabad extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1',
            btn: "Submit",
            showLoader: false,
            isHidden: false,
            showModal: false,
            firstName: '',
            mobile: '',
            email: '',
            comments: ''
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };
    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919147061467&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    onRequestCallback = (e) => {
        e.preventDefault();
        // Prevent opening a new modal if another one is already open
        if (!this.state.showModal) {
            this.setState({ showModal: true });
        }
    }


    onCloseModal = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            showModal: false,
            firstName: '',
            mobile: '',
            email: '',
            comments: ''
        });
    }

    toggleHidden = (e) => {
        e.preventDefault();
        this.setState({ isHidden: !this.state.isHidden });
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    // Function to handle form submission
    onRequestCallbackSubmit = async (e) => {
        e.preventDefault();

        const { firstName, email, mobile, comments } = this.state;

        // Validation
        if (firstName.trim() === '') {
            cogoToast.error("Full Name Required.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else if (email.trim() === '') {
            cogoToast.error("Email Required.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else if (mobile.trim() === '' || !/^\d{10}$/.test(mobile.trim())) {
            cogoToast.error("Please Insert 10 digit Mobile No.", {
                position: 'top-center', // Use top-center, then adjust via CSS
                hideAfter: 2, // optional: auto-hide after 2 seconds
                className: 'custom-toast' // Apply custom class
            });
        } else {
            this.setState({ btn: "Processing..", showLoader: true });
            // Proceed with form submission
            try {
                const _ref = 'yes';
                const _url = window.location.href;

                const formData = {
                    FirstName: firstName,
                    //LastName: lastName,
                    Email: email,
                    Mobile: mobile,
                    // City: city,
                    // TravelDate: travelDate,
                    // TicketBooked: ticketBookedStatus,
                    // NoOfGuests: noOfGuests,
                    // HotelCategory: hotelCategory,
                    Comments: comments,
                    Referrer: _ref,
                    URL: _url
                };

                const response = await axios.post(ApiURL.SaveCustomerEnquery, formData);
                console.log('Data submitted successfully:', response.data);
                // Optionally, reset the form state after successful submission
                this.setState({ firstName: '', email: '', mobile: '', comments: '' });

                // Optionally, show a success message to the user
                cogoToast.success("Form submitted successfully.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
                this.setState({ btn: "Submit", showLoader: false, showModal: false });
            } catch (error) {
                console.error('Error submitting data:', error);
                this.setState({ btn: "Submit", showLoader: false });
                // Optionally, show an error message to the user
                cogoToast.error("Please try again after 24 hrs. later.", {
                    position: 'top-center', // Use top-center, then adjust via CSS
                    hideAfter: 2, // optional: auto-hide after 2 seconds
                    className: 'custom-toast' // Apply custom class
                });
            }

        }

    };
    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Kashmir-Package-Tour-from-Ahmedabad.webp";
        const packageTopBanner2ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Kashmir-Tour-Packages-from-Ahmedabad.webp";
        const sightseen1ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Magical-Trip-to-kashmir-from-Delhi.webp";
        const sightseen2ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Delhi-to-Kashmir-Tour-Packages.webp";
        const sightseen3ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Kashmir-Package-Tour-Price-ex- Srinagar.webp";
        const sightseen4ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Kashmir-Tour-Itinerary-from-Delhi.webp";
        const sightseen5ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Kashmir-Tour-Package-from-Delhi.webp";
        const sightseen6ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Kashmir-Tour-Plan-from-Delhi.webp";
        const sightseen7ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Kashmir-Trip-from-Delhi.webp";
        const sightseen8ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Kashmir-from-Delhi.webp";
        const sightseen9ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Kashmir-Honeymoon-Trip.webp";
        const sightseen10ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Kashmir-Package-Tour.webp";
        const sightseen11ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Kashmir-Package-Tour-Cost.webp";
        const sightseen12ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Customized-Kashmir-Tour-Itinary.webp";
        //day wise itenaryy
        const navDay1ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/7N-8D-Kashmir-Tulip-Festival-Package-Tour.webp";
        const navDay2ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/2024-Tulip-Garden-Kashmir-Package.webp";
        const navDay3ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Customized-Kashmir-Tour.webp";
        const navDay4ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Tulip-Festival-in-Kashmir.webp";
        const navDay5ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Srinagar-Tulip-Garden-Tour.webp";
        const navDay6ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Tulip-Garden-Tour-Package.webp";
        const navDay7ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Book-Kashmir-Tulip-Trip.webp";
        const navDay8ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Tulip-Festival-Special-2024.webp";
        const Topbanner0ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Kashmir-Houseboat-Packages.webp";
        const Topbanner1ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Kashmir-Adventure-Tour-Plan.webp";
        const Topbanner2ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Kashmir-Gondola-Package-Tour.webp";
        const Topbanner3ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Kashmir-Trekking-Packages.webp";
        const Topbanner4ImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/Kashmir-White-River-Rafting-Plan.webp";
        const brandedTourackageImage_1_URL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/The-Khyber-Himalayan-Resort-&-Spa.webp";
        const brandedTourackageImage_2_URL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/The-LaLiT-Grand-Palace-Srinagar-Hotel.webp";
        const TrendingPackagesDataList = this.props.TrendingPackagesData;
        const hotPackagesView = TrendingPackagesDataList.length > 0 ?
            (
                TrendingPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/" + dataItem.ImageName;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} <span> &#8377; {dataItem.PricePerNight}</span></h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue"> {dataItem.Include}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href="#" className="btn btn-success btn-sm float-end" onClick={this.handleClick}>
                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.Kashmir_Tour_Package_from_Ahmedabad + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })

        return (
            <>

                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">

                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center><strong>KASHMIR PACKAGE TOUR FROM AHMEDABAD </strong></center></h1>

                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center><strong>KASHMIR PACKAGE TOUR FROM AHMEDABAD </strong></center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Destination:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Srinagar (3N)
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Gulmarg (1N)
                                                </a>{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Pahalgam (3N)
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Kashmir Package Tour From Ahmedabad</strong>, you have come to the right place!
                                        </p>
                                        <hr />
                                        <br />
                                    </div>
                                    <h2 className="content-desktop p-2" style={{ fontSize: "1.6vw" }}><b>Looking for Kashmir Tour Packages from Ahmedabad ?</b><a href='tel:9147061467' class="tomato"> To book a <strong>Kashmir Package Tour from Ahmedabad</strong> ☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}><b>Looking for Kashmir Tour Packages from Ahmedabad?</b><a href='tel:9147061467' class="tomato"> To book a <strong>Kashmir Package Tour from Ahmedabad</strong> ☎ CALL Mr. Soumya +91- 91470-61467</a></h2>
                                    <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> Explore the Enchanting Beauty of Kashmir from Ahmedabad with Adorable Vacation</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> Explore the Enchanting Beauty of Kashmir from Ahmedabad with Adorable Vacation</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Welcome to the beautiful land of Kashmir, located in the northern part of India. Known for its stunning scenery, rich culture, and peaceful atmosphere, Kashmir is often called "Paradise on Earth." Our <strong>Kashmir Packages from Ahmedabad</strong> will take you on an unforgettable journey to explore this magical place.
                                            </p>
                                            <p>Historically, Kashmir referred to the Kashmir Valley, nestled between the Great Himalayas and the Pir Panjal Range. Today, it includes areas such as Jammu and Kashmir, Ladakh (under Indian control), and regions like Azad Kashmir, Gilgit-Baltistan (under Pakistani control), and Aksai Chin (under Chinese control).
                                            </p>
                                            <p>Kashmir's landscape is diverse, featuring green valleys, snow-covered mountains, and lush forests. Through our <strong>Kashmir Tour Package</strong>, you’ll experience the best of Kashmir’s breathtaking geography.
                                            </p>
                                            <p>Kashmir has four distinct seasons: spring brings blooming flowers, summer offers mild temperatures, autumn paints the valley in warm colors, and winter covers it in snow. Our <strong>kashmir Trip from Ahmedabad</strong> captures the beauty of each season.</p>
                                            <p>The region is home to rich plant and animal life, from pine forests to the famous saffron fields. You’ll also visit places like Dachigam National Park, where you might spot the rare Hangul deer.</p>
                                            <p>With Adorable Vacation, travel from Ahmedabad to Kashmir is smooth and comfortable. Whether you’re enjoying a peaceful boat ride on Dal Lake or a scenic drive, our <strong>Kashmir Tour Package from Ahmedabad</strong> ensure a stress-free experience.</p>
                                            <p>As the top <strong>Kashmir Package Tour Operator from Ahmedabad</strong>, Adorable vacation offers <strong>Customizable Kashmir Tour Packages</strong> for all types of travelers. Whether you're seeking relaxation, adventure, or a cultural journey, we have the perfect trip for you. Book your <strong>Kashmir Tour Plan from Ahmedabad</strong> today and make your dream vacation come true!</p>
                                            <p><b>Apart from that, we also provide,</b></p>
                                            <a href='/kashmir-offbeat-package-tour-with-baisaran-valley-doodhpathri-yusmarg' target='_blank'><b className="tomato">Kashmir Package Tour with Doodhpathri Yusmarg</b></a>, <a href='/kashmir-offbeat-package-tour-with-gurez-valley' target='_blank'><b className="tomato">Kashmir Package Tour with Gurez Valley</b></a>,
                                            <a href='/kashmir-offbeat-package-tour-with-sinthan-top-aru-valley-betab-valley-chandanbari-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Offbeat Package Tour with Sinthan Top</b></a>,
                                            <a href='/kashmir-package-tour-with-vaishno-devi-katra-pahalgam-gulmarg-srinagar' target='_blank'><b className="tomato">Kashmir Package Tour with Vaishno Devi</b></a>,
                                            <a href='/kashmir-tulip-garden-festival-package-tour' target='_blank'><b className="tomato">Kashmir Package Tour with Tulip Package</b></a>,
                                            <a href='/kashmir-package-tour-from-kolkata-with-srinagar-gulmarg-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Package Tour from Kolkata </b></a>,
                                            <a href='/kashmir-package-tour-from-surat' target='_blank'><b className="tomato">Kashmir Package Tour from Surat </b></a>,
                                            <a href='/kashmir-package-tour-from-chennai' target='_blank'><b className="tomato">Kashmir Package Tour from Chennai </b></a>,
                                            <a href='/kashmir-package-tour-from-bangalore' target='_blank'><b className="tomato">Kashmir Package Tour from Bangalore </b></a>,
                                            <a href='/kashmir-package-tour-from-pune' target='_blank'><b className="tomato">Kashmir Package Tour from Pune </b></a>,
                                            <a href='/kashmir-package-tour-from-mumbai' target='_blank'><b className="tomato">Kashmir Package Tour from Mumbai </b></a>,
                                            <a href='/kashmir-package-tour-from-delhi' target='_blank'><b className="tomato">Kashmir Package Tour from Delhi </b></a>
                                            <br /><br />
                                            <p>Book your dream destination with Adorable Vacation and for further information do not hesitate to contact us at +91 9147061467</p>
                                            <br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen1ImageURL} alt="kashmir tour packages" />
                                                            <img src={sightseen2ImageURL} alt="kashmir trip from srinagar airport" />
                                                            <img src={sightseen3ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /> <br /> <br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>Duration for Ahmedabad to Kashmir Tour Package : 7N | 8D</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>Duration for Ahmedabad to Kashmir Tour Package : 7N | 8D</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Places Covered in the Ahmedabad to <strong>Kashmir Package Tour </strong> : Srinagar Houseboat 1N | Gulmarg 1N | Pahalgam 2N | Srinagar 3N </p>
                                            <p>Best <strong>Kashmir Tour Packages from Ahmedabad</strong> Pickup & Drop : Sheikh Ul Alam International Airport, Srinagar [SXR]</p>
                                            <p><b>Address</b>: Indra Gandhi Road, Humhama, Badgam, Srinagar, Jammu and Kashmir 190007</p>
                                            <br /><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>Best Kashmir Tour Packages from Ahmedabad</strong> <b>Pickup & Drop</b></td>
                                                            <td>Sheikh Ul Alam International Airport, Srinagar [SXR]</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Duration for Ahmedabad to Kashmir Tour Package</strong></td>
                                                            <td>07 Nights | 08 Days</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Ahmedabad to Kashmir Package Price </strong></td>
                                                            <td>Rs. 16,400/Based on 8 Pax</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Ahmedabad to Kashmir Travel Packages Distance</strong></td>
                                                            <td>14 hr 54 min (720.6 km) via NH 44</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Srinagar</strong></td>
                                                            <td>1,585 m | Coordinates - 34.0837° N, 74.7973° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Gulmarg</strong></td>
                                                            <td>2,650 m | Coordinates - 34.0484° N, 74.3805° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Sonmarg</strong></td>
                                                            <td>2,730 m | Coordinates - 34.3032° N, 75.2931° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Pahalgam</strong></td>
                                                            <td>2,740 m | Coordinates - 34.0161° N, 75.3150° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Betaab Valley</strong></td>
                                                            <td>2,393 m | Coordinates - 34.0541° N, 75.3639° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Aru Valley</strong></td>
                                                            <td>2,414 m | Coordinates - 34.0922° N, 75.2632° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Chandanwadi</strong></td>
                                                            <td>3,288 m | Coordinates - 34.2122° N, 73.9295° E</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Altitude of Doodhpathri</strong></td>
                                                            <td>2,730 m | Coordinates - 33.8509° N, 74.5630° E</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br /><br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen4ImageURL} alt="kashmir package tour from srinagar" />
                                                            <img src={sightseen5ImageURL} alt="kashmir package price from srinagar airport" />
                                                            <img src={sightseen6ImageURL} alt="kashmir packages from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />

                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    {/* start short itinerary */}

                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr className="table-danger">
                                                        <th>DAY-BY-DAY SUMMARY FOR KASHMIR TOUR PACKAGES FROM AHMEDABAD</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingOne"
                                                        >
                                                            <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                                <span>
                                                                    ARRIVE IN SRINAGAR, ENJOY LOCAL SIGHTSEEING
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingTwo"
                                                        >
                                                            <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                                <span>TAKE A FULL-DAY TRIP TO GULMARG </span>
                                                            </a>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingThree"
                                                        >
                                                            <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                                <span>TRAVEL FROM GULMARG TO PAHALGAM AND STAY </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFour"
                                                        >
                                                            <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                                <span>EXPLORE PAHALGAM, VISITING BETAAB VALLEY AND MORE</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingFive"
                                                        >
                                                            <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                                <span>TRANSFER BACK TO SRINAGAR</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSix"
                                                        >
                                                            <a href="#day6" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                                <span>ENJOY A DAY TRIP TO DOODHPATHRI</span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingSeven"
                                                        >
                                                            <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                                <span>TAKE A DAY TRIP TO SONMARG </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            className="clickable"
                                                            data-target="panelsStayOpen-headingEight"
                                                        >
                                                            <a href="#day8" style={{ color: 'black' }}><b>DAY 8 → </b>
                                                                <span>DEPART FROM SRINAGAR AIRPORT </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <!--end Short description--> */}
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>KASHMIR PACKAGE TOUR COST FROM AHMEDABAD</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>KASHMIR PACKAGE TOUR COST FROM AHMEDABAD</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>TOUR DURATION</th>
                                                            <th>2 PAX</th>
                                                            <th>4 PAX</th>
                                                            <th>6 PAX</th>
                                                            <th>8 PAX</th>
                                                            <th>BOOK NOW</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">4N/5D</td>
                                                            <td data-title="2 ADULTS">₹ 20,500/-</td>
                                                            <td data-title="4 ADULTS">₹ 18,300/-</td>
                                                            <td data-title="6 ADULTS">₹ 16,600/-</td>
                                                            <td data-title="8 ADULTS">₹ 16,400/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">5N/6D</td>
                                                            <td data-title="2 ADULTS">₹ 24,700/-</td>
                                                            <td data-title="4 ADULTS">₹ 22,000/-</td>
                                                            <td data-title="6 ADULTS">₹ 19,800/-</td>
                                                            <td data-title="8 ADULTS">₹ 20,800/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">6N/7D</td>
                                                            <td data-title="2 ADULTS">₹ 30,900/-</td>
                                                            <td data-title="4 ADULTS">₹ 27,400/-</td>
                                                            <td data-title="6 ADULTS">₹ 24,700/-</td>
                                                            <td data-title="8 ADULTS">₹ 24,500/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="TOUR DURATION">7N/8D</td>
                                                            <td data-title="2 ADULTS">₹ 35,600/-</td>
                                                            <td data-title="4 ADULTS">₹ 30,800/-</td>
                                                            <td data-title="6 ADULTS">₹ 28,300/-</td>
                                                            <td data-title="8 ADULTS">₹ 28,100/-</td>
                                                            <td data-title="Book Now"><a href="#" class="btn btn-success" onClick={this.handleClick}>Book Now</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><b>TOP NATIONAL PARKS TO VISIT </b><strong>KASHMIR VACATION PACKAGE FROM AHMEDABAD</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><b>TOP NATIONAL PARKS TO VISIT </b> <strong>KASHMIR VACATION PACKAGE FROM AHMEDABAD</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Kashmir, often called "Paradise on Earth," is a perfect destination for travelers who love natural beauty, culture, and adventure. If you're booking a <strong>Kashmir Package Tour from Ahmedabad</strong> and are a nature lover, here’s a national park you shouldn't miss during your 7-night, 8-day trip :</p>
                                            <p><b>Dachigam National Park, Kashmir</b> : Located in the Zabarwan mountains, Dachigam National Park is a wildlife lover's paradise. It is home to the rare Hangul deer (Kashmir stag) and also features Himalayan brown bears, red foxes, and birds like the golden eagle. With luck, you might even spot a snow leopard!
                                            </p>
                                            <br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Category</th>
                                                            <th>Information</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Category">Location</td>
                                                            <td data-title="Information">Dachigam Road, Srinagar</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Category">Best Time to Travel</td>
                                                            <td data-title="Information">June to August</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Category">Timings</td>
                                                            <td data-title="Information">8 AM to 6 PM</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Category">Entry Fees</td>
                                                            <td data-title="Information">NIL</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br />
                                            <h5><b>Kazinag National Park, Kashmir: </b></h5><br />
                                            <p>Kazinag National Park, also called the Blackbird Sanctuary, is a beautiful wetland area in the Kashmir Valley. It’s a great spot for birdwatchers, with over 400 bird species seen here. Visitors can enjoy birdwatching, hiking, and boat rides on the park’s peaceful lakes. Birds you might see include the common teal, gadwall, mallard, and the rare blackbird.</p>
                                            <br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Category</th>
                                                            <th>Information</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Category">Location</td>
                                                            <td data-title="Information">Dachigam Road, Srinagar</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Category">Best Time to Travel</td>
                                                            <td data-title="Information">June to August</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Category">Timings</td>
                                                            <td data-title="Information">8 AM to 6 PM</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Category">Entry Fees</td>
                                                            <td data-title="Information">NIL</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br />
                                            <h5><b>Hemis National Park, Kashmir: </b></h5><br />
                                            <p>Hemis National Park, in the Ladakh region of Jammu and Kashmir, is India's largest protected area. This high-altitude park is home to rare animals like the snow leopard, Tibetan wolf, Himalayan marmot, and Bharal sheep. Bird lovers can spot over 140 types of birds here. The park is also known for the Hemis Festival, a lively event with masked dances and traditional music held every July.</p>
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Category</th>
                                                            <th>Information</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Category">Location</td>
                                                            <td data-title="Information">Dachigam Road, Srinagar</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Category">Best Time to Travel</td>
                                                            <td data-title="Information">June to August</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Category">Timings</td>
                                                            <td data-title="Information">8 AM to 6 PM</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Category">Entry Fees</td>
                                                            <td data-title="Information">NIL</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br />
                                            <h5><b>Salim Ali National Park, Kashmir: </b></h5><br />
                                            <p>Salim Ali National Park, named after a famous Indian bird expert, is perfect for bird lovers. Located by Dal Lake in Srinagar, the park has over 350 bird species. Visitors can enjoy birdwatching, nature walks, and shikara rides on the lake. You might see birds like the grey heron, kingfisher, cormorant, and paradise flycatcher. It’s a great place to relax and take in the beauty of Kashmir's nature.</p>
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Category</th>
                                                            <th>Information</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Category">Location</td>
                                                            <td data-title="Information">Dachigam Road, Srinagar</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Category">Best Time to Travel</td>
                                                            <td data-title="Information">June to August</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Category">Timings</td>
                                                            <td data-title="Information">8 AM to 6 PM</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Category">Entry Fees</td>
                                                            <td data-title="Information">NIL</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br />
                                            <p><b>Kishtwar National Park, Kashmir:</b> Kishtwar National Park, located in the outer Himalayas, is full of diverse wildlife. You can spot animals like the western tragopan, koklass pheasant, Himalayan black bear, and musk deer. The park also offers stunning views with tall mountains, green meadows, and deep valleys. For adventure lovers, it’s a great place to go trekking, camping, or mountain climbing.</p>
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Category</th>
                                                            <th>Information</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Category">Location</td>
                                                            <td data-title="Information">Dachigam Road, Srinagar</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Category">Best Time to Travel</td>
                                                            <td data-title="Information">June to August</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Category">Timings</td>
                                                            <td data-title="Information">8 AM to 6 PM</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Category">Entry Fees</td>
                                                            <td data-title="Information">NIL</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br />
                                            <p>Discover the beauty of Kashmir's national parks with Adorable Vacation. Enjoy nature, culture, and adventure by booking your <strong>Kashmir Tour from Ahmedabad</strong>.</p>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>BEST WAYS TO REACH AHMEDABAD TO KASHMIR</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>BEST WAYS TO REACH AHMEDABAD TO KASHMIR</h4>
                                        </div>
                                        <div className="card-body">
                                            <p><b>1. By Flight:</b>
                                                The easiest and fastest way is to fly from Sardar Vallabhbhai Patel International Airport in Ahmedabad to Sheikh ul-Alam International Airport in Srinagar. Several airlines offer daily flights, making this a quick and comfortable option.
                                            </p>
                                            <p><b>2. By Road:</b>
                                                Enjoy a scenic road trip from Ahmedabad to Kashmir via NH44. This route lets you take in beautiful landscapes along the way.
                                            </p>
                                            <p><b>3. By Train:</b>
                                                For a more relaxed journey, take a train from Ahmedabad Junction to Jammu Tawi. From Jammu, you can continue your trip to Kashmir by road.
                                            </p>
                                            <br />
                                            <h4>TRAIN TICKETS FOR <strong>KASHMIR PACKAGES FROM AHMEDABAD BY TRAIN</strong></h4><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Train No</th>
                                                            <th>Train Name</th>
                                                            <th>Dep. Station</th>
                                                            <th>Dep. Time</th>
                                                            <th>Arr. Station</th>
                                                            <th>Arr. Time</th>
                                                            <th>Travel Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Train No">19223</td>
                                                            <td data-title="Train Name">Ahmedabad - Jammu Tawi Express</td>
                                                            <td data-title="Dep. Station">Ahmedabad (ADI)</td>
                                                            <td data-title="Dep. Time">11:05</td>
                                                            <td data-title="Arr. Station">Jammu Tawi (JAT)</td>
                                                            <td data-title="Arr. Time">18:40</td>
                                                            <td data-title="Travel Time">31hr 35min</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Train No">12475</td>
                                                            <td data-title="Train Name">Hapa - SMVD Katra SF Express</td>
                                                            <td data-title="Dep. Station">Ahmedabad (ADI)</td>
                                                            <td data-title="Dep. Time">14:25</td>
                                                            <td data-title="Arr. Station">Jammu Tawi (JAT)</td>
                                                            <td data-title="Arr. Time">14:55</td>
                                                            <td data-title="Travel Time">24hr 30min</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Train No">12477</td>
                                                            <td data-title="Train Name">Jamnagar - Shri Mata Vaishno Devi Katra SF Express</td>
                                                            <td data-title="Dep. Station">Ahmedabad (ADI)</td>
                                                            <td data-title="Dep. Time">14:25</td>
                                                            <td data-title="Arr. Station">Jammu Tawi (JAT)</td>
                                                            <td data-title="Arr. Time">14:55</td>
                                                            <td data-title="Travel Time">24hr 30min</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Train No">12473</td>
                                                            <td data-title="Train Name">Sarvodaya SF Express</td>
                                                            <td data-title="Dep. Station">Ahmedabad (ADI)</td>
                                                            <td data-title="Dep. Time">14:25</td>
                                                            <td data-title="Arr. Station">Jammu Tawi (JAT)</td>
                                                            <td data-title="Arr. Time">14:55</td>
                                                            <td data-title="Travel Time">24hr 30min</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Train No">19027</td>
                                                            <td data-title="Train Name">Vivek Express</td>
                                                            <td data-title="Dep. Station">Ahmedabad (ADI)</td>
                                                            <td data-title="Dep. Time">19:05</td>
                                                            <td data-title="Arr. Station">Jammu Tawi (JAT)</td>
                                                            <td data-title="Arr. Time">23:05</td>
                                                            <td data-title="Travel Time">28hr 00min</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Train No">19415</td>
                                                            <td data-title="Train Name">Ahmedabad - SMVD Katra Express</td>
                                                            <td data-title="Dep. Station">Ahmedabad (ADI)</td>
                                                            <td data-title="Dep. Time">20:20</td>
                                                            <td data-title="Arr. Station">Jammu Tawi (JAT)</td>
                                                            <td data-title="Arr. Time">04:10</td>
                                                            <td data-title="Travel Time">31hr 50min</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br /> <br />
                                            <h4><strong>KASHMIR PACKAGE FROM AHMEDABAD BY ROAD</strong></h4><br /> <br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>JOURNEY DAYS</th>
                                                            <th>ROUTE FROM</th>
                                                            <th>ROUTE TO</th>
                                                            <th>HIGHWAY</th>
                                                            <th>DISTANCE</th>
                                                            <th>JOURNEY TIME</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="JOURNEY DAYS">Day 1</td>
                                                            <td data-title="ROUTE FROM">Ahmedabad</td>
                                                            <td data-title="ROUTE TO">Udaipur</td>
                                                            <td data-title="NH">Via NH 48</td>
                                                            <td data-title="DISTANCE">257.8 Km</td>
                                                            <td data-title="JOURNEY TIME">4 hr 37 min</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="JOURNEY DAYS">Day 2</td>
                                                            <td data-title="ROUTE FROM">Udaipur</td>
                                                            <td data-title="ROUTE TO">Jaipur</td>
                                                            <td data-title="NH">Via NH 48</td>
                                                            <td data-title="DISTANCE">396.2 Km</td>
                                                            <td data-title="JOURNEY TIME">6 hr 54 min</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="JOURNEY DAYS">Day 3</td>
                                                            <td data-title="ROUTE FROM">Jaipur</td>
                                                            <td data-title="ROUTE TO">Delhi</td>
                                                            <td data-title="NH">Via NE 4</td>
                                                            <td data-title="DISTANCE">308.2 Km</td>
                                                            <td data-title="JOURNEY TIME">5 hr 18 min</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="JOURNEY DAYS">Day 4</td>
                                                            <td data-title="ROUTE FROM">Delhi</td>
                                                            <td data-title="ROUTE TO">Jammu</td>
                                                            <td data-title="NH">Via NH 44</td>
                                                            <td data-title="DISTANCE">586.2 Km</td>
                                                            <td data-title="JOURNEY TIME">10 hr 38 min</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="JOURNEY DAYS">Day 5</td>
                                                            <td data-title="ROUTE FROM">Jammu</td>
                                                            <td data-title="ROUTE TO">Srinagar</td>
                                                            <td data-title="NH">Via NH 244A and NH 44</td>
                                                            <td data-title="DISTANCE">244.5 Km</td>
                                                            <td data-title="JOURNEY TIME">5 hr 47 min</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>BOOK FOR KASHMIR PACKAGE TOUR FROM AHMEDABAD BY FLIGHT</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BOOK FOR KASHMIR PACKAGE TOUR FROM AHMEDABAD BY FLIGHT</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Flying from Ahmedabad to Srinagar is the quickest option. Booking a flight ensures a smooth and convenient trip, with several airlines offering daily services.</p>
                                            <br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Flight Name</th>
                                                            <th>Dep. Time</th>
                                                            <th>Arr. Time</th>
                                                            <th>Travel Time</th>
                                                            <th>Stop Over</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Flight Name">IndiGo 6E - 6945</td>
                                                            <td data-title="Dep. Time">AMD 06:55</td>
                                                            <td data-title="Arr. Time">SXR 09:05</td>
                                                            <td data-title="Travel Time">2h 10m</td>
                                                            <td data-title="Stop Over">Non stop</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Flight Name">IndiGo 6E – 6375, 6E - 6871</td>
                                                            <td data-title="Dep. Time">AMD 09:30</td>
                                                            <td data-title="Arr. Time">SXR 14:20</td>
                                                            <td data-title="Travel Time">4h 50m</td>
                                                            <td data-title="Stop Over">Via Chandigarh</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Flight Name">IndiGo 6E – 2209, 6E - 2120</td>
                                                            <td data-title="Dep. Time">AMD 04:55</td>
                                                            <td data-title="Arr. Time">SXR 10:05</td>
                                                            <td data-title="Travel Time">5h 10m</td>
                                                            <td data-title="Stop Over">Via New Delhi</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>DISCOVER KASHMIR ON YOUR CUSTOMIZABLE KASHMIR TOUR PACKAGES</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>DISCOVER KASHMIR ON YOUR CUSTOMIZABLE KASHMIR TOUR PACKAGES</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Dreaming of a vacation surrounded by stunning mountains, peaceful lakes, and historic sites? Consider a <strong>Kashmir tour package from Ahmedabad</strong> and get ready to explore Srinagar, the "Crown Jewel of Kashmir." Here are some top attractions you can look forward to:</p>
                                            <p><b>Dal Lake : A Shikara Ride Through Paradise</b> No trip to Kashmir is complete without a shikara ride on Dal Lake. Enjoy the beautiful reflections of snow-capped peaks on the water, visit floating vegetable gardens, and see colorful houseboats. As the sun sets, enjoy the vibrant sky and serene atmosphere.</p>
                                            <p><b>Mughal Gardens: A Legacy of Grandeur</b> Explore the Mughal Gardens like Shalimar Bagh, Nishat Bagh, and Chashme Shahi. With their fountains, lush lawns, and colorful flowers, these gardens showcase Mughal architecture. Stroll through these gardens and take photos to remember your visit.</p>
                                            <p><b>Hazratbal Shrine: A Spiritual Sanctuary</b> Visit the Hazratbal Shrine on the shores of Dal Lake. This important Islamic site houses a holy relic and features beautiful Mughal-Kashmiri architecture. It’s especially lively during Eid celebrations, offering a glimpse into local culture.</p>
                                            <p><b>Pari Mahal: The Abode of the Fairies</b> Check out Pari Mahal, also known as the "Abode of the Fairies." This historic building on a hilltop was once a royal observatory. Enjoy the unique architecture and panoramic views of the valley.</p>
                                            <p><b>Srinagar Markets: A Shopper's Paradise</b> Explore Srinagar’s bustling markets, from Maharaj Gunj to Zaidi Lane. Shop for pashmina shawls, hand-woven carpets, and traditional jewelry. Experience the lively atmosphere and friendly local vendors.</p>
                                            <p>Your <strong>Kashmir Package Tour from Ahmedabad</strong> can also include other beautiful places. Gulmarg is perfect for skiing and golfing with its amazing meadows and mountain views. Pahalgam, the "Valley of Shepherds," is great for trekking and nature walks.</p>
                                            <p>So pack your bags, <strong>Booking Ahmedabad to Kashmir tour Package</strong>, and get ready for an unforgettable journey through Srinagar and beyond. Enjoy every moment of this magical trip!</p>

                                        </div>
                                    </div>
                                    <br /><br /><br />

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>BEST KASHMIR OFFBEAT HOLIDAY PACKAGES FROM AHMEDABAD</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BEST KASHMIR OFFBEAT HOLIDAY PACKAGES FROM AHMEDABAD</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Kashmir Tour Packages</th>
                                                            <th>Price (Per Person)</th>
                                                            <th>Days/Nights</th>
                                                            <th>Inclusions</th>
                                                            <th>Places to Visit</th>
                                                            <th>Book Now</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-tulip-garden-festival-package-tour' target='_blank'><b className="tomato">Kashmir Package Tour with Tulip Package</b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Tulip Garden Tour</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-offbeat-package-tour-with-gurez-valley' target='_blank'><b className="tomato">Kashmir Package Tour with Gurez Valley</b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Gurez Valley</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"> <a href='/kashmir-offbeat-package-tour-with-baisaran-valley-doodhpathri-yusmarg' target='_blank'><b className="tomato">Kashmir Package Tour with Doodhpathri Yusmarg</b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Doodh Pathri, Sonmarg, Yusmarg</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-offbeat-package-tour-with-sinthan-top-aru-valley-betab-valley-chandanbari-pahalgam-sonmarg' target='_blank'><b className="tomato">Kashmir Offbeat Package Tour with Sinthan Top</b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Srinagar, Gulmarg, Pahalgam, Sinthan Top, Sonmarg</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/jammu-kashmir-family-tour-packages' target='_blank'><b className="tomato">Jammu Kashmir Family Tour Packages</b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Katra, Patnitop, Pahalgam, Gulmarg, Gurez Valley, Srinagar</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-with-amritsar-katra-vaishno-devi-pahalgam-gulmarg-srinagar-sonmarg' target='_blank'><b className="tomato">Kashmir Tour Packages with Amritsar Katra Vaishno Devi </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Amritsar, Vaishno Devi Darshan, Katra, Pahalgam, Gulmarg, Sonmarg, Srinagar</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td data-title="Packages"><a href='/kashmir-package-tour-with-vaishno-devi-katra-pahalgam-gulmarg-srinagar' target='_blank'><b className="tomato">Kashmir Tour Packages with Vaishno Devi </b></a></td>
                                                            <td data-title="Price(PP)">₹28,100/-</td>
                                                            <td data-title="Days/Nights">7N 8D</td>
                                                            <td data-title="Inclusions">Meals (MAP), Accommodation, Transfer, Sightseeing</td>
                                                            <td data-title="Places to Visit">Vaishno Devi Darshan, Katra, Pahalgam, Gulmarg, Srinagar</td>
                                                            <td data-title="Book Now">
                                                                <center>
                                                                    <a href="#" className="btn book-now-btn" onClick={this.onRequestCallback}>Book Now</a>
                                                                </center>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHAT IS THE BEST TIME TO <strong>KASHMIR HOLIDAY WITH HOUSEBOAT STAY PACKAGE FROM AHMEDABAD</strong>?</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT IS THE BEST TIME TO <strong>KASHMIR HOLIDAY WITH HOUSEBOAT STAY PACKAGE FROM AHMEDABAD</strong>?</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>The best time to book a <strong>Kashmir Tour Plan from Ahemdabad</strong> is between March and November, from spring to autumn. During this time, Kashmir is at its most beautiful, with colorful flowers blooming, green meadows, and clear skies. The snow-capped mountains provide a peaceful and romantic backdrop, perfect for enjoying a Shikara ride on Dal Lake.</p>
                                            <p><b>SPRING [MARCH-EARLY MAY] </b>
                                                In spring, Kashmir comes to life with blooming flowers and green meadows as the snow melts. The temperature is pleasant, making it a great time for nature lovers to explore the valley as it refreshes after winter.
                                            </p>
                                            <p><b>SUMMER [EARLY MAY-LATE AUGUST] </b>
                                                During summer, the weather is mild and skies are clear, perfect for outdoor activities like trekking, golfing, and shikara rides on Dal Lake. It's an excellent time for family vacations, with a cool breeze and beautiful mountain views.
                                            </p>
                                            <p><b>AUTUMN [SEPTEMBER-NOVEMBER] </b>
                                                Autumn is magical in Kashmir, with the landscape turning red and gold as Chinar trees shed their leaves. This season offers a romantic, peaceful atmosphere, and you can also visit the famous Saffron fields of Pampore, known for their beauty and rare spices.
                                            </p>
                                            <p><b>WINTER [DECEMBER-EARLY MARCH] </b>
                                                If you love snow, visit Kashmir in winter for a snowy wonderland. The valley is covered in snow, offering activities like skiing and snowboarding. The frozen Dal Lake provides a special shikara ride experience amidst the snowy landscape.
                                            </p>
                                            <p>So, plan your <strong>Kashmir Trip From Ahmedabad</strong> and experience the natural beauty of this incredible destination.</p>
                                            <br /><br />
                                            <h4><b>MONTH-WISE TEMPERATURE CHART FOR KASHMIR PACKAGES FROM AHMEDABAD</b></h4><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Months</th>
                                                            <th>Temp. (Min - Max)</th>
                                                            <th>Season</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Months">March - May</td>
                                                            <td data-title="Temp. (Min - Max)">10°C - 20°C</td>
                                                            <td data-title="Season">Spring</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Months">June - August</td>
                                                            <td data-title="Temp. (Min - Max)">15°C - 30°C</td>
                                                            <td data-title="Season">Summer</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Months">September - November</td>
                                                            <td data-title="Temp. (Min - Max)">10°C - 25°C</td>
                                                            <td data-title="Season">Autumn</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Months">December - February</td>
                                                            <td data-title="Temp. (Min - Max)">-2°C - 10°C</td>
                                                            <td data-title="Season">Winter</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>BEST TIME TO BOOKING AHMEDABAD TO <strong>KASHMIR TOUR PACKAGE</strong>?</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHAT ARE THE BEST ATTRACTIONS OF THE <strong>KASHMIR HOLIDAY PACKAGES FROM AHMEDABAD</strong>?</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Planning a trip to <strong>Kashmir from Ahmedabad</strong> ? It  covered with Customized Kashmir Packages from Ahmedabad for families, couples, and groups, starting at great prices. Whether you need flights included or not, our packages take you to all the top sights in Kashmir, and you can even add extra activities to make your trip perfect.</p>
                                            <p><b>Best Time to Visit Kashmir</b></p>
                                            <p>The best time to visit Kashmir is from March to August when the weather is pleasant and the landscapes are stunning. If you want to see snowfall and a snowy landscape, visit between November and December.</p>
                                            <h5><b>BEST SEASONS TO NOTE BEFORE</b> <strong>KASHMIR PACKAGE TOUR WITH ADORABLE VACATION</strong></h5><br />
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Season</th>
                                                            <th>Climate</th>
                                                            <th>Temperature Range</th>
                                                            <th>Special Attractions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Season">Spring</td>
                                                            <td data-title="Climate">Mild</td>
                                                            <td data-title="Temperature">10°C to 23°C</td>
                                                            <td data-title="Attractions">Blossoming Gardens, Tulip Festival</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Season">Summer</td>
                                                            <td data-title="Climate">Warm</td>
                                                            <td data-title="Temperature">20°C to 35°C</td>
                                                            <td data-title="Attractions">Pleasant Weather, Shikara Rides, Trekking in the Himalayas</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Season">Monsoon</td>
                                                            <td data-title="Climate">Rainy</td>
                                                            <td data-title="Temperature">15°C to 30°C</td>
                                                            <td data-title="Attractions">Lush Greenery, Houseboat Stays, Waterfall Sightseeing</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Season">Autumn</td>
                                                            <td data-title="Climate">Pleasant</td>
                                                            <td data-title="Temperature">10°C to 25°C</td>
                                                            <td data-title="Attractions">Vibrant Landscapes, Harvest Festivals, Photography Opportunities</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Season">Winter</td>
                                                            <td data-title="Climate">Cold</td>
                                                            <td data-title="Temperature">0°C to 15°C</td>
                                                            <td data-title="Attractions">Snowfall in Gulmarg, Skiing, Snowboarding, Frozen Lakes</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>THINGS TO DO ON <strong>KASHMIR TOUR PACKAGE BOOKING FROM AHMEDABAD</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}>THINGS TO DO ON <strong>KASHMIR TOUR PACKAGE BOOKING FROM AHMEDABAD</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Kashmir, known as "Paradise on Earth," offers a range of activities for every traveler. Here are some top things to do:</p>
                                            <br />
                                            <h5><b>Sightseeing and Culture:</b></h5><br />
                                            <ul>
                                                <li><b>• Explore Srinagar:</b> Enjoy a Shikara ride on Dal Lake, visit Mughal Gardens and Shankaracharya Temple, and shop in local markets.</li><br />
                                                <li><b>• Marvel at Gulmarg:</b>Known as the "Meadow of Flowers," Gulmarg offers mountain views, Gondola rides, pony rides, and visits to the Maharani Temple.</li><br />
                                                <li><b>• Experience Pahalgam:</b> Hike through meadows and forests, try white-water rafting on the Lidder River, or visit Baisaran meadow.</li><br />
                                                <li><b>•	Explore Sonamarg:</b> With its glaciers and snow-capped mountains, take a pony ride to Thajiwas Glacier, visit Krishnasar Lake, or explore local villages.</li>
                                            </ul>
                                            <br />
                                            <h5><b>Adventure Activities:</b></h5>
                                            <br />
                                            <ul>
                                                <li><b>•	Skiing or Snowboarding:</b> Enjoy these winter sports in Gulmarg.</li><br />
                                                <li><b>•	Camping:</b> Camp under the stars in scenic spots.</li><br />
                                                <li><b>•	River Rafting:</b> Try white-water rafting on the Lidder River in Pahalgam.</li>
                                            </ul>
                                            <br />
                                            <h5><b>Relaxation and Leisure:</b></h5>
                                            <br />
                                            <ul>
                                                <li><b>• Houseboat Stay:</b> Experience the charm of staying on a houseboat on Dal Lake in Srinagar.</li><br />
                                                <li><b>• Shopping:</b> Shop for Kashmiri handicrafts, shawls, and carpets.</li><br />
                                                <li><b>• Indulge in Kashmiri Cuisine:</b> Savor unique dishes like Rogan Josh.</li><br />
                                                <li><b>• Relaxing Massage:</b> Treat yourself to a traditional Kashmiri massage.</li><br />

                                            </ul>
                                            <br />
                                            <h5>
                                                <b>Additionally:</b>
                                            </h5><br />
                                            <ul>
                                                <li><b>• Attend a Cultural Performance:</b> Enjoy local music and dance shows.</li><br />
                                                <li><b>• Visit a Local Festival:</b> Experience vibrant events like the Hemis Festival and Tulip Festival.</li></ul><br />
                                            <p>You can <strong>Customized Kashmir Packages from Ahmedabad</strong> to include the activities that interest you most.</p>
                                            <br /><br />
                                            <table className="image-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="image-cell">
                                                            <img src={sightseen10ImageURL} alt="kashmir tour packages" />
                                                            <img src={sightseen11ImageURL} alt="kashmir trip from srinagar airport" />
                                                            <img src={sightseen12ImageURL} alt="kashmir package tour cost from srinagar airport" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>SIGHTSEEING ENTRY FEES DURING <strong>BOOK KASHMIR PACKAGES FROM AHMEDABAD</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>SIGHTSEEING ENTRY FEES DURING <strong>BOOK KASHMIR PACKAGES FROM AHMEDABAD</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <tbody>
                                                        <tr>
                                                            <td>Places</td>
                                                            <td>Entry Fees</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dal Lake</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Nishant Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pari Mahal</td>
                                                            <td>Rs. 40/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shalimar Bagh</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shankaracharya Temple</td>
                                                            <td>NIL</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Chashme Shahi</td>
                                                            <td>Rs. 24 per adult, Rs. 12 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Verinag Garden</td>
                                                            <td>Rs. 10/Person</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SPS Museum</td>
                                                            <td>Rs. 10 for Indian, Rs. 50 for Foreigners</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tulip Garden</td>
                                                            <td>Rs. 75 per adult, Rs. 30 per child</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Aru Valley</td>
                                                            <td>Rs. 25/Person</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="card">
                                        <div className="card-header custHeaer">
                                            <h4 className='content-desktop' style={{ fontSize: "1.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES FROM AHMEDABAD</strong></h4>
                                            <h4 className='content-mobile2' style={{ fontSize: "3.5vw" }}>MUST DO ACTIVITIES DURING <strong>KASHMIR TOUR PACKAGES FROM AHMEDABAD</strong></h4>

                                        </div>
                                        <div className="card-body">
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Shikara Rides:</b><b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Shikara Rides:</b> Experience the tranquility of Dal Lake with a leisurely Shikara ride During your <strong> Kashmir Tulip Festival Package Tour</strong>. Glide across the serene waters, passing by floating gardens, houseboats, and vibrant markets. Sunset and early morning rides offer particularly magical views.</p>
                                            <center> <img src={Topbanner0ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Skiing and Snowboarding:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Skiing and Snowboarding:</b> During the winter months, places like Gulmarg transform into ski resorts, attracting enthusiasts from around the world. Gulmarg offers excellent slopes for skiing, snowboarding, and heli-skiing, with breathtaking views of the surrounding Himalayan peaks.</p>
                                            <center> <img src={Topbanner1ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Gondola Ride: </b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Gondola Ride: </b>
                                                Take a ride on the Gulmarg Gondola, one of the highest cable cars in the world. Enjoy panoramic views of the snow-capped peaks as you ascend to Kongdori or Apharwat Peak, where you can indulge in activities like hiking and mountain biking.Experience this Awesome Ride During your <strong>Kashmir Tulip Garden Tour</strong> with Adorable Vacation and make this as a life long memories.</p>
                                            <center> <img src={Topbanner2ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />

                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>Trekking:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>Trekking:</b> Kashmir is a paradise for trekkers, with numerous trails that cater to all levels of expertise. Popular trekking destinations include the Kashmir Great Lakes Trek, Tarsar Marsar Trek, and the trek to Thajiwas Glacier in Sonamarg.</p>
                                            <center> <img src={Topbanner3ImageURL} className="img-fluid" alt="..." /></center><br /><br /><br />
                                            <p><b style={{ fontSize: "1.3vw" }} className='tomato content-desktop'>River Rafting:</b>
                                                <b style={{ fontSize: "3.5vw" }} className='tomato content-mobile2'>River Rafting:</b> The Lidder River in Pahalgam offers exciting opportunities for white-water rafting. Experience the thrill of navigating through rapids while enjoying the scenic beauty of the surrounding pine forests and mountains.</p>
                                            <center> <img src={Topbanner4ImageURL} className="img-fluid" alt="..." /></center>
                                            <br /><br /><br />
                                            <a href="tel://9147061467" className="btn btn-Warning" style={{ padding: '15px 30px', fontSize: '20px' }}>CALL NOW</a>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                    <div className="container-fluid navtabCustom">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button onClick={() => this.handleCustomTabClick('tab1')} className={`tab button ${customActiveTab === 'tab1' ? 'active' : ''}`} role="tab" aria-controls="nav-highlights" aria-selected={customActiveTab === 'tab1'}>Highlights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab2')} className={`tab button ${customActiveTab === 'tab2' ? 'active' : ''}`} role="tab" aria-controls="nav-flights" aria-selected={customActiveTab === 'tab2'}>Flights</button>
                                                <button onClick={() => this.handleCustomTabClick('tab3')} className={`tab button ${customActiveTab === 'tab3' ? 'active' : ''}`} role="tab" aria-controls="nav-hotel" aria-selected={customActiveTab === 'tab3'}>Hotel</button>
                                                <button onClick={() => this.handleCustomTabClick('tab4')} className={`tab button ${customActiveTab === 'tab4' ? 'active' : ''}`} role="tab" aria-controls="nav-sightseeing" aria-selected={customActiveTab === 'tab4'}>Sightseeing</button>
                                                <button onClick={() => this.handleCustomTabClick('tab5')} className={`tab button ${customActiveTab === 'tab5' ? 'active' : ''}`} role="tab" aria-controls="nav-meals" aria-selected={customActiveTab === 'tab5'}>Meals</button>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent" >
                                            <div className={customActiveTab === 'tab1' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-highlights-tab">
                                                <p>The <strong>Kashmir Package Tour from Ahmedabad</strong> is famous for its stunning landscapes, including well-known spots like Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. These places draw tourists from all over the world who come to enjoy the beautiful scenery and take part in outdoor activities.</p>
                                                <p>Planning a <strong>Kashmir Tour Packages from Ahmedabad</strong> is a great choice for travelers looking for beautiful scenery, cultural experiences, and adventure in a unique and stunning location.</p>
                                                <p>Kashmir is located in the Himalayan Mountains and is surrounded by India, Pakistan, and China. It has three main regions: Jammu, the Kashmir Valley, and Ladakh. The area is known for its snowy mountains, green valleys, winding rivers, and peaceful lakes.</p>
                                                <p>The <strong>Kashmir Tour Pakcage from Ahmedabad</strong> are famous for their stunning landscapes, including places like Dal Lake, Nigeen Lake, Gulmarg, Pahalgam, and Sonamarg. Tourists from around the world visit to enjoy the beautiful scenery and take part in outdoor activities. Kashmir has always been a popular destination, known for its natural beauty, adventure sports, religious sites, and historical landmarks. Visitors can enjoy activities like trekking, skiing, staying in houseboats, taking shikara rides, and exploring Mughal gardens and ancient temples.</p>
                                            </div>
                                            <div className={customActiveTab === 'tab2' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-flights-tab">
                                                <div className="overflow-table">
                                                    <span>POPULAR FLIGHTS FOR THE KASHMIR TULIP GARDEN TRIP 2025<b> <i>(SXR)</i></b></span><br /><br />
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th>Flight Operator</th>
                                                            <th>Departure From</th>
                                                            <th>Departure Time</th>
                                                            <th>Arrival at Srinagar</th>
                                                            <th>Stopover</th>
                                                            <th>Travel Time</th>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Kolkata [CCU]</td>
                                                            <td>08:15</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>7h 00m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Mumbai [BOM]</td>
                                                            <td>09:30</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>5h 45m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SpiceJet</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>08:00</td>
                                                            <td>09:25</td>
                                                            <td>(None)</td>
                                                            <td>1h 25m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Bangalore [BLR]</td>
                                                            <td>09:30</td>
                                                            <td>14:10</td>
                                                            <td>Sri Guru Ram Dass Jee [ATQ]</td>
                                                            <td>4h 40m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Goa [GOX]</td>
                                                            <td>10:30</td>
                                                            <td>16:45</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>6h 15m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Hyderabad [HYD]</td>
                                                            <td>08:55</td>
                                                            <td>15:15</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>6h 20m</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IndiGo</td>
                                                            <td>Chennai [MAA]</td>
                                                            <td>08:30</td>
                                                            <td>14:20</td>
                                                            <td>New Delhi [DEL]</td>
                                                            <td>5h 50m</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab3' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-hotel-tab">

                                                <div className="overflow-table">
                                                    <div id="no-more-tables">
                                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                            <thead class="cf">
                                                                <tr className="text-center">
                                                                    {/* <!-- Add the "text-center" className to center content --> */}
                                                                    <th colspan="6" style={{ fontSize: "1.3vw" }}><h6><b>3 STAR CATEGORY HOTEL CERTIFIED BY 'ADORABLE VACATION' </b></h6></th>
                                                                </tr>
                                                                <tr className="table-danger">
                                                                    <th className="text-center">Destination</th>
                                                                    <th className="text-center">Hotel Names</th>
                                                                    <th className="text-center">Hotel Type</th>
                                                                    <th className="text-center">No of Nights</th>
                                                                    <th className="text-center">Meal Plan</th>
                                                                </tr>
                                                            </thead>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Srinagar</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Grand Fortune / Hotel Shefaf / Hotel Siddique Palace / Similar</div></td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>5N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Gulmarg</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Affarwat / Hotel Mama Palace /Gulmarg House / Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>1N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td data-title="Destination"><div style={{ display: 'inline-block' }}>Pahalgam</div></td>
                                                                <td data-title="Hotel Names"><div style={{ display: 'inline-block' }}>Hotel Pine Palace Resort / Hotel Fifth Season / Hotel Moonview Resort /Similar
                                                                </div>
                                                                </td>
                                                                <td data-title="Hotel Type">3 Star
                                                                </td>
                                                                <td data-title="No of Nights"><div style={{ display: 'inline-block' }}>2N</div></td>
                                                                <td data-title="Meal Plan"><div style={{ display: 'inline-block' }}>Room with Breakfast & Dinner</div></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab4' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-sightseeing-tab">
                                                <div className="container-fluid px-0 py-1">
                                                    <h5>WHERE TO STAY DURING YOUR <strong>AHMEDABAD TO KASHMIR SIGHTSEEING TOURS</strong></h5><br />
                                                    <p>Your choice of accommodation can make a big difference in your Ahemdabad to Kashmir Adventure Tours experience. Here are some great options for where you can stay: </p><br />
                                                    <p><b className='tomato'>Houseboats on Dal Lake </b> Stay in a traditional houseboat on Dal Lake in Srinagar for a truly unique experience. These beautifully decorated houseboats offer a peaceful and relaxing stay, surrounded by the lake’s calm waters and stunning scenery. You’ll also get to enjoy the warm hospitality of the locals.
                                                    </p>
                                                    <p><b className='tomato'>Luxury Hotels and Resorts: </b>Kashmir has plenty of luxury hotels and resorts in popular places like Srinagar, Gulmarg, and Pahalgam. These hotels provide top-quality service, modern amenities, and amazing views of the landscape, making your stay comfortable and unforgettable.</p>

                                                    <p><b className='tomato'>Boutique Guesthouses: </b>If you want a more personal experience, boutique guesthouses and homestays are a great choice. These cozy accommodations are often located in quiet areas or beautiful countryside settings, allowing you to connect with local culture while enjoying a comfortable stay.</p>

                                                    <p><b className='tomato'>Tented Camps: </b>For adventure lovers and nature enthusiasts, staying in a tented camp is a unique way to experience Kashmir’s natural beauty. Set in lush valleys, near mountains, or beside streams, these camps provide a simple yet comfortable stay close to nature.</p>

                                                    <p><b className='tomato'>Heritage Properties: </b>For a taste of Kashmir’s history and culture, you can stay in heritage properties like old mansions or palaces. These historic places offer a glimpse into Kashmir’s past while providing modern comforts, making your stay both elegant and memorable.</p>

                                                    <p><b className='tomato'>Eco-Resorts and Retreats: </b>If you care about the environment, eco-resorts and retreats are great options. These sustainable accommodations are designed to be eco-friendly without sacrificing comfort. Set in serene forests, meadows, or mountains, they offer a peaceful and green place to stay.</p>
                                                    <p>No matter which option you choose, each type of accommodation in Kashmir has its own special charm. Think about your preferences, budget, and travel plans to find the best fit for your <strong>Luxury Kashmir tours from Ahmedabad</strong>.</p>
                                                </div>
                                            </div>
                                            <div className={customActiveTab === 'tab5' ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-meals-tab">
                                                <div className="overflow-table">
                                                    <h5 class="tomato">7 times Meals and 7 times Breakfast has been Provided</h5>
                                                    <table className="table table-bordered">
                                                        <tr className="table-danger">
                                                            <th colspan="6" className="text-center">Category</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">JAIN</td>
                                                            <td class="text-center">PURE-VEG</td>
                                                            <td class="text-center">VEGAN</td>
                                                            <td class="text-center">PURE-NON-VEG</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br /><br /><br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                DAY WISE <strong><span>KASHMIR TOUR ITINERARY FROM AHMEDABAD</span></strong>
                                            </h2>
                                            <h4>4 Nights 5 Days <strong>Kashmir Tour Itinerary</strong></h4>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--end Short description--> */}
                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    DAY 1  – ARRIVAL IN SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Arrive at Srinagar’s Sheikh Ul Alam International Airport. You’ll be greeted by our representative and taken to your hotel for check-in. Enjoy a welcome drink and then head out for a shikara ride on the famous Dal Lake. Explore the floating gardens and enjoy bird watching. Spend the night on a houseboat.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="Kashmir Offbeat Packages for Family" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day2"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    DAY 02: SRINAGAR TO GULMARG
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, check out from the houseboat and drive to Gulmarg, known as the “Meadow of Flowers.” Check in at your hotel in Gulmarg. You can take a gondola ride up to the first or second phase of the cable car, which offers stunning views and is one of the highest cable cars in the world. Gulmarg is also famous for skiing and its beautiful meadows. Return to your hotel for the night.
                                                    </p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Package cost offbeat" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day3"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 03: GULMARG TO PAHALGAM
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>Enjoy breakfast before checking out and driving to Pahalgam, known as the “Valley of Shepherds.” This charming town sits where the streams from Sheshnag Lake meet the Lidder River. Check in at your hotel and enjoy the rest of the day at leisure.</p>
                                                    <br />

                                                    <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="Kashmir Tour Packages" /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="Kashmir Tour Packages" /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day4"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 04: - PAHALGAM LOCAL SIGHTSEEING
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast, spend the day exploring Pahalgam. Visit Chandanwari, the starting point for the Amarnath Yatra, and enjoy snow sledding. Explore Aru Valley and Betaab Valley for their stunning scenery. You can also visit Baisaran Valley, known as “Mini Switzerland,” for beautiful meadows and dense pine forests. Return to your hotel in Pahalgam for the night.</p>

                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay4ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day5"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 05: PAHALGAM TO SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Check out from your hotel and drive back to Srinagar. Visit the Hazratbal Shrine and Shankaracharya Temple. In the afternoon, explore the Mughal Gardens: Nishat Bagh, Shalimar Bagh, and Chashme Shahi. Return to your Srinagar hotel for the night.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day6"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 06: SRINAGAR TO DOODHPATHRI AND BACK
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, take a day trip to Doodhpathri, located 42 kilometers from Srinagar. Known as the "Valley of Milk," Doodhpathri is famous for its lush meadows and cold, milky streams. The area is covered with wildflowers in spring and summer. Return to Srinagar in the evening for an overnight stay.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay6ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day7"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 07: SRINAGAR TO SONMARG AND BACK
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, check out from your hotel and head to Sonmarg, meaning "Meadow of Gold." The 87-kilometer drive offers stunning views of forests and mountains. Sonmarg is a gateway to trekking routes and glaciers. You can hire ponies or vehicles to visit Thajiwas Glacier, a major attraction. Return to Srinagar in the evening for your last overnight stay.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay7ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay7ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day8"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTen"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 08:DEPARTURE FROM SRINAGAR
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTen"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTen"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Your <strong>Kashmir Tour From Bangalore</strong> concludes today. After breakfast, you will be transferred to the Domestic Airport for your flight to your next destination. Thank you for traveling with us, and we hope you had a memorable experience.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay8ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay8ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h2>The Khyber Himalayan Resort & Spa <span>- Gulmarg</span></h2>
                                            <h4><i>Next Level Luxury Resort </i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_1_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Platinum Accommodation</td>
                                                <td data-title="Size(Room)">386+ SQ.FT</td>
                                                <td data-title="Hotel Brand">Khyber Hotels (Heritage)<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 53,119/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >The LaLiT Grand Place <span>- Srinagar</span></h2>
                                            <h4 ><i>Fill the Luxury</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row py-3">
                                    <img src={brandedTourackageImage_2_URL} alt="..." />
                                </div>

                                <div id="no-more-tables" >
                                    <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                        <thead>
                                            <tr className="table-danger">
                                                <th>Category</th>
                                                <th>Size(Room)</th>
                                                <th>Hotel Brand</th>
                                                <th>Price (Per Night)</th>
                                                <th>Book Now</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Packages">Premium Accommodation</td>
                                                <td data-title="Size(Room)">290+ SQ.FT</td>
                                                <td data-title="Hotel Brand">The LaLiT Hotels<div className="reviews">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div></td>
                                                <td data-title="Price (Per Night)">Rs 28,332/- </td>
                                                <td data-title="Book Now"><a href="#" className="btn btn-success btn-sm" onClick={this.handleClick}>
                                                    <i className="fab fa-whatsapp"></i> WhatsApp
                                                </a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card mx-0 my-5">
                                    <div className="card-header custHeaer">
                                        <h3 className='content-desktop' style={{ fontSize: "1.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                        <h3 className='content-mobile2' style={{ fontSize: "3.5vw" }}><b>EMBARKED ON A SENSE OF PRIDE AT PREMIUMNESS</b></h3>
                                    </div>
                                    <div className="card-body">
                                        <p ><b>The LaLit Grand Palace</b> was originally built in 1901 for the royal family. It now provides luxurious rooms with views of Dal Lake and the beautiful gardens. An indoor pool, tennis courts and pampering spa treatments are also available.The tastefully designed rooms are modern with a touch of traditional charm. All have a satellite TV, minibar and free tea/coffee making facilities. It is 18 km from Srinagar Airport and 4 km from Lal Chowk city centre. Jammu Railway Station is 300 km away. Guests can enjoy a relaxing massage at Rejuve Spa Health Club, practice yoga, or play golf. The hotel also has a gym and 24-hour business center. The Chinar Garden serves North Indian cuisine, while international buffet spreads are available at The Chinar – The All Day Coffee Shop. Other dining options include Dal Bar and 24-hour room service.</p>

                                        <a href="tel://9883359713" className="btn btn-Warning">Book Now</a>

                                    </div>
                                </div>
                            </div>

                            <br /><br />
                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>Kashmir<span> Package Tour From Ahmedabad </span></h2>
                                            <h4>Related Kashmir Tour plan</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2 >Frequently Ask <span>Questions</span></h2>
                                            <h4 >Kashmir Package Tour From Ahemdabad Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" >
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion" id="accordionFlushExample">
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwenty">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwenty" aria-expanded="false" aria-controls="flush-collapseTwenty">
                                                        Q. Is Kashmir Safe to Visit?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwenty" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwenty" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Yes, it is generally safe for tourists. Always check the latest updates and register with your embassy for safety.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentyOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyOne" aria-expanded="false" aria-controls="flush-collapseTwentyOne">
                                                        Q. What Is the Best Time to Visit Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentyOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentyOne" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> It depends on your preference:
                                                            <br />• Spring (March-May) for flowers.
                                                            <br />• Summer (June-August) for trekking.
                                                            <br />• Autumn (Sept-Oct) for fall views.
                                                            <br />• Winter (Nov-Feb) for snow and skiing.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentyTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyTwo" aria-expanded="false" aria-controls="flush-collapseTwentyTwo">
                                                        Q. How Many Days Are Ideal for a Kashmir Tour from Ahmedabad?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentyTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentyTwo" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> 7-8 days are enough to visit Srinagar, Gulmarg, Pahalgam, and Sonamarg. More time is needed for trekking.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentyThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyThree" aria-expanded="false" aria-controls="flush-collapseTwentyThree">
                                                        Q. How to Reach Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentyThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentyThree" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Fly to Srinagar International Airport or go by train or road, though these take longer.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentyFour">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyFour" aria-expanded="false" aria-controls="flush-collapseTwentyFour">
                                                        Q. How to Get Around in Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentyFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentyFour" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Use taxis, shikaras (boats), or auto-rickshaws.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentyFive">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyFive" aria-expanded="false" aria-controls="flush-collapseTwentyFive">
                                                        Q. What Are Must-Do Activities in Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentyFive" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentyFive" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Visit Srinagar, Gulmarg, Pahalgam, and Sonamarg; enjoy a shikara ride; go trekking; try winter sports in Gulmarg, and enjoy Kashmiri food.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentySix">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentySix" aria-expanded="false" aria-controls="flush-collapseTwentySix">
                                                        Q. Is a Houseboat Stay Worth It?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentySix" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentySix" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Yes, it’s a unique experience with beautiful views and insight into Kashmiri culture, but it depends on your preferences.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentySeven">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentySeven" aria-expanded="false" aria-controls="flush-collapseTwentySeven">
                                                        Q. How Much Does a Kashmir Tour from Ahmedabad Cost?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentySeven" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentySeven" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Prices vary. Budget tours start at ₹16,600. Luxury tours cost more based on duration and activities.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentyEight">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyEight" aria-expanded="false" aria-controls="flush-collapseTwentyEight">
                                                        Q. What Clothes Should I Pack for Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentyEight" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentyEight" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Pack light clothes for summer and warm, waterproof clothing for winter.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingTwentyNine">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyNine" aria-expanded="false" aria-controls="flush-collapseTwentyNine">
                                                        Q. Do I Need Permits for Trekking in Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseTwentyNine" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwentyNine" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> Yes, some treks need permits. Check with your tour operator or local authorities.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="flush-headingThirty">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirty" aria-expanded="false" aria-controls="flush-collapseThirty">
                                                        Q. What Is the Local Currency in Kashmir?
                                                    </button>
                                                </h4>
                                                <div id="flush-collapseThirty" className="accordion-collapse collapse show" aria-labelledby="flush-headingThirty" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p><b>Answer:</b> The Indian Rupee (INR). ATMs are available in cities, but carry cash for remote areas.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center" >
                                            <h3><b>Kashmir <span class="tomato">Holiday Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container" >

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <BookingBox />
                        </div>
                        {this.state.showModal && (
                            <div className="modal" style={{ display: this.state.showModal ? "block" : "none" }}>
                                <div className="modal-dialog">
                                    <div className="modal-content cust-modContent">
                                        <div className="modal-header">
                                            <h4 className="modal-title ms-auto" id="exampleModalLabel">Get a Free Callback &nbsp;&nbsp;<i className="fa fa-phone fa-rotate-90"></i>
                                            </h4>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.onCloseModal}></button>
                                        </div>
                                        <div className="modal-body">
                                            <form className="row g-3">
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name *"
                                                        name="firstName"
                                                        value={this.state.firstName}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                {/* <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Last Name *"
                                                        name="lastName"
                                                        value={this.state.lastName}
                                                        onChange={this.onChangeHandler} />
                                                </div> */}
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="10 Digit Mobile No.*"
                                                        name="mobile"
                                                        value={this.state.mobile}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Email *"
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={this.onChangeHandler} />
                                                </div>
                                                <div className="col-12">
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Comments"
                                                        rows="2"
                                                        name="comments"
                                                        value={this.state.comments}
                                                        onChange={this.onChangeHandler}></textarea>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <div className='container'>
                                                <div className="row">
                                                    <div className='col-sm-4'>
                                                        {
                                                            (this.state.showLoader) &&
                                                            <div className='pull-left'><Digital size={25} /></div>
                                                        }
                                                    </div>
                                                    <div className='col-sm-8 '>
                                                        <div className='pull-right'>
                                                            <button type="button" className="btn btn-Warning" onClick={this.onRequestCallbackSubmit} >Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div >
                </div >
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-91470-61467">
                        <i className="fa fa-phone"></i>
                        {/* <span><small>+91 9147061467</small></span> */}
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                        {/* <span>
                                <small>+91 9883359713</small>
                            </span> */}
                    </a>
                </div>

            </>
        );
    }
}

export default Kashmir_7N_8D_Package_Tour_From_Ahmedabad;