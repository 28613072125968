import React, { Component } from 'react';
import ApiURL from "../../../api/ApiURL";

class InclusionExclusion extends Component {
    
    render() {
        return (
            <>
                <h4><u>INCLUSION: <strong className='tomato'> VIETNAM PACKAGE TOUR FROM KOLKATA</strong></u></h4>
                <ul>
                <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;06 night accommodation based on triple sharing basis
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;01 night cruise based on on triple sharing basis boat in Halong Bay
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Meals as mentioned in the program</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;All entrance fees and sightseeing as mentioned in the program</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Boat trip(s) as mentioned in the itinerary</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;SIC tours as mentioned in the itinerary</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;All Transportation by A/C vehicle</li>
					<li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Water during tour day</li>
					<li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Local English speaking guide(s) in Vietnam</li>
					<li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Vietnam single entry tourist visa charges</li>
					<li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;All Taxes (Except TCS 5%)</li>
                </ul><br/><br/><br/>
                <h4><u>EXCLUSIONS: <strong className='tomato'>VIETNAM TOUR PACKAGES FROM KOLKATA</strong></u></h4>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Any flights (International &amp; Domestic) and airport tax</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;TCS 5% (TCS can be claimed back during filing for IT return, we will provide TCS certificate)</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Mandatory TIPs of USD 04 per person per day payable directly</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Travel Insurance</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Meals not mentioned in the program</li>
					 <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Single Supplement; Early check-in and late check-out; Hotel/Room upgrade</li>
					 <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Tips, drinks, personal expenses and any services not mentioned in the program</li>
                </ul>
                <br/>
            </>
        );
    }
}

export default InclusionExclusion;