import React, { Component } from 'react';

class InclusionExclusion extends Component {
    render() {
        return (
            <>
                <h4 className='tomato1'>INCLUSIONS : <strong>TAWANG BUMLA PASS TOUR PACKAGE</strong></h4>
                <ul>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>
                        &nbsp;&nbsp;Accommodation on a shared basis, as per the mentioned meal plan.
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>
                        &nbsp;&nbsp;All transportation arranged through a dedicated AC vehicle with a professional driver.
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>
                        &nbsp;&nbsp;Daily breakfast included in the package.
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>
                        &nbsp;&nbsp;Inner Line Permit [ILP] for Nagaland, along with processing fees.
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>
                        &nbsp;&nbsp;Pickup and drop services from Guwahati Airport/Railway Station/Bus Station.
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>
                        &nbsp;&nbsp;Comprehensive coverage of all sightseeing activities, land transfers, and inter-hotel transfers.
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>
                        &nbsp;&nbsp;Inclusive of driver allowance, toll charges, parking fees, road tax, fuel charges, night halt expenses, and interstate charges.
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>
                        &nbsp;&nbsp;Complimentary accommodation for a child below 5 years sharing the same bed with parents, adhering to the hotel's policies.
                    </li>
                </ul><br /><br />
                <h4 className='tomato1'>EXCLUSIONS : <strong>TAWANG BUMLA PASS TOUR PACKAGE</strong></h4>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                        &nbsp;&nbsp;Personal expenses and unspecified activities
                    </li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                        &nbsp;&nbsp;Meals not in the itinerary
                    </li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                        &nbsp;&nbsp;5% GST
                    </li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                        &nbsp;&nbsp;Taxi fare for optional Tawang sightseeing
                    </li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                        &nbsp;&nbsp;Entry fees, camera fees, and optional activity costs
                    </li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>
                        &nbsp;&nbsp;Not mentioned in "Inclusions"
                    </li>
                </ul>
            </>
        );
    }
}

export default InclusionExclusion;