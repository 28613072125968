import React, { Component } from 'react';

class PlacemustVisit extends Component {
    
    render() {
        return (
            <>
             <div id="no-more-tables">
                 <table className="col-md-12 table-bordered table-striped table-condensed cf">
                    <tbody>
                        <tr>
                            <td style={{ fontSize: "16px" }}> Buddha Dordenma - Thimphu </td>
                            <td style={{ fontSize: "16px" }}> Taktsang Monastery - Paro </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: "16px" }}>Dagala Thousand Lakes - Thimphu </td>
                            <td style={{ fontSize: "16px" }}>Punakha Suspension Bridge - Punakha </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: "16px" }}>National Memorial Chorten - Thimphu </td>
                            <td style={{ fontSize: "16px" }}>Trashigang Dzong - Trashigang District </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: "16px" }}>Tashichho Dzong - Thimphu </td>
                            <td style={{ fontSize: "16px" }}>Simtokha Dzong - Thimphu</td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: "16px" }}>Trongsa Dzong - Trongsa</td>
                            <td style={{ fontSize: "16px" }}>Rinpung Dzong - Paro</td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: "16px" }}>Punakha Dzong - Punakha </td>
                            <td style={{ fontSize: "16px" }}>Gangtey Monastery - Wangdue Phodrang</td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: "16px" }}>Chimi Lhakhang Temple - Punakha </td>
                            <td style={{ fontSize: "16px" }}>Dochula Pass - On the way from Thimphu to Punakha</td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <br />
                
            </>
        );
    }
}

export default PlacemustVisit;