import React, { Component } from 'react';

class InclusionExclusion_group extends Component {
    render() {
        return (
            <>
                <h4><u>INCLUSION: <strong className='tomato'>FASCINATING GARO HILLS WITH MEGHALAYA TOUR PACKAGE</strong></u></h4>
                <ul>
                <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Accommodation in listed Hotels in Double Bedded Room
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;All transfer and sightseeing by exclusive private vehicle
                    </li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Driver allowance, fuel, toll, parking and related expenditure</li>
                    <li><span className="green-dot"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;Operation executive on call for 24x7.</li>
                </ul><br/><br/><br/>
                <h4><u>EXCLUSIONS: <strong className='tomato'>FASCINATING GARO HILLS WITH MEGHALAYA TOUR PACKAGE</strong></u></h4>
                <ul>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Air Fare / Train Fares</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Any expenses of personal nature</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Items not mentioned in the inclusion section</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Lunch, Snacks or any other meal</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Sightseeing points entry fees, camera permit, ferry rides</li>
                    <li><span className="red-dot"><i className="fa fa-times" aria-hidden="true"></i></span>   
                    &nbsp;&nbsp;Applicable Govt. GST</li>
                </ul>
            </>
        );
    }
}

export default InclusionExclusion_group;