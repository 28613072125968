import React, { Component, Fragment } from "react";
import FooterDesktop from "../../../components/common/FooterDesktop";
import MainHeader from "../../../components/common/MainHeader";
import GalleryExplorerInside from "../../../components/Gallery/GalleryExplorerInside";
import ImagePopup from "../../../components/Gallery/ImagePopup";
import { Helmet } from "react-helmet";

class BhutanGalleryInsidePage extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Bhutan Gallery Inside</title>
        </Helmet>

        <MainHeader />

        <GalleryExplorerInside />

        <FooterDesktop />

      </Fragment>
    );
  }
}

export default BhutanGalleryInsidePage;
